import React from 'react'
import { Form, Select, Modal } from 'antd'
import InputE from '../../../shared/FormElements/Input'
import ButtonE from '../../../shared/FormElements/Button'
import axios from 'axios'
import showMessage from '../../../shared/MessagesInfo/message'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import authCheckStatus from '../../../shared/utils/authSeviceChecked'
import { USER_LOGOUT } from '../../../actions/userAction/userActionsType'

const CretePredefinedTextModal:React.FC<any> = ({ isModalPredefinedText, setIsModalPredefinedText, state, currentCountryName}) => {
    const [ form ] = Form.useForm()
    const history = useHistory()
    //const state = useSelector(( state:RootStore ) => state)
    //const currentCountryName =  state.dataLs.country ? state.dataLs.country.currentCountry:"rs"
    //const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1
    const { Option } = Select
    const dispatch = useDispatch()
    // useEffect(()=>{
       
    //     dispatch(getAllCategoryL3(currentCountryId))
    //   }, [ dispatch, currentCountryId ])
    

    const createPredefinedText = async ( obj:any ) => {
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/predefined-word/create?country_id=1&l3_l4_id=${obj.level_id}&${`name_${currentCountryName}`}=${obj.name}`, obj)
            showMessage(data.message)
            history.push('/price-list')
            form.resetFields()
        } catch ( err:any ) {
            if (err.response) {
                if (authCheckStatus(err.response.status)) {
                   dispatch({ type: USER_LOGOUT, payload: {} })
                }
     
             }
        }
    }

    const handleSubmit = ( e:any ) => {
        createPredefinedText(e)
        setIsModalPredefinedText(false);
    }
    //----for modal
    const handleCancel = () => {
        setIsModalPredefinedText(false);
    }
    return (
        <div>
            <Modal 
                title="Create predefined text" 
                open={isModalPredefinedText} 
                onCancel={handleCancel}
                footer={false}
            >
                <Form 
                    form={form}
                    name="create-category-form" 
                    labelCol={{ span: 8 }} 
                    onFinish={ handleSubmit }
                >
                    <Form.Item 
                        label="Category name" 
                        name="level_id"
                        rules={[{ required: true, message: 'Missing Level id' }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '180px', marginBottom:'0.5rem' }}
                            placeholder="Select category"
                            filterOption={(input, option:any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                        >
                        { state &&  state.categoriesL3.data && state.categoriesL3.data.sort((a:any,b:any) => (a[`name_${currentCountryName}`] > b[`name_${currentCountryName}`]) ? 1 : ((b[`name_${currentCountryName}`] > a[`name_${currentCountryName}`]) ? -1 : 0)).map((option:any)=>
                            <Option key={option.id} value={option.id}>{option[`name_${currentCountryName}`]}</Option>)
                        }
                    </Select>
                    </Form.Item>
                    <InputE 
                        label="Name" 
                        name={`name_${currentCountryName}`}
                        rules={[{ required: true, message: 'Missing name' }]}
                    />
                    <ButtonE 
                        type="primary" 
                        htmlType="submit" 
                        title="Create new predefined text"
                        wrapperCol={{ offset: 8, span: 16 }}
                    />
                </Form>
            </Modal>
        </div>
    )
}

export default CretePredefinedTextModal
