import axios from 'axios'
import { Dispatch } from 'redux'
import showMessage from '../../shared/MessagesInfo/message'
import authCheckStatus from '../../shared/utils/authSeviceChecked'
import { DeleteUser, UserCreate, UserEdit, UserMyAcUpdate, UserSuccess, ShowUser, USERS_LIST, USER_CREATE, USER_DELETE, USER_EDIT, USER_GET, USER_MYAC_UPDATE, USER_SHOW, USER_SET, UserLogout, USER_LOGOUT, UserMyAcChangePassword, USER_MYAC_CHANGE_PASSWORD, UserMyAcChangeEmail, USER_MYAC_CHANGE_EMAIL } from './userActionsType'
//import { countries } from '../../constants/countries'


export const getAllUsers = (active:boolean, role:string|boolean) => async (dispatch: Dispatch<UserSuccess|UserLogout>) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    axios.defaults.headers.common['withCredentials'] = false

    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/auth/users-list${ active? `?active=true`:'?active=false'}${ role ? `&role=${role}`:''}`)
        dispatch({ type: USERS_LIST, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}

// export const loginUser = (typeAction: any, user: any, history: any) => async (dispatch: Dispatch<UserSuccess|UserLogout| SetLSCurrentCountry>) => {
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_URL_API}/auth/login`, user)
//         //authCheckStatus(response.status)
//         const data = response.data
//         //const countriesArr = countries ?  countries.map( c => c.name) : []
//         if (data.success) {

//             localStorage.setItem('token', data.success.token)
//             //localStorage.setItem('countries', JSON.stringify(countriesArr))
//             //localStorage.setItem('currentCountry', countries[0])
//             const tokenExpirationDate = new Date(new Date().getTime() + 1000*60*60*10)
            
//             localStorage.setItem('tokenExpirationDate', JSON.stringify( tokenExpirationDate ))
//             localStorage.setItem('loggedInTime', JSON.stringify(new Date()))
//             const lsObj = {
//                 currentCountry:"rs",
//                 countryId:1
//             }
//             localStorage.setItem('country', JSON.stringify(lsObj))
//         }
//         showMessage(data.message)
//         dispatch({ type: typeAction, payload: { ...data, token: data.success.token, tokenExpirationDate: new Date().getTime() + 1000*60*60*10} })
//         dispatch({ type: SET_LS_CURRENT_COUNTRY, payload:{currentCountry:"rs", countryId:1}})
//         history.push('/')
//     } catch (err:any) {
//         if (err.response) {
//             if (authCheckStatus(err.response.status)) {
//                dispatch({ type: USER_LOGOUT, payload: {} })
//             }
 
//          }
//     }

// }

//user for refresh page -> from localstorage

export const setUserFromLS = (token: any, countries: any, currentCountry: any) => (dispatch: any) => {
    if (token) {
        dispatch({ type: USER_SET, payload: { data: { message: '', status: true, token: token, countries: countries, currentCountry } } })
    }
}

//---------logout 

export const userLogout = () => (dispatch: Dispatch<UserLogout>) => {

    dispatch({ type: USER_LOGOUT, payload: {} })
    localStorage.removeItem('token')
    localStorage.removeItem('countries')
    localStorage.removeItem('tokenExpirationDate')
    localStorage.removeItem('loggedInTime')


    localStorage.removeItem('country')

}

//-------create user-------

export const createUser = (user: any, history: any, historyItem: any) => async (dispatch: Dispatch<UserCreate|UserLogout>) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/auth/register`, user)
        if (data.token) {
            const countries = ['rs', 'si', 'hr']
            localStorage.setItem('token', data.token)
            localStorage.setItem('countries', JSON.stringify(countries))
            localStorage.setItem('lang', 'rs')
            const tokenExpirationDate = new Date(new Date().getTime() + 1000*60*60*10)
            localStorage.setItem('tokenExpirationDate', tokenExpirationDate.toISOString()  )
        }
        showMessage(data.message)
        dispatch({ type: USER_CREATE, payload: data })
        if (historyItem === 'add-user') {
            history.push('/user-list')
        }
        else {
            history.push('/')
            
        }
    } catch (err: any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }

    }
}

//------user from list
export const showUser = (id: any, form: any) => async (dispatch: Dispatch<ShowUser|UserLogout>) => {
    try {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/auth/show-user?id=${id}`)
        form.setFieldsValue(data.data)
        dispatch({ type: USER_SHOW, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}

//--------edit user from the list--------

export const editUserFromList = (formData: any, history: any) => async (dispatch: Dispatch<UserEdit|UserLogout>) => {
    try {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/auth/edituser`, formData)
        showMessage(data["0"])
        history.push('/user-list')
        dispatch({ type: USER_EDIT, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}


export const deleteUser = (id: any) => async (dispatch: Dispatch<DeleteUser|UserLogout>) => {
    try {
        const resp = await axios.delete(`${process.env.REACT_APP_URL_API}/auth/deleteuser/`, {
            data: {
                'id': id,
            }
        })
        showMessage(resp.data.message)
        dispatch({ type: USER_DELETE, payload: id })

    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}




//--------get single user for my account------------

export const getUser = () => async (dispatch: Dispatch<any>) => {
    try {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        const response = await axios.get(`${process.env.REACT_APP_URL_API}/auth/userinfo`)
        const data = response.data
        const newData = {
            ...data,
            data: {
                ...data.data,
                key: data.data.id

            }
        }
        dispatch({ type: USER_GET, payload: newData })
    } catch (err: any) {
        if (err.response) {
           if (authCheckStatus(err.response.status)) {
              dispatch({ type: USER_LOGOUT, payload: {} })
           }

        }
    }
}

//--------------update user myAc--------------

export const editMyAcUser = (user: any) => async (dispatch: Dispatch<UserMyAcUpdate |UserLogout>) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/auth/editself`, user)
        showMessage(data.message)
        dispatch({ type: USER_MYAC_UPDATE, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}

//---------chage passowrd myAc

export const changePasswordMyAc = (obj: any) => async (dispatch: Dispatch<UserMyAcChangePassword|UserLogout>) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/auth/changepassword`, obj)
        showMessage(data.message)
        dispatch({ type: USER_MYAC_CHANGE_PASSWORD, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}


//--------change email myAc

export const changeEmailMyAC = (email: string) => async (dispatch: Dispatch<UserMyAcChangeEmail|UserLogout>) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/auth/change-email?email=${email}`)
        showMessage(data.message)
        dispatch({ type: USER_MYAC_CHANGE_EMAIL, payload: data })
    } catch (err:any) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
}
