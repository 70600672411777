import { Badge, Col, Progress } from "antd";
import React from "react";
import { ProductType } from "../../types/types";

interface ProgressBarProps {
    matchingIndex: number;
    products_for_matching: ProductType[];
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    return (
        <Col span={12} style={{ position: "relative", display: "flex" }}>
            <Progress
                style={{ width: "100%" }}
                strokeColor={{
                    from: "#108ee9",
                    to: "#87d068",
                }}
                percent={((props.matchingIndex + 1) / props.products_for_matching.length) * 100}
                showInfo={false}
            />
            <Badge
                count={`${props.matchingIndex + 1} / ${props.products_for_matching.length}`}
                style={{
                    height: "30px",
                    fontSize: "25px",
                    paddingTop: "3px",
                    backgroundColor: "skyblue",
                    position: "absolute",
                }}
            />
        </Col>
    );
};

export default ProgressBar;
