import { AutoComplete, Button, Card, Col, Divider, Image, Layout, Radio, Row, Select, Space, Spin, Statistic, Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { ColumnType } from "antd/es/table";
import "../style/EditProductMpn.css";
import EditableCell, { EditableRow } from "../components/EditProductMpn/EditableCell";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import axios from "axios";
import numberWithCommas from "../../../shared/utils/numberUtils";
import showMessage from "../../../shared/MessagesInfo/message";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { BaseOptionType } from "antd/es/select";

const { Header } = Layout;

export type EditProductMpnType = {
    id: number;
    brand: string;
    name: string;
    mpn: string | null;
    mpn_init: string | number | null;
    mpnInit: string;
    link: string;
    image: string;
    isProductMpnLoading: boolean;
    removeMpnLoading: boolean;
    mpn_checked: boolean;
    isMpnShort: boolean;
};

type CategoryType = {
    id: number;
    count: number;
    value: string;
    [key: string]: string | number;
};

type CountRadioBtnType = {
    checked: number;
    not_checked: number;
    all: number;
};

export const clearMpn = (text: string) =>
    text
        .replace(/[^a-zA-ZŽžŠšĐđČčĆć0-9+]/g, "")
        .toLowerCase()
        .trim();

const showCount = (counts: CountRadioBtnType | null, checked: boolean | string) => {
    if (checked === "all" && counts) return counts?.checked + counts?.not_checked; // all
    else if (checked) return counts?.not_checked; // not_checked
    else return counts?.checked; // checked
};

const queryString = "brand, category_hr, category_rs, category_si, name_rs, name_hr, name_si";

let isChangedRadioBtn: boolean = false;
let isChangeCategory: boolean = false;

const EditProductMpn = () => {
    const [productMpn, setProducMpn] = useState<EditProductMpnType[]>([]);
    const [count, setCount] = useState<number | null>(null);
    const [countRadioBtns, setCountRadioBtns] = useState<CountRadioBtnType | null>(null);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [searchField, setSeatchField] = useState("");
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [filterChecked, setFilterChecked] = useState<string | boolean>("all");
    const [page, setPage] = useState(1);
    const state = useSelector((state: RootStore) => state);
    const currentCountryName = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";
    const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;

    //----------  for brands
    const [brandList, setBrandList] = useState<{ id: number; name: string }[]>([]);

    useEffect(() => {
        setProducMpn([]);
    }, [currentCountryId]);

    useEffect(() => {
        const getBrands = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/list-valid-brands`);
                const filteredData = data.data.filter((d: { id: number }) => d.id !== 3621); //3621
                filteredData.unshift(data.data.find((d: { id: number }) => d.id === 3621));
                setBrandList(filteredData);
            } catch (err) {
                console.log(err);
            }
        };
        getBrands();
    }, []);

    useEffect(() => {
        setPage(1);
    }, [currentCountryId]);

    const getCategories = useCallback(async () => {
        setLoadingCategories(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/get-facets?facet=category_rs&query=${searchField.length > 0 ? searchField : "*"}&filter_by=${`active_countries:=${currentCountryId}`}${
                    filterChecked !== "all" ? `%26%26mpn_checked:=${!filterChecked}` : ""
                }`
            );
            setCategories((curr) => {
                const allCategories = data.data[0].counts.map((c: { value: string }) => {
                    const splitData = c.value.split("::");
                    return {
                        ...c,
                        id: Number(splitData[0]),
                        name: splitData[1],
                    };
                });
                const checkCategory = allCategories.find((c: CategoryType) => c.value === selectedCategory); // if in new data there is no that category, find category in current state (set it coutn 0) and splice in new array
                const findEmptyCateogry = curr.find((c) => c.value === selectedCategory);
                return !checkCategory && selectedCategory && (isChangedRadioBtn || isChangeCategory) ? [...allCategories, { ...findEmptyCateogry, count: 0 }] : allCategories;
            });
            setLoadingCategories(false);
            isChangedRadioBtn = false;
            isChangeCategory = false;
        } catch (err) {
            console.log(err);
            setLoadingCategories(false);
        }
    }, [currentCountryId, filterChecked, selectedCategory, searchField]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        setLoadingCategories(true);
    }, [currentCountryId]);

    const getCounts = useCallback(async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/get-facets?facet=mpn_checked&query=*&filter_by=active_countries:=${currentCountryId} ${
                    selectedCategory ? `%26%26 category_${currentCountryName}:=${selectedCategory}` : ""
                }`
            );
            const counts = data.data[0].counts;
            setCountRadioBtns({
                checked: counts.find((c: { value: string }) => c.value === "true")?.count ?? 0,
                not_checked: counts.find((c: { value: string }) => c.value === "false")?.count ?? 0,
                all: 0,
            });
        } catch (err) {
            console.log(err);
        }
    }, [selectedCategory, currentCountryId, currentCountryName]);

    useEffect(() => {
        getCounts();
    }, [getCounts]);

    const getProductMpnData = useCallback(async () => {
        setLoading(true);
        try {
            const objToSend = {
                collection: "productMpns",
                data: {
                    q: searchField.length > 0 ? searchField : "*",
                    query_by: queryString,
                    per_page: 100,
                    page: page,
                    facet_by: "mpn_checked",
                    filter_by: `${`active_countries:${currentCountryId}`}${selectedCategory ? `&&category_${currentCountryName}:${selectedCategory}` : ""}${
                        filterChecked !== "all" ? `${"&& "}mpn_checked:${!filterChecked}` : ""
                    }`, //${ selectedCategory ? '&& ':'' }mpn_checked:=${ false }
                },
            };
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/search`, objToSend);
            setLoading(false);
            setCount(data.found);
            setProducMpn(
                data.hits.map((d: { document: EditProductMpnType }) => {
                    const newData = d.document;
                    return {
                        ...newData,
                        isProductMpnLoading: false,
                        isMpnShort: false,
                        removeMpnLoading: false,
                        mpn_init: newData.mpn,
                        brand: newData.brand.split("::")[1],
                        id: Number(newData.id.toString().replace("product_", "")),
                        name: newData[`name_${currentCountryName}` as keyof EditProductMpnType],
                    };
                })
            );
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }, [selectedCategory, filterChecked, page, currentCountryName, searchField, currentCountryId]);

    useEffect(() => {
        getProductMpnData();
    }, [getProductMpnData]);

    const removeMpn = (id: number) => {
        const findEmptyMpn = productMpn.find((m) => m.id === id)?.mpn;
        if (findEmptyMpn === null || findEmptyMpn?.trim().length === 0) {
            return;
        }
        updateProduct(id, null, "mpn", "remove_mpn")
            .then(() => {
                setProducMpn((curr) => curr.map((d) => (d.id === id ? { ...d, mpn: null, mpn_checked: true } : d))); // '' ili null ? to check wiht backend
            })
            .catch((err) => console.log(err));
    };

    const linkPrefix = state.countries.find((c) => c.name === currentCountryName)?.link ?? "";

    const defaultColumns: (ColumnType<EditProductMpnType> & {
        editable?: boolean;
        dataIndex: string;
    })[] = [
        {
            title: "Product Id",
            dataIndex: "product_id",
            width: "200px",
            render: (text) => <span style={{ marginLeft: "15px" }}>{text}</span>,
        },
        {
            title: "Image",
            dataIndex: "image",
            align: "center" as "center",
            width: "100px",
            render: (record) => <Image src={record} alt="product-mpn" style={{ maxWidth: "90px", maxHeight: "60px" }} />,
        },
        {
            title: "Brand",
            dataIndex: "brand",
            align: "center" as "center",
            width: "300px",
            sorter: (a, b) => a.brand.localeCompare(b.brand),
            editable: true,
        },
        {
            title: "Name",
            dataIndex: `name_${currentCountryName}`,
            width: "58%",
            editable: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, record) => <div className={record.id.toString()}>{record[`name_${currentCountryName}` as keyof typeof record]}</div>,
        },
        {
            title: "Mpn",
            dataIndex: "mpn",
            className: "edit-product-mpn-remove-mpn-left",
            width: "350px",
            sorter: (a, b) => {
                if (a.mpn == null && b.mpn == null) return 0;
                if (a.mpn == null) return -1;
                if (b.mpn == null) return 1;
                return a.mpn.localeCompare(b.mpn);
            },
            editable: true,
        },
        {
            title: "Link",
            dataIndex: `link_${currentCountryName}`,
            align: "center" as "center",
            width: "100px",
            render: (text) => (
                <a
                    href={`${linkPrefix}${text}`}
                    onClick={() => window.open(`${linkPrefix}${text}`, "", "width=900, height=1080, top=0, left=960")}
                    className="shop-link"
                    style={{
                        marginLeft: "0.5rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Link
                </a>
            ),
        },
    ];
    const handleSave = (row: EditProductMpnType, dataIndex: string) => {
        const newData = [...productMpn];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        if (dataIndex === "mpn") {
            setProducMpn(
                newData.map((d) => {
                    if (d.id === row.id) {
                        //if in list have two same products destination , it will change mpn on that products
                        return {
                            ...d,
                            mpn: row.mpn ? row.mpn.trim() : row.mpn, //clear mpn here
                            mpn_checked: true,
                        };
                    }
                    return d;
                })
            );
        }
        if (dataIndex === "brand") {
            setProducMpn(
                newData.map((d) => {
                    if (d.id === row.id) {
                        return {
                            ...d,
                            brand: row.brand,
                        };
                    }
                    return d;
                })
            );
        }
        if (dataIndex === `name_${currentCountryName}`) {
            const newName = row[`name_${currentCountryName}` as keyof typeof row];
            setProducMpn(
                newData.map((d) => {
                    if (d.id === row.id) {
                        return {
                            ...d,
                            name: typeof newName === "string" ? newName : "",
                        };
                    }
                    return d;
                })
            );
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: EditProductMpnType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                setProducMpn: setProducMpn, //add funcion for set state
                brandList: brandList,
                currentCountryName: currentCountryName,
                updateProduct: updateProduct,
                removeMpn: removeMpn,
                handleSave,
            }),
        };
    });

    const updateProduct = async (id: number, text: string | number | null, type: string, actionType?: string) => {
        actionType === "remove_mpn" && setProducMpn((curr) => curr.map((m) => (m.id === id ? { ...m, removeMpnLoading: true } : m)));
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, {
                id: id,
                [type]: text,
                ...(type === "mpn" && { mpn_checked: true }),
                ...(type === "brand_id" && {
                    brand: brandList.find((b) => b.id === text)?.name,
                }),
            });
            showMessage(data.message);
            actionType === "remove_mpn" && setProducMpn((curr) => curr.map((m) => (m.id === id ? { ...m, removeMpnLoading: false } : m)));
            type === `name_${currentCountryName}` && typeof text == "string" && setProducMpn((curr) => curr.map((m) => (m.id === id ? { ...m, [`name_${currentCountryName}`]: text } : m)));
            type === "mpn" && getCounts();
            type === "mpn" && getCategories();
            return data;
        } catch (err) {
            console.log(err);
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "F6") {
            e.preventDefault();
            const selObj = window.getSelection();
            if (selObj?.rangeCount) {
                const range = selObj?.getRangeAt(0);
                const startNode = range.startContainer;
                const endNode = range.endContainer;
                if (startNode !== endNode) {
                    openNotification("Set mpn from two products name is not allowed");
                    return;
                }
                const selectedElement = selObj?.anchorNode?.parentElement;
                const mpnText = clearMpn(selObj.toString().trim());
                const id = Number(selectedElement?.className);
                if (mpnText.length < 4) {
                    openNotification("Selected text has to be longer than 3 characters");
                    return;
                }
                setProducMpn((curr) => curr.map((d) => (d.id === id ? { ...d, isProductMpnLoading: true } : d)));
                updateProduct(id, mpnText, "mpn")
                    .then(() => {
                        setProducMpn((curr) =>
                            curr.map((d) =>
                                d.id === id
                                    ? {
                                          ...d,
                                          mpn: mpnText,
                                          mpn_checked: true,
                                          isProductMpnLoading: false,
                                      }
                                    : d
                            )
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });

    const onPaginateChange = (e: number) => {
        setPage(e);
    };

    const saveProductMpn = async () => {
        setSaveLoading(true);
        const arrObj = productMpn.map((p) => ({
            id: p.id,
            mpn: p.mpn ? p.mpn : null,
            mpn_checked: true,
        }));

        const findShortMpns = arrObj.filter((m) => m.mpn && m.mpn?.trim().length < 4);

        if (findShortMpns.length > 0) {
            setProducMpn((curr) =>
                curr.map((m) => ({
                    ...m,
                    isMpnShort: m.mpn && m.mpn.trim().length < 4 ? true : false,
                }))
            );
            const shorMpnPosition = productMpn.map((m, i) => (m.mpn && m.mpn.trim().length < 4 ? i + 1 : null)).filter((m) => m);
            openNotification(`Mpn's on position ${shorMpnPosition.map((m) => `${m}`)} in table has length less than 4 characters.`);
            return;
        }
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/mpn/process`, {
                products: arrObj,
            });
            getProductMpnData();
            // here call api for count getCountProductMpnData()
            if (count && page === Math.ceil(count / 100) && page > 1) {
                setPage((curr) => curr - 1);
            }
            // ------------------ add here like in Edit Product Mpn -------------------
            showMessage(data.message);
            setSaveLoading(false);
            getCounts();
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } catch (err) {
            console.log(err);
            setSaveLoading(false);
        }
    };

    const changeCategory = (e: string) => {
        isChangeCategory = true;
        setSelectedCategory(e);
        setPage(1);
    };

    const handleRadioBtnChange = (e: string | boolean) => {
        isChangedRadioBtn = true;
        setFilterChecked(e);
        setPage(1);
    };

    let delayTimer: NodeJS.Timeout | undefined;

    const handleSearchChange = (value: string) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            setSeatchField(value.trim());
            setPage(1);
        }, 1000);
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Product MPN Testing" />
            <Col span={17} className="automatched-prices-box">
                <Divider>Product MPN</Divider>
                <div
                    className="automatched-prices-search"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 1rem",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Select
                            style={{
                                width: "360px",
                                alignSelf: "center",
                                marginTop: "10px",
                                opacity: loadingCategories ? 0.7 : 1,
                            }}
                            showSearch
                            allowClear
                            placeholder={selectedCategory && !categories.find((c: { value: string }) => c.value === selectedCategory) ? "No Products for selected Category" : `Select Category`}
                            filterOption={(input, option: BaseOptionType | undefined) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={changeCategory}
                            value={selectedCategory && !categories.find((c: { value: string }) => c.value === selectedCategory) ? undefined : selectedCategory}
                            suffixIcon={loadingCategories ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />}
                        >
                            {categories
                                ?.sort((a, b) => b.count - a.count)
                                .map((c: { [key: string]: string | number }, index) => (
                                    <Select.Option value={c.value} key={c.value}>
                                        {`${c.name} - ${numberWithCommas(c.count)}`}
                                    </Select.Option>
                                ))}
                        </Select>
                        <AutoComplete placeholder="Search" style={{ width: "360px", marginTop: "0.5rem" }} onSearch={handleSearchChange} allowClear />
                    </div>
                    <Radio.Group onChange={(e) => handleRadioBtnChange(e.target.value)} value={filterChecked} style={{ marginTop: "0.5rem" }}>
                        {countRadioBtns && (
                            <Space direction="vertical">
                                <Radio value={false}>Checked {countRadioBtns && `( ${numberWithCommas(countRadioBtns?.checked)} )`} </Radio>
                                <Radio value={true}>Not Checked {countRadioBtns && `( ${numberWithCommas(countRadioBtns?.not_checked)} )`} </Radio>
                                <Radio value={"all"}>All</Radio>
                            </Space>
                        )}
                    </Radio.Group>
                    <Card style={{ height: "95px", maxWidth: "150px", width: "100%" }}>
                        <Statistic title="Count" value={countRadioBtns ? numberWithCommas(showCount(countRadioBtns, filterChecked)) : ""} valueStyle={{ color: "green" }} />
                    </Card>
                </div>
                <Layout style={{ margin: "0.6rem 1rem" }}>
                    <Header className="product-mpn-header" />
                    {loading && (
                        <Spin tip="Loading" size="small" style={{ marginTop: "1.8rem" }}>
                            <div className="content" />
                        </Spin>
                    )}
                    <Table
                        rowKey={(record) => record.id}
                        dataSource={productMpn}
                        columns={columns}
                        bordered
                        className="edit-product-mpn-table"
                        components={components}
                        rowClassName={(record) => (record.mpn_checked ? "edit-product-mpn-change no-row-hover" : "")}
                        pagination={{
                            position: ["topRight"],
                            pageSize: 100,
                            current: page,
                            showSizeChanger: false,
                            total: count ? count : 0,
                            onChange: onPaginateChange,
                            size: "small",
                        }}
                        footer={() =>
                            !loading ? (
                                <Row justify="center">
                                    {productMpn?.length > 0 && (
                                        <Button type="primary" onClick={() => saveProductMpn()} loading={saveLoading}>
                                            Save
                                        </Button>
                                    )}
                                </Row>
                            ) : (
                                <div></div>
                            )
                        }
                    />
                </Layout>
            </Col>
        </Row>
    );
};

export default EditProductMpn;
