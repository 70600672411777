import { Alert, List, Row, Typography } from "antd";
import React from "react";
import numberWithCommas from "../../../../shared/utils/numberUtils";
import { ApplyToAllListProps } from "../../types/props";

const filters = ["Search", "Shops", "Categories", "Brands", "Exist In Xml", "New/Outlet", "User", "Country"];

const ApplyToAllList: React.FC<ApplyToAllListProps> = (props) => {
    return (
        <List
            header={<div>Parameters</div>}
            footer={
                <Row justify="end">
                    <Alert
                        description={
                            <span>
                                Number of Products:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {numberWithCommas(String(props.count).replace(".", ","))}
                                </span>
                            </span>
                        }
                        type="warning"
                    />
                </Row>
            }
            bordered
            dataSource={props.data}
            renderItem={(item, index) => (
                <List.Item style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <Typography.Text strong> {filters[index]}</Typography.Text>
                    {item ? item : "-"}
                </List.Item>
            )}
        />
    );
};

export default ApplyToAllList;
