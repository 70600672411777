import { Col, Divider, Empty, Row, Skeleton } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts'
import DatePickerFromToReports from './DatePickerFromToReports';
import { DatePickerNewProducts } from './NewShopProducts';
import moment from 'moment';
import '../style/AttributeStatistics.css'

const COLORS = ["#0088FE", "#00C49F"];

type NewAttributesType =  [
    {
        name:'priceAttributes',
        value: number
    },   
    {
        name:'productAttributes',
        value: number
    }
]

const AttributeStatistics = () => {
    const [ newAttributes, setNewAttributes ] = useState<NewAttributesType | null>(
        [
            {
                name:'priceAttributes',
                value: 0
            },   
            {
                name:'productAttributes',
                value: 0
            }
        ]
    )
    const [ loading, setLoading ] = useState(false)
    const [ date, setDate ] = useState<DatePickerNewProducts>({ startDate:new Date(), endDate:new Date()})
    useEffect(() => {
        let formTo:{startDate:string, endDate:string};
        if (date.endDate) {
            formTo = { 
                startDate:moment( date.startDate ).format("YYYY-MM-DD"), 
                endDate:moment( date.endDate ).format("YYYY-MM-DD")
            }
        }
        const getData = async () => {
            setLoading(true)
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/prices/attributes/show-daily-attribute-statistics?from=${ formTo.startDate }&to=${ formTo.endDate }`)
                setNewAttributes([
                    {
                        name:'priceAttributes',
                        value: data.new_price_attributes[0].count
                    },   
                    {
                        name:'productAttributes',
                        value: data.new_product_attributes[0].count
                    }
                ])
                setLoading(false)
            } catch ( err ) {
                console.log(err)
                setLoading(false)
            }
        }
        if (date.startDate && date.endDate) {
            getData()
        }
    }, [ date ])

    return (
        <React.Fragment>
            <Row style={{width:'80%', margin:'0 auto'}}>
                <Divider> Attribute Statistics </Divider>
                <DatePickerFromToReports 
                    date={date} 
                    setDate={setDate} 
                    isDate={true} 
                    position="bottom"
                />
            </Row>
            <Row justify="center">
                <Col>
                {!loading ?
                    newAttributes && ( newAttributes[0].value !== 0 || newAttributes[1].value !== 0 ) ? 
                    <PieChart width={400} height={400}>
                        <Pie
                            data={newAttributes}
                            dataKey="value"
                            cx={200}
                            cy={200}
                            innerRadius={0}
                            outerRadius={90}
                            fill="#82ca9d"
                            label
                        >
                            {newAttributes.map((entry, index) => 
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                            )}
                        </Pie>
                    </PieChart>:
                    <Row className='attribute-statistics-nodata'>
                        <Empty />
                    </Row>:
                    <Row className='attribute-statistics-skeleton-box'>
                        <Skeleton.Avatar 
                            active={true} 
                            size={'large'} 
                            className='attribute-statistics-skeleton' 
                            style={{ width:"180px", height:"180px" }} 
                            shape={'circle'} 
                        />
                    </Row>}
                    <Row style={{ display:'flex',width:'100%', marginLeft:'3rem'}}>
                        <div style={{ display:'flex' , flexDirection:'row'}}>
                            <div className="square-label attribute-statistics-square" ></div>
                            <span>New Price Attributes</span>
                        </div>
                        <div style={{ display:'flex' , flexDirection:'row'}}>
                            <div className="square-label attribute-statistics-square" style={{ backgroundColor:'#00C49F', marginLeft:"10px"}}></div>
                            <span>New Product Attributes</span>
                        </div>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default AttributeStatistics