import React, { useEffect, useState } from 'react';
import { Image, Row, Button, Col, Divider, Table, Tag, Rate, Input, Space, Layout } from 'antd';
import '../style/Comments.css'
import axios from 'axios';
import { CheckOutlined, CloseOutlined, DislikeOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';
import showMessage from '../../../shared/MessagesInfo/message';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../Store';
import HelmetTitle from '../../../shared/Head/HelmetTitle';

const TestPrices = () => {
  const [ comments, setComments ] = useState<any>([])
  const [ loading, setLoading ] = useState(true)
  const [ state, setState ] = useState({
    searchText: '',
    searchedColumn: ''
  })
  const countryState = useSelector((state: RootStore) => state)
   
  const currentCountryId =  countryState.dataLs.country ? countryState.dataLs.country.countryId:1
  const currentCountryName =  countryState.dataLs.country ? countryState.dataLs.country.currentCountry:"rs"
  
  const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
    confirm();
    setState({
        ...state,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }
  const handleReset = (clearFilters:any) => {
    clearFilters();
    setState({...state, searchText: '' });
  };
  let searchInput:any
  useEffect(() => {
      const getAllComments = async () => {
          try {
              const { data } = await axios.get(`${process.env.REACT_APP_URL_API }/product/comment/list?country_id=${ currentCountryId }`)
              const newData = data.data.data.map(( comment:any ) => {
                  return {
                    ...comment,
                  }
              })
              setComments(newData)
              setLoading(false)
              
          } catch ( err ) {
              console.log(err)
          }
      }
      getAllComments()
  }, [ currentCountryId ])
 
    
  const changeStatus = async (id:number, status:number) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/product/comment/update?id=${ id }&valid=${ status === 0 ? 1:0}`)
      console.log(data)
      const newData = comments.map((comment:any) =>{
        if ( comment.id === Number( data.data.id )) {
          return {
            ...comment,
            valid:status === 1 ? 0:1
          }
        }
        return comment
      })
      setComments(newData)
      showMessage(data.message)
    } catch ( err ) {
      console.log(err)
    }

  }
    const columns:any = [
      {
        title:'Product Id',
        dataIndex:'product_id',
        key:'product_id',
        align: 'center' as 'center',
        width:'8%',
        fixed:'left',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{
          setSelectedKeys: any;
          selectedKeys: any;
          confirm: any;
          clearFilters: any;
      }) =>  (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  searchInput = node;
                }}
                placeholder={`Search Product Id`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, 'product_id')}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, 'product_id')}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({ closeDropdown: false });
                    setState({
                      ...state,
                      searchText: selectedKeys[0],
                      searchedColumn: 'product_id',
                    });
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered:any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value:any, record:any) =>
            record['product_id']
              ? record['product_id'].toString().toLowerCase().includes(value.toLowerCase())
              : '',
              onFilterDropdownOpenChange: (visible:any) => {
            if (visible) {
              setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text:any) =>
          state.searchedColumn === 'product_id' ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          )
      },
      {
        title:'Image',
        dataIndex:'default_image_url',
        key:'default_image_url',
        align: 'center' as 'center',
        fixed:'left',
        render:( record:any ) => record ? <Image src={`https://img.ep-cdn.com/images/500/500/${record.default_image_url}`} width={50}  height={50}  />:'-'
      },
      {
        title:'Product',
        dataIndex:`name_${ currentCountryName }`,
        align: 'center' as 'center',
        fixed:'left',
        key:`name_${ currentCountryName }`
      },
      {
        title:'Cateogry',
        dataIndex: `category_name_${ currentCountryName }`,
        align: 'center' as 'center',
        fixed:'left',
        key: `category_name_${ currentCountryName }`
      },
     
      {
        title:'Comment',
        dataIndex:'comment',
        //fixed:'left',
        key:'comment',
        width:'30%'
      },
      {
        title:'Advantages',
        dataIndex:'advantages',
        align: 'center' as 'center',
        key:'advantages'
      },
      {
        title:'Flaws',
        dataIndex:'flaws',
        align: 'center' as 'center',
        key:'flaws'
      },
      {
        title:'User',
        dataIndex:'username',
        align: 'center' as 'center',
        key:'username',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{
          setSelectedKeys: any;
          selectedKeys: any;
          confirm: any;
          clearFilters: any;
      }) =>  (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                    console.log(node)
                  searchInput = node;
                }}
                placeholder={`Search user`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, 'username')}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, 'username')}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({ closeDropdown: false });
                    setState({
                      ...state,
                      searchText: selectedKeys[0],
                      searchedColumn: 'username',
                    });
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered:any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value:any, record:any) =>
            record['username']
              ? record['username'].toString().toLowerCase().includes(value.toLowerCase())
              : '',
              onFilterDropdownOpenChange: (visible:any) => {
            if (visible) {
              setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text:any) =>
          state.searchedColumn === 'username' ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          )
      },
      {
        title:'Email',
        dataIndex:'email',
        align: 'center' as 'center',
        key:'email',
        
      },
      {
        title:'IP',
        dataIndex:'ip',
        align: 'center' as 'center',
        key:'ip'
      },
      {
        title:'Created At',
        dataIndex:'created_at',
        align: 'center' as 'center',
        width:'7%',
        key:'created_at', render: (record: string) =>  record?  `${moment(record).format('DD MMM YYYY')} ${ moment(record).format('HH:mm') }` :'-',
        sorter: (a:any, b:any) => a.created_at.localeCompare(b.created_at)
      },
      {
        title:'Like',
        dataIndex:'like',
        align: 'center' as 'center',
        key:'like',
        sorter: (a:any, b:any) => a.like - b.like,
        render:(record:number) => <Tag color="green" style={{ margin:'0'}}>{ record }<LikeOutlined style={{ position:'relative', bottom:'3px', left:'5px' }} /></Tag> 
      },
      {
        title:'Unlike',
        dataIndex:'unlike',
        align: 'center' as 'center',
        key:'unlike',
        sorter: (a:any, b:any) => a.unlike - b.unlike,
        render:(record:number) => <Tag color="error" style={{ margin:'0'}}>{ record }<DislikeOutlined style={{ position:'relative', bottom:'1px', left:'5px' }} /></Tag> 
      },
      {
        title:'Recommend',
        dataIndex:'recommend',
        align: 'center' as 'center',
        key:'recommend',
        sorter: (a:any, b:any) => a.recommend - b.recommend
      },
      {
        title:'Rating',
        dataIndex:'rating',
        key:'rating',
        align: 'center' as 'center',
        width:'4%',
        sorter: (a:any, b:any) => a.rating - b.rating,
        render:(record:number) =><><span>{ record }</span> <Rate allowHalf defaultValue={record} disabled  /></>
      },
      {
        title:'Valid',
        dataIndex:'valid',
        align: 'center' as 'center',
        key:'valid',
        render:(text:string,record:any, index:number) => record.valid === 1 ?<Button size='small' onClick={ () => changeStatus(record.id, record.valid)}><CheckOutlined style={{ color:'green', position:'relative',bottom:'4px' }} /></Button> :<Button size='small' onClick={ () => changeStatus(record.id, record.valid)}><CloseOutlined style={{ color:'red', position:'relative',bottom:'4px' }} /></Button>
      },
    ]
    // if ( comments.length === 0 ) {
    //   return <div className="loading"> Loading&#8230;</div>
    // }
    return ( 
      <React.Fragment>
        <HelmetTitle title="Data Entry App - All comments" />  
        <Row justify='center'>
            <Col span={24} style={{ backgroundColor:'whitesmoke', padding:'1rem', margin:'1rem' }}>
                <Divider>Comments</Divider>
                <Layout style={{ margin:'0.6rem 1rem', backgroundColor:'white', height:'83vh', padding:'0.5rem' }} >
           
            <Layout.Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }} /> 
              
        
                {
                  comments && 
                  <Table
                    size='small'
                    bordered
                    className='shop-stores'
                    columns={ columns }
                    rowKey={(record:any) => record.id}
                    dataSource={ comments }
                    scroll={{ x:'1930px' }}
                    loading={loading}

                  />
                }
               </Layout>
            </Col>
        </Row>
      </React.Fragment>
    )
}

export default TestPrices;
