import { Button, Divider, Empty, Image, Modal, Row } from "antd";
import axios from "axios";
import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import { EansType } from "../../pages/EanOverview";
import EansTable from "./EansTable";

interface EansModalProps {
    eans: EansType;
    setEans: React.Dispatch<React.SetStateAction<EansType>>;
    setProductList: React.Dispatch<React.SetStateAction<Record<string, string | number>[]>>;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isModalOpen: boolean;
    getData: () => Promise<void>;
}

const EansModal = (props: EansModalProps) => {
    const [loadingRemoveEan, setLoadingRemoveEan] = useState<string | undefined>();
    const [loadingCheckProduct, setLoadingCheckProduct] = useState(false);
    const { eans, setEans, setProductList, isModalOpen, setIsModalOpen, getData } = props;

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const removeEanCreateProduct = async (ean: string) => {
        setLoadingRemoveEan(ean);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.delete(`${baseUrl}/product/ean-overview/remove-ean-and-offers?ean=${ean}`); // l3=229
            setEans((curr) => ({ ...curr, data: curr.data.filter((e) => e.code !== ean) }));
            showMessage(data.message);
            setLoadingRemoveEan(undefined);
            setProductList((curr) => curr.map((p) => (p.id === eans.product_id ? { ...p, ean_count: typeof p.ean_count === "number" ? p.ean_count - 1 : p.ean_count } : p)));
            getData();
        } catch (err) {
            console.log(err);
        }
    };

    const checkProduct = async (product_id: number | undefined) => {
        if (product_id) {
            setLoadingCheckProduct(true);
            try {
                const baseUrl = process.env.REACT_APP_URL_API;
                const { data } = await axios.post(`${baseUrl}/product/ean-overview/confirm-product-eans`, { productID: product_id }); // l3=229
                // (checked === "2" || checked === undefined) && setProductList((curr) => curr.filter((p) => p.id !== eans.product_id));
                getData();
                setLoadingCheckProduct(false);
                setIsModalOpen(false);
                showMessage(data.message);
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <Modal
            title={
                <>
                    <p style={{ textAlign: "center" }}>List of Eans</p>
                    <div style={{ display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "0.5rem", borderRadius: "5px", backgroundColor: "whitesmoke" }}>
                        <div style={{ width: "80px", height: "80px", border: "1px solid lightgray", borderRadius: "5px", padding: "0.2rem" }}>
                            <Image src={eans.eanInfo ? eans.eanInfo.image?.toString() : ""} />
                        </div>
                        <div style={{ marginLeft: "0.5rem" }}>
                            <span>
                                Id: <span style={{ color: "darkred", marginRight: "0.5rem" }}>{eans.product_id}</span>
                            </span>
                            <br />
                            <span>
                                Name:{" "}
                                <span className="ean-overview-name-link" onClick={() => window.open(eans.eanInfo.link.toString(), "_blank", "noreferrer")}>
                                    {eans.eanInfo.name}
                                </span>
                            </span>
                        </div>
                    </div>
                </>
            }
            open={isModalOpen}
            onCancel={handleCancel}
            width="100%"
            footer={false}
        >
            {eans.data.length > 0 ? (
                <Row gutter={16} style={{ border: "1px solid lightgray", padding: "0.5rem", borderRadius: "5px" }}>
                    {eans.data.map(
                        (en, i) =>
                            en.offers.length > 0 && (
                                <React.Fragment>
                                    {i !== 0 && i % 3 === 0 && <Divider />}
                                    <EansTable
                                        en={en}
                                        eans={eans}
                                        setEans={setEans}
                                        removeEanCreateProduct={removeEanCreateProduct}
                                        loadingRemoveEan={loadingRemoveEan}
                                        key={en.code}
                                        getData={getData}
                                    />
                                </React.Fragment>
                            )
                    )}
                </Row>
            ) : (
                <Empty description="No eans" />
            )}
            <Row justify="center">
                <Button type="primary" shape="round" style={{ marginTop: "1rem" }} onClick={() => checkProduct(eans.product_id)} loading={loadingCheckProduct}>
                    Checked
                </Button>
            </Row>
        </Modal>
    );
};

export default EansModal;
