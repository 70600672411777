import React, { useState, useEffect, useCallback} from 'react'
import { Table, Input, Popconfirm, Form, Typography, Button,Row, Col, Layout, Spin, Checkbox } from 'antd';
import '../style/MainMaps.css'
import { useDispatch, useSelector } from 'react-redux';
import  { RootStore } from '../../../Store';
import { shopMapById, shopMapsUpdateField } from '../../../actions/maps/mapsAction';
import NewShopMapField from '../components/NewField';
import HelmetTitle from '../../../shared/Head/HelmetTitle';
import '../style/EditShopMap.css'
import { ShopRulesType } from '../../shops/pages/ShopList';
import axios from 'axios';
import { SHOP_MAP_ALL } from '../../../actions/maps/mapActionType';
import showMessage from '../../../shared/MessagesInfo/message';
const { Header } = Layout

interface Item {
  key: string
  id:number
  key_name: string
  key_type: string
  required: boolean
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'select' | 'text' | 'boolean';
  record: Item;
  index: number;
  children: React.ReactNode;
}

interface EditShopMapProps {
  shopMap:ShopRulesType | null  
  isModalShopMap:boolean
  handleModalShopMapOk:()=>void
  setCounter:React.Dispatch<React.SetStateAction<number>>
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {

 //console.log(dataIndex)

 const setType = (dataIndex:any) => {
  switch (dataIndex) {
    case 'tag_delimiter':
      return <Input />
    case 'key':
      return <Input />
    case 'multiple_tags':
      return <Checkbox />
    default:
      break;
  }
 }
 const inputNode = setType(dataIndex)
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={ title === 'Multiple Tags' ? 'checked' : undefined}
          rules={[
            {
              required: title === 'Tag Delimiter' || title === 'Multiple Tags'  ? false : true,
              message: `Please insert ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
////////////////////////////////////


const EditShopMap:React.FC<EditShopMapProps> = ({ shopMap }) => {
  const [ form ] = Form.useForm();
  const [ editingKey, setEditingKey ] = useState<string | number>('');
  const dispatch = useDispatch()
  const shopState = useSelector((state:RootStore)=> state)
  const [ loading, setLoading ] = useState(false)
  //const param:any = useParams()
  const getShopMap = useCallback((newField:boolean) => {
    setTimeout(() => {
      
      dispatch(shopMapById(shopMap?.id, setLoading, newField))
    }, newField ? 600:0);
  },[ shopMap?.id, setLoading, dispatch ])

  useEffect(() => {
      if ( shopMap?.id ) {
        getShopMap(false) 
      }
  }, [ shopMap?.id/*, dispatch, counter*/,getShopMap  ])
  const isEditing = (record: Item) => record.id === editingKey;

  const edit = (record: any /*Partial<Item> & { key: React.Key }*/) => {
    //console.log(record)
    form.setFieldsValue({ ...record })
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id: any) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...shopState.shopMap.data];
      const index = newData.findIndex(item => item.id === id)
      dispatch(shopMapsUpdateField(id,row, index, newData, setEditingKey))
      
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }

  const deleteRowMap = async ( id:number ) => {
    try {
      const { data } = await axios.delete(`${ process.env.REACT_APP_URL_API }/maps/shop-map/delete-field?id=${ id }`)
      dispatch({type: SHOP_MAP_ALL,payload:{ data: shopState.shopMap.data.filter((d:{id:number}) => d.id !== id )}})
      showMessage(data.message)
    } catch ( err ) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: `Shops Key Name in feed`,
      dataIndex: 'key',
      width: '15%',
      editable: true,
  },
  {
      title: 'Our Key Name',
      dataIndex: 'key_name',
      width: '10%',
      editable: false,
  },
  {
      title: 'Tag Delimiter',
      dataIndex: 'tag_delimiter',
      width: '10%',
      editable: true,
      //render:(text:any, record:any) => <Input size='small' />
  },
  {
      title: 'Multiple Tags',
      dataIndex: 'multiple_tags',
      width: '10%',
      editable: true,
      align: 'center' as 'center',
      render:(record:boolean) => record ? 'Yes':'NO'
  },
  {
      title: 'Key Type',
      dataIndex: 'key_type',
      width: '5%',
      align: 'center' as 'center',
      editable: false,
  },
  ////////////
    {
        title: 'Required',
        dataIndex: 'required',
        width: '3%',
        align: 'center' as 'center',
        editable: false,
        render: (record: string) => String (record) === "true"? "Yes":"No"
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center' as 'center',
      width: '10%',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button size='small' danger onClick={() => save(record.id)} style={{ marginRight: 8 }}>Save</Button>
            <Popconfirm title="Are you sure?" onConfirm={cancel}>
              <Button size='small' type={"default"}>Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <Button size='small' /*type={"primary"}*/ type='primary' /*className='u-button'*/ >Edit</Button>
          </Typography.Link>
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center' as 'center',
      width: '10%',
      render: (_: any, record: Item) => 
           
            <Popconfirm title="Are you sure?" onConfirm={() => deleteRowMap(record.id)}>
              <Button size='small' type={"default"} danger>Delete</Button>
            </Popconfirm>
          
        
      
    }
    
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'key' ||  col.dataIndex === 'tag_delimiter' ? 'text' :  'boolean' ,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  ////////////----------modal------------
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  
  const showModal = () => {
    setIsModalVisible(true);
    //getAllShopField()
  }

  return (
    <React.Fragment>
      <HelmetTitle title="Data Entry App - Edit shop map" />  
        <Row justify={"center"}>
          {loading ? 
          <Row>
            <Col span={ 12 } offset={ 12 }>
            <Spin size='large' style={{ marginTop:'9rem',  minHeight:'87.5vh' }}>
              </Spin>
            </Col>
          </Row> :
          <Col span={22} style={{ backgroundColor:'white', padding:'1rem', marginTop:'1rem', minHeight:'74vh', msOverflowY:'auto' }}>   
            <Form form={form} component={false} >
            { shopState.shopMap.data &&
              <React.Fragment>
                <Layout style={{ margin:'0.6rem 1rem', backgroundColor:'white' }} >
                  <Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }} > 
                    <span style={{ position:'relative', right:'1.4rem' }} >
                    { shopState.shopMap.message }
                    </span> 
                    <NewShopMapField 
                      showModal={showModal}
                      isModalVisible={isModalVisible} 
                      setIsModalVisible={setIsModalVisible} 
                      shopState={shopState} 
                      shopId={shopMap?.id}
                      getShopMap={getShopMap}
                    />
                  </Header>
                  <Row justify='center'>
                    <Col span={24}>
                      <Table
                        components={{
                          body: {
                            cell: EditableCell,
                          },
                        }}
                        bordered
                        dataSource={shopState.shopMap.data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        style={{ marginTop:'1rem' }}
                        className="edit-shop-map-table"
                        pagination={{
                          onChange: cancel, pageSize:25,
                          hideOnSinglePage:true
                        }}
                        rowKey={(record:any) => record.id} 
                      /> 
                    </Col>
                  </Row>
                </Layout>
              </React.Fragment>}
            </Form>
          </Col>}
      </Row>
    </React.Fragment>
  )
}

export default EditShopMap

