import React, { useContext, useEffect, useRef, useState } from 'react'
import { AutoMatchedType } from '../../pages/AutoMatchedOP';
import { Form, Input, InputRef, Spin } from 'antd';
import type { FormInstance } from 'antd/es/form';
import showMessage from '../../../../shared/MessagesInfo/message';
import axios from 'axios';
import openNotification from '../../../../shared/MessagesInfo/WarningBox';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof AutoMatchedType;
    record: AutoMatchedType;
    handleSave: (record: AutoMatchedType) => void;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
};

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const [ loading, setLoading ] = useState(false)
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
  
    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
  
    const save = async () => {
        setLoading(true)
        try {
            const values = await form.validateFields();
            if (!values.product_destination_mpn || values.product_destination_mpn.length  > 3) {
               
                const obj = {
                    product: {
                        id: record.product_destination_id,
                        mpn: values.product_destination_mpn
                    }
                }
    
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/automatch/update-mpn`, obj )
                showMessage(data.message)
        
                toggleEdit();
                handleSave({ ...record, ...values });
                setLoading(false)
            }
            else {
                openNotification('Mpn word has to have 0 or more than 3 characters!')
                setLoading(false)
            }

        } catch (errInfo) {
            console.log('Save failed:', errInfo);
            openNotification('Save failed')
        }
    }
  
    let childNode = children;
  
    if (editable) {
      childNode =
      <div>
        <div className="editable-cell-value-wrap" style={{ cursor:'default' }}>{ record.price_source_mpn ? record.price_source_mpn : '-' }</div>
        {editing ? (
          <Form.Item
            style={{ margin: 0, backgroundColor:'#e4f1fe' }}
            name={dataIndex}
          >
            <Input 
              ref={inputRef} 
              suffix={ 
                loading ? 
                  <Spin 
                      size='small' 
                      indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin /> } 
                  />:<span />} 
              onPressEnter={save} 
              onBlur={save}  
            />
          </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap"  onClick={toggleEdit}>
                {
                    record.product_destination_mpn ? children : '-'
                }
            
            </div>
        )}
      </div>
    }
  
    return <td {...restProps}>{childNode}</td>;
  };


  export default EditableCell