export const SET_LS_CURRENT_COUNTRY = "SET_LS_CURRENT_COUNTRY"

export interface SetLSCurrentCountry {
    type: typeof SET_LS_CURRENT_COUNTRY
    payload:any
}


export const REMOVE_LS_CURRENT_COUNTRY = "REMOVE_LS_CURRENT_COUNTRY"

export interface RemoveLSCurrentCountry {
    type: typeof REMOVE_LS_CURRENT_COUNTRY
    payload:any
}
