import { Button, Divider, Form, Input, Modal, Row } from "antd";
import { saveCorrectWord } from "../api/apiData";
import { CorrectWordModalProp } from "../types/modals";

const CorrectWordModal: React.FC<CorrectWordModalProp> = (props) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        props.setIsModalCorrectWord(false);
        form.resetFields();
    };
    return (
        <Modal title="Add Correct Word" open={props.isModalCorrectWord} footer={false} onCancel={handleCancel}>
            <Divider style={{ margin: "0.1rem 0 0.9rem 0" }} />
            <Form
                form={form}
                onFinish={(e) => saveCorrectWord(e, props.currentCountryId, form)}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    name="misspelled_word"
                    label="Misspelled Word"
                    rules={[{ required: true, message: "Misspelled Word is required!" }]}
                >
                    <Input placeholder="Misspelled Word" />
                </Form.Item>
                <Form.Item
                    name="correct_word"
                    label="Correct Word"
                    rules={[{ required: true, message: "Correct Word is required!" }]}
                >
                    <Input placeholder="Correct Word" />
                </Form.Item>
                <Row justify="center">
                    <Button htmlType="submit" type="primary">
                        Save
                    </Button>
                </Row>
            </Form>
            <Divider style={{ margin: "0.9rem 0 0.3rem 0" }} />
        </Modal>
    );
};

export default CorrectWordModal;
