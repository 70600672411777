import { TreeSelect } from 'antd'
import React from 'react'
import '../style/OurCategories.css'
import { TreeCategoryType } from '../types/AllPricesTypes'

type OurCategoriesType = {
    levelCategories: TreeCategoryType[]
    setOurCategory: React.Dispatch<React.SetStateAction<{ level: string, id: string } | null>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const OurCategories:React.FC<OurCategoriesType> = ({ levelCategories, setOurCategory, setCurrentPage }) => {    
    const onChange = ( newValue: string | undefined ) => {
        setCurrentPage(1)
        const parseValue = typeof newValue === 'string' ?  newValue.split('::') : null
        setOurCategory( parseValue ?  {level: parseValue[0],id: parseValue[1]} : null)
    }

    return (
        <div className='our-cateogires-box'>
            <TreeSelect
                showSearch
                style={{ width: '500px' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Select Category"
                allowClear
                onChange={ onChange }
                treeData={ levelCategories }
                treeDefaultExpandAll={ false }
                filterTreeNode={(search:string, item: any) => item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0 }
            />
        </div>
    )
}

export default OurCategories