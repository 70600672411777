//---------get transform
export const TRANSFORM_GET = "TRANSFORM_GET"
export interface TransformGet {
    type: typeof TRANSFORM_GET
    payload:any
}
//---------price to db
export const TRANSFORM_PRICE_TO_DB = "TRANSFORM_PRICE_TO_DB"
export interface TransformPriceToDb {
    type: typeof TRANSFORM_PRICE_TO_DB
    payload:any
}

//---------feed parser, feed download
export const FEED_PARSER = "FEED_PARSER"
export interface FeedParser {
    type: typeof FEED_PARSER
    payload:any
}

//---------feed parser, feed download
export const ALL_TRANSFORM = "ALL_TRANSFORM"
export interface AllTransform {
    type: typeof ALL_TRANSFORM
    payload:any
}

//----------logs by shop
export const LOGS_BY_SHOP = "LOGS_BY_SHOP"
export interface LogsByShop {
    type: typeof LOGS_BY_SHOP
    payload:any
}