import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, Collapse, Tooltip, Typography } from 'antd';
import axios from 'axios';
import showMessage from '../../../shared/MessagesInfo/message';
import { PlusCircleOutlined } from '@ant-design/icons';
import '../style/CreateStore.css'
import { StoreType } from '../pages/ShopList';

interface CreateStoreType  {
    cities:  {
        id: number;
        name: string;
    }[]
    storeShop: any
    setStores:  React.Dispatch<React.SetStateAction<StoreType[]>>
}

const { Panel } = Collapse;

const dateExample = 
    <> 
        <span>Date Example:</span>
        <p>pon-sub: 9-21h, ned: 9-21h</p>
    </>

const CreateStrore: React.FC<CreateStoreType> = ({ cities, storeShop, setStores }) => {
    const [ form ] = Form.useForm()
    const [ loading, setLoading ] = useState(false)

    const onFinish = async (e:any) => {
        setLoading(true)
        const objToSend = {
            ...e, 
            shop_id:storeShop?.id,
            email: e.email ? e.email : null,
            phone: e.phone ? e.phone : null,
            work_hour: e.work_hour ? e.work_hour : null,
            latitude: e.latitude ? e.latitude : null,
            longitude: e.longitude ? e.longitude : null,

        }
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/store/create`, objToSend)
            showMessage(data.message)
            setStores(curr => [ data.data, ...curr ])
            form.resetFields()
            setLoading(false)
        } catch ( err ) {
            console.log(err)
        }
    }

    const addExampleDate = () => {
        form.setFieldsValue({work_hour:'pon-sub:9-21h, ned:9-21h'})
    }

    return (
        <Collapse ghost>
            <Panel 
                header={<Typography.Paragraph strong>Create Store</Typography.Paragraph>} 
                key="1" 
                collapsible='icon' 
            >
                <Form 
                    layout="inline" 
                    onFinish={onFinish} 
                    style={{ border:'1px solid lightgray', borderRadius:'8px', padding:'1rem', backgroundColor:'whitesmoke' }}
                    form={form}
                >
                    <Row gutter={16}>
                        <Col>
                            <Form.Item
                                label="City"
                                name="city_id"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input City!',
                                    },
                                ]}
                                labelCol={{ span: 24 }} 
                                style={{ margin:0 }}
                            >
                            <Select
                                showSearch
                                allowClear={undefined}
                                style={{ width: '250px', marginBottom:'0.5rem' }}
                                placeholder="Select City"
                                filterOption={(input, option:any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                    
                                {cities.length > 0 && cities.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((brand:any)=>
                                <Select.Option key={brand.id} value={brand.id}>{ brand.name }</Select.Option>)}
                            </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input address!',
                                    }
                                ]}
                                labelCol={{ span: 24 }} 
                                style={{ margin:0 }}
                            >
                                <Input placeholder='Address' />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Website"
                                name="website"
                                rules={[
                                    {
                                    required: true,
                                    message: "Please input Website!",
                                    },
                                ]}
                                style={{ margin:0 }}
                                labelCol={{ span: 24 }} 
                            >
                                <Input placeholder="Website" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                style={{ margin:0 }}
                                labelCol={{ span: 24 }} 
                            >
                                <Input placeholder='Phone' />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Email"
                                name="email"
                                style={{ margin:0 }}
                                labelCol={{ span: 24 }} 
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                       
                        <Col style={{ position:'relative' }}>
                            <Tooltip placement="topRight" title={dateExample}>
                                <PlusCircleOutlined onClick={addExampleDate} className='create-store-add-work-hour'/>
                            </Tooltip>
                            <Form.Item
                                label="Work Hour"
                                name="work_hour"
                                style={{ margin:0 }}
                                labelCol={{ span: 24 }} 
                            >
                                    <Input placeholder="Work Hour" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Latitude"
                                name="latitude"
                                style={{ margin:0, width:'150px' }}
                                labelCol={{ span: 24 }} 
                            >
                                <Input placeholder="Latitude" style={{ width:'150px'}} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Longitude"
                                name="longitude"
                                style={{ margin:0, width:'150px' }}
                                labelCol={{ span: 24 }} 
                            >
                                <Input placeholder="Longitude" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label=' ' labelCol={{ span: 24 }} wrapperCol={{ span: 24}} >
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    style={{ position:'relative', bottom:6 }} 
                                    loading={ loading }
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Form>

            </Panel>
        </Collapse>
    )
}


export default CreateStrore