import React, { useEffect } from 'react'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import { Col, Divider, Layout, Row, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../../Store'
import { getAllUsers } from '../../../actions/userAction/userAction'
import userColunms from '../components/UserColumns'
import { useSearchState } from '../../../shared/hooks/useSearchState'
const { Header } = Layout
export interface User {
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    created_at: string,
    updated_at: string
}

export type Columns = {
    title: string
    dataIndex: string 
    key: string
}  

const UsersList: React.FC = () => {
    const dispatch = useDispatch()
    const userState = useSelector(( state: RootStore ) => state.users )
    const { getColumnSearchProps } = useSearchState(userState.users?.data)

    useEffect(()=>{
        dispatch(getAllUsers(false, false))
    }, [ dispatch ])

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - User list" />
            <Row justify="center">
                <Col span={ 16 } style={{ backgroundColor:'white', padding:'1rem', marginTop:'1rem', height:'88vh' }}>
                    <Divider>User list</Divider>
                    <Layout style={{ margin:'0.6rem 1rem', backgroundColor:'white' }} >
                        <Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }}> 
                            <span style={{ position:'relative', right:'1.4rem' }} ></span> 
                        </Header>
                        <Table 
                            rowKey={ record => record.id } 
                            dataSource={ userState.users?.data } 
                            columns={ userColunms(getColumnSearchProps) }
                            size='small'
                            pagination={{ defaultPageSize:20 }}
                        /> 
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default UsersList
