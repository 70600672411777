import { Form, Select, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { capitalize } from '../../helper/firstLetter';

const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color="geekblue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ margin: 3 }}
        >
            {label}
        </Tag>
    )
}

type FormTagsType = {
    name:string
    datasource: {id: number, name: string}[]
}

const FormTags = ( props:FormTagsType ) => 
    <Form.Item 
        
        name={props.name}
        label={capitalize(props.name)}
        rules={[{ required: true, message: `Please input ${ props.name }` }]}
    >
        <Select
            mode="multiple"
            //showArrow
            tagRender={tagRender}
            
            style={{ width: '100%' }}
            options={props.datasource?.map( t => ({ value:t.id, label:t.name }))}    //to check this is it ok?
        />
    </Form.Item>

export default FormTags