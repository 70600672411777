import { ColumnType } from 'antd/lib/table';
import moment from 'moment';


const userColunms = (getColumnSearchProps:(dataIndex: any) => ColumnType<any>) => {
    return [
        {
            title: '#',
            dataIndex: "id",
            align: "center" as "center",
            render: ( text:any, record:any, index:any ) => index + 1
            
        },
        {
            title: "User ID",
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            
        },
        {
            title: "First name",
            dataIndex: "first_name",
            key: "first_name",
            ...getColumnSearchProps('first_name')
        },
        {
            title: "Last name",
            dataIndex: "last_name",
            key: "last_name"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center" as "center",
            render: (record: string) => //parseDate(record)
                record ? 
                    `${moment(record).format("DD MMM YYYY")} ${moment(record).format("HH:mm")}`: "-"
        },
        {
            title: "Updated at",
            dataIndex: "updated_at",
            key: "updated_at",
            align: "center" as "center",
            render: (record: string) => 
                record ? 
                    `${moment(record).format("DD MMM YYYY")} ${moment(record).format("HH:mm")}`: "-"
        },
        // {
        //     title: "Actions",
        //     dataIndex: "id",
        //     key: "delete_user",
        //     render: (record: number) => (
        //         <Radio.Group value='large'>
        //             <Radio.Button
        //                 value="default"
        //                 style={{ marginLeft: '3px' }}
        //             >
        //                 <Link type="primary" to={`/edit/${record}`}>
        //                     Edit
        //                 </Link>
        //             </Radio.Button>
        //         </Radio.Group>
        //     )
        // }
    ]
}

export default userColunms