import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Input, Layout, Radio, RadioChangeEvent, Row, Select, Space, Typography } from 'antd'
import axios from 'axios'
import React, { useState, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategoryL3 } from '../../../actions/category/categoryActions'
import CategorySelect, { CateogoryL3L4 } from '../../../shared/FormElements/CategorySelect'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import showMessage from '../../../shared/MessagesInfo/message'
import openNotification from '../../../shared/MessagesInfo/WarningBox'
import { RootStore } from '../../../Store'
//import { CategoryType } from '../../attributes/pages/AttirbutesOverview'
const { Header } = Layout
const { Text } = Typography

type ShowCategoryDataType = {
  not_contain: string
  suffix: string
  prefix: string
  id?: number 
  country_id: number
  updated_at?: string
  created_at?: string
  isChanged?: boolean
}

const CategoryPrefSuf = () => {
  const [ categories, setCategories ] = useState<CateogoryL3L4[]>([])
  const [ selectedCategory, setSelectedCateogry ] = useState<{id:number, name:string } | null>(null)
  const [ showCategoryData, setShowCateogryData ] = useState<ShowCategoryDataType[] | null>(null)
  const [ isPrefix, setIsPrefix ] = useState<any>({rs: true, si: true, hr: true, ba:true, me:true})
  const state = useSelector(( state:RootStore ) => state)
  const dispatch = useDispatch()
  const currentCountryName =  state.dataLs.country ? state.dataLs.country.currentCountry:"rs"
  const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1

  useEffect(()=>{
    dispatch(getAllCategoryL3(currentCountryId))
  }, [ dispatch, currentCountryId])
  
  useEffect(() => {
    setCategories(state.categoriesL3.data && state.categoriesL3.data
      .sort((a:CateogoryL3L4,b:CateogoryL3L4) => (a[`name_${currentCountryName}` as keyof CateogoryL3L4] > b[`name_${currentCountryName}` as keyof CateogoryL3L4]) ? 1 : ((b[`name_${currentCountryName}`as keyof CateogoryL3L4] > a[`name_${currentCountryName}` as keyof CateogoryL3L4]) ? -1 : 0)))
  }, [ state.categoriesL3.data, currentCountryName ])

  useEffect(() => {
    //setIsPrefix({rs:true, si:true, hr:true})
    const getSelectedCategory = async () => {
      try {
        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/category-mapping/l3-suffix?category_l3_id=${ selectedCategory?.id }`)
        const newData:ShowCategoryDataType[] = data.data.map((item:ShowCategoryDataType,index:number) =>{ 
          //console.log(data.data)
          data.data.forEach((c:{country_id:number}) => {
            console.log(c.country_id)
            setIsPrefix((curr:any) => ({
              ...curr, 
              [`${state?.countries.find( country => country.value === c.country_id)?.name}`]:  item.prefix ? true : false
            }))   
          })
          return {...item , not_contain:item.not_contain ? JSON.parse( item.not_contain ):[], category_l3_id:selectedCategory?.id, isChanged:false }
          
        })
        const allCountry = state?.countries.flatMap( country =>  ({id:3000, not_contain:'', suffix:'', prefix:'', country_id: country.value, isChanged:false, category_l3_id:selectedCategory?.id}))
        setShowCateogryData( allCountry.map( countryData => {
          const findData = newData.find( d => d.country_id === countryData.country_id) 
          if (findData) {
            return findData
          }
          else {
            return countryData
          }
        }))

      } catch ( err ) {
        console.log(err)
      }

    }
    selectedCategory && getSelectedCategory()
  }, [ selectedCategory, state?.countries ])


  const handleChangeSuffix = (e:ChangeEvent<HTMLInputElement>, id:number, type:string) => {
    setShowCateogryData( curr =>curr ? curr.map( d => d.country_id === id ? ({...d, [type]:e.target.value, isChanged:true}):d):[] )
  }

  const handleChange = (e:string, id:number) => {
    setShowCateogryData( curr =>curr ? curr.map( d => d.country_id === id ? ({...d, not_contain:e, isChanged:true}):d):[] )
  }

  const saveNewCategorySuffix = async (id:number) => {
    const findData = showCategoryData?.find( d => d.country_id === id )
   
    delete findData?.id
    delete findData?.updated_at
    delete findData?.created_at
    delete findData?.isChanged
    try {
      const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/category-mapping/upsert-category-suffixes`, findData)
      showMessage(data.message)
      setShowCateogryData( curr =>curr ? curr.map( d => d.country_id === id? ({...d,  isChanged:false}):d):[] )
      console.log(data)
    } catch ( err ) {
      console.log(err)
      openNotification("It can be set only prefix or suffix!")
    }
  }

  const onRadioChange = (e:RadioChangeEvent, country:string) => {
    setIsPrefix( (curr:any) => ({...curr, [country]:e.target.value}))
  }

  const onCategoryChange = (e:number) => {
    //console.log(state.categoriesL3.data)
    state.categoriesL3.data.forEach( (category3:any ) => {
        if( category3.id === e) {
            setSelectedCateogry({id:category3.id, name:category3[`name_${ currentCountryName }` ]})
            return 
        }
        category3?.children.length > 0 &&
        category3.children.forEach((categoryl4:any) => {
            if( categoryl4.id === e) {
                setSelectedCateogry({id:categoryl4.id, name:categoryl4[`name_${ currentCountryName }`]})
                return 
            }
        })
    })
  }

  return (
    <React.Fragment>
      <HelmetTitle title="Data Entry App - Category prefix/suffix" /> 
      <Row justify='center'>
        <Col span={/*windowSize.width > 1700 ? 23 :*/23}  style={{ backgroundColor:'white', padding:'1rem', marginTop:'1rem' }} >
          <Divider>Cateogry prefix / suffix</Divider>
          <div className='product-img-info-bar' style={{ display:'flex', justifyContent:'center'}}>
            <CategorySelect 
              categoryL3Options={categories} 
              currentCountry={currentCountryName} 
              onCategoryChange={onCategoryChange} 
              style={{ width:'300px' }}  
            /> 
          </div>
        </Col>
        <Col span={23} style={{  backgroundColor:'white'}}>
          <Layout style={{ margin:'0.6rem 1rem', height:'72vh' }}>
            <Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }}>
              {selectedCategory?.name &&
              <span style={{ position:'relative', right:'1.4rem' }} >
                {`Suffix for Category - `} <i style={{ fontSize:'16px' }}>{ `${ selectedCategory?.name}`}</i> 
              </span>}   
            </Header>

            { selectedCategory && <Row gutter={[32, 24]} justify='center' style={{ marginTop:'4rem', padding:'1.5rem' }}>
            {state?.countries?.map( country => 
             
              <Col span={8} key={country.name}>
                <Card 
                  title={ <Text code style={{ fontSize:"24px" }}>{ country.name.toUpperCase() }</Text> }  
                  extra={ selectedCategory ?
                    <Button 
                      type="default"  
                      // disabled={ 
                      //   showCategoryData?.find( data => 
                      //     data.country_id === country.value)?.suffix && 
                      //     showCategoryData?.find( data => data.country_id === country.value)?.not_contain.length === 0 ? 
                      //     true:false 
                      // } 
                      onClick={() => saveNewCategorySuffix(country.value)}
                    >
                      Save { showCategoryData?.find( data => data.country_id === country.value)?.isChanged && <ExclamationCircleOutlined style={{ color:'darkred'}} /> }
                    </Button>:undefined} 
                  style={{ borderRadius:'6px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}
                >
                  {isPrefix[country.name] ?
                  <div style={{ display:'flex', justifyContent:"center" }}>

                    <label style={{ marginRight:'0.3rem', marginTop:"1rem"}}>Prefix:</label>
                    <Input 
                      value={showCategoryData?.find( data => data.country_id ===country.value)?.prefix}  
                      style={{ width: '400px', marginTop:'10px' }} 
                      placeholder="Prefix text" 
                      onChange={(e) => handleChangeSuffix(e, country.value,'prefix') }
                    />
                  </div>:
                  <div style={{ display:'flex', justifyContent:"center" }}>
                    <label style={{ marginRight:'0.3rem', marginTop:"1rem"}}>Suffix:</label>
                    <Input 
                      value={showCategoryData?.find( data => data.country_id === country.value)?.suffix}  
                      style={{ width: '400px', marginTop:'10px' }} 
                      placeholder="Suffix text" 
                      onChange={(e) => handleChangeSuffix(e, country.value,'suffix') }
                    />
                  </div>
                  }
                  <div style={{ position:"absolute", right:'10px', top:"80px" }}>
                  {selectedCategory && showCategoryData &&
                  <Radio.Group 
                    onChange={(e) =>onRadioChange(e, country.name)} 
                    //defaultValue={showCategoryData?.find( data => data.country_id === index +1)?.prefix ? true:false}
                    value={isPrefix[country.name]}
                  >
                    <Space direction="vertical">
                      <Radio value={true}>Prefix { showCategoryData?.find( data => data.country_id === country.value)?.prefix ? '+': '' }</Radio>
                      <Radio value={false}>Suffix { showCategoryData?.find( data => data.country_id === country.value)?.suffix ? '+': '' }</Radio>
                    </Space>
                  </Radio.Group>}
                  </div>
                  <div style={{ display:'flex', justifyContent:"center", marginRight:"2.3rem" }}>

                    <label style={{ marginRight:'0.3rem', marginTop:"1rem" }}>No Contain:</label>
                    <Select 
                      mode="tags" 
                      style={{width: '400px', color:'green', marginTop:'10px' }} 
                      onChange={(e) => handleChange(e, country.value)} 
                      tokenSeparators={[',']}
                      placeholder="Not Contain"
                      value={  showCategoryData?.find( data => data.country_id === country.value)?.not_contain ? showCategoryData?.find( data => data.country_id === country.value)?.not_contain:undefined }
                      //size='small'
                    />
                  </div>

                </Card>
              </Col> )}
            
            </Row>}
          </Layout>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CategoryPrefSuf