import { MAIN_MAP_ADD_FIELD, MAIN_MAP_ALL, MAIN_MAP_OPTIONS, MAIN_MAP_UPDATE_FIELD, SHOP_MAP_ALL, SHOP_MAP_CLEAR, SHOP_MAP_NEW_FIELDS, SHOP_MAP_UPDATE_FIELD } from "../actions/maps/mapActionType";

export const mainMapReducer = ( state:any=[], action:any ) => {
    switch ( action.type ) {
        case MAIN_MAP_ALL :
            return action.payload
        case MAIN_MAP_UPDATE_FIELD :
            //console.log(action.payload.data)
            /*proveri ovde sta se menja */
            return {...state, data:state.data.map((main_map:any)=> main_map.id ===Number(action.payload.data.id) ? action.payload.data:main_map)}
        case MAIN_MAP_OPTIONS :
            return state
        case MAIN_MAP_ADD_FIELD:
            const newObject = action.payload.data
            return {...state,data:[...state.data, newObject]}
        default:
            break;
    }
    return state
}

export const shopMapReducer = ( state:any=[], action:any ) =>{
    switch ( action.type ) {
        case SHOP_MAP_ALL:
            return action.payload
            case SHOP_MAP_CLEAR:
                return action.payload
            
        case SHOP_MAP_NEW_FIELDS:
            return state                       //da se promeni
        case SHOP_MAP_UPDATE_FIELD:
            return {...state, data:state.data.map((shop_map:any)=> shop_map.id ===Number(action.payload.data.id) ? 
                {
                    ...shop_map,
                    key_name:shop_map.key_name,
                    key:action.payload.data.key,
                    tag_delimiter: action.payload.data.tag_delimiter,
                    multiple_tags: action.payload.data.multiple_tags
                }
                :shop_map)}
        default:
            break;
    }
    return state
}