import { CheckCircleFilled, CloseCircleFilled, LinkOutlined } from "@ant-design/icons";
import { Descriptions, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { PriceType } from "../../../types/type";
import { RootStore } from "../../../../../Store";

interface ProductHistoryPrice {
    productPrice: PriceType | undefined;
    isPricesModalVisible: boolean;
    currentCountryId: number;
    handleCancel: () => void;
    stateProduct: RootStore;
}

const PriceModal: React.FC<ProductHistoryPrice> = ({ productPrice, isPricesModalVisible, handleCancel, currentCountryId, stateProduct }) => {
    const modalRef: any = useRef(null);
    useEffect(() => {
        if (isPricesModalVisible && modalRef.current) {
            modalRef.current.focus(); // Preusmerava fokus na modal
        }
    }, [isPricesModalVisible]);

    const currency = stateProduct.countries.find((c) => c.value === Number(currentCountryId))?.currency;

    return (
        <React.Fragment>
            {productPrice && (
                <Modal
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>
                                {`${productPrice.shop_name} `}
                                <LinkOutlined style={{ color: "#00bbf0", position: "relative", bottom: "3px" }} onClick={() => window.open(productPrice.product_url, "_blank")} />
                            </span>
                            <span style={{ fontSize: "20px", fontWeight: "normal", marginRight: "2rem" }}>
                                {productPrice.price}
                                <span style={{ fontSize: "14px" }}> {currency?.toLowerCase()}</span>
                            </span>
                        </div>
                    }
                    open={isPricesModalVisible}
                    onCancel={handleCancel}
                    footer={false}
                    width={500}
                    style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "0.5rem", backgroundColor: "whitesmoke" }}
                >
                    <div ref={modalRef} tabIndex={-1}>
                        <img src={productPrice.seller_image} alt="Price" style={{ display: "block", margin: "auto" }} width={80} />

                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "1rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label={<span>Price ID </span>}>
                                {productPrice.id}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Sku">
                                {productPrice.sku ? productPrice.sku : "-"}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Ean">
                                {productPrice.ean ? productPrice.ean : "-"}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Mpn">
                                {productPrice.mpn ? productPrice.mpn : "-"}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Processed">
                                {productPrice.processed === 0 ? <CloseCircleFilled style={{ color: "#da5151" }} /> : <CheckCircleFilled style={{ color: "#42b883" }} />}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Availability">
                                {productPrice.availability === 0 ? <CloseCircleFilled style={{ color: "#da5151" }} /> : <CheckCircleFilled style={{ color: "#42b883" }} />}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title={""} layout="horizontal" bordered style={{ marginTop: "0.3rem" }} size="small">
                            <Descriptions.Item style={{ width: "200px" }} label="Active for website">
                                {productPrice.active_for_website === 0 ? <CloseCircleFilled style={{ color: "#da5151" }} /> : <CheckCircleFilled style={{ color: "#42b883" }} />}
                            </Descriptions.Item>
                        </Descriptions>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginTop: "1rem" }}>
                            <p>
                                <b>First Appeared:</b> {moment(productPrice.created_at).format("DD MMM YYYY, HH:MM")}
                            </p>
                            <p>
                                <b>Last Updated:</b> {moment(productPrice.updated_at).format("DD MMM YYYY, HH:MM")}
                            </p>
                        </div>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default PriceModal;
