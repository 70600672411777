import { combineReducers } from 'redux'
import userReducer, { loginUser, userMyAcReducer } from '../reducers/userReducer'
import { attributeItemReducer, attributeReducer } from './attributeReducer'
import { brandReducer } from './brandReducer'
import { categoryL1Reducer } from './categoryL1Reducer'
import { categoryL3L4Reducer, categoryL3Reducer } from './categoryL3Reducer'
import { lsReducer } from './localeStoregeReducer'
import { logReducer } from './logReducer'
import { mainMapReducer, shopMapReducer } from './mainMapReducer'
import { productReducer } from './productsReucer'
import { shopById, shopReducer, shopRuleByIdReducer, shopRulesExistReducer, shopRulesReducer } from './shopReducer'
import { allTransformLogsReducer, transformReducer } from './transformReducer'
import { countriesReducer } from './countriesReducer'

const rootReducer = combineReducers({
    users: userReducer,
    loggedInUser: loginUser,
    user: userMyAcReducer,
    shops: shopReducer,
    shop:shopById,
    shopRulesExist: shopRulesExistReducer,
    shopRules: shopRulesReducer,
    shopRule: shopRuleByIdReducer,
    mainMap: mainMapReducer,
    shopMap: shopMapReducer,
    transform: transformReducer,
    transformLogs: allTransformLogsReducer,
    logsByShop: logReducer,
    categoriesL3: categoryL3Reducer,
    categoriesL1: categoryL1Reducer,
    brands: brandReducer,
    attributes: attributeReducer,
    atribute: attributeItemReducer,
    products: productReducer,
    categoryL3L4: categoryL3L4Reducer,
    dataLs:lsReducer,
    countries: countriesReducer
})

export default rootReducer