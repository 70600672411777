import { Form, DatePicker } from 'antd'
import { capitalize } from '../../helper/firstLetter'
//import moment from 'moment'

type FormDatePickerType = {
   name:string
}

const { RangePicker } = DatePicker

const FormDatePicker = (props:FormDatePickerType) => 
    <Form.Item 
        name={props.name}
        label={capitalize(props.name)}
        rules={[{ required: true, message: `Please input date` }]}
    >
        <RangePicker 
            placeholder={["Valid from","Valid to"]} 
            format="DD-MM-YYYY"
           
            style={{ width:'100%' }}
            //disabledDate={(current:any) => current.isBefore(moment().subtract(1,"day"))}
        />
    </Form.Item>

export default FormDatePicker