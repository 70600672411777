import axios from "axios";
import { ProductType } from "../types/types";
import showMessage from "../../../shared/MessagesInfo/message";

export const moveImage = async (matchingProduct: ProductType, product: ProductType, setMatchingProducts: React.Dispatch<React.SetStateAction<ProductType[]>>) => {
    const url = matchingProduct.image;
    const startIndex = url.lastIndexOf("/") - 20;
    const endIndex = url.lastIndexOf("/");
    const extractedString = url.substring(startIndex, endIndex);
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/image/set-default-image?product_id=${product.product_id}&name=${extractedString}&transfer=1`);
        showMessage(data.message);
        const originalString = extractedString;
        const prefix = originalString.substring(0, 2);
        const result = `${prefix}/${originalString}`;
        const image = `https://img.ep-cdn.com/images/500/500/${result}.webp`;

        setMatchingProducts((curr) => curr.map((p) => (p.product_id === product.product_id ? { ...p, image: image } : p)));
    } catch (err) {
        console.log(err);
    }
};
