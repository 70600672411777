import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Divider,
  Button,
  Popover,
  Switch,
  Alert,
} from "antd";
import {
  CopyOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useClipboard } from "use-clipboard-copy";
import "../style/ShopTable.css";
import moment from "moment";
import axios from "axios";
import { feedParser } from "../../../actions/transformAction/transformAction";
import TransformModalInfo from "../components/TransformModalInfo";
import CreateShopRulesModal from "./CreateShopRulesModal";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllShops } from "../../../actions/shopAction/shopAction";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import CreateShopMapModal from "./CreateShopMapModal";
import CreateShopDrawer from "./CreateShopDrawer";
import EditShopDrawer from "./EditShopDrawer";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import StoreList from "./StoreList";

export interface IValues {
  [key: string]: any;
}

export interface IState {
  id: number;
  //company: CompanyObject,
  shops: ShopObject[];
  values: IValues[];
  submitSuccess: boolean;
  loading: boolean;
  message: string;
  name: string;
  address: string;
  city: string;
  phone: string;
  email: string;
  country_name: string;
  homepage_url: string;
  feed_url: string;
  active: boolean;
  shop_rules_exist: number;
  shop_maps_exist: number;
  created_at: string;
  updated_at: string;
  isTransform?: boolean;
  isInsertProducts?: boolean;
  //name:string
}

export type ShopObject = {
  id: number;
  company_id: string | number;
  key?: any;
  created_at?: string;
  country_id?: boolean | string;
  feed_url: string;
  homepage_url: string;
  name: string;
  updated_at?: string;
  shop_rules_exist?: number;
  note_on_website: string;
  email: string;
  etrustmark:number
};

export type StoreType = {
  id: number
  city: string 
  city_id: number
  address: string 
  phone: string 
  email: string 
  website: string 
  work_hour: string 
  coordinates: string 
}

export type ShopRulesType = { id: number; status: string; shop_name: string };
export type ShopMapType = { id: number; status: string; shop_name: string };

const ShopList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [shopList, setShopList] = useState<IState[]>([]);
  const [transformState, setTransformState] = useState<{ transform: {} }>({
    transform: [],
  });
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ counter, setCounter ] = useState(0);
  const [ shopRules, setShopRules ] = useState<ShopRulesType | null>(null);
  const [ shopMap, setShopMap ] = useState<ShopMapType | null>(null);
  const [ editShop, setEditShop ] = useState<ShopMapType | null>(null);
  const [ storeModal, setStoreModal ] = useState(false)
  const [ stores, setStores ] = useState<StoreType[]>([])

  const shopState: {
    shops: { data: IState[] };
    dataLs: { country: { countryId: number } };
  } = useSelector((state: RootStore) => state);
  const dispatch = useDispatch();
  const [sfActive, setSfActive] = React.useState(1);
  const currentCountryId = shopState.dataLs.country
    ? shopState.dataLs.country.countryId
    : 1;
  //--------------modals for shop rules and shop maps----------------//

  const [isModalRules, setIsModalRules] = useState(false);
  const [isModalShopMap, setIsModalShopMap] = useState(false);
  const [isModalCreateShop, setIsModalCreateShop] = useState(false);
  const [isDrawerEditShop, setIsDrawerEditShop] = useState(false);
  const { getColumnSearchProps } = useSearchState(shopList)
  const handleModalRulesOk = () => {
    setIsModalRules(false);
  };

  const handleModalRulesCancel = () => {
    setIsModalRules(false);
  };

  const handleModalShopMapOk = () => {
    setIsModalShopMap(false);
  };

  const handleModalShopMapCancel = () => {
    setIsModalShopMap(false);
  };

  const handleModalCreateShopOk = () => {
    setIsModalCreateShop(false);
  };

  const handleModalCreateShopCancel = () => {
    setIsModalCreateShop(false);
  };

  const handleDrawerEditShopOk = () => {
    setIsDrawerEditShop(false);
    setEditShop(null)
  };

  const handleDrawerEditShopCancel = () => {
    setIsDrawerEditShop(false);
    setEditShop(null)
  };

  useEffect(() => {
    dispatch(getAllShops(sfActive, currentCountryId, setLoading ));
  }, [dispatch, sfActive, currentCountryId, counter]);

  useEffect(() => {
    shopState.shops.data &&
      setShopList(
        shopState.shops.data.map((shop) => ({
          ...shop,
          isTransform: false,
          isInsertProducts: false,
        }))
      );
  }, [shopState.shops.data, counter]);
  const [alertInfo, setAlertInfo] = useState({
    alert1: false,
    alert2: false,
  });
  const clipboard1 = useClipboard({
    onSuccess() {
      setAlertInfo({ ...alertInfo, alert1: true });
      setTimeout(() => {
        setAlertInfo({ alert1: false, alert2: false });
      }, 200);
    },
  });
  const clipboard2 = useClipboard({
    onSuccess() {
      setAlertInfo({ ...alertInfo, alert2: true });
      setTimeout(() => {
        setAlertInfo({ alert1: false, alert2: false });
      }, 200);
    },
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  const sorter = (a: any, b: any) =>
    isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b;

  const openNewTab = (url: string, type: string) => {
    if (type === "homepage") {
      window.open(`https://` + url, "_blank");
    }
    if (type === "feed") {
      window.open(url, "_blank");
    }
  };

  const sortTable = (a: string, b: string, type: string) => {
    if (type === "date") {
      if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
      if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
      return 0;
    }
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };

  const startTransform = async (id: number) => {
    setShopList(
      shopState.shops.data.map((shop) =>
        shop.id === id ? { ...shop, isTransform: true } : shop
      )
    );
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_API}/maps/transform?shop_id=${id}`
      );
      setTransformState({ transform: data });
      setShopList(
        shopState.shops.data.map((shop) => ({ ...shop, isTransform: false }))
      );
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
      setShopList(
        shopState.shops.data.map((shop) => ({ ...shop, isTransform: false }))
      );
      setIsError(true);
      setTimeout(() => {
        setIsModalOpen(true);
      }, 300);
    }
  };

  const openShopRulesModal = (record: {
    id: number;
    shop_rules_exist: number;
    name: string;
  }) => {
    setShopRules({
      id: record.id,
      status: record.shop_rules_exist === 1 ? "Edit" : "Create",
      shop_name: record.name,
    });
    setIsModalRules(true);
  };

  const openShopMapModal = (record: {
    id: number;
    shop_maps_exist: number;
    name: string;
  }) => {
    setShopMap({
      id: record.id,
      status: record.shop_maps_exist === 1 ? "Edit" : "Create",
      shop_name: record.name,
    });
    setIsModalShopMap(true);
  };

  const openEditShopDrawer = (record: { id: number; name: string }) => {
    setEditShop({ id: record.id, status: "Edit", shop_name: record.name });
    setIsDrawerEditShop(true);
  };

  const [ storeShop, setStoreShpo ] = useState<ShopObject | null>(null)

  const openStoreModal = async (record:ShopObject) => {
    //console.log(record)
    try {
      const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/store/shop-store-list?shop_id=${record?.id}`)
      setStores(data.data)
      setStoreShpo(record)
      setStoreModal(true)
    } catch ( err ) {
      console.log(err)
    }
  }

  // const storeModalCancel = () => {
  //   setStoreModal(false)
  // }

  const columns: any = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      fixed: "left",
      sorter: (a: IState, b: IState) => sorter(a.id, b.id),
      ...getColumnSearchProps('id')
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: "10%",
      sorter: (a: IState, b: IState) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name')
    },
    {
      title: "Info",
      dataIndex: "country_name",
      key: "country_name",
      align: "center" as "center",
      width: "4%",
      render: (text: string, record: IState, index: number) => (
        <Popover
          content={
            <div>
              <p>{`${record.address} ${record.city}, ${record.country_name}`}</p>
              <p>Phone: {record.phone}</p>
              <p>Email: {record.email}</p>
            </div>
          }
        >
          <span style={{ cursor: "pointer", color: "skyblue" }}>
            <InfoCircleOutlined />
          </span>
        </Popover>
      ),
    },
    {
      title: "Edit shop",
      dataIndex: "id",
      key: "delete_user",
      align: "center" as "center",
      render: (_: "stirng", record: { id: number; name: string }) => (
        // <Link type="primary" to={`/edit-shop/${record}`}>
        <Button
          value="default"
          style={{ marginLeft: "3px" }}
          size="small"
          onClick={() => openEditShopDrawer(record)}
        >
          Edit
        </Button>
        // </Link>
      ),
    },
    {
      title: "Stores",
      dataIndex: "id",
      key: "shop_stores",
      align: "center" as "center",
      render: (_:number,record: ShopObject) => (
        // <Link type="primary" to={`/shop-store/${record}`}>
          <Button value="default" style={{ marginLeft: "3px" }} size="small" onClick={() => openStoreModal(record) }>
            Stores
          </Button>
        // </Link>
      ),
    },
    {
      title: "Homepage/Feed URL",
      dataIndex: "feed_url",
      key: "feed_url",
      align: "center" as "center",
      width: "12%",
      className: "redirect-center",
      render: (text: string, record: IState, index: number) => {
        return record.homepage_url || record.feed_url ? (
          <Popover
            content={
              <div>
                <input
                  ref={clipboard1.target}
                  readOnly
                  value={record.homepage_url}
                  style={{ display: "none" }}
                />
                <input
                  ref={clipboard2.target}
                  readOnly
                  value={record.feed_url}
                  style={{ display: "none" }}
                />
                <div style={{ position: "relative" }}>
                  {alertInfo.alert1 && (
                    <Alert
                      className="url-clipboard-home-url"
                      message="Copy"
                      type="info"
                    />
                  )}
                  <div style={{ display: "flex" }}>
                    <p
                      className="homepage-feed-url-link"
                      onClick={() =>
                        openNewTab(record.homepage_url, "homepage")
                      }
                    >
                      {record.homepage_url}
                    </p>{" "}
                    <CopyOutlined
                      className="copy-clipboard-icon"
                      onClick={clipboard1.copy}
                    />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  {alertInfo.alert2 && (
                    <Alert
                      className="url-clipboard-feed-url"
                      message="Copy"
                      type="info"
                    />
                  )}
                  <div style={{ display: "flex" }}>
                    <p
                      className="homepage-feed-url-link"
                      onClick={() => openNewTab(record.feed_url, "feed")}
                    >
                      {record.feed_url}
                    </p>{" "}
                    {record.feed_url && (
                      <CopyOutlined
                        className="copy-clipboard-icon"
                        onClick={clipboard2.copy}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          >
            <span style={{ cursor: "pointer", color: "darkred" }}>
              Homepage/Feed URL
            </span>
          </Popover>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: "Custom mapping",
      dataIndex: "custom_mapping",
      key: "custom_mapping",
      align: "center" as "center",
      className: "redirect-center",
      render: (record: boolean) => (record ? "Yes" : "No"),
    },
    {
      title: "Allow Update",
      dataIndex: "allow_update",
      key: "allow_update",
      align: "center" as "center",
      className: "redirect-center",
      render: (record: boolean) => (record ? "Yes" : "No"),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      align: "center" as "center",
      className: "redirect-center",
      sorter: (a: IState, b: IState) =>
        sortTable(String(a.active), String(b.active), "string"),
    },
    {
      title: "Transform",
      dataIndex: "id",
      align: "center" as "center",
      key: "id",
      render: (text: string, record: IState, index: number) => {
        return (
          <Button
            size="small"
            disabled={record.shop_rules_exist === 1 ? false : true}
            value="default"
            style={{ marginLeft: "3px" }}
            onClick={() => startTransform(record.id)}
            loading={record.isTransform}
          >
            Transform
          </Button>
        );
      },
    },
    {
      title: "Create/Change rules",
      dataIndex: "id",
      key: "change_rules",
      align: "center" as "center",
      render: (text: string, record: IState, index: number) => (
        <Button
          value="default"
          className={`${
            record.shop_rules_exist !== 1
              ? "change-rules-btn"
              : "create-rules-btn"
          }`}
          size="small"
          style={{ width: "97px" }}
          onClick={() => openShopRulesModal(record)}
        >
          {record.shop_rules_exist === 1 ? "Change rules" : "Create rules"}
        </Button>
      ),
    },
    {
      title: "Create/Edit shop map",
      dataIndex: "id",
      key: "delete_user",
      align: "center" as "center",
      width: "10%",
      render: (text: string, record: IState, index: number) => (
        <Button
          value="default"
          size="small"
          style={{ width: "125px" }}
          className={`${
            record.shop_maps_exist !== 1
              ? "change-rules-btn"
              : "create-rules-btn"
          }`}
          onClick={() => openShopMapModal(record)}
        >
          {record.shop_maps_exist === 1 ? `Edit shop map` : `Create shop map`}
        </Button>
      ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center" as "center",

      render: (record: string) =>
        /*parseDate(record)*/ record
          ? `${moment(record).format("DD MMM YYYY")} ${moment(record).format(
              "HH:mm"
            )}`
          : "-",
      sorter: (a: IState, b: IState) =>
        sortTable(a.created_at, b.created_at, "date"),
    },
    {
      title: "Last updated",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center" as "center",
      className: "redirect-center",
      width: "12%",
      render: (record: string) =>
        /*parseDate(record)*/ record
          ? `${moment(record).format("DD MMM YYYY")} ${moment(record).format(
              "HH:mm"
            )}`
          : "-",
      sorter: (a: IState, b: IState) =>
        sortTable(a.updated_at, b.updated_at, "date"),
    },
  ];
  const handleSwitchSF = (e: boolean) => {
    setSfActive(e === false ? 0 : 1);
  };
  const downloadFeed = (path: any) => {
    feedParser(path);
  };

  const storeModalCancel = () => {
    setStoreModal(false)
    //setShopId(null) ?????????????????
    // form.resetFields()
    // setShowComponent('list')
}

  return (
    <React.Fragment>
      <HelmetTitle title="Data Entry App - Shops" />
      <Row justify={"center"}>
        <Col span={22} style={{ height: "70vh" }}>

          <div className="shop-list-box">
          <Divider>Shop list</Divider>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "0.2rem",
              }}
            >
              <button
                className="u-button"
                style={{ marginBottom: "7px" }}
                onClick={() => setIsModalCreateShop(true)}
              >
                Add new shop
              </button>

              <Switch
                onChange={handleSwitchSF}
                checkedChildren="SF Active"
                unCheckedChildren="All Shops"
                defaultChecked
                className="shop-list-switch-btn"
              />
            </div>
            <TransformModalInfo
              transformState={transformState}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              downloadFeed={downloadFeed}
              setTransformState={setTransformState}
              isError={isError}
              setIsError={setIsError}
            />

            <CreateShopRulesModal
              shopRules={shopRules}
              isModalRules={isModalRules}
              handleModalRulesOk={handleModalRulesOk}
              handleModalRulesCancel={handleModalRulesCancel}
              setCounter={setCounter}
            />

            <CreateShopMapModal
              shopMap={shopMap}
              isModalShopMap={isModalShopMap}
              handleModalShopMapOk={handleModalShopMapOk}
              handleModalShopMapCancel={handleModalShopMapCancel}
              setCounter={setCounter}
            />

            <CreateShopDrawer
              isModalCreateShop={isModalCreateShop}
              handleModalCreateShopOk={handleModalCreateShopOk}
              handleModalCreateShopCancel={handleModalCreateShopCancel}
              setCounter={setCounter}
            />

            <EditShopDrawer
              editShopObj={editShop}
              isDrawerEditShop={isDrawerEditShop}
              handleDrawerEditShopOk={handleDrawerEditShopOk}
              handleDrawerEditShopCancel={handleDrawerEditShopCancel}
              setCounter={setCounter}
            />
            <StoreList
              storeModal={ storeModal }
              storeModalCancel={storeModalCancel}
              stores={stores}
              setStores={setStores}
              storeShop={storeShop}
            />

            <Table
              rowKey={(record) => record.id}
              dataSource={shopList}
              columns={columns}
              id="shop-table"
              pagination={{ defaultPageSize: 50, showSizeChanger: false }}
              //scroll={{x:'1630px'}}
              loading={loading}
              scroll={{ y: "70vh", x: "1630px" }}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ShopList;
