import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import { ColumnType } from "antd/es/table";
import { Button, Col, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import axios from "axios";
import EditableCell, { EditableRow } from "../ProductComponents/ProductAttributes/EditableCell";
import CreateNewValues from "../ProductComponents/ProductAttributes/CreateNewValues";
import "../../style/ProductsAttributes.css";
import { AttributeListType } from "../../types/type";

interface AttributesProps {
    productId: number | undefined;
    currentCountryId: number;
    currentCountryName: string;
    attributeList: AttributeListType[];
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeListType[]>>;
    countries: { name: string; value: number }[];
    loading: boolean;
}

const Attributes = (props: AttributesProps) => {
    const [openModal, setOpenModal] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState<{ name: string; attribute_id: number } | null>(null);

    const { currentCountryName, attributeList, setAttributeList, countries, loading } = props;

    const removeValue = async (product_attribute_id: number, type: "range" | "select" | "multiselect") => {
        setAttributeList((curr) => curr.map((a) => (a.product_attribute_id === product_attribute_id ? { ...a, loadingDelete: true } : a)));
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-product-attribute`, { product_attribute_id: product_attribute_id });
            setAttributeList((curr: any) =>
                curr.map((a: any) =>
                    a.product_attribute_id === product_attribute_id
                        ? { ...a, loadingDelete: false, [`value_${currentCountryName}`]: null, ...(type === "select" && { product_attribute_id: null }) }
                        : a
                )
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const defaultColumns: (ColumnType<AttributeListType> & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Name",
            dataIndex: `attribute_name_${currentCountryName}`,
            width: "30%",
        },
        {
            title: "Value",
            dataIndex: `value_${currentCountryName}`,
            className: "product-attributes-remove-value-left",
            editable: true,
        },
        {
            title: "",
            dataIndex: "",
            width: "55px",
            align: "center" as "center",
            className: "product-attributes-remove-value-right",
            render: (_, record) =>
                record.attribute_type !== "multiselect" && (
                    <Button
                        size="small"
                        style={{ color: "#ffaaa5" }}
                        loading={record.loadingDelete}
                        icon={!record.loadingDelete ? <CloseOutlined /> : ""}
                        disabled={record[`value_${currentCountryName}` as keyof typeof record] ? false : true}
                        onClick={() => removeValue(record.product_attribute_id, record.attribute_type)}
                        type="ghost"
                    />
                ),
        },
        {
            title: "Type",
            dataIndex: "attribute_type",
            width: "20%",
        },
    ];

    const handleSave = (row: AttributeListType, product_attribute_id: number) => {
        const newData = [...attributeList];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
            product_attribute_id: product_attribute_id,
        });
        setAttributeList(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: AttributeListType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                setAttributeList: setAttributeList,
                showModal: showModal,
                currentCountryName: currentCountryName,
                handleSave,
            }),
        };
    });

    const showModal = (attributeObj: { name: string; attribute_id: number }) => {
        setNewAttributeValue(attributeObj);
        setOpenModal(true);
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    return (
        <React.Fragment>
            <Col span={24}>
                <CreateNewValues
                    openModal={openModal}
                    handleCancel={handleCancel}
                    newAttributeValue={newAttributeValue}
                    countries={countries}
                    setAttributeList={setAttributeList}
                    currentCountryName={currentCountryName}
                    attributeList={attributeList}
                />
                <Table
                    components={components}
                    rowKey={(record) => record.attribute_id}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={attributeList}
                    columns={columns as any}
                    pagination={false}
                    //className="product-attribute-table"
                    size="small"
                    loading={loading}
                />
            </Col>
        </React.Fragment>
    );
};

export default Attributes;
