import { Badge } from "antd";
import React from "react";

const CountiesStatus = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid whitesmoke",
                padding: "0.3rem 0.5rem",
                borderRadius: "5px",
                backgroundColor: "white",
                //marginRight: "1rem",
            }}
        >
            <Badge status="error" text="Not Changed" style={{ marginRight: "1.4rem" }} />
            <Badge status="warning" text="AI" style={{ marginRight: "1.4rem" }} />
            <Badge status="success" text="Manually" />
        </div>
    );
};

export default CountiesStatus;
