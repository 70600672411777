import { Progress, Spin, Table } from "antd";
import AttributeTable from "./AttributeTable";
import { CategoryType } from "../../types/AttributesAnalytics.type";
import ExpandIcon from "./ExpandIcon";
import { showAttributes } from "../../api/AttributesAnalytics";

interface ShopTableProps {
    category: CategoryType;
    setData: React.Dispatch<React.SetStateAction<CategoryType[]>>;
}

const ShopTable = (props: ShopTableProps) => {
    const { category, setData } = props;

    return (
        <Table
            bordered
            dataSource={category.shops}
            columns={[
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "300px",
                    render: (text, record) =>
                        text && (
                            <div>
                                <span style={{ fontWeight: category.isOpen ? 600 : "normal" }}>{text}</span>
                            </div>
                        ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "100px",
                    render: (text, record) =>
                        text && (
                            <div>
                                <div className="attributes-analytics-count">{record.total}</div>
                            </div>
                        ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "150px",
                    align: "center" as "center",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Mapped</span>
                            <span>{`${record.count_is_mapped ?? 0}/${record.total}`}</span>
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    align: "center" as "center",
                    width: "100px",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "130px" }}>
                            <span>Total</span>
                            {/* <span style={{ backgroundColor: "#1f6f78", color: "whitesmoke", borderRadius: "5px" }}>
                                {Math.round((((record.count_is_allowed_value ?? 0) + (record.count_is_in_range ?? 0)) / record.total) * 100) + "%"}{" "}
                            </span> */}
                            <span>{`${(record.count_is_allowed_value ?? 0) + (record.count_is_in_range ?? 0)}/${record.total}`}</span>
                            <Progress
                                percent={Math.round((((record.count_is_allowed_value ?? 0) + (record.count_is_in_range ?? 0)) / record.total) * 100)}
                                //steps={10}
                                //strokeColor={[orange[5], orange[5], blue[5], blue[5], blue[5], blue[5], blue[5], blue[5], green[6], green[6]]}
                            />
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "150px",
                    align: "center" as "center",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Range (mapped)</span>
                            <span> {`${record.count_is_range ?? 0}/${record.total}`}</span>
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "150px",
                    align: "center" as "center",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Select (mapped)</span>
                            <span> {`${/*record.count_is_mapped !== null && record.count_is_range !== null ?*/ (record.count_is_mapped ?? 0) - (record.count_is_range ?? 0)}/${record.total}`}</span>
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "150px",
                    align: "center" as "center",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Select allowed value (mapped)</span>
                            <span> {`${record.count_is_allowed_value ?? 0}/${record.count_is_mapped ? record.count_is_mapped - (record.count_is_range ? record.count_is_range : 0) : 0}`}</span>
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    width: "150px",
                    align: "center" as "center",
                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Range (numeric)</span>
                            <span>{`${record.count_is_numeric ?? 0}/${record.count_is_range ?? 0}`}</span>
                        </div>
                    ),
                },
                {
                    title: "",
                    dataIndex: "shop_name",
                    align: "center" as "center",

                    render: (text, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Range (in range)</span>
                            <span>{`${record.count_is_in_range ?? 0}/${record.count_is_range ?? 0}`}</span>
                        </div>
                    ),
                },
            ]}
            expandable={{
                expandIcon: ({ expanded, onExpand, record }) => <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />,
                onExpand: (expanded, shop) => showAttributes(expanded, shop, category.key, setData),
                expandedRowRender: (shop) => (shop.attributes?.length === 0 ? <Spin style={{ marginLeft: "5rem" }} /> : <AttributeTable {...props} shop={shop} />),
                rowExpandable: (record) => record.shop_name !== "Not Expandable",
                expandedRowClassName: () => "attributes-analytics-expanded-row",
            }}
            rowClassName={(shop) => (shop.isOpen ? "shop-attributes-open-row no-row-hover row-bold-text" : "")}
            pagination={false}
            style={{ marginLeft: "3rem" }}
        />
    );
};

export default ShopTable;
