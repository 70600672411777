import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Table, Select, Popover, Layout } from 'antd'
import axios from 'axios'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllShops } from '../../../actions/shopAction/shopAction';
import { RootStore } from '../../../Store';
import '../style/FeedHealthFromTo.css'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts"
import HelmetTitle from '../../../shared/Head/HelmetTitle';
import DatePickerFromToReports from '../components/DatePickerFromToReports';
import { DatePickerNewProducts } from '../components/NewShopProducts';
import { useSearchState } from '../../../shared/hooks/useSearchState';

const { Option } = Select
type FeedHealthType = {
    data:{id:number}[]
    message:string
}
let tempArr:any[] = []

const sortTable = (a: string, b: string, type: string) => {
  if (type === "date") {
    if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
    if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
    return 0;
  }
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

const FeedHealthFromTo = () => {
  const [ feed, setFeed ] = useState<FeedHealthType | null>(null)
  const [ shop, setShop ] = useState(null)
  const [ status, setStatus ] = useState("ERROR")
  //const [ dates, setDates ] = useState<string[]>(['',''])
  const [ date, setDate ] = useState<DatePickerNewProducts>({ startDate:new Date(), endDate:new Date()})
  const [ countError, setCountError ] = useState<number>(0)
  const [ feedError, setFeedError ] = useState<any>([])
  const dispatch = useDispatch()
  const stateS = useSelector((state:RootStore) => state)
  const { getColumnSearchProps } = useSearchState(feed?.data)
  const currentCountryId =  stateS.dataLs.country ? stateS.dataLs.country.countryId:1
  
  useEffect(() => {
    dispatch(getAllShops(0,currentCountryId))
  } , [ dispatch, currentCountryId ])
  useEffect(() => {
    let formTo:{startDate:string, endDate:string};
       if (date.endDate) {
         formTo = { 
           startDate:moment( date.startDate ).format("YYYY-MM-DD"), 
           endDate:moment( date.endDate ).format("YYYY-MM-DD")}
       }
    const getFeedHealth = async () => {
        try {
          const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/intelligence/feed-health-stats?${ `&from=${formTo.startDate}&to=${formTo.endDate}` }&status=${ status }&${ shop ? `&shop_id=${ shop }`:``}`)
          setFeed(data)
          data.data.forEach((item:any) => {
            if (!tempArr.includes(item.store_id)) {
              tempArr.push(item.store_id)
            }
          })
          setCountError(tempArr.length)
          ////////////
          let result = Object.values( data.data.reduce((a:any,{store_id,shop_name, ...props}:any)=>{
            if(!a[store_id])
               a[store_id]  = Object.assign({}, {store_id,shop_name,data : [props]});
             else
              a[store_id].data.push(props);
            return a;
          },{}))
          const newData = result.map((item:any)=>{
            return {
              ...item,
              errors:item.data.length,
              sn:item.shop_name.slice(0,4)
            }
          })
          const sortedResult = newData.sort(( a:any, b:any) => b.data.length - a.data.length)
          setFeedError(sortedResult)

          
        } catch ( err ) {
            console.log(err)
        }
    }
    getFeedHealth()
  }, [ date, shop, status ])

  const columns:any = [
    
    {
      title: "Shop",
      dataIndex: "shop_name",
      key: "shop_name",
      sorter: (a:any, b:any) => a.name && b.name && a.name.localeCompare(b.name),
      ...getColumnSearchProps('shop_name')
    },
    {
      title: "Shop Id",
      dataIndex: "store_id",
      key: "store_id",
      sorter: (a:any, b:any) => a.store_id - b.store_id
    },
    {
      title: "Error",
      dataIndex: "error",
      key: "error",
      sorter: (a:any, b:any) => a.error - b.error
    },
    {
      title: "Stats",
      dataIndex: "stats",
      key: "stats",
      render:( record:any ) =>{
        const xmlTime = record && JSON.parse(record).xmlTransformationTime
        return record ?
        <Popover 
          content={
            
            <div>
              <p>Failed: { JSON.parse(record).failedCount}</p>
              <p>Successful: { JSON.parse(record).successfulCount}</p>
              <p>Total Products: { JSON.parse(record).totalProductsCount}</p>
              {xmlTime && <p>Xml Transformation: { `${ xmlTime.hours }h:${ xmlTime.minutes }m:${ xmlTime.seconds }s (${ xmlTime.ms }ms)`}</p>}
              
            </div>
          }> 
          <span style={{ cursor:'pointer', color:'skyblue'}}><InfoCircleOutlined /></span>
        </Popover>:<span>-</span>
      }
    },
    {
      title: "Feed url",
      dataIndex: "feed_url",
      key: "feed_url",
      sorter: (a:any, b:any) => a.feed_url - b.feed_url
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (record:any) => `${ moment(record).format('DD MMM YYYY') } ${ moment(record).format('HH:mm:ss') }`,
      sorter: (a: any, b: any) =>
        sortTable(a.created_at, b.created_at, "date"),

    }
  ]
  const columnsFeedError = [
    {
      title:'Shop Id',
      dataIndex:'store_id',
      key:'store_id'
    },
    {
      title:'Shop',
      dataIndex:'shop_name',
      key:'shop_name'
    },
    {
      title:'Errors',
      dataIndex:'errors',
      key:'errors'
    }
  ]

  const  handleShopChange = (value:any) => {
    setShop(value)
  }
  const clearSelect = () => {
    //setDates(['',''])
    setShop(null)
  }
  const onStatusChange = ( value:any) => {
    setStatus(value)
  }

  if ( !feed ) {
    return <div className="loading"> Loading&#8230;</div>
  }
  return (
    <React.Fragment>
      
      <HelmetTitle title="Data Entry App - Feed health (from to)" />  
      <Row justify={"center"}>
        <Col span={20} className='prices-products-box'>
          <Divider>{ feed && feed.message } </Divider>
          <Select
            showSearch
            allowClear={ true }
            onClear={ clearSelect }
            style={{ width: '200px', marginBottom:'0.5rem', marginRight:"0.3rem" }}
            placeholder="Select shop"
            onSelect={handleShopChange}
            filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
            stateS.shops.data && stateS.shops.data.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((shop:any)=>(
            <Option key={shop.id} value={shop.id}>{ shop.name }</Option>
          ))
          }
          </Select>
          <DatePickerFromToReports date={date} setDate={setDate} isDate />
          <Select 
            style={{ width: 120, marginLeft:'5px' }} 
            /*value={secondCity}*/ 
            onChange={onStatusChange}
            defaultValue="ERROR"
          >
            <Option key="error" value="ERROR">Error</Option>
            <Option key="finished" value="FINISHED">Finished</Option>
          </Select>
          <h6 style={{ position:'absolute', top:'0.3rem', right:'0.2rem' }}>
            {feed.data.length > 0 && <span style={{ fontSize:'16px' }}>
              Total Errors:{" "} 
              <span style={{ color:'darkred' }}>
                { Math.round(((countError/stateS.shops.data?.length)*100 + Number.EPSILON) * 100) / 100 } %
              </span>
            </span>}
          </h6>
          <Layout style={{ margin:'0.6rem 1rem', backgroundColor:'white' }} >
            <Layout.Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px", position:"relative" }}> </Layout.Header>
            <Table 
              rowKey={ record => record.id } 
              dataSource={ feed.data } 
              columns={ columns } 
            />
            <Row justify="center" style={{ paddingTop:'2rem' }}>
            {feed.data.length> 0 && <Divider>All errors by shop</Divider>}
              <Col span={6}>
                {feed.data.length> 0 && 
                  <Table 
                    rowKey={ record => record.store_id } 
                    dataSource={ feedError } 
                    columns={ columnsFeedError } 
                    pagination={{ size:'small'}}
                    className="feed-errors"
                    style={{ height:"380px" }}
                    size='small'
                  />}
              </Col>
              <Col span={18} className="feed-helth-chart">

              {feed.data.length> 0 && <BarChart
                  width={1100}
                  height={400}
                  data={feedError}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                  
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="sn" />
                  <YAxis dataKey="errors" />
                  
                  <Tooltip  />
                  <Legend />
                  
                  <Bar dataKey="errors" fill="#d6476f"  />
                </BarChart>}
              </Col>
            </Row>
           
         


          </Layout>

         

        </Col>
       
      </Row>
    </React.Fragment>
  )
}

export default FeedHealthFromTo
