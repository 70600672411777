import { Button, Checkbox, Form, FormInstance, Input, Select } from 'antd'
import React from 'react'
import { AttributeMappingRulesType, Rules } from '../../pages/AttributeMappingRules'

interface CreateDefintionPopoverProps {
    form: FormInstance<any>
    selectedAttrRules: string | Rules[] | undefined
    setSelectedAttrRules: React.Dispatch<React.SetStateAction<string | Rules[] | undefined>>
    attirbuteRules: AttributeMappingRulesType[]
    saveNewRuleDef:(e: {
        rule_id: number;
    }) => Promise<void>
}

const fieldType = (type:string) => {
    switch (type) {
        case 'input':
            return <Input placeholder="Parameter value" />
        case 'checkbox':
            return <Checkbox />
        default:
            return
    }
}

const CreateDefintionPopover:React.FC<CreateDefintionPopoverProps> = ({ form, selectedAttrRules, setSelectedAttrRules, attirbuteRules, saveNewRuleDef }) => {
    
    return (
        <Form 
            style={{ width:'300px', borderTop:'1px solid whitesmoke', paddingTop:'17px' }}
            labelCol={{ span: 8 }}
            //wrapperCol={{ span: 16 }}
            form={form}
            onFinish={saveNewRuleDef}
        
        >
            <Form.Item
                label={<span style={{ color:"darkred" }}>Rule</span>}
                name="rule_id"
                rules={[{ required: true, message: 'Please input your name!' }]}
            >
                <Select 
                    onChange={( e ) => setSelectedAttrRules ( attirbuteRules.find( attrRule => attrRule.id ===  parseInt( e ))?.rules )} 
                    placeholder="Select Rule" 
                    ////////
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={
                        attirbuteRules.map( attrRule => 
                            ({ value: attrRule.id, label:attrRule.name }))
                    }
                />     
               
            </Form.Item> 
            { typeof selectedAttrRules !== 'string' && selectedAttrRules?.map( r => 
            { 
            return <Form.Item
                key={r.id}
                label={`${ r.field_name[0].toUpperCase() + r.field_name.slice(1) }`}
                name={r.id}
                rules={[{ required: true, message: `Please input value for ${ r.field_name }!` }]}
            >
                { fieldType(r.field_type) }
            </Form.Item>
            }
            )
            }
            {/* <Divider /> */}
            <Form.Item
                wrapperCol={{ offset: 7, span: 15 }}
                style={{ borderTop:'1px solid whitesmoke', paddingTop:'0px' }}
            >
                <Button type="primary" htmlType="submit" style={{ marginTop:'2rem' }}>
                    Create definition
                </Button>
            </Form.Item>

        </Form>
    )
}

export default CreateDefintionPopover