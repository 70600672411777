import { Button, Divider, Form, Popconfirm, Row, Select, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import numberWithCommas from "../../../../shared/utils/numberUtils";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import { ApplyToAllFormProps } from "../../types/props";
const { TreeNode } = TreeSelect;

const sortCategory = (data: CateogoryL3L4[], currentCountryName: string) => {
    return data.sort((a: CateogoryL3L4, b: CateogoryL3L4) =>
        a[`name_${currentCountryName}` as keyof CateogoryL3L4] > b[`name_${currentCountryName}` as keyof CateogoryL3L4]
            ? 1
            : b[`name_${currentCountryName}` as keyof CateogoryL3L4] >
              a[`name_${currentCountryName}` as keyof CateogoryL3L4]
            ? -1
            : 0
    );
};

const ApplyToAllForm: React.FC<ApplyToAllFormProps> = (props) => {
    const [categories, setCategories] = useState<CateogoryL3L4[]>([]);

    useEffect(() => {
        setCategories(
            props.state.categoriesL3.data &&
                sortCategory(
                    props.state.categoriesL3.data.map((data: any) => ({
                        ...data,
                        children: sortCategory(data.children, props.currentCountryName),
                    })),
                    props.currentCountryName
                )
        );
    }, [props.state.categoriesL3.data, props.currentCountryName]);

    return (
        <Row justify="center" align="middle">
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{
                    border: "1px solid lightgray",
                    borderRadius: "8px",
                    width: "90%",

                    height: "100%",
                    padding: "1rem",
                }}
                form={props.form}
            >
                <Form.Item name="brand" label="Brand">
                    <Select
                        showSearch
                        allowClear
                        placeholder="Apply Brand"
                        //onChange={onBrandChange}

                        optionLabelProp="label"
                        filterOption={(input: string, option?: { label: string; value: string }) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        options={props.brands.map((b) => ({
                            value: b.id.toString(),
                            label: `${b.brand_name}`,
                        }))}
                    />
                </Form.Item>
                <Form.Item name="category" label="Category">
                    <TreeSelect
                        showSearch
                        treeDataSimpleMode
                        placeholder="Select category"
                        allowClear
                        filterTreeNode={(search: string, item: any) =>
                            item.title.props?.children[0]?.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        }
                    >
                        {categories &&
                            categories.map((category: CateogoryL3L4) =>
                                category.children.length === 0 ? (
                                    <TreeNode
                                        key={category.id}
                                        value={category.id}
                                        title={
                                            <>
                                                {`${category.id}: ${
                                                    category[`name_${props.currentCountryName}` as keyof CateogoryL3L4]
                                                }`}{" "}
                                                <span
                                                    style={{
                                                        fontSize: "12px",
                                                        opacity: "0.8",
                                                        color: "gray",
                                                    }}
                                                >
                                                    {" "}
                                                    ({category.l2_name}){" "}
                                                </span>
                                            </>
                                        }
                                    />
                                ) : (
                                    <TreeNode
                                        key={category.id}
                                        value={category.id}
                                        title={
                                            <>
                                                {`${category.id}: ${
                                                    category[`name_${props.currentCountryName}` as keyof CateogoryL3L4]
                                                }`}{" "}
                                                <span
                                                    style={{
                                                        fontSize: "12px",
                                                        opacity: "0.8",
                                                        color: "gray",
                                                    }}
                                                >
                                                    {" "}
                                                    ({category.l2_name}){" "}
                                                </span>
                                            </>
                                        }
                                    >
                                        {category.children.map(
                                            (itemChild: { id: number; [key: string]: string | number }) => (
                                                <TreeNode
                                                    key={itemChild.id}
                                                    value={itemChild.id}
                                                    title={
                                                        <>
                                                            {`${itemChild.id}: ${
                                                                itemChild[`name_${props.currentCountryName}`]
                                                            } `}{" "}
                                                        </>
                                                    }
                                                />
                                            )
                                        )}
                                    </TreeNode>
                                )
                            )}
                    </TreeSelect>
                </Form.Item>
                <Divider />
                <Form.Item style={{ marginLeft: "85%" }}>
                    <Popconfirm
                        title={
                            <span>
                                Apply to{" "}
                                <span style={{ color: "darkred" }}>
                                    {" "}
                                    {numberWithCommas(String(props.count).replace(".", ","))}{" "}
                                </span>
                                products
                            </span>
                        }
                        description={() => {
                            const findCategory = categories.find((c) => c.id === props.form.getFieldValue("category"));
                            const selectedCategory = findCategory
                                ? findCategory[`name_${props.currentCountryName}` as keyof CateogoryL3L4]
                                : "";

                            const selectedBrand = props.form.getFieldValue("brand")?.split("::")[1];

                            return (
                                <span>
                                    Are you sure you want to apply{" "}
                                    {selectedCategory && (
                                        <span>
                                            Category{" "}
                                            <span style={{ color: "darkred" }}>{selectedCategory.toString()}</span>{" "}
                                        </span>
                                    )}
                                    {selectedCategory && selectedBrand && <span>{" and "}</span>}
                                    {selectedBrand && (
                                        <span>
                                            Brand <span style={{ color: "darkred" }}>{selectedBrand}</span>{" "}
                                        </span>
                                    )}
                                </span>
                            );
                        }}
                        onConfirm={props.save}
                        disabled={false}
                        //onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            disabled={props.brand || props.category ? false : true}
                            loading={props.loading}
                        >
                            Apply
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </Row>
    );
};

export default ApplyToAllForm;
