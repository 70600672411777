import { Col, Divider, Empty, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { tabs } from "../utils/tabs";
import SearchForm from "../components/ProductNew/SearchForm";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import Navigation from "../components/ProductNew/Navigation";
import ProductDetails from "../components/ProductNew/ProductDetails";
import { PicturesType } from "../types/type";
import "../style/ProductNew.css";
import DefaultImage from "../components/ProductNew/DefaultImage";
import { useHistory, useParams } from "react-router-dom";
import DescriptionAndReview from "../components/ProductNew/DescriptionAndReview";
import Attributes from "../components/ProductNew/Attributes";
import History from "../components/ProductNew/History";
import Prices from "../components/ProductNew/Prices";
import { UseAttributes, UseBrands, UseCategories, useDescriptionReview, UseGetEans, UseGetProduct, UseHistory, UsePrices } from "../api/Product/UseGetData";
import FixPricesModal from "../components/ProductNew/FixPrices/FixPricesModal";
import CreateNewImage from "../components/ProductNew/InfoAndImages/CreateNewImage";
import ImageCard from "../components/ProductNew/InfoAndImages/ImageCard";

const findTabName = (id: number) => tabs.find((t) => t.id === id)?.key;

export enum Tabs {
    PRICES = 1,
    DESCRIPTION_REVIEW,
    ATTRIBUTES,
    HISTORY,
}
// export const filterDetails = ["id", "model", "category", "brand", "mpn"];

let choosenTab: number = 1;

const ProductNew = () => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [productId, setProductId] = useState<number | undefined>();
    const [fixPriceVisible, setFixPriceVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const params = useParams() as { id: string; tab: string };
    const historyParams = useHistory();
    const stateProduct = useSelector((state: RootStore) => state);
    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";
    const currentCountryId = stateProduct.dataLs.country ? stateProduct.dataLs.country.countryId : 1;

    // get Product data
    const { getProduct, productInfo, setProductInfo, loadingInfo, loadingSearch, setLoadingSearch } = UseGetProduct(currentCountryId, setProductId /*setProductInfo*/);
    // get Product eans

    const { getEans, eans, setEans, loadingDeleteEan, setLoadingDeleteEan } = UseGetEans(productId);
    // get description and review data
    const { getDescriptionReview, description, setDescription, review, setReview } = useDescriptionReview(productId, setLoading);
    // get categories
    const { categories } = UseCategories(currentCountryId, currentCountryName, stateProduct);
    // get history
    const { getHistory, history } = UseHistory(productId, setLoading);
    //get prices
    const { getPrices, prices, setPrices, loaidngPrices } = UsePrices(productId, currentCountryId, setLoading);
    // get brands
    const { brandList } = UseBrands();
    // get attributes
    const { getAttributes, attributeList, setAttributeList } = UseAttributes(productId, currentCountryId, currentCountryName, setLoading);

    useEffect(() => {
        if (params.id) {
            if (params.tab && tabs.map((t) => t.key).includes(params.tab.toLowerCase())) {
                const findIndex = tabs.findIndex((t) => t.key === params.tab.toLowerCase());
                setSelectedTab(tabs[findIndex].id);
                choosenTab = tabs[findIndex].id;
                setProductId(Number(params.id));
            } else {
                historyParams.replace(`/product/${params.id}/prices`);
            }
            // productId && selectedTab === 1 && getPrices();
        } else {
            historyParams.replace(`/product`);
        }
    }, [params.id, historyParams, params.tab, getPrices, productId]);

    const onFinish = async (e: { id: string }) => {
        // setProductId(undefined);
        if (e.id.toString() !== productId?.toString()) {
            // if user put other id in input
            setLoadingSearch(true);
        } else {
            // cal apis with the same product id
            if (productId) {
                switch (selectedTab) {
                    case Tabs.PRICES:
                        getPrices();
                        getEans();
                        getProduct(productId.toString(), "changeCountry");
                        return;
                    case Tabs.DESCRIPTION_REVIEW:
                        getDescriptionReview();
                        getProduct(productId.toString(), "changeCountry");
                        getEans();
                        return;
                    case Tabs.ATTRIBUTES:
                        getAttributes();
                        getProduct(productId.toString(), "changeCountry");
                        getEans();
                        return;
                    case Tabs.HISTORY:
                        getHistory();
                        getProduct(productId.toString(), "changeCountry");
                        getEans();
                        return;
                }
            }
        }
        historyParams.replace(`/product/${e.id}/prices`);
    };

    const changeTab = (tab: number) => {
        switch (tab) {
            case Tabs.PRICES:
                getPrices().then(() => {
                    setSelectedTab(tab);
                    choosenTab = tab;
                    historyParams.replace(`/product/${params.id}/${findTabName(tab)}`);
                });
                return;
            case Tabs.DESCRIPTION_REVIEW:
                getDescriptionReview().then(() => {
                    setSelectedTab(tab);
                    choosenTab = tab;
                    historyParams.replace(`/product/${params.id}/${findTabName(tab)}`);
                });
                return;
            case Tabs.ATTRIBUTES:
                getAttributes().then(() => {
                    setSelectedTab(tab);
                    choosenTab = tab;
                    historyParams.replace(`/product/${params.id}/${findTabName(tab)}`);
                });
                return;
            case Tabs.HISTORY:
                getHistory().then(() => {
                    setSelectedTab(tab);
                    choosenTab = tab;
                    historyParams.replace(`/product/${params.id}/${findTabName(tab)}`);
                });
                return;

            default:
                break;
        }
    };

    useEffect(() => {
        if (productId) {
            switch (choosenTab) {
                case Tabs.PRICES:
                    getPrices();
                    getEans();
                    getProduct(productId.toString(), "changeCountry");
                    return;
                case Tabs.DESCRIPTION_REVIEW:
                    getDescriptionReview();
                    getProduct(productId.toString(), "changeCountry");
                    getEans();
                    return;
                case Tabs.ATTRIBUTES:
                    getAttributes();
                    getProduct(productId.toString(), "changeCountry");
                    getEans();
                    return;
                case Tabs.HISTORY:
                    getHistory();
                    getProduct(productId.toString(), "changeCountry");
                    getEans();
                    return;
            }
        }
    }, [productId, getProduct, getEans, getDescriptionReview, getAttributes, getPrices, getHistory]);

    const pictures = productInfo.find((p) => p.name === "pictures")?.value as PicturesType[];

    return (
        <div style={{ backgroundColor: "white", minHeight: "90vh" }}>
            <HelmetTitle title="Data Entry App - Product" />
            {fixPriceVisible && (
                <FixPricesModal
                    fixPriceVisible={fixPriceVisible}
                    setFixPriceVisible={setFixPriceVisible}
                    productInfo={productInfo}
                    selectedTab={selectedTab}
                    getPrices={getPrices}
                    prices={prices}
                    stateProduct={stateProduct}
                />
            )}
            <Divider style={{ fontSize: "21px" }}>Product</Divider>
            <div style={{ margin: "1rem", padding: "1rem", display: "flex" }}>
                <div
                    style={{
                        height: "100%",
                        width: "530px",
                        minWidth: "470px",
                        border: productInfo.length > 0 ? "1px solid lightgray" : "",
                        padding: "1rem",
                        borderRadius: "8px",
                        position: "sticky",
                        top: "30px",
                    }}
                >
                    <SearchForm onFinish={onFinish} params={params} loadingSearch={loadingSearch} />
                    {productInfo.length > 0 ? (
                        <React.Fragment>
                            <Row style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                                <Col span={11} style={{ paddingRight: "1rem" }}>
                                    {loadingSearch ? <Skeleton.Image style={{ width: "160px", height: "160px" }} /> : <DefaultImage productInfo={productInfo} preview />}
                                </Col>
                                <Col span={13} style={{ backgroundColor: "#FAFAFA", padding: "0.5rem", borderRadius: "8px" }}>
                                    <Navigation selectedTab={selectedTab} changeTab={changeTab} />
                                </Col>
                            </Row>
                            <Col span={24} style={{ marginTop: "1rem" }}>
                                {loadingSearch ? (
                                    <Skeleton.Input block style={{ height: "110px" }} />
                                ) : (
                                    <Row wrap justify="start" style={{ margin: "0.2rem", padding: "0.5rem 0 0.8rem 0.5rem", borderRadius: "8px", backgroundColor: "whitesmoke" }}>
                                        <CreateNewImage productId={productId} setProductInfo={setProductInfo} />
                                        {pictures?.length > 0 ? pictures?.map((img) => <ImageCard key={img.name} img={img} productId={productId} setProductInfo={setProductInfo} />) : ""}
                                    </Row>
                                )}
                            </Col>
                            <ProductDetails
                                brandList={brandList}
                                categories={categories}
                                loadingInfo={loadingInfo}
                                productId={productId}
                                productInfo={productInfo}
                                setFixPriceVisible={setFixPriceVisible}
                                setProductInfo={setProductInfo}
                                getPrices={getPrices}
                                stateProduct={stateProduct}
                                currentCountryName={currentCountryName}
                                selectedTab={selectedTab}
                                loadingSearch={loadingSearch}
                                eans={eans}
                                loadingDeleteEan={loadingDeleteEan}
                                setLoadingDeleteEan={setLoadingDeleteEan}
                                setEans={setEans}
                            />
                        </React.Fragment>
                    ) : (
                        !params.id && (
                            <div style={{ marginTop: "50%" }}>
                                <Empty description="No data" />
                            </div>
                        )
                    )}
                </div>

                <div style={{ width: "100%", marginLeft: "1rem" }}>
                    <Row
                        style={{
                            minHeight: "85vh",
                            height: "100%",
                            overflow: "hidden",
                            border: "1px solid lightgray",
                            padding: "1rem",
                            borderRadius: "8px",
                        }}
                    >
                        <Col span={24}>
                            {selectedTab === Tabs.DESCRIPTION_REVIEW && description ? (
                                <DescriptionAndReview
                                    stateProduct={stateProduct}
                                    description={description}
                                    setDescription={setDescription}
                                    productId={productId}
                                    currentCountryId={currentCountryId}
                                    review={review}
                                    setReview={setReview}
                                    productInfo={productInfo}
                                    loading={loading}
                                />
                            ) : selectedTab === Tabs.ATTRIBUTES ? (
                                attributeList && (
                                    <Attributes
                                        productId={productId}
                                        currentCountryId={currentCountryId}
                                        currentCountryName={currentCountryName}
                                        attributeList={attributeList}
                                        setAttributeList={setAttributeList}
                                        countries={stateProduct.countries}
                                        loading={loading}
                                    />
                                )
                            ) : selectedTab === Tabs.PRICES && prices ? (
                                productId && !loaidngPrices && <Prices prices={prices} setPrices={setPrices} currentCountryId={currentCountryId} loading={loading} stateProduct={stateProduct} />
                            ) : (
                                selectedTab === Tabs.HISTORY && <History history={history} loading={loading} />
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ProductNew;
