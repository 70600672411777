import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Divider, Form, Spin } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import AttributeGroup from "../components/AttributeOverview/AttributeGroup";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import FilterBox from "../components/AttributeOverview/FilterBox";
import { AttributeType, ValuesType } from "../types/AttributeType";
import "../style/AttributeOverview.css";
import AttributeOverviewTable from "../components/AttributeOverview/AttributeOverviewTable";
import AttributeValues from "../components/AttributeOverview/AttributeValues";
import axios from "axios";
import TranslationModal from "../components/AttributeOverview/TranslationModal";

export type GroupType = {
    [key: string]: string | number;
    id: number;
};

const AttributeOverview = () => {
    const [attributeList, setAttributeList] = useState<AttributeType[]>([]);
    const [selectedCategory, setSelectedCateogry] = useState<{ id: number; name: string } | null>(null);
    const [groups, setGroups] = React.useState<GroupType[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<number | undefined>();
    const [selectedAttribute, setSelectedAttribute] = useState<{ name: string; id: string }>();
    const [values, setValues] = useState<{ name: string; id: number; data: ValuesType[]; type: string } | null>(null);
    const [allAttributes, setAllAtributes] = useState<{ value: string; label: string; group_id: number }[]>([]);
    // loading
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [loadingAttributes, setLoadingAttributes] = useState(false);
    /////////// for translate
    const [translationModal, setTranslationModal] = useState<{ open: boolean; type: "attribute" | "value" | "" }>({
        open: false,
        type: "",
    });
    const [form] = Form.useForm();
    //////////////////////
    const state = useSelector((state: RootStore) => state);
    const currentCountryName: string = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";
    const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;

    const getAttributeList = useCallback(async () => {
        setLoadingAttributes(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attributes/list-attributes-with-value-count?l3=${selectedCategory?.id}${selectedGroup !== 0 ? `&group_id=${selectedGroup}` : ""}`
            );
            setAttributeList(data.data.map((d: AttributeType) => ({ ...d, key: d.id.toString() })));
            setValues(null);
            setLoadingAttributes(false);
        } catch (err) {
            console.log(err);
        }
    }, [selectedGroup, selectedCategory]);

    useEffect(() => {
        selectedCategory && getAttributeList();
    }, [selectedCategory, getAttributeList]);

    useEffect(() => {
        setGroups([]);
        const getGroupByCategory = async () => {
            setLoadingGroup(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attribute-groups-by-l3?l3_id=${selectedCategory?.id}`);
                setGroups([
                    { id: 0, name_rs: "No Group", key: "0" },
                    ...data.data.sort((a: { order: number }, b: { order: number }) => a.order - b.order).map((d: GroupType) => ({ ...d, key: d.id.toString() })),
                ]);
                setLoadingGroup(false);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getGroupByCategory();
    }, [selectedCategory]);

    useEffect(() => {
        const getAllAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attributes-with-value-count?l3=${selectedCategory?.id}&list_all=${1}`);
                setAllAtributes(
                    data.data.map((a: { [key: string]: string | number }) => ({
                        value: a.id.toString(),
                        label: a[`name_${currentCountryName}`],
                        group_id: a.group_id,
                    }))
                );
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getAllAttributes();
    }, [selectedCategory, currentCountryName]);

    const [loadingShopAttributes, setLoadingShopAttributes] = useState(false);

    useEffect(() => {
        const showValue = async (id: number, name: string) => {
            setLoadingShopAttributes(true);
            setValues(null);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-values-with-count?attribute_id=${id}`);

                setValues({
                    name: name,
                    id: id,
                    data: data.data.map((item: ValuesType) => {
                        return { ...item, isMergeValues: false };
                    }),
                    type: data.data[0].type,
                });
                setLoadingShopAttributes(false);
            } catch (err) {
                console.log(err);
            }
        };

        selectedAttribute && showValue(Number(selectedAttribute.id), selectedAttribute.name);
    }, [selectedAttribute]);

    return (
        <React.Fragment>
            <div style={{ backgroundColor: "white", padding: "0.5rem" }}>
                <HelmetTitle title="Data Entry App - Attributes Overview" />
                <Row>
                    <Col span={24}>
                        <Divider>Attributes Overview</Divider>
                    </Col>
                </Row>
                <Row
                    justify="space-between"
                    align="middle"
                    style={{
                        border: "1px solid lightgray",
                        backgroundColor: "whitesmoke",
                        borderRadius: "8px",
                        padding: "0.8rem",
                        zIndex: 100,
                    }}
                >
                    <FilterBox
                        state={state}
                        currentCountryName={currentCountryName}
                        currentCountryId={currentCountryId}
                        setAttributeList={setAttributeList}
                        selectedCategory={selectedCategory}
                        setSelectedCateogry={setSelectedCateogry}
                        setSelectedGroup={setSelectedGroup}
                        setValues={setValues}
                        groups={groups}
                        selectedGroup={selectedGroup}
                        setGroups={setGroups}
                        setAllAtributes={setAllAtributes}
                    />
                </Row>
                <Row justify="center">
                    <Col span={3}>
                        <AttributeGroup
                            state={state}
                            selectedCategory={selectedCategory}
                            currentCountryName={currentCountryName}
                            selectedGroup={selectedGroup}
                            setSelectedGroup={setSelectedGroup}
                            groups={groups}
                            setGroups={setGroups}
                            loadingGroup={loadingGroup}
                        />
                    </Col>
                    <Col span={17}>
                        <AttributeOverviewTable
                            attributeList={attributeList}
                            setAttributeList={setAttributeList}
                            currentCountryName={currentCountryName}
                            groups={groups}
                            setGroups={setGroups}
                            selectedCategory={selectedCategory}
                            loadingAttributes={loadingAttributes}
                            getAttributeList={getAttributeList}
                            setSelectedAttribute={setSelectedAttribute}
                            setTranslationModal={setTranslationModal}
                            form={form}
                            allAttributes={allAttributes}
                            selectedAttribute={selectedAttribute}
                        />
                    </Col>
                    <Col span={4}>
                        {loadingShopAttributes ? (
                            <Row justify="center" style={{ marginTop: "6rem" }}>
                                <Spin />
                            </Row>
                        ) : (
                            <AttributeValues values={values} setValues={setValues} setTranslationModal={setTranslationModal} formOne={form} state={state} currentCountryName={currentCountryName} />
                        )}
                    </Col>
                </Row>
                <TranslationModal form={form} translationModal={translationModal} setTranslationModal={setTranslationModal} setAttributeList={setAttributeList} setValues={setValues} state={state} />
            </div>
        </React.Fragment>
    );
};

export default AttributeOverview;
