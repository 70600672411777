import { Button, Popconfirm, Tag } from "antd";
import React from "react";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { FixProductProps } from "../../types/modals";

const FixProduct: React.FC<FixProductProps> = (props) => {
    const { firstProductFix, matchingProduct, matchingProducts, products_for_fixing } = props;
    const submitFixProduct = async () => {
        const obj = {
            product_id: firstProductFix === "product-for-fixing" ? matchingProduct.product_id : matchingProducts.find((p) => p.productForFixing)?.product_id,
            products: [...(firstProductFix === "fixing" ? [matchingProduct.product_id] : []), ...products_for_fixing],
        };
        if ([...(firstProductFix === "fixing" ? [matchingProduct.product_id] : []), ...products_for_fixing].length === 0) {
            openNotification("Choose at least one  product for fixing");
            return;
        }
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/update-product-price`, obj);

            if (firstProductFix === "product-for-fixing") {
                // yellow product is for fixing
                props.setMatchingProducts((curr) => curr.map((p) => ({ ...p, productForFixing: false, isFixing: false })));
                props.setMatchingProducts((curr) => curr.filter((p) => !props.products_for_fixing.includes(p.product_id)));
                // if yellow procut is for fixing and other products go to that yellow product - go to next match (next button)
                if (matchingProducts.length === products_for_fixing.length) {
                    props.setProducts_for_matching((curr) =>
                        curr.map((p) =>
                            p.product_id === matchingProduct?.product_id
                                ? {
                                      ...p,
                                      isChecked: false,
                                  }
                                : p
                        )
                    );
                    props.setProducts((curr) =>
                        curr.map((p) =>
                            p.product_id === matchingProduct?.product_id
                                ? {
                                      ...p,
                                      isChecked: false,
                                  }
                                : p
                        )
                    );
                    props.setMatchingProducts([]);
                    if (props.matchingIndex + 1 !== props.products_for_matching.length) {
                        props.setMatchingIndex((curr) => curr + 1);
                    } else {
                        props.setIsModalMatching(false);
                    }
                }
                // new added for remove fixing product from product list
                if (props.products.filter((p) => !obj.products.includes(p.product_id))) {
                    props.setProducts((curr) => curr.filter((p) => !obj.products.includes(p.product_id)));
                    props.setProducts_for_matching((curr) => curr.filter((p) => !obj.products.includes(p.product_id)));
                }
            } else if (firstProductFix === "fixing") {
                // yellow product is in array of fixing - remove from list, increase matching index
                props.setProducts((curr) => curr.filter((p) => /*p.product_id !== matchingProduct.product_id &&*/ !obj.products.includes(p.product_id)));
                props.setProducts_for_matching((curr) => curr.filter((p) => /*p.product_id !== matchingProduct.product_id &&*/ !obj.products.includes(p.product_id)));

                if (props.products.filter((p) => p.isChecked).filter((p) => !obj.products.includes(p.product_id)).length === 0) {
                    props.setIsModalMatching(false);
                }
            } else {
                // move prices
                let countPrices = 0;
                props.setMatchingProducts((curr) =>
                    curr.filter((p) => {
                        if (!props.products_for_fixing.includes(p.product_id)) {
                            return true;
                        } else {
                            countPrices += p.active_prices;
                            return false;
                        }
                    })
                );
                props.setMatchingProducts((curr) =>
                    curr.map((p) => ({
                        ...p,
                        active_prices: p.productForFixing ? p.active_prices + countPrices : p.active_prices,
                        productForFixing: false,
                        isFixing: false,
                    }))
                );

                // new added for remove fixing product from product list
                if (props.products.filter((p) => !obj.products.includes(p.product_id))) {
                    props.setProducts((curr) => curr.filter((p) => !obj.products.includes(p.product_id)));
                    props.setProducts_for_matching((curr) => curr.filter((p) => !obj.products.includes(p.product_id)));
                }
            }

            props.setFirstProductFix("no-fixing");
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={submitFixProduct}>
                <Button className="fix-btn" style={{ marginRight: "25px" }} danger size="small" shape="round">
                    Fix product
                </Button>
            </Popconfirm>
            <Tag color="magenta">{firstProductFix === "product-for-fixing" ? matchingProduct?.product_id : matchingProducts.find((p) => p.productForFixing)?.product_id}</Tag>
            {[...(firstProductFix === "fixing" ? [matchingProduct?.product_id] : []), ...products_for_fixing].map((id) => (
                <Tag key={id} color="processing">
                    {id}
                </Tag>
            ))}
        </React.Fragment>
    );
};

export default FixProduct;
