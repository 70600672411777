import { Button,  } from 'antd';
import { Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import showMessage from '../../../../shared/MessagesInfo/message';
import '../../style/Names.css'
import { EditCateogyType } from '../EditCategory';
import Spinner from '../../../../images/spinner-products.gif'
import { CategoryType } from '../../pages/AllCategories';
import { LinkOutlined } from '@ant-design/icons';
import { ColumnTypes, editableCellcomponents, mappedColumns } from '../../../../shared/FormElements/EditableCell';

export interface DataType {
  key: React.Key
  id:number |undefined
  name: string
  value: string |undefined|null
  parent_id:number |any
  level_id:number
  isLoading:boolean
}

interface NamesProps {
  data:EditCateogyType|null
  selectedCateogry:CategoryType | null
  countries: string[]
}

const Links: React.FC<NamesProps> = ({ data, selectedCateogry, countries }) => {
  const [ category, setCategory ] = useState<DataType[]>([])
  const [ dataLinksRedirects, setDataLinksRedirects ] = useState<{id:number, redirect_from:string,redirect_to:string}[]>([])

  useEffect(() => {
    let arr:DataType[] = []
    
    for( let el in data?.translate) {
      if (countries.map( country => `link_${ country }`).includes(el) ) {
        arr.push({ 
          name:el, 
          value:data?.translate[el], 
          id: data?.id,                                        
          key:uuidv4(),
          parent_id:Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1])-1}_id` as keyof CategoryType],
          level_id:Number(selectedCateogry?.type[1]),
          isLoading:false
        })
      } 
    }

    setCategory(arr)
    data && setDataLinksRedirects(data.redirects)
  }, [ data?.translate, data?.id, selectedCateogry, data, countries ])

    //const countriesArr =  ['rs', 'si','hr', 'me', 'ba']
   

    const deleteRedirect = async (id:number|undefined) => {
      if(id) {

        try {
          const { data } = await axios.delete(`${ process.env.REACT_APP_URL_API }/product/category/delete-redirect?redirect_id=${ id }`)
          showMessage(data.message)
          setDataLinksRedirects( curr => curr ? curr.filter( d => d.id !== id ):[] )
        } catch ( err ) {
          console.log(err)
        }
      }
    }

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
          title: 'Country',
          dataIndex: 'name_rs',
          width: '160px',
          align:'center' as 'center',
          render:(text, record, index) =>   `Link ${countries[index]}`
      
      },
      {
          title: <span style={{ marginLeft:'0.9rem' }}>From (category link)</span>  , //<div style={{ display:'flex', justifyContent:'center' }}> <span>Name</span></div>,
          dataIndex: 'value',
          width: '320px',
          editable: true,
          className:'attribute-mapping-shop-att-td',
          render:(_:string, record:any, index:number) => 
            record.isLoading ? 
              <div style={{ height:'22px' }}><img src={Spinner} style={{ position:'absolute', marginLeft:'18px',top:'14px' }} alt='Spinner' width={46} /></div> : category.find( c => c.name === `link_${ countries[index] }`)?.value 
          
      },
      {
        title:'',
        dataIndex:'id', 
        render:(_,record:any, index:number) => 
          <LinkOutlined style={{color:'#01b0f5' }} onClick={()=> dataLinksRedirects.find( (d:any) => d.locale_code === countries[index]) && window.open( dataLinksRedirects.find( (d:any) => d.locale_code === countries[index])?.redirect_from, "", "width=900,height=1080")}  />
          

      },
      {
        title: <span style={{ marginLeft:'0.9rem' }}>To (Redirects to)</span>,
        dataIndex: 'value',
        width: '320px',
        editable: false,
        className:'attribute-mapping-shop-att-td',
        render:(_:string, record:any, index:number) => 
         <div style={{ display:'flex', justifyContent:"space-between" }}>
            <span >
              { dataLinksRedirects.find( (d:any) => d.locale_code === countries[index]) ?  dataLinksRedirects.find( (d:any) => d.locale_code === countries[index])?.redirect_to:'-'  }
            </span>
          </div>
        
    },
    {
      title:'',
      dataIndex:'id', 
      render:(_,record:any, index:number) => 
        <LinkOutlined style={{color:'#01b0f5' }} onClick={()=> dataLinksRedirects.find( (d:any) => d.locale_code === countries[index]) && window.open( dataLinksRedirects.find( (d:any) => d.locale_code === countries[index])?.redirect_to, "", "width=900,height=1080")}  />
    },
    {
      title:'',
      dataIndex:'value',
      render:(_:string, record:any, index:number) => 
        <Button 
          size='small' 
          onClick={() => deleteRedirect(dataLinksRedirects.find((d:any) => d.locale_code === countries[index])?.id)}
          disabled={ dataLinksRedirects.find((d:any) => d.locale_code === countries[index]) ? false:true }
        >
          Delete
        </Button>
    }
    
  ]

  const handleSave = async (row: DataType) => {
    //saveChanges(row)
    setCategory( curr => curr.map( d =>{ return d.key === row.key ? {...d, isLoading:true }:d }   ))  ////////////////////////////here
    const objToSend = {
      id: row.id, level_id: row.level_id, parent_id: row.parent_id, [`${ row.name}`]: row.value
    }
    try {
      const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/category/update`, objToSend)
      showMessage(data.message)
      const newData = [...category]
      const index = newData.findIndex(item => row.key === item.key)
      const item = newData[index]
      newData.splice(index, 1, {
          ...item,
          ...row,
        })
      setCategory(newData)
      setCategory( curr => curr.map( d => ({...d, isLoading:false })))
    } catch ( err ) {
      console.log(err)
    }
  }

  return (
    <Table
      components={editableCellcomponents}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={category}
      columns={mappedColumns(defaultColumns, handleSave, true) as ColumnTypes}
      style={{ marginTop:'4rem' }}
      pagination={false}
      loading={category.length === 0 ? true:false}
    />
  )
}

export default Links;