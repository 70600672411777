import React, { useState } from 'react'
import { Form, Button, Modal, Input, Space, Card } from 'antd'
import { useDispatch } from 'react-redux'
import '../../style/CreateBrand.css'
import showMessage from '../../../../shared/MessagesInfo/message'
import axios from 'axios'
import { getAllBrands } from '../../../../actions/brand/BrandAction'

const CreateBrand: React.FC<{currentCountryName: string}> = ({ currentCountryName }) => {
    const [isModalCreateBrandVisible, setIsModalCreateBrandVisible] = useState(false)

    const showCreateBrandModal = () => {
        setIsModalCreateBrandVisible(true)
    }

    const handleOk = () => {
        setIsModalCreateBrandVisible(false)
    }

    const handleCancelCreateBrand = () => {
        setIsModalCreateBrandVisible(false)
    }
    const [ form ] = Form.useForm()
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(false)
    
    const handleSubmit = async ( e:any ) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/brand/create`,{name: e[`name_${ currentCountryName }`]})        
            form.resetFields()
            setIsModalCreateBrandVisible(false)
            showMessage(data.message)
            setLoading(false)
            dispatch(getAllBrands())
        } catch ( err ) {
            console.log(err)
            setLoading(false)
        }
    }
    return (
        <React.Fragment>
            <Button size='small' type='primary' style={{marginBottom:'0.5rem'}} onClick={showCreateBrandModal}>
                Create Brand
            </Button>
            <Modal 
                title="Create new Brand" 
                open={isModalCreateBrandVisible} 
                onOk={handleOk} 
                onCancel={handleCancelCreateBrand} 
                width={600}
                footer={false}

            >
                <Card size='small' >
                    <Form 
                        form={form}
                        name="create-brand-form" 
                        labelCol={{ span: 16 }} 
                        onFinish={ handleSubmit }
                        style={{ padding:'2.5rem', borderRadius:'8px', backgroundColor:'#f8f8f8' }}
                    >
                        <Form.Item
                            label='Brand Name'
                            name={`name_${ currentCountryName }`}
                            rules={[{ required: true, message: 'Missing Name rs' }]}
                        >
                            <Input placeholder='Brand Name' />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'right' }}>
                                <Space size="small">
                                    <Button 
                                        style={{ marginTop:'18px', padding:'3px 8px', fontSize:'14px' }} 
                                        type='primary'
                                        loading={ loading }
                                        htmlType='submit'
                                    >
                                        Create Brand
                                    </Button>
                                </Space>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </React.Fragment>
    )
}

export default CreateBrand
