import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Select, Space } from "antd";
import "../style/CreateCategory.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import "../style/CreateCategory.css";
import CategoryImage from "./CategoryImage";
import { CategoryType } from "../pages/AllCategories";
let categoryId = 0;

type OneCategoryType = {
    id?: number;
    type: string;
};

type CategoriesType = {
    categories: OneCategoryType[];
    setModalCreate: React.Dispatch<React.SetStateAction<boolean>>;
    currentCountryName: string;
    currentCountryId: number;
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
    modalCreate: boolean;
    countries: { name: string; value: number; currency: string; order: number }[];
};

let isRequired = true;

const CreateCategory: React.FC<CategoriesType> = ({
    setModalCreate,
    currentCountryId,
    currentCountryName,
    setCounter,
    modalCreate,
    counter,
    countries,
}) => {
    const [categories, setCategories] = useState<{ id: number; type: string; name_rs: string }[]>([]);
    const [levelCateogires, setLevelCategories] = useState<{ id: number; type: string; name_rs: string }[]>([]);
    const [imageForL3, setImageForL3] = useState(false);
    const [image, setImage] = React.useState<{ file: { type: string }; id: number | string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const getAllCategoriesArray = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/product/category/list-all-categories?country_id=${currentCountryId}`
                );
                const level1 = data.data[0]
                    .map((category1: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category1,
                            categoryId: categoryId,
                            category_l1_id: category1.id,
                            [`category_l1_name_${currentCountryName}`]: [`category1.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);
                const level2 = data.data[1]
                    .map((category2: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category2,
                            categoryId: categoryId,
                            category_l2_id: category2.id,
                            [`category_l2_name_${currentCountryName}`]: [`category2.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level3 = data.data[2]
                    .map((category3: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category3,
                            categoryId: categoryId,
                            category_l3_id: category3.id,
                            [`category_l3_name_${currentCountryName}`]: [`category3.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level4 = data.data[3]
                    .map((category4: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category4,
                            categoryId: categoryId,
                            category_l4_id: category4.id,
                            [`category_l4_name_${currentCountryName}`]: [`category4.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);
                setCategories([...level1, ...level2, ...level3, ...level4]);
            } catch (err) {
                console.log(err);
            }
        };
        modalCreate && getAllCategoriesArray();
    }, [currentCountryId, currentCountryName, modalCreate, counter]);

    const onChangeLevel = (e: string) => {
        form.setFieldsValue({ parent_id: null }); //every time when level change, i will be change parent cagtegory
        if (e === "1") {
            isRequired = false;
            form.setFieldsValue({ parent_id: null });
        } else {
            isRequired = true;
        }
        if (e === "2" || e === "3" || e === "4") {
            setImageForL3(true);
        } else {
            setImageForL3(false);
            setImage([]);
        }
        setLevelCategories(categories.filter((cat) => cat.type === `l${Number(Number(e) - 1)}`));
    };

    useEffect(() => {
        setLevelCategories([]);
        form.setFieldsValue({ parent_id: null, level_id: null });
    }, [categories, currentCountryName, form]);

    const createNewCategory = async (e: { categories: OneCategoryType[]; parent_id: number }) => {
        if (!e.categories) {
            openNotification("At least one category have to be created!");
        } else {
            //   if (hasDuplicates(e.categories)) {
            //     openNotification('Some categories has the same name')
            //   return
            // }

            const newData: any = {
                ...e,
                parent_id: e.parent_id ? e.parent_id : 0,
                categories: e.categories.map((c: any) => {
                    delete c[`createimg`];
                    const obj = { ...c, image: image.find((i) => i.id === c.id)?.file };
                    /*delete obj.img; delete obj.id ;*/ return obj;
                }),
            };

            const findWrongType = image.find((i) => {
                const type = i.file.type.split("/");
                if (type[1] === "jpeg" || type[1] === "jpg" || type[1] === "png" || type[1] === "webp") {
                    return false;
                } else {
                    return true;
                }
            });

            if (findWrongType) {
                openNotification(`Allowed image extensions JPG, JPEG, WEBP and PNG`);
            } else {
                setLoading(true);
                const imageData = newData.categories.map((d: any) => {
                    const findImg = image.find((i) => i.id === d.imgID || (i.id === 7855 && d.imgID === 0));
                    if (findImg) {
                        return findImg;
                    } else {
                        return null;
                    }
                });
                const formData = new FormData();
                for (let key in newData) {
                    Array.isArray(newData[key])
                        ? newData[key].forEach((value: string) => formData.append(key + "[]", JSON.stringify(value)))
                        : formData.append(key, newData[key]);
                }
                imageData.forEach(
                    (value: any) => value && formData.append(`image-${value.id === 7855 ? 0 : value.id}`, value.file)
                );

                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_URL_API}/product/category/create-multiple`,
                        formData
                    );
                    showMessage(data.message);
                    setModalCreate(false);
                    form.resetFields(["level_id", "parent_id", "categories"]);
                    setImage([]);
                    setCounter((curr) => curr + 1);
                    setLoading(false);
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        }
    };
    return (
        <Form form={form} name="dynamic_form_nest_item" onFinish={createNewCategory} autoComplete="off">
            <div className="create-category-header">
                <Form.Item
                    label="Level"
                    name="level_id"
                    rules={[{ required: true, message: "Select Category level!" }]}
                    style={{ width: "200px" }}
                >
                    <Select placeholder="Select Level" onChange={(e) => onChangeLevel(e)}>
                        {Array.from(Array(4).keys()).map((num) => (
                            <Select.Option key={String(num + 1)} value={String(num + 1)}>{`Level ${
                                num + 1
                            }`}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Parent Category"
                    name="parent_id"
                    rules={[{ required: isRequired /*true*/, message: "Select Parent Category!" }]}
                    style={{ width: "430px", marginLeft: "1rem" }}
                >
                    <Select
                        showSearch
                        placeholder="Select Parent Category"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {levelCateogires.map((cat) => (
                            <Select.Option
                                key={`${cat.id} ${
                                    cat[
                                        `name_${currentCountryName}` as keyof {
                                            id: number;
                                            type: string;
                                            name_rs: string;
                                        }
                                    ]
                                }`}
                                value={cat.id}
                            >
                                {`${cat.id}: ${
                                    cat[
                                        `name_${currentCountryName}` as keyof {
                                            id: number;
                                            type: string;
                                            name_rs: string;
                                        }
                                    ]
                                }`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
            <Divider />
            <Form.List name="categories">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map((field, index) => (
                            <Space
                                style={{ position: "relative" }}
                                key={field.key}
                                align="baseline"
                                className="cateogry-items-container"
                            >
                                {countries
                                    .map((c) => c.name)
                                    .map((country) => (
                                        <Form.Item
                                            {...field}
                                            key={field.key + country}
                                            label={`${country.toUpperCase()}`}
                                            name={[field.name, `name_${country}`]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Missing Name ${country.toUpperCase()}`,
                                                },
                                            ]}
                                            style={{
                                                margin: "0",
                                                padding: "0",
                                                marginRight: "1rem",
                                                width: "280px",
                                                marginLeft: country === "rs" ? "1rem" : "",
                                            }}
                                        >
                                            <Input placeholder={`Name ${country.toUpperCase()}`} />
                                        </Form.Item>
                                    ))}
                                <Form.Item
                                    {...field}
                                    key={field.key + "img"}
                                    label="Image"
                                    name={[field.name, "imgID"]}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    style={{ width: "200px", display: "none" }}
                                    initialValue={field.key}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    key={field.key + "createimg"}
                                    label=""
                                    name={[field.name, "createimg"]}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    style={{ width: "200px" }}
                                >
                                    <CategoryImage
                                        image={image}
                                        setImage={setImage}
                                        id={field.key}
                                        imageForL3={imageForL3}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                    style={{ position: "absolute", right: "0.4rem", bottom: "1.7rem" }}
                                />
                            </Space>
                        ))}
                        <Divider />

                        <Form.Item style={{ width: "200px", margin: "0 auto" }}>
                            <Button type="dashed" danger onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Category
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateCategory;
