import { REMOVE_LS_CURRENT_COUNTRY, SET_LS_CURRENT_COUNTRY } from "../actions/LsActions/localeStorageActionsType";

const country:string | null = localStorage.getItem('country')
const lsObject = country && JSON.parse(country)

export const lsReducer = (state:any=lsObject ? {country:lsObject}:{}, action:any) => {
    switch (action.type) {
        case SET_LS_CURRENT_COUNTRY:
            return {
                country: action.payload,
            }
        case REMOVE_LS_CURRENT_COUNTRY:
            return {}
            
        default:
            break;
    }
    return state
}