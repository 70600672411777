import {
    Modal,
    Button,
    Image,
    Row,
    Card,
    Spin,
    Badge,
    Popover,
    Checkbox,
    Tag,
    Popconfirm,
    Input,
    Select,
    TreeSelect,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { ZoomInOutlined, AimOutlined, FileImageOutlined, CloseSquareOutlined } from "@ant-design/icons";
import ProgressLine from "./Progress";
import "../style/AllPricesMpnModal.css";
import axios from "axios";
import authCheckStatus from "../../../shared/utils/authSeviceChecked";
import { useDispatch } from "react-redux";
import { USER_LOGOUT } from "../../../actions/userAction/userActionsType";
import showMessage from "../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../shared/utils/numberUtils";
import { showNameByCaountry } from "../../reports/helpers/showName.";
import { HighlilghtText, words } from "../../../shared/HighlightWords/Highlilght";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
//import { countries } from '../../../constants/countries';
const { TextArea } = Input;
const { TreeNode } = TreeSelect;

const sortProducts = (objArr: any) => {
    return objArr
        .sort((a: any, b: any) => b.prices_count - a.prices_count)
        .sort((a: any, b: any) => {
            if (a.category_l3_id === 1 && b.category_l3_id !== 1) {
                return 1;
            } else if (a.category_l3_id !== 1 && b.category_l3_id === 1) {
                return -1;
            } else {
                return 0;
            }
        });
};

let uniqueProductIdArr: number[] = []; // unique ids in matcing mpn modal

const AllPricesMpnModal: React.FC<any> = ({
    mpnItem,
    allPrices,
    setAllPrices,
    mpnObj,
    setMpnObj,
    multySelected,
    oneSelect,
    selectTemp,
    modalProduct,
    setModalProduct,
    skipPrice,
    showProducts,
    setSelectTemp,
    setMultySelected,
    currentCountry,
    currentCountryId,
    searchType,
    //uncategorized,
    noCategory,
    mpnModal,
    setMpnModal,
    setSearchType,
    modalProductLoading,
    setModalProductLoading,
    handleMpnModalOk,
    handleCancel,
    brands,
    categoryL3Options,
    unMatched,
    countries,
}) => {
    const [productNameId, setProductNameId] = useState(null);
    const [nameValue, setNameValue] = useState("");

    const [mpnEdit, setMpnEdit] = useState<string>("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectTemp === 0 && multySelected.length === 0) {
            setMpnModal((prev: any) => {
                return {
                    ...prev,
                    visible: false,
                };
            });
            setModalProduct([]);
        }
        if (selectTemp < multySelected.length) {
            setMpnModal((prev: any) => {
                return {
                    ...prev,
                    visible: true,
                };
            });
        } else if (selectTemp === multySelected.length + 1) {
            setMpnModal((prev: any) => {
                return {
                    ...prev,
                    visible: false,
                };
            });
            setModalProduct([]);
        }
    }, [selectTemp, multySelected.length, setMpnModal, setModalProduct]);

    const markPrice = useCallback(
        async (id: number) => {
            if (unMatched === 1) {
                try {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_URL_API}/prices/mark-price?product_id=${id}`
                    );
                    console.log(data);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [unMatched]
    );

    // const markPriceInTable = useCallback((id:number) => {
    //   if (unMatched === 1 ) {   // to mart checked prices whten we are looging for duplicate
    //     //const newData = allPrices.data.data.map((item:any) => { return {...item, checkToFlag:true}} )
    //     // setAllPrices(( currArr:any) =>{return   {...currArr,data:{...currArr.data, data:currArr.data.data.map((item:any) =>  item.id === id ? {...item, checkToFlag:true}:item  /*{ return {...item, checkToFlag:true}}*/ )}}   })
    //   }
    // },[  setAllPrices, unMatched])

    useEffect(() => {
        setModalProductLoading(true);
        const findMpnMatching = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/product/item/list-matched-products?${
                        mpnObj.brand_id !== 0 ? `brand_id=${mpnObj.brand_id}` : ""
                    }&mpn=${mpnObj.mpn}&model=${
                        !mpnObj.model ? null : encodeURIComponent(mpnObj.model)
                    }&type=${searchType}&condition=${mpnObj.condition}&product_id=${
                        mpnObj.product_id
                    }&country_id=${currentCountryId}`
                );
                //const sortedData = data.data
                const addChecked = data.data.map((item: any) => {
                    return {
                        ...item,
                        isSelectedProduct: false,
                        fixedPrice: false,
                        movePrice: false,
                        checkedFixedProduct: false,
                        changeName: false,
                        changeBrand: false,
                        changeCategory: false,
                        changeMpn: false,
                    };
                });

                setTimeout(() => {
                    setModalProduct(addChecked);
                    setModalProductLoading(false);
                }, 400);

                !noCategory &&
                    unMatched === 1 &&
                    markPrice(mpnObj.product_id).then((resp) => {
                        setAllPrices((currArr: any) => {
                            return {
                                ...currArr,
                                data: {
                                    ...currArr.data,
                                    data: currArr.data.data.map(
                                        (item: any) =>
                                            item.id === mpnObj.price_id
                                                ? { ...item, checkToFlag: true }
                                                : item /*{ return {...item, checkToFlag:true}}*/
                                    ),
                                },
                            };
                        });
                        console.log(data);
                    });
            } catch (err: any) {
                if (err.response) {
                    if (authCheckStatus(err.response.status)) {
                        dispatch({ type: USER_LOGOUT, payload: {} });
                    }
                }
                setModalProductLoading(false);
            }
        };
        if (
            (mpnObj.brand_id !== 0 && mpnObj.mpn !== "") ||
            (mpnObj.mpn !== "" && mpnObj.type === 2) ||
            (mpnObj.brand_id !== 0 && (mpnObj.model !== "" || mpnObj.model !== ""))
        ) {
            findMpnMatching();
        }
    }, [
        mpnObj,
        searchType,
        setModalProduct,
        dispatch,
        setModalProductLoading,
        noCategory,
        markPrice,
        setAllPrices,
        unMatched,
        currentCountryId,
    ]);

    const addNewProduct = (id: number) => {
        if (selectTemp < multySelected.length) {
            oneSelect(multySelected[selectTemp]);
        }

        const newChecked = allPrices.data.data.map((price: any) => {
            if (price.id === id) {
                return {
                    ...price,
                    isChecked: false,
                    isNewProduct: true,
                };
            }
            return price;
        });
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newChecked } });
        setMultySelected((prev: any) => prev.filter((data: any) => data.id !== mpnItem.id));
        setSelectTemp((prev: number) => prev - 1);
    };

    const draggleRef: any = useRef();

    const handleMpnModalCancel = (e: any) => {
        setMpnModal({
            ...mpnModal,
            visible: false,
        });
        setModalProduct([]);
        setMpnObj({
            price_id: "",
            brand_id: 0,
            mpn: "",
            model: "",
            type: "",
            condition: "",
            product_id: "",
        });

        setSelectTemp(0);
        setMultySelected([]);
    };

    const { visible } = mpnModal;

    const toggleSelected = (id: number) => {
        uniqueProductIdArr = [];
        const changeSelectProduct = modalProduct.map((product: any) => {
            if (product.id === id) {
                return {
                    ...product,
                    isSelectedProduct: true,
                };
            }
            return {
                ...product,
                isSelectedProduct: false,
            };
        });
        setModalProduct(changeSelectProduct);
        const findSelected = modalProduct.find((product: any) => product.id === id);

        const changeItem = allPrices.data.data.map((price: any) => {
            if (price.id === mpnItem.id && price.isNewProduct) {
                uniqueProductIdArr.push(price.product_id);
                return {
                    ...price,
                    product_id: findSelected.id,
                    brand_id: findSelected.brand_id,
                    brand_name: findSelected.brand_name,
                    category_l3_id: findSelected.category_l3_id,
                    data_entry_image_url: findSelected.image,
                    mpn: findSelected.mpn,
                    default_image_url: findSelected.image,
                    [`category_name_${currentCountry}`]: findSelected[`category_name_${currentCountry}`],
                    [`url_${currentCountry}`]: findSelected[`url_${currentCountry}`], //??????
                    isChanged: true,
                    ///
                    isChecked: false,
                    merge_product: 1, // flag when user merge product (click on select)
                };
            } else if (price.id === mpnItem.id && !price.isNewProduct) {
                uniqueProductIdArr.push(price.product_id);
                return {
                    ...price,
                    product_id: findSelected.id,
                    brand_id: findSelected.brand_id,
                    brand_name: findSelected.brand_name,
                    category_l3_id: findSelected.category_l3_id,
                    mpn: findSelected.mpn,
                    default_image_url: findSelected.image,
                    [`category_name_${currentCountry}`]: findSelected[`category_name_${currentCountry}`],
                    [`url_${currentCountry}`]: findSelected[`url_${currentCountry}`], //??????
                    isChanged: true,
                    ///
                    isChecked: false,
                    merge_product: 1, // flag when user merge product (click on select)
                };
            } else {
                return price;
            }
        });

        const newFilteredData = changeItem.filter(
            (p: any) => !uniqueProductIdArr.includes(p.product_id) || p.merge_product
        );
        setAllPrices({ ...allPrices, data: { ...allPrices.data, data: newFilteredData } });
        if (selectTemp < multySelected.length) {
            oneSelect(multySelected[selectTemp]);
            setModalProduct([]);
        }

        if (selectTemp === multySelected.length) {
            setSelectTemp(0);
            setMultySelected([]);
        }
        setModalProduct([]);
    };
    const prevMathcItem = () => {
        setSelectTemp((prev: number) => prev - 2);
        setTimeout(() => {
            oneSelect(multySelected[selectTemp - 2]);
        }, 300);
    };
    const [imageID, setImageID] = useState<any>(null);

    const findSecondImg = modalProduct.find((i: any) => i.id === imageID);

    const contentImages = (
        <div>
            {findSecondImg && mpnItem && (
                <>
                    {" "}
                    <img
                        alt="firstImage"
                        src={
                            mpnItem.default_image_url
                                ? `https://img.ep-cdn.com/images/500/500/${mpnItem.default_image_url}.webp`
                                : mpnItem.seller_image_url
                        }
                        width="400"
                    />
                    <img
                        alt="secondImage"
                        src={`https://img.ep-cdn.com/images/500/500/${findSecondImg?.image}.webp`}
                        width="400"
                    />
                </>
            )}
        </div>
    );

    const fixPrice = (id: number) => {
        const findItem = modalProduct.map((product: any) => {
            if (product.id === id) {
                return {
                    ...product,
                    fixedPrice: !product.fixedPrice,
                };
            }
            return {
                ...product,
                movePrice: !product.movePrice,
            };
        });
        setModalProduct(findItem);

        const findFixedProduct = findItem.find((price: any) => price.fixedPrice);
        if (!findFixedProduct) {
            const unChecekedProducts = findItem.map((product: any) => {
                return {
                    ...product,
                    checkedFixedProduct: false,
                };
            });
            setModalProduct(unChecekedProducts);
        }
        if (findFixedProduct) {
            setIsPriceFixing({ fixing_price_id: mpnItem.product_id, type: "checkbox", isChecked: false });
        } else {
            setIsPriceFixing(null);
        }
    };

    const [checkedFixed, setCheckedFixed] = useState<any>([]);

    function onfixPriceChange(id: any) {
        const checkdProducts = modalProduct.map((product: any) => {
            if (product.id === id) {
                return {
                    ...product,
                    checkedFixedProduct: !product.checkedFixedProduct,
                };
            }
            return product;
        });
        setModalProduct(checkdProducts);
    }

    const findFixedProduct = modalProduct.find((price: any) => price.fixedPrice);

    useEffect(() => {
        const findCheckedProducts = modalProduct.filter((price: any) => price.checkedFixedProduct);
        setCheckedFixed(findCheckedProducts);
    }, [modalProduct]);

    const editNameInMatching = (item: any) => {
        const findPoduct = modalProduct.map((product: any) => {
            if (product.id === item.id) {
                return {
                    ...product,
                    changeName: true,
                };
            }
            return {
                ...product,
                changeName: false,
                changeBrand: false,
                changeCategory: false,
                changeMpn: false,
            };
        });
        setModalProduct(findPoduct);
        setProductNameId(item.id);
        setNameValue(item[`name_${currentCountry}`]);
    };

    const saveNewName = async (id: number) => {
        //console.log(id)

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_URL_API}/product/item/update-product-name?id=${id}&name_${currentCountry}=${nameValue}`
            );
            const resetNewName = modalProduct.map((product: any) => {
                if (product.id === productNameId) {
                    return {
                        ...product,
                        changeName: false,
                        [`name_${currentCountry}`]: nameValue,
                    };
                }
                return {
                    ...product,
                    changeName: false,
                };
            });
            showMessage(data.message);
            setModalProduct(resetNewName);
            setProductNameId(null);
            setNameValue("");
        } catch (err) {
            console.log(err);
        }
    };
    const moveImage = async (product: any) => {
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_URL_API}/product/item/merge-product-data?source_id=${mpnItem.product_id}&target_id=${product.id}&price_id=${mpnItem.id}`
            );
            showMessage(data.message);
            const findProduct = modalProduct.map((item: any) => {
                if (item.id === product.id) {
                    return {
                        ...item,
                        image: data.data.default_image_url,
                    };
                }
                return item;
            });
            setModalProduct(findProduct);
        } catch (err) {
            console.log(err);
        }
    };

    const showBrandSelect = (id: number, type: string) => {
        const newData = modalProduct.map((item: any) => {
            if (item.id === id) {
                return {
                    ...item,
                    [`${type}`]: true,
                };
            }
            return {
                ...item,
                changeName: false,
                changeBrand: false,
                changeCategory: false,
                changeMpn: false,
            };
        });
        setModalProduct(newData);
    };

    const handleBrandChange = async (e: number, id: number, product_id: number) => {
        const objToSend = {
            // id:product.id,
            // [type]:text
            id: product_id,
            brand_id: e,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            showMessage(data.message);
            const findBrandName = brands.find((brand: any) => brand.id === e);
            const newData = modalProduct.map((item: any) => {
                if (item.id === id) {
                    return {
                        ...item,
                        brand_id: e,
                        brand_name: findBrandName.name,
                        changeBrand: false,
                    };
                }
                return item;
            });
            setModalProduct(newData);
        } catch (err) {
            console.log(err);
        }
    };

    const resetBrand = () => {
        const newData = modalProduct.map((item: any) => {
            return {
                ...item,
                changeName: false,
                changeBrand: false,
                changeCategory: false,
                changeMpn: false,
            };
        });
        setModalProduct(newData);
    };

    const onCategoryChange = async (value: any, id: any) => {
        ////////////////   choosen item on first place
        const objToSend = {
            id: id,
            category_l3_id: value,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            showMessage(data.message);
            //////////////-------------------------------------------------//////////////////////////////
            let oneCategory: any;
            const findCategoryL3 = categoryL3Options.find((category3: any) => category3.id === value);

            if (findCategoryL3) {
                oneCategory = findCategoryL3;
            } else {
                categoryL3Options.forEach((category3: any) => {
                    category3.children &&
                        category3.children.forEach((category4: any) => {
                            if (category4.id === value) {
                                oneCategory = category3;
                            }
                        });
                });
            }
            console.log(oneCategory);
            //const filteredCategories = categoryL3Options.filter((category:any) => category.id !== oneCategory.id)
            //setCategories([oneCategory, ...filteredCategories])

            ////////////////////////////////////

            const allCateogies: any = [];
            categoryL3Options &&
                categoryL3Options.forEach((data: any) => {
                    allCateogies.push({
                        id: data.id,
                        value: data[`name_${currentCountry}`],
                    });
                    if (data.children) {
                        data.children.forEach((childData: any) => {
                            allCateogies.push({
                                id: childData.id,
                                value: childData[`name_${currentCountry}`],
                            });
                        });
                    }
                });
            const findCategory = allCateogies.find((category: any) => Number(category.id) === Number(value));
            // if ( findCategory ) {
            //     setCategoryPreDef(findCategory.id)
            // }

            const addCatgegory = modalProduct.map((price: any) => {
                if (price.id === id) {
                    return {
                        ...price,
                        // isChanged:true,
                        // category_l3_id: value && findCategory.id,
                        // [`category_name_${ currentCountryName }`]:value && findCategory && findCategory.value
                        category_l3_id: value,
                        [`category_name_${currentCountry}`]: findCategory.value,
                        changeCategory: false,
                    };
                }
                return price;
            });
            //console.log(addCatgegory)

            setModalProduct(addCatgegory);

            //////////////-------------------------------------------------/////////////////////////////
        } catch (err) {
            console.log(err);
        }
    };

    const handleMpnChange = (item: any) => {
        //645641asd
        const findPoduct = modalProduct.map((product: any) => {
            if (product.id === item.id) {
                return {
                    ...product,
                    changeMpn: true,
                };
            }
            return {
                ...product,
                changeName: false,
                changeBrand: false,
                changeCategory: false,
                changeMpn: false,
            };
        });
        setModalProduct(findPoduct);
        setMpnEdit(item.mpn);
        // setProductNameId (item.id)
        // setNameValue(item[`name_${ currentCountry }`])
    };

    const checForOutlet = (text: string) => words.find((w) => text?.toLowerCase().includes(w));
    const currency = countries?.find((c: { currency: string; name: string }) => c.name === currentCountry)?.currency;

    const saveNewMpn = async (item: any) => {
        if (!mpnEdit || mpnEdit.trim().length === 0 || mpnEdit.trim().length > 3) {
            const objToSend = {
                id: item.id,
                mpn: mpnEdit,
            };
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
                showMessage(data.message);

                const newData = modalProduct.map((prod: any) => {
                    if (prod.id === item.id) {
                        return {
                            ...prod,
                            mpn: mpnEdit.trim().length === 0 ? "" : mpnEdit,
                            changeMpn: false,
                        };
                    }
                    return prod;
                });
                setModalProduct(newData);

                setMpnEdit("");
            } catch (err) {
                console.log(err);
            }
        } else {
            openNotification("Mpn word has to have 0 or more than 3 characters!");
        }
    };

    const resetMpn = () => {
        const newData = modalProduct.map((item: any) => {
            return {
                ...item,
                changeName: false,
                changeBrand: false,
                changeCategory: false,
                changeMpn: false,
            };
        });
        setModalProduct(newData);
        setMpnEdit("");
    };

    const [isPriceFixing, setIsPriceFixing] = useState<{
        fixing_price_id: number;
        type: "aim" | "checkbox";
        isChecked: boolean;
    } | null>(null);

    const handleFixingPrice = (id: number, type: "aim" | "checkbox") => {
        if (isPriceFixing) {
            setIsPriceFixing(null);
            // setCheckedFixed([])  //delete all checked porducts
            // setModalProduct((curr:any) => curr.map((p:any) => ({...p, hhhhhhh})))

            setModalProduct((curr: any) =>
                curr.map((product: any) => ({
                    ...product,
                    movePrice: false,
                    checkedFixedProduct: false,
                }))
            );
        } else {
            setIsPriceFixing({ fixing_price_id: id, type: type, isChecked: false });

            setModalProduct((curr: any) =>
                curr.map((product: any) => ({
                    ...product,
                    movePrice: true,
                }))
            );
        }
    };

    // console.log( isPriceFixing?.isChecked )

    const saveFixedProducts = async () => {
        const onlyId = checkedFixed.map((product: any) => product.id);
        let obj = {
            product_id: isPriceFixing?.type === "aim" ? isPriceFixing.fixing_price_id : findFixedProduct.id,
            products: onlyId,
        };

        // if (!isPriceFixing) {

        // }

        if (isPriceFixing?.type === "checkbox" && isPriceFixing.isChecked) {
            obj["products"] = [isPriceFixing.fixing_price_id, ...onlyId];
        }
        // if (isPriceFixing?.type === 'aim' ) {
        //   console.log(isPriceFixing)
        //   obj['product_id']  = isPriceFixing.fixing_price_id
        // }

        //console.log(obj)

        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/update-product-price`, obj);
            const resetAllProducts = modalProduct.map((product: any) => {
                return {
                    ...product,
                    fixedPrice: false,
                    movePrice: false,
                    checkedFixedProduct: false,
                };
            });
            setModalProduct(resetAllProducts);
            showMessage(data.message);
            setIsPriceFixing(null);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div id="match-modal">
            <Modal
                width={2000}
                title={
                    <div style={{ width: "100%", display: "flex", height: "22px" }}>
                        <div
                            style={{
                                width: "100%",
                                cursor: "move",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            onMouseOut={() => {
                                setMpnModal({
                                    ...mpnModal,
                                    disabled: true,
                                });
                            }}
                            onFocus={() => {}}
                            onBlur={() => {}}
                        >
                            {/* <span>Drag <DragOutlined /></span> */}
                            <div style={{ marginRight: "auto", marginLeft: "2rem", marginTop: "1.2rem" }}>
                                {(findFixedProduct || isPriceFixing?.type === "aim") && (
                                    <Popconfirm
                                        title="Are you sure？"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={saveFixedProducts}
                                    >
                                        <Button className="fix-btn" style={{ marginRight: "5px" }} danger>
                                            Fix product
                                        </Button>
                                    </Popconfirm>
                                )}
                                {findFixedProduct && <Tag color="magenta">{findFixedProduct.id}</Tag>}
                                {isPriceFixing?.type === "aim" && (
                                    <Tag color="magenta">{isPriceFixing.fixing_price_id}</Tag>
                                )}
                                {isPriceFixing?.type === "checkbox" && isPriceFixing.isChecked && (
                                    <Tag color="processing">{isPriceFixing.fixing_price_id}</Tag>
                                )}
                                {checkedFixed.length > 0 &&
                                    checkedFixed.map((product: any) => (
                                        <Tag color="processing" key={product.id}>
                                            {product.id}
                                        </Tag>
                                    ))}
                            </div>
                        </div>
                        <div style={{ width: "150px", position: "absolute", right: "60px", top: "-1rem" }}>
                            <Button disabled={selectTemp > 1 ? false : true} onClick={() => prevMathcItem()}>
                                Prev
                            </Button>
                            <Button
                                onClick={() => {
                                    setModalProductLoading(true);
                                    selectTemp < multySelected.length
                                        ? skipPrice(multySelected[selectTemp])
                                        : handleMpnModalOk();
                                }}
                                danger
                                style={{ marginTop: "3rem", marginLeft: "5px", width: "60px" }}
                            >
                                {selectTemp < multySelected.length ? "Next" : "Finish"}
                            </Button>
                        </div>
                    </div>
                }
                open={visible}
                onOk={handleMpnModalOk}
                onCancel={handleMpnModalCancel}
                footer={false}
                modalRender={(modal) => (
                    // <Draggable
                    //   disabled={disabled}
                    //   bounds={bounds}
                    //   onStart={(event:any, uiData:any) => onStart(event, uiData)}
                    // >
                    <div ref={draggleRef}>{modal}</div>
                    // </Draggable>
                )}
            >
                <br />
                <div>
                    <div style={{ width: "80%", position: "absolute", top: "1rem" }}>
                        <ProgressLine selectTemp={selectTemp} multySelected={multySelected} />
                    </div>
                    <Row gutter={16} style={{ flexFlow: "row" }}>
                        <Card className="all-prices-card" style={{ backgroundColor: "beige" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Image
                                    width={90}
                                    src={
                                        mpnItem.default_image_url
                                            ? `https://img.ep-cdn.com/images/500/500/${mpnItem.default_image_url}.webp`
                                            : mpnItem.seller_image_url
                                    }
                                />
                                <a
                                    href={mpnItem.product_url}
                                    onClick={() => window.open(mpnItem.product_url, "", "width=900,height=1080")}
                                    className="shop-link"
                                    style={{ marginTop: "24px", color: "#01b0f5", textDecoration: "underline" }}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Shop
                                </a>
                            </div>
                            <div style={{ height: "300px", width: "220px" }}>
                                {!isPriceFixing || isPriceFixing?.type === "aim" ? (
                                    <AimOutlined
                                        style={{
                                            cursor: "pointer",
                                            fontSize: isPriceFixing?.type === "aim" ? "18px" : "",
                                            color: isPriceFixing?.type === "aim" ? "#c20909" : "",
                                        }}
                                        onClick={() => handleFixingPrice(mpnItem.product_id, "aim")}
                                    />
                                ) : (
                                    <Checkbox
                                        className="fix-price-checkbox"
                                        onChange={(e) =>
                                            setIsPriceFixing((curr) =>
                                                curr ? { ...curr, isChecked: !curr.isChecked } : null
                                            )
                                        }
                                    />
                                )}
                                <br />
                                <b>Product ID</b> {mpnItem.product_id}
                                <br></br>
                                <b>Brand</b> {mpnItem.brand_name}
                                <br></br>
                                <b>Mpn</b> {mpnItem.mpn}
                                <br></br>
                                <b>Category</b> {mpnItem.seller_category}
                                <br></br>
                                <b>Name</b>
                                <span>
                                    {" "}
                                    {HighlilghtText(mpnObj.model, mpnItem.initName)}{" "}
                                    {checForOutlet(mpnItem.initName) ? (
                                        <span className="mpn-modal-span-outlet">OUTLET</span>
                                    ) : (
                                        ""
                                    )}
                                </span>
                                <br></br>
                                <b>Price</b>{" "}
                                <span style={{ color: "darkred" }}>
                                    {numberWithCommas(String(mpnItem.price).replace(".", ","))} {currency}{" "}
                                </span>
                                <br />
                            </div>
                            {
                                /*(!uncategorized || !noCategory)*/ !noCategory && (
                                    <Button
                                        type="default"
                                        shape="round"
                                        onClick={() => addNewProduct(mpnItem.id)}
                                        style={{
                                            position: "absolute",
                                            width: "140px",
                                            height: "40px",
                                            fontSize: "18px",
                                            bottom: "3rem",
                                            right: "4rem",
                                        }}
                                    >
                                        New product
                                    </Button>
                                )
                            }
                        </Card>
                        {modalProductLoading ? (
                            <Spin style={{ marginTop: "10%", marginLeft: "10%" }} />
                        ) : (
                            <div className="card-container ant-row">
                                {modalProduct.length > 0
                                    ? sortProducts(
                                          modalProduct
                                      ) /*.sort((a:any,b:any) => b.prices_count -a.prices_count ).sort((a:any, b:any) => a.category_l3_id === 1 ? 1 : b.category_l3_id === 1 ? -1 : a.category_l3_id - b.category_l3_id)*/
                                          .map((product: any, index: number) => (
                                              <Card
                                                  key={product.id}
                                                  className={`all-prices-card ${
                                                      mpnItem.product_id === product.id ? "mpn-card-same-product" : ""
                                                  }`}
                                                  style={
                                                      product.isSelectedProduct
                                                          ? { border: "3px solid green", backgroundColor: "lightgreen" }
                                                          : {}
                                                  }
                                              >
                                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                      <Image
                                                          width={90}
                                                          src={`https://img.ep-cdn.com/images/500/500/${product.image}.webp`}
                                                      />
                                                      <Popover
                                                          className="popover-images"
                                                          placement="topRight"
                                                          title="Compare images"
                                                          content={contentImages}
                                                          trigger="click"
                                                      >
                                                          <ZoomInOutlined
                                                              style={{
                                                                  fontSize: "20px",
                                                                  marginLeft: "2rem",
                                                                  marginTop: "1rem",
                                                              }}
                                                              onClick={() => {
                                                                  setImageID(product.id);
                                                              }}
                                                          />
                                                      </Popover>
                                                      <div
                                                          style={{
                                                              display: "flex",
                                                              flexDirection: "column",
                                                              justifyContent: "space-around",
                                                          }}
                                                      >
                                                          <Badge
                                                              count={product.prices_count}
                                                              style={{
                                                                  backgroundColor: "#1d9696",
                                                                  width: "33px",
                                                                  height: "25px",
                                                                  fontSize: "15px",
                                                                  paddingTop: "1.2px",
                                                                  marginLeft: "2rem",
                                                              }}
                                                          />
                                                          <div style={{ alignSelf: "center" }}>
                                                              {countries.map((country: { name: string }) => (
                                                                  <a
                                                                      key={country.name}
                                                                      href={product[`url_${country.name}`]}
                                                                      onClick={() =>
                                                                          product[`url_${country.name}`]
                                                                              ? window.open(
                                                                                    product[`url_${country.name}`],
                                                                                    "",
                                                                                    "width=900, height=1080, top=0, left=960"
                                                                                )
                                                                              : () => {}
                                                                      }
                                                                      className="shop-link"
                                                                      style={{
                                                                          marginLeft: "0.5rem",
                                                                          color: product[`url_${country.name}`]
                                                                              ? "#01b0f5"
                                                                              : "gray",
                                                                          textDecoration: "underline",
                                                                          cursor: product[`url_${country.name}`]
                                                                              ? "pointer"
                                                                              : "default",
                                                                          pointerEvents: product[`url_${country.name}`]
                                                                              ? "all"
                                                                              : "none",
                                                                      }}
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                  >
                                                                      {country.name.toUpperCase()}
                                                                  </a>
                                                              ))}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div style={{ height: "300px", width: "220px" }}>
                                                      {!product.movePrice ? (
                                                          <AimOutlined
                                                              onClick={() => fixPrice(product.id)}
                                                              style={{
                                                                  cursor: "pointer",
                                                                  fontSize: product.fixedPrice ? "18px" : "",
                                                                  color: product.fixedPrice ? "#c20909" : "",
                                                              }}
                                                          />
                                                      ) : (
                                                          <Checkbox
                                                              className="fix-price-checkbox"
                                                              onChange={() => onfixPriceChange(product.id)}
                                                          />
                                                      )}
                                                      <br />
                                                      {!product.image && (
                                                          <Popconfirm
                                                              placement="top"
                                                              title={"Are you sure?"}
                                                              onConfirm={() => moveImage(product)}
                                                              okText="Yes"
                                                              cancelText="No"
                                                          >
                                                              {" "}
                                                              <FileImageOutlined
                                                                  style={{
                                                                      position: "absolute",
                                                                      left: "45px",
                                                                      top: "122px",
                                                                      cursor: "pointer",
                                                                  }}
                                                              />
                                                          </Popconfirm>
                                                      )}
                                                      <b>Product ID</b> {product.id}
                                                      <br></br>
                                                      <b>Brand</b>
                                                      {!product.changeBrand ? (
                                                          <span
                                                              onDoubleClick={() =>
                                                                  showBrandSelect(product.id, "changeBrand")
                                                              }
                                                              className="drawer-am-info-span"
                                                          >
                                                              {" "}
                                                              {product.brand_name ? product.brand_name : "..."}{" "}
                                                          </span>
                                                      ) : (
                                                          <div style={{ display: "flex" }}>
                                                              <Select
                                                                  showSearch
                                                                  //allowClear={undefined}
                                                                  style={{ width: "100%", marginBottom: "0.5rem" }}
                                                                  placeholder="Select brand"
                                                                  defaultActiveFirstOption
                                                                  onChange={(e) =>
                                                                      handleBrandChange(e, product.id, product.id)
                                                                  }
                                                                  className="all-prices-column-brand"
                                                                  value={product.brand_id}
                                                                  filterOption={(input, option: any) =>
                                                                      option.children
                                                                          .toLowerCase()
                                                                          .indexOf(input.toLowerCase()) >= 0
                                                                  }
                                                                  size="small"
                                                              >
                                                                  {brands &&
                                                                      brands.map((brand: any) => (
                                                                          <Select.Option
                                                                              key={brand.id}
                                                                              value={brand.id}
                                                                          >
                                                                              {brand.name}
                                                                          </Select.Option>
                                                                      ))}
                                                              </Select>{" "}
                                                              <CloseSquareOutlined
                                                                  onClick={resetBrand}
                                                                  style={{
                                                                      position: "relative",
                                                                      left: "0.5rem",
                                                                      top: "0.3rem",
                                                                      cursor: "pointer",
                                                                  }}
                                                              />
                                                          </div>
                                                      )}{" "}
                                                      <br></br>
                                                      <div
                                                          style={{
                                                              display: "flex",
                                                              flexDirection: !product.changeMpn ? "row" : "column",
                                                          }}
                                                      >
                                                          <b>Mpn</b>
                                                          {!product.changeMpn ? (
                                                              <span
                                                                  onDoubleClick={() => handleMpnChange(product)}
                                                                  className="drawer-am-info-span"
                                                                  style={{ marginLeft: "3px" }}
                                                              >
                                                                  {" "}
                                                                  {product.mpn ? (
                                                                      product.mpn
                                                                  ) : (
                                                                      <div
                                                                          style={{
                                                                              width: "50px",
                                                                              height: "15px",
                                                                              marginTop: "4px",
                                                                          }}
                                                                      >
                                                                          {" "}
                                                                      </div>
                                                                  )}{" "}
                                                              </span>
                                                          ) : (
                                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                                  <Input
                                                                      size="small"
                                                                      style={{ width: "180px", marginLeft: "3px" }}
                                                                      value={mpnEdit}
                                                                      onChange={(e) => setMpnEdit(e.target.value)}
                                                                      placeholder="Mpn"
                                                                  />
                                                                  <Button
                                                                      size="small"
                                                                      style={{ marginLeft: "3px" }}
                                                                      onClick={() => saveNewMpn(product)}
                                                                  >
                                                                      Save
                                                                  </Button>

                                                                  <CloseSquareOutlined
                                                                      onClick={resetMpn}
                                                                      style={{ marginLeft: "3px", cursor: "pointer" }}
                                                                  />
                                                              </div>
                                                          )}
                                                      </div>
                                                      <b>Category</b>{" "}
                                                      {!product.changeCategory ? (
                                                          <span
                                                              onDoubleClick={() =>
                                                                  showBrandSelect(product.id, "changeCategory")
                                                              }
                                                              className="drawer-am-info-span"
                                                          >
                                                              {" "}
                                                              {product[`category_name_${currentCountry}`]
                                                                  ? product[`category_name_${currentCountry}`]
                                                                  : "..."}{" "}
                                                          </span>
                                                      ) : (
                                                          <div style={{ display: "flex" }}>
                                                              <TreeSelect
                                                                  showSearch
                                                                  treeDataSimpleMode
                                                                  value={product.category_l3_id}
                                                                  placeholder="Select category"
                                                                  style={{
                                                                      width: "100%",
                                                                      marginBottom: 6,
                                                                  }}
                                                                  onChange={(e) => onCategoryChange(e, product.id)}
                                                                  filterTreeNode={(search: any, item: any) => {
                                                                      return (
                                                                          item.title.props?.children[0]
                                                                              ?.toLowerCase()
                                                                              .indexOf(search.toLowerCase()) >= 0
                                                                      );
                                                                  }}
                                                                  size="small"
                                                              >
                                                                  {categoryL3Options &&
                                                                      categoryL3Options.map((category: any) =>
                                                                          category.children.length === 0 ? (
                                                                              <TreeNode
                                                                                  key={category.id}
                                                                                  value={category.id}
                                                                                  title={
                                                                                      <>
                                                                                          {`${category.id}: ${
                                                                                              category[
                                                                                                  `name_${currentCountry}`
                                                                                              ]
                                                                                          }`}{" "}
                                                                                          <span
                                                                                              style={{
                                                                                                  fontSize: "12px",
                                                                                                  opacity: "0.8",
                                                                                                  color: "gray",
                                                                                              }}
                                                                                          >
                                                                                              {" "}
                                                                                              ({category.l2_name}){" "}
                                                                                          </span>
                                                                                      </>
                                                                                  }
                                                                              />
                                                                          ) : (
                                                                              <TreeNode
                                                                                  key={category.id}
                                                                                  value={category.id}
                                                                                  title={
                                                                                      <>
                                                                                          {`${category.id}: ${
                                                                                              category[
                                                                                                  `name_${currentCountry}`
                                                                                              ]
                                                                                          }`}{" "}
                                                                                          <span
                                                                                              style={{
                                                                                                  fontSize: "12px",
                                                                                                  opacity: "0.8",
                                                                                                  color: "gray",
                                                                                              }}
                                                                                          >
                                                                                              {" "}
                                                                                              ({category.l2_name}){" "}
                                                                                          </span>
                                                                                      </>
                                                                                  }
                                                                              >
                                                                                  {category.children.map(
                                                                                      (itemChild: any) => (
                                                                                          <TreeNode
                                                                                              key={itemChild.id}
                                                                                              value={itemChild.id}
                                                                                              title={
                                                                                                  <>
                                                                                                      {`${
                                                                                                          itemChild.id
                                                                                                      }: ${
                                                                                                          itemChild[
                                                                                                              `name_${currentCountry}`
                                                                                                          ]
                                                                                                      } `}{" "}
                                                                                                  </>
                                                                                              }
                                                                                          />
                                                                                      )
                                                                                  )}
                                                                              </TreeNode>
                                                                          )
                                                                      )}
                                                              </TreeSelect>
                                                              <CloseSquareOutlined
                                                                  onClick={resetBrand}
                                                                  style={{
                                                                      position: "relative",
                                                                      left: "0.5rem",
                                                                      top: "0.5rem",
                                                                      cursor: "pointer",
                                                                  }}
                                                              />
                                                          </div>
                                                      )}{" "}
                                                      <br></br>
                                                      {!product.changeName ? (
                                                          <>
                                                              <b>Name </b>
                                                              <span
                                                                  onDoubleClick={() => editNameInMatching(product)}
                                                                  className="drawer-am-info-span"
                                                              >
                                                                  {HighlilghtText(
                                                                      mpnObj.model,
                                                                      showNameByCaountry(
                                                                          product,
                                                                          currentCountry,
                                                                          countries
                                                                      )
                                                                  )}
                                                                  {checForOutlet(
                                                                      showNameByCaountry(product, currentCountry)
                                                                  ) ? (
                                                                      <span className="mpn-modal-span-outlet">
                                                                          OUTLET
                                                                      </span>
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                              </span>
                                                          </>
                                                      ) : (
                                                          <div>
                                                              <TextArea
                                                                  value={nameValue}
                                                                  onChange={(e) => setNameValue(e.target.value)}
                                                              ></TextArea>
                                                              <Button
                                                                  size="small"
                                                                  danger
                                                                  style={{ margin: "5px 0px" }}
                                                                  onClick={() => saveNewName(product.id)}
                                                              >
                                                                  Save
                                                              </Button>
                                                              <CloseSquareOutlined
                                                                  onClick={resetBrand}
                                                                  style={{
                                                                      position: "relative",
                                                                      left: "10rem",
                                                                      cursor: "pointer",
                                                                  }}
                                                              />
                                                          </div>
                                                      )}
                                                      <br></br>
                                                      {product[`min_price_${currentCountry}`] &&
                                                          product[`max_price_${currentCountry}`] && (
                                                              <span style={{ color: "darkred" }}>
                                                                  {numberWithCommas(
                                                                      String(
                                                                          product[`min_price_${currentCountry}`]
                                                                      ).replace(".", ",")
                                                                  )}{" "}
                                                                  -{" "}
                                                                  {numberWithCommas(
                                                                      String(
                                                                          product[`max_price_${currentCountry}`]
                                                                      ).replace(".", ",")
                                                                  )}{" "}
                                                                  {currency}
                                                              </span>
                                                          )}
                                                  </div>
                                                  {mpnItem.product_id !== product.id && product.category_l3_id !== 1 ? (
                                                      <Button
                                                          danger
                                                          shape="round"
                                                          onClick={
                                                              () =>
                                                                  //setTimeout(() => {
                                                                  {
                                                                      setModalProductLoading(true);
                                                                      toggleSelected(product.id);
                                                                  }
                                                              /*}, 10)*/
                                                          }
                                                          style={{
                                                              position: "absolute",
                                                              width: "120px",
                                                              height: "40px",
                                                              fontSize: "18px",
                                                              bottom: "3rem",
                                                              right: "5rem",
                                                          }}
                                                      >
                                                          Select
                                                      </Button>
                                                  ) : (
                                                      ""
                                                  )}
                                              </Card>
                                          )) /*<Button 
                    onClick={()=>{ selectTemp < multySelected.length ? skipPrice(multySelected[selectTemp]): handleOk() }} 
                    danger 
                    style={{ marginTop:'3rem', marginLeft:'3px', width:'60px'}}
                  >

               {selectTemp < multySelected.length ? 'Next' :'Finish'}
                  </Button>*/
                                    : !modalProductLoading &&
                                      modalProduct.length === 0 && (
                                          <button
                                              className={`mpn-modal-next-button ${
                                                  selectTemp < multySelected.length ? "" : "mpn-modal-finish-button"
                                              }`}
                                              onClick={() => {
                                                  setModalProductLoading(true);
                                                  selectTemp < multySelected.length
                                                      ? skipPrice(multySelected[selectTemp])
                                                      : handleMpnModalOk();
                                              }}
                                          >
                                              <span className="mpn-modal-next-button-text">
                                                  {selectTemp < multySelected.length ? "Next" : "Finish"}
                                              </span>
                                              {selectTemp < multySelected.length && (
                                                  <span className="mpn-modal-next-button-icon">
                                                      <svg
                                                          width="16"
                                                          height="16"
                                                          viewBox="2 2 16 16"
                                                          fill="currentColor"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                          <path
                                                              fillRule="evenodd"
                                                              d="M8.776 3.553a.5.5 0 01.671.223l3 6a.5.5 0 010 .448l-3 6a.5.5 0 11-.894-.448L11.44 10 8.553 4.224a.5.5 0 01.223-.671z"
                                                              clipRule="evenodd"
                                                          />
                                                      </svg>
                                                  </span>
                                              )}
                                          </button>
                                      )}
                            </div>
                        )}
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default AllPricesMpnModal;
