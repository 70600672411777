export const SHOP_ALL = "SHOP_ALL"

export interface ShopAll {
    type: typeof SHOP_ALL
    payload:any
}

export const SHOP_SINGLE = "SHOP_SINGLE"

export interface ShopOne {
    type: typeof SHOP_SINGLE
    payload:any
}
//------------remove one shop-----------
export const RESET_SHOP_SINGLE = "RESET_SHOP_SINGLE"

export interface ResetShopOne {
    type: typeof RESET_SHOP_SINGLE
    payload:any
}

export const SHOP_CREATE = "SHOP_CREATE"

export interface ShopCreate {
    type: typeof SHOP_CREATE
    payload:any
}

export const SHOP_EDIT = "SHOP_EDIT"

export interface ShopEdit {
    type: typeof SHOP_EDIT
    payload:any
}

export const SHOP_DELETE = "SHOP_DELETE"

export interface ShopDelete {
    type: typeof SHOP_DELETE
    payload:any
}

//------shop rules-------

export const SHOP_RULES_ALL = "SHOP_RULES_ALL"

export interface ShopRulesAll {
    type: typeof SHOP_RULES_ALL
    payload:any
}




//******* for single shop *********/

//------shop rules by id-------


export const SHOP_RULES_BY_ID = "SHOP_RULES_BY_ID"

export interface ShopRulesbyId {
    type: typeof SHOP_RULES_BY_ID
    payload:any
}

export const SHOP_RULES_EDIT = "SHOP_RULES_EDIT"

export interface ShopRulesEdit {
    type: typeof SHOP_RULES_EDIT
    payload:any
}

//add new shop rules

export const SHOP_RULES_CREATE = "SHOP_RULES_CREATE"

export interface ShopRulesCreate {
    type: typeof SHOP_RULES_CREATE
    payload:any
}

//shop rules exist

export const SHOP_RULES_EXIST = "SHOP_RULES_EXIST"

export interface ShopRulesExist {
    type: typeof SHOP_RULES_EXIST
    payload:any
}