import { Button, Input, Modal } from "antd";
import axios from "axios";
import showMessage from "../../../../../../shared/MessagesInfo/message";
import { useEffect, useRef, useState } from "react";

interface ShortDescriptionModalProps {
    shortDescriptionVisible: boolean;
    setShortDescriptionVisible: React.Dispatch<React.SetStateAction<boolean>>;
    shortDescription: string;
    setShortDescription: React.Dispatch<React.SetStateAction<string>>;
    productId: number | undefined;
    currentCountryId: number;
}

const ShortDescriptionModal = (props: ShortDescriptionModalProps) => {
    const modalRef: any = useRef(null);
    const { shortDescription, shortDescriptionVisible, setShortDescriptionVisible, productId, currentCountryId, setShortDescription } = props;
    const [loadingSave, setLoadingSave] = useState(false);

    const saveShortDescription = async () => {
        const objToSend = {
            product_id: productId,
            preview: shortDescription.length > 0 ? shortDescription : null,
            country_id: currentCountryId,
        };
        setLoadingSave(true);
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/item/update-short-description`, objToSend);
            showMessage(data.message);
            setLoadingSave(false);
            setShortDescriptionVisible(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (shortDescriptionVisible && modalRef.current) {
            modalRef.current.focus(); // Preusmerava fokus na modal
        }
    }, [shortDescriptionVisible]);

    return (
        <Modal title="Short description" centered open={shortDescriptionVisible} onCancel={() => setShortDescriptionVisible(false)} width={600} footer={false}>
            <div ref={modalRef} tabIndex={-1}>
                <Input.TextArea rows={8} placeholder="Enter short description text" onChange={(e) => setShortDescription(e.target.value)} value={shortDescription} />
                <Button type="primary" onClick={saveShortDescription} style={{ marginTop: "1rem" }} loading={loadingSave}>
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default ShortDescriptionModal;
