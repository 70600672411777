import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { AttributeType, CategoryType, ShopType } from "../../types/AttributesAnalytics.type";

type RecordType = CategoryType | ShopType | AttributeType;

interface ExpandIconProps {
    expanded: boolean;
    onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void;
    record: RecordType;
}

const ExpandIcon = (props: ExpandIconProps) => {
    const { expanded, onExpand, record } = props;
    return expanded ? (
        <MinusCircleTwoTone style={{ fontSize: "22px" }} onClick={(e) => onExpand(record, e)} />
    ) : (
        <PlusCircleTwoTone style={{ fontSize: "22px" }} onClick={(e) => onExpand(record, e)} />
    );
};

export default ExpandIcon;
