import { getCorrectLatinWords } from "../api/apiData";
import { KeyEventsParam } from "../types/keyEvents";

export const parenthesesOnly = (setProducts: KeyEventsParam) => {
    setProducts((curr) =>
        curr.map((product) => {
            if (product.isChecked && product.name && product.name.length > 2) {
                const wordToArray = product.name.split(" ");
                const findParentheses = wordToArray.filter((item) => {
                    if (item.indexOf(`(`) > -1 && item.indexOf(`)`) > -1) {
                        return item.trim();
                    }
                    return null;
                });
                return {
                    ...product,
                    name: findParentheses[0] ? findParentheses[0].slice(1, -1) : product.name,
                };
            }
            return product;
        })
    );
};

export const correctName = async (setProducts: KeyEventsParam, currentCountryId: number) => {
    getCorrectLatinWords(currentCountryId).then((resp) => {
        setProducts((curr) =>
            curr.map((product) => {
                if (product.isChecked && product.name) {
                    const wordToArray = product.name.split(" ");
                    let keys = Object.keys(resp);
                    const newCorrectWords = wordToArray.map((word: string) => {
                        for (let index = 0; index < keys.length; index++) {
                            const element = keys[index];
                            if (word.trim().toLowerCase().includes(element.trim().toLowerCase())) {
                                const numberOfCharacter = element.length;
                                const newWord = word.slice(numberOfCharacter, word.length);
                                const clearWord = resp[element] + newWord;
                                return clearWord.toLowerCase();
                            }
                        }
                        return word;
                    });
                    return {
                        ...product,
                        name: newCorrectWords.join(" "),
                    };
                }
                return product;
            })
        );
    });
};
