import { Button, Form, Input, Modal } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import React from 'react'

interface CreateRuleModalProps {
    createRuleModal: boolean
    handleOk: () => void 
    handleCancel: () => void
    saveNewRule: (e: any) => Promise<void>
    //setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>
    form: FormInstance<any>
}

const CreateRuleModal:React.FC<CreateRuleModalProps> = ({  createRuleModal, handleOk, handleCancel, saveNewRule, form }) => 
   
    <Modal 
        title="Create rule" 
        open={ createRuleModal } 
        onOk={ handleOk } 
        onCancel={ handleCancel }
        footer={ false }
    >
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={saveNewRule}
            autoComplete="off"
            form={form}
        >
        <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your Name!' }]}
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input your Description!' }]}
        >
            <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
            Save
            </Button>
        </Form.Item>
        </Form>
        
    </Modal>



export default CreateRuleModal