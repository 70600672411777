import { Button, Form, InputNumber, Space } from "antd";
import { FieldType } from "../../types/type";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";

interface SearchFormType {
    onFinish: (e: { id: string }) => Promise<void>;
    params: { id: string };
    loadingSearch: boolean;
}

const SearchForm = (props: SearchFormType) => {
    const [form] = Form.useForm();
    const { onFinish, params, loadingSearch } = props;

    useEffect(() => {
        if (params.id) {
            form.setFieldValue("id", params.id);
        }
    }, [params.id, form]);

    return (
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "5px", width: "100%" }}
            layout="inline"
            form={form}
        >
            <Space.Compact style={{ width: "100%" }}>
                <Form.Item<FieldType> name="id" rules={[{ required: true, message: "Id is Required!" }]} style={{ margin: "0", width: "90%" }}>
                    <InputNumber placeholder="Enter Product Id" min={0} controls={false} />
                </Form.Item>

                <Form.Item style={{ margin: "0" }}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={loadingSearch}></Button>
                </Form.Item>
            </Space.Compact>
        </Form>
    );
};

export default SearchForm;
