import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { FAQItemType } from '../../pages/FAQ'
import { ArrowRightOutlined } from '@ant-design/icons'

type FaqFormType = {
    c: string
    formData: FAQItemType 
    index: number
    countries: { name: string; value: number; order: number; langOrder: number; currency: string; locale_code: string; }[]
    onFinish: (values: FAQItemType) => Promise<void>
    deleteOneFaq: (values: FAQItemType, country: string) => Promise<void> 
    itemToSave: {
        id: number
        country: string 
    } | null
}

const FaqForm:React.FC<FaqFormType> = ({c, formData, index, countries, onFinish, deleteOneFaq, itemToSave }) => {
    const [form ] = Form.useForm()
    useEffect(() => {
        itemToSave && itemToSave.id === formData.id && itemToSave.country === c && form.setFieldsValue(formData)
    },[ formData, c, form, itemToSave])

    const countryArr = countries.filter(country => country.locale_code !== c ).map( country => {
        const temp = formData[`question_${ country.locale_code }`] && formData[`answer_${ country.locale_code }`]
        return {
            from:c,
            to:country.locale_code,
            hasFaq: temp  ? false:true
        }
    })
    
    return (
        <Form 
            form={form}   
            key={ formData.id + c } 
            name={ formData.id + c }
            layout="vertical" 
            initialValues={ formData } 
            onFinish={onFinish}
            style={{ 
                position:'relative',
                marginBottom: '20px', border:'1px solid lightgray', borderRadius:"8px", backgroundColor:'white',
                ...(formData.isLoading && itemToSave?.country === c && { border:'1px solid skyblue' }),
                ...(formData.isLoadingDelete && itemToSave?.country === c && { border:'1px solid #ff7875' })
                
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 0 }}
        >
            <div style={{ fontWeight:"bold", fontSize:'18px', backgroundColor:'white', borderTopLeftRadius:'8px', borderTopRightRadius:"8px", width:'100%', textAlign:"center", borderBottom:'1px solid lightgray' }}>
                { c.toUpperCase() }
            </div>
            <div style={{  padding:"2rem",margin:'0.5rem', backgroundColor:"whitesmoke", borderRadius:"8px"}}>
                <div className='faq-item-number'>
                    { index + 1}
                </div>
                <Form.Item
                    label=""
                    name="id"
                    style={{ display:"none"}}  //????????????????????????????????? check this for id
                >     
                    <Input style={{ display:"none"}} />
                </Form.Item>
                <Form.Item
                    label=""
                    name="country"
                    style={{ display:"none"}}  
                    initialValue={ c }
                >     
                    <Input style={{ display:"none"}} />
                </Form.Item>
                <Form.Item
                    label="Question"
                    name={`question_${ c }`}
                    rules={[{ required: true, message: 'Question is required!' }]}
                    style={{ margin:"0.4rem 0 0 0" }}
                >     
                    <Input placeholder='Question' />
                </Form.Item>
                <Form.Item
                    label="Button Name"
                    name={`btn_name_${ c }`}
                    style={{ margin:"0.4rem 0 0 0" }}
                >     
                    <Input placeholder='Btn Name' />
                </Form.Item>
                <Form.Item
                    label="Button link"
                    name={`btn_link_${ c }`}
                    style={{ margin:"0.4rem 0 0 0" }}
                >     
                    <Input placeholder='Btn link' />
                </Form.Item>
                <Form.Item
                    label="Answer"
                    name={`answer_${ c }`}
                    rules={[{ required: true, message: 'Answer is required!' }]}
                    style={{ margin:"0.4rem 0 0 0" }}
                >
                    <Input.TextArea placeholder='Answer' className='faq-answer-textarea' rows={9} />
                </Form.Item>
                <Row justify="space-around" align="middle" style={{ marginTop:"1.5rem" }}> 
                    <Col span={14}>
                        <Space wrap>
                        {countryArr.map(( country, i ) => 
                            country.hasFaq && formData.id && formData[`question_${ c }`] && formData[`answer_${ c }`] &&
                            <Button 
                                size='small' 
                                key={ i }
                            >  
                                {  country.from.toUpperCase() }  
                                <ArrowRightOutlined style={{ fontSize:'12px' }} />  
                                { country.to.toUpperCase() } 
                            </Button>)}  
                        </Space>
                    </Col>
                        <Button
                            //icon={ formData.id ? <DeleteTwoTone />:<DeleteOutlined />}
                            //icon={ <DeleteOutlined /> }
                            size='small'
                            onClick={() => deleteOneFaq(formData, c)}
                            danger={  !formData.id }
                            loading={ formData.isLoadingDelete && itemToSave?.country === c }
                            
                        >
                            Clear
                        </Button>
                
                        <Button
                            type="primary"
                            danger={ !formData.id }
                            size='small'
                            htmlType='submit'
                            loading={ formData.isLoading && itemToSave?.country === c }
                        >
                            Save
                        </Button>
                    
                    
                </Row>
            </div>
        </Form>
        
    )
}

export default FaqForm