import axios from "axios";
import { FetchedProductData, ProductType } from "../types/types";
import { FormInstance } from "antd";
import showMessage from "../../../shared/MessagesInfo/message";
const baseUrl = process.env.REACT_APP_URL_API;

// Get Products
export const getData = async (
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    currentCountryName: string,
    currentCountryid: number,
    search: string,
    selectedShops: string[],
    selectedShopCategories: string[],
    selectedBrands: string[],
    selectedCategories: { id: string; level: string; fullCategory: string } | undefined,
    selectedSort: string | undefined,
    selectedNew: string | undefined,
    xml: boolean,
    user: boolean,
    strictCategory: boolean,
    setCount: React.Dispatch<React.SetStateAction<number | undefined>>,
    page: number,
    skus: string[],
    loadCategory: boolean
) => {
    if (!loadCategory) {
        setLoading(true);

        const searchInput = search.length > 0 ? search : undefined;
        const shops = selectedShops.length > 0 ? `&& sellers:=[${selectedShops.map((s) => `\`${s}\``).join(",")}]` : "";
        const shop_categories_filter = selectedShopCategories.length > 0 ? `&& seller_categories:=[${selectedShopCategories.map((c) => `\`${c}\``).join(",")}]` : "";
        const categories = selectedCategories ? `&& ${`category${selectedCategories.level !== "3" ? `_${selectedCategories.level}` : ""}_${currentCountryName}:=${selectedCategories.id}`} ` : "";
        const brand = selectedBrands.length > 0 ? `&& brand:=[${selectedBrands.map((b) => `\`${b}\``).join(",")}]` : ""; //user_active=1
        const active_user = user ? `&& user_active:=1` : "";
        const strict_category = strictCategory && selectedCategories?.level === "3" ? `&& category_id:=${selectedCategories.id.split("::")[0]}` : "";

        const xml_active = xml ? `&& active_prices_${currentCountryName}:=true` : "";
        const newProducts = selectedNew ? `&& condition:=${selectedNew}` : "";
        const skus_arr = skus.length > 0 ? `&& skus:=[${skus.map((b) => `\`${b}\``).join(",")}]` : "";

        try {
            const { data } = await axios.post(`${baseUrl}/prices/search`, {
                collection: "allProducts",

                data: {
                    per_page: 100,
                    page: page,
                    q: searchInput && searchInput.trim().length > 0 ? searchInput : "*",
                    ...(selectedSort && { sort_by: selectedSort }),
                    filter_by: `countries:=[${currentCountryid}] ${brand}${categories}${shops}${shop_categories_filter}${active_user}${newProducts}${xml_active}${skus_arr}${strict_category}`,
                },
            });
            data.hits.map((d: any) => d.document);
            setCount(data.found);
            setProducts(
                data.hits.map((d: { document: FetchedProductData }) => {
                    if (d.document) {
                        d.document.seller_categories && delete d.document[`seller_categories` as keyof typeof d.document];
                        d.document.sellers && delete d.document[`sellers` as keyof typeof d.document];
                    }

                    const product = d.document;
                    const cateogry = product[`category_4_${currentCountryName}` as keyof typeof product]
                        ? product[`category_4_${currentCountryName}` as keyof typeof product]
                        : product[`category_${currentCountryName}` as keyof typeof product];
                    return {
                        ...product,
                        name: product[`name_${currentCountryName}` as keyof typeof product],
                        brand_name: product.brand.split("::")[1],
                        category_name: cateogry.split("::")[1],
                        category_l3_id: cateogry.split("::")[0],
                        openName: false,
                        openMpn: false,
                        isChecked: false,
                        isChanged: false,
                        isDropdown: false,
                        openLink: false,
                        isMatching: false, // if product is on list for matching
                    };
                })
            );
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }
};

/* Shop more data for product */

export const getProductPrices = async (product_id: number, currentCountryId: number) => {
    try {
        const { data } = await axios.get(`${baseUrl}/product/item/get-all-product-prices?product_id=${product_id}&country_id=${currentCountryId}`);
        return data.data;
    } catch (err) {
        console.log(err);
    }
};

// Save Correct Word

export const saveCorrectWord = async (e: { misspelled_word: string; correct_word: string }, currentCountryId: number, form: FormInstance) => {
    const obj = {
        country_id: currentCountryId,
        misspelled_word: e.misspelled_word.trim().toLowerCase(),
        correct_word: e.correct_word.trim().toLowerCase(),
    };
    try {
        const { data } = await axios.post(`${baseUrl}/prices/word-replacement/add-word`, obj);
        showMessage(data.message);
        form.resetFields();
        return data;
    } catch (err) {
        console.log(err);
    }
};

/* get correct latin word */

export const getCorrectLatinWords = async (currentCountryId: number) => {
    try {
        const { data } = await axios.get(`${baseUrl}/prices/word-replacement/list-all?country_id=${currentCountryId}`);
        return data.data;
    } catch (err) {
        console.log(err);
    }
};

// Get disabled categories

export const getDisabledCategories = async (currentCountryName: string) => {
    const { data } = await axios.get(`${baseUrl}/product/category/list-disabled?lang=${currentCountryName}`);

    return data.data;
};

// Get brand list for select in table header

export const getBrandList = async (
    setBrandsList: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                brand_name: string;
            }[]
        >
    >
) => {
    try {
        const { data } = await axios.get(`${baseUrl}/product/brand/list-valid-brands`);
        const filteredData = data.data.filter((d: any) => d.id !== 3621); //3621
        filteredData.unshift(data.data.find((d: any) => d.id === 3621));
        setBrandsList(filteredData.map((b: { id: number; name: string }) => ({ id: b.id, brand_name: b.name })));
    } catch (err) {
        console.log(err);
    }
};
