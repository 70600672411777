import { Dispatch } from "react";
import { RemoveLSCurrentCountry, REMOVE_LS_CURRENT_COUNTRY, SetLSCurrentCountry, SET_LS_CURRENT_COUNTRY } from "./localeStorageActionsType";

export const setLSCurrentCountry = (currentCountry:string | undefined, id:number) => (dispatch:Dispatch<SetLSCurrentCountry>) => {

    dispatch({ type: SET_LS_CURRENT_COUNTRY, payload:{currentCountry:currentCountry, countryId:id}})

}

export const removeLSCurrentCountry = () => (dispatch:Dispatch<RemoveLSCurrentCountry>) => {

    dispatch({ type: REMOVE_LS_CURRENT_COUNTRY, payload:{}})

}

