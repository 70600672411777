import { message } from 'antd'

const showMessage = (msgInfo:string) => {
    message.success({
        content:`${msgInfo}`, 
        className: 'custom-class',
        style: {
            marginTop: '20vh',
            height:'100px',
            zIndex:1000
        },
        duration:2
        })
}

export default showMessage