import { Col, Form, Row, Select, Tag } from 'antd'
import React from 'react'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { capitalizeFirstLetter } from '../../../attributes/helpers/checkIsIncludes';

type TagSelectType = {
    handleKeysContain: ( e: string[], type: string ) =>void
    name: string
    type: string
    span?: number
}

const TagSelect:React.FC<TagSelectType> = ({ handleKeysContain, name, type , span}) => {
    const tagRender = (props: CustomTagProps, type:string) => {
        const { label, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={ type === 'in' ? 'green':'red'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, marginTop:'2px', marginBottom:'2px' }}
          >
            {label}
          </Tag>
        )
    }
  return  span ? <Row justify="space-between" align="middle" style={{ width:'100%' }}>
            <Col span={ 24 - span } style={{ textAlign:'end', paddingRight:'8px' }}> 
                <span>{ capitalizeFirstLetter( name )}</span>
            </Col>
            <Col span={ span }>
                <Select
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={ capitalizeFirstLetter( name ) }
                    //defaultValue={['a10', 'c12']}
                    onChange={(e) => handleKeysContain(e, type)}
                    options={[]}
                    tagRender={(props) => tagRender(props, type)}
                    popupClassName="name-mass-editing-seledt-tags"
                    dropdownStyle={{ display:'none' }}
                />
            </Col>
        </Row>:

        <Form.Item
          label={ capitalizeFirstLetter( name )} name={ name } style={{ margin:'8px', width:'414px', marginRight:'16px' }} 
        >
          <Select
              mode="tags"
              allowClear
              //style={{ width: '400px' }}
              placeholder={ capitalizeFirstLetter( name ) }
              //defaultValue={['a10', 'c12']}
              onChange={(e) => handleKeysContain(e, type)}
              options={[]}
              tagRender={(props) => tagRender(props, type)}
              popupClassName="name-mass-editing-seledt-tags"
              dropdownStyle={{ display:'none' }}
          />


        </Form.Item>
       
}

export default TagSelect