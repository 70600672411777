import { CloseOutlined } from "@ant-design/icons";
import { Tabs, Row, Col, Divider, Layout, Button, Popconfirm, Form } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { v4 as uuidv4 } from "uuid";
import "../style/AttributeMappingRules.css";
import showMessage from "../../../shared/MessagesInfo/message";
import TabPaneContent from "../components/AttributeMappingRule/TabPaneContent";
import CreateRuleModal from "../components/AttributeMappingRule/CreateRuleModal";

const { Header } = Layout;

export type Rules = {
    acr_id: number;
    field_name: string;
    field_type: string;
    id: number | string;
};

export type AttributeMappingRulesType = {
    id: number;
    name: string;
    rules: Rules[] | string;
    isChangeName: boolean;
    isChangeFormula: boolean;
    formula: string;
};

const tabPaneContent = (
    attr: AttributeMappingRulesType,
    setAttributeRules: React.Dispatch<React.SetStateAction<AttributeMappingRulesType[]>>,
    handleSave: (row: Rules) => Promise<void>,
    addNewRowRule: (id: number) => void,
    deleteRuleRow: (record: Rules) => void,
    getAttributeMapping: () => Promise<void>
) => {
    if (typeof attr.rules !== "string") {
        return (
            <TabPaneContent
                attr={attr}
                setAttributeRules={setAttributeRules}
                handleSave={handleSave}
                addNewRowRule={addNewRowRule}
                deleteRuleRow={deleteRuleRow}
                getAttributeMapping={getAttributeMapping}
            />
        );
    }
    return;
};

const AttributeMappingRules = () => {
    const [attirbuteRules, setAttributeRules] = useState<AttributeMappingRulesType[]>([]);
    const [createRuleModal, setCreateRuleModal] = useState(false);
    const [form] = Form.useForm();
    const getAttributeMapping = useCallback(async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/index`);
            const newData = data.data.map((item: Partial<AttributeMappingRulesType>) => ({
                ...item,
                isChangeFormula: false,
                rules: typeof item.rules === "string" ? JSON.parse(item.rules) : [],
                isChangeName: false,
                key: item.id?.toString(),
            }));
            setAttributeRules(newData);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getAttributeMapping();
    }, [getAttributeMapping]);

    const handleSave = async (row: Rules) => {
        setAttributeRules((curr) =>
            curr.map((a) =>
                a.id === row.acr_id
                    ? {
                          ...a,
                          rules: typeof a.rules !== "string" ? a.rules.map((r) => (r.id === row.id ? row : r)) : "",
                      }
                    : a
            )
        );
    };

    const addNewRowRule = (id: number) => {
        setAttributeRules((curr) =>
            curr.map((a) =>
                a.id === id
                    ? {
                          ...a,
                          rules:
                              typeof a.rules !== "string"
                                  ? [
                                        ...a.rules,
                                        {
                                            acr_id: id,
                                            field_name: "",
                                            field_type: "input",
                                            formula: "",
                                            id: uuidv4(), //to change on null
                                            isChangeFormula: false,
                                        },
                                    ]
                                  : [],
                      }
                    : a
            )
        );
    };

    console.log(attirbuteRules);
    const deleteRuleRow = (record: Rules) => {
        setAttributeRules((curr) =>
            curr.map((a) =>
                a.id === record.acr_id
                    ? { ...a, rules: typeof a.rules === "string" ? [] : a.rules.filter((r) => r.id !== record.id) }
                    : a
            )
        );
    };

    const showModal = () => {
        setCreateRuleModal(true);
    };

    const handleOk = () => {
        setCreateRuleModal(false);
    };

    const handleCancel = () => {
        setCreateRuleModal(false);
    };

    const saveNewRule = async (e: any) => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/create-rule`,
                e
            );
            showMessage(data.message);
            setCreateRuleModal(false);
            getAttributeMapping();
            setActiveKey(data.data.rule_id.toString());
            form.resetFields();
        } catch (err) {
            console.log(err);
        }
    };

    //console.log(attirbuteRules)

    const deleteRule = async (id: number) => {
        try {
            const { data } = await axios.delete(
                `${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/delete-rule?id=${id}`
            );
            showMessage(data.message);
            getAttributeMapping();
        } catch (err) {
            console.log(err);
        }
    };

    const tabLabel = (attr: AttributeMappingRulesType) => {
        return (
            <span style={{ width: "225px", display: "flex", justifyContent: "space-between" }}>
                {attr.name}
                <Popconfirm
                    placement="right"
                    title="Deleting rule"
                    description={`Are you sure you want delete ${attr.name} rule`}
                    onConfirm={() => deleteRule(attr.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <CloseOutlined style={{ marginTop: "0.2rem" }} />
                </Popconfirm>
            </span>
        );
    };
    const [activeKey, setActiveKey] = useState(attirbuteRules.length > 0 ? attirbuteRules[0].id.toString() : undefined);
    const onChange = (e: any) => {
        setActiveKey(e);
    };
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Attributes Mapping Rules" />
            <Row justify="center">
                <Col
                    span={16}
                    className="attribute-value-clean-rules-box"
                    style={{ backgroundColor: "white", padding: "0rem", marginTop: "1rem", height: "90vh" }}
                >
                    <Divider>Attribute value cleaning rules</Divider>
                    <Layout style={{ margin: "0 1rem 1rem 1rem", height: "81.4vh", padding: "0 1rem" }}>
                        <Header
                            className="product-img-header"
                            style={{
                                color: "whitesmoke",
                                backgroundColor: "#5a5a5a",
                                height: "1.9rem",
                                lineHeight: "30px",
                            }}
                        >
                            <span> </span>{" "}
                        </Header>
                        <Button
                            type="primary"
                            style={{ width: "100px", marginTop: "1rem", marginLeft: "1rem" }}
                            onClick={showModal}
                        >
                            Create Rule
                        </Button>
                        <CreateRuleModal
                            createRuleModal={createRuleModal}
                            handleOk={handleOk}
                            handleCancel={handleCancel}
                            saveNewRule={saveNewRule}
                            form={form}
                        />

                        {attirbuteRules.length > 0 ? (
                            <React.Fragment>
                                <Tabs
                                    onChange={onChange}
                                    type="card"
                                    //type="editable-card"
                                    activeKey={activeKey}
                                    tabPosition="left"
                                    style={{ marginTop: "1rem" }}
                                    items={attirbuteRules.map((attr) => ({
                                        label: tabLabel(attr),
                                        children: tabPaneContent(
                                            attr,
                                            setAttributeRules,
                                            handleSave,
                                            addNewRowRule,
                                            deleteRuleRow,
                                            getAttributeMapping
                                        ) /*  attr.id.toString() */,
                                        key: attr.id.toString(),
                                        style: {
                                            padding: "4rem",
                                            margin: "1rem",
                                            height: "64vh",
                                        },
                                    }))}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AttributeMappingRules;
