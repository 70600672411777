import { Col, Divider, Row, Form, UploadFile, Input, Checkbox, InputRef, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RcFile } from "antd/es/upload";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import "../style/CreateCatalog.css";
import FormSelect from "../components/CreateCatalogForm/FormSelect";
import FormInput from "../components/CreateCatalogForm/FormInput";
import FormUpolad from "../components/CreateCatalogForm/FormUpolad";
import FormCheckboxGroup from "../components/CreateCatalogForm/FormCheckboxGroup";
import FormDatePicker from "../components/CreateCatalogForm/FormDatePicker";
import FormButton from "../components/CreateCatalogForm/FormButton";
import "../style/CreateCatalog.css";
import { CreateCatalogObjEventType, FetchedDataType } from "../types/CreateCatalogTypes";
import FormTags from "../components/CreateCatalogForm/FormTags";
import Spinner from "../../../shared/Spinner/Spinner";
import moment from "moment";
import showMessage from "../../../shared/MessagesInfo/message";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { capitalize } from "../helper/firstLetter";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import StoredImages from "../components/CreateCatalog/StoredImages";
import { CategoryType } from "../../categories/pages/AllCategories";
import HelmetTitle from "../../../shared/Head/HelmetTitle";

let cityInit: { id: number; name: string }[] = [];
// let url_id_for_edit:null | number = null

const CreateCatalog = (props: { location: string }) => {
    const [fetchedData, setFetchedData] = useState<FetchedDataType>({
        shops: [],
        categories: [],
        cities: [],
        types: [],
        tags: [],
    });
    const [shopLinks, setShopLinks] = useState<{ id: number; name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [checkLinkLoading, setCheckLinkLoading] = useState(false);

    const countryState = useSelector((state: RootStore) => state);
    const [isOnline, setIsOnline] = useState(false);
    /*----- for upload images ------*/
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    /*----- for edit catalog ------*/
    const [editImages, setEditImages] = useState<{ id: number; picture_url: string }[]>([]);
    /*----- check if is set form in useEffect ------*/
    const [isCatalog, setIsCatalog] = useState(false);
    //////////////----------------- For Upload Images ( preview ) ----------------------
    /* --------- url for edit or new  --------- */
    const [urlForEdit, setUrlForEdit] = useState<{ id: null | string; name: null | string }>({ id: null, name: null });
    //-------- for add new images on edit -------
    const [isEditChangeImages, setIsEditChangeImages] = useState(false);
    //-------- when filter city, list of select cities are empty -------
    const [selectedCities, setSelectedCities] = useState<number[]>([]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const currentCountryId = countryState.dataLs.country ? countryState.dataLs.country.countryId : 1;
    const currentCountryName: string = countryState.dataLs.country ? countryState.dataLs.country.currentCountry : "rs";
    const [form] = Form.useForm();
    const inputRef = useRef<InputRef>(null);
    const param: { id: string; type: string } = useParams();

    ////////////////------------ Promise All ---------------

    useEffect(() => {
        const getData = async () => {
            let URL1 = `${process.env.REACT_APP_URL_API}/shop/list?active=0&country_id=${currentCountryId}`;
            let URL2 = `${process.env.REACT_APP_URL_API}/product/category/list-categories?country_id=${currentCountryId}`;

            //revmoe this cities, only onselect shop
            let URL3 = `${process.env.REACT_APP_URL_API}/city/list`;
            let URL4 = `${process.env.REACT_APP_URL_API}/catalogues/catalogue/types`;
            let URL5 = `${process.env.REACT_APP_URL_API}/catalogues/catalogue/tags`;

            const promise1 = axios.get(URL1);
            const promise2 = axios.get(URL2);
            const promise3 = axios.get(URL3);
            const promise4 = axios.get(URL4);
            const promise5 = axios.get(URL5);

            Promise.all([promise1, promise2, promise3, promise4, promise5]).then((values) => {
                values &&
                    setFetchedData({
                        shops: values[0].data.data,
                        categories: values[1].data.data.filter((c: CategoryType) => c.disabled === 0),
                        cities: values[2].data.data,
                        types: values[3].data,
                        tags: values[4].data,
                    });
                cityInit = values[2].data.data;
            });
        };

        getData();
    }, [currentCountryId]);

    const selectShop = useCallback(
        async (e: number | undefined) => {
            const findShop = fetchedData.shops.find((s) => s.id === e);
            form.setFieldValue("title_h1", `${findShop?.name ? findShop?.name : ""}`);
            inputRef?.current?.focus({
                cursor: "end",
            });
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/catalogues/catalogue/urls/${e}`);
                setShopLinks(data.map((link: { id: number; url: string }) => ({ id: link.id, name: link.url })));
            } catch (err) {
                console.log(err);
            }
        },
        [fetchedData.shops, form]
    );

    const prevProps = useRef(props);

    const getCatalog = useCallback(async () => {
        setIsCatalog(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/catalogues/catalogue/${param.id}`);
            //console.log(data.data)

            // Code to be executed after the delay
            if (data.data) {
                const catalog = data.data;
                setUrlForEdit({ id: catalog.url.id, name: catalog.url.url }); //////////////////////////////////////
                selectShop(catalog.shop_id);
                let urlText = "";
                catalog?.pictures.forEach((p: { picture_url: string }) => (urlText += p.picture_url + "\n"));
                param.type === "edit" && setEditImages(catalog?.pictures);

                form.setFieldsValue({
                    shop_id: catalog.shop_id,
                    title: catalog.title,
                    sub_title: catalog.sub_title,
                    title_h1: catalog.title_h1,
                    type_id: catalog.type_id,
                    url_id: param.type ? catalog.url.url : catalog.url_id,
                    paying: catalog.paying,
                    ...(param.type === "edit" && { date: dateArr(catalog.valid_from, catalog.valid_to) }),
                    categories_l3: catalog.categories_l3.map((c: CategoryType) => c.category_l3_id?.toString()),
                    tags: catalog.tags.map((t: { pivot: { tag_id: number } }) => t.pivot.tag_id),
                    categories_l1: catalog.categories_l1.map((t: CategoryType) => t.category_l1_id),
                    locations: catalog.locations.map((t: { location_id: number }) => t.location_id),
                });
                param.type && catalog.locations.length === 0 && setIsOnline(true);
                setSelectedCities(catalog.locations.map((t: { location_id: number }) => t.location_id));
            }
        } catch (err) {
            console.log(err);
        }
    }, [form, param.id, param.type, selectShop]);

    const [loadingOnPrevPage, setLoadingOnPrevPage] = useState(false);

    useEffect(() => {
        // Compare current props with previous props
        if (prevProps.current.location !== props.location) {
            if (!param.id) {
                form.resetFields();
            } else {
                setLoadingOnPrevPage(true);
                getCatalog().then((resp) => {
                    setLoadingOnPrevPage(false);
                });
            }
        }

        // Update prevProps to store the current props for the next re-render
        prevProps.current = props;
    }, [props.location, props, form, param.id, getCatalog]);

    const dateArr = (date1: string, date2: string) => [dayjs(date1, "YYYY-MM-DD"), dayjs(date2, "YYYY-MM-DD")];

    useEffect(() => {
        if (param.id && param.type && !isCatalog) {
            getCatalog();
        }
    }, [param, form, isCatalog, currentCountryId, selectShop, getCatalog]);

    const categoryL3L4: any = [];

    fetchedData.categories &&
        fetchedData.categories.forEach((c1: any) => {
            c1.children.forEach((c2: any) => {
                c2.children.forEach((c3: any) => {
                    categoryL3L4.push(c3);
                });
            });
        });

    const handleCancel = () => setPreviewOpen(false);

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const removeImages = () => {
        setFileList([]);
        form.setFieldValue("pictures_files", []);
    };

    const categoryList = categoryL3L4
        .sort((a: { [name: string]: string }, b: { [name: string]: string }) =>
            a[`name_${currentCountryName}`]?.localeCompare(b[`name_${currentCountryName}`])
        )
        .filter((c: any) => c.disabled === 0)
        .map((c3: any) => ({
            title: c3[`name_${currentCountryName}`],
            value: c3.id.toString(),
            disabled: c3.disabled,
            children: c3.children
                .map((c4: any) => ({
                    title: c4[`name_${currentCountryName}`],
                    value: c4.id.toString(),
                    disabled: c4.disabled,
                }))
                .filter((c: any) => c.disabled === 0),
        }));

    const handleSubmit = async (e: CreateCatalogObjEventType) => {
        if ((!e.locations || e.locations.length === 0) && !isOnline) {
            openNotification("Select Location or Online checkox");
            return;
        }
        if (
            fileList.length === 0 &&
            editImages.length === 0 &&
            !e.pictures_urls?.split("\n").filter((w: string) => w.length > 0).length
        ) {
            openNotification("Input Pictures urls or select Uploded Images");
            return;
        }
        if (fileList.length > 0 && e.pictures_urls?.split("\n").filter((w: string) => w.length > 0).length) {
            openNotification("Filled Pictures urls and selected Uploded Images, Choose one of them");
            return;
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append("shop_id", e.shop_id);
            formData.append("sub_title", e.sub_title);
            formData.append("title", e.title);
            formData.append("title_h1", e.title_h1);
            formData.append("type_id", e.type_id);
            console.log(e);
            formData.append("paying", e.paying ? "true" : "false"); // paying - boolean
            if (param.type || e.url_id) {
                formData.append("url_id", param.type && urlForEdit.id ? urlForEdit.id : e.url_id);
            }
            formData.append("valid_from", moment(e.date[0].$d).format("YYYY-MM-DD"));
            formData.append("valid_to", moment(e.date[1].$d).format("YYYY-MM-DD"));
            isOnline && param.type === "edit" && formData.append("online", "1");
            e.tags.forEach((t) => {
                formData.append("tags[]", t.toString());
            });
            if (!isOnline) {
                e.locations.forEach((l) => {
                    formData.append("locations[]", l);
                });
            }
            e.categories_l1.forEach((l) => {
                formData.append("categories_l1[]", l);
            });

            e.categories_l3.forEach((c: string, i: number) => {
                formData.append("categories_l3[]", c);
            });

            fileList.forEach((item: any) => {
                formData.append("pictures_files[]", item.originFileObj, item.originFileObj?.name);
            });

            e.pictures_urls?.split("\n").forEach((w: string) => {
                if (w) {
                    formData.append("pictures_urls[]", w);
                }
            });

            if (param.type === "edit") {
                try {
                    await axios.post(`${process.env.REACT_APP_URL_API}/catalogues/catalogue/${param.id}`, formData);
                    showMessage("Catalog updated");
                    //form.resetFields()
                    fileList.length > 0 &&
                        setEditImages(fileList.map((f, i) => ({ id: i, picture_url: f.thumbUrl ? f.thumbUrl : "" })));
                    setFileList([]);
                    form.setFieldValue("pictures_files", []);
                    setIsEditChangeImages(false);
                    setLoading(false);
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    openNotification("Error, something went wrong");
                }
            } else {
                try {
                    await axios.post(`${process.env.REACT_APP_URL_API}/catalogues/catalogue`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    showMessage("Catelog created");
                    form.resetFields();
                    form.setFieldValue("pictures_files", []);
                    setFileList([]);
                    setIsOnline(false);
                    setLoading(false);
                    param.type === "new" &&
                        setTimeout(() => {
                            window.close();
                        }, 1000);
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    openNotification("Error, something went wrong");
                }
            }
        }
    };

    const filterCities = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.toLowerCase().trim();
        setFetchedData((curr) => ({
            ...curr,
            cities: cityInit.filter((c) => c.name.toLowerCase().includes(input)),
        }));
    };

    const setOnline = (e: boolean) => {
        if (e) {
            form.setFieldValue("locations", null);
            setSelectedCities([]);
        }
        setIsOnline(e);
    };

    const inputBlur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (e.target.value) {
            setCheckLinkLoading(true);
            const shop_id = form.getFieldValue("shop_id");
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/catalogues/catalogue/urlByShopIdTitle/${shop_id}?title=${e.target.value}`
                );
                setCheckLinkLoading(false);
                if (data) {
                    form.setFieldValue("url_id", data.id);
                }
            } catch (err) {
                setCheckLinkLoading(false);
                console.log(err);
            }
        }
    };

    useEffect(() => {
        if (!param.type) {
            form.setFieldValue(
                "tags",
                fetchedData.tags.map((t) => t.id)
            );
        }
    }, [param.type, fetchedData.tags, form]);

    useEffect(() => {
        form.setFieldValue("locations", selectedCities);
    }, [selectedCities, form]);

    return (
        <Row
            style={{
                backgroundColor: "white",
                margin: "0 2rem",
                padding: "3rem",
            }}
            justify="center"
        >
            <HelmetTitle title="Data Entry App - Create Catalog" />
            <Divider>{`${param.type ? capitalize(param.type) : "Create"} Catalog`}</Divider>

            <Form
                labelCol={{ span: 6 }}
                //layout="horizontal"
                form={form}
                className={fetchedData.shops.length > 0 && !loadingOnPrevPage ? `create-catalog-form` : ""}
                //onFinish={handleSubmit}
                //onSubmitCapture={(e) => console.log(e)}
                onFinish={handleSubmit}
                initialValues={{
                    //"default_top_l3/l4": [1,2],
                    //"shop_id":60,
                    //"tags":fetchedData.tags.map( t => t.id ),
                    //"cities":[10,25,34]
                    locations: [],
                    // ...( !param.type && { "tags":fetchedData.tags.map( t => t.id )}),
                }}
            >
                {fetchedData.shops.length === 0 || loadingOnPrevPage ? (
                    <Spinner />
                ) : (
                    <Row justify="center">
                        <Col
                            span={10}
                            //offset={3}
                            className="create-catalog-form-left"
                        >
                            <FormSelect
                                name="shop_id"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                sourcedata={fetchedData.shops}
                                onchange={selectShop}
                                select={true}
                            />

                            <FormInput name="title_h1" require input inputRef={inputRef} />
                            <FormInput name="title" require input onInputBlur={inputBlur} />
                            <FormInput name="sub_title" require input />

                            <FormSelect
                                name="type_id"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                sourcedata={fetchedData.types}
                                select={true}
                            />
                            {param.type ? (
                                // <FormInput
                                //     name="url_id"
                                //     require
                                //     input
                                //     disabled
                                // />:
                                <Form.Item label={`Url`}>
                                    <span
                                        onClick={() =>
                                            window.open(
                                                `https://www.eponuda.com/katalog-akcije/${urlForEdit.name}`,
                                                "_blank",
                                                "noreferrer"
                                            )
                                        }
                                        className="create-catalog-url-link"
                                    >
                                        {urlForEdit.name}
                                    </span>
                                </Form.Item>
                            ) : (
                                <div style={{ position: "relative", width: "100%" }}>
                                    <FormSelect
                                        name="url_id"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        select={true}
                                        sourcedata={shopLinks}
                                        form={form}
                                        checkLinkLoading={checkLinkLoading}
                                    />
                                    {checkLinkLoading && (
                                        <Spin style={{ position: "absolute", top: "6px", right: "7px" }} />
                                    )}
                                </div>
                            )}
                            <FormDatePicker name="date" />
                            <FormInput name="pictures_urls" rows={3} />
                            <FormSelect
                                name="categories_l3"
                                multiple
                                showSearch
                                sourcedata={categoryList}
                                select={false}
                            />
                            <FormTags name="tags" datasource={fetchedData.tags} />
                        </Col>

                        <Col span={13} offset={1} className="create-catalog-form-right">
                            {param.type === "edit" && editImages.length > 0 && <StoredImages editImages={editImages} />}

                            <FormUpolad
                                fileList={fileList}
                                handlePreview={handlePreview}
                                onChange={onchange}
                                setFileList={setFileList}
                                previewOpen={previewOpen}
                                previewTitle={previewTitle}
                                handleCancel={handleCancel}
                                previewImage={previewImage}
                                type={param.type}
                                isEditChangeImages={isEditChangeImages}
                                setIsEditChangeImages={setIsEditChangeImages}
                            />

                            {fileList.length > 0 && (
                                <div className="create-catalog-form-remove-img-btn">
                                    <FormButton
                                        danger
                                        style={{ borderRadius: "8px" }}
                                        onClick={removeImages}
                                        title="Remove Images"
                                    />
                                </div>
                            )}
                        </Col>
                        <Col span={15}>
                            <Divider />
                        </Col>
                        <Col span={15} className="create-catalog-form-checkboxs">
                            <Divider>Categories L1</Divider>
                            <FormCheckboxGroup
                                name={"categories_l1"}
                                sourceData={fetchedData.categories
                                    .map((c: any) => ({ id: c.id, name: c[`name_${currentCountryName}`] }))
                                    .filter((c) => c.id !== 30) // id=30 is 'nesortirano'
                                    .sort((a, b) => a?.name?.localeCompare(b.name))}
                                checkboxStyle={{ marginLeft: "1rem", fontSize: "12px" }}
                                form={form}
                                required
                            />
                        </Col>
                        <Col span={15}>
                            <Divider />
                        </Col>
                        <Col span={15} className="create-catalog-form-checkboxs cities" style={{ minHeight: "63vh" }}>
                            <Divider style={{ margin: 0, padding: 0 }}>Cities</Divider>

                            <Checkbox
                                style={{ color: "darkblue", margin: "0.4rem 0", fontWeight: isOnline ? 600 : "" }}
                                onChange={(e) => setOnline(e.target.checked)}
                                checked={isOnline}
                            >
                                Online
                            </Checkbox>

                            <br />
                            <Input
                                style={{ width: "300px", margin: "0 0 1rem 0" }}
                                placeholder="Search City"
                                onChange={(e) => filterCities(e)}
                            />
                            <FormCheckboxGroup
                                name={"locations"}
                                sourceData={fetchedData.cities.sort((a, b) => a.name.localeCompare(b.name))}
                                checkboxStyle={null}
                                form={form}
                                required={false}
                                disabled={isOnline}
                                setSelectedCities={setSelectedCities}
                            />
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                name="paying"
                                valuePropName="checked"
                                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                            >
                                <Checkbox>Paying</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={8} offset={6}>
                            <FormButton
                                htmlType="submit"
                                style={{ marginTop: "2rem" }}
                                type="primary"
                                title={
                                    param.type
                                        ? `${param.type === "new" ? "Add new" : `${capitalize(param.type)}`} Catalog`
                                        : "Create Catalog"
                                }
                                loading={loading}
                                //onClick={handleSubmit}
                            />
                        </Col>
                    </Row>
                )}
            </Form>
        </Row>
    );
};

export default CreateCatalog;
