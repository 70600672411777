import Highlighter from "react-highlight-words";

export const HighlilghtWord = (model: string, text: string) => {
    const modelArr = model ? model.split(" ") : [""];

    return !text ? (
        <span>{text}</span>
    ) : (
        <Highlighter
            highlightClassName="hightlight-name-mpn"
            unhighlightClassName="unhightlight-otulet-text"
            //unhighlightStyle={{ fontSize:'24px' }}
            searchWords={/*words*/ modelArr}
            autoEscape={true}
            textToHighlight={text}
            caseSensitive={false}
        />
    );
};
