import { PRODUCT_CREATE, PRODUCT_LIST } from "../actions/product/productActionType";

export const productReducer = ( state:any={}, action:any ) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return action.payload
        case PRODUCT_CREATE:
            return state
        default:
            break;
    }
    return state
}