import axios from "axios";
import { useEffect, useState } from "react";
import { CategoryType } from "../../types/AttributesAnalytics.type";

const UseGetCategories = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CategoryType[]>([]);

    useEffect(() => {
        const showCategories = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-new-attribute-categories?date=${"2024-10-23"}`);
                setData(data.data.map((d: Omit<CategoryType, "key">) => ({ ...d, key: d.attribute_category_id, shops: [] })));
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        showCategories();
    }, []);

    return { loading, data, setData };
};

export default UseGetCategories;
