import { PlusCircleOutlined } from "@ant-design/icons";
import { Card, Image, Popover } from "antd";
import React from "react";
import { PicturesType } from "../../../types/type";
import CreateImageForm from "./CreateImageForm";

interface CreateNewImageType {
    productId: number | undefined;
    setProductInfo: React.Dispatch<React.SetStateAction<{ name: string; value: string | PicturesType[] }[]>>;
}

const CreateNewImage = (props: CreateNewImageType) => (
    <Popover content={<CreateImageForm productId={props.productId} setProductInfo={props.setProductInfo} />} trigger="click">
        <Card
            className="product-add-image-btn"
            style={{ border: "2px solid white", padding: 0, marginLeft: "0.3rem", marginTop: "0.3rem", position: "relative" }}
            bodyStyle={{ padding: "5px 10px", width: "80px", height: "80px" }}
        >
            <Image preview={false} src={`#`} height={50} width={50} style={{ opacity: 0 }} />
            <div style={{ opacity: 0 }}>x</div>
            <PlusCircleOutlined className="add-img-plus-btn" style={{ fontSize: "30px", color: "#88D66C", position: "absolute", top: "28px", left: "26px" }} />
        </Card>
    </Popover>
);

export default CreateNewImage;
