import { Divider, Modal, Table } from 'antd'
import { useSearchState } from '../../../shared/hooks/useSearchState'
import { ShopObject, StoreType } from '../pages/ShopList'
import { ColumnType } from 'antd/es/table'
import EditableCell, { EditableRow } from '../components/Store/EditableCell'
import '../style/StoreList.css'
import { useEffect, useState } from 'react'
import CreateStore from '../components/CreateStore'
import axios from 'axios'

interface StoreModalProps {
  storeModal: boolean
  storeModalCancel:() => void
  stores: StoreType[]
  setStores: React.Dispatch<React.SetStateAction<StoreType[]>>
  storeShop: ShopObject | null
}

const StoreList:React.FC<StoreModalProps> = ({ storeModal, storeModalCancel, stores, setStores, storeShop })  => {
  const  { getColumnSearchProps }  = useSearchState(stores)
  const [ cities, setCities ] = useState<{id: number, name: string}[]>([])
  useEffect(() => {
    const getAllCities = async () => {
        try {
            const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/city/shop-city-list?shop_id=${storeShop?.id}`)
            setCities(data.data)
        } catch ( err ) {
            console.log(err)
        }
    }
    if ( storeShop?.id ) {
        getAllCities()
    }

}, [ storeShop?.id ])

  const defaultColumns:(ColumnType <StoreType> & { editable?: boolean; dataIndex: any  })[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:'3%'
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width:'10%',
      sorter: (a:any, b:any) => a.city.localeCompare(b.city),
      ...getColumnSearchProps('city'),
      editable: true
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      editable: true,
      width:'15%'
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      editable: true,
      width:'12%'
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      editable: true,
      width:'12%'
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      editable: true,
      width:'14%'
    },
    {
      title: "Work Hour",
      dataIndex: "work_hour",
      key: "work_hour",
      editable: true,
      width:'18%'
    },
    {
      title: "Coordinates",
      dataIndex: "coordinates",
      key: "coordinates",
      editable: true,
      width:'12%'
    }
  ]

  const handleSave = (row: StoreType) => {
    const newData = [...stores];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setStores(newData)
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map((col:any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: StoreType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        cities,
        setStores
      }),
    }
  })

  return (
    <Modal 
      title = {<Divider>Store List</Divider>}
      open={storeModal} 
      onCancel={storeModalCancel}
      width="95%"
      footer={false}
    >
      <CreateStore 
        cities={cities} 
        storeShop={ storeShop } 
        setStores={ setStores } 
      />
      <Table 
        components={components}
        columns={columns as any}
        bordered
        rowKey={record =>String (record?.id)} 
        dataSource={stores} 
        className="shop-stores-table"
        pagination={{ pageSize:15,  showSizeChanger: false, size:'small' }}
      />
    </Modal>
  )
}

export default StoreList
