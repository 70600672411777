import { Button, Cascader, Checkbox, Col, Divider, Input, Layout, Modal, Popconfirm, Row, Table, Tooltip } from "antd";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import showMessage from "../../../shared/MessagesInfo/message";
import errorMessage from "../../../shared/MessagesInfo/ErrorMessage";
import "../style/AllCategories.css";
import { CheckCircleOutlined, EditFilled, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
//import FadeIn from 'react-fade-in';
import CategoryPrevText from "../components/CategoryPrevText";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import Spinner from "../../../shared/Spinner/Spinner";
import CreateCategory from "../components/CreateCategory";
import EditCategory, { EditCateogyType } from "../components/EditCategory";
import { DefaultOptionType } from "antd/lib/select";
import { useSearchState } from "../../../shared/hooks/useSearchState";
const { Header } = Layout;
let categoryId = 0;
//const currentCountry = 'rs'

export type CategoryType = {
    categoryId: number;
    category_l1_name_rs: string;
    created_at: string | null;
    default_image_url: string | null;
    description_rs: string;
    disabled: number;
    id: number;
    inputError: boolean;
    isMetaDescription: boolean;
    isMetaKeywords: boolean;
    isMetaTitle: boolean;
    large_image_url: string;
    meta_description_rs: string | null;
    meta_description: string | null;
    meta_keywords_rs: string | null;
    meta_title_rs: string | null;
    name_hr: string;
    name_rs: string;
    name_si: string;
    name_ba: string;
    name_me: string;
    seo_status_rs: string;
    shoptok_id: number | null;
    type: string;
    updated_at: string | null;
    category_l1_id?: number;
    category_l2_id?: number;
    category_l3_id?: number;
    category_l4_id?: number;
    image_slug: string;
};

type ObjectToSendType = {
    id: number | undefined;
    level_id: number;
    parent_id: any;
};

type CategoryCascade = {
    children: string[];
    description_rs: string | null;
    disabled: number;
    id: number;
    meta_description_rs: string | null;
    meta_keywords_rs: string | null;
    meta_title_rs: string | null;
    name_rs: string | null;
    c1: number;
    c2: number;
    c3: number;
    c4: number;
};

const AllCategories = () => {
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [allCategories, setAllCategories] = useState<CategoryCascade[]>([]);
    const [description, setDescription] = useState<CategoryType | null>(null);
    const [categoryInfo, setCategoryInfo] = useState<string[]>([]);
    const [descEditorText, setDescEditorText] = useState(false);

    ///////////////////for search

    const [onlyEmpty, setOnlyEmpty] = useState<boolean>(false);
    const [onlyEmptyDesc, setOnlyEmptyDesc] = useState(false);
    const [counter, setCounter] = useState(1);
    const [disabledCategories, setDisabledCategories] = useState(false);
    /////////////////state for search

    const [searchId, setSearcId] = useState<string | undefined>(undefined);
    ////////////////modals
    const [modalCreate, setModalCreate] = useState(false);

    const toggleChecked = () => {
        setDisabledCategories((current) => !current);
    };

    const countryState = useSelector((state: RootStore) => state);
    const { getColumnSearchProps } = useSearchState(categories);

    const currentCountryId = countryState.dataLs.country ? countryState.dataLs.country.countryId : 1;
    const currentCountryName = countryState.dataLs.country ? countryState.dataLs.country.currentCountry : "rs";
    ///////////////////////////

    useEffect(() => {
        const getAllCategoriesArray = async () => {
            // if (categoryInfo.length >0) {
            // }
            try {
                const { data }: { data: { data: CategoryType[][] } } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/product/category/list-all-categories?country_id=${currentCountryId}${onlyEmpty ? "&empty_only=1" : ""}${
                        onlyEmptyDesc ? `&empty_description=1` : ""
                    }${disabledCategories ? "&disabled=1" : ""}${searchId ? `&category_id=${searchId}` : ""}`
                );
                const level1 /*:CategoryType[]*/ = data.data[0]
                    .map((category1: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category1,
                            categoryId: categoryId,
                            category_l1_id: category1.id,
                            [`category_l1_name_${currentCountryName}`]: [`category1.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);
                const level2 = data.data[1]
                    .map((category2: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category2,
                            categoryId: categoryId,
                            category_l2_id: category2.id,
                            [`category_l2_name_${currentCountryName}`]: [`category2.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level3 = data.data[2]
                    .map((category3: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category3,
                            categoryId: categoryId,
                            category_l3_id: category3.id,
                            [`category_l3_name_${currentCountryName}`]: [`category3.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level4 = data.data[3]
                    .map((category4: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category4,
                            categoryId: categoryId,
                            category_l4_id: category4.id,
                            [`category_l4_name_${currentCountryName}`]: [`category4.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const newArray = [...level1, ...level2, ...level3, ...level4];
                if (categoryInfo.length === 0) {
                    setCategories(newArray);
                } else {
                    const level = categoryInfo.length;
                    const element = JSON.parse(categoryInfo[level - 1]);
                    if (level === 1) {
                        const newLevel2 = level2.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l1_id === element.c1) return { ...item, categoryId };
                            else return null;
                        });
                        const newLevel3 = level3.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l1_id === element.c1) return { ...item, categoryId };
                            else return null;
                        });
                        const newLevel4 = level4.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l1_id === element.c1) return { ...item, categoryId };
                            else return null;
                        });
                        setCategories([...newLevel2, ...newLevel3, ...newLevel4]);
                    }
                    if (level === 2) {
                        const newLevel2 = level2.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.id === element.c2) return { ...item, categoryId };
                            else return null;
                        });
                        const newLevel3 = level3.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l2_id === element.c2) return { ...item, categoryId };
                            else return null;
                        });
                        const newLevel4 = level4.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l2_id === element.c2) return { ...item, categoryId };
                            else return null;
                        });
                        setCategories([...newLevel2, ...newLevel3, ...newLevel4]);
                    }

                    if (level === 3) {
                        const newLevel4 = level4.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.category_l3_id === element.c3) return { ...item, categoryId };
                            else return null;
                        });
                        //newLevel4 && setCategories([...newLevel4])

                        const newLevel3 = level3.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.id === element.c3) return { ...item, categoryId };
                            else return null;
                        });
                        setCategories([...newLevel3, ...newLevel4]);
                    }
                    if (level === 4) {
                        const newLevel4 = level4.filter((item: CategoryType) => {
                            categoryId += 1;
                            if (item.id === element.c4) return { ...item, categoryId };
                            else return null;
                        });
                        setCategories([...newLevel4]);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };
        getAllCategoriesArray();
    }, [categoryInfo, onlyEmpty, currentCountryId, currentCountryName, counter, onlyEmptyDesc, disabledCategories, searchId]);

    useEffect(() => {
        const getCategoriesCascader = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-categories?country_id=${currentCountryId}`);
                setAllCategories(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getCategoriesCascader();
    }, [currentCountryId, counter]);

    const showMetaTitle = (id: number, BooleanName: string) => {
        const findErrorInput = categories.find((category) => category.inputError === true);
        if (!findErrorInput) {
            const newData = categories.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isMetaTitle: false,
                        isMetaKeywords: false,
                        isMetaDescription: false,
                        [`${BooleanName}`]: true,
                        inputError: false,
                    };
                }
                return {
                    ...item,
                    isMetaTitle: false,
                    isMetaKeywords: false,
                    isMetaDescription: false,
                    inputError: false,
                };
            });
            setCategories(newData);
        }
    };
    const handleMetaTitleChange = (id: number, text: string, keyWord: string) => {
        const newData = categories.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    [`${keyWord}`]: text,
                };
            }
            return item;
        });
        setCategories(newData);
    };

    ////////

    const saveMetaTitleChange = async (e: any, BooleanName: string, type: string) => {
        //let findCategory:CategoryType | undefined
        //if (BooleanName !== 'description' && typeof e !== 'string'){
        let findCategory: CategoryType | undefined = categories.find((category) => category.id === Number(e.target.id) && category.type === type);
        //}
        let changeType: string = "";
        switch (BooleanName) {
            case "isMetaTitle":
                changeType = `meta_title_${currentCountryName}`;
                break;
            case "isMetaKeywords":
                changeType = `meta_keywords_${currentCountryName}`;
                break;
            case "isMetaDescription":
                changeType = `meta_description_${currentCountryName}`;
                break;

            default:
                break;
        }

        findCategory = categories.find((category) => category.id === Number(e.target.id));
        let objectToSend: ObjectToSendType = {
            id: findCategory?.id,
            level_id: Number(findCategory?.type[1]),
            parent_id: findCategory && Number(findCategory?.type[1]) - 1 === 0 ? 0 : findCategory && findCategory[`category_l${Number(findCategory?.type[1]) - 1}_id` as keyof CategoryType],
            [`${changeType}`]: findCategory && findCategory[`${changeType.slice(0, -3)}` as keyof CategoryType],
        };

        // else {
        //   let parent_id:any = 0
        //   if (Number(description?.type[1])!== 0 && Number(description?.type[1])!== 1 ) {
        //     parent_id = description &&  description[`category_l${ Number(description?.type[1]) -1 }_id`  as keyof CategoryType]
        //   }

        //   objectToSend = {
        //   id:description?.id,
        //   level_id:Number(description?.type[1]),
        //   parent_id:parent_id,
        //   //[`description_${currentCountry}`]:draftToHtml(convertToRaw(state.editorState.getCurrentContent())).length === 8 ? null: draftToHtml(convertToRaw(state.editorState.getCurrentContent()))
        //   [`description_${currentCountryName}`]:editorText
        //   }
        // }

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objectToSend);
            const newData = categories.map((item: any) => {
                return {
                    ...item,
                    isMetaTitle: false,
                    isMetaKeywords: false,
                    isMetaDescription: false,
                    inputError: false,
                };

                // else {
                //   if (item.id === description?.id) {
                //     return {
                //       ...item,
                //       isMetaTitle:false,
                //       isMetaKeywords:false,
                //       isMetaDescription:false,
                //       inputError:false,
                //       //[`description_${currentCountry}`]:draftToHtml(convertToRaw(state.editorState.getCurrentContent())).length === 8 ? null: draftToHtml(convertToRaw(state.editorState.getCurrentContent()))
                //       [`description_${currentCountryName}`]:editorText.length === 8 ? null: editorText
                //     }
                //   }
                //   else {
                //     return {
                //       ...item,
                //       isMetaTitle:false,
                //       isMetaKeywords:false,
                //       isMetaDescription:false,
                //       inputError:false,

                //     }
                //   }
                // }
            });
            setCategories(newData);
            setDescEditorText(false);
            setDescription(null);
            showMessage(data.message);
        } catch (error) {
            const err = error as AxiosError;
            errorMessage(err.message);

            //  if (err) {
            //   errorMessage(err.message)
            //  }

            const newData = categories.map((item) => {
                if (item.id === findCategory?.id && item.type === findCategory?.type) {
                    return {
                        ...item,
                        inputError: true,
                    };
                }
                return item;
            });
            setCategories(newData);
        }
    };

    const openDescEditor = (item: CategoryType) => {
        setDescription(item);
        setTimeout(() => {
            setDescEditorText(true);
        }, 300);
    };

    const setDescTextStatus = async (item: CategoryType, e: React.ChangeEvent<HTMLSelectElement>) => {
        let objectToSend: ObjectToSendType;
        objectToSend = {
            id: item.id,
            level_id: Number(item.type[1]),
            parent_id: Number(item.type[1]) - 1 === 0 ? 0 : item[`category_l${Number(item.type[1]) - 1}_id` as keyof CategoryType],
            [`seo_status_${currentCountryName}`]: e.target.value,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objectToSend);
            const newData = categories.map((category) => {
                if (category.id === data.data.id && Number(category.type[1]) === objectToSend.level_id) {
                    return {
                        ...category,
                        [`seo_status_${currentCountryName}`]: objectToSend[`seo_status_${currentCountryName}` as keyof ObjectToSendType],
                    };
                }
                return category;
            });
            showMessage(data.message);
            setCategories(newData);
        } catch (err) {
            console.log(err);
        }
    };

    const disableCateogry = async (cateogryObj: CategoryType) => {
        const objectToSend = {
            //?????????????????????????????
            id: cateogryObj.id,
            level_id: Number(cateogryObj.type[1]),
            parent_id: Number(cateogryObj.type[1]) - 1 === 0 ? 0 : cateogryObj[`category_l${Number(cateogryObj.type[1]) - 1}_id` as keyof CategoryType],
            disabled: cateogryObj.disabled === 1 ? 0 : 1,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objectToSend);
            setCounter((current) => current + 1);
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    //for edit data

    const [editCategory, setEditCateogry] = useState<EditCateogyType | null>(null);

    const [modalEdit, setModalEdit] = useState(false);

    const [selectedEditCat, setSelectedEditCat] = useState<CategoryType | null>(null);

    const openEditModal = (record: CategoryType) => {
        setModalEdit(true);
        setSelectedEditCat(record);
    };

    const columns: any = [
        {
            title: "Level",
            dataIndex: "type",
            key: "type",
            render: (record: string) => (record ? record.toLocaleUpperCase() : ""),
            align: "center" as "center",
        },
        {
            title: "L1 id",
            dataIndex: "category_l1_id",
            key: "category_l1_id",
            align: "center" as "center",
            render: (_: string, record: CategoryType) => <span style={{ fontWeight: record.type === "l1" ? "bold" : "" }}>{record.type === "l1" ? record.id : record.category_l1_id}</span>,
        },
        {
            title: " L1 Name",
            dataIndex: `category_l1_name_${currentCountryName}`,
            key: `category_l1_name_${currentCountryName}`,
            render: (_: string, record: CategoryType) => (
                <span style={{ fontWeight: record.type === "l1" ? "bold" : "" }}>
                    {record.type === "l1" ? record[`name_${currentCountryName}` as keyof CategoryType] : record[`category_l1_name_${currentCountryName}` as keyof CategoryType]}
                </span>
            ),
        },
        {
            title: "L2 id",
            dataIndex: "category_l2_id",
            key: "category_l2_id",
            align: "center" as "center",
            ...getColumnSearchProps("category_l2_id"),
        },
        {
            title: " L2 Name",
            dataIndex: `category_l2_name_${currentCountryName}`,
            key: `category_l2_name_${currentCountryName}`,
            render: (_: string, record: CategoryType) => (
                <span style={{ fontWeight: record.type === "l2" ? "bold" : "" }}>
                    {record.type === "l2" ? record[`name_${currentCountryName}` as keyof CategoryType] : record[`category_l2_name_${currentCountryName}` as keyof CategoryType]}
                </span>
            ),
        },
        ///////
        {
            title: "L3 id",
            dataIndex: "category_l3_id",
            key: "category_l3_id",
            align: "center" as "center",
            ...getColumnSearchProps("category_l3_id"),
        },
        {
            title: " L3 Name",
            dataIndex: `category_l3_name_${currentCountryName}`,
            key: `category_l3_name_${currentCountryName}`,
            render: (_: string, record: CategoryType) => (
                <span style={{ fontWeight: record.type === "l3" ? "bold" : "" }}>
                    {record.type === "l3" ? record[`name_${currentCountryName}` as keyof CategoryType] : record[`category_l3_name_${currentCountryName}` as keyof CategoryType]}
                </span>
            ),
        },
        //////
        {
            title: "L4 id",
            dataIndex: "category_l4_id",
            key: "category_l4_id",
            align: "center" as "center",
            ...getColumnSearchProps("category_l4_id"),
        },
        {
            title: " L4 Name",
            dataIndex: `category_l4_name_${currentCountryName}`,
            key: `category_l4_name_${currentCountryName}`,
            render: (_: string, record: CategoryType) => (
                <span style={{ fontWeight: record.type === "l4" ? "bold" : "" }}>
                    {record.type === "l4" ? record[`name_${currentCountryName}` as keyof CategoryType] : record[`category_l4_name_${currentCountryName}` as keyof CategoryType]}
                </span>
            ),
        },
        {
            title: "Meta Title",
            dataIndex: `meta_title_${currentCountryName}`,
            key: `meta_title_${currentCountryName}`,
            render: (text: string, record: CategoryType) =>
                record.isMetaTitle ? (
                    <textarea
                        id={String(record.id)}
                        style={{
                            backgroundColor: record.isMetaTitle && record.inputError ? "pink" : "#f0fdff",
                            border: record.isMetaTitle && record.inputError ? "1px solid red" : "2px solid gray",
                        }}
                        className="meta-title-input"
                        onBlur={(e) => saveMetaTitleChange(e, "isMetaTitle", record.type)}
                        defaultValue={text}
                        onChange={(e) => handleMetaTitleChange(record.id, e.target.value, "meta_title")}
                        placeholder="Enter Meta Title"
                        rows={6}
                    />
                ) : (
                    <textarea className="meta-title-input-readonly" readOnly defaultValue={text} onDoubleClick={() => showMetaTitle(record.id, "isMetaTitle")} rows={1} />
                ),
        },
        {
            title: "Meta keywords",
            dataIndex: `meta_keywords_${currentCountryName}`,
            key: `meta_keywords_${currentCountryName}`,
            render: (text: string, record: CategoryType) =>
                record.isMetaKeywords ? (
                    <textarea
                        id={String(record.id)}
                        className="meta-keywords-input"
                        style={{
                            backgroundColor: record.isMetaKeywords && record.inputError ? "pink" : "#f0fdff",
                            paddingLeft: "5px",
                            border: record.isMetaKeywords && record.inputError ? "1px solid red" : "",
                        }}
                        onBlur={(e) => saveMetaTitleChange(e, "isMetaKeywords", record.type)}
                        defaultValue={text ? text : ""}
                        onChange={(e) => handleMetaTitleChange(record.id, e.target.value, "meta_keywords")}
                        placeholder="Enter Meta Keywords"
                        rows={6}
                    />
                ) : (
                    <textarea className="meta-keywords-input-readonly" readOnly defaultValue={text} onDoubleClick={() => showMetaTitle(record.id, "isMetaKeywords")} rows={1} />
                ),
        },
        {
            title: "Meta description",
            dataIndex: `meta_description_${currentCountryName}`,
            key: `meta_description_${currentCountryName}`,
            render: (text: string, record: CategoryType) =>
                record.isMetaDescription ? (
                    <textarea
                        id={String(record.id)}
                        className="meta-description-input"
                        style={{
                            backgroundColor: record.isMetaDescription && record.inputError ? "pink" : "#f0fdff",
                            paddingLeft: "5px",
                            border: record.isMetaDescription && record.inputError ? "1px solid red" : "",
                        }}
                        onBlur={(e) => saveMetaTitleChange(e, "isMetaDescription", record.type)}
                        defaultValue={record.meta_description ? record.meta_description : ""}
                        onChange={(e) => handleMetaTitleChange(record.id, e.target.value, "meta_description")}
                        placeholder="Enter Meta Description"
                        rows={6}
                    />
                ) : (
                    <textarea className="meta-description-input-readonly" readOnly defaultValue={text} onDoubleClick={() => showMetaTitle(record.id, "isMetaDescription")} rows={1} />
                ),
        },
        {
            title: <div style={{ textAlign: "center" }}>Description</div>,
            dataIndex: `description_${currentCountryName}`,
            key: `description_${currentCountryName}`,
            width: "6%",
            render: (_: string, record: CategoryType) => (
                <div className="description-btn-box">
                    {record[`seo_status_${currentCountryName}` as keyof CategoryType] === null ||
                    record[`seo_status_${currentCountryName}` as keyof CategoryType] === "empty" ||
                    record[`seo_status_${currentCountryName}` as keyof CategoryType] === "basic" ? (
                        <ExclamationCircleOutlined
                            className="desc-icon"
                            style={{
                                color:
                                    record[`seo_status_${currentCountryName}` as keyof CategoryType] === null || record[`seo_status_${currentCountryName}` as keyof CategoryType] === "empty"
                                        ? "#fa2a4a"
                                        : "orange",
                            }}
                        />
                    ) : (
                        <CheckCircleOutlined
                            className="desc-icon"
                            style={{
                                color: record[`seo_status_${currentCountryName}` as keyof CategoryType] === "seo_full" ? "green" : "#3e4efa",
                            }}
                        />
                    )}
                    <select
                        className="select-description"
                        value={
                            record[`seo_status_${currentCountryName}` as keyof CategoryType] === "empty" || record[`seo_status_${currentCountryName}` as keyof CategoryType] === null
                                ? "empty"
                                : record[`seo_status_${currentCountryName}` as keyof CategoryType] === "basic"
                                ? "basic"
                                : record[`seo_status_${currentCountryName}` as keyof CategoryType] === "seo_ready"
                                ? "seo_ready"
                                : "seo_full"
                        }
                        onChange={(e) => setDescTextStatus(record, e)}
                        style={{ width: "100px", textAlign: "center" }}
                    >
                        <option value="empty" className="select-description-empty">
                            Empty
                        </option>
                        <option value="basic">Basic</option>
                        <option value="seo_ready">SEO ready</option>
                        <option value="seo_full">SEO full</option>
                    </select>
                    <Button
                        type="dashed"
                        size="small"
                        className="description-btn"
                        style={{
                            width: "100px",
                            marginLeft: "6px",
                            opacity: record.disabled ? 0.7 : 1,
                        }}
                        onClick={() => openDescEditor(record)}
                    >
                        <span style={{ textDecoration: record.disabled ? "line-through" : "" }}>Description</span>
                    </Button>
                </div>
            ),
        },
        {
            title: "Disable",
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            render: (_: string, record: CategoryType) => (
                <Popconfirm
                    title={`${record.disabled ? "Enable" : "Disable"} this category?`}
                    //description="Disable this category?"
                    onConfirm={() => disableCateogry(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        size="small"
                        style={{
                            border: "1px solid violet",
                            borderRadius: "4px",
                            opacity: record.disabled ? 0.5 : 1,
                        }}
                        danger
                    >
                        {record.disabled ? "Enable" : "Disable"}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: "Edit",
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            render: (_: string, record: CategoryType) => (
                <Tooltip placement="top" title={"Edit cateogry"}>
                    <Button type="default" size="small" style={{ borderRadius: "4px" }} onClick={() => openEditModal(record)}>
                        <EditFilled style={{ color: "#385170" }} />
                    </Button>
                </Tooltip>
            ),
        },
    ];

    const handleOk = () => {
        setDescEditorText(false);
        setDescription(null);
    };

    const handleCancel = () => {
        setDescEditorText(false);
        setDescription(null);
    };

    const parseCategory = (category: CategoryType & { c1: number; c2: number; c3: number; c4: number }) => {
        let parent_id;
        if (category.category_l1_id) {
            parent_id = category.category_l1_id;
        } else if (category.category_l2_id) {
            parent_id = category.category_l2_id;
        } else if (category.category_l3_id) {
            parent_id = category.category_l3_id;
        } else {
            parent_id = 0;
        }
        const obj = {
            c1: category.c1,
            c2: category.c2,
            c3: category.c3,
            c4: category.c4,
            id: category.id,
            [`meta_description_${currentCountryName}`]: category[`meta_description_${currentCountryName}` as keyof CategoryType],
            [`meta_keywords_${currentCountryName}`]: category[`meta_keywords_${currentCountryName}` as keyof CategoryType],
            [`meta_title_${currentCountryName}`]: category[`meta_title_${currentCountryName}` as keyof CategoryType],
            name: category[`name_${currentCountryName}` as keyof CategoryType],
            //name_si:category.name_si,    //???????
            description: category[`description_${currentCountryName}` as keyof CategoryType],
            parent_id: parent_id,
        };
        return JSON.stringify(obj);
    };

    let c1: number;
    let c2: number;
    let c3: number;
    let c4: number;

    const optionData =
        allCategories &&
        allCategories.map((category1: any) => {
            c1 = category1.id;

            if (!category1.children) {
                return {
                    value: parseCategory({ ...category1, c1: c1 }),
                    label: category1[`name_${currentCountryName}`],
                };
            } else {
                const category1children = category1.children.map((category2: any) => {
                    c2 = category2.id;
                    if (!category2.children) {
                        return {
                            value: parseCategory({ ...category2, c1: c1, c2: c2 }),
                            label: category2[`name_${currentCountryName}`],
                        };
                    } else {
                        const category2children = category2.children.map((category3: any) => {
                            c3 = category3.id;
                            if (!category3.children) {
                                return {
                                    value: parseCategory({
                                        ...category3,
                                        c1: c1,
                                        c2: c2,
                                        c3: c3,
                                    }),
                                    label: category3[`name_${currentCountryName}`],
                                };
                            } else {
                                const category3children = category3.children.map((category4: any) => {
                                    c4 = category4.id;
                                    return {
                                        value: parseCategory({
                                            ...category4,
                                            c1: c1,
                                            c2: c2,
                                            c3: c3,
                                            c4: c4,
                                        }),
                                        label: category4[`name_${currentCountryName}`],
                                    };
                                });
                                return {
                                    value: parseCategory({
                                        ...category3,
                                        c1: c1,
                                        c2: c2,
                                        c3: c3,
                                    }),
                                    label: category3[`name_${currentCountryName}`],
                                    children: category3children,
                                };
                            }
                        });
                        return {
                            value: parseCategory({ ...category2, c1: c1, c2: c2 }),
                            label: category2[`name_${currentCountryName}`],
                            children: category2children,
                        };
                    }
                });
                return {
                    value: parseCategory({ ...category1, c1: c1 }),
                    label: category1[`name_${currentCountryName}`],
                    children: category1children,
                };
            }
        });

    const onCategoryChange = (value: any) => {
        setCategoryInfo(value);
    };

    function filter(inputValue: string, path: DefaultOptionType[]) {
        return path.some((option: any) => option.label && option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    const showOnlyEmpty = (e: boolean) => {
        setOnlyEmpty(e);
    };
    const showOnlyEmptyDescription = (e: boolean) => {
        setOnlyEmptyDesc(e);
    };
    let delayTimer: ReturnType<typeof setTimeout>;
    const searchCateogryById = (id: string | undefined) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setSearcId(id);
        }, 1500);
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - All categories" />
            <Row justify="center">
                <Col span={23} className="all-categories-box">
                    <Divider>All Categories</Divider>
                    <div>
                        <Cascader
                            options={optionData}
                            onChange={(e) => onCategoryChange(e)}
                            changeOnSelect
                            placeholder="Select Category"
                            showSearch={{ filter }}
                            style={{
                                width: "680px",
                                marginLeft: "1rem",
                                marginTop: "0.3rem",
                            }}
                            expandTrigger="click"
                            className="category-all-cascader"
                            allowClear={false}
                        />
                        <button
                            className="u-button"
                            style={{
                                padding: "4px 16px",
                                marginLeft: "0.3rem",
                                backgroundColor: "#d63645",
                                fontWeight: "normal",
                                boxShadow: "none",
                            }}
                            onClick={() => setCategoryInfo([])}
                        >
                            Reset
                        </button>
                        <Checkbox className="check-empty-only" style={{ marginLeft: "2rem" }} id="check-empty" onChange={(e) => showOnlyEmpty(e.target.checked)} />
                        <label htmlFor="check-empty">Show empty only</label>

                        <Checkbox className="check-empty-description" style={{ marginLeft: "2rem" }} id="check-empty-description" onChange={(e) => showOnlyEmptyDescription(e.target.checked)} />
                        <label htmlFor="check-empty-description">Show empty description</label>
                        <button
                            className="u-button"
                            style={{
                                backgroundColor: "#1dad9b",
                                position: "absolute",
                                right: "2rem",
                            }}
                            onClick={() => setModalCreate(true)}
                        >
                            <PlusOutlined style={{ marginRight: "9px" }} />
                            Create
                        </button>
                    </div>

                    <div className="alert-box-items">
                        {categoryInfo.length > 0 &&
                            categoryInfo.map((category: string, index: number) => (
                                <span
                                    key={index}
                                    style={{
                                        width: "330px",
                                        marginBottom: "2px",
                                        height: "22px",
                                        backgroundColor: "lightblue",
                                        border: "1px solid #b0bcf5",
                                        borderRadius: "3px",
                                        marginLeft: "6px",
                                        padding: "4px 4px 2px 8px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            position: "relative",
                                            bottom: "3px",
                                        }}
                                    >
                                        {`L-${index + 1}  ${JSON.parse(category).name} (${JSON.parse(category).id})`}
                                    </span>
                                </span>
                            ))}
                    </div>

                    <Modal
                        title="Create new Category"
                        centered
                        open={modalCreate}
                        onOk={() => {
                            setModalCreate(false);
                            setCounter((curr) => curr + 1);
                        }}
                        onCancel={() => {
                            setModalCreate(false);
                            setCounter((curr) => curr + 1);
                        }}
                        width={1800}
                        footer={false}

                        //style={{ width:'1000%!important' }}
                    >
                        <CreateCategory
                            categories={categories}
                            setModalCreate={setModalCreate}
                            currentCountryName={currentCountryName}
                            currentCountryId={currentCountryId}
                            setCounter={setCounter}
                            counter={counter}
                            modalCreate={modalCreate}
                            countries={countryState.countries}
                        />
                    </Modal>

                    <EditCategory
                        modalEdit={modalEdit}
                        setModalEdit={setModalEdit}
                        selectedCateogry={selectedEditCat}
                        setSelectedEditCat={setSelectedEditCat}
                        editCategory={editCategory}
                        setEditCateogry={setEditCateogry}
                        counter={counter}
                        setCounter={setCounter}
                        currentCountryName={currentCountryName}
                        categories={categories}
                        currentCountryId={currentCountryId}
                        countries={countryState.countries}
                    />
                    {descEditorText && (
                        <Modal
                            title={`${description?.type && description.type.toUpperCase()} - ${description && description[`name_${currentCountryName}` as keyof CategoryType]}`}
                            open={descEditorText}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={false}
                            width={1000}
                            style={{ height: "600px!important" }}
                        >
                            <CategoryPrevText
                                currentCountryName={currentCountryName}
                                description={description}
                                setDescEditorText={setDescEditorText}
                                categories={categories}
                                setCategories={setCategories}
                                setDescription={setDescription}
                            />
                        </Modal>
                    )}
                    <Layout style={{ marginTop: "2.1rem" }}>
                        <Header
                            className="product-img-header"
                            style={{
                                color: "white",
                                backgroundColor: "#5a5a5a",
                                height: "1.9rem",
                                lineHeight: "30px",
                            }}
                        >
                            <span style={{ fontSize: "15px" }}>
                                Categories list ( L1 - L4 ){" "}
                                <Input
                                    style={{
                                        border: "1px solid white",
                                        backgroundColor: "lightgray",
                                        height: "1.4rem",
                                        width: "98px",
                                        color: "black",
                                        paddingLeft: "0.5rem",
                                        marginLeft: "1rem",
                                        borderRadius: "3px",
                                        //borderColor: typeof   'red'
                                    }}
                                    placeholder="Search by Id"
                                    type="number"
                                    onChange={(e) => searchCateogryById(e.target.value)}
                                    className="all-categories-searchById"
                                    defaultValue={undefined}
                                    //value={searchId}
                                />
                            </span>

                            <Button
                                style={{
                                    position: "absolute",
                                    right: "40px",
                                    marginTop: "3px",
                                }}
                                type="dashed"
                                size="small"
                                onClick={toggleChecked}
                            >
                                {disabledCategories ? "All" : "Disabled"}
                            </Button>
                        </Header>
                        {categories.length !== 0 || (categories.length === 0 && searchId) ? (
                            <Table
                                bordered
                                rowKey={(record) => record && record.categoryId}
                                className="all-categories-table"
                                dataSource={categories}
                                columns={columns}
                                rowClassName={(record) => `${record.disabled === 1 ? "all-categoires-disable-row" : ""}`}
                                pagination={{
                                    defaultPageSize: 20,
                                    style: { marginRight: "0.5rem" },
                                }}
                                //scroll={{x:'1300px'}}
                                //0vw' }}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AllCategories;
