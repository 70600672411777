import { DeleteFilled, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Col, Divider, Empty, Form, FormInstance, Input, InputRef, Row, Select, Table } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { mappedColumns } from "../../../../shared/FormElements/EditableCell";
import showMessage from "../../../../shared/MessagesInfo/message";
//import openNotification from '../../../../shared/MessagesInfo/WarningBox'
import { AttributeMappingRulesType, Rules } from "../../pages/AttributeMappingRules";
const { Search } = Input;
const { Option } = Select;

interface TabPaneContentProps {
    attr: AttributeMappingRulesType;
    setAttributeRules: React.Dispatch<React.SetStateAction<AttributeMappingRulesType[]>>;
    handleSave: (row: Rules) => Promise<void>;
    addNewRowRule: (id: number) => void;
    deleteRuleRow: (record: Rules) => void;
    getAttributeMapping: () => Promise<void>;
}

interface Item {
    type: string;
    value: string;
    name_short: string;
}

interface EditableRowProps {
    index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    isInput?: boolean;
    record: Item;
    handleSave: (record: Item) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    isInput, //added boolean for checking is input Text Area (in MetaDescirption is Text Area)
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                // rules={[
                //   {
                //     required: true,
                //     message: `${title} is required.`,
                //   },
                // ]}
            >
                {isInput ? (
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                ) : (
                    <Input.TextArea ref={inputRef} rows={3} onPressEnter={save} onBlur={save} />
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24, height: "30px", width: "100%" }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const TabPaneContent: React.FC<TabPaneContentProps> = ({
    attr,
    addNewRowRule,
    deleteRuleRow,
    getAttributeMapping,
    handleSave,
    setAttributeRules,
}) => {
    const changeName = (e: string) => {
        setAttributeRules((curr) => curr.map((a) => (a.id === attr.id ? { ...a, name: e, isChangeName: false } : a)));
    };

    const saveChanges = async () => {
        //console.log(attr)
        const objToSend = {
            id: attr.id,
            name: attr.name,
            formula: attr.formula,
            rules:
                typeof attr.rules !== "string"
                    ? attr.rules.map((r) => ({
                          field_name: r.field_name,
                          field_type: r.field_type,
                          acr_id: r.acr_id,
                          ...(typeof r.id === "number" && { id: r.id }),
                      }))
                    : [],
        };

        //console.log(attr.rules);

        /*const findEmptyRule = objToSend.rules.find( r => !r.field_name.trim() || !r.field_type.trim())
        
        if ( findEmptyRule || objToSend.rules.length === 0 || !objToSend.name?.trim() || !objToSend.formula?.trim() ) {
          openNotification('Empty rule name, parameters and Formula are not allowed.')
        }

        else {*/
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/update-rule-with-parameters`,
                objToSend
            );
            showMessage(data.message);
            getAttributeMapping();
        } catch (err) {
            console.log(err);
        }
        /* }*/
    };
    return (
        <div style={{ widows: "100%", display: "flex", justifyContent: "center" }}>
            <Card hoverable style={{ width: 580, marginBottom: "2rem" }}>
                <div style={{ border: "1px solid lightgray", padding: "1rem", borderRadius: "8px" }}>
                    {attr.isChangeName ? (
                        <Search
                            placeholder="Change brand"
                            size="small"
                            style={{ width: "95%", margin: "0 5px" }}
                            value={attr.name}
                            onChange={(e) =>
                                setAttributeRules((curr) =>
                                    curr.map((a) => (a.id === attr.id ? { ...a, name: e.target.value } : a))
                                )
                            }
                            onSearch={(e) => changeName(e)}
                            enterButton="Edit"
                        />
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span>{attr.name}</span>
                            <EditOutlined
                                style={{ marginTop: "4px", marginLeft: "5px" }}
                                onClick={() =>
                                    setAttributeRules((curr) =>
                                        curr.map((a) => (a.id === attr.id ? { ...a, isChangeName: true } : a))
                                    )
                                }
                            />
                        </div>
                    )}
                    <Divider />
                    <Row justify="center" style={{ backgroundColor: "#F8F9F9", padding: "1rem", borderRadius: "8px" }}>
                        <Divider>Parameters:</Divider>
                        <Col span={24}>
                            {attr.rules.length > 0 ? (
                                <Table
                                    components={{
                                        body: {
                                            row: EditableRow,
                                            cell: EditableCell,
                                        },
                                    }}
                                    rowClassName={() => "editable-row"}
                                    bordered
                                    columns={
                                        mappedColumns(
                                            [
                                                {
                                                    title: "",
                                                    dataIndex: "field_name",
                                                    editable: true,
                                                    width: "50%",
                                                    //render:(record) => <span>{record ? record:'Enter'}</span>
                                                },
                                                {
                                                    title: "",
                                                    dataIndex: "field_type",
                                                    width: "50%",
                                                    render: (record) => (
                                                        <Select style={{ width: "100%" }} defaultValue={"input"}>
                                                            <Option key={"input"}>Input</Option>
                                                        </Select>
                                                    ),
                                                },
                                                {
                                                    title: "",
                                                    dataIndex: "id",
                                                    align: "center" as "center",
                                                    render: (_, record: any) => (
                                                        <DeleteFilled
                                                            style={{
                                                                fontSize: "18px",
                                                                //backgroundColor:'darkred',
                                                                color: "#CD6155",
                                                                cursor: "pointer",
                                                                position: "relative",
                                                            }}
                                                            onClick={() => deleteRuleRow(record)}
                                                        />
                                                    ),
                                                },
                                            ],
                                            handleSave,
                                            true
                                        ) as any
                                    }
                                    dataSource={typeof attr.rules !== "string" ? attr.rules : undefined}
                                    pagination={false}
                                    rowKey={(record) => record?.id}
                                    style={{ marginTop: "1rem" }}
                                    showHeader={false}
                                />
                            ) : (
                                <Empty />
                            )}
                        </Col>
                        <Button
                            type="primary"
                            icon={<PlusCircleFilled />}
                            style={{
                                //fontSize:'26px',
                                marginTop: "0.8rem",
                                //marginBottom:'12px',
                                backgroundColor: "#55c59d",
                                cursor: "pointer",
                                position: "relative",
                            }}
                            onClick={() => addNewRowRule(attr.id)}
                        >
                            New parameter
                        </Button>
                    </Row>
                    <Row justify={"center"} style={{ backgroundColor: "white", padding: "0" }}>
                        <Col
                            span={24}
                            style={{
                                backgroundColor: "#F2F3F4",
                                padding: "1rem",
                                marginTop: "1rem",
                                borderRadius: "8px",
                            }}
                        >
                            <Divider>Formula:</Divider>
                            {!attr.isChangeFormula ? (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <span>{attr.formula}</span>
                                    <EditOutlined
                                        style={{ marginTop: "4px", marginLeft: "5px" }}
                                        onClick={() =>
                                            setAttributeRules((curr) =>
                                                curr.map((a) => ({
                                                    ...a,
                                                    isChangeFormula: a.id === attr.id ? true : false,
                                                }))
                                            )
                                        }
                                    />
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Input
                                        placeholder="Enter formula"
                                        value={attr.formula}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setAttributeRules((curr) =>
                                                curr.map((a) => ({
                                                    ...a,
                                                    formula: a.id === attr.id ? e.target.value : a.formula,
                                                }))
                                            )
                                        }
                                    />
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        onClick={() =>
                                            setAttributeRules((curr) =>
                                                curr.map((a) => ({
                                                    ...a,
                                                    isChangeFormula: a.id !== attr.id ? true : false,
                                                }))
                                            )
                                        }
                                    >
                                        Change
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Button block type="primary" style={{ marginTop: "2rem" }} onClick={() => saveChanges()}>
                        Save Changes
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default TabPaneContent;
