export const CATEGORY_L3_LIST = "CATEGORY_L3_LIST"
export interface CategoryL3List {
    type: typeof CATEGORY_L3_LIST
    payload: any
}

//-----category l3l4

export const CATEGORY_L3_L4 = "CATEGORY_L3_L4"
export interface CategoryL3L4 {
    type: typeof CATEGORY_L3_L4
    payload: any
}


export const CATEGORY_L1_LIST = "CATEGORY_L1_LIST"
export interface CategoryL1List {
    type: typeof CATEGORY_L1_LIST
    payload: any
}