import { Checkbox, Col, Row, Form } from 'antd'
import { CategoryChildrenType } from '../../types/CreateCatalogTypes'
import { capitalize } from '../../helper/firstLetter'

type FormCheckboxGroupType = {
    name:string
    sourceData:{ id:number, name:string, children?:CategoryChildrenType }[] 
    checkboxStyle:any
    form:any 
    required: boolean
    disabled?: boolean
    setSelectedCities?: React.Dispatch<React.SetStateAction<number[]>>
}

const sortedArrayByColumns = (sortedWords:{ id:number, name:string, children?:CategoryChildrenType }[] , numberOfColumns:number ) => {
    //const sortedWords = words.sort((a, b) => a.localeCompare(b));
    const columnSize = Math.ceil(sortedWords.length / numberOfColumns)
    return Array.from(Array(numberOfColumns).keys())
            .map((c, i) => sortedWords.slice( columnSize * i, columnSize * (i+1) ))
}

const FormCheckboxGroup = ( props:FormCheckboxGroupType ) => {
    const checkboxGroupArr = Form.useWatch( props.name, props.form )
    const newArr = sortedArrayByColumns(props.sourceData,6)

    const addIdToChecked = (id:number) => {
        props.setSelectedCities &&
        props.setSelectedCities( curr => {
                return  !curr.includes(id) ? [...curr, id] : curr.filter( cId => cId !== id)
            }
        )
    }    

    return (
        <Form.Item 
            name={ props.name } 
            rules={[{ required: props.required, message: `Please select ${ capitalize(props.name) }` }]}
           
        >
            <Checkbox.Group>
                <Row gutter={[8, 8]}>
                    {newArr.map((column, index) => (
                        <Col span={4} key={index} className="column">
                        {column.map((word, wordIndex) => (
                            <Checkbox 
                                key={wordIndex}
                                value={ word.id } 
                                style={{ ...props.checkboxStyle }}
                                className={`create-catalog-checkobx ${  checkboxGroupArr?.includes(word.id) ? 'create-catalog-checked-item':'' }`}
                                disabled={ props.disabled }
                                onChange={() => props.name === 'locations' ? addIdToChecked(word.id):{}}
                                checked
                            >
                                <span style={{ width:'200px', display:'flex' }}>
                                    { word.name } 
                                </span>
                            </Checkbox>
                        ))}
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        </Form.Item>
    )
}

export default FormCheckboxGroup