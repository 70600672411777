import { Alert, Col, Divider, Row, Select, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../actions/userAction/userAction";
import { RootStore } from "../../../Store";
import "../style/NewMatch.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import DatePickerFromToReports from "../../reports/components/DatePickerFromToReports";
import { DatePickerNewProducts } from "../../reports/components/NewShopProducts";
import { useSearchState } from "../../../shared/hooks/useSearchState";
const { Option } = Select;
//const { RangePicker } = DatePicker

const NewMatch = () => {
    const stateUser = useSelector((state: RootStore) => state);
    const [user, setUser] = useState("");
    const [action, setAction] = useState([]);
    const [newMatched, setNewMatched] = useState<any>([]);
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const { getColumnSearchProps } = useSearchState(newMatched.data);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUsers(false, false));
    }, [dispatch]);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getNewMatched = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/intelligence/user-stats-log?user_id=${user}&action=${JSON.stringify(action)}&from=${formTo.startDate}&to=${formTo.endDate}`
                );
                setNewMatched(data);
            } catch (err) {
                console.log(err);
            }
        };
        getNewMatched();
    }, [user, action, date]);

    const handleBrandChange = (value: any) => {
        setUser(value);
    };
    const dataColumn: any = [
        {
            title: "Id",
            key: "id",
            dataIndex: "id",
            className: "redirect-center",
        },
        {
            title: "User",
            key: "username",
            dataIndex: "username",
            className: "redirect-center",
            sorter: (a: any, b: any) => a.username.localeCompare(b.username),
            ...getColumnSearchProps("username"),
        },
        {
            title: "Shop",
            key: "shop_name",
            dataIndex: "shop_name",
            className: "redirect-center",
            sorter: (a: any, b: any) => a.shop_name.localeCompare(b.shop_name),
            ...getColumnSearchProps("shop_name"),
        },
        {
            title: "Final Category",
            key: "category_name",
            dataIndex: "category_name",
            className: "redirect-center",
            sorter: (a: any, b: any) => a.category_name.localeCompare(b.category_name),
            ...getColumnSearchProps("category_name"),
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            className: "redirect-center",
            sorter: (a: any, b: any) => a.action.localeCompare(b.action),
            ...getColumnSearchProps("action"),
        },
        {
            title: "Current Product Id",
            key: "current_product_id",
            dataIndex: "current_product_id",
            className: "redirect-center",
            ...getColumnSearchProps("current_product_id"),
        },
        {
            title: "Destination Product Id",
            key: "destination_product_id",
            dataIndex: "destination_product_id",
            className: "redirect-center",
            ...getColumnSearchProps("destination_product_id"),
        },
        {
            title: "Created At",
            key: "created_at",
            dataIndex: "created_at",
            className: "redirect-center",
            sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            ...getColumnSearchProps("created_at"),
        },
    ];
    const onStatusChange = (e: any) => {
        setAction(e);
    };
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - User logger" />
            <Row justify="center">
                <Col span="22">
                    <Divider>User Logger</Divider>
                    <Select
                        mode="tags"
                        showSearch
                        style={{ width: 200, marginBottom: "5px" }}
                        placeholder="Select a status"
                        optionFilterProp="children"
                        onChange={onStatusChange}
                        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value="New match">New Match</Option>
                        <Option value="Create product">Create Product</Option>
                        <Option value="Ean match">Ean Match</Option>
                        <Option value="Mpn match">Mpn Match</Option>
                        <Option value="Model match">Model Match</Option>
                        <Option value="Auto match">Auto Match</Option>
                        <Option value="Insert">Insert</Option>
                        <Option value="Image page change">Image page change</Option>
                        <Option value="Image change">Image Change</Option>
                        <Option value="Merge product">Merge product</Option>
                        <Option value="Check price difference">Check price difference</Option>

                        <Option value="Preview text change">Preview text change</Option>
                        <Option value="Short description change">Short description change</Option>
                        <Option value="Category change">Category change</Option>
                        <Option value="Product name match">Product name match</Option>

                        <Option value="Catalogue Create">Catalogue Create</Option>
                        <Option value="Catalogue Update">Catalogue Update</Option>
                        <Option value="Product name mass edit">Product name mass edit</Option>
                        <Option value="Product review change">Product review change</Option>
                        <Option value="Automatch blacklist">Automatch blacklist</Option>
                        <Option value="MPN check">MPN check</Option>
                        <Option value="Ean detach">Ean detach</Option>
                        <Option value="Faq action">Faq action</Option>
                        <Option value="Ean overview check">Ean overview check</Option>
                    </Select>
                    <br />
                    <Select
                        showSearch
                        allowClear={true}
                        style={{ width: "200px", marginBottom: "0.5rem", marginRight: "1rem" }}
                        placeholder="Select user"
                        clearIcon={true}
                        onSelect={handleBrandChange}
                        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {stateUser.users.users &&
                            stateUser.users.users.data
                                .sort((a: any, b: any) => (a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0))
                                .map((user: any) => <Option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</Option>)}
                    </Select>

                    <DatePickerFromToReports date={date} setDate={setDate} isDate={true} />
                    <Table
                        bordered
                        rowKey={(record) => record && record.id}
                        columns={dataColumn}
                        dataSource={newMatched.data}
                        className="new-match-cell"
                        pagination={{ pageSize: 100, showSizeChanger: false }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div style={{ margin: 0 }}>
                                    <Alert
                                        message={
                                            <div>
                                                <p>
                                                    <b>Price Id: </b>
                                                    {JSON.parse(record.parameters)?.price_id}
                                                </p>
                                                <hr />
                                                <p>
                                                    <b>Current product: </b>
                                                    {record.current_product_name} ({record.current_product_id})
                                                </p>
                                                <p>
                                                    <b>Destination product: </b>
                                                    {record.destination_product_name} ({record.destination_product_id})
                                                </p>
                                            </div>
                                        }
                                        type="warning"
                                        style={{ width: "", margin: "7px" }}
                                    />
                                </div>
                            ),
                            rowExpandable: (record) => record.name !== "Not Expandable",
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default NewMatch;
