import { Button, Image, Modal, Table } from 'antd'
import React from 'react'
import { EanProductType } from '../../pages/EanCleaning'
import axios from 'axios'
import showMessage from '../../../../shared/MessagesInfo/message'

type EanModalType = {
    isModalOpen: boolean
    handleOk: () => void 
    handleCancel: () => void  
    eanData: { ean: string, data: EanProductType[] } | null
    setEanData: React.Dispatch<React.SetStateAction<{ ean: string; data: EanProductType[] } | null >>
}

const EanModal:React.FC<EanModalType> = ({ isModalOpen, handleOk, handleCancel, eanData, setEanData }) => {
    const detachPrice = async ( id:number ) => {
        setEanData( curr => curr ? ({...curr, data : curr?.data.map( e => ({...e, detach_price_loading: e.id === id ? true : false }))}) : null)
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API }/product/ean/delete-product?code=${ eanData?.ean }&product_id=${ id }`)
            showMessage(data.message)
            setEanData( curr => curr ? ({...curr, data : curr?.data.filter( e => e.id !==id )}) : null)
        } catch ( err ) {
            console.log(err)
        }
    }

    return (
        <Modal 
            title={
                <div style={{ textAlign:'center' }}>
                    List of Prices for ean: 
                    <span style={{ marginLeft:'3px', color:'darkblue' }}>{ eanData?.ean }</span> 
                </div>} 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel} 
            width={1400} 
            footer={ false }
        >
            <Table
                bordered
                dataSource={ eanData?.data }
                rowKey={ record => record.id}
                rowClassName={ record => record.detach_price_loading ? 'ean-cleaning-modal-detach-price' : ''}
                columns={
                    [
                        {
                            title:'Product Id',
                            dataIndex:'id',
                            align:"center" as "center",
                            width:'120px'
                        },
                        {
                            title: 'Image',
                            dataIndex:'id',
                            width:'100px',
                            render:( _, record ) =>
                                <Image
                                    width={60}
                                    src={`https://img.ep-cdn.com/images/500/500/${record.product_image}.webp`}
                                />
                        },
                        {
                            title:'Product Name',
                            dataIndex:'product_name',
                            render:( text, record ) => 
                                <span
                                    onClick={()=> 
                                        window.open( record.product_url, "", "width=900, height=1080, top=0, left=0")
                                    } 
                                    className="shop-link"
                                    style={{ cursor:'pointer' }}
                                >
                                    { text }
                                </span>
                        },
                        {
                            title:'',
                            dataIndex:'id',
                            width:'200px',
                            align:"center" as "center",
                                render:( id, record ) => 
                                    <Button 
                                        danger 
                                        size='small'
                                        onClick={() => detachPrice(id)}
                                        loading={ record.detach_price_loading }
                                    >
                                        Remove EAN
                                    </Button>
                        }
                    ] }
                pagination={{  
                    pageSize:100, 
                    showSizeChanger:false,
                    size:"small"   
                }}
            />
        </Modal>
    )
}

export default EanModal