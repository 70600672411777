import React from 'react'
import { Editor } from "@tinymce/tinymce-react";
import { ModalDataType } from '../page/ShopTokPage';

interface ShopTokTextEditorProps {
  initContent:string
    setModalData:React.Dispatch<React.SetStateAction<ModalDataType | null>>
}

const ShopTokTextEditor:React.FC<ShopTokTextEditorProps> = ({ initContent,setModalData }) => {
  return (
    <Editor
        initialValue={initContent}
        init={{
         
            //selector:'textarea',
            deprecation_warnings: false,
    
          
          plugins: 'emoticons hr image link lists charmap table code',
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright |emoticons | image | link | lists | charmap | table forecolor backcolor',
          height : "800",
          entity_encoding : "raw",
          force_br_newlines : true,
          force_p_newlines : false,
          forced_root_block : '',
          menubar: 'edit insert view format table tools',
          
        }}
        
        //onChange={(e:any)=>console.log(e.level.content)}
        apiKey='ttoom8yj7vhzborinbvzx8hsyovkzfee1cdfpjwpmeke3z3n'
        onEditorChange={(newValue, editor) =>  setModalData(currentData => currentData && {...currentData, content:newValue})}
        
    />
    
  )
}

export default ShopTokTextEditor