
import { Row, Col, Divider, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createAttribute } from '../../../actions/attribute/attributeAction'
import { getAllCategoryL3 } from '../../../actions/category/categoryActions'
import ButtonE from '../../../shared/FormElements/Button'
import InputE from '../../../shared/FormElements/Input'
import { RootStore } from '../../../Store'

const CreateAttribute = () => {
    //const [ categoryL3Options, setCategoryL3Options ] = useState<any>([])
    const state = useSelector(( state:RootStore ) => state )
    const [ form ] = Form.useForm()
    const { Option } = Select
    const history = useHistory()
    const dispatch = useDispatch()
    const [ curretnCountry, setCurrentCountry ] = useState<any>([])
    
    useEffect(()=>{
        if ( state.loggedInUser.user ){
           
            setCurrentCountry (  state.loggedInUser.user.data && state.loggedInUser.user.data.countries[0])
        }
    }, [ state.loggedInUser.user ])
    useEffect(()=> {
        dispatch(getAllCategoryL3(1))
    }, [ dispatch ])
   
    const handleSubmit = ( e:any ) => {
        console.log(e)
        dispatch(createAttribute(e, form, history))

    }
    return (
        <Row>
            <Col span={6} offset={8}>
                <Divider>Create new Attribute</Divider>
                <Form 
                    form={form}
                    name="create-attribute-form" 
                    labelCol={{ span: 8 }} 
                    onFinish={ handleSubmit }
                >
                    <Form.Item
                        label="Category L3" 
                        name="category_l3_id"
                        rules={[{ required: true, message: 'Missing Category L3' }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '180px', marginBottom:'0.5rem' }}
                            placeholder="Select category"
                            //defaultValue={1}
                            //onChange={onCategoryChange}
                            filterOption={(input, option:any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                            state.categoriesL3 && state.categoriesL3.data.sort((a:any,b:any) => (a[`name_${curretnCountry}`] > b[`name_${curretnCountry}`]) ? 1 : ((b[`name_${curretnCountry}`] > a[`name_${curretnCountry}`]) ? -1 : 0)).map((option:any)=>
                                 <Option key={option.id} value={option.id}>{option[`name_${curretnCountry}`]}</Option>  //option.translate &&  option.id!==1 &&    ovo da se vrati 
                            )
                        }
                        </Select>
                    </Form.Item>
                    <InputE 
                        label="Name" 
                        name="name"
                        rules={[{ required: true, message: 'Missing Name' }]}
                    />
                    {/*<InputE 
                        label="Name Si" 
                        name="name_si"
                    />
                    <InputE 
                        label="Name En" 
                        name="name_en"
                    /> */}
                    <InputE 
                        label="Type" 
                        name="type"
                        rules={[{ required: true, message: 'Missing Name Type' }]}
                    />
                    <InputE 
                        label="Min Value" 
                        name="min_value"
                        rules={[{ required: true, message: 'Missing Min Value' }]}
                    />
                    <InputE 
                        label="Max Value" 
                        name="max_value"
                        rules={[{ required: true, message: 'Missing Max Value' }]}
                    />
                    <InputE 
                        label="Eponuda Id" 
                        name="eponuda_id"
                        rules={[{ required: true, message: 'Missing Eponuda Id' }]}
                    />                   
                    <ButtonE 
                        type="primary" 
                        htmlType="submit" 
                        title="Add new Category"
                        wrapperCol={{ offset: 8, span: 16 }}
                    />
                </Form>
            </Col>
        </Row>
    )
}

export default CreateAttribute

