import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {  Row, Col, Card, Divider, Button } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import showMessage from '../../../shared/MessagesInfo/message'
import { RootStore } from '../../../Store'
import FadeIn from 'react-fade-in';
import '../style/WordReplacement.css'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import CreateCorrectWord from '../components/CreateCorrectWord'
const alpha = Array.from(Array(26)).map((e, i) => i + 65)
const alphabet = alpha.map((x) => String.fromCharCode(x))

export type CorrectWordData = {
    correct_word: string
    country_id: number
    id: number
    misspelled_word: string 
    deleteLoading: boolean
}

export type SortedAlphabetType = {
    letter: string 
    data: CorrectWordData[]
    addNew: boolean
    
}

const WordReplacement = () => {
    const [ sortedAlphabet, setSortedAlphabet ] = useState<SortedAlphabetType[]>([])
    const [ editWord, setEditWord ] = useState<CorrectWordData | null>(null)
    const state = useSelector(( state:RootStore ) => state)
    const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1

    useEffect(() => {
        const getWordReplacementList = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API }/prices/word-replacement/index?country_id=${ currentCountryId }`)
                setSortedAlphabet(alphabet.map((l:string) =>
                    ({
                        letter: l,
                        data: data.data.filter(( item:CorrectWordData ) => l.toLowerCase() === item.misspelled_word[0].toLowerCase()).map(( item: CorrectWordData ) => ({...item, deleteLoading: false})),
                        addNew: false
                        
                    })
                ))
            } catch ( err ) {
                console.log(err)
            }
        }
        getWordReplacementList()
    }, [ currentCountryId ])

    const deleteWord = async ( word: CorrectWordData, letter: string ) => {
        setSortedAlphabet( curr => curr.map( item => item.letter === letter ? ({...item, data: item.data.map( d => d.id === word.id ? ({...d, deleteLoading: true }) :d)}) : item))
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API }/prices/word-replacement/delete-word?id=${ word.id }&country_id=${ currentCountryId }`)
            setSortedAlphabet( curr => curr.map( item => item.letter === letter ? ({...item, data: item.data.filter( d => d.id !== word.id)}) : item))
            showMessage(data.message)

        } catch ( err ) {
            console.log(err)
        }
    }

    const openAddNewItem = ( letter:string, show: boolean ) => {
        setEditWord(null)
        setSortedAlphabet( curr => curr.map( w => ({...w, addNew: w.letter === letter ? show: false })))
    }

    const changeWord = ( wordData :CorrectWordData, letter: string ) => {
        setSortedAlphabet(curr => curr.map( l =>   ({...l, addNew: l.letter === letter ? true: false}) ))
        setEditWord(wordData)
    }

    if ( sortedAlphabet.length === 0 ) {
        return <div className="loading"> Loading&#8230;</div>
    }

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Word replacement" />  
            <Row justify="space-around"  id='word-replacement-box'>
                <Divider>List of Word Replacement</Divider>
                { sortedAlphabet.map((item) => 
                    <Col span={4} style={{ marginBottom:'1rem', marginTop:'1rem'}} key={item.letter}>
                        <FadeIn delay={300} transitionDuration={800}>
                            <Card 
                                type="inner" 
                                title={
                                    <Row justify="space-between">
                                        <span className='word-replacment-title-word'>
                                            { item.letter }
                                        </span>
                                        <span>
                                            {!item.addNew ?
                                            <PlusOutlined className='w-r-add-icon' style={{ marginTop:'0.3rem' }} onClick={() => openAddNewItem(item.letter, true )} />:
                                            <MinusOutlined className='w-r-add-icon' style={{ marginTop:'0.6rem' }} onClick={() => openAddNewItem(item.letter, false)} />}
                                        </span>
                                        { item.addNew && 
                                        <Col span={24}>
                                            <CreateCorrectWord 
                                                setSortedAlphabet={ setSortedAlphabet } 
                                                letterItem={ item } 
                                                currentCountryId={ currentCountryId }
                                                editWord={ editWord }
                                            />
                                        </Col>
                                        }
                                    </Row>}  
                                className='wr-card'  
                                bordered={ true } 
                            >
                                {item.data.sort(( a:CorrectWordData, b:CorrectWordData ) => a.misspelled_word.localeCompare(b.misspelled_word) ).map( words => 
                                <div className='tag-letter'  key={words.id}>
                                        <div style={{ display:'flex', justifyContent:'space-between'}}>
                                            <span style={{ color:'black', fontSize:'13px', width:'80%' }}>{`${words.misspelled_word } - `}{ words.correct_word }</span>
                                            <div style={{ display:'flex' }}>
                                                
                                                <Button 
                                                    size='small' 
                                                    icon={ 
                                                        <EditOutlined 
                                                            className='wr-edit-icon'  
                                                            onClick={()=> changeWord(words, item.letter)} 
                                                        /> 
                                                    } 
                                                />
                                                <Button 
                                                    size='small' 
                                                    icon={ 
                                                        <DeleteOutlined 
                                                            style={{ color:'darkred' }} 
                                                            onClick={() => deleteWord(words,item.letter)} 
                                                        /> 
                                                    } 
                                                    loading={ words.deleteLoading }
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Card>
                    </FadeIn>
                </Col> 
                )
            }
            {[...Array(4)].map((e, i) => <Col key={i} span={4} style={{ marginBottom:'0.5rem'}}/>)}
            </Row>
        </React.Fragment>
       
    )
}

export default WordReplacement
