import { Badge, Button, Popconfirm, Row, Table } from "antd";
import numberWithCommas from "../../../../shared/utils/numberUtils";
import showMessage from "../../../../shared/MessagesInfo/message";
import axios from "axios";
import { CheckOutlined, MinusOutlined, MoreOutlined } from "@ant-design/icons";
import { useState } from "react";
import PriceModal from "./Prices/PriceModal";
import moment from "moment";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { PriceType } from "../../types/type";
import { useSearchState } from "../../../../shared/hooks/useSearchState";
import { RootStore } from "../../../../Store";

interface PricesProps {
    prices: PriceType[];
    setPrices: React.Dispatch<React.SetStateAction<PriceType[]>>;
    currentCountryId: number;
    loading: boolean;
    stateProduct: RootStore;
}

const sortTable = (a: string, b: string, type: string) => {
    if (type === "date") {
        if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
        if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
        return 0;
    }
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

const Prices = (props: PricesProps) => {
    const [isPricesModalVisible, setIsPricesModalVisible] = useState(false);
    const [productPrice, setProductPrice] = useState<PriceType | undefined>();
    const [checkedAll, setCheckedAll] = useState(false);
    const { prices, setPrices, currentCountryId, loading, stateProduct } = props;
    const { getColumnSearchProps } = useSearchState(productPrice);
    const [visitedLink, setVisitedLink] = useState<number | undefined>();

    const detachPrice = async (id: number) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/detach-price?price_id=${id}`);
            showMessage(data.message);
            setPrices((curr) => curr.filter((p) => p.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const openPricesModal = (price: PriceType) => {
        setIsPricesModalVisible(true);
        setProductPrice(price);
    };

    const handleCancel = () => {
        setIsPricesModalVisible(false);
        setProductPrice(undefined);
    };

    const handleDetachPrices = (id: number) => {
        setPrices((curr) => curr.map((p) => (p.id === id ? { ...p, isFix: !p.isFix } : p)));
    };

    const saveDetachPrices = async () => {
        const checkedPrices = prices.flatMap((item) => (item.isFix ? item.id : []));
        if (checkedPrices.length > 0) {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/detach-prices`, {
                    price_ids: checkedPrices,
                });
                showMessage(data.message);
                // setProduct((curr) => (curr ? { ...curr, prices: curr.prices.filter((p) => !checkedPrices.includes(p.id)) } : null));
                setPrices((curr) => curr.filter((p) => !checkedPrices.includes(p.id)));
            } catch (err) {
                console.log(err);
            }
        } else {
            openNotification(`There's no selected prices!`);
        }
    };

    const visitShopLink = (url: string, id: number) => {
        window.open(url, "", "width=900,height=1080");
        setVisitedLink(id);
    };

    const currency = stateProduct.countries.find((c) => c.value === Number(currentCountryId))?.currency;

    return (
        <div>
            <PriceModal productPrice={productPrice} isPricesModalVisible={isPricesModalVisible} handleCancel={handleCancel} currentCountryId={currentCountryId} stateProduct={stateProduct} />
            <Table
                dataSource={prices.sort((a, b) => b.active_for_website - a.active_for_website)}
                columns={[
                    {
                        title: (
                            <Row justify="space-between" style={{ width: "100%" }}>
                                <div>Shop</div>

                                <div>
                                    <Badge count={prices.filter((item) => item.active_for_website).length} showZero color="#399918" />
                                    <Badge count={prices.filter((item) => !item.active_for_website).length} showZero color="red" />
                                </div>
                            </Row>
                        ),
                        dataIndex: "shop_name",
                        sorter: (a, b) => a.shop_name.localeCompare(b.shop_name),
                        ...getColumnSearchProps("shop_name"),
                        render: (_, record) => (
                            <span
                                style={{ color: visitedLink === record.id ? "purple" : "darkblue", textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => visitShopLink(record.product_url, record.id)}
                            >
                                {record.shop_name}
                            </span>
                        ),
                    },
                    {
                        title: "Price",
                        dataIndex: "price",
                        sorter: (a, b) => Number(a.price) - Number(b.price),
                        render: (_, record) => (
                            <span style={{ width: "350px", marginLeft: "2px" }}>
                                {numberWithCommas(String(record.price).replace(".", ","))} <span style={{ fontSize: "12px" }}>{currency?.toLowerCase()}</span>
                            </span>
                        ),
                    },
                    {
                        title: "Updated At",
                        dataIndex: "updated_at",
                        sorter: (a, b) => sortTable(a.updated_at, b.updated_at, "date"),
                        render: (text) => <div> {/*parseDate(record)*/ text ? `${moment(text).format("DD MMM YYYY")}, ${moment(text).format("HH:mm")}` : "-"}</div>,
                    },
                    {
                        title: "Action",
                        dataIndex: "id",
                        align: "center" as "center",
                        render: (text, record) => (
                            <Popconfirm
                                title={
                                    <div>
                                        Detach price for <b>{record.shop_name}</b>?
                                    </div>
                                }
                                onConfirm={() => detachPrice(record.id)}
                            >
                                {/* <ExclamationCircleOutlined className="demapping-price" style={{ fontSize: "20px" }} /> */}
                                <Button size="small" danger>
                                    Detach
                                </Button>
                            </Popconfirm>
                        ),
                    },
                    {
                        title: "Info",
                        dataIndex: "id",
                        align: "center" as "center",
                        render: (text, record) => (
                            <Button size="small" icon={<MoreOutlined />} onClick={() => openPricesModal(record)}>
                                More
                            </Button>
                        ),
                    },
                    {
                        title: (
                            <Row justify="space-between">
                                <Button
                                    size="small"
                                    type="default"
                                    onClick={() => {
                                        // setPriceList((curr) => curr.map((p) => ({ ...p, isFix: !checkedAll })));
                                        setPrices((curr) => curr.map((p) => ({ ...p, isFix: !checkedAll })));
                                        setCheckedAll((curr) => !curr);
                                    }}
                                >
                                    {checkedAll ? <MinusOutlined /> : <CheckOutlined />}
                                </Button>
                                <Popconfirm disabled={!prices.filter((p) => p.isFix).length} title={<div>Detach {prices.filter((p) => p.isFix).length} prices</div>} onConfirm={saveDetachPrices}>
                                    <Button
                                        size="small"
                                        // shape="round"
                                        danger
                                        disabled={!prices.filter((p) => p.isFix).length}
                                    >
                                        Detach
                                    </Button>
                                </Popconfirm>
                            </Row>
                        ),
                        dataIndex: "id",
                        width: "130px",
                        align: "center" as "center",
                        className: "prices-table-last-cell",
                        render: (_, record) => (
                            <div
                                onClick={() => handleDetachPrices(record.id)}
                                style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                {
                                    <CheckOutlined
                                        style={{
                                            opacity: record.isFix ? "1" : "0",
                                        }}
                                    />
                                }
                            </div>
                        ),
                    },
                ]}
                pagination={false}
                // scroll={{ y: "100vh" }}
                size="small"
                rowKey={(record) => record.id}
                bordered
                rowClassName={(record) => (record.active_for_website === 1 ? "product-page-active-price" : "")}
                loading={loading}
            />
        </div>
    );
};

export default Prices;
