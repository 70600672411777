import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Input, Form, Table } from 'antd'
import type { FormInstance } from 'antd/es/form';
import { AllPricesType } from '../types/AllPricesTypes';

const EditableContext = React.createContext<FormInstance<any> | null>(null);



interface EditableRowProps {
  index: number;
}

type EditableTableProps = Parameters<typeof Table>[0]

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof AllPricesType;
  record: AllPricesType;
  handleSave: (record: AllPricesType) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async (dataIndex:string) => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values, isMpnChange: dataIndex === 'mpn' ? true:record.isMpnChange });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={() => save(dataIndex)} onBlur={()=>save(dataIndex)} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


export const mappedColumns = (defaultColumns:(ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] , handleSave:(row: AllPricesType & {key: string}) => void ) => {
    return defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: EditableTableProps) =>({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            })
        }
    })
}
  
  
export const editableCellcomponents  = {
    body: {
    row: EditableRow,
    cell: EditableCell,
    },
}