import { AimOutlined, LinkOutlined } from "@ant-design/icons";
import { Row, Skeleton } from "antd";
import { EanType, PicturesType, Tabs } from "../../types/type";
import EditableTable from "./InfoAndImages/EditableTable";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import { RootStore } from "../../../../Store";

interface ProductDetailsProps {
    productInfo: {
        name: string;
        value: string | PicturesType[];
    }[];
    loadingInfo: boolean;
    setFixPriceVisible: React.Dispatch<React.SetStateAction<boolean>>;
    brandList: { id: number; name: string }[];
    categories: CateogoryL3L4[];
    productId: number | undefined;
    setProductInfo: React.Dispatch<React.SetStateAction<{ name: string; value: string | PicturesType[] }[]>>;
    getPrices: () => Promise<void>;
    stateProduct: RootStore;
    currentCountryName: string;
    selectedTab: number;
    loadingSearch: boolean;
    eans: EanType[];
    loadingDeleteEan: number | undefined;
    setLoadingDeleteEan: React.Dispatch<React.SetStateAction<number | undefined>>;
    setEans: React.Dispatch<React.SetStateAction<EanType[]>>;
}

const ProductDetails = (props: ProductDetailsProps) => {
    const { productInfo, setFixPriceVisible, getPrices, stateProduct, currentCountryName, selectedTab, loadingSearch } = props;
    const url = productInfo.find((p) => p.name === `url_${currentCountryName}`)?.value as string;
    const openFixModal = () => {
        if (selectedTab === Tabs.PRICES) {
            setFixPriceVisible(true);
        } else {
            getPrices().then(() => {
                setFixPriceVisible(true);
            });
        }
    };

    return (
        <>
            {loadingSearch ? (
                <Skeleton.Input block style={{ marginTop: "1rem" }} />
            ) : (
                <Row align="middle" justify="space-between" style={{ backgroundColor: "whitesmoke", marginTop: "1rem", padding: "0.4rem 0.8rem", border: "1px solid lightgray", borderRadius: "8px" }}>
                    <AimOutlined className="product-aim" onClick={openFixModal} />
                    <LinkOutlined
                        className="product-link"
                        style={{ opacity: url ? 1 : 0.5, cursor: url ? "pointer" : "default" }}
                        onClick={() => (url ? window.open(`${stateProduct.countries.find((c) => c.name === currentCountryName)?.link}${url}`, "", "width=900,height=1080") : {})}
                    />
                </Row>
            )}
            {loadingSearch ? (
                <div style={{ marginTop: "1rem" }}>
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                    <Skeleton.Input block style={{ height: "40px", marginTop: "0.3rem" }} />
                </div>
            ) : (
                <EditableTable {...props} />
            )}
        </>
    );
};

export default ProductDetails;
