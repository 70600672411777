import React from "react";
import Highlighter from "react-highlight-words";
import "./Hightlight.css";

export const words = [
    "osteceno",
    "ošteceno",
    "oštećeno",
    "korisceno",
    "korišceno",
    "korisćeno",
    "korišćeno",
    "outlet",
    "outlets",
    "polovno",
    "servisirano",
    "otvoren",
    "otvoren proizvod",
    "izložen",
    "izlozen",
    "kao nov",
    "testiran",
    "odprta",
    "izložbeni",
    "izlozbeni",
    "poškodovana embalaža",
    "poskodovana embalaza",
    "poškodovana",
    "poskodovana",
    "oštečena ambalaža",
    "ostecena",
    "ostecena ambalaza",
    "eksonat",
];

export const HighlilghtText = (model: string, text: string) => {
    const modelArr = model.split(" ");

    return !text ? (
        <span>{text}</span>
    ) : (
        <Highlighter
            highlightClassName="hightlight-otulet-text"
            unhighlightClassName="unhightlight-otulet-text"
            //unhighlightStyle={{ fontSize:'24px' }}
            searchWords={/*words*/ modelArr}
            autoEscape={true}
            textToHighlight={text}
        />
    );
};

interface HighlightProp {
    word: string;
    text: string;
}

const Highlilght: React.FC<HighlightProp> = ({ word, text }) => {
    return <Highlighter highlightClassName="hightlight-custom-text" searchWords={[word]} autoEscape={true} textToHighlight={text} highlightTag="span" />;
};

export default Highlilght;

export const HighlilghtProductName = (model: string, text: string) => {
    const modelArr = model ? model.split(" ") : [""];

    return !text ? (
        <span>{text}</span>
    ) : (
        <Highlighter
            highlightClassName="hightlight-otulet-text"
            unhighlightClassName="unhightlight-otulet-text"
            //unhighlightStyle={{ fontSize:'24px' }}
            searchWords={/*words*/ modelArr.map((w) => w.replace(/[(),]/g, ""))} // added .map((w) => w.replace("(", "").replace(")", "")) to ignore ()
            autoEscape={true}
            textToHighlight={text}
            caseSensitive={false}
        />
    );
};
