import { notification } from 'antd';


  const key = 'updatable';

  const openTopNotification = ( msg:string ) => {
    
      notification.error({
        key,
        message: 'Error',
        description: msg,
        placement:'top',
        duration:3
      })
  }

export default openTopNotification