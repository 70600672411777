import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";
import { updateProduct } from "../../api/apiUpdateProduct";
import { TreeCategoryType } from "../../../prices/types/AllPricesTypes";
import { CategorySelectProps, ObjCategoryType } from "../../types/modals";

const CategorySelect: React.FC<CategorySelectProps> = (props) => {
    const [levelCategories, setLevelCategories] = useState<TreeCategoryType[]>([]);
    const [updateLoading, setUpdateLoading] = useState(false);
    useEffect(() => {
        const newData = props.state.categoriesL3.data
            ? props.state.categoriesL3.data.map((level3: TreeCategoryType) => ({
                  value: `3::${level3.id}::${level3[`name_${props.currentCountryName}`]}`,
                  title: (
                      <span>
                          {`(L3) ${level3.id}: ${level3[`name_${props.currentCountryName}`]}`}
                          <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>
                              {` (${level3.l2_name})`}
                          </span>
                      </span>
                  ),
                  children: level3.children.map((level4) => ({
                      value: `4::${level4.id}::${level4[`name_${props.currentCountryName}`]}`,
                      title: <span>{`(L4) ${level4.id}: ${level4[`name_${props.currentCountryName}`]}`}</span>,
                  })),
              }))
            : [];
        setLevelCategories(newData);
    }, [props.currentCountryId, props.currentCountryName, props.state.categoriesL3?.data]);

    const onCategoryChange = async (e: string) => {
        if (e) {
            const wordToArr = e.split("::");
            const obj: ObjCategoryType = {
                category_l3_id: Number(wordToArr[1]),
                category_name: wordToArr[2],
                [`category_${props.currentCountryName}`]: `${Number(wordToArr[1])}::${wordToArr[2]}`,
            };
            setUpdateLoading(true);
            updateProduct(props.productItem?.product_id, "category", obj)
                .then(() => {
                    // console.log(resp);
                    // if (resp) {
                    props.setMatchingProducts((curr) =>
                        curr.map((p) => (p.product_id === props.productItem?.product_id ? { ...p, ...obj } : p))
                    );
                    props.setProductItem(null);
                    setUpdateLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    //props.setProductItem(null);
                });
        }
    };

    return (
        <TreeSelect
            showSearch
            style={{ width: "500px" }}
            dropdownStyle={{ overflow: "auto" }}
            placeholder="Select Category"
            allowClear
            onChange={onCategoryChange}
            treeData={levelCategories}
            treeDefaultExpandAll={false}
            size="small"
            autoFocus
            defaultOpen
            onBlur={() => props.noChangeProduct()}
            loading={props.productItem.type === "category" && updateLoading ? true : false}
        />
    );
};

export default CategorySelect;
