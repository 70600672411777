

const authCheckStatus = ( status:number ) => {
    if ( status === 401 || status === 403 ) {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpirationDate')
        localStorage.removeItem('countries')
        localStorage.removeItem('currentCountry')
        return true
    }
    return false
    
}

export default authCheckStatus