// import React, { useEffect } from 'react'
// import Highlighter from 'react-highlight-words'

// function ZamenaSpecijalnihKaraktera(text:string) {
//     const zamene = {
//         "č": "c",
//         "ć": "c",
//         "ž": "z",
//         "š": "s",
//         "đ": "d",
//         "Č": "c",
//         "Ć": "c",
//         "Ž": "z",
//         "Š": "s",
//         "Đ": "d"
//     };

// import React from 'react'
// import Highlighter from 'react-highlight-words'

// const specialCaracters = [" ","-","/"]

// const HighlightName = ({text1, text2}:{text1:string, text2:string}) => {

//   //var specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-\/\\]/g;

// export default HighlightName

//   function removeSpecialChars(word:string) {
//     return word/*.replace(specialCharsRegex, "")*/.toUpperCase();
//   }

//   const arrString = text2 ?  text2.split(" ").map(removeSpecialChars):[]

//   console.log(text1.split(" ").map(removeSpecialChars).join(" "))

//   console.log(arrString)

//   return (
//     <Highlighter
//       textToHighlight={  text1  }  //.split(" ").map(removeSpecialChars).join(" ") }
//       searchWords={ arrString }
//       highlightClassName="YourHighlightClass-test"
//       autoEscape={true}
//     />
//   )
// }

// export default HighlightName



import React, { useEffect, useState } from 'react'

//import { AutoMatchedType } from '../../pages/NewAutoMatched'


const HighlightName = ({ text1, text2, record, words, type }:{text1:string, text2:string, record: any, words: string [], type?:string }) => {
  //const inputText1 = 'Laptop Pavilion Gaming 15-dk2039nm - 4Q621EA 15,6" FHD/Intel i7-11370H/16 GB/512 GB SSD outlet'
  //const inputText2 = 'Pavilion Gaming 15-dk2039nm (4Q621EA) Intel Quad Core i7 11370H 15.6" FHD 16GB 512GB SSD GeForce RTX3050Ti crni laptop'

  const [ spanT1, setSpanT1 ] = useState('')
  const [ spanT2, setSpanT2 ] = useState('')

  useEffect(() => {
    function splitWords(text: string) {
      text = ignore_special_chars(text);
      return text.toLowerCase().split(/\s+/).filter(Boolean);
  }

  function ignore_special_chars(text: string) {
      return text? text
      .replaceAll("/", "")
      .replaceAll("", "")
      .replaceAll("-", "")
      .replaceAll("|", "")
      .replaceAll(")", "")
      .replaceAll("(", "")
      .replaceAll(",", "")
      .replaceAll(".", "")
      : ''
  }
    const words1 = splitWords(text1);
    const words2 = splitWords(text2);

 
      // const inputText1 = document.getElementById("a").textContent
      // const inputText2 = document.getElementById("b").textContent;

      const commonWords = words1.filter((word) => words2.includes(word));

      // Highlight matching words in inputText1
      //let span1 = ''
      var newtext1 = highlight_text(commonWords, text1);
      setSpanT1( remove_double_highlighting(newtext1) )

      //let span2 = ''
      var newtext2 = highlight_text(commonWords, text2);
      setSpanT2(remove_double_highlighting(newtext2))
  
 

  function remove_double_highlighting(newtext: string) {
      var special_chars = [" ", "+", "-", ".", ",", "\\", "/", "'"];

      special_chars.forEach((char, poz) => {
          newtext = newtext.replaceAll(
              "</span>" + char + '<span style="background-color: #c6e8f3">',
              char
          );
      });
      return newtext.trim();
  }

  function highlight_text(commonWords: string[], inputText1: string) {
      var newtext = "";
      inputText1 && inputText1.split(/(?<=\S)\s+(?=\S)/g).forEach((word, poz) => {


          let search_word = ignore_special_chars(word);


          newtext += commonWords.includes(search_word.toLowerCase())
              ? `<span style="background-color: #c6e8f3">${word}</span> `
              : word + " ";
      });

      return newtext;
  }

  },[ text1, text2])

  const openLink = ( url:string, side:string ) => {
    const strWindowFeatures = `width=900,height=1080${ side === "right" ? ",top=0, left=960":",top=0, left=0" }`
    window.open(url, "_blank", strWindowFeatures);
}

  return (
    <div>
      <div style={{ display:"flex" }}>
        <div onClick={() => type === "PP" ? {} : openLink(record.source_product_url,"left") } dangerouslySetInnerHTML={{ __html: spanT1 }} style={{ cursor:  type === "PP" ? "default" : "pointer" }}></div>
        { record.source_product_name && words.find( w => record.source_product_name.toLowerCase().includes(w))  && <span style={{ backgroundColor: '#f76b8a', borderRadius: '3px', color: 'white', marginLeft:"8px", padding:'0 3px'  }}>OUTLET</span>}
      </div>
      <div style={{ display:"flex" }}>
        <div onClick={() => type === "PP" ? {} : openLink(record.destination_link,"right")} dangerouslySetInnerHTML={{ __html: spanT2 }} style={{ cursor: type === "PP" ? "default" : "pointer" }}></div>
        { record.destination_product_name && words.find( w => record.destination_product_name.toLowerCase().includes(w))  && <span style={{ backgroundColor: '#f76b8a', borderRadius: '3px', color: 'white', marginLeft:"8px", padding:'0 3px'  }}>OUTLET</span>}
      </div>
    </div>
  )
}

export default HighlightName

