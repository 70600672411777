import axios from "axios";
import { Dispatch } from "react";
import showMessage from "../../shared/MessagesInfo/message";
import { AttributeCreate, AttributeDelete, AttributeItem, AttributeList, AttributeUpdate, ATTRIBUTE_DELETE, ATTRIBUTE_ITEM, ATTRIBUTE_LIST, ATTRIBUTE_UPDATE } from "./attributeActionType";

//------get all atributes
export const getAllAtributes = (currentPage: number) => async (dispatch: Dispatch<AttributeList>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/list?page=${currentPage}`)
        //setAttribute(data)
        dispatch({ type: ATTRIBUTE_LIST, payload: data })
    } catch (err) {
        console.log(err)
    }
}


//-------create attribute

export const createAttribute = (obj: any, form: any, history: any) => async (dispatch: Dispatch<AttributeCreate>) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/attribute/create`, obj)
        console.log(data)
        showMessage(data.message)
        form.resetFields()
        history.push('/attribute-list')
    } catch (err) {
        console.log(err)
    }
}

//------delete attribute

export const deleteAttribute = (id: number) => async (dispatch: Dispatch<AttributeDelete>) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/attribute/delete?id=${id}`)
        showMessage(data.message)
        console.log(data)
        dispatch({ type: ATTRIBUTE_DELETE, payload: data.data.id })
    } catch (err) {
        console.log(err)
    }
}

//--------update attribute
export const updateAttribute = (objToSend: any, form: any, history: any) => async (dispatch: Dispatch<AttributeUpdate>) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/attribute/update`, objToSend)
        showMessage(data.message)
        form.resetFields()
        history.push('/attribute-list')
        console.log(data)
        dispatch({ type: ATTRIBUTE_UPDATE, payload: data.data.id })
    } catch (err) {
        console.log(err)
    }
}

//-------attribute item

export const getOneAttribute = (id: number, form: any, setLoading: any) => async (dispatch: Dispatch<AttributeItem>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/show?id=${id}`)
        console.log(data)
        dispatch({ type: ATTRIBUTE_ITEM, payload: data })
        form.setFieldsValue(data.data)
        setTimeout(() => {
            setLoading(false)
        }, 300)
    } catch (err) {

    }
}
