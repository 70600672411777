import { CaretDownOutlined, CaretUpOutlined, CheckCircleFilled, ExclamationOutlined, LinkOutlined, MinusSquareOutlined, PlusSquareOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Layout, Input, Select, Table, Space, Button, Popover, Menu, Empty, Alert, Tag, Affix, Spin, Switch, Popconfirm } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
//import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from "react-redux";
import { getCategoryL3L4 } from "../../../actions/category/categoryActions";
import { getAllShops } from "../../../actions/shopAction/shopAction";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import showMessage from "../../../shared/MessagesInfo/message";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
//import Spinner from '../../../shared/Spinner/Spinner';
import { RootStore } from "../../../Store";
import ExpandRowItem from "../components/MatchingSellerCategory/ExpandRowItem";
import { v4 as uuidv4 } from "uuid";
import FadeIn from "react-fade-in";
import openTopNotification from "../../../shared/MessagesInfo/WarningBoxTop";
const { Header } = Layout;
const { Option } = Select;

type Suggestion = {
    id: number;
    name: string;
    rank: number;
    category_l2_id: number;
    category_l2_name: string;
};
export type Uncategorized = {
    default_image_url: string;
    id: number;
    seller_category: string;
    [key: string]: any;
};
export type KeyWordsType = {
    id: number;
    category_l3_id: number | null;
    allowed: string[];
    forbidden: string[];
    allowed_or: boolean;
    forbidden_or: boolean;
};

type SelectedUncategorizedArr = number[];

export type MappedCategoriesType = {
    category_l3_id: number | null;
    count: number;
    name: string;
    suggested: Suggestion[];
    uncategorized: Uncategorized[];
    isPopover: boolean;
    keywords: KeyWordsType[];
    selectUncategorizedArr: SelectedUncategorizedArr[];
    isExpand: boolean;
    isKeyWordLoading: boolean;
};

export type ShopType = {
    id: number;
    name: string;
    link: string;
};

export type CategoryType = {
    value: number;
    label: string;
};

export type SelectedCategoryArr = {
    name: string;
    ids: number[];
};

export type PredictedPrices = {
    id: number;
    default_image_url: string;
    seller_category: string;
    [key: string]: any;
    allowed_or: boolean;
    forbidden_or: boolean;
};

const firstLetterUpperCase = (text: string | undefined) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
};

const letters = ["š", "đ", "ž", "ć", "č"];
const removeLetters = ["s", "d", "z", "c", "c"];

const changeSpecialLetter = (text: string) => {
    if (!text) return null;
    const textLowerCase = text.toLocaleLowerCase();
    if (letters.includes(textLowerCase[0])) {
        const index = letters.findIndex((letter) => letter === textLowerCase[0]);
        const arrayLetters: string[] = textLowerCase.split("");
        arrayLetters[index] = removeLetters[index] + "z";
        return arrayLetters.join("").trim();
    } else {
        return text;
    }
};

export const showKeyCountedKeyWords = (uncategorized: Uncategorized[], currentCountryName: string) => {
    var counts: any = {};

    uncategorized.forEach((item: any) => {
        if (typeof item[`name_${currentCountryName}`] === "string") {
            item[`name_${currentCountryName}`].replace(/[^a-zA-Z ]/g, "");
        }
        const arr = item[`name_${currentCountryName}`] && item[`name_${currentCountryName}`].split(" ");
        const clearWordArr = arr?.length > 0 ? arr?.map((w: string) => w.replace(/[^a-zA-ZŽžŠšĐđČčĆć ]/g, "")) : [];
        //console.log(clearWordArr)
        clearWordArr &&
            clearWordArr.forEach((word: any) => {
                let cleanWord = word.length === 1 ? word.replace(/[^a-zA-Z ]/g, "").toLowerCase() : word.toLowerCase();
                if (!counts.hasOwnProperty(cleanWord)) {
                    counts[cleanWord] = 1;
                } else {
                    counts[cleanWord]++;
                }
            });
    });

    const entries = Object.entries(counts);
    return entries.sort((a: any, b: any) => b[1] - a[1]).slice(0, 20);
};

const MatchingSellerCategory = () => {
    const stateR = useSelector((state: RootStore) => state);
    const dispatch = useDispatch();
    const [shop, setShop] = useState<ShopType | null>(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setsaveLoading] = useState(false);
    const [mappedCategories, setMappedCategories] = useState<MappedCategoriesType[]>([]);
    const [total, setTotal] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyWords, setkeyWords] = useState([]);
    const [predictedPrices, setPredictedPrices] = useState<PredictedPrices[]>([]);
    ///////////
    const [showUncategorizedPrices, setShowUncategorizedPrices] = useState<MappedCategoriesType | null>(null);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [sortType, setSortType] = useState<null | string>(null);
    const [categoryName, setCategoryName] = useState<string | null>(null);
    const [sortUncategorized, setSortUncategorized] = useState<null | string>(null);
    ///////////
    const [activeInXml, setActiveInXml] = useState(true);
    const windowSize = useWindowSize();
    const currentCountryId = stateR.dataLs.country ? stateR.dataLs.country.countryId : 1;
    const currentCountryName = stateR.dataLs.country ? stateR.dataLs.country.currentCountry : "rs";

    useEffect(() => {
        dispatch(getAllShops(0, currentCountryId));
        dispatch(getCategoryL3L4());
        const getKeyWords = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/category-mapping/list-keywords`);
                setkeyWords(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getKeyWords();
    }, [dispatch, currentCountryId]);

    useEffect(() => {
        setMappedCategories([]);
        setShop(null);
    }, [currentCountryId]);

    ////////shop handlers
    const onShopChange = (value: number) => {
        setShowUncategorizedPrices(null);
        const findShop = stateR.shops.data.find((item: any) => item.id === value);
        if (value) {
            //getShopMappedCategories(value)
            setShop({
                id: value,
                link: findShop.homepage_url,
                name: findShop.name,
            });
            setCurrentPage(1);
        }
    };

    const resetSelectedShop = () => {
        setShop(null);
        setMappedCategories([]);
    };
    ////////

    const getShopMappedCategories = useCallback(
        async (shop_id: number | undefined) => {
            setLoading(true);
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/shop/list-mapped-categories?shop_id=${shop_id}&page=${currentPage}${sortType ? `&sort=${sortType === "desc" ? 2 : 1}` : ""}${
                        sortUncategorized ? `&uncategorized_sort=${sortUncategorized === "desc" ? 2 : 1}` : ""
                    }${categoryName ? `&name=${categoryName}` : ""}${activeInXml ? `&active=1` : ""}&limit=20`
                );
                const newData = data.data.map((item: MappedCategoriesType) => {
                    const sortedSuggested = item.suggested.sort((a, b) => a.rank - b.rank);
                    const findCateogryName = stateR.categoryL3L4.data && stateR.categoryL3L4.data.find((data1: { value: number; label: string }) => data1.value === item.category_l3_id)?.label;

                    const newKeywords =
                        item.keywords.length === 0
                            ? []
                            : item.keywords.map((data) => {
                                  return { ...data, id: uuidv4() /*, allowed_or:false, forbidden_or:false*/ };
                              });
                    return {
                        ...item,
                        keywords: newKeywords,
                        isPopover: false,
                        suggested: sortedSuggested,
                        category_l3_id: item.category_l3_id ? item.category_l3_id : null,
                        selectedCategory: findCateogryName ? findCateogryName : null,
                        selectUncategorizedArr: {},
                        isExpand: false,
                        isKeyWordLoading: false,
                    };
                }); //.sort(( a:MappedCategoriesType,b:MappedCategoriesType) => sortType ? a.uncategorized.length - b.uncategorized.length :b.uncategorized.length - a.uncategorized.length )
                setTotal(data.pages);
                setMappedCategories(newData);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
                setTotal(null);
                setMappedCategories([]);
            }
        },
        [currentPage, stateR.categoryL3L4.data, sortType, categoryName, sortUncategorized, activeInXml]
    );

    useEffect(() => {
        //const controller = new AbortController();
        shop?.id && getShopMappedCategories(shop?.id);
        //return () => controller.abort();
    }, [currentPage, shop?.id, getShopMappedCategories]);

    const openNewTab = (url: string) => {
        window.open(`https://` + url, "_blank");
    };
    const onPaginateChange = (page: number) => {
        setCurrentPage(page);
        setShowUncategorizedPrices(null);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    ///////////////////////// search in table
    const [state, setState] = useState({
        searchText: "",
        searchedColumn: "",
    });
    const handleSearch = (selectedKeys: string, confirm: () => void, dataIndex: string) => {
        confirm();
        setState({
            ...state,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setState({ ...state, searchText: "" });
    };
    let searchInput: any;
    ////////////////////////////////////
    const handleVisibleChange = (e: boolean, record: MappedCategoriesType) => {
        //
        setMappedCategories((current) => current.map((data) => ({ ...data, isPopover: data.name === record.name ? e : data.isPopover })));
    };
    const addSuggestion = (data: MappedCategoriesType, item: { id: number; name: string }) => {
        const categoryName = data.name;
        setMappedCategories((current) =>
            current.map((data) => {
                if (data.name === categoryName) {
                    return {
                        ...data,
                        category_l3_id: item.id,
                        selectedCategory: item.name,
                        isPopover: false,
                    };
                }
                return { ...data, isPopover: false };
            })
        );
    };
    const menu = (newData: MappedCategoriesType) => {
        return (
            <Menu style={{ left: "5.5rem", bottom: "3rem" }}>
                {newData?.suggested &&
                    newData?.suggested.length > 0 &&
                    newData.suggested /*.sort((a:Suggestion,b:Suggestion)=> b.rank - a.rank)*/
                        .map((item) => {
                            return (
                                <Menu.Item key={item.id} onClick={() => addSuggestion(newData, item)} className="menu-item-matching-seller-cat">
                                    {`${item.id}: ${item.name}`}
                                    <span style={{ fontSize: "11px", color: "darkslategray" }}>{`(${item?.rank.toFixed(0)}%)`}</span>
                                </Menu.Item>
                            );
                        })}
            </Menu>
        );
    };
    const setCategoryL3L4 = (record: MappedCategoriesType, e: number) => {
        const findCateogryName = stateR.categoryL3L4.data && stateR.categoryL3L4.data.find((item: { label: string; value: number }) => item.value === e)?.label;
        setMappedCategories((current) =>
            current.map((item) => {
                if (item.name === record.name) {
                    return {
                        ...item,
                        category_l3_id: e,
                        selectedCategory: findCateogryName ? findCateogryName : null,
                    };
                }
                return item;
            })
        );
    };

    const sortStringNull = (a: string | null, b: string | null) => {
        var va = a === null ? "zzz" : "" + a,
            vb = b === null ? "zzz" : "" + b;
        return va > vb ? 1 : va === vb ? 0 : -1;
    };

    const saveCategory = async () => {
        //    ??????????????????????????????????????????

        let unSelectedCategory = 0;
        mappedCategories.forEach((category) => {
            const findKeywords = category.keywords.filter((keyWords) => keyWords.category_l3_id === null);
            if (findKeywords.length > 0) {
                unSelectedCategory += findKeywords.length;
            }
        });
        if (unSelectedCategory > 0) {
            openNotification(`There is ${unSelectedCategory} open${unSelectedCategory === 0 ? "" : "s"} box in Expandable row`);
        } else {
            setsaveLoading(true);
            const newData = mappedCategories.map((item) => {
                return {
                    category_l3_id: item.category_l3_id,
                    count: item.count,
                    count_uncategorized: item.uncategorized.length,
                    name: item.name,
                    keywords: item.keywords,
                };
            });
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL_API}/maps/category/upsert`, {
                    shop_id: shop?.id,
                    mapping: newData,
                });
                showMessage(response.data.message);
                setsaveLoading(false);
            } catch (err) {
                setsaveLoading(false);
            }
        }
    };

    /////////////////////////

    const insertNewKeyword = (word: string, name: string) => {
        const findItem = mappedCategories.find((data) => data.name === name);
        //const findSameWord = findItem?.keywords.find(item => item.allowed.includes(word))

        const findSameWord = findItem?.keywords[findItem?.keywords.length - 1]?.allowed.includes(word);

        if (!findSameWord) {
            setMappedCategories((curr) =>
                curr.map((data) => {
                    if (data.name === name) {
                        return {
                            ...data,
                            isKeyWordLoading: true,
                        };
                    }
                    return data;
                })
            );

            const newData = mappedCategories.map((data: any) => {
                if (data.keywords.length > 0) {
                    if (data.name === name) {
                        const keywordsArr =
                            data.keywords.length > 0 &&
                            data.keywords.map((item: any, index: number) => {
                                if (index === data.keywords.length - 1 && !item.allowed.includes(word)) {
                                    getPredictedPrices(shop?.id, data, [...item.allowed, word], item.forbidden, index, { allowed_or: item.allowed_or, forbidden_or: item.forbidden_or });

                                    return {
                                        ...item,
                                        allowed: [...item.allowed, word],
                                    };
                                }
                                return item;
                            });
                        return {
                            ...data,
                            isKeyWordLoading: true,
                            keywords: keywordsArr.length > 0 ? keywordsArr : [],
                        };
                    }
                }

                return data;
            });
            setMappedCategories(newData);
        } else {
            openTopNotification(`Keyword "${word}"  is chosen!`);
        }
    };

    const insertKeywordForbidden = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, word: string, name: string) => {
        e.preventDefault();
        if (e.type === "contextmenu") {
            // const findItem = mappedCategories.find( data => data.name === name)
            // const findSameWord = findItem?.keywords.find( item => item.forbidden.includes(word))

            const findItem = mappedCategories.find((data) => data.name === name);
            //const findSameWord = findItem?.keywords.find(item => item.allowed.includes(word))

            const findSameWord = findItem?.keywords[findItem?.keywords.length - 1]?.forbidden.includes(word);

            if (!findSameWord) {
                ///////////////////////////////
                setMappedCategories((curr) =>
                    curr.map((data) => {
                        if (data.name === name) {
                            return {
                                ...data,
                                isKeyWordLoading: true,
                            };
                        }
                        return data;
                    })
                );
                ///////////////////////////////
                const newData = mappedCategories.map((data: any) => {
                    if (data.keywords.length > 0) {
                        if (data.name === name) {
                            const keywordsArr =
                                data.keywords.length > 0 &&
                                data.keywords.map((item: any, index: number) => {
                                    if (index === data.keywords.length - 1 && !item.forbidden.includes(word)) {
                                        getPredictedPrices(shop?.id, data, item.allowed, [...item.forbidden, word], index, { forbidden_or: item.forbidden_or, allowed_or: item.allowed_or });
                                        return {
                                            ...item,
                                            forbidden: [...item.forbidden, word],
                                        };
                                    }
                                    return item;
                                });
                            return {
                                ...data,
                                isKeyWordLoading: true,
                                keywords: keywordsArr.length > 0 ? keywordsArr : [],
                            };
                        }
                    }

                    return data;
                });
                setMappedCategories(newData);
            } else {
                openTopNotification(`Keyword "${word}"  is chosen!`);
            }
        }
    };

    // const onChangeSort = (e:any) => {
    //   console.log(e.target.value)
    // }

    const columns: any = [
        {
            title: (
                <div style={{ display: "flex" }}>
                    Count
                    <Button
                        //onClick={() =>{ setSortType(curr => !curr); setCurrentPage(1) }}
                        onClick={() => {
                            setSortType((curr) => (curr === "desc" ? "asc" : "desc"));
                            setSortUncategorized(null);
                            setCurrentPage(1);
                        }}
                        style={{ padding: "0 3px", marginLeft: "5px" }}
                        size="small"
                        icon={
                            !sortType ? undefined : sortType === "desc" ? (
                                <CaretDownOutlined style={{ marginTop: "5px", marginLeft: "3px" }} />
                            ) : (
                                <CaretUpOutlined style={{ marginTop: "5px", marginLeft: "3px" }} />
                            )
                        }
                    >
                        {!sortType ? "Sort" : ""}
                    </Button>
                </div>
            ),
            dataIndex: "count",
            align: "center" as "center",
            width: "5%",
            //sorter: (a: MappedCategoriesType, b: MappedCategoriesType) => (a.count > b.count ? 1 : -1),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a: MappedCategoriesType, b: MappedCategoriesType) => a.name.localeCompare(b.name),
            // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{
            //   setSelectedKeys: (e:React.ChangeEventHandler<HTMLInputElement> | string[])=>void;
            //   selectedKeys: string;
            //   confirm: any;
            //   clearFilters: ()=>void;
            // }) =>  (
            // <div style={{ padding: 8 }}>
            //   <Input
            //     ref={node => {
            //       searchInput = node;
            //     }}
            //     placeholder={`Search name`}
            //     value={selectedKeys[0]}
            //     onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            //     onPressEnter={() => handleSearch(selectedKeys, confirm, 'name')}
            //     style={{ marginBottom: 8, display: 'block' }}
            //   />
            //   <Space>
            //     <Button
            //       type="primary"
            //       onClick={() => handleSearch(selectedKeys, confirm, 'name')}
            //       icon={<SearchOutlined />}
            //       size="small"
            //       style={{ width: 90 }}
            //     >
            //       Search
            //     </Button>
            //     <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            //       Reset
            //     </Button>
            //     <Button
            //       type="link"
            //       size="small"
            //       onClick={() => {
            //         confirm({ closeDropdown: false });
            //         setState({
            //           ...state,
            //           searchText: selectedKeys[0],
            //           searchedColumn: 'name',
            //         });
            //       }}
            //     >
            //       Filter
            //     </Button>
            //   </Space>
            // </div>
            // ),
            // filterIcon: (filtered:string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            // onFilter: (value:string, record:any) =>
            //   record['name']
            //     ? record['name'].toString().toLowerCase().includes(value.toLowerCase())
            //     : '',
            // onFilterDropdownVisibleChange: (visible:boolean) => {
            //   if (visible) {
            //     setTimeout(() => searchInput.select(), 100);
            //   }
            // },
            // render: (text:string) =>
            //   state.searchedColumn === 'name' ? (
            //     <Highlighter
            //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            //       searchWords={[state.searchText]}
            //       autoEscape
            //       textToHighlight={text ? text.toString() : ''}
            //     />
            //   ) : (
            //     text
            // )
        },

        {
            title: "Suggestion",
            dataIndex: "suggested",
            //align: 'center' as 'center',
            sorter: (a: MappedCategoriesType, b: MappedCategoriesType) => b.suggested.length - a.suggested.length,
            render: (text: any, record: MappedCategoriesType, index: number) => {
                const firstElement = text && text.length > 0 ? text.sort((a: Suggestion, b: Suggestion) => b.rank - a.rank)[0] : [];
                if ((text && text.length === 0) || record === undefined) {
                    return;
                }
                return (
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div
                            style={{
                                width: "100%",
                                minWidth: "300px",
                                display: "flex",
                                justifyContent: "flex-start",
                                backgroundColor: firstElement.rank > 85 ? "#f2f9f1" : "",
                                paddingLeft: "10px",
                                borderRadius: "5px 0 0 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => addSuggestion(record, firstElement)}
                        >
                            <span style={{ color: "black" }}>
                                {`${firstElement?.id}: ${firstLetterUpperCase(firstElement?.name)}`}
                                <span style={{ marginLeft: "3px", fontSize: "12px", opacity: "0.8", marginTop: "1px" }}>{`(${firstElement.category_l2_name})`}</span>
                                <span style={{ color: "darkslategray", fontSize: "11px", marginTop: "3px", marginLeft: "7px" }}>{`${
                                    firstElement && firstElement.rank && firstElement?.rank.toFixed(0)
                                }%`}</span>

                                {firstElement.rank > 85 && (
                                    <CheckCircleFilled
                                        style={{
                                            marginTop: "6px",
                                            color: "#5c5470",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            marginLeft: "4px",
                                        }}
                                    />
                                )}
                            </span>
                        </div>
                        {record && record.suggested.length > 0 && (
                            <Popover
                                placement="right"
                                trigger="click"
                                onOpenChange={(e) => handleVisibleChange(e, record)}
                                open={record.isPopover}
                                content={/*record && record.suggested.length > 0 */ /*record.isPopover ?*/ () => menu(record) /*:''*/}
                            >
                                {record.suggested.length > 1 && (
                                    <Button
                                        size="small"
                                        style={{ backgroundColor: firstElement.rank > 85 ? "#f2f9f1" : "" }}
                                        onClick={() =>
                                            setMappedCategories((current) =>
                                                current.map(
                                                    (
                                                        data //dfgsdsgdas
                                                    ) => ({ ...data, isPopover: data.name === record.name ? true : false })
                                                )
                                            )
                                        }
                                    >
                                        ...
                                    </Button>
                                )}
                            </Popover>
                        )}
                    </div>
                );
            },
        },

        {
            title: "Category L3-L4",
            key: "category_l3_id",
            sorter: (a: any, b: any) => sortStringNull(changeSpecialLetter(b.selectedCategory), changeSpecialLetter(a.selectedCategory)),

            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: {
                setSelectedKeys: (e: React.ChangeEventHandler<HTMLInputElement> | string[]) => void;
                selectedKeys: string;
                confirm: any;
                clearFilters: () => void;
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => {
                            searchInput = node;
                        }}
                        placeholder={`Search selectedCategory`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, "selectedCategory")}
                        style={{ marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, "selectedCategory")} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                setState({
                                    ...state,
                                    searchText: selectedKeys[0],
                                    searchedColumn: "selectedCategory",
                                });
                            }}
                        >
                            Filter
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value: string, record: any) => (record["selectedCategory"] ? record["selectedCategory"].toString().toLowerCase().includes(value.toLowerCase()) : ""),
            onFilterDropdownOpenChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput.select(), 100);
                }
            },
            render: (text: string, record: any, index: number) => {
                return (
                    <Select
                        showSearch
                        allowClear
                        defaultValue={record.category_l3_id}
                        value={Number(record.category_l3_id) ? Number(record.category_l3_id) : null}
                        style={{ width: "100%", minWidth: "300px", border: "1px solid lightgray" }}
                        key="label"
                        bordered={false}
                        size="small"
                        onChange={(e) => setCategoryL3L4(record, e)}
                        filterOption={(input, option: any) => option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {stateR.categoryL3L4.data &&
                            stateR.categoryL3L4.data.map((item: { value: number; label: string; category_l2_id: number; category_l2_name: string }) => (
                                <Option value={item.value} key={item.value}>
                                    {
                                        <>
                                            <span>{`${item.value}: ${item.label} `}</span>
                                            <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({item.category_l2_name})</span>
                                        </>
                                    }
                                </Option>
                            ))}
                    </Select>
                );
            },
        },
        {
            title: (
                <div style={{ display: "flex" }}>
                    <span>Uncategorized</span>
                    <Button
                        onClick={() => {
                            setSortUncategorized((curr) => (curr === "desc" ? "asc" : "desc"));
                            setSortType(null);
                            setCurrentPage(1);
                        }}
                        style={{ padding: "0 3px", marginLeft: "5px" }}
                        size="small"
                        icon={
                            !sortUncategorized ? undefined : sortUncategorized === "desc" ? (
                                <CaretDownOutlined style={{ marginTop: "5px", marginLeft: "3px" }} />
                            ) : (
                                <CaretUpOutlined style={{ marginTop: "5px", marginLeft: "3px" }} />
                            )
                        }
                    >
                        {!sortUncategorized ? "Sort" : ""}
                    </Button>
                </div>
            ),
            dataIndex: "uncategorized",
            align: "center" as "center",
            width: "8%",
            //sorter: (a: MappedCategoriesType, b: MappedCategoriesType) => /*(a.count > b.count ? 1 : -1),*/ a.uncategorized.length < b.uncategorized.length ? 1 : -1,
            //sorter: (a: any, b: any) => a.uncategorized.length - b.uncategorized.length,
            render: (text: string, record: MappedCategoriesType, index: number) => {
                const oneArray: number[] = [];

                for (const key in record.selectUncategorizedArr) {
                    record.selectUncategorizedArr[key].forEach((item) => oneArray.push(item));
                }
                const countUncategorized = record.uncategorized.filter((item) => !oneArray.includes(item.id));

                return text.length > 0 ? (
                    windowSize.width < 1700 ? (
                        <Popover
                            placement="right"
                            content={
                                record.uncategorized && record.uncategorized.length > 0 ? (
                                    <div
                                        style={{
                                            maxHeight: "600px",
                                            width: "400px",
                                            overflowY: "auto",
                                            border: "1px solid lightgray",
                                            borderRadius: "4px",
                                            padding: "0.6rem",
                                            backgroundColor: "#fafafa",
                                        }}
                                    >
                                        {record.isExpand &&
                                            record.uncategorized &&
                                            showKeyCountedKeyWords(record.uncategorized, currentCountryName).map((item) =>
                                                item[0].length > 1 ? (
                                                    <Tag
                                                        color="magenta"
                                                        key={item[0]}
                                                        style={{ marginTop: "5px", cursor: "pointer" }}
                                                        onClick={() => insertNewKeyword(item[0], record.name)}
                                                        onContextMenu={(e) => insertKeywordForbidden(e, item[0], record.name)}
                                                    >
                                                        <b>{`${item[0]}`}</b>
                                                        {` - ${item[1]}`}
                                                    </Tag>
                                                ) : null
                                            )}
                                        {record.isExpand && <Divider />}
                                        {record.uncategorized.slice(0, 1000).map((data) => {
                                            return !oneArray.includes(data.id) ? (
                                                <div key={data.id} style={{ position: "relative", display: "flex", alignItems: "center", padding: "0.5rem 0rem", borderBottom: "1px solid lightgray" }}>
                                                    <img src={`https://img.ep-cdn.com/i/160/160/${data.default_image_url}.webp`} alt="uncategorized-item" width={50} />
                                                    <br />

                                                    <span style={{ marginLeft: "0.3rem", fontSize: "13px" }}>{data[`name_${currentCountryName}`]}</span>
                                                    <br />
                                                    <LinkOutlined
                                                        style={{ position: "absolute", right: "2px", top: "10px", fontSize: "12px", color: "#00bbf0" }}
                                                        onClick={() => window.open(data.product_url, "", "width=900,height=1080")}
                                                    />
                                                    <hr />
                                                </div>
                                            ) : (
                                                ""
                                            );
                                        })}
                                    </div>
                                ) : (
                                    ""
                                )
                            }
                        >
                            <span style={{ backgroundColor: "whitesmoke", cursor: "default" }}>
                                <Alert message={countUncategorized.length} style={{ height: "1rem", backgroundColor: "gray!important" }} />
                            </span>
                        </Popover>
                    ) : (
                        <Alert
                            message={countUncategorized.length}
                            type={record.name === showUncategorizedPrices?.name ? "success" : "info"}
                            style={{ height: "1rem", backgroundColor: "gray!important", cursor: "pointer" }}
                            onClick={() => showSizeUncategoirzedImages(record)}
                        />
                    )
                ) : (
                    ""
                );
            },
        },
    ];

    const onExpandRow = (e: boolean, record: MappedCategoriesType) => {
        setMappedCategories((current) =>
            current.map((data) => {
                if (data.name === record.name) {
                    return {
                        ...data,
                        isExpand: e,
                    };
                }
                return data;
            })
        );
    };
    //--------------------- Predicted prices fetch data -------------------

    const getPredictedPrices = async (shop_id: number | undefined, record: MappedCategoriesType, allowed: any, forbidden: any, indexCategorized: number, objOr: any) => {
        const obj: any = {
            shop_id: shop_id,
            seller_category: record.name,
            allowed: allowed,
            forbidden: forbidden,
            ...objOr,
        };
        let allowedString = "";
        allowed.forEach((item: any) => {
            allowedString += `&allowed[]=${item}`;
        });

        let forbiddenString = "";
        forbidden.forEach((item: any) => {
            forbiddenString += `&forbidden[]=${item}`;
        });

        setMappedCategories((curr) =>
            curr.map((data) => {
                if (data.name === record.name) {
                    return {
                        ...data,
                        isKeyWordLoading: true,
                    };
                }
                return data;
            })
        );
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/category-mapping/get-predicted-prices?shop_id=${obj.shop_id}&seller_category=${obj.seller_category.replaceAll(
                    "&",
                    "%26"
                )}${allowedString}${forbiddenString}${`&allowed_or=${obj.allowed_or ? true : false}`}${`&forbidden_or=${obj.forbidden_or ? true : false}`}`
            );
            if (data.data.length === 0) {
                openNotification("There is no Items");
                ////////////////////////////////////////////
                const newData = mappedCategories.map((item) => {
                    if (item.name === obj.seller_category) {
                        return {
                            ...item,
                            selectUncategorizedArr: { ...item.selectUncategorizedArr, [indexCategorized]: [] },
                            keywords: item.keywords.map((key, index) => {
                                if (index === indexCategorized) {
                                    return {
                                        ...key,
                                        allowed: allowed,
                                        forbidden: forbidden,
                                        ...objOr,
                                    };
                                }
                                return key;
                            }),
                        };
                    }
                    return item;
                });

                setMappedCategories(newData);
            } else {
                //setPredictedPrices(data.data)
                const idsArr = obj.allowed.length !== 0 || obj.forbidden.length !== 0 ? data.data.map((item: { id: number }) => item.id) : [];
                const newData = mappedCategories.map((item) => {
                    if (item.name === obj.seller_category) {
                        return {
                            ...item,
                            selectUncategorizedArr: { ...item.selectUncategorizedArr, [indexCategorized]: idsArr },
                            keywords: item.keywords.map((key, index) => {
                                if (index === indexCategorized) {
                                    return {
                                        ...key,
                                        allowed: allowed,
                                        forbidden: forbidden,
                                        ...objOr,
                                    };
                                }
                                return key;
                            }),
                        };
                    }
                    return item;
                });

                setMappedCategories(newData);
                setPredictedPrices([]);
            }
            setMappedCategories((curr) =>
                curr.map((data) => {
                    //if (data.name===record.name) {
                    return {
                        ...data,
                        isKeyWordLoading: false,
                    };
                    //}
                    //return data
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    const [loadingUncategoirzed, setLoadingUncategorized] = useState(false);

    const showSizeUncategoirzedImages = (record: MappedCategoriesType) => {
        setLoadingUncategorized(true);
        setShowUncategorizedPrices(null);
        setTimeout(() => {
            setShowUncategorizedPrices(record);
            let arr: number[] = [];

            for (const key in record.selectUncategorizedArr) {
                record.selectUncategorizedArr[key].forEach((item) => arr.push(item));
            }
            setSelectedIds(arr);
            setLoadingUncategorized(false);
        }, 300);
    };

    useEffect(() => {
        if (showUncategorizedPrices) {
            const findItem: any = mappedCategories.find((data) => data.name === showUncategorizedPrices.name);
            let arr: number[] = [];
            for (const key in findItem?.selectUncategorizedArr) {
                findItem?.selectUncategorizedArr[key].forEach((item: any) => arr.push(item));
            }
            setSelectedIds(arr);
        }
    }, [mappedCategories, showUncategorizedPrices]);

    // const hideSizeUncategoirzedImages = () => {
    //   setShowUncategorizedPrices(null)
    // }

    // const sortTable = (sorter:any /*SorterResult<MappedCategoriesType> | SorterResult<MappedCategoriesType>[]*/) => {
    //   if (sorter.field === 'uncategorized') {
    //     setSortType(sorter.order === 'ascend' ? 2 : sorter.order === 'descend' ? 1 :null)

    //   }

    // }

    const { Search } = Input;

    //let delayTimer:ReturnType<typeof setTimeout>
    const searchCateogryByName = (name: string | null) => {
        // clearTimeout(delayTimer);
        // delayTimer = setTimeout(function() {
        setCategoryName(name);
        setCurrentPage(1);
        // }, 1500)
    };

    const handleDemapping = async () => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/maps/category/demap-shop?shop_id=${shop?.id}`);
            getShopMappedCategories(shop?.id);
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Matching Seller Category" />
            <Row justify="center">
                <Col span={windowSize.width > 1700 ? 23 : 23} style={{ backgroundColor: "white", padding: "1rem", marginTop: "1rem" }}>
                    <Divider>Matching Seller Category</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center", position: "relative" }}>
                        <Select
                            style={{ width: "300px" }}
                            showSearch
                            allowClear
                            placeholder="Select shop"
                            onChange={onShopChange}
                            key={"shop_select"}
                            //value={shopID}
                            onClear={resetSelectedShop}
                            filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {stateR.shops?.data &&
                                stateR.shops?.data.map(
                                    (item: { id: number; name: string; last_generated_feed: boolean }, index: number) =>
                                        item.last_generated_feed && (
                                            <Select.Option value={item.id} key={"category_seller_" + item.id}>
                                                {`${item.id}: ${item.name}`}
                                            </Select.Option>
                                        )
                                )}
                        </Select>
                        <Switch
                            checkedChildren="xml"
                            unCheckedChildren="All"
                            //checked={ activeInXml }
                            defaultChecked
                            onChange={(e) => setActiveInXml(e)}
                            size="small"
                            style={{ marginTop: "7px", marginLeft: "10px" }}
                        />
                        <div style={{ position: "absolute", right: "10%" }}>
                            {mappedCategories.length > 0 && (
                                <Popconfirm title="Demapping shop categories" description="Are you sure?" okText="Yes" cancelText="No" onConfirm={handleDemapping}>
                                    <Button danger /*onClick={() => handleDemapping()}*/>Demapping</Button>
                                </Popconfirm>
                            )}
                        </div>
                    </div>
                </Col>
                <React.Fragment>
                    {mappedCategories.length > 0 ? (
                        <React.Fragment>
                            <Col span={windowSize.width > 1700 ? 17 : 23} style={{ backgroundColor: "white", minHeight: "75vh" }}>
                                <div>
                                    {
                                        <Layout style={{ margin: "0.6rem 1rem", backgroundColor: "white", height: "100%" }}>
                                            {mappedCategories.length > 0 && (
                                                <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.85rem", lineHeight: "30px" }}>
                                                    <span style={{ position: "relative", right: "1.4rem" }}>
                                                        {`Category list for `} <i style={{ fontSize: "16px" }}>{`${shop?.name}`}</i>
                                                        {shop?.link && (
                                                            <React.Fragment>
                                                                <LinkOutlined
                                                                    style={{ color: "#00bbf0", position: "relative", bottom: "3px", marginLeft: "3px" }}
                                                                    onClick={() => openNewTab(shop.link)}
                                                                />
                                                                <span style={{ marginLeft: "1rem" }}>|</span>
                                                            </React.Fragment>
                                                        )}
                                                        <span style={{ marginLeft: "1rem", fontSize: "12px" }}>{`Page ${currentPage}`}</span>
                                                    </span>

                                                    {!categoryName ? (
                                                        <Search placeholder="Search cateogry by Name" size="small" onSearch={(e) => searchCateogryByName(e)} style={{ width: 200, marginTop: "3px" }} />
                                                    ) : (
                                                        <Tag color="blue" closable onClose={() => setCategoryName(null)}>
                                                            {categoryName}
                                                        </Tag>
                                                    )}
                                                </Header>
                                            )}

                                            <Table
                                                columns={columns}
                                                dataSource={mappedCategories}
                                                id="matching-seller-category-table"
                                                bordered
                                                rowKey={(record) => record.name}
                                                //rowClassName = { ( record ) =>  `${record.isExpand ? 'msc-expand-row':''}` }
                                                loading={saveLoading || loading}
                                                pagination={{ pageSize: 50, total: total ? 50 * total : 0, onChange: onPaginateChange, current: currentPage, showSizeChanger: false }}
                                                //onChange={(_: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter)=>{sortTable(sorter)}}

                                                footer={() => (
                                                    <div className="text-center">
                                                        <Button className={"primary"} danger onClick={saveCategory}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                )}
                                                expandable={{
                                                    expandedRowKeys: mappedCategories.filter((m) => m.isExpand).map((m) => m.name),
                                                    expandedRowRender: (record, index) => {
                                                        return (
                                                            <div style={{ display: "flex" }}>
                                                                <ExpandRowItem
                                                                    record={record}
                                                                    shop={shop}
                                                                    // keywords={mappedCategories[index].keywords} //??????????????????????
                                                                    keywords={mappedCategories.find((data) => data.name === record.name)?.keywords}
                                                                    stateR={stateR}
                                                                    mappedCategories={mappedCategories}
                                                                    setMappedCategories={setMappedCategories}
                                                                    keyWords={keyWords}
                                                                    currentCountryName={currentCountryName}
                                                                    uuidv4={uuidv4}
                                                                    predictedPrices={predictedPrices}
                                                                    setPredictedPrices={setPredictedPrices}
                                                                    getPredictedPrices={getPredictedPrices}
                                                                />
                                                                {windowSize.width > 1700 && (
                                                                    <div style={{ padding: "0 0.5rem 0 1.5rem", width: windowSize.width > 1700 ? (windowSize.width * 420) / 1770 : "600px" }}>
                                                                        {record.isExpand &&
                                                                            record.uncategorized &&
                                                                            showKeyCountedKeyWords(record.uncategorized, currentCountryName).map((item) =>
                                                                                item[0].length > 1 ? (
                                                                                    <Tag
                                                                                        color="geekblue"
                                                                                        key={item[0]}
                                                                                        style={{ marginTop: "5px", cursor: "pointer" }}
                                                                                        onClick={() => insertNewKeyword(item[0], record.name)}
                                                                                        onContextMenu={(e) => insertKeywordForbidden(e, item[0], record.name)}
                                                                                    >
                                                                                        <b>{`${item[0]}`}</b>
                                                                                        {` - ${item[1]}`}
                                                                                    </Tag>
                                                                                ) : null
                                                                            )}
                                                                        {/* windowSize.width > 1700 && <RightSquareFilled style={{ position:'absolute', top:'0', right:'0', color:'coral', cursor:'pointer' }} onClick={() => showSizeUncategoirzedImages(record)} />*/}
                                                                        {/* windowSize.width > 1700 && <CloseSquareFilled style={{ position:'absolute', bottom:'0', right:'0', color:'#eeeeee', cursor:'pointer' }} onClick={() => hideSizeUncategoirzedImages()} />*/}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    },
                                                    onExpand: (e, record) => {
                                                        onExpandRow(e, record);
                                                    },
                                                    expandIcon: ({ expanded, onExpand, record }) => (
                                                        <div>
                                                            {expanded ? (
                                                                <MinusSquareOutlined
                                                                    style={record.keywords && record.keywords.length > 0 ? { backgroundColor: "#eaf6f6", color: "#00bbf0" } : {}}
                                                                    onClick={(e) => onExpand(record, e)}
                                                                />
                                                            ) : (
                                                                <PlusSquareOutlined
                                                                    style={record.keywords && record.keywords.length > 0 ? { backgroundColor: "#eaf6f6", color: "#00bbf0" } : {}}
                                                                    onClick={(e) => onExpand(record, e)}
                                                                />
                                                            )}
                                                            {record.keywords && record.keywords.filter((keyword) => !keyword.category_l3_id).length > 0 && <ExclamationOutlined />}
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        </Layout>
                                    }
                                </div>
                            </Col>
                            {windowSize.width > 1700 && (
                                <Col span={/*windowSize.width > 1700 ? 6 :1*/ 6} style={{ backgroundColor: "white" }}>
                                    {showUncategorizedPrices && showUncategorizedPrices?.uncategorized.length > 0 ? (
                                        <Affix offsetTop={10}>
                                            <div style={{ height: "67.5vh", border: "1px solid #dfd3c3", overflowY: "auto", marginTop: "6px" }}>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: 300,
                                                        backgroundColor: "white",
                                                        width: "96%",
                                                        padding: "0 0.3rem",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <p style={{ fontSize: "14px" }}>{showUncategorizedPrices && showUncategorizedPrices.name}</p>
                                                    <p style={{ fontSize: "14px" }}>
                                                        {showUncategorizedPrices && showUncategorizedPrices.uncategorized.filter((data) => !selectedIds.includes(data.id)).length}
                                                    </p>
                                                </div>

                                                <Divider></Divider>

                                                {showUncategorizedPrices &&
                                                    showUncategorizedPrices.uncategorized.slice(0, 1000).map((data) =>
                                                        !selectedIds.includes(data.id) ? (
                                                            <FadeIn delay={300} transitionDuration={800} key={data.id}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        position: "relative",
                                                                        backgroundColor: "white",
                                                                        margin: "0 0.6rem 0.2rem 0.3rem",
                                                                        padding: "0.5rem 0.3rem",
                                                                        border: "1px solid #eef2e2",
                                                                    }}
                                                                >
                                                                    <img src={`https://img.ep-cdn.com/i/160/160/${data.default_image_url}.webp`} alt="uncategorized-item" width={50} />
                                                                    <br />

                                                                    <span style={{ marginLeft: "0.4rem", fontSize: "13px" }}>{data[`name_${currentCountryName}`]}</span>
                                                                    <br />
                                                                    <LinkOutlined
                                                                        style={{ position: "absolute", right: "10px", top: "10px", fontSize: "12px", color: "#00bbf0" }}
                                                                        onClick={() => window.open(data.product_url, "", "width=900,height=1080")}
                                                                    />
                                                                    <hr />
                                                                </div>
                                                            </FadeIn>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                            </div>
                                        </Affix>
                                    ) : (
                                        <Affix offsetTop={10}>
                                            <div style={{ backgroundColor: "white", height: "99%", paddingTop: "0.6rem" }}>
                                                <div style={{ backgroundColor: "#f9f9f9", height: "100%", border: "1px solid #dfd3c3", marginRight: "1rem" }}>
                                                    <div style={{ textAlign: "center", marginTop: "0.5rem" }}>{!loadingUncategoirzed ? `No selected data` : <Spin />}</div>
                                                </div>
                                            </div>
                                        </Affix>
                                    )}
                                </Col>
                            )}
                        </React.Fragment>
                    ) : (
                        <Col style={{ height: "75vh", width: "95.9vw", backgroundColor: "white" }}>
                            <div className="matching-seller-category-empty">{!loading ? <Empty description="No data, select shop" /> : <Spin style={{ marginLeft: "47%" }} />} </div>{" "}
                        </Col>
                    )}
                </React.Fragment>
            </Row>
        </React.Fragment>
    );
};

export default MatchingSellerCategory;
