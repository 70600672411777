import { Button, Divider, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { PrimaryAttributesType } from "../../pages/TopProductAttributes";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

type CreateNewValuesType = {
    openModal: boolean;
    handleCancel: () => void;
    newAttributeValue: { name: string | undefined; attribute_id: number | undefined } | null;
    setPrimaryAttributes: React.Dispatch<React.SetStateAction<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>>;
    countries: { name: string; value: number }[];
    currentCountryName: string;
};

const ApplyToAllCreateNewValue: React.FC<CreateNewValuesType> = ({ openModal, handleCancel, newAttributeValue, countries, currentCountryName, setPrimaryAttributes }) => {
    const [form] = Form.useForm();
    const [loadingNewValue, setLoadingNewValue] = useState(false);

    const saveNewAttributeValues = async (e: any) => {
        const objToSend = {
            ...e,
            attribute_id: newAttributeValue?.attribute_id,
        };

        try {
            setLoadingNewValue(true);
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-value`, objToSend);
            if (data.data.already_exists) {
                openNotification("Attribute already exists");
            } else {
                setPrimaryAttributes((curr: any) =>
                    curr.map((attr: any) =>
                        attr.id === newAttributeValue?.attribute_id
                            ? { ...attr, attribute_values: [...attr.attribute_values, { allowed_value_id: data.data.values, value: e[`value_${currentCountryName}`] }] }
                            : attr
                    )
                );
                handleCancel();
                form.resetFields();
                showMessage(data.message);
            }
            setLoadingNewValue(false);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Modal
            title={
                <div>
                    <p style={{ textAlign: "center" }}>
                        New Value for <span style={{ color: "darkred" }}>{newAttributeValue?.name}</span>
                    </p>
                </div>
            }
            open={openModal}
            onCancel={handleCancel}
            footer={false}
        >
            <Divider />
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={saveNewAttributeValues}
                autoComplete="off"
                form={form}
            >
                {countries.map((c) => (
                    <Form.Item<FieldType>
                        label={c.name.toUpperCase()}
                        name={`value_${c.name}`}
                        rules={[{ required: true, message: `Please input your ${c.name.toUpperCase()} translate!` }]}
                        key={c.name}
                    >
                        <Input placeholder={`Attribute Value ${c.name.toUpperCase()}`} />
                    </Form.Item>
                ))}
                <Form.Item<FieldType> label={"EN"} name={`value_en`} rules={[{ required: true, message: `Please input your ${"EN"} translate!` }]} key={"en"}>
                    <Input placeholder={`Attribute Value ${"EN"}`} />
                </Form.Item>
                <Divider />
                <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit" loading={loadingNewValue}>
                        Save New Value
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ApplyToAllCreateNewValue;
