import { Button, Card, Col, Divider, Form, Input, Row, Skeleton, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/userAction/userAction'
import { RootStore } from '../../../Store'
import openNotification from '../../../shared/MessagesInfo/WarningBox'
import showMessage from '../../../shared/MessagesInfo/message'

const EditUser = () => {
    const [ user, setUser ] = useState< {id:Number,first_name:string, last_name:string} | null>(null)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const userState = useSelector( ( state:RootStore ) => state.user)

    useEffect(() => {
        dispatch(getUser())
    }, [ dispatch ])

    useEffect(() => {
        //console.log(userState.data)
        if(userState.data) {
            setUser({first_name:userState.data.first_name, last_name:userState.data.last_name, id: userState.data.id})
            //form.setFieldsValue({first_name:userState.data.first_name, last_name:userState.data.last_name})
            // form.getFieldsValue([
            //     ['password', 125],
            //     ['last_name', 'account'],
            //   ]);
        }
    }, [userState])

    const [ isValidPassword, setIsValidPassword ] = useState(true)

    const editUser = async (e:any) => {
        const objToSend = {
            ...user,
            ...e
        }
        if ( e.password !== e.password_confirmation ) {
            openNotification('Password Confirmation has to be same as Password.')
            setIsValidPassword(false)
            return
        }
        if( e.password.trim().length < 6) {
            openNotification('The password has to contain minimum 6 characters')
            setIsValidPassword(false)
            return
        }

        setIsValidPassword(true)
      
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/auth/editself`, objToSend)
            showMessage(data.message)
            form.resetFields()
            //setUser(null)
        } catch ( err ) {
            console.log(err)
        }
        
    }

    // const watchPassword: string = Form.useWatch( 'password', form )
    // const watchPasswordConfirmation: string = Form.useWatch( 'password_confirmation', form )

    return (
        <Row justify="center" style={{ backgroundColor:"white",margin:"1rem" }}>
            <Col 
                span={6} 
                style={{  height:"86vh" }}
                
            >
            
                <Divider>Edit User</Divider>
                <Card style={{ padding:"2rem" }} >
                <Form 
                    name="myForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    form={ form }
                    onFinish={editUser}
                    autoComplete="off"
                >
                    <Form.Item
                        label="First Name"
                    >
                        { user ? <Typography.Text strong>{user?.first_name}</Typography.Text>:
                        <Skeleton.Input block active  />}
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                    >
                         { user ? <Typography.Text strong>{user?.last_name}</Typography.Text>:
                        <Skeleton.Input block active  />}
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required:true, message:"New Password is required" }]}
                    >
                         { user ?  <Input.Password type='password'  placeholder="New Password" autoComplete="off" />:
                        <Skeleton.Input block active /> }
                    </Form.Item>
                    <Form.Item
                        label="Password Confirmation"
                        name="password_confirmation"  
                        rules={[{required:true, message:"Password Confirmation is required"}]}  
                        validateStatus={ !isValidPassword ? 'error':undefined}                      
                    >
                        { user ? 
                        <Input.Password  placeholder="Password Confirmation" autoComplete="one-time-code" />:
                        <Skeleton.Input block  active />}
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
                        {user ?<Button type="primary" htmlType="submit" block>Edit</Button>:
                        <Skeleton.Button block active />}
                    </Form.Item>
                </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default EditUser