//-----------category l3 list 

import axios from "axios";
import { Dispatch } from "react";
import { CATEGORY_L1_LIST, CATEGORY_L3_L4, CATEGORY_L3_LIST } from "./categoryActionsType";

export const getAllCategoryL3 = (country_id:number) => async (dispatch: Dispatch<any>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-l3?country_id=${ country_id }`)
        dispatch({ type: CATEGORY_L3_LIST, payload: data })
    } catch (err) {
        console.log(err)
    }
}

//---------- get category L3L4    for deleting

export const getCategoryL3L4 = () => async (dispatch: Dispatch<any>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-l34`)

        for (var i = 0; i < data.data.length; i++) {
            data.data[i]['key'] = data.data[i].id + '.category.l3.l4';
            data.data[i]['label'] = data.data[i].name.split(":")[1].trim();
            data.data[i]['value'] = data.data[i].id;
            delete data.data[i].name
            delete data.data[i].id;
        }
        //console.log(data)
        // for (var i = 0; i < data.data.length; i++) {
        //     data.data[i]['key'] = data.data[i].id + '.category.l3.l4';
        //     data.data[i]['label'] = data.data[i].name;
        //     data.data[i]['value'] = data.data[i].id;
        //     delete data.data[i].name
        //     delete data.data[i].id;
        // }
        dispatch({ type: CATEGORY_L3_L4, payload: data })
    } catch (err) {
        console.log(err)
    }
}

export const getAllCategoryL1 = () => async (dispatch: Dispatch<any>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list?level_id=1`)
        dispatch({ type: CATEGORY_L1_LIST, payload: data })
    } catch (err) {
        console.log(err)
    }
}

