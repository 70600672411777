import { Form, Input, InputRef } from 'antd'
import { capitalize } from '../../helper/firstLetter'

type FormInputType = {
    name: string
    input?: boolean
    rows?: number
    require?: boolean
    onInputBlur?: (e:React.FocusEvent<HTMLInputElement, Element> ) => void
    disabled?: boolean
    inputRef?: React.RefObject<InputRef>
}

const FormInput = ( props:FormInputType ) => 
    <Form.Item 
        label={ capitalize( props.name.replace("_id","" )) }
        name={ props.name }
        rules={[{ required: props.require ? true:false, message: `Please input ${ capitalize( props.name.replace("_id","" )) }` }]}
    >
        {props.input ? 
            <Input 
                placeholder={capitalize( props.name )} 
                onBlur={ props.onInputBlur} 
                disabled={ props.disabled }
                ref={ props.inputRef }
            />:
            <Input.TextArea 
                placeholder={capitalize( props.name )} 
                rows={8} 
            />
        }
    </Form.Item>

export default FormInput