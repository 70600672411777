import { BRAND_CREATE, BRAND_DELETE, BRAND_LIST } from "../actions/brand/BrandActionType";

export const brandReducer = ( state:any = {}, action:any ) => {
    switch ( action.type ) {
        case BRAND_LIST:
            return action.payload
        case BRAND_DELETE:
            const newState = state.data.filter((brand:any)=>brand.id!==Number(action.payload.data.id))
            return {
                ...state,
                data:newState
            }
        case BRAND_CREATE:
            return state
        default:
            break;
    }
    return state
}