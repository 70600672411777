import { Card, Col, Divider, Image, Modal, Popover, Row, Table } from "antd";
import React from "react";
import { ProductType } from "../../pages/TopProductAttributes";

interface ProductsInModalProps {
    searchParam:
        | {
              category_name: string;
              attribute_name: string;
              attribute_type: string;
              search: string;
              include: string[];
              exclude: string[];
              or: Boolean;
          }
        | undefined;
    products: ProductType[];
    isShowProducts: boolean;
    handleCancel: () => void;
}

const ProductsInModal = (props: ProductsInModalProps) => {
    const { searchParam, products, isShowProducts, handleCancel } = props;
    return (
        <Modal open={isShowProducts} onCancel={handleCancel} width="60vw" footer={false}>
            {/* <Card title={`Category - ${searchParam?.category_name}`} size="small"> */}
            <Card
                title={
                    <div style={{ display: "flex" }}>
                        {searchParam?.category_name} -
                        <div style={{ color: "darkred", marginLeft: "0.3rem" }}>
                            {searchParam?.attribute_name} ({searchParam?.attribute_type})
                        </div>
                    </div>
                }
                size="small"
                style={{ backgroundColor: "whitesmoke", marginRight: "1rem" }}
            >
                {/* <div style={{ color: "darkred" }}>
                        Attribute : {searchParam?.attribute_name} ({searchParam?.attribute_type})
                    </div> */}
                <div style={{ display: "flex", marginTop: "0.3rem", alignItems: "center" }}>
                    {/* <span style={{ fontWeight: 600 }}>Paramseters:</span> */}
                    <div style={{ border: "1px solid lightgray", padding: "2px 8px", borderRadius: "5px" }}>
                        Search : <span style={{ fontWeight: 600 }}>{searchParam?.search}</span>
                    </div>
                    <div style={{ border: "1px solid lightgray", padding: "2px 8px", borderRadius: "5px", marginLeft: "0.3rem" }}>
                        Include : <span style={{ fontWeight: 600 }}>{searchParam?.include?.map((item) => item).join(",") ?? "-"}</span>
                    </div>
                    <div style={{ border: "1px solid lightgray", padding: "2px 8px", borderRadius: "5px", marginLeft: "0.3rem" }}>
                        Exclude : <span style={{ fontWeight: 600 }}>{searchParam?.exclude?.map((item) => item).join(",") ?? "-"}</span>
                    </div>
                    <div style={{ border: "1px solid lightgray", padding: "2px 8px", borderRadius: "5px", marginLeft: "0.3rem" }}>
                        Or : <span style={{ fontWeight: 600 }}>{searchParam?.or ? "Yes" : "No"}</span>
                    </div>
                </div>
            </Card>
            <Row style={{ marginTop: "1rem", backgroundColor: "#E9EFEC", padding: "1rem", borderRadius: "8px" }}>
                <Divider style={{ margin: "5px 0" }}>Products</Divider>
                <Col span={11}>
                    <Table
                        dataSource={products.slice(0, 50)}
                        columns={[
                            {
                                dataIndex: "id",
                                render: (_, record, index) => index + 1 + ".",
                                width: "12px",
                                align: "center" as "center",
                            },
                            {
                                title: "Name",
                                dataIndex: "product_name",
                                width: "400px",
                                render: (_, record: any) => (
                                    <span
                                        className="top-attribute-product-name"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => window.open(record.product_link, "_blank", "noreferrer")}
                                    >{`${record.brand_name} ${record.product_name}`}</span>
                                ),
                            },
                            {
                                title: "Image",
                                dataIndex: "product_image",
                                width: "70px",
                                render: (text) => (
                                    <Popover
                                        placement="rightTop"
                                        content={
                                            <div style={{ width: "500px" }}>
                                                <Image src={text} preview={false} />
                                            </div>
                                        }
                                        arrowPointAtCenter
                                    >
                                        <Image src={text} width={40} preview={false} />
                                    </Popover>
                                ),
                            },
                        ]}
                        pagination={false}
                        size="small"
                        rowKey={(record) => record.product_id}
                    />
                </Col>
                <Col span={11} offset={2}>
                    {products.slice(50).length > 0 && (
                        <Table
                            dataSource={products.slice(50)}
                            columns={[
                                {
                                    dataIndex: "id",
                                    render: (_, __, index) => index + 51 + ".",
                                    width: "12px",
                                    align: "center" as "center",
                                },
                                {
                                    title: "Name",
                                    dataIndex: "product_name",
                                    width: "400px",
                                    render: (_, record: any) => (
                                        <span
                                            className="top-attribute-product-name"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => window.open(record.product_link, "_blank", "noreferrer")}
                                        >{`${record.brand_name} ${record.product_name}`}</span>
                                    ),
                                },
                                {
                                    title: "Image",
                                    dataIndex: "product_image",
                                    width: "70px",
                                    render: (text) => (
                                        <Popover
                                            placement="rightTop"
                                            content={
                                                <div style={{ width: "500px" }}>
                                                    <Image src={text} preview={false} />
                                                </div>
                                            }
                                            arrowPointAtCenter
                                        >
                                            <Image src={text} width={40} preview={false} />
                                        </Popover>
                                    ),
                                },
                            ]}
                            pagination={false}
                            size="small"
                            rowKey={(record) => record.product_id}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default ProductsInModal;
