import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import TextEditor from "./TextEditor";
import { RootStore } from "../../../../Store";
import { DescriptionReviewType, ExpandRowDataType } from "../../pages/DescriptionReview";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { CheckCircleOutlined } from "@ant-design/icons";
interface AllReviewsProps {
    state: RootStore;
    expandRowData: ExpandRowDataType | null;
    setExpandRowData: React.Dispatch<React.SetStateAction<ExpandRowDataType | null>>;
    record: DescriptionReviewType;
    getCategories: () => Promise<void>;
    setDescRevData: React.Dispatch<React.SetStateAction<DescriptionReviewType[]>>;
    currentCountryName: string;
}
const checkYoutubeLength = (text: string) => text.split(":").length - 1;
const urlToSec = (number: number | string | null) => {
    if (typeof number === "number") {
        const min = number / 60;
        return `${Math.floor(min) < 10 ? 0 : ""}${Math.floor(min)}:${number % 60 < 10 ? 0 : ""}${number % 60}`;
    }
};
const AllReviews: React.FC<AllReviewsProps> = (props) => {
    const { state, expandRowData, setExpandRowData, record, getCategories, setDescRevData, currentCountryName } = props;
    const [getTextLoading, setGetTextLoading] = useState(false);
    const [reviewLoading, setReviewLoading] = useState(false);
    const [deleteReviewLoading, setDeleteReviewLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (expandRowData) {
            form.setFieldsValue({
                review_title: expandRowData?.review[`review_title_rs`],
                youtube_url: expandRowData?.review[`youtube_url_rs`],
                youtube_length: urlToSec(expandRowData?.review[`youtube_length_rs`]),
            });
        }
    }, [expandRowData, form]);

    const saveReviewText = async () => {
        setReviewLoading(true);
        const eventObj = form.getFieldsValue();
        const url = String(eventObj[`youtube_length`]);
        if (!url.split("").includes(":") || checkYoutubeLength(url) > 1) {
            openNotification("YouTube length need to be formatted with : between minutes and seconds.");
            setReviewLoading(false);
            return;
        }
        const urlArr = url.split(":");
        const urlToSeconds = Number(urlArr[0]) * 60 + Number(urlArr[1]);
        let tempArr: { [key: string]: string | number | null | undefined } = {};
        const review_title = form.getFieldValue("review_title");
        const youtbe_url = form.getFieldValue("youtube_url");
        const lengthInSecond = urlToSeconds;
        state.countries.forEach((c) => {
            const prodObj = { ...expandRowData?.review };
            tempArr[`review_title_${c.locale_code}`] = review_title ?? null;
            tempArr[`youtube_url_${c.locale_code}`] = youtbe_url ?? null;
            tempArr[`youtube_length_${c.locale_code}`] = lengthInSecond ?? null;
            tempArr[`review_content_${c.locale_code}`] = prodObj[
                `review_content_${c.locale_code}` as keyof typeof prodObj
            ]
                ? prodObj[`review_content_${c.locale_code}` as keyof typeof prodObj]
                : null;
        });
        tempArr[`review_content_en`] = expandRowData?.review ? expandRowData.review[`review_content_en`] : "";
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/product-review/upsert`, {
                product_id: record.id,
                ...tempArr,
            });
            showMessage(data.message);
            setReviewLoading(false);
            setIsSaved(true);
            getCategories();
            const newReviewFlag = tempArr[`review_content_${currentCountryName}`];
            setDescRevData((curr) =>
                curr.map((d) => (d.id === record.id ? { ...d, review_flag: newReviewFlag ? 1 : 0 } : d))
            );
        } catch (err) {
            console.log(err);
        }
    };

    const getReviewText = async () => {
        setGetTextLoading(true);
        const youtubeLength = form.getFieldValue(`youtube_length`);
        if (!youtubeLength || !youtube_url) {
            openNotification("Youtube url and Youtube lenght are required!");
            setGetTextLoading(false);
            return;
        }
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/reviews/summary?youtubeId=${youtube_url}`
            );
            if (!data.success) {
                openNotification(`This video doesn't have review text`);
                setGetTextLoading(false);
                return;
            }
            const dataObj = data.data;
            const arrayDescCountry = state.countries.map((c) => {
                const content = dataObj[c.theebox_locale_code] ?? null;
                const url = form.getFieldValue("youtube_length");
                const urlArr = url.split(":");
                const lengthInSecond = Number(urlArr[0]) * 60 + Number(urlArr[1]);
                if (!content) {
                    return {
                        [`review_content_${[c.name]}`]: null,
                        [`review_title_${[c.name]}`]: null,
                        [`youtube_length_${[c.name]}`]: null,
                        [`youtube_url_${[c.name]}`]: null,
                    };
                }
                return {
                    [`review_content_${[c.name]}`]: dataObj[c.theebox_locale_code],
                    [`review_title_${[c.name]}`]: `${record.brand_name} ${record.mpn ? record.mpn : ""} video test`, //??? here
                    [`youtube_length_${[c.name]}`]: lengthInSecond,
                    [`youtube_url_${[c.name]}`]: form.getFieldValue("youtube_url"),
                };
            });
            /*.filter((c) => c);*/
            let tempObj: { [key: string]: string } = {};
            arrayDescCountry.forEach((obj) => {
                for (let key in obj) {
                    tempObj[key] = obj[key];
                }
            });
            setExpandRowData((curr) => (curr ? { ...curr, review: { ...curr.review, ...tempObj } } : null));
            setGetTextLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    const deleteReviewText = async () => {
        const tempArr: { [key: string]: string | number | null } = {};
        state.countries.forEach((c) => {
            tempArr[`review_title_${c.locale_code}`] = null;
            tempArr[`youtube_url_${c.locale_code}`] = null;
            tempArr[`youtube_length_${c.locale_code}`] = null;
            tempArr[`review_content_${c.locale_code}`] = null;
            // }
        });
        tempArr[`review_content_en`] = null;
        setDeleteReviewLoading(true);
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/product-review/upsert`, {
                product_id: record.id,
                ...tempArr,
            });
            setExpandRowData((curr) => (curr ? { ...curr, review: { ...curr?.review, ...tempArr } } : null));
            setDeleteReviewLoading(false);
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };
    const youtube_url = Form.useWatch(`youtube_url`, form);

    const handleBlur = (type: "review_title" | "youtube_url" | "youtube_length") => {
        const formInput = form.getFieldValue(type);

        const tempCountryArr: { [key: string]: string | number | null } = {};

        state.countries.forEach((c) => {
            tempCountryArr[`${type}_${c.locale_code}`] =
                type === "youtube_length"
                    ? Number(formInput.split(":")[0]) * 60 + Number(formInput.split(":")[1])
                    : formInput;
        });
        setExpandRowData((curr) => (curr ? { ...curr, review: { ...curr.review, ...tempCountryArr } } : null));
    };

    return (
        <Row
            style={{
                border: "1px solid lightgray",
                borderRadius: "8px",
                marginTop: "2rem",
            }}
            justify="center"
        >
            <Divider>Review</Divider>
            <Col span={18} offset={6}>
                <Form form={form} className="des-rev-form" labelCol={{ span: 4 }}>
                    <Form.Item label="Title" name={`review_title`}>
                        <Input onBlur={() => handleBlur("review_title")} />
                    </Form.Item>
                    <Form.Item label="Youtube Url" name={`youtube_url`}>
                        <Input onBlur={() => handleBlur("youtube_url")} />
                    </Form.Item>
                    <Form.Item label="YouTube Length" name={`youtube_length`}>
                        <Input style={{ width: "80px" }} onBlur={() => handleBlur("youtube_length")} />
                    </Form.Item>
                    <Form.Item label="Text">
                        <Button size="small" onClick={getReviewText} loading={getTextLoading}>
                            Get review text
                        </Button>
                    </Form.Item>
                    {youtube_url ? (
                        <YouTube videoId={youtube_url} opts={{ height: "240px", width: "100%" }} />
                    ) : (
                        <Row justify="center">No Url</Row>
                    )}
                </Form>
            </Col>
            {state.countries.map((c) => (
                <Col span={6} style={{ padding: "1rem 0.5rem 1rem 0.5rem" }} key={`${c.locale_code}`}>
                    <div
                        style={{
                            backgroundColor: "#F2F9F1",
                            border: "1px solid lightblue",
                            borderRadius: "8px",
                        }}
                    >
                        <div className="desc-rev-country-header">{c.name.toUpperCase()}</div>
                        <Col span={24} style={{ border: "1px solid whitesmoke", borderRadius: "10px" }}>
                            <Row align="middle" justify="center" className="desc-rev-card">
                                <h5 style={{ textAlign: "center", fontSize: "20px" }}>Review</h5>
                                <Col span={24}>
                                    <TextEditor
                                        country={c.name}
                                        type="review"
                                        expandRowData={expandRowData}
                                        setExpandRowData={setExpandRowData}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Col>
            ))}
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    style={{ margin: "0.5rem 1rem" }}
                    type="primary"
                    onClick={saveReviewText}
                    loading={reviewLoading}
                    icon={isSaved && <CheckCircleOutlined />}
                >
                    Save Review
                </Button>
                <Button
                    style={{ margin: "0.5rem 1rem" }}
                    danger
                    type="primary"
                    onClick={deleteReviewText}
                    loading={deleteReviewLoading}
                >
                    Delete All Reviews
                </Button>
            </Col>
        </Row>
    );
};
export default AllReviews;
