
import React, { forwardRef } from 'react'
import DatePicker  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/DatePickerReports.css'

interface DatePickerReportsProps {
    selectedDate:Date
    setSelectedDate:React.Dispatch<React.SetStateAction<Date>>
}

const DatePickerReports:React.FC<DatePickerReportsProps> = ({ selectedDate, setSelectedDate }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }:any, ref:any) => (
    <button className="date-picker-feed-helth" onClick={onClick} ref={ref}>
      {value}
    </button>
  ))
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date:Date) => setSelectedDate( date)}
      customInput={<ExampleCustomInput />}
      calendarClassName="date-picker-calendar-body"
      popperPlacement="left-start"
      dateFormat=" d MMM yyyy"
    />
  )
}

export default DatePickerReports
