import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import showMessage from "../../../../shared/MessagesInfo/message";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { CategoryType } from "../../pages/AllCategories";
import { EditCateogyType } from "../EditCategory";

const { Meta } = Card;

interface ImageEditProps {
    data: EditCateogyType | null;
    selectedCateogry: CategoryType | null;
    setSelectedCategories?: any;
    setSelectedEditCat: React.Dispatch<React.SetStateAction<CategoryType | null>>;
    newImg:
        | {
              data_url: string;
          }
        | null
        | any;
    setNewImg: React.Dispatch<
        React.SetStateAction<{
            data_url: string;
        } | null>
    >;
    imageEdit: any;
    setImageEdit: React.Dispatch<
        React.SetStateAction<
            {
                file: {
                    type: string;
                };
                id: number;
            }[]
        >
    >;
}

const ImageEdit: React.FC<ImageEditProps> = ({ data, selectedCateogry, setSelectedEditCat, newImg, setNewImg, imageEdit, setImageEdit, setSelectedCategories }) => {
    let id = "5";
    const [loading, setLoading] = useState(false);

    //const [images, setImages] = React.useState([]);
    const maxNumber = 69;

    const onChange = (imageList: any) => {
        const img = imageList[0];
        setImageEdit((curr) => [...curr, { ...img, id: id }]);
    };

    const removeImage = (id: string) => {
        setImageEdit((curr) => curr.filter((i: any) => i.id !== id));
    };

    const setNewImage = async () => {
        if (imageEdit) {
            const type = imageEdit[0].file.type.split("/");
            if (type[1] === "jpeg" || type[1] === "jpg" || type[1] === "png" || type[1] === "webp") {
                setLoading(true);
                const formData: any = new FormData();
                formData.append("id", selectedCateogry?.id);
                formData.append("level_id", Number(selectedCateogry?.type[1]));
                formData.append(
                    "parent_id",
                    Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof CategoryType]
                );
                //formData.append('id',Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry[`category_l${Number(selectedCateogry?.type[1])-1}_id`])
                formData.append("image", imageEdit[0].file);
                try {
                    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, formData);
                    showMessage(data.message);
                    setNewImg(imageEdit);
                    setImageEdit([]);
                    setLoading(false);
                    setSelectedEditCat((curr) => (curr ? { ...curr, default_image_url: data.data.default_image_url } : null));
                    setSelectedCategories &&
                        setSelectedCategories((curr: any) => ({
                            ...curr,
                            cat1: { ...curr.cat1, default_image_url: data.data.default_image_url },
                        }));
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
            } else {
                openNotification("`Allowed image extensions JPG, JPEG, WEBP and PNG`");
            }
        }
    };

    if (selectedCateogry?.type === "l1") {
        return <p>Add or change images is not allowed for level 1</p>;
    }

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Card
                hoverable
                style={{ width: 200, padding: "2rem 1rem", marginTop: "6rem" }}
                cover={
                    <img
                        alt="Product Img"
                        style={{ height: "160px", width: "160px" }}
                        src={
                            newImg
                                ? newImg[0]?.data_url
                                : `https://img.ep-cdn.com/i/${selectedCateogry?.type === "l2" ? "240" : "160"}/${selectedCateogry?.type === "l2" ? "100" : "160"}/${
                                      selectedCateogry?.default_image_url
                                  }${data?.image_slug}.webp`
                        }
                    />
                }
            >
                <Meta title="Exist image" style={{ marginLeft: "1.3rem" }} />
            </Card>

            <ImageUploading value={imageEdit} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    // write your building UI
                    <div
                        className="upload__image-wrapper"
                        style={{
                            width: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "1rem",
                            marginTop: "6rem",
                            backgroundColor: "whitesmoke",
                        }}
                    >
                        {imageEdit.length === 0 ? (
                            <div>
                                <p style={{ marginLeft: "1rem" }}>Set new Image</p>
                                <Button type="dashed" onClick={onImageUpload} {...dragProps}>
                                    Click or Drop <UploadOutlined />
                                </Button>
                            </div>
                        ) : (
                            <div key={id} className="image-item" style={{ display: "flex", alignItems: "center" }}>
                                <img src={imageEdit[0].data_url} alt="" width="150" />
                                <div className="image-item__btn-wrapper">
                                    <CloseCircleOutlined
                                        onClick={() => removeImage(id)}
                                        style={{
                                            fontSize: "11px",
                                            color: "rgb(174, 161, 163)",
                                            position: "relative",
                                            bottom: "3rem",
                                            right: "0.4rem",
                                        }}
                                    />
                                    <Button danger onClick={() => setNewImage()} style={{ width: "130px" }} loading={loading}>
                                        Set Image
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default ImageEdit;
