import axios from "axios";
import { Dispatch } from "react";
import showMessage from "../../shared/MessagesInfo/message";
import {
    ShopRulesAll,
    ShopRulesbyId,
    ShopRulesCreate,
    ShopRulesEdit,
    ShopRulesExist,
    SHOP_RULES_ALL,
    SHOP_RULES_BY_ID,
    SHOP_RULES_CREATE,
    SHOP_RULES_EDIT,
    SHOP_RULES_EXIST,
} from "./shopActionType";

//shop rules

export const getAllShopRules = () => async (dispatch: Dispatch<ShopRulesAll>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/maps/shop-rules/show-all`);
        dispatch({ type: SHOP_RULES_ALL, payload: data });
    } catch (err) {
        console.log(err);
    }
};

//single shop rules

export const showShopRulesById =
    (id: any, form: any, setBooleans: any, setLoading: any, /*setIsRulesMessage:any,*/ setFileType: any) =>
    async (dispatch: Dispatch<ShopRulesbyId>) => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/maps/shop-rules/show-shop-rules?shop_id=${id}`
            );
            setBooleans({
                parse_price: data.data.parse_price,
                price_required: data.data.price_required,
                trim: data.data.trim,
                relax: data.data.relax,
                skip_empty_lines: data.data.skip_empty_lines,
                skip_lines_with_error: data.data.skip_lines_with_error,
                has_multiple_images: data.data.has_multiple_images,
                has_attributes: data.data.has_attributes,
            });
            form.setFieldsValue(data.data);
            form.setFieldsValue({
                parse_price: String(data.data.parse_price),
                price_required: String(data.data.price_required),
                trim: String(data.data.trim),
                relax: String(data.data.relax),
                skip_empty_lines: String(data.data.skip_empty_lines),
                skip_lines_with_error: String(data.data.skip_lines_with_error),
                attribute_array: data.data.attribute_array ? JSON.parse(data.data.attribute_array) : [],
            });
            //setFileType(data.file_type)
            setFileType(data.data.file_type);
            setLoading(false);
            dispatch({ type: SHOP_RULES_BY_ID, payload: data });
        } catch (err) {
            console.log(err);
        }
    };

export const editShopRules =
    (
        objectUpdate: any,
        form: any,
        /*setShopId:any,*/
        setLoading: any,
        history: any,
        setCounter: React.Dispatch<React.SetStateAction<number>>,
        handleModalRulesOk: () => void
    ) =>
    async (dispatch: Dispatch<ShopRulesEdit>) => {
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_URL_API}/maps/shop-rules/update-shop-rules`,
                objectUpdate
            );
            showMessage(data.message);
            //form.resetFields()
            //setShopId('')
            //setLoading(false)
            //history.push( '/shop-list' )
            setCounter((curr) => curr + 1);
            handleModalRulesOk();
            dispatch({ type: SHOP_RULES_EDIT, payload: data });
        } catch (err) {
            console.log(err);
        }
    };

export const createShopRules =
    (
        objectToSend: any,
        form: any,
        /* setShopId:any,*/ setLoading: any,
        history: any,
        handleModalRulesOk: () => void,
        setCounter: React.Dispatch<React.SetStateAction<number>>
    ) =>
    async (dispatch: Dispatch<ShopRulesCreate>) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/maps/shop-rules/add-new-shop-rules`, {
                ...objectToSend,
                price_key_name: "price",
            });
            showMessage(data.message);
            form.resetFields();
            //setShopId('')
            setLoading(false);
            setCounter((curr) => curr + 1);
            handleModalRulesOk();
            dispatch({ type: SHOP_RULES_CREATE, payload: data });
            //history.push( '/shop-list' )
        } catch (err) {
            console.log(err);
        }
    };

//----------shop rules exist---------

export const shopRuleExist = (id: any) => async (dispatch: Dispatch<ShopRulesExist>) => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_URL_API}/maps/shop-rules/check-if-rules-exist?shop_id=${id}`
        );
        //setExist(data.exists)
        dispatch({ type: SHOP_RULES_EXIST, payload: data });
    } catch (err) {
        console.log(err);
    }
    //setLoading(false)
};
