import { LinkOutlined } from "@ant-design/icons";
import { Divider, Skeleton, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart
} from "recharts";
import { useSearchState } from "../../../shared/hooks/useSearchState";

import '../style/FeedHealthToday.css'
import DatePickerReports from "./DatePickerReports";
type FeedColumnType = {
  id:number 
  error:number
  success:number
}
function capitalizeFirstLetter(string:string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface UPProps {
  currentCountryId:number
  sfActive:number
}

const  FeedHelthToday:React.FC<UPProps> = ({ currentCountryId, sfActive }) => {
  const [ healthFeedToday, setHealthFeedToday ] = useState([])
  const [ selectedDate, setSelectedDate ] = useState(new Date())
  const { getColumnSearchProps } = useSearchState(healthFeedToday)

  useEffect(()=>{
      const getUncategorizedProducts = async () => {
        try {
          const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/logger/feed-health-today?date=${  moment(selectedDate).format("YYYY-MM-DD") }&country_id=${ currentCountryId }&active=${ sfActive }`)
          const newData = data.data.sort((a:{error:number},b:{error:number}) => b.error - a.error ).map((item:any)=>{
            const newName = capitalizeFirstLetter(item.name.toLowerCase())
            return {
              ...item,
              name:newName
            }
          })
          setHealthFeedToday(newData)
        } catch ( err ) {
          console.log(err)
        }
      }
      getUncategorizedProducts()
     },[ selectedDate, currentCountryId, sfActive ])

     const tableColumns:any = [
   
      {
        title:'Shop',
        dataIndex:'name',
        key:'name',
        width:'60%',
        className:"first-column",
        ...getColumnSearchProps('name')
      },
      {
        title:'Error',
        dataIndex:'error',
        width:'15%',
        align: 'center' as 'center',
        key:'error',
        sorter: (a:FeedColumnType, b:FeedColumnType) => a.error - b.error,
      },
      {
        title:'Success',
        dataIndex:'success',
        align: 'center' as 'center',
        width:'15%',
        key:'success',
        sorter: (a:FeedColumnType, b:FeedColumnType) => a.success - b.success,
      },
      {
        title:'Feed',
        dataIndex:'feed_url',
        width:'15%',
        align: 'center' as 'center',
        key:'feed_url',
        render:(record:string) =>   <a href={record} onClick={()=> window.open(record, "", "width=900,height=1080")} className="shop-link" style={{color:'#01b0f5', textDecoration:'underline'}} target="_blank" rel="noreferrer"><LinkOutlined /></a>
      }
    ]
  return (
    <div style={{ width:'90%' }}>
      <div style={{width:'80%', margin:'0 auto'}}>
        <Divider > Feed health </Divider>
      </div>
      <div style={{ display:'flex', justifyContent:'space-around'}}>
        
      {healthFeedToday.length > 0 ?
        <ComposedChart
            layout="vertical"
            width={480}
            height={680}
            data={healthFeedToday.slice(0,10)}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" width={150} scale="band" />
            <Tooltip />
            {/*<Legend />*/}
            <Bar 
              dataKey="error" 
              barSize={14} 
              fill="#da5151" 
            />
            <Bar 
              dataKey="success" 
              barSize={14} 
              fill="#413ea0" 
            />
          </ComposedChart>:<div>

        <Skeleton.Button active /*size='large'*/ style={{ width:'320px', height:'680px', margin:'20px 30px 20px 20px' ,position:"relative", bottom:'1.2rem', left:"20px"}}  block />
    </div>}
      <div>
        {healthFeedToday.length > 0 && <DatePickerReports selectedDate={ selectedDate } setSelectedDate={ setSelectedDate } />}
        {healthFeedToday.length > 0 ?
        <Table
            bordered
            dataSource={ healthFeedToday }
            rowKey={ ( record:{id:number} ) => record &&  record.id }
            columns={tableColumns}
            className="feed-health-today-table"
            pagination={{ size:'small', showSizeChanger:false, defaultPageSize: 20}}
        />:
        <div style={{ width:'280px' }}>
          <Skeleton active paragraph={{rows:16}} title={{width:'100%'}} className="new-shop-products-table" style={{ border:'none'}} />
        </div>}
       </div>
      </div>
      {healthFeedToday.length > 0 && 
      <div style={{ display:'flex',width:'100%', marginLeft:'6rem'}}>
        <div style={{ display:'flex' , flexDirection:'row'}}><div className="square-label" style={{ backgroundColor:'#413ea0', position:'relative', top:'3px' }}></div><span>Success</span></div>
        <div style={{ display:'flex' , flexDirection:'row'}}><div className="square-label" style={{ backgroundColor:'#ff5959', position:'relative', top:'3px', marginLeft:'8px' }}></div><span>Error</span></div>
      </div>}
    </div>
  );
}

export default FeedHelthToday
