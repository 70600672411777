import { Checkbox, Col, Divider, Empty, Layout, Row, Table, TreeSelect } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import FadeIn from 'react-fade-in';
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../../Store'
import { getAllCategoryL3 } from '../../../actions/category/categoryActions'
import axios from 'axios'
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'
import '../style/AttributeKeyMapping.css'
const { TreeNode } = TreeSelect

type OurAttributesType = {
    category_l3_id: number
    category_name: string
    id: number
    max_value: string
    min_value: string
    name_rs: string
    primary: boolean
    show_filter: number
    show_specification: number
    type: string
}

const AttributeKeyMapping = () => {

    const state = useSelector(( state:RootStore ) => state)
    ////////////////////////////////////
    const currentCountryName =  state.dataLs.country ? state.dataLs.country.currentCountry:"rs"
    const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1
    const [ categories, setCategories ] = useState<any>([])
    const [ selectedCategory, setSelectedCateogry ] = useState({id:null, name:''})
    const [ ourAttributes, setOurAttributes ] = useState<OurAttributesType[]>([])
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllCategoryL3(currentCountryId))
      }, [ dispatch, currentCountryId])

    useEffect(() => {
        setCategories(state.categoriesL3.data && state.categoriesL3.data.sort((a:any,b:any) => (a[`name_${currentCountryName}`] > b[`name_${currentCountryName}`]) ? 1 : ((b[`name_${currentCountryName}`] > a[`name_${currentCountryName}`]) ? -1 : 0)).sort((a:any,b:any) => (a[`name_${currentCountryName}`] > b[`name_${currentCountryName}`]) ? 1 : ((b[`name_${currentCountryName}`] > a[`name_${currentCountryName}`]) ? -1 : 0)) )
    }, [ state.categoriesL3.data, currentCountryName ])
    const  onCategoryChange = (value:any) => {
        ////////////////   choosen item on first place
        let oneCategory:any;
        const findCategoryL3 = categories.find((category3:any) => category3.id === value)
        setSelectedCateogry({ id:findCategoryL3.id, name:findCategoryL3[`name_${ currentCountryName }`]})
        if(findCategoryL3) {

            oneCategory = findCategoryL3
        }
        else {
      
        categories.forEach((category3:any) => {
            category3.children && category3.children.forEach((category4:any) => {
            if(category4.id === value) {
                oneCategory = category3
            }
            })
        })
        }
        const filteredCategories = categories.filter((category:any) => category.id !== oneCategory.id)
        setCategories([oneCategory, ...filteredCategories])

    }

    useEffect(() => {
        const getOurAttributesData = async () => {
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/prices/attributes/list-similar-price-attributes-by-l3?l3=${ selectedCategory.id }`)
                setOurAttributes(data.data)
            } catch ( err ) {
                console.log(err)
            }
        }
        const getOurAttributesShopData = async () => {
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/prices/attributes/list-similar-price-attributes-by-l3?l3=${ selectedCategory.id }`)
                console.log(data.data)
            } catch ( err ) {
                console.log(err)
            }
        } 
        selectedCategory.id && getOurAttributesData()
        selectedCategory.id  && getOurAttributesShopData()
    }, [ selectedCategory.id ])

    const [ shopAttributes, setShopAttributes ] = useState([
        {id:1,shop:'Dijaspora', showMore:false},
        {id:2,shop:'Shoppster', showMore:false},
        {id:3,shop:'BC Group', showMore:false},
    ])
    const showMore = (id:number) => {
        setShopAttributes( curr => curr.map( data => data.id ===id ? {...data, showMore:!data.showMore}:data ))
    }
    //console.log(ourAttributes)

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Attribute Key Mapping" />  
            <Row justify='center'>
                <Col span={23} className="user-assignments-header" >
                    <Divider>Attribute Key Mapping</Divider>
                    <div className='product-img-info-bar' style={{ display:'flex', justifyContent:'center'}}>
                        <TreeSelect
                            showSearch
                            treeDataSimpleMode
                            
                            placeholder="Select category"
                            style={{
                                width: '300px',
                                marginBottom:6
                            }}
                            onChange={onCategoryChange}
                            filterTreeNode={(search:any, item:any) => {
                                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                            }}
                        >
                            {
                            categories && categories.map((category:any) => (
                                category.children.length === 0  ?
                                <TreeNode key={category.id} value={ category.id } title={`${category.id}: ${category[`name_${ currentCountryName }`]} `}/>:
                                <TreeNode key={category.id} value={ category.id } title={`${category.id}: ${category[`name_${ currentCountryName }`]} `}>
                                {
                                category.children.map((itemChild:any) => (
                                <TreeNode key={itemChild.id} value={itemChild.id} title={`${itemChild.id}: ${itemChild[`name_${ currentCountryName }`]} `}/>
                                ))
                                }
                            </TreeNode>
                            )
                        )
                    }
                        </TreeSelect>
                    </div>
                </Col>
                
                {ourAttributes.length > 0 ? <React.Fragment><Col span={23} style={{ backgroundColor:'white', minHeight:'75vh' }}>
                <Layout style={{  backgroundColor:'white' }} >
                    <Row>
                        {/* <Col span={6} style={{ border:'1px solid #d9dad7', padding:'1rem', margin:'0 1rem', backgroundColor:'#fcfefe' }} >
                        <Header className='product-img-header user-list-assignments-header' style={{ backgroundColor:'#5585b5' }}> 
                            <span className='user-list-assignments-title' >
                            {`Our Attributes`} <i style={{ fontSize:'16px' }}></i>
                            </span> 
                        </Header>
                        
                        <ul className='user-sssignments-user-list'>
                            <FadeIn delay={50} transitionDuration={100} > 
                                {
                                    ourAttributes.map( data => (
                                        <div key={data.id} style={{ border:'1px solid gray',width:'380px', padding:'1rem 2.8rem', marginTop:'0.3rem', borderRadius:'4px' }}>
                                            <p style={{  }}>{ data.name_rs }</p>
                                            <div style={{ display:'flex', justifyContent:'space-between' }}>
                                                <Checkbox ><span style={{ color:'#005792'}}>Filter</span></Checkbox>
                                                <Checkbox ><span style={{ color:'#005792'}}>Specification</span></Checkbox>
                                            </div>
                                        </div>
                                    ))
                                }
                            </FadeIn>
                        </ul>
                        </Col> */}
                        <Col span={17} style={{ border:'1px solid #d9dad7', padding:'1rem', margin:'0 1rem', backgroundColor:'#fcfefe' }} >
                        <Header className='product-img-header' style={{ margin:'0 0.8rem 0 0' }} > 
                            <span className='user-list-assignments-title' >
                                {`Shops with attributes for cateogry ${ selectedCategory.name } `} <i style={{ fontSize:'16px' }}></i>
                            </span> 
                        </Header>
                       

                       
                        <ul  style={{ width:'100%', marginTop:'1rem'}}>
                            <FadeIn delay={50} transitionDuration={100}> 
                                {
                                    shopAttributes.map( data => (
                                        <div 
                                           style={{
                                            border:'1px solid gray', 
                                            padding:'1rem 2.5rem', 
                                            marginTop:'0.3rem', 
                                            borderRadius:'4px', 
                                            margin:'0 13rem 6px 13rem'
                                           }}
                                           key={data.id}
                                        >
                                            <div  
                                                style={{ 
                                                    display:'flex',
                                                    justifyContent:'space-between'
                                                }}
                                            >

                                            <p>{ data.shop} ID: { selectedCategory.id }</p>
                                                <div style={{ display:'flex', flexDirection:'column' }}>
                                                    <span style={{ textAlign:'end' }}>Count attributes: 595</span>
                                                    <span style={{ textAlign:'end' }}>Different Attributes: 11</span>
                                                </div>
                                            </div>
                                            { data.showMore ? 
                                                <UpSquareOutlined style={{ marginLeft:'97%', fontSize:'22px',color:'gray' }} onClick={() => showMore(data.id)} />: 
                                                <DownSquareOutlined style={{ marginLeft:'97%', fontSize:'22px',color:'darkgreen' }} onClick={() => showMore(data.id)} />}
                                                <Table
                                                    style={{ display:'none' }}
                                                    className={ data.showMore ? `shop-attribute-sim-table`:''}
                                                    rowKey={(record) => record.feed_key}
                                                    columns={[
                                                        {title:'Feed key', dataIndex:'feed_key'},
                                                        {title:'Our Attribute', dataIndex:'ourAttribute'},
                                                        {title:'Similarity', dataIndex:'similarity'},
                                                        {title:'', dataIndex:'feed_key', render:(record:any) =><Checkbox />},
                                                    ]}
                                                    dataSource={[
                                                        {feed_key:'Nivo buke', ourAttribute:'Jačina buke ', similarity:50},
                                                        {feed_key:'Težina', ourAttribute:'Težina', similarity:100},
                                                        {feed_key:'Snaga', ourAttribute:'Usisna snaga', similarity:60}

                                                    ]} pagination={false} 
                                                />
                                        </div>
                                    ))
                                }
                            </FadeIn>
                        </ul>
                    
                    </Col>
                </Row>
                </Layout>
            </Col>  </React.Fragment>:<Col style={{ height:'75vh',width:'95.9vw', backgroundColor:'white'}} ><div className='matching-seller-category-empty'><Empty description="No data, select Category"  /> </div> </Col>}
        </Row>    
    </React.Fragment>
    )
}

export default AttributeKeyMapping