import React, { useCallback, useEffect, useState } from "react";
import { Table, Row, Col, Divider, Layout, Statistic, Card, Select, Image, Badge, Radio, Input, Spin, TreeSelect } from "antd";
import "../style/AutoMatched.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { ColumnType } from "antd/es/table";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import ExpandedRowContent from "../components/DescriptionReview/ExpandedRowContent";
import type { RadioChangeEvent } from "antd";
import axios from "axios";
import "../style/DescriptionReview.css";
import { capitalizeFirstLetter } from "../../attributes/helpers/checkIsIncludes";
import moment from "moment";
import Header from "../../../shared/UIElements/Header";
import { BaseOptionType } from "antd/es/select";
import numberWithCommas from "../../../shared/utils/numberUtils";

export type DescriptionReviewType = {
    id: number;
    image: string;
    name: string;
    category: string;
    link: string;
    description_flag: StatusFleg;
    review_flag: StatusFleg;
    update: string;
    key: string;
    brand_name: string;
    mpn: string;
    [key: string]: string | number;
};

type CategoryTreeType = {
    children: { count: number; id: number; [key: string]: string | number; parent_id: number }[];
    count: number;
    id: number;
    [key: string]:
        | string
        | number
        | {
              [key: string]: string | number;
              count: number;
              id: number;
              parent_id: number;
          }[];
};

/* Flag - not changed, manual, al */

export enum FlagStatus { // new status only change here and add in show status, on other palce will be dynamically
    NOT_CHANGED,
    MANUAL,
    AI,
}

const tempFlags: number[] = [];

Object.keys(FlagStatus).forEach((flag) => {
    if (!isNaN(Number(flag))) {
        tempFlags.push(Number(flag));
    }
});

export type StatusFleg = (typeof tempFlags)[number];

/* Type - description and review */

enum TypeStatus {
    ALL,
    DESCRIPTION,
    REVIEW,
}

const tempTypes: number[] = [];

Object.keys(TypeStatus).forEach((type) => {
    if (!isNaN(Number(type))) {
        tempTypes.push(Number(type));
    }
});

enum ReviewStatus {
    NOT_FILLED,
    FILLED,
}

export type ExpandRowDataType = {
    preview: { [key: string]: string | number | null };
    review: { [key: string]: string | number | null };
};

export const showStatus = (status: StatusFleg) => {
    switch (status) {
        case FlagStatus.NOT_CHANGED:
            return "error";
        case FlagStatus.AI:
            return "warning";
        case FlagStatus.MANUAL:
            return "success";
    }
};

enum AllDescAndRev {
    NOT_COMPLETED,
    COMPLETED,
}

let initCategories: CategoryTreeType[] = [];

let categoryWithZero: { category: CategoryTreeType; level: number }[] = [];

const DescriptionReview = () => {
    const [descRevData, setDescRevData] = useState<DescriptionReviewType[]>([]);
    const [count, setCount] = useState(0);
    const [countLoading, setCountLoading] = useState(false);
    const [categories, setCategories] = useState<CategoryTreeType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [createdBy, setCreatedBy] = useState<number | null>(null); // flag
    const [type, setType] = useState<number | null>(TypeStatus.ALL); // type
    const [product_id, setProduct_id] = useState<number | null>(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingExpand, setLoadingExpand] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [expandRowData, setExpandRowData] = useState<ExpandRowDataType | null>(null);
    //const dispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);
    // const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;
    const currentCountryName = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";

    const getDataAndCount = useCallback(() => {
        setLoading(true);
        const baseUrl = process.env.REACT_APP_URL_API;
        const lang = `?lang=${currentCountryName}`;
        const currnetPage = `&page=${page}`;
        const category_l3_id = selectedCategory ? `&category_l3_id=${selectedCategory}` : "";
        const all = type === TypeStatus.ALL && createdBy !== null ? `&complete=${createdBy}` : "";
        const description = type === TypeStatus.DESCRIPTION && createdBy !== null ? `&flag=${createdBy}` : "";
        const review = type === TypeStatus.REVIEW && createdBy !== null ? `&review=${createdBy}` : "";
        const product = product_id ? `&product_id=${product_id}` : "";
        const getData = async () => {
            try {
                const url = `${baseUrl}/product/item/get-top-products${lang}${currnetPage}${category_l3_id}${all}${description}${review}${product}`;
                const { data } = await axios.get(url);
                setDescRevData(data.data.map((d: DescriptionReviewType) => ({ ...d, key: d.id.toString() })));
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        getData();

        const getCount = async () => {
            setCountLoading(true);
            try {
                const url = `${baseUrl}/product/item/get-count-top-products${lang}${currnetPage}${category_l3_id}${all}${description}${review}${product}`;
                const { data } = await axios.get(url);
                setCount(data.data);
                setCountLoading(false);
            } catch (err) {
                console.log(err);
            }
        };

        getCount();
    }, [currentCountryName, page, selectedCategory, createdBy, type, product_id]);

    useEffect(() => {
        getDataAndCount();
    }, [getDataAndCount]);

    const getCategories = useCallback(async () => {
        setCategoryLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const url = `${baseUrl}/product/category/list-l3-description-review?lang=${currentCountryName}${createdBy && type === TypeStatus.DESCRIPTION ? `&flag=${createdBy}` : ""}${
                type === TypeStatus.REVIEW && createdBy ? `&review=${createdBy}` : ""
            }${type === TypeStatus.ALL && createdBy ? `&complete=${createdBy}` : ""}`;
            const { data } = await axios.get(url);

            setCategories((curr) => {
                const allCategories: CategoryTreeType[] = data.data;
                const l4Categories = allCategories.flatMap((obj) => obj.children);

                initCategories.forEach((l3category) => {
                    // Selected L3
                    if (l3category.id === selectedCategory) {
                        const checkCategory = allCategories.find((l3: CategoryTreeType) => l3.id === selectedCategory);
                        const findCategoryInData = curr.find((l3: CategoryTreeType) => l3.id === selectedCategory);
                        if (!checkCategory && findCategoryInData) {
                            categoryWithZero.push({
                                category: {
                                    ...l3category,
                                    count: 0,
                                    children: l3category.children.map((l4) => ({ ...l4, count: 0 })),
                                },
                                level: 3,
                            });
                        }
                    }

                    // Selected L4
                    l3category.children.forEach((l4category) => {
                        let tempL3: CategoryTreeType | undefined;
                        if (l4category.id === selectedCategory) {
                            const findCountInL3 = allCategories.find((l3) => l3.id === l3category.id);
                            tempL3 = findCountInL3;
                            const checkCategory = l4Categories.find((l4) => l4.id === selectedCategory);

                            const findCategoryInData = curr.flatMap((obj) => obj.children).find((l4) => l4.id === selectedCategory);
                            if (!checkCategory && findCategoryInData) {
                                categoryWithZero.push({
                                    category: {
                                        ...l3category,
                                        count: tempL3 ? tempL3.count : 0,
                                        children: [...(tempL3 ? [...tempL3?.children] : []), { ...l4category, count: 0 }],
                                    },
                                    level: 4,
                                });
                            }
                        }
                    });
                });

                if (categoryWithZero && categoryWithZero.length > 0) {
                    initCategories = [...allCategories, categoryWithZero[0].category];
                    return [...allCategories.filter((c3) => c3.id !== categoryWithZero[0].category.id), categoryWithZero[0].category];
                } else {
                    initCategories = allCategories;
                    return allCategories;
                }
            });

            categoryWithZero = [];

            setCategoryLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [currentCountryName, createdBy, type, selectedCategory]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    const columns: ColumnType<DescriptionReviewType>[] = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: "100px",
        },
        {
            title: "Image",
            dataIndex: "image",
            width: "100px",
            render: (text) => <Image width={60} src={`https://img.ep-cdn.com/i/500/500/${text}.webp`} alt="product" />,
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "40%",
        },
        {
            title: "Cateogry",
            dataIndex: "category",
            key: "",
            width: "15%",
        },
        {
            title: "Link",
            dataIndex: "link",
            width: "150px",
            render: (_, record) =>
                state.countries.map((c) => {
                    const link = record[`url_${c.name}`];
                    return typeof link === "string" ? (
                        <a
                            key={c.name}
                            href={link}
                            onClick={() => window.open(typeof link === "string" ? link : "", "", "width=900, height=1080, top=0, left=960")}
                            className="shop-link"
                            style={{
                                marginLeft: "0.5rem",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            target="_blank"
                            rel={"noreferrer"}
                        >
                            {c.name.toUpperCase()}
                        </a>
                    ) : (
                        ""
                    );
                }),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "",
            width: "300px",
            render: (_, record) => (
                <Row>
                    {Object.keys(TypeStatus).map(
                        (status) =>
                            !isNaN(Number(status)) &&
                            Number(status) > 0 && (
                                <Col span={24} key={status}>
                                    <Badge status={showStatus(Number(record[`${TypeStatus[status as keyof typeof TypeStatus].toString().toLowerCase()}_flag`]))} style={{ marginRight: "0.5rem" }} />
                                    {capitalizeFirstLetter(TypeStatus[status as keyof typeof TypeStatus].toString())}
                                </Col>
                            )
                    )}
                </Row>
            ),
        },
        {
            title: "Update at",
            dataIndex: "updated",
            key: "",
            render: (record: string) => (record ? `${moment(record).format("DD MMM YYYY")}, ${moment(record).format("HH:mm")}` : "-"),
        },
    ];

    const openExpandContent = async (expandedKeys: boolean, record: DescriptionReviewType) => {
        setLoadingExpand(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const url = `${baseUrl}/product/item/get-description-review?product_id=${record.id}`;
            const { data } = await axios.get(url);

            setExpandRowData({ preview: data.data.description, review: data.data.review });
            setLoadingExpand(false);
        } catch (err) {
            console.log(err);
        }
        setExpandedRowKeys(expandedKeys ? [record.key] : []);
    };

    const onCategoryChange = (value: number) => {
        // console.log(value);
        setPage(1);
        setSelectedCategory(value);
    };

    const onCreatedByChange = (e: number) => {
        setPage(1);
        setExpandedRowKeys([]);
        setCreatedBy(e ? e : null);
    };

    const onChangeType = (e: RadioChangeEvent) => {
        setPage(1);
        setExpandedRowKeys([]);
        setCreatedBy(null);
        setType(e.target.value);
    };

    const onPageChange = (e: number) => {
        setPage(e);
    };

    let delayTimer: ReturnType<typeof setTimeout>;

    const searchCateogryById = (id: string | null) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setPage(1);
            setProduct_id(isNaN(Number(id)) || Number(id) === 0 ? null : Number(id));
        }, 1500);
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Product - Descriptions and Reviews" />
            <Col span={24} className="automatched-prices-box">
                <Divider>Product - Descriptions and Reviews</Divider>
                <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0.5rem" }}>
                    <Row justify="start" className="desc-rev-container">
                        <Col span={12}>
                            <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                dropdownStyle={{ overflow: "auto" }}
                                placeholder="Select Category"
                                allowClear
                                onChange={onCategoryChange}
                                value={selectedCategory}
                                treeDefaultExpandAll={false}
                                filterTreeNode={(search, item: BaseOptionType) => item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                                loading={categoryLoading}
                                treeData={categories
                                    .sort((a: CategoryTreeType, b: CategoryTreeType) => b.count - a.count)
                                    .map((l3: CategoryTreeType) => ({
                                        value: l3.id,
                                        title: `${l3[`name_${currentCountryName}` as keyof typeof l3]} (${numberWithCommas(String(l3.count).replace(".", ","))})`,
                                        children: l3.children
                                            .sort((a, b) => b.count - a.count)
                                            .map((l4) => ({
                                                value: l4.id,
                                                title: `${l4[`name_${currentCountryName}` as keyof typeof l4]} (${numberWithCommas(String(l4.count).replace(".", ","))})`,
                                                parent_id: l4.parent_id,
                                            })),
                                    }))}
                            />
                        </Col>

                        <Col span={12}>
                            <Select // STATUS FILTER
                                placeholder={`Status By ${type === TypeStatus.ALL || !type ? "All" : type === TypeStatus.DESCRIPTION ? "Description Status" : "Review Status"} `}
                                style={{ width: "300px", textTransform: "capitalize", marginLeft: "0.5rem" }}
                                onChange={onCreatedByChange}
                                value={createdBy}
                                allowClear
                            >
                                {type === TypeStatus.ALL // ALL
                                    ? Object.keys(AllDescAndRev).map(
                                          (descRev) =>
                                              !isNaN(Number(descRev)) && (
                                                  <Select.Option key={Number(descRev)}>
                                                      {capitalizeFirstLetter(AllDescAndRev[descRev as keyof typeof AllDescAndRev].toString().replace("_", " "))}
                                                  </Select.Option>
                                              )
                                      )
                                    : type === TypeStatus.DESCRIPTION //DESCRIPTION
                                    ? Object.keys(FlagStatus).map(
                                          (flag) =>
                                              !isNaN(Number(flag)) && (
                                                  <Select.Option key={Number(flag)}>{capitalizeFirstLetter(FlagStatus[flag as keyof typeof FlagStatus].toString().replace("_", " "))}</Select.Option>
                                              )
                                      )
                                    : Object.keys(ReviewStatus).map(
                                          //REVIEW
                                          (review) =>
                                              !isNaN(Number(review)) && (
                                                  <Select.Option key={Number(review)}>
                                                      {capitalizeFirstLetter(ReviewStatus[review as keyof typeof ReviewStatus].toString().replace("_", " "))}
                                                  </Select.Option>
                                              )
                                      )}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Radio.Group // RAIDO BUTTONS
                                name="radiogroup"
                                defaultValue={0}
                                style={{ marginLeft: "0.7rem", marginTop: "0.3rem" }}
                                onChange={onChangeType}
                            >
                                {Object.keys(TypeStatus).map(
                                    (status) =>
                                        !isNaN(Number(status)) && (
                                            <Radio value={Number(status)} key={Number(status)}>
                                                {capitalizeFirstLetter(TypeStatus[status as keyof typeof TypeStatus].toString())}
                                            </Radio>
                                        )
                                )}
                            </Radio.Group>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row align="middle" justify="end">
                        <div className="description-status-info">
                            <h3>{capitalizeFirstLetter(TypeStatus[TypeStatus.DESCRIPTION])}</h3>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {Object.keys(FlagStatus).map(
                                    (flag) =>
                                        !isNaN(Number(flag)) && (
                                            <Badge
                                                key={flag}
                                                status={showStatus(Number(flag))}
                                                text={capitalizeFirstLetter(FlagStatus[flag as keyof typeof FlagStatus].toString().replace("_", " "))}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                        <div className="description-status-info">
                            <h3>{capitalizeFirstLetter(TypeStatus[TypeStatus.REVIEW])}</h3>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {Object.keys(ReviewStatus).map(
                                    (review) =>
                                        !isNaN(Number(review)) && (
                                            <Badge
                                                key={review}
                                                status={showStatus(Number(review))}
                                                text={capitalizeFirstLetter(ReviewStatus[review as keyof typeof ReviewStatus].toString().replace("_", " "))}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    </Row>
                    <Card className="desc-rev-count-box">
                        <Statistic title="Total" value={count} valueStyle={{ color: "green" }} loading={countLoading} />
                    </Card>
                </div>
                <Layout style={{ margin: "0.6rem 0rem" }}>
                    <Header>
                        <Input placeholder="Search by ID" size="small" className="desc-rev-search-id-input" onChange={(e) => searchCateogryById(e.target.value.trim())} allowClear />
                    </Header>
                    {loading && (
                        <Spin tip="Loading" size="small" style={{ marginTop: "1.8rem" }}>
                            <div className="content" />
                        </Spin>
                    )}
                    <Table
                        columns={columns}
                        dataSource={descRevData}
                        rowKey={(record) => record.key}
                        pagination={{
                            current: page,
                            pageSize: 100,
                            showSizeChanger: false,
                            onChange: onPageChange,
                            total: count,
                            position: ["topRight"],
                            size: "small",
                        }}
                        expandable={{
                            expandedRowRender: (record: DescriptionReviewType) => (
                                <ExpandedRowContent
                                    record={record}
                                    loading={loadingExpand}
                                    state={state}
                                    expandRowData={expandRowData}
                                    setExpandRowData={setExpandRowData}
                                    getCategories={getCategories}
                                    setDescRevData={setDescRevData}
                                    currentCountryName={currentCountryName}
                                />
                            ),
                            expandedRowKeys: expandedRowKeys,
                            onExpand(expandedKeys, record) {
                                openExpandContent(expandedKeys, record);
                            },
                            columnTitle: "Show",
                        }}
                    />
                </Layout>
            </Col>
        </Row>
    );
};

export default DescriptionReview;
