import React from 'react'
import {Helmet} from "react-helmet";

interface HelmetProps {
  title:string
}

const HelmetTitle:React.FC<HelmetProps> = ({ title }) => {
  return (
    <Helmet>
        <title>{ title }</title>
    </Helmet>
  )
}

export default HelmetTitle