import { UploadOutlined } from '@ant-design/icons'
import { Divider, Form, Modal, Popconfirm, Upload, UploadFile, UploadProps } from 'antd'
import { DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    //verticalListSortingStrategy,
    horizontalListSortingStrategy
  } from '@dnd-kit/sortable';
  import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import React from 'react';

interface DraggableUploadListItemProps {
    originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    file: UploadFile<any>;

  }
  
  const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: file.uid,
    });
  
    const style: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'move',
    };
  
    // prevent preview event when drag end
    const className = isDragging
      ? css`
          a {
            pointer-events: none;
          }
        `
      : '';
  
    return (
      <div ref={setNodeRef} style={style} className={className} {...attributes} {...listeners}>
        {/* hide error tooltip when dragging */}
        {file.status === 'error' && isDragging ? originNode.props.children : originNode}
      </div>
    )
  }

type FormUpoladType = {
  fileList: UploadFile<any>[] 
  onChange:((this: Window, ev: Event) => any) | null
  handlePreview: (file: UploadFile) => Promise<void>
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
  previewOpen: boolean
  previewTitle: string
  handleCancel: () => void
  previewImage: string
  type: string
  isEditChangeImages: boolean
  setIsEditChangeImages: React.Dispatch<React.SetStateAction<boolean>>
}

const FormUpolad = ( props:FormUpoladType ) => {
  const sensor = useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
  });
  
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      props.setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    //openNotification()
    props.setFileList(newFileList);
  };

 
  return (
    <React.Fragment>
      {/* {contextHolder} */}
      <Form.Item  
        name="pictures_files"
        style={{ paddingLeft:'1rem' }}
      >
        { props.type === 'edit' && !props.isEditChangeImages ?
        <Popconfirm
          title="Stored images will be replaced with new uploaded images!"
          //description="Are you sure to delete this task?"
          onConfirm={() => props.setIsEditChangeImages(true)}
          onCancel={undefined}
          okText="Add New Images"
          cancelText="Cancel"
        >
          <div 
            style={{ 
              margin:' 0 10px 10px 0', 
              width:'100px', 
              height:'100px', 
              borderRadius:'5px', 
              overflow:'hidden', 
              border:'1px dashed lightgray',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              cursor:'pointer'
            }}
          >
            <span style={{ textAlign:'center' }}>Upload new Images <UploadOutlined style={{ marginLeft:'0.5rem' }} /></span>
          </div>
        </Popconfirm>:
        <div>
          <Divider>Upload Images</Divider>
          <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext items={props.fileList.map((i) => i.uid)} strategy={horizontalListSortingStrategy}>
              <Upload
                action={undefined}
                listType="picture-card"
                fileList={props.fileList}
                onPreview={props.handlePreview}
                onChange={onChange}
                beforeUpload={() => false}
                multiple
                itemRender={(originNode, file) => (
                  <DraggableUploadListItem 
                    originNode={originNode} 
                    file={file} 
                  />
                )}
              >
                Upload <UploadOutlined style={{ marginLeft:'0.5rem' }} />
              </Upload>
            </SortableContext>
          </DndContext> 
        </div>}
      </Form.Item>
      <Modal open={props.previewOpen} title={props.previewTitle} footer={null} onCancel={props.handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={props.previewImage} />
      </Modal>
    </React.Fragment>
  )
}

export default FormUpolad