import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { AttributeListType, EanType, HistoryType, PicturesType, PriceType } from "../../types/type";
import { getAllCategoryL3 } from "../../../../actions/category/categoryActions";
import { useDispatch } from "react-redux";
import { CateogoryL3L4 } from "../../../../shared/FormElements/CategorySelect";
import { RootStore } from "../../../../Store";

export const UseGetProduct = (
    currentCountryId: number,
    setProductId: React.Dispatch<React.SetStateAction<number | undefined>>
    //setProductInfo: React.Dispatch<React.SetStateAction<{ name: string; value: string | PicturesType[] }[]>>
) => {
    const [productInfo, setProductInfo] = useState<{ name: string; value: string | PicturesType[] }[]>([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);

    const getProduct = useCallback(
        async (id: string, isChangeCountry?: "changeCountry") => {
            isChangeCountry && setLoadingInfo(true);
            // setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/view?id=${id}&parameters[]=images&country_id=${currentCountryId}`);
                const arr: any = Object.entries(data.data).map(([name, value]: any) => {
                    if (name === "category_name") {
                        return { name: "category", value: value };
                    } else if (name === "brand_name") {
                        return { name: "brand", value: value };
                    } else {
                        return { name, value };
                    }
                });

                setProductInfo([...arr, { name: "default_image_url", value: data.data.pictures.find((img: any) => img.primaryimage)?.name }]);
                setProductId(data.data.id);
                setLoadingInfo(false);
                setLoadingSearch(false);
            } catch (err) {
                console.log(err);
                // setProductId(undefined);
                setProductInfo([]);
                setLoadingSearch(false);
            }
        },
        [currentCountryId, /*currentCountryName ,infoKeys, setProductId, setProductInfo*/ setProductId]
    );

    return { getProduct, productInfo, setProductInfo, loadingSearch, setLoadingSearch, loadingInfo };
};

export const UseGetEans = (productId: number | undefined) => {
    const [eans, setEans] = useState<EanType[]>([]);
    const [loadingDeleteEan, setLoadingDeleteEan] = useState<number | undefined>();
    const getEans = useCallback(async () => {
        // setLoadingDeleteEan(productId)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/get-product-eans?product_id=${productId}`);

            setEans(data.data);
        } catch (err) {
            console.log(err);
        }
    }, [productId]);

    return { getEans, eans, setEans, loadingDeleteEan, setLoadingDeleteEan };
};

export const useDescriptionReview = (productId: number | undefined, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [description, setDescription] = useState<Record<string, string | number | null> | undefined>();
    const [review, setReview] = useState<Record<string, string> | undefined>(); // if review doesn't exist return null
    const getDescriptionReview = useCallback(async () => {
        setLoading(true);
        const URL1 = `${process.env.REACT_APP_URL_API}/product/item/get-description?product_id=${productId}`;
        const URL2 = `${process.env.REACT_APP_URL_API}/product/product-review/get?product_id=${productId}`;

        const promise1 = axios.get(URL1);
        const promise2 = axios.get(URL2);

        Promise.all([promise1, promise2])
            .then((values) => {
                if (values) {
                    setDescription(values[0].data.data);
                    setReview(values[1].data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [productId, setLoading]);

    return { getDescriptionReview, description, setDescription, review, setReview };
};

export const UseCategories = (currentCountryId: number, currentCountryName: string, stateProduct: RootStore) => {
    const [categories, setCategories] = useState<CateogoryL3L4[]>([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);

    useEffect(() => {
        setCategories(
            stateProduct.categoriesL3.data &&
                stateProduct.categoriesL3.data
                    .sort((a: CateogoryL3L4, b: CateogoryL3L4) =>
                        a[`name_${currentCountryName}` as keyof CateogoryL3L4] > b[`name_${currentCountryName}` as keyof CateogoryL3L4]
                            ? 1
                            : b[`name_${currentCountryName}` as keyof CateogoryL3L4] > a[`name_${currentCountryName}` as keyof CateogoryL3L4]
                            ? -1
                            : 0
                    )
                    .map((c3: CateogoryL3L4) => ({
                        l2_name: c3.l2_name,
                        name: c3[`name_${currentCountryName}` as keyof CateogoryL3L4],
                        id: c3.id,
                        children: c3.children.map((c4: Record<string, string | number>) => ({
                            name: c4[`name_${currentCountryName}`],
                            id: c4.id,
                        })),
                    }))
        );
    }, [stateProduct.categoriesL3.data, currentCountryName]);

    return { categories };
};

export const UseHistory = (productId: number | undefined, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [history, setHistory] = useState<HistoryType[]>([]);

    const getHistory = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/get-product-history?product_id=${productId}`);
            setHistory(data.data.map((d: any) => ({ ...d, parameters: JSON.parse(d.parameters) })));
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [productId, setLoading]);
    return { getHistory, history };
};

export const UsePrices = (productId: number | undefined, currentCountryId: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [prices, setPrices] = useState<PriceType[]>([]);
    const [loaidngPrices, setLoadingPrices] = useState(false);
    const getPrices = useCallback(async () => {
        setLoading(true);
        setLoadingPrices(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/get-all-product-prices?product_id=${productId}&country_id=${currentCountryId}`);
            setPrices(
                data.data
                    .sort((a: { price: string }, b: { price: string }) => Number(a.price) - Number(b.price))
                    .map((d: { active_for_website: number }) => ({ ...d, active_for_website: d.active_for_website === 1 ? 1 : 0 }))
            );
            setLoadingPrices(false);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [productId, currentCountryId, setLoading]);

    return { getPrices, prices, setPrices, loaidngPrices };
};

export const UseBrands = () => {
    const [brandList, setBrandList] = useState<{ id: number; name: string }[]>([]);
    useEffect(() => {
        const getBrands = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/list-valid-brands`);
                const filteredData = data.data.filter((d: { id: number }) => d.id !== 3621); //3621
                filteredData.unshift(data.data.find((d: { id: number }) => d.id === 3621));
                setBrandList(filteredData);
            } catch (err) {
                console.log(err);
            }
        };
        getBrands();
    }, []);

    return { brandList };
};

export const UseAttributes = (productId: number | undefined, currentCountryId: number, currentCountryName: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [attributeList, setAttributeList] = useState<AttributeListType[]>([]);
    const getAttributes = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-product-attributes?product_id=${productId}&country_id=${currentCountryId}`);

            let groupedAttributeByGroup = data.data.reduce((acc: any, obj: any) => {
                let group = acc.find((g: { attribute_id: number }) => g.attribute_id === obj.attribute_id);

                if (group) {
                    group.all_obj_data.push(obj);
                } else {
                    acc.push({
                        ...obj,
                        all_obj_data: [obj],
                        all_values: JSON.parse(obj.all_values),
                        key: obj[`attribute_name_${currentCountryName}`]?.toString(),
                    });
                }

                return acc;
            }, []);

            setAttributeList(
                groupedAttributeByGroup
                    .map((a: any) => {
                        return {
                            ...a,
                            ...(a.attribute_type === "multiselect"
                                ? {
                                      allowed_values: a.all_obj_data.map((av: any) => av.value_rs).filter((av: any) => av) /*.join(", ") */,
                                  }
                                : null),
                            ...(a.attribute_type === "multiselect"
                                ? {
                                      allowed_ids: a.all_obj_data.map((av: any) => av.attribute_allowed_value_id).filter((av: any) => av),
                                  }
                                : null),
                        };
                    })
                    .sort((a: AttributeListType, b: AttributeListType) => a.attribute_type.localeCompare(b.attribute_type))
            );
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [productId, currentCountryId, currentCountryName, setLoading]);

    return { getAttributes, attributeList, setAttributeList };
};
