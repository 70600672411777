import { Button, Divider, Form, Input, Modal, Row } from "antd";
import React from "react";
import { SkuModalProp } from "../types/modals";

const SkuModal: React.FC<SkuModalProp> = (props) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        props.setIsModalSku(false);
    };
    const searchProductsBySku = (e: { skus: string }) => {
        const skus = e.skus.replace(" ", "");
        const skusToArray = skus.split("\n");
        props.setSkus(skusToArray);
    };
    const clearSkus = () => {
        props.setSkus([]);
        form.resetFields();
    };
    return (
        <Modal title="Search Product By sku" open={props.isModalSku} footer={false} onCancel={handleCancel}>
            <Divider style={{ margin: "0.1rem 0 0.9rem 0" }} />
            <Form
                form={form}
                onFinish={searchProductsBySku}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                style={{ border: "1px solid whitesmoke", borderRadius: "8px", padding: "1rem" }}
            >
                <Form.Item name="skus" label="Skus" rules={[{ required: true, message: "Skus is required!" }]}>
                    <Input.TextArea placeholder="Enter Sku list separate by comma" rows={18} />
                </Form.Item>
                <Divider />
                <Row justify="space-between">
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Search
                        </Button>
                    </Form.Item>
                    <Button danger onClick={clearSkus}>
                        Clear sku
                    </Button>
                </Row>
            </Form>
        </Modal>
    );
};

export default SkuModal;
