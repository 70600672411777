import { Button, Card, Modal, Select, Tabs, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import showMessage from "../../../shared/MessagesInfo/message";
import { CategoryType } from "../pages/AllCategories";
import ImageEdit from "./EditCategory/ImageEdit";
import Links from "./EditCategory/Links";
import MetaDescription from "./EditCategory/MetaDescritpion";
import MetaKeywords from "./EditCategory/MetaKeywords";
import MetaTitle from "./EditCategory/MetaTitle";
import Names from "./EditCategory/Names";
import "../style/EditCategory.css";
const { Text } = Typography;

export type TranslateType = {
    description_hr: string;
    description_rs: string;
    description_si: string;
    description_ba: string;
    description_me: string;

    id: number;

    link_hr: string;
    link_rs: string;
    link_si: string;
    link_ba: string;
    link_me: string;

    meta_description_hr: null;
    meta_description_rs: null;
    meta_description_si: string;
    meta_description_ba: string;
    meta_description_me: string;

    meta_keywords_hr: string;
    meta_keywords_rs: string;
    meta_keywords_si: string;
    meta_keywords_ba: string;
    meta_keywords_me: string;

    meta_title_hr: string;
    meta_title_rs: string;
    meta_title_si: string;
    meta_title_ba: string;
    meta_title_me: string;

    name_hr: string;
    name_rs: string;
    name_si: string;
    name_ba: string;
    name_me: string;

    parent_id: number | undefined;
    seo_status_hr: null;
    seo_status_rs: string;
    seo_status_si: string;
    seo_status_ba: string;
    seo_status_me: string;

    type: string;
    key?: string;

    [name: string]: any;
};

export type EditCateogyType = {
    child: string[];
    created_at: null;
    default_image_url: null;
    disabled: number;
    id: number;
    large_image_url: string;
    name: string;
    shoptok_id: null;
    translate: TranslateType;
    updated_at: null;
    redirects: { id: number; redirect_to: string; redirect_from: string }[];
    image_slug: string;
};

export interface EditCateogryProps {
    selectedCateogry: CategoryType | null;
    categories?: CategoryType[];
    modalEdit: boolean;
    setModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedEditCat: React.Dispatch<React.SetStateAction<CategoryType | null>>;
    editCategory: EditCateogyType | null;
    setEditCateogry: React.Dispatch<React.SetStateAction<EditCateogyType | null>>;
    currentCountryName: string;
    currentCountryId: number;
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
    countries: { name: string; value: number; currency: string; order: number }[];
    setSelectedCategories?: any;
}
let categoryId = 0;

const EditCategory: React.FC<EditCateogryProps> = ({
    selectedCateogry,
    modalEdit,
    setModalEdit,
    setSelectedEditCat,
    editCategory,
    setEditCateogry,
    currentCountryName,
    currentCountryId,
    counter,
    setCounter,
    countries,
    setSelectedCategories,
}) => {
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [categoryLevel, setCategoryLevel] = useState<{ id: number; type: string; name_rs: string }[]>([]);
    const [imageEdit, setImageEdit] = React.useState<{ file: { type: string }; id: number }[]>([]);
    const [newImg, setNewImg] = useState<{ data_url: string } | null>(null);
    const [newParent, setNewParent] = useState<number | null>(null);
    const [isParentChange, setIsParentChange] = useState(false);

    useEffect(() => {
        const getAllCategoriesArray = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/list-all-categories?country_id=${currentCountryId}`);
                const level1 = data.data[0]
                    .map((category1: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category1,
                            categoryId: categoryId,
                            category_l1_id: category1.id,
                            [`category_l1_name_${currentCountryName}`]: [`category1.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);
                const level2 = data.data[1]
                    .map((category2: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category2,
                            categoryId: categoryId,
                            category_l2_id: category2.id,
                            [`category_l2_name_${currentCountryName}`]: [`category2.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                            inputError: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level3 = data.data[2]
                    .map((category3: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category3,
                            categoryId: categoryId,
                            category_l3_id: category3.id,
                            [`category_l3_name_${currentCountryName}`]: [`category3.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);

                const level4 = data.data[3]
                    .map((category4: CategoryType) => {
                        categoryId += 1;
                        return {
                            ...category4,
                            categoryId: categoryId,
                            category_l4_id: category4.id,
                            [`category_l4_name_${currentCountryName}`]: [`category4.name_${currentCountryName}`],
                            isMetaTitle: false,
                            isMetaKeywords: false,
                            isMetaDescription: false,
                        };
                    })
                    .sort((a: CategoryType, b: CategoryType) => a.id - b.id);
                setCategories([...level1, ...level2, ...level3, ...level4]);
            } catch (err) {
                console.log(err);
            }
        };
        modalEdit && getAllCategoriesArray();
    }, [currentCountryId, currentCountryName, counter, modalEdit]);

    useEffect(() => {
        const getCategory = async () => {
            const objectToSend = {
                id: selectedCateogry?.id,
                level_id: Number(selectedCateogry?.type[1]),
                parent_id: Number(selectedCateogry?.type[1]) === 1 ? 0 : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof CategoryType],
            };
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/category/show?id=${objectToSend.id}&level_id=${objectToSend.level_id}&parent_id=${objectToSend.parent_id}`);
                setEditCateogry(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCateogry && getCategory();
    }, [selectedCateogry, setEditCateogry]);

    useEffect(() => {
        setCategoryLevel(categories.filter((c) => c.type === `l${Number(selectedCateogry?.type[1]) - 1}`));
    }, [selectedCateogry, categories, modalEdit]);

    const setNewParentCategory = async () => {
        let level = selectedCateogry?.type;
        const objToSend = {
            [level === "l2" || level === "l3" ? `category_l${Number(selectedCateogry?.type[1]) - 1}_id` : "parent_id"]: newParent,
            level_id: Number(selectedCateogry?.type[1]),
            parent_id: level === "l4" ? newParent : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof CategoryType], //for level 4 parent id will be different from l2 and l3!!!
            id: selectedCateogry?.id,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/category/update`, objToSend);
            setNewParent(null);
            showMessage(data.message);
            const findnewCat = categories.find((c) => c[`category_l${Number(selectedCateogry?.type[1]) - 1}_id` as keyof typeof c] === newParent) ?? "";
            const name = findnewCat[`name_${currentCountryName}` as keyof typeof findnewCat];
            setSelectedEditCat((curr) => {
                return curr
                    ? {
                          ...curr,
                          [`category_l${Number(selectedCateogry?.type[1]) - 1}_id`]: newParent,
                          [`category_l${Number(selectedCateogry?.type[1]) - 1}_name_${currentCountryName}`]: name,
                      }
                    : null;
            });
            setSelectedCategories((curr: any) => ({
                ...curr,
                cat1: {
                    ...curr.cat1,
                    [`category_l${Number(selectedCateogry?.type[1]) - 1}_id`]: newParent,
                    [`category_l${Number(selectedCateogry?.type[1]) - 1}_name_${currentCountryName}`]: name,
                },
            }));
        } catch (err) {
            console.log(err);
        }
    };

    const countriesArr = countries?.sort((a, b) => a.order - b.order).map((c) => c.name);

    const tabsArr: any[] = [
        {
            title: "Names",
            key: 1,
            component: <Names data={editCategory} setEditCateogry={setEditCateogry} selectedCateogry={selectedCateogry} countries={countriesArr} />,
        },
        {
            title: "Meta Title",
            key: 2,
            component: <MetaTitle data={editCategory} selectedCateogry={selectedCateogry} countries={countriesArr} />,
        },
        {
            title: "Meta Keywords",
            key: 3,
            component: <MetaKeywords data={editCategory} selectedCateogry={selectedCateogry} countries={countriesArr} />,
        },
        {
            title: "Meta Description",
            key: 4,
            component: <MetaDescription data={editCategory} selectedCateogry={selectedCateogry} countries={countriesArr} />,
        },
        {
            title: "Links / Redirects",
            key: 5,
            component: <Links data={editCategory} selectedCateogry={selectedCateogry} countries={countriesArr} />,
        },
        {
            title: "Image",
            key: 6,
            component: (
                <ImageEdit
                    data={editCategory}
                    selectedCateogry={selectedCateogry}
                    setSelectedEditCat={setSelectedEditCat}
                    newImg={newImg}
                    setNewImg={setNewImg}
                    imageEdit={imageEdit}
                    setImageEdit={setImageEdit}
                    setSelectedCategories={setSelectedCategories} //only for reCategorized for first category
                />
            ),
        },
    ];

    return (
        <Modal
            title="Edit"
            centered
            open={modalEdit}
            onOk={() => setModalEdit(false)}
            onCancel={() => {
                setSelectedEditCat(null);
                setModalEdit(false);
                setEditCateogry(null);
                setNewImg(null);
                setImageEdit([]);
                setCounter((curr: number) => curr + 1);
                setIsParentChange(false);
                setNewParent(null);
            }}
            width={1700}
            footer={false}
        >
            <div
                style={{
                    border: "1px solid lightgray",
                    position: "relative",
                    borderRadius: "4px",
                    paddingTop: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f1",
                }}
            >
                <div style={{ position: "absolute", left: "7.6rem", top: "0.3rem", display: "flex" }}>
                    <Text strong>{selectedCateogry && selectedCateogry[`name_${currentCountryName}` as keyof CategoryType]}</Text>{" "}
                    {editCategory?.disabled === 1 && (
                        <div style={{ position: "relative", bottom: "0.4rem", marginLeft: "1rem" }}>
                            <Text type="danger" strong>
                                Disabled
                            </Text>
                            <br />
                            <Text type="warning" strong>
                                Redirect - {editCategory?.redirects.length > 0 ? "YES" : "NO"}
                            </Text>
                        </div>
                    )}
                </div>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"middle"}
                    className="edit-category-tabs"
                    style={{
                        height: "70vh",
                        minWidth: "1000px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.5rem",
                        border: "1px solid #f1f1f1",
                        borderRadius: "7px",
                        margin: "1.5rem 0",
                        backgroundColor: "white",
                        paddingTop: "1rem",
                    }}
                    items={tabsArr.map((tab) => ({
                        label: tab.title,
                        key: tab.key,
                        children: tab.component,
                    }))}
                />

                <Card
                    style={{
                        position: "relative",
                        left: "6rem",
                        margin: "1.5rem 4rem 1.5rem 0",
                        borderRadius: "6px" /*, backgroundColor:'#f1f1f1'*/,
                    }}
                >
                    <p>Change Parent Category</p>
                    <Select
                        showSearch
                        placeholder="Select Parent Category"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                        onChange={(e) => {
                            setNewParent(Number(e));
                            setIsParentChange(true);
                        }}
                        allowClear
                        style={{ width: "240px" }}
                        disabled={selectedCateogry?.type === "l1"}
                        value={isParentChange ? newParent : selectedCateogry && selectedCateogry[`category_l${Number(selectedCateogry.type[1]) - 1}_id` as keyof CategoryType]}
                    >
                        {categoryLevel.map(
                            (cat) =>
                                cat[`name_${currentCountryName}` as keyof { id: number; type: string; name_rs: string }] && (
                                    <Select.Option
                                        key={`${cat.id} ${
                                            cat[
                                                `name_${currentCountryName}` as keyof {
                                                    id: number;
                                                    type: string;
                                                    name_rs: string;
                                                }
                                            ]
                                        }`}
                                        value={cat.id}
                                    >
                                        {`${cat.id}: ${
                                            cat[
                                                `name_${currentCountryName}` as keyof {
                                                    id: number;
                                                    type: string;
                                                    name_rs: string;
                                                }
                                            ]
                                        }`}
                                    </Select.Option>
                                )
                        )}
                    </Select>
                    <Button style={{ marginLeft: "0.2rem" }} disabled={newParent ? false : true} onClick={setNewParentCategory}>
                        Set
                    </Button>
                </Card>
            </div>
        </Modal>
    );
};

export default EditCategory;
