import { useState } from "react"
export const useKeyEvents = (
    allPrices:any,
    setAllPrices:any,
    currentCountryId:number,
    currentCountryName:string,
    notShowPrices:any,
    setMpnItem:any
    ) => {
    const [ highlightItem, setHighlightItem ] = useState<any>({text:'',id:''})




    //------ //----- F1 ------// ----------
    const onlyFirstWordInModel = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
            
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const wordToArray =  price[`name_${currentCountryName}`].split(" ")
                const cutFirstItem = wordToArray.slice(0, 1)
                return {
                    ...price,
                    [`name_${currentCountryName}`]:cutFirstItem.join(" ")
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }
    //------//----- F2 ------// ----------
    const onlyLastWordInModel = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
           
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const wordToArray = price[`name_${currentCountryName}`].split(" ")
                const cutFirstItem = wordToArray.slice(wordToArray.length - 1, wordToArray.length)
                return {
                    ...price,
                    [`name_${currentCountryName}`]:cutFirstItem.join(" ")
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }
    //------//----- F3 ------// ----------
    const deleteFirstWordInModel = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
           
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const wordToArray = price[`name_${currentCountryName}`].split(" ")
                const cutFirstItem = wordToArray.slice(1,  wordToArray.length)
                return {
                    ...price,
                    [`name_${currentCountryName}`]:cutFirstItem.join(" ")
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }
    //------ //----- F4 ------//----------
    const deleteLastWordInModel = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
            
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const wordToArray = price[`name_${currentCountryName}`].split(" ")
                const cutFirstItem = wordToArray.slice( 0 , wordToArray.length -1 )
                return {
                    ...price,
                    [`name_${currentCountryName}`]:cutFirstItem.join(" ")
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }
    //-------//----- F5 ------//-------
    const resetModel = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any, index:any ) => {
            let filteredOriginal:any
            if ( price.isChecked === true ) {
                if ( price.brand_name && price.initName ) {
                    const removeBrand = price.initName.toLowerCase().includes(price.brand_name.toLowerCase())
                    if (removeBrand) {
                        let indexItem:any;
                        const newRemoveBrand = price.initName.toLowerCase().split(' ')
                        newRemoveBrand.forEach(( item:any,index:any ) => {
                            
                            if(item.includes(price.brand_name.toLowerCase())){
                                indexItem=index
                            }
                        })
                        const originalTextArr = price.initName.split(' ')
                        const filteredOriginalFinl = originalTextArr.filter((txt:any,index:any)=> index!==indexItem)
                        filteredOriginal = filteredOriginalFinl && filteredOriginalFinl.join(' ')
                    }
                    else {
                    return {
                        ...price,
                        [`name_${currentCountryName}`]:price.initName
                        }
                    }
                    return {
                        ...price,
                        [`name_${currentCountryName}`]:filteredOriginal
                    }
                }
                else {
                    return {
                        ...price,
                        [`name_${currentCountryName}`]:price.initName
                    }
                    
                }
                
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }
    //----------//----- F6 ------//------
    const setMultiMpn = () => {
        const findChecked = allPrices.data.data.map(( price:any ) => {
            if ( price.isChecked === true) {
                return {
                    ...price,
                    mpn:price[`name_${currentCountryName}`],
                    isMpnChange:true
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:findChecked}})
    }
    //  remove shorcut from F7
      
    //-----------//----- F8 ------//-------
    const firstCharacterLowCase = () => {
        const firstLetterLowerCase = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
            
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const joinStringArr = price[`name_${currentCountryName}`][0].charAt(0).toLowerCase() + price[`name_${currentCountryName}`].substring(1)
                return {
                    ...price,
                    [`name_${currentCountryName}`]:joinStringArr
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:firstLetterLowerCase}})
    }
    //------//------F9----- //-----------
    const allLetterSmall = () => {
        const smallLetter = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
        if ( price.isChecked && price[`name_${currentCountryName}`] ) {
            const wordToArray = price[`name_${currentCountryName}`].split(" ")
            const makeSmallLetter = wordToArray.map((word:any) =>{
                    if((/^[a-zA-ZŠšČčĆćŽžĐđ]+$/.test(word) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test (word)) && !/\d/g.test(word)){
                        return word.toLocaleLowerCase()
                    }
                    else if (parseInt(word)) {
                    return word
                    }
                    else {
                        return word
                    }
            })
            return {
                ...price,
                [`name_${currentCountryName}`]:makeSmallLetter.join(" ")
            }
        }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:smallLetter}}) 
    }
    //------//----- F11 ------//------
    const resetAllCheched = () => {
        const resetChecked =  allPrices.data &&  allPrices.data.data.map((price:any) => {
            return {
                ...price,
                isChecked:false
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:resetChecked}}) 
    }
    //-------//----- ctrl + 1 -----//---------
    const removeSelectedRows = () => {
        const removeRows = allPrices.data &&  allPrices.data.data.filter(( price:any, index:any ) => price.isChecked === false )
        allPrices.data.data.forEach((price:any) => {
            if (price.isChecked === true ) {
                notShowPrices.push(price.id)
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:removeRows}})
    }
    //-------//----ctrl + 2----//------

    const parenthesesOnly = () => {
        const addTextBefore = allPrices.data &&  allPrices.data.data.map(( price:any, index:any ) => {
            if ( price.isChecked === true && price[`name_${currentCountryName}`] && price[`name_${currentCountryName}`].length >2  ) {  //this is changed before it was model
                const wordToArray = price[`name_${currentCountryName}`].split(" ")
                const findParentheses = wordToArray.filter((item:any) => {
                    if ( item.indexOf(`(`) > -1 && item.indexOf(`)`) > -1 ) {
                        const trimString = item.trim() 
                        return trimString
                    }
                    return null
                })
                return {
                    ...price,
                    [`name_${currentCountryName}`]:findParentheses[0] ? findParentheses[0].slice(1,-1) : price.model
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:addTextBefore}})
    }

    //-------//----ctrl + 3----//------
    /*------------check first letter if upperCase------------ */
    

    const convertLatinWord = (latWordChnage:any) => {
        const firstLetterLowerCase = allPrices.data &&  allPrices.data.data.map(( price:any ) => {
            if ( price.isChecked === true && price[`name_${currentCountryName}`] ) {
                const stringArr = price[`name_${currentCountryName}`].split(' ')
                let keys = Object.keys(latWordChnage);    
                const newCorrectWords = stringArr.map((word:string) => {
                    for (let index = 0; index < keys.length; index++) {
                        const element = keys[index];
                        if( word.trim().toLowerCase().includes(element.trim().toLowerCase())) {
                            const numberOfCharacter = element.length
                            const newWord = word.slice(numberOfCharacter,word.length)
                            const clearWord = latWordChnage[element] + newWord
                            return  clearWord.toLowerCase()
                        }
                    }
                    return  word
                })
                return {
                    ...price,
                    [`name_${currentCountryName}`]:newCorrectWords.join(' ')
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:firstLetterLowerCase}})
    }

    const setMpnField = () => {
        const replaceItem = allPrices.data && allPrices.data.data.map((price:any) => {
            if (  price.id === highlightItem.id && price.isMpn === false ) {
                setMpnItem(price)
                return {
                    ...price,
                    mpn:highlightItem.text && highlightItem.text.trim(),
                    selected:true,
                    isMpnChange:true
                }
            }
            else {
                return price
            }
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:replaceItem}})
        setHighlightItem({text:'',id:''})
    }

    //----------  ALT + A --------------


    const setCheckedToChanged = () => {
        const checkedToChanged = allPrices.data.data.map((price:any)=>{
            if (price.isChecked) {
                return {
                    ...price,
                    isChecked:false,
                    isChanged:true
                }
            }
            return  price
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:checkedToChanged}})
    }

    //----------  ALT + M --------------

    const addSellerModelToName = () => {
        const findPrices = allPrices.data.data.map((price:any) => {
            if ( price.isChecked && price[`name_${currentCountryName}`] !== null && price.product_model ) {
                return {
                    ...price,
                    [`name_${currentCountryName}`]: `${price[`name_${currentCountryName}`]} ${price.product_model}`
                }
            }
            return price
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:findPrices}})
    }

     //----------  ALT + S --------------

     const addSellerSkuToName = () => {
        const findPrices = allPrices.data.data.map((price:any) => {
            if ( price.isChecked && price[`name_${currentCountryName}`] !==null && price.sku ) {
                return {
                    ...price,
                    [`name_${currentCountryName}`]: `${price[`name_${currentCountryName}`]} ${price.sku}`
                }
            }
            return price
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:findPrices}})

        
    }

    //----------------- ALT + R -----------------

    const addMpnToEditableName = () => {
        const findPrices = allPrices.data.data.map((price:any) => {
            if ( price.isChecked && price[`name_${currentCountryName}`] !==null && price.mpn ) {
                return {
                    ...price,
                    [`name_${currentCountryName}`]: `${price[`name_${currentCountryName}`]} ${price.mpn}`
                }
            }
            if ( price.isChecked && price[`name_${currentCountryName}`] !==null && price.product_mpn ) {
                return {
                    ...price,
                    [`name_${currentCountryName}`]: `${price[`name_${currentCountryName}`]} ${price.product_mpn}`
                }
            }
            return price
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:findPrices}})

    }


    //----------------- ALT + D -----------------

    const setMpnToEmpty = () => {
        const findPrices = allPrices.data.data.map((price:any) => {
            if ( price.isChecked && price[`name_${currentCountryName}`] !==null && price.mpn ) {
                return {
                    ...price,
                    isMpnChange:true,
                    mpn: null
                }
            }
            return price
        })
        setAllPrices({...allPrices,data:{...allPrices.data, data:findPrices}})

    }
   



    return { 
        onlyFirstWordInModel, 
        onlyLastWordInModel, 
        deleteFirstWordInModel, 
        deleteLastWordInModel, 
        resetModel,
        setMultiMpn, 
        //openDrawerAutoChecked,
        firstCharacterLowCase,
        resetAllCheched,
        setMpnField,
        removeSelectedRows,
        parenthesesOnly,
        allLetterSmall,
        setCheckedToChanged,
        addSellerModelToName,
        addSellerSkuToName,
        convertLatinWord,
        addMpnToEditableName,
        setMpnToEmpty
    }
}