import { Table, Divider, Statistic, Skeleton, Empty } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import numberWithCommas from "../../../shared/utils/numberUtils";
import "../style/NewShopProducts.css";
import DatePickerFromToReports from "./DatePickerFromToReports";

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

interface UPProps {
    currentCountryId: number;
    sfActive: number;
}

export type DatePickerNewProducts = {
    startDate: Date;
    endDate: Date | null;
};

const NewShopProducts: React.FC<UPProps> = ({ currentCountryId, sfActive }) => {
    const [initProducts, setInitProducts] = useState([]);
    const [newShopProducts, setNewShopProducts] = useState([]);
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const [loading, setLoading] = useState(false);
    const { getColumnSearchProps } = useSearchState(newShopProducts);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getAllNewPoroducts = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(
                    `${
                        process.env.REACT_APP_URL_API
                    }/logger/list-new-products?country_id=${currentCountryId}&active=${sfActive}${
                        /*formTo !==undefined ?*/ `&from=${formTo.startDate}&to=${formTo.endDate}` /*:''*/
                    }`
                );
                const sortedData = data.data
                    .sort((a: any, b: any) => b.new_products - a.new_products)
                    .map((item: any) => {
                        const newName = capitalizeFirstLetter(item.name.toLowerCase());
                        return {
                            ...item,
                            name: newName,
                        };
                    });
                setNewShopProducts(sortedData);
                setInitProducts(sortedData);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        getAllNewPoroducts();
    }, [currentCountryId, sfActive, date]);

    const tableColumns: any = [
        {
            title: "Shop",
            dataIndex: "name",
            key: "name",
            width: "60%",
            ...getColumnSearchProps("name"),
        },
        {
            title: "New",
            dataIndex: "new_products",
            width: "35%",
            align: "center" as "center",
            key: "new_products",
            sorter: (
                a: { id: number; new_products: number },
                b: { id: number; new_products: number; feed_products: number }
            ) => a.new_products - b.new_products,
        },
    ];
    const [pageNumber, setPageNumber] = useState(1);
    const onPaginateChange = (e: number) => {
        setPageNumber(e);
    };

    const onChangeTableParameters = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (extra.action === "filter") {
            setNewShopProducts(extra.currentDataSource);
        }
        if (extra.action === "sort") {
            setNewShopProducts(extra.currentDataSource);
        }
        if (filters.name === null && extra.action !== "sort" /*&& extra.action !=='sort'*/) {
            setNewShopProducts(initProducts);
        }
    };
    const dataInChart = (newShopProducts: any) => {
        if (newShopProducts.length > 20) {
            return newShopProducts.slice(20 * (pageNumber - 1), 20 * pageNumber);
        }
        return newShopProducts;
    };

    return (
        <div style={{ width: "90%" }}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <Divider>New products</Divider>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                {!loading ? (
                    newShopProducts.length > 0 ? (
                        <ComposedChart
                            layout="vertical"
                            width={440}
                            height={680}
                            data={dataInChart(newShopProducts)} //(20 * (pageNumber-1),20*pageNumber)
                            margin={{
                                top: 20,
                                right: 30,
                                bottom: 10,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis type="number" />
                            <YAxis dataKey="name" type="category" width={120} scale="band" />
                            <Tooltip />
                            <Bar dataKey="new_products" barSize={15} fill="#413ea0" />
                        </ComposedChart>
                    ) : (
                        <div style={{ width: "20px" }}></div>
                    )
                ) : (
                    <div>
                        <Skeleton.Button
                            active
                            style={{
                                width: "420px",
                                height: "680px",
                                margin: "20px 30px 20px 20px",
                                position: "relative",
                                bottom: "1.2rem",
                                left: "20px",
                            }}
                            block
                        />
                    </div>
                )}
                <div>
                    <DatePickerFromToReports date={date} setDate={setDate} isDate />
                    <div className="new-products-sum-info">
                        <Statistic
                            style={{ marginRight: "1rem" }}
                            title="New products"
                            value={
                                newShopProducts.length > 0
                                    ? numberWithCommas(
                                          String(
                                              newShopProducts.reduce(function (
                                                  previousValue,
                                                  currentValue: { new_products: number }
                                              ) {
                                                  return previousValue + currentValue.new_products;
                                              },
                                              0)
                                          ).replace(".", ",")
                                      )
                                    : "..."
                            }
                            valueStyle={{ color: "#008f95" }}
                        />
                    </div>
                    {!loading ? (
                        newShopProducts.length > 0 && (
                            <Table
                                bordered
                                dataSource={newShopProducts}
                                rowKey={(record: { id: number }) => record && record.id}
                                columns={tableColumns}
                                className="new-shop-products-table"
                                onChange={onChangeTableParameters}
                                pagination={{
                                    size: "small",
                                    showSizeChanger: false,
                                    defaultPageSize: 20,
                                    onChange: onPaginateChange,
                                }}
                            />
                        )
                    ) : (
                        <div style={{ width: "380px" /*, height:'402px',marginRight:'8.2rem'*/ }}>
                            <Skeleton
                                active
                                paragraph={{ rows: 16 }}
                                title={{ width: "100%" }}
                                className="new-shop-products-table"
                                style={{ border: "none" }}
                            />
                        </div>
                    )}
                </div>
            </div>

            {newShopProducts.length > 0 ? (
                <div style={{ display: "flex", width: "100%", marginLeft: "6rem" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                            className="square-label"
                            style={{ backgroundColor: "#413ea0", position: "relative", top: "3px" }}
                        ></div>
                        <span>New Products</span>
                    </div>
                </div>
            ) : (
                ""
            )}
            {!loading && newShopProducts.length === 0 && (
                <div style={{ textAlign: "center", marginTop: "6rem" }}>
                    <Empty />
                </div>
            )}
        </div>
    );
};

export default NewShopProducts;
