import React from 'react'
import { Row, Col, Divider, Form, Select } from 'antd'
import InputE from '../../../shared/FormElements/Input'
import { useEffect } from 'react'
import axios from 'axios'
import showMessage from '../../../shared/MessagesInfo/message'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategoryL3 } from '../../../actions/category/categoryActions'
import { RootStore } from '../../../Store'
import { useHistory } from 'react-router'
import '../style/CreatePredefinedText.css'
import HelmetTitle from '../../../shared/Head/HelmetTitle'

const CreatePredefinedText:React.FC = () => {
    const [ form ] = Form.useForm()
    const history = useHistory()
    const state = useSelector(( state:RootStore ) => state)
    const currentCountryName =  state.dataLs.country ? state.dataLs.country.currentCountry:"rs"
    const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1
    const { Option } = Select
    const dispatch = useDispatch()
   
    useEffect(()=>{
        dispatch(getAllCategoryL3(currentCountryId))
      }, [ dispatch, currentCountryId ])

    const createPredefinedText = async ( obj:any ) => {
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/predefined-word/create?country_id=1&l3_l4_id=${obj.level_id}&${`name_${currentCountryName}`}=${obj.name}`, obj)
            showMessage(data.message)
            history.push('/price-list')
           
            form.resetFields()
        } catch ( err ) {
            console.log(err)
        }
    }

    const handleSubmit = ( e:any ) => {
        createPredefinedText(e)
    }
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Create predefined text" />  
            <Row justify='center'>
                <Col span={ 6 }>
                    <Divider>Create predefined text</Divider>
                    <Form 
                        form={form}
                        name="create-category-form" 
                        labelCol={{ span: 8 }} 
                        onFinish={ handleSubmit }
                        id="create-predefinedText-form"
                    >
                        <Form.Item 
                            label="Category name" 
                            name="level_id"
                            rules={[{ required: true, message: 'Missing Level id' }]}
                        >
                            <Select
                            showSearch
                            allowClear
                            style={{ width: '180px', marginBottom:'0.5rem' }}
                            placeholder="Select category"
                            //defaultValue={1}
                            //onChange={onCategoryChange}
                            filterOption={(input, option:any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                state.categoriesL3.data && state.categoriesL3.data.sort((a:any,b:any) => (a[`name_${currentCountryName}`] > b[`name_${currentCountryName}`]) ? 1 : ((b[`name_${currentCountryName}`] > a[`name_${currentCountryName}`]) ? -1 : 0)).map((option:any)=>
                                    <Option key={option.id} value={option.id}>{option[`name_${currentCountryName}`]}</Option>  //option.translate &&  option.id!==1 &&    ovo da se vrati 
                                )
                            }
                        </Select>
                        </Form.Item>
                        <InputE 
                            label="Name" 
                            name={`name_${currentCountryName}`}
                            rules={[{ required: true, message: 'Missing name' }]}
                        />
                    
                        <button type='submit' className='u-button' style={{ marginTop:'8px' }}>Create predefined text</button>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
        
    )
}

export default CreatePredefinedText
