import { Button, Col, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SegmentedValue } from "antd/es/segmented";
import axios from "axios";
import { RootStore } from "../../../../../../Store";
import ShortDescriptionModal from "./ShortDescriptionModal";
import CountriesTabs from "./CountriesTabs";
import CountiesStatus from "./CountiesStatus";
import ButtonsBox from "./ButtonsBox";

interface DescriptionProps {
    productId: number | undefined;
    stateProduct: RootStore;
    description: Record<string, string | number | null>;
    setDescription: React.Dispatch<React.SetStateAction<Record<string, string | number | null> | undefined>>;
    currentCountryId: number;
    loading: boolean;
}

const Description = (props: DescriptionProps) => {
    const { productId, stateProduct, description, setDescription, currentCountryId, loading } = props;
    const [country, setCountry] = useState("rs");
    const [text, setText] = useState("");

    // short description
    const [shortDescription, setShortDescription] = useState("");
    const [shortDescriptionVisible, setShortDescriptionVisible] = useState(false);
    const init: any = description[`preview_text_${country}`] ? description[`preview_text_${country}`] : undefined;

    const changeCountryValue = (e: SegmentedValue) => {
        setCountry(e.toString());
    };

    const changeDataOnBlur = (event: any, editor: ClassicEditor) => {
        const newText = editor.getData();
        const clearInit = init?.toString() ?? "";

        if (clearInit.trim() === newText.trim()) {
            return;
        }
        setDescription((curr) =>
            curr
                ? {
                      ...curr,
                      [`flag_${country}`]: text?.trim().length === 0 ? 0 : 1,
                      [`preview_text_${country}` as keyof typeof curr]: newText,
                  }
                : undefined
        );
    };

    const onEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setText(data);
    };

    const showShortDescription = async () => {
        //setProductId(id)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/get-short-description?product_id=${productId}&country_id=${currentCountryId}`);
            setShortDescription(data.data.preview);
            setShortDescriptionVisible(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div style={{ width: "100%", padding: "0.5rem", borderRadius: "8px", border: "1px solid lightgray", backgroundColor: "#fafafb", marginRight: "0.5rem" }}>
            <Col span={24}>
                {/* <Row justify="center">
                    <Typography.Title level={5}>Description</Typography.Title>
                </Row> */}
                <Row justify="space-between" align="middle">
                    <Col>
                        <Button onClick={showShortDescription}>Short Description</Button>
                    </Col>
                    <Typography.Title level={5}>Description</Typography.Title>
                    <ShortDescriptionModal
                        shortDescription={shortDescription}
                        shortDescriptionVisible={shortDescriptionVisible}
                        setShortDescriptionVisible={setShortDescriptionVisible}
                        setShortDescription={setShortDescription}
                        productId={productId}
                        currentCountryId={currentCountryId}
                    />
                    <Row align="middle">
                        <CountriesTabs stateProduct={stateProduct} description={description} changeCountryValue={changeCountryValue} />
                        <CountiesStatus />
                    </Row>
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: "1rem" }} className="product-description-container">
                {loading && <Spin style={{ position: "absolute", top: "50%", left: "45%", zIndex: "200" }} />}
                <CKEditor editor={ClassicEditor} data={init ? init : ""} onChange={onEditorChange} onBlur={changeDataOnBlur} />
                <ButtonsBox {...props} country={country} text={text} />
            </Col>
        </div>
    );
};

export default Description;
