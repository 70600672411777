import { Popover, Spin } from "antd";
import React, { useState } from "react";
import { getProductImages } from "../api/apiImage";
import { DropdownImagesProps } from "../types/props";
import { ImageType } from "../types/types";
import PopupImageContent from "./Popups/PopupImageContent";
import { EditOutlined } from "@ant-design/icons";

const PopupImages: React.FC<DropdownImagesProps> = (props) => {
    const [images, setImages] = useState<ImageType[]>([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);

    const showImageData = async (
        visible: boolean,
        type?: "editIcon" | undefined
    ) => {
        console.log(visible);
        if (visible && type === "editIcon") {
            setLoadingImages(true);
            getProductImages(props.record.product_id)
                .then((resp) => {
                    setImages(
                        resp.data.sort((i: ImageType) =>
                            i.primaryimage ? 1 : 0
                        )
                    );
                    props.setProducts((curr) =>
                        curr.map((d) => ({
                            ...d,
                            isImage:
                                d.product_id === props.record.product_id
                                    ? true
                                    : false,
                        }))
                    );
                    setPopoverVisible(true);
                    setLoadingImages(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingImages(false);
                });
        } else {
            setPopoverVisible(false);
            props.setProducts((curr) =>
                curr.map((p) => ({ ...p, isImage: false }))
            );
            setImages([]);
        }
    };

    return (
        <Popover
            content={
                // popoverVisible && (
                <PopupImageContent
                    images={images}
                    setImages={setImages}
                    record={props.record}
                    setProducts={props.setProducts}
                />
                // )
            }
            style={{ paddingTop: "20px", width: "100%" }}
            onOpenChange={showImageData}
            open={popoverVisible}
            placement="bottom"
            trigger="click"
        >
            <div
                style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <EditOutlined
                    className="all-products-edit-image"
                    onClick={() => showImageData(true, "editIcon")}
                />
                {loadingImages && (
                    <Spin
                        size="small"
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "2px",
                        }}
                    />
                )}
            </div>
        </Popover>
    );
};

export default PopupImages;
