import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/userAction/userAction'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import { RootStore } from '../../../Store'
import '../style/Home.css'

const Home = () => {
  
  const dispatch = useDispatch()
  const userMyAcState = useSelector((state: RootStore) => state.user)
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  const showDate = (date:string|null) => {
    let textDate
    if (date) {
      textDate = `Logged in: ${moment(new Date(JSON.parse(date))).format('DD. MMMM YYYY.')} ${ moment(new Date(JSON.parse(date))).format('HH:mm a') }`
    }
    else {
      textDate = `${moment(new Date()).format('DD. MMMM YYYY.')} ${ moment(new Date()).format('HH:mm a') }`
    }
    return textDate
  }

  return (
    <React.Fragment>
      <HelmetTitle title="Data Entry App - Home" />
      <div  className='wlc-container in-animation'>
        
          <header id="wlc-header">
          {userMyAcState.data && 
          <h5  style={{ textAlign:'center',position:'relative', top:'2rem', fontSize:'28px'}}> { `${userMyAcState.data.first_name} ${userMyAcState.data.last_name}` } </h5>}
          <h6  style={{ textAlign:'center',position:'relative', top:'2rem', fontSize:'20px'}}>{showDate(localStorage.getItem('loggedInTime'))}</h6>
            <div className='wlc-logo'>
              <span>
                <img src="assets/images/eponuda_logo.svg" alt="eponuda-logo" height="" style={{ marginLeft:'4rem', marginBottom:'3rem'}} /*style={{ backgroundColor:'lightgray',padding:'0.2rem',marginLeft:'0.5rem', marginTop:'0.5rem', borderRadius:'5px'}}*/ />
              </span>
            </div>
          </header>
          <h3 className='wlc-h3'>Welcome to ePonuda</h3>
          <h5 className='wlc-h4'>Data Management Tool </h5>
      </div>
    </React.Fragment>
  )
}

export default Home
