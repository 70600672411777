import React, { useState, useEffect } from 'react'
import { Row, Col, Divider, Table, Radio, Popconfirm, Button } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAttribute, getAllAtributes } from '../../../actions/attribute/attributeAction'
import { RootStore } from '../../../Store'

const AttributeList:React.FC = () => {
    const state = useSelector(( state:RootStore ) => state)
    const currentCountry = localStorage.getItem('currentCountry')? localStorage.getItem('currentCountry') : state.loggedInUser.user && state.loggedInUser.user.data.currentCountry
    console.log(currentCountry)
    //const attributeState = useSelector(( state:RootStore ) => state.attributes)
    const [ currentPage, setCurrentPage ] = useState(1)
    const dispatch = useDispatch()
    const onPaginateChange = (page:any) => {
        setCurrentPage(page);
      };
    ///////

    useEffect(()=>{
        dispatch(getAllAtributes(currentPage))
    }, [ currentPage, dispatch ])

    const handleDeleteClick = ( id:any ) => {
        dispatch(deleteAttribute(id))
    }
    const columns = [
        {
            title:'Id',
            dataIndex:'id',
            key:'id'
            
        },
        {
            title:'Category L3 id',
            dataIndex:'category_l3_id',
            key:'category_l3_id'
            
        },
        {
            title:'Name',
            dataIndex:'translate',
            key:'translate',
            render: ( record:any ) => record && record[`name_${currentCountry}`]
            
        },
        {
            title:'Min value',
            dataIndex:'min_value',
            key:'min_value'
            
        },
        {
            title:'Max value',
            dataIndex:'max_value',
            key:'max_value'
            
        },
        {
            title:'Type',
            dataIndex:'type',
            key:'type'
            
        },
        {
            title: `Action`, 
            dataIndex: `id`, 
            key:'id',
            render: (record: number ) => (
                <Radio.Group value={'large'} className='delete-edit-btn'>
                    <Popconfirm 
                        title={"Delete?"} 
                        onConfirm={() => handleDeleteClick(record)}
                    >
                        <Button danger >
                            Delete
                        </Button>
                    </Popconfirm>
                    <Radio.Button 
                        value="default" 
                        style={{marginLeft:'3px'}}
                    >
                        <Link type="primary" to={`/edit-attribute/${record}`}>
                            Edit
                        </Link>
                    </Radio.Button>
                </Radio.Group>
            //render 
            )}
    ]
    //console.log(categoryL1)
   
     console.log(state)
    return (
        <>
         <Divider>Attribute list</Divider>
        <Row justify="center">
            <Col span={12}>
                { state.attributes.data && 
                <Table 
                    columns={ columns }
                    dataSource={ state.attributes.data.data }
                    rowKey={ record => record.id } 
                    pagination={{  current: currentPage , total:state.attributes.data.total, onChange:onPaginateChange, pageSize:100, showSizeChanger: false  }}
                />}
            
            </Col>
        </Row>
           
        </>
        
    )
}

export default AttributeList
