export const USERS_LIST = "USERS_LIST"
export interface UserSuccess {
    type: typeof USERS_LIST
    payload:any
}

export const USERS_LOGIN = "USERS_LOGIN"
export interface UserLogin {
    type: typeof USERS_LOGIN
    payload:any
}

export const USER_CREATE = "USER_CREATE"
export interface UserCreate {
    type: typeof USER_CREATE
    payload:any
}
//-----------set user from LS

export const USER_SET = "USER_SET"
export interface UserSet {
    type: typeof USER_SET
    payload:any
}

//-------logout user, remove LS 

export const USER_LOGOUT = "USER_LOGOUT"
export interface UserLogout {
    type: typeof USER_LOGOUT
    payload:any
}


export const USER_DELETE = "USER_DELETE"
export interface DeleteUser {
    type: typeof USER_DELETE
    payload:any
}

//-------user from list------

export const USER_GET_BY_ID = "USER_GET_BY_ID"
export interface UserGetById {
    type: typeof USER_GET_BY_ID
    payload:any
}


export const USER_EDIT = "USER_EDIT"
export interface UserEdit {
    type: typeof USER_EDIT
    payload:any
}

export const USER_SHOW = "USER_SHOW"
export interface ShowUser {
    type: typeof USER_SHOW
    payload:any
}

//--------single user for my account------------

export const USER_GET = "USER_GET"
export interface UserSingle {
    type: typeof USER_GET
    payload:any
}

export const USER_MYAC_UPDATE = "USER_MYAC_UPDATE"
export interface UserMyAcUpdate {
    type: typeof USER_MYAC_UPDATE
    payload:any
}

export const USER_MYAC_CHANGE_PASSWORD = "USER_MYAC_CHANGE_PASSWORD"
export interface UserMyAcChangePassword {
    type: typeof USER_MYAC_CHANGE_PASSWORD
    payload:any
}

export const USER_MYAC_CHANGE_EMAIL = "USER_MYAC_CHANGE_EMAIL"
export interface UserMyAcChangeEmail {
    type: typeof USER_MYAC_CHANGE_EMAIL
    payload:any
}

