import { Button, Form, Input, InputRef, Select, Spin, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AttributeListType } from "../ProductsAttributes";
import { Loading3QuartersOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import showMessage from "../../../../../shared/MessagesInfo/message";
import openNotification from "../../../../../shared/MessagesInfo/WarningBox";

interface EditableRowProps {
    index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false} name="product-attribute-editable-cell">
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof AttributeListType;
    record: AttributeListType;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeListType[]>>;
    showModal: (attributeObj: { name: string; attribute_id: number }) => void;
    handleSave: (record: AttributeListType, product_attribute_id: number) => void;
    currentCountryName: string;
}

const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, setAttributeList, showModal, handleSave, currentCountryName, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const [loadingRange, setLoadingRange] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const [loadingMultiselect, setLoadingMultiselect] = useState(false);
    const [loadingDeleteMultiselect, setLoadingDeleteMultiselect] = useState(false);
    const inputRef = useRef<InputRef | any>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        const values = await form.validateFields();

        if (record[`value_${currentCountryName}` as keyof typeof record]?.trim() === values[`value_${currentCountryName}` as keyof typeof record]?.trim()) {
            // is new mpn is the same as previous don't call endpoint
            toggleEdit();
            return;
        }

        //console.log(values[`value_${ currentCountryName }`])

        if (values[`value_${currentCountryName}`] === "add-button") {
            toggleEdit();
            return;
        }

        if (record.attribute_type === "range") {
            try {
                if (parseInt(values[`value_${currentCountryName}`]) < parseInt(record.min_value) || parseInt(values[`value_${currentCountryName}`]) > parseInt(record.max_value)) {
                    openNotification(`Value have to be bettwen ${record.min_value} and ${record.max_value}`);
                    return;
                }
                setLoadingRange(true);
                ///////////////////
                const objToSend = record[`value_${currentCountryName}` as keyof typeof record]
                    ? {
                          product_id: record.product_id,
                          type: record.attribute_type,
                          product_attribute_id: record.product_attribute_id,
                          attribute_allowed_value_id: null,
                          value: parseInt(values[`value_${currentCountryName}`]),
                      }
                    : {
                          attribute_id: record.attribute_id,
                          product_id: record.product_id,
                          attribute_allowed_value_id: null,
                          value: parseInt(values[`value_${currentCountryName}`]),
                      };

                const { data } = await axios.post(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/${record[`value_${currentCountryName}` as keyof typeof record] ? "update" : "assign"}-product-attribute`,
                    objToSend
                );
                showMessage(data.message);
                ///////////////
                toggleEdit();
                handleSave({ ...record, ...values }, data.data);
                setLoadingRange(false);
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        }
        if (record.attribute_type !== "range" && record.attribute_type !== "multiselect") {
            try {
                const values = await form.validateFields();
                ////////////////////
                setLoadingSelect(true);
                const findId = record.all_values.find((v) => v.value === values[`value_${currentCountryName}`])?.allowed_value_id;
                const objToSend = record.product_attribute_id
                    ? {
                          product_id: record.product_id,
                          type: record.attribute_type,
                          product_attribute_id: record.product_attribute_id,
                          attribute_allowed_value_id: findId, /////
                          value: null,
                      }
                    : {
                          attribute_id: record.attribute_id,
                          product_id: record.product_id,
                          attribute_allowed_value_id: findId,
                          value: null,
                      };
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/${record.product_attribute_id ? "update" : "assign"}-product-attribute`, objToSend);
                showMessage(data.message);
                ////////////////////

                // console.log(values);
                toggleEdit();
                handleSave(
                    {
                        ...record,
                        // product_attribute_id: data.data,
                        ...(values[`value_${currentCountryName}`] && values[`value_${currentCountryName}`] !== "add-button" && values),
                    },
                    data.data
                );
                setLoadingSelect(false);
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        }
    };

    let childNode = children;

    // record && record.attribute_type === "multiselect" && console.log(record.allowed_ids);
    // record && record.attribute_type === "multiselect" && console.log(record.all_values);

    const onMultiselectChange = async (e: any) => {
        const objToSend = {
            attribute_id: record.attribute_id,
            product_id: record.product_id,
            attribute_allowed_value_id: e,
            value: /*parseInt(values[`value_${currentCountryName}`])*/ null,
        };
        const findName = record.all_values.find((a) => a.allowed_value_id.toString() === e);
        setLoadingMultiselect(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/assign-product-attribute`, objToSend);
            showMessage(data.message);
            setAttributeList((curr) =>
                curr.map((a) =>
                    a.attribute_id === record.attribute_id
                        ? {
                              ...a,
                              allowed_ids: [...a.allowed_ids, Number(e)],
                              allowed_values: [...a.allowed_values, findName?.value],
                          }
                        : a
                )
            );
            setLoadingMultiselect(false);
        } catch (error) {}
    };

    const onMultiselectUnSelect = async (id: any, e: any) => {
        const product_attribute = record.all_obj_data.find((av: any) => av.attribute_allowed_value_id === Number(e.value));

        setLoadingDeleteMultiselect(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-product-attribute`, { product_attribute_id: product_attribute.product_attribute_id });

            setAttributeList((curr) =>
                curr.map((a) =>
                    a.attribute_id === record.attribute_id
                        ? {
                              ...a,
                              allowed_ids: a.allowed_ids.filter((i_d) => i_d !== Number(id)),
                              allowed_values: a.allowed_values.filter((av) => av !== product_attribute.value_rs),
                          }
                        : a
                )
            );
            showMessage(data.message);
            setLoadingDeleteMultiselect(false);
        } catch (err) {
            console.log(err);
        }
    };

    if (editable) {
        childNode = editing ? (
            record.attribute_type === "range" ? (
                <Tooltip placement="topLeft" title={`${record.min_value} - ${record.max_value}`} trigger={["focus"]}>
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            suffix={loadingRange /*|| record.isProductMpnLoading */ ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />}
                        />
                    </Form.Item>{" "}
                </Tooltip>
            ) : record.attribute_type === "multiselect" ? (
                <Form.Item style={{ margin: 0 }} name={"allowed_values"} initialValue={record.allowed_ids.map((id) => id?.toString())}>
                    {/* <Select ref={inputRef} placeholder="mulitlselect"></Select> */}
                    <Select
                        ref={inputRef}
                        showSearch
                        placeholder="Multiselect"
                        mode="multiple"
                        tokenSeparators={[","]}
                        loading={loadingMultiselect || loadingDeleteMultiselect}
                        onBlur={toggleEdit}
                        value={record.allowed_ids.map((id) => id?.toString())}
                        defaultOpen
                        //onPressEnter={save}

                        //onBlur={save}
                        // defaultValue={
                        //     record.allowed_ids.length > 0 ? record.allowed_ids.map((id) => id?.toString()) : undefined
                        // }
                        onSelect={onMultiselectChange}
                        onDeselect={(e, test) => onMultiselectUnSelect(e, test)}
                        style={{ width: "100%" }}
                        optionLabelProp="label"
                        filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={record.all_values.map((v) => ({
                            value: v.allowed_value_id?.toString(),
                            label: v.value,
                        }))}
                    />
                </Form.Item>
            ) : (
                <Form.Item style={{ padding: 0, margin: 0 }} name={dataIndex}>
                    <Select
                        ref={inputRef}
                        onChange={save}
                        onBlur={save}
                        autoFocus
                        defaultOpen
                        showSearch
                        //allowClear
                        filterOption={(input: string, option?: { label: string; value: number }) => (option?.value.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
                        style={{ padding: 0, margin: 0 }}
                        loading={loadingSelect}
                    >
                        {record?.all_values?.map((v) => (
                            <Select.Option key={v.allowed_value_id} value={v.value}>
                                {v.value}
                            </Select.Option>
                        ))}
                        <Select.Option
                            value={"add-button"}
                            children={
                                <Button
                                    size="small"
                                    onClick={() =>
                                        showModal({
                                            name: record[`attribute_name_${currentCountryName}` as keyof typeof record],
                                            attribute_id: record.attribute_id,
                                        })
                                    }
                                    icon={<PlusOutlined />}
                                >
                                    Add New
                                </Button>
                            }
                        />
                    </Select>
                </Form.Item>
            )
        ) : record.attribute_type === "multiselect" ? (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, borderRadius: "5px" }} onClick={!loadingRange && !loadingSelect && !loadingMultiselect ? toggleEdit : undefined}>
                {record.allowed_values.length > 0 ? record.allowed_values.join(", ") : "-"}
            </div>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, borderRadius: "5px" }} onClick={!loadingRange && !loadingSelect && !loadingMultiselect ? toggleEdit : undefined}>
                {record && record[`value_${currentCountryName}` as keyof AttributeListType] ? children : "-"}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
