import React, { useState, useEffect} from 'react'
import { Form, Select, Popover, Button, Checkbox, Divider  } from 'antd'
import { getAllMainMap, shopMapAddNewField } from '../../../actions/maps/mapsAction'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'
import InputE from '../../../shared/FormElements/Input'
import ButtonE from '../../../shared/FormElements/Button'
import { RootStore } from '../../../Store'


interface NewShopMapFieldProps {
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  shopState: RootStore 
  shopId: number | undefined
  showModal: () => void
  getShopMap: (newField: boolean) => void
}

const NewShopMapField:React.FC<NewShopMapFieldProps> = ({ isModalVisible,  setIsModalVisible, shopState, shopId, showModal, getShopMap }) => {
  const [ allMainKey, setAllMainKey ] = useState<any>([])
  const [ allId, setAllId ] = useState<any>([])
  const [ form ] = useForm()
  const dispatch = useDispatch()
  const mainMapState = useSelector((state:any)=>state.mainMap)
  //-----------------?????????????????????? to many re-renders
  useEffect(() => {
    if ( isModalVisible === true ) {
      dispatch(getAllMainMap())
      
    }
  }, [ dispatch, isModalVisible ])

  useEffect(() => {
      if ( mainMapState.data ) {
        const shopKeyAll = mainMapState.data && mainMapState.data.filter((shop:any) =>  {
          if( !allId.includes(shop.id)){
            return shop
          }
          return null
        })
        setAllMainKey(shopKeyAll)
      }
  }, [  mainMapState.data, allId ])

  const addNewField = (e:any) => {
    
    const objectToSend = {...e, shop_id:shopId}
    dispatch(shopMapAddNewField(objectToSend, setAllId, setIsModalVisible))
    //createNewShopField(objectToSend)
    setIsModalVisible(false)
    form.resetFields()
    //message.warning('After saving new main key item, page have to be refreshed!', 15);
    getShopMap(true)
  }
  useEffect(()=>{
    const shopKeyAll = shopState.shopMap.data.map((shop_key:any)=>{ /*console.log(shop_key.main_key_id);*/ return shop_key.main_key_id})
    setAllId(shopKeyAll)
  }, [ shopState.shopMap.data ])
  const handleVisibleChange = (newVisible: boolean) => {
    setIsModalVisible(newVisible)
  }
  return (
    <Popover
      title="Add New Field"
      trigger="click"
      placement='bottomRight'
      open={isModalVisible}
      onOpenChange={handleVisibleChange}
      style={{ width:'400px!important' }}
      content={   
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        form={ form }
        onFinish={addNewField}
      >
        <Form.Item label="Main key" name="main_key_id" style={{ width:'300px'}} rules={[{ required: true, message: 'Missing Main Key' }]}>
          <Select  
            style={{ width: 420}} 
            placeholder="Choose main key" 
            showSearch 
            optionFilterProp="children"
            filterOption={(input, option:any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={ allMainKey &&  allMainKey.map((mainKey:any)=>({ value: mainKey.id, label: mainKey.key_name})) }
          />
        </Form.Item>
        <InputE 
          label="Key" 
          name="key"
          rules={[{ required: true, message: 'Missing key' }]}
        />
        <InputE 
          label="Tag Delimiter" 
          name="tag_delimiter"
          rules={[{ message: 'Missing key' }]}
        />
        <Form.Item name="multiple_tags" label="Multiple Tags" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Divider/>
        <ButtonE
          type="primary" 
          htmlType="submit" 
          title="Add new field"
          wrapperCol={{ offset: 8, span: 16 }}
        />
      </Form>}
    >
      <Button 
        style={{ position:'absolute', right:'35px', marginTop:'3px', color:'black', backgroundColor:'lightgray' }} 
        type="default"  
        size="small" 
        onClick={showModal}
      >
        Add new field
      </Button> 
     </Popover>
  )
}

export default NewShopMapField
