import { Descriptions } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../actions/userAction/userAction'
import { RootStore } from '../../../Store'
import '../style/UserDateReportInfo.css'

const UserDateReportInfo = () => {
  const dispatch = useDispatch()
  const userMyAcState = useSelector((state: RootStore) => state.user)
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])
  return (
    <div style={{ width:'300px', marginTop:'3rem'}}>
      {userMyAcState.data && <div className="user-date-description"><Descriptions  bordered size='small'>
        <Descriptions.Item label="Operater" style={{backgroundColor:'white'}}><span className="report-name-date-info" >{ `${userMyAcState.data.first_name} ${userMyAcState.data.last_name}` }</span></Descriptions.Item>
      </Descriptions>
      <Descriptions bordered size='small' style={{marginTop:'3px'}}>
        <Descriptions.Item label="Date" style={{backgroundColor:'white'}}><span className="report-name-date-info">{ `${moment(new Date()).format('DD MMM YYYY')} ${ moment(new Date()).format('HH:mm') }` }</span></Descriptions.Item>
      </Descriptions>
      </div>
      }
    </div>
  )
}

export default UserDateReportInfo