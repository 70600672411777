import React from "react";
import { Button, Col, Divider, Row, Select, Table, Layout } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import numberWithCommas from "../../../shared/utils/numberUtils";
import "../style/PricesDifference.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllShops } from "../../../actions/shopAction/shopAction";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import Spinner from "../../../shared/Spinner/Spinner";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import PricesDifferenceModal from "../components/PricesDifference/PricesDifferenceModal";
import "../style/PricesDifference.css";
import { ColumnType } from "antd/es/table";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
const { Header } = Layout;
const { Option } = Select;

export type ShopType = {
    id: number;
    name: string;
    link: string;
};

export type AllPricesListType = {
    id: number;
    price: number;
    product_image: string;
    product_model: string;
    product_name: string;
    product_url: string;
    seller_image: string;
    shop_name: string;
    percentage: number;
    detachLoading: boolean;
    isChecked: boolean;
};

export type PricesDifferenceType = {
    difference: number;
    max: number;
    min: number;
    percent: string;
    product_id: number;
    product_link: string;
    product_name: string;
};

const PricesDifference = () => {
    const [pricesDifference, setPricesDifference] = useState<PricesDifferenceType[]>([]);
    const [shop, setShop] = useState<ShopType | null>(null);
    const [product, setProduct] = useState<{ id: number | null; urlProduct: string }>({ id: null, urlProduct: "" });
    const [loading, setLoading] = useState(false);
    const [loadingGetPrices, setLoadingGetPrices] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [allPircesList, setAllPricesList] = useState<AllPricesListType[]>([]);
    const [percentage, setPercentage] = useState(0);
    const { getColumnSearchProps } = useSearchState(pricesDifference);
    const stateR = useSelector((state: RootStore) => state);
    const currentCountryId = stateR.dataLs.country ? stateR.dataLs.country.countryId : 1;
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    useEffect(() => {
        dispatch(getAllShops(0, currentCountryId));
    }, [dispatch, currentCountryId]);

    useEffect(() => {
        setLoading(true);
        const getPricesDifference = async () => {
            try {
                const { data }: { data: { data: PricesDifferenceType[] } } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/prices/show-prices-difference?country_id=${currentCountryId}${
                        shop?.id ? `&shop_id=${shop?.id}` : ""
                    }${percentage ? `&percentage=${percentage}` : ""}`
                );
                const sortedArray = data.data
                    .map((p) => ({ ...p, min: Number(p.min), max: Number(p.max), difference: Number(p.difference) }))
                    .sort((a, b) => (b.max / b.min) * 100 - (a.max / a.min) * 100);
                setPricesDifference(sortedArray);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        getPricesDifference();
    }, [currentCountryId, shop, percentage]);

    const getAllPricesList = async (record: PricesDifferenceType) => {
        setAllPricesList([]);
        setLoadingGetPrices(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/product/item/get-active-product-prices?product_id=${record.product_id}&country_id=${currentCountryId}`
            );
            setProduct({ id: record.product_id, urlProduct: record.product_link });
            setAllPricesList(
                data.data
                    .map((p: AllPricesListType) => ({
                        ...p,
                        price: Number(p.price),
                        percentage: 0,
                        detachLoading: false,
                        isChecked: false,
                    }))
                    .sort((a: AllPricesListType, b: AllPricesListType) => a.price - b.price)
            );
            setLoadingGetPrices(false);
            setIsModalVisible(true);
        } catch (err) {
            console.log(err);
            setLoadingGetPrices(false);
        }
    };

    const changePercentage = (value: number) => {
        setPercentage(value);
    };

    const columns: ColumnType<PricesDifferenceType>[] = [
        {
            title: "Id",
            dataIndex: "product_id",
            key: "product_id",
            className: "pd-cell-center",
            width: "5%",
            align: "center" as "center",
            ...getColumnSearchProps("product_id"),
        },
        {
            title: "Product name",
            dataIndex: "product_name",
            key: "product_name",
            width: "22%",
            ...getColumnSearchProps("product_name"),
        },
        {
            title: "Price Range",
            dataIndex: "max",
            key: "max",
            className: "pd-cell-center",
            width: "10%",
            align: "center" as "center",
            render: (_, record) =>
                `${numberWithCommas(String(Math.trunc(record.min)).replace(".", ","))} - ${numberWithCommas(
                    String(Math.trunc(record.max)).replace(".", ",")
                )}`,
        },
        {
            title: "Procent",
            dataIndex: "percent",
            key: "percent",
            className: "pd-cell-center",
            width: "10%",
            align: "center" as "center",
            render: (record: number) => <span>{Number(record)} %</span>,
            sorter: (a, b) => a.max / a.min - b.max / b.min,
        },
        {
            title: "Action",
            dataIndex: "product_id",
            key: "product_id",
            width: "10%",
            className: "pd-cell-center",
            align: "center" as "center",
            render: (_, record) => (
                <Button size="small" style={{ margin: "1px 0" }} type="dashed" onClick={() => getAllPricesList(record)}>
                    See prices
                </Button>
            ),
        },
    ];

    const onShopChange = (value: number) => {
        const findShop = stateR.shops.data.find((item: { id: number }) => item.id === value);
        if (value) {
            setShop({
                id: value,
                link: findShop.homepage_url,
                name: findShop.name,
            });
        }
    };

    const resetSelectedShop = () => {
        setShop(null);
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Prices difference" />
            <Row justify="center">
                <Col
                    span={windowSize.width > 1700 ? 18 : 23}
                    style={{ backgroundColor: "white", padding: "1rem", marginTop: "1rem" }}
                >
                    <Divider>Prices Difference</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center" }}>
                        <Select
                            style={{ width: "250px" }}
                            showSearch
                            allowClear
                            placeholder="Select shop"
                            onChange={onShopChange}
                            key={"shop_select"}
                            //value={shopID}
                            onClear={resetSelectedShop}
                            filterOption={(input, option: DefaultOptionType | BaseOptionType | undefined) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {stateR.shops?.data &&
                                stateR.shops?.data.map(
                                    (item: { id: number; name: string; last_generated_feed: boolean }, index: number) =>
                                        item.last_generated_feed && (
                                            <Select.Option value={item.id} key={"category_seller_" + item.id}>
                                                {item.name}
                                            </Select.Option>
                                        )
                                )}
                        </Select>
                        <Select
                            defaultValue={percentage}
                            style={{ width: "70px", marginLeft: "1rem" }}
                            onChange={changePercentage}
                        >
                            <Option value={0}>-</Option>
                            <Option value={50}>50%</Option>
                            <Option value={75}>75%</Option>
                        </Select>
                    </div>
                </Col>
                <Col span={windowSize.width > 1700 ? 18 : 23} style={{ backgroundColor: "white", minHeight: "75vh" }}>
                    <PricesDifferenceModal
                        isModalVisible={isModalVisible}
                        allPircesList={allPircesList}
                        setAllPricesList={setAllPricesList}
                        product={product}
                        shop={shop}
                        currentCountryId={currentCountryId}
                        setPricesDifference={setPricesDifference}
                        setProduct={setProduct}
                        loadingGetPrices={loadingGetPrices}
                        setIsModalVisible={setIsModalVisible}
                    />
                    {!loading ? (
                        <div>
                            {
                                <Layout style={{ margin: "0.6rem 1rem", backgroundColor: "white" }}>
                                    {pricesDifference.length > 0 && (
                                        <Header
                                            className="product-img-header"
                                            style={{
                                                color: "whitesmoke",
                                                backgroundColor: "#5a5a5a",
                                                height: "1.9rem",
                                                lineHeight: "30px",
                                            }}
                                        >
                                            <span style={{ position: "relative", right: "1.4rem" }}>
                                                {`List of Prices Difference for ${
                                                    shop?.name ? shop?.name : " all shops"
                                                }`}
                                            </span>
                                        </Header>
                                    )}
                                    <Table
                                        bordered
                                        columns={columns}
                                        dataSource={pricesDifference}
                                        rowKey={(record) => record.product_id}
                                        className="price-difference-cell"
                                        pagination={{ pageSize: 100, showSizeChanger: false, size: "small" }}
                                        scroll={{ y: "61vh" }}
                                    />
                                </Layout>
                            }
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PricesDifference;
