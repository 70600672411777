import React from 'react'
import { Modal, Form, Input, Select} from 'antd'
import ButtonE from '../../../shared/FormElements/Button'
import { useDispatch } from 'react-redux'
import { mainMapAddField } from '../../../actions/maps/mapsAction'


const NewFieldMainMapModal:React.FC<any> = ({ isModalVisible, handleCancel,  options, setOptions, setIsModalVisible }) => {
    const { Option } = Select
    const dispatch = useDispatch()
    const [ form ] = Form.useForm()
    const addNewField = async (e:any) => {
        const objectToSend = { ...e, required: e.required === "true" ? true : false }
        dispatch(mainMapAddField ( objectToSend, options, setOptions, setIsModalVisible, form ))
    }
    return (
        <Modal title="Add new field to main map" open={isModalVisible} onCancel={()=>{handleCancel(); form.resetFields()}} footer={null}>
            <Form
                form={ form }
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={addNewField}
                initialValues={{ required:"true", key_type:"string" }}
               
            >
                <Form.Item label="Key name" name="key_name" rules={[{ required: true, message: 'Missing key name' }]}>
                <Input />
                </Form.Item>
                <Form.Item label="Key type" name="key_type" rules={[{ required: true, message: 'Missing key type' }]}>
                    <Select>
                        <Option value="boolean">boolean</Option>
                        <Option value="string">string</Option>
                        <Option value="integer">integer</Option>
                        <Option value="float">float</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="required" label="Required" rules={[{ required: true, message: 'Missing required' }]}>
                    <Select>
                        <Option value="true">Yes</Option>
                        <Option value="false">No</Option>
                    </Select>
                </Form.Item>
                <ButtonE 
                    type="primary" 
                    htmlType="submit" 
                    title="Add new field"
                    wrapperCol={{ offset: 8, span: 16 }}
                />
               {/* <p style={{color:'red'}}>{err!==''? err:''}</p>*/}
            </Form>
        </Modal>
    )
}

export default NewFieldMainMapModal
