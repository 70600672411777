import YouTube from "react-youtube";

const YoutubeBox = (props: { youtube_url: string }) => {
    const { youtube_url } = props;
    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#B3C8CF",
                border: "1px solid white",
                paddingTop: "4px",
                borderRadius: "5px",
                margin: "0 1rem",
            }}
        >
            {youtube_url ? <YouTube videoId={youtube_url} opts={{ height: "240px", width: "100%" }} /> : <div>No Url</div>}
        </div>
    );
};

export default YoutubeBox;
