
import { Table, Divider, Statistic, Checkbox, Skeleton } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import numberWithCommas from "../../../shared/utils/numberUtils";
import '../style/UncategorizedProducts.css'

const COLORS = [ "#680747","#765285","#975a5e","#c51350",'#cd4545',"#c24d2c","#347474","#35495e" ,"#005689",'#394a51']
let initialSum = 0
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${numberWithCommas(value)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
interface UPProps {
  currentCountryId:number
  sfActive:number
}

const  UncategorizedProduct:React.FC<UPProps> = ({ currentCountryId, sfActive }) => {
  const [ uncategorizedProducts, setUncategorizedProducts ] = useState([])
  const [ activeIndex, setActiveIndex ] = useState(0);
  const [ onlyXml, setOnlyXml ] = useState(1)
  const [ loading, setLoading ] = useState(false)
  const { getColumnSearchProps } = useSearchState(uncategorizedProducts)
  const onXMLCheckChange = (e:CheckboxChangeEvent) => {
    if(e.target.checked) {
      setOnlyXml(1)
    }
    else {
      setOnlyXml(0)
    }
  }
  const onPieEnter = useCallback(
    (_:string, index:number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  useEffect(()=>{
      const getUncategorizedProducts = async () => {
        setLoading(true)
        try {
          const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/logger/list-uncategorized-products?country_id=${ currentCountryId }&active=${ sfActive }&xml=${ onlyXml }`)
          const newData = data.data.sort((a:{unpaired:number},b:{unpaired:number}) => b.unpaired - a.unpaired ).map((item:any)=>{
            return {
              ...item,
              value:item.unpaired
            }
          })
          setUncategorizedProducts(newData)
          setLoading(false)
        } catch ( err ) {
          console.log(err)
        }
      }
      getUncategorizedProducts()
     },[ currentCountryId, sfActive, onlyXml ])

     const tableColumns:any = [
   
      {
        title:'Shop',
        dataIndex:'name',
        key:'name',
        width:'70%',
        ...getColumnSearchProps('name')
      },
      {
        title:'Unpaired',
        dataIndex:'unpaired',
        width:'15%',
        align: 'center' as 'center',
        key:'unpaired',
        sorter: (a:{id:number, unpaired:number, paired:number}, b:{id:number,unpaired:number, paired:number}) => a.unpaired - b.unpaired,
        render:(record:number) => numberWithCommas(record)
      },
      {
        title:'paired',
        dataIndex:'paired',
        width:'15%',
        align: 'center' as 'center',
        key:'paired',
        sorter: (a:{id:number, paired:number}, b:{id:number,paired:number}) => {return a.paired - b.paired},
        render:(record:number) => numberWithCommas(record)
      }
    ]
  
  return (
    <div className="uncategorized-container" style={{ marginBottom:'3rem' }}>
       <div style={{width:'80%', margin:'0 auto'}}>
      <Divider>Uncategorized products</Divider>
      </div>
      <div style={{width:'80%', margin:'0 auto'}}>
        {/* {uncategorizedProducts.length > 0 ?  */}
        <Statistic
          className="uncategorized-products-info"
          title="Unpaired"
          value={ !loading ?  numberWithCommas(String(  uncategorizedProducts.reduce(function (previousValue, currentValue:{unpaired:number}) {
            return previousValue + currentValue.unpaired
          }, initialSum)).replace('.', ',')):'...'}
          //precision={2}
          valueStyle={{ color: '#da5151' }}
          //prefix={<ArrowUpOutlined />}
          //suffix="%"
        />  {/*:<Skeleton.Button active  style={{ width:'98px', height:'65px',position:"relative", bottom:'1.2rem'}}  block /> */}
        {!loading ? <Checkbox onChange={onXMLCheckChange} defaultChecked={onlyXml === 1 ? true:false} className="up-checkbox-xml-only" >{onlyXml === 1 ?  'xml':'all'}</Checkbox>:''}
      </div>
      <div style={{ display:'flex', justifyContent:'space-around',width:'100%', marginTop:'2rem'}}>
      {!loading ? <PieChart width={480} height={402}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={uncategorizedProducts.slice(0,10)}
            cx={230}
            cy={200}
            innerRadius={60}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {uncategorizedProducts.map((entry:any, index:any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>:
        <div style={{ width:'480px'/*, height:'402px',marginRight:'8.2rem'*/ }}>
          <Skeleton.Avatar   shape='circle' size="large" active  style={{ margin:'0 1rem', width:'200px', height:'200px', marginLeft:'8.2rem', marginTop:'8rem'}} />
          </div>
          }
        {!loading ? <Table
          bordered
          dataSource={ uncategorizedProducts }
          columns={tableColumns}
          className="uncategorized-products-table"
          rowKey={ ( record:{id:number} ) => record &&  record.id }
          pagination={{ size:'small', showSizeChanger:false}}
        />: <Skeleton active paragraph={{rows:10}} title={{width:'100%'}} className="uncategorized-products-table" style={{ minWidth:'200px', border:'none', marginLeft:'8rem', position:'relative', top:'2rem'}} /> }
      </div>
    </div>
  )
}

export default UncategorizedProduct