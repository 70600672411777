import React from "react";
import { List } from "antd";

interface ExsistingAttributesProps {
    loading: boolean;
    attributesByGroup: {
        group_id: number;
        group_name: string;
        data: {
            group_id: number;
            [key: string]: string | number;
        }[];
    }[];
    currentCountryName: string;
}

const ExsistingAttributes: React.FC<ExsistingAttributesProps> = ({ loading, attributesByGroup, currentCountryName }) => {
    return !loading ? (
        <div>
            {attributesByGroup.map((a) => (
                <List
                    key={a.group_id}
                    size="small"
                    style={{ marginTop: "0.3rem", backgroundColor: "white" }}
                    className="exsisting-attributes-list"
                    header={
                        <div style={{ textAlign: "center" }}>
                            <b>{a.group_name}</b>
                        </div>
                    }
                    bordered
                    dataSource={a.data}
                    renderItem={(item) => <List.Item>{item[`name_${currentCountryName}`]}</List.Item>}
                />
            ))}
        </div>
    ) : null;
};

export default ExsistingAttributes;
