import { Dispatch } from 'react'
import { GET_ALL_COUNTRIES, GetAllCountries } from './countriesActionType'
import axios from 'axios'
export const getAllCountries = () => async (dispatch:Dispatch<GetAllCountries>) => {
    try {
        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/product/country/datatok`)
        //setCompanies(data.data)
        //setMessage(data.message)
        dispatch({type:GET_ALL_COUNTRIES , payload:data.data})

    } catch ( err ) {
        console.log( err )
    }
}
