import { FormInstance } from "antd";
import { ProductType } from "../types/types";
import { KeyEventsParam } from "../types/keyEvents";

// handler for Column Name
// Arrow Up or Arrow down
export const handler =
    (idx: number, form: FormInstance, products: ProductType[], setProducts: KeyEventsParam, inputRef: any) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowDown" && idx < 99) {
            e.preventDefault();
            const newName = e.target as HTMLInputElement;
            const findIsMatched = products.find((d, i) => i === idx + 1 && d.new_prodcut_id);
            form.setFieldValue("name", products[idx + 1][findIsMatched ? "newName" : "name"]);
            setProducts((curr) =>
                curr.map((d, i) =>
                    i === idx
                        ? { ...d, [findIsMatched ? "newName" : "name"]: newName.value, openName: false }
                        : i === idx + 1
                        ? { ...d, openName: true }
                        : d
                )
            ); //({ ...d, openName: i === idx + 1 ? true : false })
            setTimeout(() => {
                const next = inputRef.current[idx + 1];
                if (next) {
                    next.focus();
                }
            }, 20);
        }
        if (e.key === "ArrowUp" && idx > 0) {
            e.preventDefault();
            //setDataSource((curr) => curr.map((d, i) => ({ ...d, openName: i === idx - 1 ? true : false })));
            const newName = e.target as HTMLInputElement;
            const findIsMatched = products.find((d, i) => i === idx - 1 && d.new_prodcut_id);
            form.setFieldValue("name", products[idx - 1][findIsMatched ? "newName" : "name"]);
            setProducts((curr) =>
                curr.map((d, i) =>
                    i === idx
                        ? { ...d, [findIsMatched ? "newName" : "name"]: newName.value, openName: false }
                        : i === idx - 1
                        ? { ...d, openName: true }
                        : d
                )
            );
            setTimeout(() => {
                const previous = inputRef.current[idx - 1];
                if (previous) {
                    previous.focus();
                    setTimeout(() => {
                        previous.input.setSelectionRange(previous.input.value.length, previous.input.value.length);
                    }, 1);
                }
            }, 20);
        }
    };
// handler for Column Mpn
// Arrow Up or Arrow down

export const handlerMpn =
    (idx: number, form: FormInstance, products: ProductType[], setProducts: KeyEventsParam, inputRef: any) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowDown" && idx < 99) {
            e.preventDefault();
            const newMpn = e.target as HTMLInputElement;
            const findIsMatched = products.find((d, i) => i === idx + 1 && d.new_prodcut_id);
            form.setFieldValue("mpn", products[idx + 1][findIsMatched ? "newMpn" : "mpn"]);
            setProducts((curr) =>
                curr.map((d, i) =>
                    i === idx
                        ? { ...d, [findIsMatched ? "newMpn" : "mpn"]: newMpn.value, openMpn: false }
                        : i === idx + 1
                        ? { ...d, openMpn: true }
                        : d
                )
            );
            setTimeout(() => {
                const next = inputRef.current[idx + 1];
                if (next) {
                    next.focus();
                }
            }, 20);
        }
        if (e.key === "ArrowUp" && idx > 0) {
            e.preventDefault();
            const newMpn = e.target as HTMLInputElement;
            const findIsMatched = products.find((d, i) => i === idx - 1 && d.new_prodcut_id);
            form.setFieldValue("mpn", products[idx - 1][findIsMatched ? "newMpn" : "mpn"]);
            setProducts((curr) =>
                curr.map((d, i) =>
                    i === idx
                        ? { ...d, [findIsMatched ? "newMpn" : "mpn"]: newMpn.value, openMpn: false }
                        : i === idx - 1
                        ? { ...d, openMpn: true }
                        : d
                )
            );
            setTimeout(() => {
                const previous = inputRef.current[idx - 1];
                if (previous) {
                    previous.focus();
                    setTimeout(() => {
                        previous.input.setSelectionRange(previous.input.value.length, previous.input.value.length);
                    }, 1);
                }
            }, 20);
        }
    };
