import { DeleteTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { Checkbox, Drawer, Popconfirm, Select, Spin, Tag, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
//import showMessage from '../../../../shared/MessagesInfo/message';
import { KeyWordsType, MappedCategoriesType, PredictedPrices, ShopType, Uncategorized } from "../../pages/MatchingSellerCategory";
import "../../style/MatchingSellerCategory.css";

const { Option } = Select;

interface ExplandRowItem {
    record: MappedCategoriesType;
    keywords: KeyWordsType[] | undefined;
    stateR: any;
    mappedCategories: MappedCategoriesType[];
    setMappedCategories: React.Dispatch<React.SetStateAction<MappedCategoriesType[] | any>>;
    keyWords: { id: number; name: string }[];
    shop: ShopType | null;
    currentCountryName: string;
    uuidv4: any;
    predictedPrices: PredictedPrices[];
    setPredictedPrices: React.Dispatch<React.SetStateAction<PredictedPrices[]>>;
    getPredictedPrices: (shop_id: number | undefined, record: MappedCategoriesType, allowed: any, forbidden: any, indexCategorized: number, typeOr: any) => Promise<void>;
}

const ExpandRowItem: React.FC<ExplandRowItem> = ({
    record,
    keywords,
    stateR,
    mappedCategories,
    setMappedCategories,
    keyWords,
    shop,
    currentCountryName,
    uuidv4,
    predictedPrices,
    setPredictedPrices,
    getPredictedPrices,
}) => {
    const [options, setOptions] = useState<React.ReactNode[]>([]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const children: React.ReactNode[] = [];
        keyWords.forEach((key) => children.push(<Option key={key.name}>{key.name}</Option>));
        setOptions(children);
    }, [keyWords]);

    const handleChange = (value: any, item: any, record: MappedCategoriesType, type: string, index: number) => {
        const newMappedCategories = mappedCategories.map((data) => {
            if (data.name === record.name) {
                return {
                    ...data,
                    keywords: data.keywords.map((key) => {
                        if (key.id === item.id) {
                            return {
                                ...key,
                                [`${type}`]: value,
                            };
                        }
                        return key;
                    }),
                };
            }
            return data;
        });
        setMappedCategories(newMappedCategories);
        /////////////////
        mappedCategories.forEach((data) => {
            if (data.name === record.name) {
                data.keywords.forEach((key) => {
                    if (key.id === item.id) {
                        if (type === "allowed") {
                            getPredictedPrices(shop?.id, record, value, item.forbidden, index, { allowed_or: key.allowed_or, forbidden_or: key.forbidden_or });
                        } else {
                            getPredictedPrices(shop?.id, record, item.allowed, value, index, { forbidden_or: key.forbidden_or, allowed_or: key.allowed_or });
                        }
                    }
                });
            }
        });
    };

    const setCategoryToKeywords = (e: any, record: any, item: any) => {
        const newMappedCategories = mappedCategories.map((data) => {
            if (data.name === record.name) {
                return {
                    ...data,
                    keywords: data.keywords.map((key) => {
                        if (key.id === item.id) {
                            return {
                                ...key,
                                category_l3_id: e,
                            };
                        }
                        return key;
                    }),
                };
            }
            return data;
        });
        setMappedCategories(newMappedCategories);
    };

    const addNewKeyForm = (record: MappedCategoriesType) => {
        const indexData = record.keywords.length;
        if (record) {
            const newMappedCategories =
                mappedCategories.length > 0 &&
                mappedCategories.map((data: any) => {
                    if (data.name === record.name) {
                        return {
                            ...data,
                            keywords: [...data.keywords, { id: uuidv4(), category_l3_id: null, allowed: [], forbidden: [], allowed_or: false, forbidden_or: false }],
                            selectUncategorizedArr: { ...data.selectUncategorizedArr, [indexData]: [] }, //data.selectUncategorizedArr.splice(indexData, 0, 'wednesday')
                        };
                    }
                    return data;
                });
            setMappedCategories(newMappedCategories);
        }
    };

    const removeKeywordsForm = (record: any, indexData: any) => {
        //console.log(indexData)
        const newMappedCategories =
            mappedCategories.length > 0 &&
            mappedCategories.map((data) => {
                if (data.name === record.name) {
                    delete data.selectUncategorizedArr[indexData];
                    return {
                        ...data,
                        keywords: data.keywords.filter((item, index) => index !== indexData),
                        //selectUncategorizedArr:data.selectUncategorizedArr                                        //.filter((item, index) => index!==indexData)
                    };
                }
                return data;
            });
        setMappedCategories(newMappedCategories);
    };

    const showPredictedPrices = (record: MappedCategoriesType, keyIndex: number) => {
        setVisible(true);
        const newData = mappedCategories.find((data) => data.name === record.name);
        const filteredData: Uncategorized[] | any = newData?.uncategorized.filter((item) => newData.selectUncategorizedArr[keyIndex].includes(item.id));
        setPredictedPrices(filteredData);
    };

    const onClose = () => {
        setVisible(false);
    };

    const setAllowedOr = (e: CheckboxChangeEvent, record: MappedCategoriesType, id: number, item: any, index: number) => {
        setMappedCategories(() =>
            mappedCategories.map((data) => {
                return {
                    ...data,
                    keywords: data.keywords.map((kw) => {
                        if (kw.id === id) {
                            return {
                                ...kw,
                                allowed_or: e.target.checked,
                            };
                        }
                        return kw;
                    }),
                };
            })
        );

        ////////////////////////////////

        mappedCategories.forEach((data) => {
            if (data.name === record.name) {
                data.keywords.forEach((key) => {
                    if (key.id === item.id) {
                        getPredictedPrices(shop?.id, record, item.allowed, item.forbidden, index, { allowed_or: e.target.checked, forbidden_or: key.forbidden_or });
                    }
                });
            }
        });
    };

    // const setForbiddenOr = (e:CheckboxChangeEvent, record:MappedCategoriesType, id:number, item:any, index:number) => {
    //     setMappedCategories( () => mappedCategories.map( data => {
    //         return {
    //             ...data,
    //             keywords:data.keywords.map( kw => {
    //                 if( kw.id === id) {
    //                     return {
    //                         ...kw,
    //                         forbidden_or:e.target.checked
    //                     }
    //                 }
    //                 return kw
    //             } )
    //         }
    //     }))

    //     ////////////////////////////////

    //     mappedCategories.forEach( data => {
    //         if (data.name === record.name) {
    //             data.keywords.forEach( key => {
    //                 if (key.id === item.id){
    //                     getPredictedPrices (shop?.id, record, item.allowed, item.forbidden, index,{forbidden_or: e.target.checked, allowed_or:key.allowed_or })

    //                 }

    //             })

    //         }
    //     })
    // }

    return (
        <div
            style={{
                borderRadius: "3px",
                height: "100%",
                width: "760px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "whitesmoke",
                paddingTop: "2rem",
                paddingLeft: "0rem",
                paddingRight: "0rem",
                border: "1px solid lightgray",
            }}
        >
            {record.isKeyWordLoading && <Spin style={{ position: "absolute", top: "15px" }} />}
            <Drawer title="Matched Prices" placement="right" className="matching-sc-drawer" onClose={onClose} open={visible}>
                <div style={{ width: "280px", overflowY: "auto", border: "1px solid lightgray", borderRadius: "4px", padding: "0.6rem", backgroundColor: "#fafafa" }}>
                    {predictedPrices.length > 0 ? (
                        predictedPrices.slice(0, 1000).map((data: any) => (
                            <div key={data.id}>
                                <img src={`https://img.ep-cdn.com/i/160/160/${data.default_image_url}.webp`} alt="uncategorized-item" width={50} />
                                <br />
                                <b>Name:</b>
                                <span> {data[`name_${currentCountryName}`]}</span>
                                <br />
                                <b>Seller Category:</b>
                                <span>{data.seller_category}</span>
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p style={{ textAlign: "center" }}>No data</p>
                    )}
                </div>
            </Drawer>

            {keywords &&
                keywords.length > 0 &&
                keywords.map((item, index) => {
                    return (
                        <div className="match-seller-category-expand-row" key={index}>
                            <Popconfirm title={<div>Are you sure?</div>} onConfirm={() => removeKeywordsForm(record, index)}>
                                <DeleteTwoTone style={{ position: "relative", left: "98.4%", bottom: "1.4rem", color: "#2470a0", fontSize: "14px" }} />
                            </Popconfirm>
                            {/* <SmallDashOutlined className='expand-row-shop-matched-prices' onClick={() => showPredictedPrices(record, index) } /> */}

                            <Tooltip
                                mouseEnterDelay={item.category_l3_id ? 0.2 : 10}
                                //style={{ display: !item.category_l3_id ? 'none':''  }}
                                title={() => {
                                    const findCategory = stateR.categoryL3L4.data.find((c: any) => c.value === item.category_l3_id);
                                    return findCategory ? `${findCategory.value} : ${findCategory?.label} (${findCategory.category_l2_name})` : "";
                                }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    defaultValue={item.category_l3_id}
                                    value={Number(item.category_l3_id) ? Number(item.category_l3_id) : null}
                                    style={{ width: 300, border: "1px solid lightgray", height: "33px", backgroundColor: "white" }}
                                    key="label"
                                    placeholder="Select category"
                                    bordered={false}
                                    onChange={(e) => setCategoryToKeywords(e, record, item)}
                                    filterOption={(input, option: any) => option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {stateR.categoryL3L4.data.map((category: { value: number; label: string; category_l2_id: number; category_l2_name: string }) => (
                                        <Option
                                            style={{
                                                pointerEvents: record.keywords.filter((kw) => kw.category_l3_id === category.value).length > 0 ? "none" : "",
                                                color: record.keywords.filter((kw) => kw.category_l3_id === category.value).length > 0 ? "lightgray" : "",
                                                display: record.keywords.filter((kw) => kw.category_l3_id === category.value).length > 0 ? "none" : "",
                                            }}
                                            value={category.value}
                                            key={category.value}
                                        >
                                            {/* `${category.value}: ${category.label}`*/}
                                            {
                                                <>
                                                    <span>{`${category.value}: ${category.label} `}</span>
                                                    <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({category.category_l2_name})</span>
                                                </>
                                            }
                                        </Option>
                                    ))}
                                </Select>
                            </Tooltip>
                            <br />
                            <div style={{ position: "relative" }}>
                                <Select
                                    mode="tags"
                                    style={{ width: "300px", color: "green", marginLeft: "5px" }}
                                    onChange={(e) => handleChange(e, item, record, "allowed", index)}
                                    tokenSeparators={[","]}
                                    placeholder="Allowed keywords"
                                    value={item.allowed}
                                    size="small"
                                >
                                    {options}
                                </Select>
                                <Checkbox checked={item.allowed_or} style={{ marginLeft: "4px" }} onChange={(e) => setAllowedOr(e, record, item.id, item, index)}>
                                    {item.allowed_or ? "OR" : "AND"}
                                </Checkbox>
                                <br />
                                <Select
                                    mode="tags"
                                    style={{ width: "300px", marginTop: "0.3rem", color: "red", marginLeft: "5px" }}
                                    onChange={(e) => handleChange(e, item, record, "forbidden", index)}
                                    tokenSeparators={[","]}
                                    value={item.forbidden}
                                    placeholder="Forbidden keywords"
                                    //defaultValue={item.forbidden}
                                    size="small"
                                >
                                    {options}
                                </Select>
                                {/*<Checkbox checked={item.forbidden_or}  style={{ marginLeft:'4px' }} onChange={(e) => setForbiddenOr(e, record, item.id, item, index)} >{ item.forbidden_or ? 'OR':'AND'}</Checkbox>*/}{" "}
                                <br />
                                {record.selectUncategorizedArr[index] && record.selectUncategorizedArr[index].length > 0 && (
                                    <Tag style={{ position: "absolute", right: "-2rem", bottom: "0.1rem", borderRadius: "15%", cursor: "pointer" }} onClick={() => showPredictedPrices(record, index)}>
                                        {record.selectUncategorizedArr[index].length}
                                    </Tag>
                                )}
                            </div>
                        </div>
                    );
                })}

            <PlusCircleFilled
                onClick={() => (record.keywords && record.keywords.filter((keyword) => !keyword.category_l3_id).length === 0 ? addNewKeyForm(record) : {})}
                style={{ fontSize: "26px", marginTop: "0.8rem", marginBottom: "12px", color: "#55c59d" }}
            />
        </div>
    );
};

export default ExpandRowItem;
