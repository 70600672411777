import { Button, Form, FormInstance, Input, Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import { AttributeType, ValuesType } from "../../types/AttributeType";
import { RootStore } from "../../../../Store";

interface TranslationModalProps {
    form: FormInstance<any>;
    translationModal: {
        open: boolean;
        type: "attribute" | "value" | "";
    };
    setTranslationModal: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            type: "attribute" | "value" | "";
        }>
    >;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    setValues: React.Dispatch<
        React.SetStateAction<{
            name: string;
            id: number;
            data: ValuesType[];
            type: string;
        } | null>
    >;
    state: RootStore;
}

const TranslationModal = (props: TranslationModalProps) => {
    const [editTranslateLoaidng, setEditTranslateLoading] = useState(false);
    const { form, translationModal, setTranslationModal, setAttributeList, setValues, state } = props;

    const saveTranslate = async (e: { id: number; [key: string]: string | number }) => {
        setEditTranslateLoading(true);
        if (translationModal.type === "attribute") {
            try {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/update-attribute-translations`,
                    e
                    /*{ ...objToSend, id: translations?.id }*/
                );
                showMessage(data.message);
                setAttributeList((curr) => curr.map((a) => (a.id === e.id ? { ...a, ...e } : a)));
                form.resetFields();
                setEditTranslateLoading(false);
                setTranslationModal({ open: false, type: "" });
            } catch (err) {
                console.log(err);
            }
        } else {
            const attribute_allowed_value_id = e.id;

            const obj: Record<string, string | number> = {};

            state.countries.forEach((c) => {
                obj[`value_${c.name}`] = e[`value_${c.name}`];
            });
            obj[`value_en`] = e.value_en;

            try {
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/update-attribute-value?attribute_allowed_value_id=${attribute_allowed_value_id}`, obj);
                showMessage(data.message);
                form.resetFields();
                // console.log(data);
                setValues((curr) => (curr ? { ...curr, data: curr.data.map((v) => (v.value_id === e.id ? { ...v, ...obj } : v)) } : null));
                setEditTranslateLoading(false);
                setTranslationModal({ open: false, type: "" });
            } catch (err) {
                console.log(err);
            }
        }
    };

    //console.log(translationModal.type);

    return (
        <Modal
            title={translationModal.type === "attribute" ? "Translate" : "Values"}
            open={translationModal.open}
            onCancel={() => {
                setTranslationModal({ open: false, type: "" });
                form.resetFields();
            }}
            footer={false}
        >
            <Form
                form={form}
                onFinish={saveTranslate}
                style={{
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                    padding: "1rem",
                }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                {state.countries.map((c) => (
                    <Form.Item
                        key={c.name}
                        name={`${translationModal.type === "attribute" ? "name" : "value"}_${c.name}`}
                        label={`Name ${c.name.toUpperCase()}`}
                        rules={[{ required: true, message: `${c.name.toUpperCase()} name is required` }]}
                    >
                        <Input placeholder={`${c.name.toUpperCase()} name`} />
                    </Form.Item>
                ))}
                <Form.Item name={`${translationModal.type === "attribute" ? "name" : "value"}_en`} label="EN name" rules={[{ required: true, message: "EN name is required" }]}>
                    <Input placeholder="EN name" />
                </Form.Item>

                <Form.Item name="id" style={{ display: "none" }}>
                    <Input />
                </Form.Item>

                <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }} style={{ margin: "0" }}>
                    <Button type="primary" htmlType="submit" loading={editTranslateLoaidng}>
                        {translationModal.type === "attribute" ? "Edit Translate" : "Edit Values"}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default TranslationModal;
