import React, { useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { InitValueType } from './AllBrandsDescription';
import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';

type DescriptionEditorType = {
    initValue: InitValueType | null
    country: string
    loading: boolean
    setBrandDescriptionState: React.Dispatch<React.SetStateAction<{
        country: string;
        value: string;
        isChanged: boolean
        title: string 
        h1: string
    }[]>>
    clearTextEditor: number
    saveDescription: (clear?: boolean, country?: string) => Promise<void>
    form: FormInstance<any>
}

const DescriptionEditor:React.FC<DescriptionEditorType> = ({ initValue, country, loading, setBrandDescriptionState, clearTextEditor, saveDescription, form }) => {
    const [ editorTextValue, setEditorTextValue ] = useState('') 
    const [ loadingClearEditor, setLoadingClearEditor ] = useState(false)
    const editorInitValue = initValue && initValue[`description_${ country }`]

    useEffect(() => {
        setEditorTextValue( initValue ? initValue[`description_${ country }`] : '')
        form.setFieldValue(`title_${ country }`, initValue ? initValue[`title_${ country }`] :'' )
        form.setFieldValue(`h1_${ country }`, initValue ? initValue[`h1_${ country }`] :'' )
    }, [ clearTextEditor, initValue, country, form ])

    const clearDescription = () => {
        setLoadingClearEditor(true)
        saveDescription(true, country)
            .then(() => {
                setEditorTextValue('')
                setLoadingClearEditor(false)
            })
            .catch( err => {
                console.log( err )
            })
    }

    const handleTitle = ( e: string, type: string ) => {
        setBrandDescriptionState( curr => curr.map( b => (
            {
                ...b, 
                [type]: b.country === country ? e : b[`${type}` as 'title' | 'h1'], 
                isChanged: b.country === country ? true : b.isChanged }
        )))
    }

    return (
        <div className="all-brands-editor" style={{  marginLeft:'0.3rem' }}>
            <Row>
                <Col span={4}>
                    <Typography.Text code style={{ fontSize:"24px" }}>{ country.toUpperCase() }</Typography.Text>
                </Col>
                <Col span={20}>
                    <Form 
                        form={ form }
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        style={{ border:'1px solid lightgray', borderRadius:'8px', backgroundColor:'#f5f9ee', padding:'0 0.5rem 0.5rem 0' }}
                    >
                        <Form.Item name={ `title_${ country }` } style={{ marginBottom:0, marginTop:8 }} label={`Title ${ country.toUpperCase() }`}>
                            <Input 
                                placeholder={ `Title ${ country.toUpperCase() }` } 
                                onBlur={(e) => handleTitle(e.target.value, 'title')}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item name={ `h1_${ country }` } style={{ margin:'0.3rem 0 0 0' }} label={`Custom H1 ${ country.toUpperCase() }`}>
                            <Input 
                                placeholder={ `Custom H1 ${ country.toUpperCase() }` } 
                                onBlur={(e) => handleTitle(e.target.value, 'h1')} 
                                allowClear
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div style={{ margin:'1rem 0' }}>
                <Editor 
                    initialValue={  editorInitValue ? editorInitValue : '' }
                    value={editorTextValue}
                    init={{
                        plugins: 'emoticons image link lists charmap table code',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright |emoticons | image | link | lists | charmap | table forecolor backcolor',
                        height : "680",
                        entity_encoding : "raw",
                        nowrap: true,
                        menubar: 'edit insert view format table tools'
                    }}
                    apiKey='ttoom8yj7vhzborinbvzx8hsyovkzfee1cdfpjwpmeke3z3n'
                    onEditorChange={(a) => setEditorTextValue(a)}   
                    onBlur={() => setBrandDescriptionState( curr =>  curr.map( b => b.country === country ? ({...b, value: editorTextValue, isChanged: true}):b))}
                />
            </div>
            <Row justify="space-between">
                <div/>
                <Button 
                    type='primary' 
                    danger
                    style={{ marginTop:'1rem' }} 
                    onClick={() => clearDescription()}
                    loading={ loadingClearEditor }
                >
                    Clear
                </Button>
            </Row>
        </div>
    )
}

export default DescriptionEditor