import { Button, Checkbox, Divider, Form, FormInstance, Modal, TreeSelect } from "antd";
import { RootStore } from "../../../../Store";

interface CreateDescriptionAiProps {
    form: FormInstance;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    state: RootStore;
    currentCountryName: string;
    saveNewCategoryDescrption: (e: { category_l3_id: string; active: boolean }) => Promise<void>;
    loadingCreateData: boolean;
}

type TreeSelectType = {
    id: number;
    l2_name: string;
    children: { [key: string]: string | number }[];
    [key: string]: string | number | { [key: string]: string | number }[];
};

const CreateDescriptionAiModal = (props: CreateDescriptionAiProps) => {
    const {
        form,
        isModalOpen,
        setIsModalOpen,
        state,
        currentCountryName,
        saveNewCategoryDescrption,
        loadingCreateData,
    } = props;
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const selectedCategory = Form.useWatch("category_l3_id", form);
    const treeData = state.categoriesL3.data?.map((l3: TreeSelectType) => ({
        value: `${l3.id}`,
        title: (
            <span>
                {`${l3.id}: ${l3[`name_${currentCountryName}` as keyof typeof l3]}`}{" "}
                <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>({l3.l2_name})</span>{" "}
            </span>
        ),
        l2_name: l3.l2_name,
        children: l3.children.map((l4) => ({
            value: `${l4.id}`,
            title: `${l4.id}: ${l4[`name_${currentCountryName}` as keyof typeof l4]}`,
        })),
    }));

    return (
        <Modal title="Create ai description category" open={isModalOpen} onCancel={handleCancel} footer={false}>
            <Divider />
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ active: true }}
                form={form}
                onFinish={saveNewCategoryDescrption}
            >
                <Form.Item name="category_l3_id" label="Category">
                    <TreeSelect
                        showSearch
                        style={{ width: "400px", margin: "0.5rem 0 0.5rem 0.5rem", textAlign: "start" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Select Category"
                        allowClear
                        treeData={treeData}
                        treeDefaultExpandAll={false}
                        filterTreeNode={(search: string, item: any) =>
                            item.value.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        }
                    />
                </Form.Item>
                <Form.Item name="active" label="Active" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
                    <Button htmlType="submit" disabled={!selectedCategory} loading={loadingCreateData}>
                        Create
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
        </Modal>
    );
};

export default CreateDescriptionAiModal;
