
import {  Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllShops } from '../../../actions/shopAction/shopAction'
import numberWithCommas from '../../../shared/utils/numberUtils'
import { RootStore } from '../../../Store'
import '../style/ShopStatus.css'

interface UPProps {
  currentCountryId:number
}

const ShopStatus:React.FC<UPProps> = ({ currentCountryId }) => {
  const dispatch = useDispatch()
  const state = useSelector((state:RootStore) => state)
  const [ shopStatus, setShopStatus ] = useState({active:0, inactive:0,nostatus:0})
  useEffect(() => {
    dispatch(getAllShops(0,currentCountryId))
  }, [ dispatch, currentCountryId ])
  useEffect(() => {
    if ( state.shops.data ) {
      let active = 0
      let inactive = 0
      let nostatus = 0
      state.shops.data.forEach(( item:any ) => {
        if (item.active === 'Active'){
          active += 1
        } 
        else if ( item.active === 'Inactive' ) {
          inactive += 1
        }
        else if ( item.active === null ) {
          nostatus += 1
        }

      })
      setShopStatus({ active, inactive, nostatus})
    }
  }, [ state.shops ])
  
  return (
    <div className="user-date-description shop-status-box">
      
      <h6 style={{ alignSelf:'center', margin:'0 1rem', borderBottom:'1px solid gray', fontSize:'14px' }}>Shops</h6>
      <div style={{ display:'flex',justifyContent:"center", borderRadius:'3px'}}>
      
        <Statistic
          style={{ width:'80px'}}
          title="Active"
          value={ shopStatus.active ?  numberWithCommas(String( shopStatus.active ).replace('.', ',')) : '...' }
          valueStyle={{ color: '#3f8600' }}
          
        />
        <Statistic
        style={{ width:'80px'}}
          title="Inactive"
          value={ shopStatus.inactive ?  numberWithCommas(String( shopStatus.inactive ).replace('.', ',')) :'...' }
          valueStyle={{ color: '#cf1322' }}
        />
        <Statistic
          style={{ width:'80px'}}
          title="All"
          value={ shopStatus.active && shopStatus.inactive && shopStatus.nostatus ?   numberWithCommas(String( shopStatus.active + shopStatus.inactive + shopStatus.nostatus ).replace('.', ',')) :'...' }
          valueStyle={{ color: 'blue' }}
        />
      </div>
      
    </div>
  )
}

export default ShopStatus
