import { Space, Tag } from "antd";

import { capitalizeFirstLetter } from "../../../attributes/helpers/checkIsIncludes";
import { Tabs } from "../../types/type";

interface NavigationProps {
    selectedTab: number;
    changeTab: (tab: number) => void;
}

export const showTabName = (name: any) =>
    name
        .split("_")
        .map((text: string) => capitalizeFirstLetter(text))
        .join("/");

const Navigation = (props: NavigationProps) => {
    const { selectedTab, changeTab } = props;
    return (
        <Space size={[0, "small"]} direction="vertical" style={{ paddingLeft: "0.3rem" }}>
            {Object.keys(Tabs).map(
                (tab) =>
                    !isNaN(Number(tab)) && (
                        <Tag
                            key={Number(tab)}
                            color={Number(tab) === selectedTab ? "blue" : undefined}
                            style={{ padding: "0.5rem", borderRadius: "5px", cursor: "pointer", width: "184px", fontSize: "18px" }}
                            onClick={() => changeTab(Number(tab))}
                            className="attribute-mapping-tab"
                        >
                            {showTabName(Tabs[tab as keyof typeof Tabs])}
                        </Tag>
                    )
            )}
        </Space>
    );
};

export default Navigation;
