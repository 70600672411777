import { CloseCircleOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Affix, Alert, Button, Checkbox, Col, Divider, Layout, Popconfirm, Row, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import showMessage from '../../../shared/MessagesInfo/message'
import Spinner from '../../../shared/Spinner/Spinner'
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'
import '../style/CleanupBrands.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBrands } from '../../../actions/brand/BrandAction'
import { RootStore } from '../../../Store'
import FadeIn from 'react-fade-in';
//import SpinnerLine from '../../../images/spinner-products.gif'
import { useSearchState } from '../../../shared/hooks/useSearchState'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import numberWithCommas from '../../../shared/utils/numberUtils'
import { editableCellcomponents, EditableCellProps, mappedColumns } from '../../../shared/FormElements/EditableCell'
const { Header } = Layout

type SuggestedType = {
  id: number
  model_clean_name: string
  name: string
  percent: number
  products_count: number
  isBrandSelected: boolean
  valid: boolean
}

export type BrandType = {
  key: string
  created_at: string
  id: number
  image: string
  logo_url: string
  mapping_brand_name: null | string
  model_clean_name: string
  name: string
  products_count: number
  redirect_brand_name: null
  suggested: SuggestedType[]
  updated_at: string
  selectedPercent: number
  isSuggestedLoading: boolean
  isShowSelect: boolean
  isLoadingName: boolean
}

type SelectedBrandsType = {
  id: 653612
  image_name: string
  [key: string]: any
}

const sortTable = (a: string, b: string, type: string) => {
  if (type === 'date') {
    if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
    if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
    return 0;
  }
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
}

//////////////for editing cell


// type EditableTableProps = Parameters<typeof Table>[0];

// interface DataType {
//   key: React.Key;
//   name: string;
//   age: string;
//   address: string;
// }

//type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const CleanupBrands = () => {
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState<BrandType[]>([])
  //const [ currentPage, setCurrentPage ] = useState(1)
  const [selectedBrand, setSelectedBrand] = useState<{ id: number, name: string } | null>(null)
  const [showBrandsList, setShowBrandsList] = useState<{ id: number, name: string, level: number, data: SelectedBrandsType[] } | null>(null)
  const brandState = useSelector((state: RootStore) => state.brands)
  const { getColumnSearchProps } = useSearchState(brands,'ClenaupBrands')
  const windowSize = useWindowSize()
  const dispatch = useDispatch()
  const stateR = useSelector((state: RootStore) => state)
  //const currentCountryId =  stateR.dataLs.country ? stateR.dataLs.country.countryId:1
  const currentCountryName = stateR.dataLs.country ? stateR.dataLs.country.currentCountry : "rs"
  useEffect(() => {
    dispatch(getAllBrands())
  }, [dispatch])

 
  useEffect(() => {
    const getBrands = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/list?valid=true`)
        //console.log(data)
        const newData = data.data.map((item: BrandType) => {
          return {
            ...item,
            selectedPercent: 30,
            suggested: [],
            isSuggestedLoading: false,
            isShowSelect: false,
            isLoadingName: false
          }
        })
        setBrands(newData)
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }
    getBrands()
  }, [])

  const changePercent = (percent: string, id: number) => {
    setBrands(curr => curr.map(data => data.id === id ? { ...data, isSuggestedLoading: true } : data))
    const findBrand = brands.find(brand => brand.id === id)
    if (findBrand) {
      findSimilarBrands({ ...findBrand, selectedPercent: Number(percent) })
        .then((resp) => {
          setBrands(curr => curr.map(brand => brand.id === id ? { ...brand, isSuggestedLoading: false, selectedPercent: Number(percent), suggested: resp.map((item: SuggestedType) => { return { ...item, isBrandSelected: !item.valid } }) } : brand))
        })
        .catch(err => console.log(err))
    }
  }

  const expandRow = (record: BrandType) => {
    setBrands(curr => curr.map(data => data.id === record.id ? { ...data, isSuggestedLoading: true } : data))
    findSimilarBrands(record)
      .then((resp) => {
        setBrands(curr => curr.map(brand => brand.id === record.id ? { ...brand, isSuggestedLoading: false, suggested: resp.map((item: SuggestedType) => { return { ...item, isBrandSelected: !item.valid } }) } : brand))
      })
      .catch(err => console.log(err))
  }


  const findSimilarBrands = async (record: BrandType) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/find-similar-brands?id=${record.id}&name=${record.name}&percent=${record.selectedPercent}`)
      //console.log(data)
      return data.data
    } catch (err) {
      console.log(err)
      //setLoading(false)
    }
  }

  const showSelect = (id: number) => {
    setBrands(curr => curr.map(data => { return { ...data, isShowSelect: data.id === id ? true : false } }))
    setSelectedBrand(null)
  }

  const handleBrandChange = async (id: number) => {
    const findBrand = brandState.data.find((brand: any) => brand.id === id)

    setSelectedBrand({ id: findBrand.id, name: findBrand.name })


  }


  const saveBrandMap = async (record: { id: number, name: string }) => {  //      map brand      
    if (selectedBrand) {


      try {

        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/brand/map-brand`, { redirect_brand_id: selectedBrand.id, brands: [{ id: record.id, name: record.name, map: true }] })
        setBrands(curr => curr.map(data => { return { ...data, isShowSelect: false } }))
        setSelectedBrand(null)
        showMessage(data.message)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const resetMapBrand = () => {
    setBrands(curr => curr.map(data => { return { ...data, isShowSelect: false } }))
    setSelectedBrand(null)
  }


  const showBrands = async (record: BrandType, level: number) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/brand/list-brand-products?id=${record.id}`)
      //console.log(data)
      setShowBrandsList({ id: record.id, name: record.name, level: level, data: data?.data })
    } catch (err) {
      console.log(err)
    }
  }

  const setToNN = async (record: BrandType) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/brand/map-brand`, { redirect_brand_id: 3621, brands: [{ id: record.id, name: record.name, map: true }] })
      setBrands(curr => curr.filter(data => data.id !== record.id))
      showMessage(data.message)
    } catch (err) {
      console.log(err)
    }
  }

  const defaultColumns: any = [
    {
      title: 'Percent',
      dataIndex: 'selectedPercent',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '10%',
      render: (text: string, record: BrandType, index: number) =>
        <Select
          size='small'
          style={{ width: '60px' }}
          defaultValue={text}
          onChange={(e) => changePercent(e, record.id)}
        >
          {
            Array.from({ length: 10 }).map((_, i) => i !== 0 &&
              <Select.Option
                key={i * 10}
                value={i * 10}
              >
                {i * 10}
              </Select.Option>)
          }
        </Select>
    },
    {
      title: 'Id',
      dataIndex: 'id',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '6%'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: BrandType, b: BrandType) => sortTable(String(a.name), String(b.name), 'string'),
      editable: true,
      ...getColumnSearchProps('name')
    },

    {
      title: '',
      dataIndex: 'id',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '3%',
      render: (text: string, record: BrandType, index: number) =>
        <Popconfirm placement="top" title={'Are you sure?'} onConfirm={() => setToNN(record)} okText="Yes" cancelText="No">
          <Button size='small'
          >
            Set to NN
          </Button>
        </Popconfirm>
    },
    {
      title: '',
      dataIndex: 'id',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '3%',
      render: (text: string, record: BrandType, index: number) =>
        <div>
          {!record.isShowSelect ?
            <Button onClick={() => showSelect(record.id)} size="small">Map brand</Button> :
            <div style={{ display: 'flex' }}>
              <Select
                showSearch
                allowClear={undefined}
                style={{ width: '200px' }}
                placeholder="Select Brand"
                //value={ mainBrand }
                onChange={(e) => handleBrandChange(e)}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size="small"
              >
                {brandState.data && brandState.data.sort((a: any, b: any) => b.products_count - a.products_count).map((brand: any) => (
                  <Select.Option key={brand.id} value={brand.id}>{`(${brand.id}) ${brand.name} - ${brand.products_count}`}</Select.Option>
                ))
                }
              </Select>
              <Button size='small' type='primary' disabled={record.isShowSelect && selectedBrand ? false : true} style={{ marginLeft: '5px' }} onClick={() => saveBrandMap(record)}>Save</Button>
              <CloseCircleOutlined style={{ marginTop: '5px', marginLeft: '6px' }} onClick={resetMapBrand} />
            </div>
          }

        </div>
    },
    {
      title: 'Products Count',
      dataIndex: 'products_count',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '3%',
      render: (text: string, record: any, index: number) => 
        <Alert 
          message={ numberWithCommas(String(record.products_count).replace('.', ','))} 
          type={record.id === showBrandsList?.id ? "success" : "info"} 
          style={{ height: '1rem', backgroundColor: 'gray!important', cursor: 'pointer' }} 
          onClick={() => showBrands(record, 2)} 
        />
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      className: "redirect-center",
      align: 'center' as 'center',
      width: '20%',
      render: (record: string) => record ? `${moment(record).format('DD MMMM YYYY,')} ${moment(record).format('HH:mm')}` : '-',
      sorter: (a: BrandType, b: BrandType) => sortTable(a.updated_at, b.updated_at, 'date')
    },
  ]
  const selectBrandToMap = async (e: boolean, recordParent: BrandType, recordChild: SuggestedType) => {
    setBrands(curr => curr.map(data => {
      if (data.id === recordParent.id) {
        return {
          ...data,
          suggested: data.suggested.map(item => {
            if (item.id === recordChild.id) {
              return {
                ...item,
                isBrandSelected: e
              }
            }
            return item
          })
        }
      }
      return data
    }))
  }

  const saveBrandMapGroup = async (record: BrandType) => {
    const newData = record.suggested.map(data => (
      {
        id: data.id,
        name: data.name,
        map: data.isBrandSelected
      })
    )

    const objToSend = {
      redirect_brand_id: record.id,
      brands: newData
    }

    try {
      const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/brand/map-brand`, objToSend)
      showMessage(data.message)
      setTimeout(() => {

        setExpandRowArr(curr => curr.filter(item => item !== record.id))
      }, 300);
    }
    catch (err) {
      console.log(err)
    }

  }

  const [expandRowArr, setExpandRowArr] = useState<number[]>([])

  const keyToExpandRow = (id: number, type: string) => {
    if (type === 'add') {
      setExpandRowArr(curr => [...curr, id])
    }
    if (type === 'remove') {
      setExpandRowArr(curr => curr.filter(item => item !== id))
    }
  }

  const handleSave = async (row: BrandType & EditableCellProps) => {
    setBrands(curr => curr.map(data => row.id === data.id ? ({ ...data, isLoadingName: true }) : data))
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, { id: row.id, name: row.name })
      const newData = [...brands]
      const index = newData.findIndex((item) => row.id === item.id)
      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setBrands(newData.map(data => ({ ...data, isLoadingName: false })))
      //setBrands(newData)
      showMessage(data.message)

    } catch (err) {
      console.log(err)
    }
  }

  

  const columns = mappedColumns(defaultColumns, handleSave, true)

  const columns2 = (record:any):any => [
    {
      title: '',
      dataIndex: 'isBrandSelected',
      className: "redirect-center",
      align: 'center' as 'center',
      render: (text:string, recordChild:SuggestedType, index:number) => <Checkbox checked={recordChild.isBrandSelected} onChange={(e) => selectBrandToMap(e.target.checked, record, recordChild)} />
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className:"redirect-center",
      align: 'center' as 'center',
      
    },
    {
      title: 'Products Count',
      dataIndex: 'products_count',
      className: "redirect-center",
      align: 'center' as 'center',
      render: (text: string, recordChild: any, index: number) => <Alert message={recordChild.products_count} type={recordChild.id === showBrandsList?.id ? "success" : "info"} style={{ height: '1rem', backgroundColor: 'gray!important', cursor: 'pointer' }} onClick={() => showBrands(recordChild, 2)} />
    },
  ]

  return (
    <React.Fragment>
      <HelmetTitle title="Data Entry App - Clenaup Brands" /> 
      <Row justify='center'>
        <Divider>Cleanup Brands</Divider>
        <Col span={windowSize.width < 2000 ? 16 : 12} style={{ backgroundColor: 'white' }}>
          <Layout style={{ margin: '0.6rem 1rem' }}>
            <Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }}><span> List of Brands </span> </Header>
            {!loading ?
              <Table
                dataSource={brands}
                columns={columns as any}
                rowKey={(record: BrandType) => record.id}
                className="cleanup-brand-table"
                bordered
                components={editableCellcomponents}
                rowClassName={() => 'editable-row'}
                pagination={{ pageSize: 100, /*total: brands.length, /*onChange:onPaginateChange, current:currentPage,*/ showSizeChanger: false }}
                expandable={{
                  expandedRowRender: record =>
                    <Row justify='center' style={{ backgroundColor: '#f6f6f6', border: '1px solid #bbe4e9', padding: '2px', borderRadius: '5px' }}>
                      <Col span={10} style={{ border: '1px solid lightgray', backgroundColor: 'white', borderRadius: '5px', padding: '0.5rem', margin: '0.5rem 0' }}>
                        {!record.isSuggestedLoading ?
                          <Table
                            bordered
                            dataSource={record.suggested}
                            rowKey={(record: SuggestedType) => record.id.toString() + record.name}
                            columns={columns2(record)}
                            pagination={false}
                            footer={() =>
                              <div className="text-center">
                                <Button className={'primary'} danger onClick={() => saveBrandMapGroup(record)}>
                                  Save
                                </Button>
                              </div>
                            }
                          /> : <Spin size='large' style={{ marginLeft: '45%', marginTop: '0.5rem' }} />}
                      </Col>
                    </Row>,
                  showExpandColumn: true,
                  rowExpandable: record => record.name !== 'Not Expandable',
                  expandedRowKeys: expandRowArr,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return expanded ? (
                      <MinusCircleTwoTone onClick={e => { onExpand(record, e); keyToExpandRow(record.id, 'remove') }} />
                    ) : (
                      <PlusCircleTwoTone onClick={e => { onExpand(record, e); keyToExpandRow(record.id, 'add') }} />
                    )
                  },
                  onExpand: (e, record) => { e && expandRow(record) },
                }}
              /> : <Spinner />}
          </Layout>
        </Col>
        <Col span={7} style={{ backgroundColor: 'white' }}>
          <Affix offsetTop={30}>
            <div style={{ textAlign: 'center', height: '95vh', backgroundColor: '#f9f9f9', border: '1px solid #dfd3c3', overflowY: 'auto', marginTop: '6px' }}>
              {
                (showBrandsList && showBrandsList?.data.length > 0) ?
                  <div>
                    <h6 style={{ textAlign: 'center' }}>{showBrandsList?.name}</h6>
                    {showBrandsList?.data.map(item =>
                      <FadeIn delay={300} transitionDuration={800} key={item.id}>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', backgroundColor: 'white', margin: '0 0.6rem 0.2rem 0.3rem', padding: '0.5rem 0.3rem', border: '1px solid #eef2e2' }} >
                          <img src={`https://img.ep-cdn.com/images/160/160/${item.image_name}.webp`} alt="uncategorized-item" width={50} /><br />

                          <span style={{ marginLeft: '0.4rem', fontSize: '13px' }}>{item[`name_${currentCountryName}`]}</span><br />
                          {/* <LinkOutlined style={{ position:'absolute', right:'10px', top:'10px', fontSize:'12px', color:'#00bbf0'}} onClick={()=> window.open(item.product_url, "", "width=900,height=1080")} /> */}
                          <hr />
                        </div>
                      </FadeIn>
                    )}

                  </div>
                  :
                  <p>No Selected brand</p>
              }
            </div>

          </Affix>

        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CleanupBrands