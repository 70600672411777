import { Button, Modal, Table } from 'antd'
import React from 'react'

interface ModalUrlType {
    isModalOpen: boolean
    handleOk: () => void
    handleCancel: () => void
    
    testUrlResp:{id:number}[]

}

const ModalUrl:React.FC<ModalUrlType> = ({ isModalOpen, handleCancel, handleOk, testUrlResp }) => {
    let temKeys:string[] = []
  
    testUrlResp.forEach(( el:any) => {
        for (const key in el) {
        if (!temKeys.includes(key)) {

            temKeys.push(key);
        }
        }
    })
    const columns =  
        [
            {
                title: '',
                dataIndex: 'index',
                key: 'index',
                align:"center" as "center",
                render: ( text:string, record:any , index: number ) => index + 1,
            },
            ...temKeys.map( k => {
                return {
                title: (k.charAt(0).toUpperCase()+k.slice(1)).replaceAll('_',' '),
                dataIndex: k,
                key:k
                }
            }).filter( item => item.key !== 'id')
        ]

  return (
    <Modal 
        title="Basic Modal" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        style={{  minWidth:'80vw' }}
        footer={false}
    >
        <Table 
            columns={columns}
            dataSource={testUrlResp}
            bordered
            pagination={false}
            rowKey={(record) =>record.id}
           
        />
        <Button style={{ marginTop:'10px' }} onClick={handleOk}>Close</Button>
    </Modal>

  )
}

export default ModalUrl


