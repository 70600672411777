import { ProductType } from "../types/types";

export const showNameByCountry = (
    product: ProductType,
    currentCountryName: string,
    currentCountryId: number,
    countries: { value: number; name: string; order: number }[]
) => {
    const otherCountires = countries.filter((country) => country.value !== currentCountryId);
    if (product[`name_${currentCountryName}` as keyof typeof product]) {
        return product[`name_${currentCountryName}` as keyof typeof product];
    } else {
        let findName: boolean | string | number | string[] = "";
        otherCountires.forEach((country) => {
            if (product[`name_${country.name}` as keyof typeof product]) {
                findName = product[`name_${country.name}` as keyof typeof product];
            }
            return;
        });
        return findName;
    }
};
