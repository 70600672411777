export function arraysAreEqual(arr1: number[], arr2: number[]) {
    // Proveri da li imaju istu dužinu
    if (arr1.length !== arr2.length) return false;

    let areEqual = true; // Postavljamo promenljivu za rezultat

    // Koristimo forEach da iteriramo kroz prvi niz
    arr1.forEach((element, index) => {
        if (element !== arr2[index]) {
            areEqual = false; // Ako nađemo različite elemente, postavljamo areEqual na false
        }
    });

    return areEqual;
}
