import { message } from 'antd'

const errorMessage = (msgInfo:string) => {
    message.error({
        content:`${msgInfo}`, 
        className: 'custom-class',
        style: {
            marginTop: '20vh',
            height:'100px'
        },
        duration:2
        })
}

export default errorMessage