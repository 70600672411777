// import { ValuesType } from '../pages/AttirbutesOverview';
// import { ValueTyp } from '../pages/AttributeMapping';

import { ValueType } from "../types/AttributeType";

export const checkIsIncludes = (valuesShop: ValueType, rangeToCompare: { min: number; max: number } | null, arrToCompare: string[], text: string | number | boolean) => {
    return valuesShop.attribute_type === "range"
        ? typeof Number(text) === "number" &&
          typeof text === "string" &&
          rangeToCompare &&
          Number(text.replace(",", ".")) <= rangeToCompare?.max &&
          Number(text.replace(",", ".")) >= rangeToCompare?.min
            ? true
            : false
        : valuesShop.attribute_type === "select" || valuesShop.attribute_type === "multiselect" || "yesno"
        ? arrToCompare?.includes(String(text))
            ? true
            : false
        : "";
};
export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
