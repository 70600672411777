import axios from "axios";
import { Dispatch } from "react";
import showMessage from "../../shared/MessagesInfo/message";
import { MainMapAddField, mainMapAll, MainMapOptions, MainMapUpdateField, MAIN_MAP_ADD_FIELD, MAIN_MAP_ALL, MAIN_MAP_UPDATE_FIELD, ShopMapAddNewField, ShopMapAll, ShopMapClear, shopMapNewFields, ShopMapUpdateField, SHOP_MAP_ADD_NEW_FIELD, SHOP_MAP_ALL, SHOP_MAP_CLEAR, SHOP_MAP_NEW_FIELDS, SHOP_MAP_UPDATE_FIELD } from "./mapActionType";


export const getAllMainMap = () => async (dispatch:Dispatch<mainMapAll>) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/maps/main-map`)
        const respData = data.data
        const newData = respData.map((itemData:any)=>{return {...itemData, key:itemData.id}})
        
        dispatch({type:MAIN_MAP_ALL,payload:{...data, data:newData}})
      } catch ( err ) {
        console.log(err)
      }
}

export const mainMapUpdateField = ( obj:any ) => async ( dispatch:Dispatch<MainMapUpdateField>) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/maps/main-map/update-field`, obj)
        
        showMessage( data.message )
        dispatch({type:MAIN_MAP_UPDATE_FIELD, payload: data})
        return data
    } catch ( err ) {
        console.log(err)
        return err
    }
}
//----main map add new field

export const mainMapAddField = ( obj:any,  options:any, setOptions:any, setIsModalVisible:any, form:any ) => async ( dispatch:Dispatch<MainMapAddField>) => {
    try {
        const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/maps/main-map/add-field`, obj )
        setOptions([...options, {id:data.data.id, value:data.data.key_name, label:data.data.key_name}])
        form.resetFields()
        setIsModalVisible(false)  
        showMessage( data.message )
        
        //console.log(data)
        dispatch({ type:MAIN_MAP_ADD_FIELD, payload:data })
          
        //window.location.reload(false)
    } catch ( err ) {
        //errorMessage( err.response.data.errors[0] )
        console.log(err)
       
        
    }
}

export const getMainMapOptions = ( setOptions:any, setOptionsInit:any ) => async (dispatch:Dispatch<MainMapOptions>) => {
    try {
        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/maps/main-map`)
        const mainMapList = data.data.map((map:any)=>{
            return {
                id:map.id,
                value: map.key_name,
                label: map.key_name,
                shopKeyText:''
            }
        })
        // const mapOptions = mainMapList.map(( map:any )=>{
        //     return {
        //         id:map.id,
        //         value: map.key_name,
        //         label: map.key_name
        //     }
        //    })
        setOptions(mainMapList)
        //optionsInit.push([...mainMapList])
        setOptionsInit(mainMapList)
        
    } catch ( err ) {
        console.log(err)
    }
}


//--------------show shop maps---------

export const shopMapById = ( id:number| undefined,setLoading: React.Dispatch<React.SetStateAction<boolean>>, newField:boolean ) => async ( dispatch:Dispatch<ShopMapAll> ) => {
    !newField && setLoading(true)
    try {

        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/maps/shop-map/show-map?shop_id=${id}`)
        dispatch({type: SHOP_MAP_ALL,payload:data})
        !newField && setLoading(false)
    } catch ( err ) {
       console.log(err)
    }
}


//--------------clear shop maps---------

export const clearShopMap = () => async ( dispatch:Dispatch<ShopMapClear> ) => {
    try {
        
        dispatch({type: SHOP_MAP_CLEAR,payload:{}})
    } catch ( err ) {
       console.log(err)
    }
}

//--------------add shop maps---------

export const addNewFieldToShopMap = (shop_id:any, tempObjects:any, headers:any, history:any,handleModalShopMapOk:() => void ) => async ( dispatch:Dispatch<shopMapNewFields>) =>{
    try {
        const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/maps/shop-map/add-bulk-fields?shop_id=${shop_id}`,tempObjects, headers)
        dispatch({type:SHOP_MAP_NEW_FIELDS,payload:data})
        showMessage(data.message)
        // if(data.data){
        //     history.push('/shop-list')
        // }
        handleModalShopMapOk()
    } catch ( err ) {
        console.log(err)
    }

}

//--------------edit shop maps---------

export const shopMapsUpdateField = (id:any, row:any, index:any, newData:any, setEditingKey:any) => async ( dispatch:Dispatch<ShopMapUpdateField>) => {
    try {
        
        const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/maps/shop-map/update-field`,{ id:id, key:row.key, tag_delimiter:row.tag_delimiter, multiple_tags:row.multiple_tags })
        dispatch({type:SHOP_MAP_UPDATE_FIELD, payload:data})
        showMessage( data.message )
        if( data.status ) {
            if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...row,
              });
              //setData(newData);
              setEditingKey('');
              
            } else {
              newData.push(row);
              //setData(newData);
              setEditingKey('');
            }
        }
        return data
    } catch ( err ) {
        
        return err
    }
}

//------------shop map add field-----

export const shopMapAddNewField = ( obj:any, setAllId:any, setIsModalVisible:any ) => async ( dispatch:Dispatch<ShopMapAddNewField> ) => {
    try {
        const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/maps/shop-map/add-field`, {...obj, multiple_tags: obj.multiple_tags ? true:false })
        showMessage(data.message)
        setAllId((prev:any)=> [...prev, data.data.main_key_id ])
        dispatch({ type:SHOP_MAP_ADD_NEW_FIELD, payload:data })
        setIsModalVisible(false)
      } catch ( err ) {
        console.log(err)
      }
}

