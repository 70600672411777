import React, { useContext, useEffect, useRef, useState } from 'react'
import { Input, Form, InputRef, Spin } from 'antd'
import { AutoMatchedPPType } from '../../pages/AutoMatchedPP';
import type { FormInstance } from 'antd/es/form';
import axios from 'axios';
import showMessage from '../../../../shared/MessagesInfo/message';
import openNotification from '../../../../shared/MessagesInfo/WarningBox';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof AutoMatchedPPType;
    record: AutoMatchedPPType;
    handleSave: (record: AutoMatchedPPType) => void;
}


interface EditableRowProps {
    index: number;
  }
  
export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
}

const EditableContext = React.createContext<FormInstance<any> | null>(null)

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [ editing, setEditing] = useState(false);
    const [ loading, setLoading ] = useState(false)
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    const [ dataIndexValue, setDataIndexValue ] = useState<string>('')
  
    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing])
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }
  
    const save = async () => {
      setLoading(true)
      try {
        const values = await form.validateFields();
        
        if (!values[dataIndexValue] || values[dataIndexValue].length  > 3) {
          const obj = {
              product: {
                  id: dataIndexValue === 'source_product_mpn' ? record.source_product_id : record.destination_product_id,
                  mpn: dataIndexValue === 'source_product_mpn' ? values.source_product_mpn : values.destination_product_mpn
              }
          }
          const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/automatch/update-mpn`, obj )

          showMessage(data.message)
          toggleEdit();
          handleSave({ ...record, ...values });
          setLoading(false)
        }
        else {
          openNotification('Mpn word has to have 0 or more than 3 characters!')
          setLoading(false)
        }

      } catch (errInfo) {
        console.log('Save failed:', errInfo);
        openNotification('Save failed')
      }
    }
  
    let childNode = children;

    

    if (editable) {
      childNode = editing ? (
        <div>
            { dataIndexValue === 'destination_product_mpn' && <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} /*onClick={() =>{ toggleEdit();setDataIndexValue('source_product_mpn')}}*/>
                { record.source_product_mpn ? record.source_product_mpn : '-' }
            </div>}
        
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndexValue}
                initialValue={ dataIndexValue === 'source_product_mpn' ? record.source_product_mpn ? record.source_product_mpn:''  : record.destination_product_mpn ? record.destination_product_mpn :''}  //check this
            >
                <Input 
                    ref={inputRef} 
                    onPressEnter={() => save()} 
                    onBlur={() => save()} 
                    suffix={ 
                        loading ? 
                        <Spin 
                            size='small' 
                            indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin /> } 
                        />:
                        <span />
                    } 
                />
            </Form.Item>

            {dataIndexValue === 'source_product_mpn' &&  <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} /*onClick={() =>{ toggleEdit();setDataIndexValue('destination_product_mpn')}}*/>
                { record.destination_product_mpn ? record.destination_product_mpn : '-' }
            </div>}

        </div>
      ) : (
        <div>
           
          <div 
            className="editable-cell-value-wrap" 
            style={{ paddingRight: 24, width:"90%" }} 
            onClick={() =>{ 
              toggleEdit();
              setDataIndexValue('source_product_mpn')
            }}
            >
            { record.source_product_mpn ? record.source_product_mpn : '-' }
          </div>
              
           
            
          <div 
            className="editable-cell-value-wrap" 
            style={{ paddingRight: 24 }} 
            onClick={() =>{ 
              toggleEdit();
              setDataIndexValue('destination_product_mpn'); 
              form.setFieldValue('destination_product_mpn',   // because of same inputs 
              record.destination_product_mpn)  
            }}
          >  
            { record.destination_product_mpn ? record.destination_product_mpn : '-' }
          </div>

        </div>

      );
    }

    
  
    return <td {...restProps}>{childNode}</td>;
}

export default EditableCell

