import React from 'react';
import { Modal, Button } from 'antd';

const SkuModal:React.FC<any> = ({ 
  isModalSku, 
  setIsModalSku, 
  setSkuTextArea
 }) => {
  const handleOk = () => {
    setIsModalSku(false);
  }

  const handleCancel = () => {
    setIsModalSku(false);
  }
  const showSkuData = () => {
    const skuValue:any = document.querySelector('.sku-modal-value')
    const textToArray = skuValue.value.replace(' ','')
    const skuText =  textToArray.split('\n')
    setSkuTextArea(skuText)
    skuValue.value=''
    setIsModalSku(false)

  }

  return (
    <>
      <Modal 
        title="Search prices by sku" 
        open={isModalSku} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={false}
      >
        <textarea 
          style={{width:'100%'}} 
          className='sku-modal-value'
        />
        <Button type='primary' onClick={ showSkuData }>Show</Button>
      </Modal>
    </>
  );
};


export default SkuModal