import { Col, Space, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { SimilarAttributType, SimilarDataType } from "../../types/AttributeType";
import "../../style/AttributeMappingTabs.css";
import Tables from "./Tables";

interface AttributeMappingTabsProps {
    similarAttributes: SimilarAttributType[];
    columns: ColumnsType<SimilarDataType>;
    tableData: SimilarAttributType | null;
    setTabValue: React.Dispatch<React.SetStateAction<number | null>>;
    tabValue: number | null;
    setActiveTabKey: React.Dispatch<React.SetStateAction<number | null>>;
}

const AttributeMappingTabs: React.FC<AttributeMappingTabsProps> = ({ similarAttributes, columns, setTabValue, setActiveTabKey, tabValue, tableData }) => {
    const changeValue = async (e: number) => {
        setTabValue(e);
        setActiveTabKey(e);
    };

    return (
        <div>
            <Space size={[0, "small"]} className="attribute-mapping-tabs-box">
                {similarAttributes.map((d) => (
                    <Tag
                        key={d.shop_id}
                        color={d.shop_id === tabValue ? "blue" : undefined}
                        style={{ padding: "0.5rem", borderRadius: "5px", cursor: "pointer" }}
                        onClick={() => changeValue(d.shop_id)}
                        className="attribute-mapping-tab"
                    >
                        <span>{`${d.shop_name} - ${((d.data.filter((d) => d.mapping_id).length / d.data.length) * 100).toFixed(0)}%`} </span>
                    </Tag>
                ))}
            </Space>
            <Col span={24}>
                <Tables mapped={tableData ? tableData.data.filter((d) => d.mapped) : []} notMapped={tableData ? tableData.data.filter((d) => !d.mapped) : []} columns={columns} tabValue={tabValue} />
            </Col>
        </div>
    );
};

export default AttributeMappingTabs;
