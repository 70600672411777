
import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Col, Divider, Drawer, Form, Image, Input, Row, Select } from 'antd';
import axios from 'axios';
import InputE from '../../../shared/FormElements/Input';
import openTopNotification from '../../../shared/MessagesInfo/WarningBoxTop';
import { validateEmail } from '../../../shared/utils/ValidateEmail';
import { createShop } from '../../../actions/shopAction/shopAction';
import { useDispatch } from 'react-redux';
import  trustMarkImg  from '../../../images/etrustmark.png'
import '../style/CreateShop.css'

const { Option } = Select;
interface CreateShopModalProps {
    isModalCreateShop:boolean
    handleModalCreateShopOk: () =>void
    handleModalCreateShopCancel: () =>void
    setCounter: React.Dispatch<React.SetStateAction<number>>
}

function slugify(string:string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return string.toString().toLowerCase().replace(/\s+/g, '-')// Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
     .replace(/-+$/, '') // Trim - from end of text
    }

const CreateShopDrawer:React.FC<CreateShopModalProps> = ({ isModalCreateShop, handleModalCreateShopOk, handleModalCreateShopCancel, setCounter}) => {
    const [ countries, setCountries ] = useState<any>([])
    const [ cities, setCities ] = useState<any>([])
    const dispatch = useDispatch()
    
    const [ form ] = Form.useForm()
    useEffect(() => {
        const getAllCities = async () => {
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/city/list`)
                setCities(data)
            } catch ( err ) {
                console.log(err)
            }
        }
        isModalCreateShop && getAllCities()
    }, [ isModalCreateShop ])
    useEffect(() => {
        const getCountries = async () => {
            try {
                const { data } =  await axios.get(`${ process.env.REACT_APP_URL_API }/product/country/list`)
                setCountries(data)
            } catch ( err ) {
                console.log(err)
            }
        }
        isModalCreateShop && getCountries()
        form.setFieldsValue({ allow_update: true, allow_custom_note:false, sensitive_content:false, utm_disabled: false, etrustmark:false  })
    }, [ form, isModalCreateShop ])

    const handleSubmit = (e: any): void => {
        if (e.homepage_url && e.homepage_url.length > 500) {
            openTopNotification('Home page Url is invalid')
        }
        else if (e.feed_url && (!e.feed_url.toLowerCase().includes('http') || e.feed_url.length > 500 || e.feed_url.trim().slice(0,4).toLowerCase() !=='http')) {
            openTopNotification('Feed Url is invalid')
        }
        else if (e.email && (!validateEmail(e.email) ||  e.email.length > 255) /*(!e.email.includes('.') || !e.email.includes('@') || !checkEmailText(e.email))*/ ) {
            openTopNotification('Email is invalid')
        }
        else {
            if (!e.note_on_website || (e.note_on_website && e.note_on_website.length<170)){
    
                const obj = {
                    ...e,
                    url_link:slugify(e.name),
                    etrustmark: e.etrustmark ? 1:0
                }
                dispatch(createShop(obj))
                setCounter( curr => curr +1)
                handleModalCreateShopOk()
            }
            else {
                openTopNotification(`Seller Note on Website have to have les than 170 characters. Current number of charactes is ${e.note_on_website && e.note_on_website.length}`)
            }
        }
    }

    return (
        <Drawer
            title={<div style={{ textAlign:'center', color:'darkred', marginTop:'0.5rem' }}>Create shop</div>}
            placement="right"
            width={1100}
            //size='large'
            onClose={handleModalCreateShopCancel}
            open={isModalCreateShop}
            forceRender 
        >
            <Form 
                layout="vertical" 
                onFinish={ handleSubmit } 
                form={form} 
            >
                <div style={{ backgroundColor:'#f8f8f8', padding:'1.5rem', border:'1px solid beige', borderRadius:'5px' }}>

                
                <Row gutter={16}>
                    <Col span={12}>
                        <InputE
                            label="Name" 
                            name="name"
                            rules={[{ required: true, message: 'Missing company name' }]}    
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item 
                            label="Country" 
                            name="country_id"
                            rules={[{ required: true, message: 'Missing Country' }]}
                        >
                            <Select placeholder="Choose country">
                                {
                                    countries.data && countries.data.map(( country:any ) => (
                                        <Option value={country.id} key={country.id} >{ country[`name_rs`]}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item 
                            label="City" 
                            name="city"
                            style={{ width: '100%', marginBottom:'0.5rem' }}
                        >
                            <Select
                                showSearch
                                allowClear={undefined}
                                
                                placeholder="Select City"  
                                filterOption={(input, option:any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                            { cities.data && cities.data.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((brand:any)=>(
                                <Option key={brand.id} value={brand.name}>{ brand.name }</Option>
                            ))
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <InputE
                            label="Address" 
                            name="address"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <InputE
                            label="Phone" 
                            name="phone"
                        />
                    </Col>
                    <Col span={12}>
                        <InputE
                            label="Email" 
                            name="email"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <InputE
                            label="Homepage URL" 
                            name="homepage_url"
                        />
                    </Col>
                    <Col span={12}>
                        <InputE
                            label="Feed URL" 
                            name="feed_url"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} style={{display:'flex'}}>
                    <Form.Item 
                        name="has_sensitive_content"
                        valuePropName="checked"
                        className='create-shop-allow-update'
                    >
                        <Checkbox 
                            style={{marginTop:'0.5rem'}}
                        >
                            <span style={{ fontSize:'14px' }}>Sensitive Content</span>
                        </Checkbox>
                    </Form.Item>
                        <Form.Item 
                            name="allow_update"
                            valuePropName="checked"
                            className='create-shop-allow-update'
                            style={{ marginLeft:'3rem' }}
                        >
                            <Checkbox 
                                style={{marginTop:'0.5rem'}}
                            >
                                <span style={{ fontSize:'14px' }}>Allow Update</span>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item 
                            name="allow_custom_note"
                            valuePropName="checked"
                            className='create-shop-allow-custom-note'
                            style={{ marginLeft:'3rem' }}
                        >
                            <Checkbox 
                                style={{marginTop:'0.5rem'}}
                            >
                                <span style={{ fontSize:'14px' }}> Allow custom note from feed</span>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item 
                            name="utm_disabled"
                            valuePropName="checked"
                            className='create-shop-allow-custom-note'
                            style={{ marginLeft:'3rem' }}
                        >
                            <Checkbox 
                                style={{marginTop:'0.5rem'}}
                            >
                                <span style={{ fontSize:'14px' }}> UTM Disabled</span>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item 
                            name="etrustmark"
                            valuePropName="checked"
                            //className='create-shop-allow-custom-note'
                            style={{ marginLeft:'3rem' }}
                        >
                            <Checkbox 
                                style={{marginTop:'0.5rem'}}
                            >
                               <Image src={trustMarkImg} style={{ position:'relative', bottom:'2px', right:"5px" }} preview={false} width={115} />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                </div>
               
                <Divider />
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                                label={<span className='text-area-label'>Description</span>}
                                name="description" 
                            >
                                <Input.TextArea placeholder="Description" className='create-shop-textarea' rows={14} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label={<span className='text-area-label'>Seller Payment</span>}
                            name="seller_payment" 
                        >
                            <Input.TextArea placeholder="Seller Payment" className='create-shop-textarea' rows={14} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label={<span className='text-area-label'>Seller Delivery</span>}
                            name="seller_delivery" 
                            style={{ marginBottom:'8px'}}
                        >
                            <Input.TextArea placeholder="Seller Delivery" className='create-shop-textarea' rows={14} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label={<span className='text-area-label'>Seller Note on Website</span>}
                            name="note_on_website" 
                            style={{ marginBottom:'8px'}}
                        >
                            <Input.TextArea placeholder="Seller Note" className='create-shop-textarea' rows={14} />
                        </Form.Item>
                    </Col>
                </Row>   
                <Divider />
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                                label={<span className='text-area-label'>Delivery Note (Under offer, max 350 Characters) </span>}
                                name="delivery_note" 
                            >
                                <Input.TextArea placeholder="Delivery Note " className='create-shop-textarea' style={{ backgroundColor:"#F8F9F9" }} rows={6} />
                        </Form.Item>
                    </Col>
                </Row>      
                <Button type='primary' htmlType='submit' style={{ marginLeft:'90%', marginTop:"2rem", paddingBottom:'1rem' }}>Add new shop</Button>    
            </Form>
        </Drawer>
    )
}

export default CreateShopDrawer