import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";
import { ColumnEditableProps, ProductType } from "../types/types";
import { handlerMpn } from "../utils/arrowKeyEvents";
import { HighlilghtWord } from "../utils/HighlightWord.ts";

const ColumnMpn: React.FC<ColumnEditableProps & { inputRefMpn: any }> = (props) => {
    //const [focusId, setFocusId] = useState<number | undefined>();

    const pressEnter = (id: number, e: { mpn: string }) => {
        props.setProducts((curr) =>
            curr.map((d) =>
                d.id === id ? { ...d, openMpn: false, [props.record.new_prodcut_id ? "newMpn" : "mpn"]: e.mpn } : d
            )
        );
    };

    const openNewMpn = (record: ProductType, index: number) => {
        props.setProducts((curr) => curr.map((d) => ({ ...d, openMpn: d.id === record.id ? true : false })));
        props.form.setFieldValue("mpn", props.record.new_prodcut_id ? props.record.newMpn : props.record.mpn);
        setTimeout(() => {
            const next = props.inputRefMpn.current[index];
            if (next) {
                next.focus();
            }
        }, 2);
    };

    const mpnInputBlur = (text: string, id: number) => {
        props.setProducts((curr) =>
            curr.map((d) => (d.id === id ? { ...d, [props.record.new_prodcut_id ? "newMpn" : "mpn"]: text } : d))
        );
    };

    return props.record.openMpn ? (
        <Form form={props.form} onFinish={(e) => pressEnter(props.record.id, e)} name={`input-form-${props.record.id}`}>
            <FormItem
                name="mpn"
                initialValue={props.record.new_prodcut_id ? props.record.newMpn : props.record.mpn}
                style={{ margin: 0, padding: 0 }}
            >
                <Input
                    id={props.record.id.toString()}
                    ref={(el) => (props.inputRefMpn.current[props.index] = el)}
                    onKeyDown={handlerMpn(
                        props.index,
                        props.form,
                        props.products,
                        props.setProducts,
                        props.inputRefMpn
                    )}
                    onBlur={(e) => {
                        mpnInputBlur(e.target.value, props.record.id);
                        //setFocusId(undefined);
                        props.setProducts((curr) =>
                            curr.map((d) =>
                                d.id === props.record.id
                                    ? {
                                          ...d,
                                          openMpn: false,
                                          [props.record.new_prodcut_id ? "newMpn" : "mpn"]: e.target.value,
                                      }
                                    : d
                            )
                        );
                    }}
                    //onFocus={(e) => setFocusId(Number(e.target.getAttribute("id")))}
                    //bordered={focusId !== props.record.id ? false : true}
                />
            </FormItem>
        </Form>
    ) : (
        <div
            className="all-products-editable-name"
            style={{ cursor: "pointer", paddingLeft: "12px", width: "100%", height: "30px", marginTop: "8px" }}
            onClick={() => openNewMpn(props.record, props.index)}
        >
            {props.record.new_prodcut_id ? props.record.newMpn : HighlilghtWord(props.search, props.record.mpn)}
        </div>
    );
};

export default ColumnMpn;
