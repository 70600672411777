import axios from "axios";
import { Dispatch } from "react";
import showMessage from "../../shared/MessagesInfo/message";
import {
  ResetShopOne,
  RESET_SHOP_SINGLE,
  ShopAll,
  ShopCreate,
  //ShopEdit,
  ShopOne,
  SHOP_ALL,
  SHOP_CREATE,
  SHOP_EDIT,
  SHOP_SINGLE,
} from "./shopActionType";

export const getAllShops =
  (sfActive: number, currentCountryId: number | null, setLoading?:React.Dispatch<React.SetStateAction<boolean>> ) =>
  async (dispatch: Dispatch<ShopAll>) => {
    setLoading && setLoading(true)
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_API}/shop/list?active=${sfActive}${
          currentCountryId ? `&country_id=${currentCountryId}` : ""
        }`
      );
      dispatch({ type: SHOP_ALL, payload: data });
      setLoading && setLoading(false)
    } catch (err) {
      //errorMessage(err.response.data.message)
      console.log(err);
      setLoading && setLoading(false)
    }
  };

export const getOneShop =
  (
    id: string | undefined,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  async (dispatch: Dispatch<ShopOne>) => {
    setLoading && setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_API}/shop/show?id=${id}`
      );
      dispatch({ type: SHOP_SINGLE, payload: data });
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
//-------------clear one shop from redux ------------------

export const resetOneShop = () => async (dispatch: Dispatch<ResetShopOne>) => {
  dispatch({ type: RESET_SHOP_SINGLE, payload: {} });
};

export const createShop =
  (shop: any) => async (dispatch: Dispatch<ShopCreate>) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_API}/shop/create`,
        shop
      );
      showMessage(data.message);
      dispatch({ type: SHOP_CREATE, payload: data.data });
    } catch (err) {
      //errorMessage(err.response.data.message)
      console.log(err);
    }
  };

export const editShop =
  (
    shop: any,
    setImg: boolean,
    handleDrawerEditShopOk: () => void,
    setCounter: React.Dispatch<React.SetStateAction<number>>,
    isLogoEdit?: boolean
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_API}/shop/edit`,
        shop
      );
      showMessage(data.message);
      dispatch({ type: SHOP_EDIT, payload: data.data });
      handleDrawerEditShopOk();
      setCounter((curr) => curr + 1);
      isLogoEdit &&
        setTimeout(() => {
          dispatch(getOneShop(String(shop.get("id"))));
        }, 500);
    } catch (err) {
      console.log(err);
    }
  };
