import { Badge, Button, Col, Divider, Row } from "antd";
import React, { useState } from "react";
import TextEditor from "./TextEditor";
import { CheckCircleOutlined } from "@ant-design/icons";
import showMessage from "../../../../shared/MessagesInfo/message";
import {
  DescriptionReviewType,
  ExpandRowDataType,
  FlagStatus,
  showStatus,
} from "../../pages/DescriptionReview";
import { RootStore } from "../../../../Store";
import axios from "axios";
interface AllDescriptionsProps {
  record: DescriptionReviewType;
  state: RootStore;
  expandRowData: ExpandRowDataType | null;
  setExpandRowData: React.Dispatch<
    React.SetStateAction<ExpandRowDataType | null>
  >;
  getCategories: () => Promise<void>;
  setDescRevData: React.Dispatch<React.SetStateAction<DescriptionReviewType[]>>;
  currentCountryName: string;
}
const AllDescriptions: React.FC<AllDescriptionsProps> = (props) => {
  const {
    record,
    state,
    expandRowData,
    setExpandRowData,
    getCategories,
    setDescRevData,
    currentCountryName,
  } = props;
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const [descriptionDeleteLoading, setDescriptionDeleteLoading] =
    useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isDescDeleted, setIsDescDeleted] = useState(false);
  const [descriptionTextLoading, setDescriptionTextLoading] = useState(false);
  const saveNewDescription = async () => {
    const arrayDescCountry = state.countries.map((c) => ({
      ...(expandRowData && {
        [`preview_text_${c.name}`]:
          expandRowData.preview[`preview_text_${c.name}`],
      }),
      ...(expandRowData && {
        [`flag_${c.name}`]: expandRowData.preview[`flag_${c.name}`],
      }),
    }));
    let tempObj: { [key: string]: string | number | null } = {};
    arrayDescCountry.forEach((obj) => {
      for (let key in obj) {
        tempObj[key] = obj[key];
      }
    });
    const objToSend = {
      id: record.id,
      ...tempObj,
    };
    setDescriptionLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_API}/product/item/update`,
        objToSend
      );
      showMessage(data.message);
      setDescriptionLoading(false);
      setIsSaved(true);
      getCategories();
      const newDescriptionFlag =
        objToSend[`flag_${currentCountryName}` as keyof typeof objToSend];
      setDescRevData((curr) =>
        curr.map((d) =>
          d.id === record.id
            ? { ...d, description_flag: newDescriptionFlag }
            : d
        )
      );
    } catch (err) {
      console.log(err);
    }
  };
  const deleteDescripions = async () => {
    const arrayDescCountry = state.countries.map((c) => ({
      ...(expandRowData && { [`preview_text_${c.name}`]: null }),
      ...(expandRowData && { [`flag_${c.name}`]: FlagStatus.NOT_CHANGED }),
    }));
    let tempObj: { [key: string]: string | number | null } = {};
    arrayDescCountry.forEach((obj) => {
      for (let key in obj) {
        tempObj[key] = obj[key];
      }
    });
    const objToSend = {
      id: record.id,
      ...tempObj,
    };
    setDescriptionDeleteLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_API}/product/item/update`,
        objToSend
      );
      setExpandRowData((curr) =>
        curr
          ? { ...curr, description: { ...curr.preview, ...objToSend } }
          : null
      );
      showMessage(data.message);
      setDescriptionDeleteLoading(false);
      setExpandRowData((curr) =>
        curr ? { ...curr, preview: { ...curr.preview, ...tempObj } } : null
      );
      setIsDescDeleted(true);
    } catch (err) {
      console.log(err);
    }
  };
  const clearDescription = (country: string) => {
    setExpandRowData((curr) =>
      curr
        ? {
            ...curr,
            preview: {
              ...curr.preview,
              [`preview_text_${country}`]: null,
              [`flag_${country}`]: FlagStatus.NOT_CHANGED,
            },
          }
        : null
    );
  };

  const getDescriptionText = async () => {
    setDescriptionTextLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_API}/reviews/product-description?product_id=${record.id}`
      );
      const arrayDescCountry = state.countries
        .map((c) => ({
          [`preview_text_${[c.name]}`]: data[c.theebox_locale_code] ?? null,
          [`flag_${c.name}`]: data[c.theebox_locale_code]
            ? FlagStatus.AI
            : FlagStatus.NOT_CHANGED,
        }))
        .filter((c) => c);
      let tempObj: { [key: string]: string } = {};
      arrayDescCountry.forEach((obj) => {
        for (let key in obj) {
          tempObj[key] = obj[key];
        }
      });
      setExpandRowData((curr) =>
        curr ? { ...curr, preview: { ...curr.preview, ...tempObj } } : null
      );
      showMessage("Description is filled");
      setDescriptionTextLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Row
      justify="center"
      style={{
        border: "1px solid lightgray",
        borderRadius: "8px",
        marginTop: "1rem",
      }}
    >
      <Col span={24}>
        <Divider>Description</Divider>
        <Button
          style={{ marginLeft: "0.5rem" }}
          onClick={getDescriptionText}
          loading={descriptionTextLoading}
        >
          Get Description Text
        </Button>
      </Col>
      {state.countries.map((c) => (
        <Col
          span={6}
          style={{ padding: "1rem 0.5rem 1rem 0.5rem" }}
          key={`${c.locale_code}`}
        >
          <div
            style={{
              backgroundColor: "#EAF6F6",
              border: "1px solid lightblue",
              borderRadius: "8px",
            }}
          >
            <div className="desc-rev-country-header">
              {c.name.toUpperCase()}
            </div>
            <Col
              span={24}
              style={{ border: "1px solid whitesmoke", borderRadius: "10px" }}
            >
              <Row align="middle" justify="center" className="desc-rev-card">
                <Badge
                  status={
                    typeof expandRowData?.preview[`flag_hr`] === "number"
                      ? showStatus(
                          Number(expandRowData?.preview[`flag_${c.name}`])
                        )
                      : undefined
                  }
                  style={{ margin: "0 0.5rem 0.5rem 0" }}
                  className="descRev-badge-dot"
                />
                <h5 style={{ textAlign: "center", fontSize: "20px" }}>
                  Description
                </h5>
                <Col span={24}>
                  <TextEditor
                    country={c.name}
                    type="preview"
                    expandRowData={expandRowData}
                    setExpandRowData={setExpandRowData}
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  <Button
                    onClick={() => clearDescription(c.name)}
                    danger
                    style={{ width: "80px" }}
                  >
                    {`Clear ${c.name.toUpperCase()}`}
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      ))}
      <Button
        style={{ margin: "0.5rem 1rem" }}
        type="primary"
        onClick={saveNewDescription}
        loading={descriptionLoading}
        icon={isSaved && <CheckCircleOutlined />}
      >
        Save Description
      </Button>
      <Button
        style={{ margin: "0.5rem 1rem" }}
        danger
        type="primary"
        onClick={deleteDescripions}
        loading={descriptionDeleteLoading}
        icon={isDescDeleted && <CheckCircleOutlined />}
      >
        Delete All Descriptions
      </Button>
    </Row>
  );
};
export default AllDescriptions;
