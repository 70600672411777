import axios from "axios"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { USER_LOGOUT } from "../../../actions/userAction/userActionsType"
import authCheckStatus from "../../../shared/utils/authSeviceChecked"
import { RootStore } from "../../../Store"

//let pricesFirstInit:any=[]         //chenged because paginate


 
export const useAllPrices = ( 
    selectedShops:any, 
    noCategory:any, 
    searchField:any, 
    sortArr:any, 
    fillSelectSC:string[], 
    currentCountry:any, 
    setSearchLoading:any, 
    sameParam:number,  
    setCountDone:any,
    setAllPrices:any, 
    currentPage:number,
    skuTextArea:any,
    existInXml:any,
    ourCateogry: { level:string, id:string } | null,
    condition:string,
    sfActive:number,
    unMatched:number,
    userFilter:boolean,  
    setPricesFirstInit:any
    ) => {
   const dispatch = useDispatch()
   const state = useSelector((state: RootStore) => state)
   //const currentCountry = localStorage.getItem('currentCountry')? localStorage.getItem('currentCountry') : state.loggedInUser.user && state.loggedInUser.user.data.currentCountry
   
   const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1
   //console.log(currentCountryTest)

   const getPricesByShop = useCallback( async () => {
    const selectSCToStirng = fillSelectSC ? JSON.stringify(fillSelectSC.map( d => encodeURIComponent(d))):[]
    //console.log(ourCateogry)
    
    try {
        setSearchLoading((prev:any)=>true)
        const response = await axios.get(
            `${ 
                process.env.REACT_APP_URL_API 
            }/prices/list-company-prices?shop_ids=[${
                selectedShops
            }]&skus=${
                JSON.stringify(skuTextArea) 
            }&limit=100&page=${
                currentPage
            }&search=${
                searchField
            }${
                sortArr ? `&sort=${sortArr}`:''
            }&seller_categories=${
                selectSCToStirng
            }&country_id=${ 
                currentCountryId 
            }${ 
                existInXml ? '':'&available=1'
            }&uncategorised=${ 
                !noCategory ? '': 1        // uncategorized ? 1 :2 
            }${
                ourCateogry && `&l${ ourCateogry.level }_id=${ ourCateogry.id }`
            }&condition=${ 
                condition
            }&user_filter=${ 
                userFilter ? 1:0
            }&sf_active=${ sfActive }${
                !noCategory && unMatched === 1 ? `&unmatched=1`:''}`)
        //authCheckStatus(response.status)

        const data = response.data
        const newData = data.data.data.sort((a:any,b:any) =>{ 
           
            if ( noCategory ) {                              //if ( !uncategorized && noCategory ) {
                return (a.category_l3_id > b.category_l3_id) ? -1 : ((b.category_l3_id > a.category_l3_id) ? 1 : 0)
            }
            else {
                return (a.brand_name > b.brand_name) ? 1 : ((b.brand_name > a.brand_name) ? -1 : 0)
            }
            
        }
            ).map((singleData:any)=>{ 
                const mapImages = singleData.images.map((image:any)=>{
                    return {
                        ...image,
                        isChechedImg:false
                    }
                })
            return {
                ...singleData, 
                key:singleData.id, 
                isChanged:false,
                isChecked:false, 
                isNewProduct:false,
                new_image_url:false,
                toggleImage:false,
                wrongAutoChecked:false,
                isDisabledChanged:false,
                //ignorePrice:false,
                isMpn: singleData.mpn ? true : false, 
                [`name_${currentCountry}`]: singleData[`name_${currentCountry}`] ? singleData[`name_${currentCountry}`] : singleData.model,
                initName:singleData[`name_${currentCountry}`] ? singleData[`name_${currentCountry}`] : singleData.model,
                selected:false, 
                product_old_id:singleData.product_id,
                images:mapImages,
                //expandImgSettings:false,
                isMpnChange:false,
                ////////// for automatched F7
                isAMBrand:false,
                isAMCategory:false,
                isAMName:false,
                //////// for matchingmodal ctrl + shift
                checkToFlag:false // -------> for prices marked in table
               
            }
        })
        setCountDone(0)
        setPricesFirstInit({...data,data:{...data.data, data:newData}})
        setAllPrices({...data,data:{...data.data, data:newData/*.slice(0,100)*/}})
        setSearchLoading((prev:any)=>false)
    } catch ( err:any ) {
        if (err.response) {
            if (authCheckStatus(err.response.status)) {
               dispatch({ type: USER_LOGOUT, payload: {} })
            }
 
         }
    }
},[
    selectedShops, 
        noCategory, 
        //uncategorized, 
        searchField, 
        sortArr, 
        fillSelectSC, 
         
        currentCountry, 
        setCountDone, 
        setSearchLoading, 
        setAllPrices, 
        setPricesFirstInit, 
        currentPage, 
        skuTextArea, 
        dispatch, 
        existInXml, 
        currentCountryId, 
        ourCateogry, 
        condition,
        sfActive,
        unMatched,
        userFilter
    ])
    useEffect(() => {
       
        getPricesByShop()
    }, [ getPricesByShop,sameParam ])

    return  { getPricesByShop }
}