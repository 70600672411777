import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";
import { ColumnEditableProps, ProductType } from "../types/types";
import { handler } from "../utils/arrowKeyEvents";
import { HighlilghtWord } from "../utils/HighlightWord.ts";

const ColumnName: React.FC<ColumnEditableProps & { inputRef: any }> = (props) => {
    //const [focusId, setFocusId] = useState<number | undefined>();

    const pressEnter = (id: number, e: { name: string }) => {
        props.setProducts((curr) =>
            curr.map((d) =>
                d.id === id ? { ...d, openName: false, [props.record.new_prodcut_id ? "newName" : "name"]: e.name } : d
            )
        );
    };

    const openNewName = (record: ProductType, index: number) => {
        props.setProducts((curr) => curr.map((d) => ({ ...d, openName: d.id === record.id ? true : false })));
        props.form.setFieldValue("name", record.new_prodcut_id ? record.newName : record.name);
        //const findIndex = dataSource.findIndex((d) => d.id === record.id);
        setTimeout(() => {
            const next = props.inputRef.current[index];
            if (next) {
                next.focus();
            }
        }, 2);
    };

    const nameInputBlur = (text: string, id: number) => {
        props.setProducts((curr) =>
            curr.map((d) => (d.id === id ? { ...d, [props.record.new_prodcut_id ? "newName" : "name"]: text } : d))
        );
    };

    return props.record.openName ? (
        <Form form={props.form} onFinish={(e) => pressEnter(props.record.id, e)} name={`input-form-${props.record.id}`}>
            <FormItem
                name="name"
                initialValue={props.record.new_prodcut_id ? props.record.newName : props.record.name}
                style={{ margin: 0, padding: 0 }}
            >
                <Input
                    id={props.record.id.toString()}
                    //defaultValue={text}
                    ref={(el) => (props.inputRef.current[props.index] = el)}
                    onKeyDown={handler(props.index, props.form, props.products, props.setProducts, props.inputRef)}
                    onBlur={(e) => {
                        nameInputBlur(e.target.value, props.record.id);
                        //setFocusId(undefined);
                        props.setProducts((curr) =>
                            curr.map((d) =>
                                d.id === props.record.id ? { ...d, openName: false, name: e.target.value } : d
                            )
                        );
                    }}
                    //onFocus={(e) => setFocusId(Number(e.target.getAttribute("id")))}
                    //bordered={focusId !== props.record.id ? false : true}
                />
            </FormItem>
        </Form>
    ) : (
        <div
            style={{ cursor: "pointer", paddingLeft: "12px", width: "100%", height: "30px", marginTop: "8px" }}
            className="all-products-editable-name"
            onClick={() => openNewName(props.record, props.index)}
        >
            <span>
                {props.record.new_prodcut_id ? props.record.newName : HighlilghtWord(props.search, props.record.name)}
            </span>
        </div>
    );
};

export default ColumnName;
