import { Progress, Spin, Table } from "antd";
import ValueTable from "./ValueTable";
import { CategoryType, ShopType } from "../../types/AttributesAnalytics.type";
import ExpandIcon from "./ExpandIcon";
import { showValues } from "../../api/AttributesAnalytics";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../helpers/checkIsIncludes";

interface AttributeTableProps {
    category: CategoryType;
    shop: ShopType;
    setData: React.Dispatch<React.SetStateAction<CategoryType[]>>;
}

// const showAttributeStatus = (record:AttributeType) => {

//     return

// }

const AttributeTable = (props: AttributeTableProps) => {
    const { category, shop, setData } = props;

    return (
        <Table
            dataSource={shop.attributes}
            bordered
            columns={[
                {
                    title: "",
                    dataIndex: "attribute_key",
                    width: "300px",
                    render: (text, shop) =>
                        text && (
                            <div style={{ display: "inline-block" }}>
                                <span style={{ fontWeight: shop.isOpen ? 600 : "normal" }}>{text}</span>
                            </div>
                        ),
                },
                {
                    title: "",
                    dataIndex: "attribute_key",
                    width: "100px",
                    render: (_, shop) => <div className="attributes-analytics-count">{shop.total}</div>,
                },
                {
                    title: "",
                    dataIndex: "mapped_key_boolean",
                    width: "140px",
                    align: "center" as "center",
                    render: (text, record) =>
                        text ? (
                            <div>
                                <CheckCircleFilled style={{ color: "#42b883", fontSize: "18px" }} />
                                <span style={{ marginLeft: "0.5rem" }}>Mapped</span>
                                <span style={{ marginLeft: "0.5rem" }}>{`${record.count_is_mapped ?? 0}/${record.total ?? 0}`}</span>
                            </div>
                        ) : (
                            <div>
                                <CloseCircleFilled style={{ color: "#da5151", fontSize: "18px" }} />
                                <span style={{ marginLeft: "0.5rem" }}>Not Mapped</span>
                            </div>
                        ),
                },
                {
                    title: "",
                    dataIndex: "attribute_type",
                    align: "center" as "center",
                    width: "100px",
                    render: (text, record) => {
                        return record.main_attribute_id !== null ? (
                            <div style={{ display: "flex", flexDirection: "column", width: "130px" }}>
                                <span>Total passing</span>
                                {/* <span>
                                    {record.attribute_type === "range"
                                        ? Math.round(((record.count_is_in_range ?? 0) / record.total) * 100) + "%"
                                        : Math.round(((record.count_is_allowed_value ?? 0) / record.total) * 100) + "%"}
                                </span> */}

                                <Progress
                                    percent={
                                        record.attribute_type === "range"
                                            ? Math.round(((record.count_is_in_range ?? 0) / record.total) * 100)
                                            : Math.round(((record.count_is_allowed_value ?? 0) / record.total) * 100)
                                    }
                                />
                            </div>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", width: "130px" }}>
                                <Progress percent={0} />
                            </div>
                        );
                    },
                },
                {
                    title: "",
                    dataIndex: "attribute_type",
                    align: "center" as "center",
                    width: "120px",
                    render: (text) => (text ? <span>{capitalizeFirstLetter(text)}</span> : "-"),
                },
                {
                    title: "",
                    dataIndex: "attribute_type",
                    width: "130px",
                    align: "center" as "center",
                    render: (text, record) =>
                        record.attribute_type ? (
                            record.attribute_type === "range" ? (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span>Numeric </span>
                                    <span>
                                        {`${record.count_is_numeric ?? 0}/${record.total ?? 0}`}
                                        <span style={{ marginLeft: "10px" }}>({Math.round(((record.count_is_numeric ?? 0) / record.total) * 100) + "%"})</span>
                                    </span>
                                    {/* <span>In range :{record.count_is_in_range !== null ? (record.count_is_in_range / record.total) * 100 + "%" : ""} </span> */}
                                </div>
                            ) : (
                                <div>
                                    <span>Allowed values </span>
                                    <span>
                                        {record.count_is_allowed_value !== null ? `${record.count_is_allowed_value}/${record.total}` : ""}
                                        <span style={{ marginLeft: "10px" }}>({Math.round(((record.count_is_allowed_value ?? 0) / record.total) * 100) + "%"})</span>
                                    </span>
                                </div>
                            )
                        ) : (
                            "-"
                        ),
                },
                {
                    title: "",
                    dataIndex: "attribute_type",
                    align: "center" as "center",
                    // width: "100px",
                    render: (text, record) =>
                        record.attribute_type === "range" ? (
                            <div style={{ display: "flex", flexDirection: "column", width: "80px" }}>
                                <span>In range</span>
                                <span>
                                    {`${record.count_is_in_range ?? 0}/${record.total ?? 0}`}
                                    <span style={{ marginLeft: "10px" }}>({Math.round(((record.count_is_in_range ?? 0) / record.total) * 100) + "%"})</span>{" "}
                                </span>
                            </div>
                        ) : (
                            <div></div>
                        ),
                },
            ]}
            expandable={{
                expandIcon: ({ expanded, onExpand, record }) => <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />,
                onExpand: (expanded, attribute) => showValues(expanded, attribute, category.key, shop.key, setData),
                expandedRowRender: (attribute) => (attribute.values.length === 0 ? <Spin style={{ marginLeft: "5rem" }} /> : <ValueTable attribute={attribute} />),
                expandedRowClassName: () => "attributes-analytics-expanded-row",
            }}
            rowClassName={(attribute) => (attribute.isOpen ? "shop-attributes-open-row no-row-hover attributes-analytics-open-row" : "")}
            pagination={false}
            style={{ marginLeft: "3rem" }}
        />
    );
};

export default AttributeTable;
