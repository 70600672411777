import { Button, Col, Divider, Form, List, Row, Switch } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllCategoryL3 } from "../../../actions/category/categoryActions";
import showMessage from "../../../shared/MessagesInfo/message";
import { DeleteTwoTone } from "@ant-design/icons";
import CreateDescriptionAiModal from "../components/DescriptionAi/CreateDescriptionAiModal";

const DescriptionAi = () => {
    const [descriptionAi, setDescriptionAi] = useState<
        { id: number; category_l3_id: number; [key: string]: string | number | boolean; active: boolean }[]
    >([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingCreateData, setLoadingCreateData] = useState(false);
    const [deleteId, setDeleteId] = useState<number | undefined>();
    const [updateId, setUpdateId] = useState<number | undefined>();
    const state = useSelector((store: RootStore) => store);
    const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;
    const currentCountryName = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const getDescriptionAi = useCallback(async () => {
        setLoadingData(true);
        const baseUrl = process.env.REACT_APP_URL_API;

        try {
            const url = `${baseUrl}/product/description-ai/list-categories`;
            const { data } = await axios.get(url);
            // const originalArray = data.data;
            // const resultArray = Array.from({ length: 100 }, (_, i) => originalArray[i % originalArray.length]);
            setDescriptionAi(
                data.data.sort((a: { [key: string]: string }, b: { [key: string]: string }) =>
                    a[`name_${currentCountryName}`].localeCompare(b[`name_${currentCountryName}`])
                )
            );
            setLoadingData(false);
        } catch (err) {
            console.log(err);
        }
    }, [currentCountryName]);

    useEffect(() => {
        getDescriptionAi();
    }, [getDescriptionAi]);

    const saveNewCategoryDescrption = async (e: { category_l3_id: string; active: boolean }) => {
        const obj = {
            category_l3_id: Number(e.category_l3_id),
            active: e.active.toString(),
        };
        setLoadingCreateData(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const url = `${baseUrl}/product/description-ai/create-category`;
            const { data } = await axios.post(url, obj);
            showMessage(data.message);
            form.resetFields();
            getDescriptionAi();
            setLoadingCreateData(false);
        } catch (err) {
            console.log(err);
            setLoadingCreateData(false);
        }
    };

    const handleSwitch = async (id: number, acitve: boolean) => {
        setUpdateId(id);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const url = `${baseUrl}/product/description-ai/update-category?id=${id}&active=${acitve}`;
            const { data } = await axios.put(url);
            showMessage(data.message);
            setDescriptionAi((curr) => curr.map((d) => (d.id === id ? { ...d, active: !d.active } : d)));
            setUpdateId(undefined);
        } catch (err) {
            console.log(err);
            setUpdateId(undefined);
        }
    };

    const deleteCategory = async (id: number) => {
        setDeleteId(id);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const url = `${baseUrl}/product/description-ai/delete-category?id=${id}`;
            const { data } = await axios.delete(url);
            showMessage(data.message);
            setDescriptionAi((curr) => curr.filter((d) => d.id !== id));
            setDeleteId(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Description AI" />

            <Col span={17} className="automatched-prices-box" style={{ padding: "1rem", minHeight: "92vh" }}>
                <Divider>Ai Description Category</Divider>

                <CreateDescriptionAiModal
                    form={form}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    state={state}
                    currentCountryName={currentCountryName}
                    saveNewCategoryDescrption={saveNewCategoryDescrption}
                    loadingCreateData={loadingCreateData}
                />
                <Row justify="center">
                    <List
                        size="small"
                        header={
                            <Row justify="space-between">
                                <b>Categories</b>
                                <Button onClick={showModal} style={{ marginLeft: "1rem" }} size="small">
                                    Create
                                </Button>
                            </Row>
                        }
                        bordered
                        dataSource={descriptionAi}
                        style={{ width: "450px" }}
                        renderItem={(item) => (
                            <List.Item
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: deleteId === item.id ? "whitesmoke" : "",
                                }}
                            >
                                {`${item.category_l3_id}: ${item[`name_${currentCountryName}`]}`}{" "}
                                <Row>
                                    <Switch
                                        checked={item.active}
                                        onChange={(e) => handleSwitch(item.id, e)}
                                        loading={item.id === updateId}
                                    />
                                    <Button
                                        icon={<DeleteTwoTone />}
                                        size="small"
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => deleteCategory(item.id)}
                                        loading={deleteId === item.id ? true : false}
                                    />
                                </Row>
                            </List.Item>
                        )}
                        loading={loadingData}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default DescriptionAi;
