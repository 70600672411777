import React from 'react'
import { Row, Col, Select,Form, Divider, Spin, Alert } from 'antd'
import InputE from '../../../shared/FormElements/Input'
import ButtonE from '../../../shared/FormElements/Button'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../../Store'
import { getAllCategoryL3 } from '../../../actions/category/categoryActions'
import { getOneAttribute, updateAttribute } from '../../../actions/attribute/attributeAction'

const EditAttribute = () => {
    const [ loading, setLoading ] = useState(true)
    const state = useSelector(( state:RootStore ) => state)
    const dispatch = useDispatch()
    const [ form ] = Form.useForm()
    const history = useHistory()
    const { Option } = Select
    const param:any = useParams()
    const currentCountry = localStorage.getItem('currentCountry')? localStorage.getItem('currentCountry') : state.loggedInUser.user && state.loggedInUser.user.data.currentCountry

    useEffect(()=>{
        if ( param.id ) {
            dispatch(getOneAttribute(param.id, form , setLoading))
            dispatch(getAllCategoryL3(1))
        }
    }, [ param.id, form, dispatch ])
    const editAttribute = async (obj:any) => {
        const objToSend = {
            id:param.id,
            ...obj
        }
        dispatch(updateAttribute(objToSend, form , history))
    }
   
    const handleSubmit = ( e:any ) => {
        editAttribute(e)
    }
    if ( loading ) {
        return  <Spin tip="Loading edit data" style={{marginTop:'3rem'}}>
                    <Alert
                        message="Loading data..."
                        type="info"
                    />
                </Spin>
    }
    return (
        <Row>
            <Col span={6} offset={8}>
                <Divider>Create new Category</Divider>
                <Form 
                    form={form}
                    name="create-attribute-form" 
                    labelCol={{ span: 8 }} 
                    onFinish={ handleSubmit }
                >
                    <Form.Item
                        label="Category L3" 
                        name="category_l3_id"
                        rules={[{ required: true, message: 'Missing Category L3' }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '180px', marginBottom:'0.5rem' }}
                            placeholder="Select category"
                            filterOption={(input, option:any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                state.categoriesL3.data && state.categoriesL3.data.map((option:any)=>(
                                    <Option value={ option.id } key={ option.id }>{ option[`name_${ currentCountry }`] }</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <InputE 
                        label="Name" 
                        name={`name_${ currentCountry }`}
                        rules={[{ required: true, message: 'Missing Name rs' }]}
                    />
                    <InputE 
                        label="Name Si" 
                        name="name_si"
                    />
                    <InputE 
                        label="Name En" 
                        name="name_en"
                    /> 
                    <InputE 
                        label="Type," 
                        name="type"
                        rules={[{ required: true, message: 'Missing Name Type' }]}
                    />
                    <InputE 
                        label="Min Value," 
                        name="min_value"
                        rules={[{ required: true, message: 'Missing Min Value' }]}
                    />
                    <InputE 
                        label="Max Value," 
                        name="max_value"
                        rules={[{ required: true, message: 'Missing Max Value' }]}
                    />
                    <InputE 
                        label="Eponuda Id," 
                        name="eponuda_id"
                        rules={[{ required: true, message: 'Missing Eponuda Id' }]}
                    />                   
                    <ButtonE 
                        type="primary" 
                        htmlType="submit" 
                        title="Edit Category"
                        wrapperCol={{ offset: 8, span: 16 }}
                    />
                </Form>
            </Col>
        </Row>
    )
    
}

export default EditAttribute
