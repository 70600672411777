
//product list

export const PRODUCT_LIST = "PRODUCT_LIST"

export interface ProductList {
    type: typeof PRODUCT_LIST
    payload:any
}


//create new product
export const PRODUCT_CREATE = "PRODUCT_CREATE"

export interface ProductCreate {
    type: typeof PRODUCT_CREATE
    payload:any
}
