import { GET_ALL_COUNTRIES, GetAllCountries } from "../actions/countries/countriesActionType"

type InitState = {name:string, value:number, order:number, langOrder:number, currency:string, locale_code:string, theebox_locale_code: string, link:string } []

export const countriesReducer = ( state: InitState = [], action:GetAllCountries ) => {
    switch ( action.type ) {
        case GET_ALL_COUNTRIES:
            localStorage.setItem('countries', JSON.stringify(action.payload.map( c => c.name)))
            return action.payload
        default:
            return state
    }
}