import { Modal } from 'antd'
import React, { useState } from 'react'
import DatePickerFromToReports from './DatePickerFromToReports'
import { DatePickerNewProducts } from './NewShopProducts'
import '../style/PricesProductsChart.css'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import moment from 'moment';
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'

type charDataArr = {
  products_in_xml:number 
  valid_in_stock:number 
  valid_stock_categorized_distinct:number 
  errored_prices:number
  timeInDay:any
  dayInMonth:any
  created_at:any
}

interface PricesProductsProps {
    isChartModalVisible:boolean
    handleChaartModalCancel:() => void
    chartData:{message:string, data:charDataArr[]}
    date:DatePickerNewProducts
    setDate:React.Dispatch<React.SetStateAction<DatePickerNewProducts>>
}

const getDaysArray = function(s:Date,e:any) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};

const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = entry;

  return <span style={{ color }}>{value}</span>
}


  const PricesProductsChart:React.FC<PricesProductsProps> = ({ isChartModalVisible, handleChaartModalCancel, chartData, date, setDate }) => {
    const [ showLabel, setShowLable ] = useState<any>({
      Productsinxml:true,
      InStock:true,
      InStockCategorized:true,
      ErroredPrices:true
    })
    const windowSize = useWindowSize()
 
    const daylist = getDaysArray(date.startDate,date.endDate);
    const allDays = daylist.map((v)=>v.toISOString().slice(0,10))

  const data:charDataArr[] = chartData.data.filter(item => allDays.includes(item.dayInMonth)).sort((a, b) =>{ 
    const date1:any = new Date(a.created_at)
    const date2:any = new Date(b.created_at)
    return  date1 - date2
  })
  
  const changeLabelTitles = ( text:string ) => {
    const clearText = text.replaceAll(" ","")
    const objBoolean =  showLabel[`${clearText}`]
    console.log(clearText)
    setShowLable((current:any) =>{ return {...current, [`${clearText}`]:!objBoolean  }})
  }


  return (
    <Modal 
      title={ chartData.message } 
      open={isChartModalVisible} 
      onCancel={handleChaartModalCancel}
      width={'100vw'}
      footer={false}
    >
      <DatePickerFromToReports date={date} setDate={setDate} /*setRadioDate={setRadioDate}*/ position="bottom" isDate/>
      <div >
      <LineChart
        width={(windowSize.width/1700)*1600}
        height={600}
        data={data}
        
        margin={{ top: 55, right: 30, left: 20, bottom:45 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={moment( date.startDate ).format("YYYY-MM-DD") === moment( date.endDate ).format("YYYY-MM-DD") ? "timeInDay":"created_at"} 
        />
        <YAxis />
        <Tooltip />
        <Legend formatter={renderColorfulLegendText} onClick={(e)=>changeLabelTitles(e.value)} className="prices-products-chart-label" />
        <Line
          type="linear"
          dataKey= {showLabel.Productsinxml &&  "products_in_xml"}
          name="Products in xml"
          stroke={showLabel.Productsinxml ? "darkorange":"lightgray"}
          activeDot={{ r: 8 }}
          
        />
        <Line 
          type="monotone" 
          dataKey={showLabel.InStock && "valid_in_stock" }
          name="In Stock"
          stroke={showLabel.InStock ? "#82ca9d" :"lightgray"}
          
        />
        <Line 
          type="monotone" 
          dataKey={showLabel.InStockCategorized && "valid_stock_categorized_distinct" }
          name='In Stock Categorized'
          stroke={showLabel.InStockCategorized ? "red":"lightgray"}
        />
          <Line 
          type="monotone" 
          dataKey={showLabel.ErroredPrices && "errored_prices" }
          name="Errored Prices"
          stroke={showLabel.ErroredPrices ? "blue" :"lightgray"}
        />
      </LineChart>
      </div>
    </Modal>
  )
}

export default PricesProductsChart