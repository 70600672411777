import React from 'react'
import { Form, Input} from "antd";

interface AddUserProps  {
    label: string 
    name:any
    rules?:any
    desc?:string
    type?:string
    disabled?:boolean
    className?:string
    value?:string | number
}
const InputE:React.FC<AddUserProps> = props => {
    return (
        <Form.Item 
            key={ props.name } 
            label={ props.label } 
            name={ props.name }
            rules={ props.rules }
        >
            <Input disabled={props.disabled}
                name={props.name} 
                type={ props.name==='password' || props.name==='password_confirmation'? 'password': props.type === 'number' ? 'number':'text'}
                placeholder={ `${props.label} ${props.desc? ` (${props.desc})`:''}` }
                className={props.className}
                value={props.value}
        /></Form.Item>
    )
}

export default InputE
