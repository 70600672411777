import { Checkbox, Col, Divider, Input, Modal, Row } from 'antd'
import axios from 'axios'

import React from 'react'
import showMessage from '../../../shared/MessagesInfo/message'
import openNotification from '../../../shared/MessagesInfo/WarningBox'
import { ModalDataType } from '../page/ShopTokPage'
import ShopTokTextEditor from './ShopTokTextEditor'
const { TextArea } = Input

interface ShopTokPagesProps {
    modalData:ModalDataType|null
    setModalData:React.Dispatch<React.SetStateAction<ModalDataType | null>>
    modalVisible: boolean
    setModalVisible:React.Dispatch<React.SetStateAction<boolean>>
    closeModal: () => void
    initContent: string
    saveTextChanges: () => Promise<void>
    createNew:boolean
    setCreateNew:React.Dispatch<React.SetStateAction<boolean>>
    allUrls:{
        id: number;
        type: string;
    }[],
    setAllUrls: React.Dispatch<React.SetStateAction<{
        id: number;
        type: string;
    }[]>>
}

const ShopTokPagesModal:React.FC<ShopTokPagesProps> = ({allUrls,setAllUrls, modalData, setModalData, modalVisible, setModalVisible, closeModal, initContent, saveTextChanges, createNew, setCreateNew }) => {
    const createNewPage = async () => {

        let urlArr = allUrls.map((url:any) => url.type)
        if (createNew && urlArr && urlArr.includes(modalData?.type.trim())){
            openNotification(`Url page ${modalData?.type} already exists`)
        }
        
        else {

            try {
                const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/shoptok/pages/create`,modalData )
                showMessage(data.message)
                /////
                setAllUrls( (currentData:any) => [...currentData,{id:new Date().getTime(), type:modalData?.type}])
                ////
                setModalData(null)
                setModalVisible(false)
                setCreateNew(false)
            } catch ( err:any ) {
                const message1 = "Url field is required |"
                const message2 = "Title field is required |"
                const message3 = 'Meta title field is required |'
                const message4 = 'Description field is required |'
                openNotification(`${ !modalData?.type.trim() ? message1:''}
                                  ${ !modalData?.title.trim() ? message2:''}
                                  ${ !modalData?.meta_title.trim() ? message3:''}
                                  ${ !modalData?.content.trim() ? message4:''}`)
                
            }
        }
    }
    return (
        <React.Fragment>
            <Modal
                title={ createNew ?'Create new page': modalData && <p>Url: {<strong>{modalData.type}</strong>}</p>}
                centered
                open={modalVisible}
                onCancel={ closeModal }
                width={ '80vw'}
                footer={false}
            >   
                <Checkbox checked={modalData?.active} onChange={(e) => setModalData(currentData => currentData && {...currentData, active:e.target.checked})}>Active</Checkbox>
                <Row gutter={16} justify='center'>
              
                    <Col className="gutter-row" span={12} style={{ marginTop:'1rem' }} >
                        <label htmlFor='url'><strong>Url</strong></label>
                        <Input id='url' size="large" placeholder='Url'  value={ modalData ? modalData.type:'' } onChange={(e) =>setModalData(currentData => currentData && {...currentData, type:e.target.value})} />
                    </Col>
                    <Col className="gutter-row" span={12} style={{ marginTop:'1rem' }}>
                        <label htmlFor='title'><strong>Title</strong></label>
                        <Input id='title' size="large" placeholder='Title'  value={ modalData ? modalData.title:'' } onChange={(e) =>setModalData(currentData => currentData && {...currentData, title:e.target.value})} />
                    </Col>
                    <Col className="gutter-row" span={12} style={{ marginTop:'2rem' }} >
                        <label htmlFor='meta_title'><strong>Meta Title</strong></label>
                        <Input id='meta_title' size="large" placeholder='Meta Title'  value={ modalData ? modalData.meta_title:'' } onChange={(e) =>setModalData(currentData => currentData && {...currentData, meta_title:e.target.value})} />
                    </Col>
                    <Col className="gutter-row" span={12} style={{ marginTop:'2rem' }}>
                        <label htmlFor='meta_description'><strong>Meta Description</strong></label>
                        <TextArea id='meta_description'  rows={4} placeholder='Meta Description'  value={ modalData?  modalData.meta_description:'' } onChange={(e) =>setModalData(currentData => currentData && {...currentData, meta_description:e.target.value})} />
                    </Col>
                    <Col span={24}  >
                        <Divider orientation="left"></Divider>
                        <ShopTokTextEditor initContent={initContent}  setModalData={setModalData}/>
                    </Col>
                   
                </Row >
                <button className='u-button' style={{ marginTop:'10px',backgroundColor:createNew? '#1dad9b':''}} onClick={() => createNew ? createNewPage (): saveTextChanges ()}>{ createNew ? 'Create':'Update'}</button>
                
            </Modal>
        </React.Fragment>
  )
}

export default ShopTokPagesModal