import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "antd";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import { CategoryType } from "../pages/AllCategories";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TextArea from "antd/es/input/TextArea";
import "../style/CategoryPrevText.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
//import { Editor } from "@tinymce/tinymce-react";

interface CategoryPrevTextProps {
  currentCountryName: string;
  description: any;
  setDescEditorText: React.Dispatch<React.SetStateAction<boolean>>;
  categories: CategoryType[];
  setCategories: React.Dispatch<React.SetStateAction<CategoryType[]>>;
  setDescription: React.Dispatch<React.SetStateAction<CategoryType | null>>;
}

const CategoryPrevText: React.FC<CategoryPrevTextProps> = ({
  currentCountryName,
  description,
  setDescription,
  setDescEditorText,
  categories,
  setCategories,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [showSourceCode, setShowSourceCode] = useState(false);
  const [form] = Form.useForm();

  const saveDesText = async () => {
    const objectToSend = {
      id: description?.id,
      level_id: Number(description?.type[1]),
      parent_id:
        Number(description?.type[1]) - 1 === 0
          ? 0
          : description &&
            description[
              `category_l${
                Number(description?.type[1]) - 1
              }_id` as keyof CategoryType
            ],
      [`description_${currentCountryName}`]: editorData,
    };
    setLoadingSave(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_API}/product/category/update`,
        objectToSend
      );
      const newData = categories.map((item: any) => {
        if (item.id === description?.id) {
          return {
            ...item,
            isMetaTitle: false,
            isMetaKeywords: false,
            isMetaDescription: false,
            inputError: false,
            [`description_${currentCountryName}`]: editorData
              ? editorData
              : null, //???
          };
        } else {
          return {
            ...item,
            isMetaTitle: false,
            isMetaKeywords: false,
            isMetaDescription: false,
            inputError: false,
          };
        }
      });
      setCategories(newData); //find newData in AllCategories and set it here.....???????????????????????????????????
      setDescEditorText(false);
      //setDescription(null)
      showMessage(data.message);
      setDescription(null);
      setEditorData("");
      //setTestValue("");
      setLoadingSave(false);
    } catch (err) {
      console.log(err);
    }
  };

  const init = description[`description_${currentCountryName}`];
  const [editorData, setEditorData] = useState(init?.toString());
  useEffect(() => {
    setEditorData(description[`description_${currentCountryName}`]);
  }, [description, currentCountryName]);

  const handleEditorChange = (event: any, editor: ClassicEditor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleSourceCode = () => {
    if (!showSourceCode) {
      form.setFieldValue("source-code", editorData ?? "");
      setShowSourceCode(true);
    } else {
      setEditorData(form.getFieldValue("source-code"));
      form.resetFields();
      setShowSourceCode(false);
    }
  };

  return (
    <>
      <div style={{ height: "700px" }} className="category-desc-ckeditor">
        {!showSourceCode ? (
          <CKEditor
            editor={ClassicEditor}
            data={editorData ?? ""}
            onChange={handleEditorChange}
          />
        ) : (
          <Form form={form}>
            <Form.Item name="source-code">
              <TextArea rows={30} />
            </Form.Item>
          </Form>
        )}
      </div>
      <Row justify="space-between">
        <Button
          type="primary"
          style={{ marginTop: "0.5rem" }}
          onClick={() => saveDesText()}
          loading={loadingSave}
          disabled={showSourceCode}
        >
          Save Description
        </Button>
        <Button onClick={handleSourceCode} style={{ marginTop: "0.5rem" }}>
          {!showSourceCode ? (
            "Open Source Code"
          ) : (
            <div>
              <ArrowLeftOutlined /> Back
            </div>
          )}
        </Button>
      </Row>
    </>
  );
};

export default CategoryPrevText;
