import { Col, Image } from 'antd'
import React from 'react'

type StoredImageProps = {
    item: {id: number, picture_url: string;}
}

const StoredImage:React.FC<StoredImageProps> = (props) => {
  return (
    <Col style={{ margin:' 0 10px 10px 0', borderRadius:'5px', overflow:'hidden', border:'1px dashed lightgray' }} key={props.item.id}>
      <Image
        width={100}
        height={100}
        style={{ objectFit:'contain' }}
        src={ props.item.picture_url }
      />
    </Col>
  )
}

export default StoredImage