import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { PicturesType } from "../../../types/type";
import showMessage from "../../../../../shared/MessagesInfo/message";

interface CreateImageFormType {
    productId: number | undefined;
    setProductInfo: React.Dispatch<React.SetStateAction<{ name: string; value: string | PicturesType[] }[]>>;
}

const CreateImageForm = (props: CreateImageFormType) => {
    const [form] = Form.useForm();
    const { productId, setProductInfo } = props;
    const [imageLoading, setImageLoading] = useState(false);

    const saveNewImage = async (e: { image_url: string }) => {
        const obj = {
            product_id: productId,
            image: e.image_url,
        };

        setImageLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/image/add-image`, obj);
            const newImage = {
                height: data.data.height,
                name: data.data.name,
                primaryimage: data.data.primaryimage,
                source_url: data.data.source_url,
                width: data.data.width,
            };
            setProductInfo((curr) => curr.map((p) => (p.name === "pictures" && typeof p.value !== "string" ? { ...p, value: [...p.value, newImage] } : p)));
            form.resetFields();
            showMessage(data.message);
            setImageLoading(false);
        } catch (err) {
            console.log(err);
            setImageLoading(false);
        }
    };

    const checkImageUrl = Form.useWatch("image_url", form);

    return (
        <Form layout="inline" form={form} onFinish={saveNewImage} autoComplete="off">
            <Form.Item name="image_url">
                <Input placeholder="Image url" style={{ width: "600px" }} autoComplete="off" />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary" disabled={!checkImageUrl || (checkImageUrl && checkImageUrl.trim().length === 0)} loading={imageLoading}>
                    Add New Image
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateImageForm;
