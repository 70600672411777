import { notification } from 'antd';


  const key = 'updatable';

  const SuccessNotification = ( msg:string ) => {
    
      notification.info({
        key,
        message: 'Update',
        description: msg,
        placement:'top',
        duration:3
      })
  }

export default SuccessNotification