export const MAIN_MAP_ALL = "MAIN_MAP_ALL"

export interface mainMapAll {
    type: typeof MAIN_MAP_ALL
    payload:any
} 

export const MAIN_MAP_UPDATE_FIELD = "MAIN_MAP_UPDATE_FIELD"

export interface MainMapUpdateField {
    type: typeof MAIN_MAP_UPDATE_FIELD
    payload:any
} 

export const MAIN_MAP_ADD_FIELD = "MAIN_MAP_ADD_FIELD"

export interface MainMapAddField {
    type: typeof MAIN_MAP_ADD_FIELD
    payload:any
} 

export const MAIN_MAP_OPTIONS = "MAIN_MAP_OPTIONS"

export interface MainMapOptions {
    type: typeof MAIN_MAP_OPTIONS
    payload:any
} 

//-------shop map--------

export const SHOP_MAP_NEW_FIELDS = "SHOP_MAP_NEW_FIELDS"

export interface shopMapNewFields {
    type: typeof SHOP_MAP_NEW_FIELDS
    payload:any
} 

//-------shop map--------

export const SHOP_MAP_ALL = "SHOP_MAP_ALL"

export interface ShopMapAll {
    type: typeof SHOP_MAP_ALL
    payload:any
}

//------- clear shop map--------

export const SHOP_MAP_CLEAR = "SHOP_MAP_CLEAR"

export interface ShopMapClear {
    type: typeof SHOP_MAP_CLEAR
    payload:any
}

//-------shop map--------

export const SHOP_MAP_UPDATE_FIELD = "SHOP_MAP_UPDATE_FIELD"

export interface ShopMapUpdateField {
    type: typeof SHOP_MAP_UPDATE_FIELD
    payload:any
}

//---------add map new field-----

export const SHOP_MAP_ADD_NEW_FIELD = "SHOP_MAP_ADD_NEW_FIELD"

export interface ShopMapAddNewField {
    type: typeof SHOP_MAP_ADD_NEW_FIELD
    payload:any
}