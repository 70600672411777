import { Table } from "antd";
import React from "react";
import { AttirbuteDefRulesType, ValueType } from "../../types/AttributeType";
const { Column, ColumnGroup } = Table;

interface AttributeValuesTableProps {
    values: ValueType | null;
    valuesShop: ValueType | null;
    attirbuteDefRules: AttirbuteDefRulesType[] | null;
    rangeToCompare: { min: number; max: number } | null;
    currentCountryName: string;
}

const AttributeValuesTable: React.FC<AttributeValuesTableProps> = ({ values, valuesShop, attirbuteDefRules, rangeToCompare, currentCountryName }) => {
    return values && valuesShop && attirbuteDefRules ? (
        <React.Fragment>
            <div className="att-value-box" style={{ height: "76vh" }}>
                <Table
                    dataSource={values.data}
                    pagination={false}
                    scroll={{ y: "66vh" }}
                    size="small"
                    className="attribute-mapping-table-scroll"
                    rowKey={(record) => (record.type === "range" ? (record[`value_${currentCountryName}`] as string) : record.value_id)}
                >
                    <ColumnGroup
                        title={
                            <span style={{ fontSize: "16px" }}>
                                <span style={{ position: "absolute", left: "10px", color: values.type === "Attributes" ? "#f78536" : "#3baea0" }}> {values.type}</span>
                                <br />
                                <span style={{ backgroundColor: "whitesmoke", width: "100%" }}>
                                    {values?.name}
                                    <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                        {` ${values.data.find((d) => d.type === "range") ? `(${values.data[0].range_min} - ${values.data[0].range_max})` : ""}`}
                                        {values.data.length === 0 && values.attribute_type === "range" && ` ${rangeToCompare?.min} - ${rangeToCompare?.max}`}
                                    </span>
                                </span>
                            </span>
                        }
                    >
                        <Column title="Value" dataIndex="value_rs" key="firstName" width="81%" />
                        <Column title="Count" dataIndex="products_assigned" key="lastName" />
                    </ColumnGroup>
                </Table>
            </div>
        </React.Fragment>
    ) : null;
};

export default AttributeValuesTable;
