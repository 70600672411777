
import { RESET_SHOP_SINGLE, SHOP_ALL, SHOP_CREATE, SHOP_DELETE, SHOP_EDIT, SHOP_RULES_ALL, SHOP_RULES_BY_ID, SHOP_RULES_CREATE, SHOP_RULES_EDIT, SHOP_RULES_EXIST, SHOP_SINGLE } from "../actions/shopAction/shopActionType";

export const shopReducer = ( state:any=[], action:any ) => {
    switch ( action.type ) {
        case SHOP_ALL:
            return action.payload
        case SHOP_CREATE:
            return state  //dont need to add new item, because page is redirected
        // case SHOP_EDIT:
        //     return state //state.data.map((shop:any)=> shop.id ===Number(action.payload.id) ? action.payload:shop) 
        case SHOP_DELETE:
            const newState = state.data.filter((shop:any)=>shop.id!==Number(action.payload))
            return {
                ...state,
                data:newState
            }
        default:
            break;
    }
    return state
}

export const shopById = ( state:any={},action:any ) => {
    switch ( action.type ) {
        case SHOP_SINGLE:
            return action.payload
        case RESET_SHOP_SINGLE:
            return action.payload
        case SHOP_EDIT:
            return state //state.data.map((shop:any)=> shop.id ===Number(action.payload.id) ? action.payload:shop) 
    }
    return state
}

export const shopRulesReducer = ( state:any=[], action:any ) => {
    switch ( action.type ) {
        case SHOP_RULES_ALL:
            return action.payload
        default:
            break;
    }
    return state
}

export const shopRuleByIdReducer = ( state:any=[], action:any ) => {
    switch ( action.type ) {
        case SHOP_RULES_BY_ID:
            return action.payload
        case SHOP_RULES_EDIT:
            return state.data.map((shop:any)=> shop.id ===Number(action.payload.id) ? action.payload:shop)
        case SHOP_RULES_CREATE:
            return [...state, action.payload]
        default:
            break;
    }
    return state
}

//shop rules exist

export const shopRulesExistReducer = ( state:any=[], action:any) => {
    switch (action.type) {
        case SHOP_RULES_EXIST:
            return action.payload
        default:
            break;
    }
    return state
}