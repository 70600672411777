import React, { useEffect } from "react";
import { ProductType } from "../types/types";
import { keyF } from "../utils/FkeyEvents";
import { correctName, parenthesesOnly } from "../utils/CtrlKeyEvents";
import { setModelFromPrices, setMpnFromPrices } from "../utils/AltKeyEvents";

type UseKeyEventsParam = React.Dispatch<React.SetStateAction<ProductType[]>>;

const alts = ["a", "w", "q", "r", "d", "m", "s", "p"];

const ctrls = ["1", "2", "3", "Shift"];

const useKeyEvents = (
    currentCountryName: string,
    currentCountryId: number,
    products: ProductType[],
    setProducts: UseKeyEventsParam,
    setSameParam: React.Dispatch<React.SetStateAction<number>>,
    setIsModalCorrectWord: React.Dispatch<React.SetStateAction<boolean>>,
    setIsModalSku: React.Dispatch<React.SetStateAction<boolean>>,
    setIsModalMatching: React.Dispatch<React.SetStateAction<boolean>>,
    selectedShops: string[]
) => {
    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });

    const handleKeyPress = (e: KeyboardEvent) => {
        if (Array.from({ length: 10 }, (_, i) => `F${i + 1}`).includes(e.key)) {
            e.preventDefault();
            if (
                e.key === "F1" ||
                e.key === "F2" ||
                e.key === "F3" ||
                e.key === "F4" ||
                e.key === "F8" ||
                e.key === "F9"
            )
                keyF(setProducts, e.key);

            if (e.key === "F5") keyF(setProducts, e.key, currentCountryName);

            if (e.key === "F6") keyF(setProducts, e.key, currentCountryName, "mpn");

            if (e.key === "F7") setProducts((curr) => curr.map((p) => ({ ...p, isChecked: false })));

            if (e.key === "F10") {
                if (window.confirm("Load data with the parameters. Are you sure?!")) setSameParam((prev) => prev + 1);
            }
        }

        if (e.altKey) {
            if (alts.includes(e.key)) {
                e.preventDefault();
                if (e.key === "a") {
                    setProducts((curr) =>
                        curr.map((p) => (p.isChecked ? { ...p, isChanged: true, isChecked: false } : p))
                    );
                }
                if (e.key === "w") {
                    setIsModalCorrectWord((curr) => !curr);
                }
                if (e.key === "q") {
                    setIsModalSku((curr) => !curr);
                }
                if (e.key === "r") {
                    setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: p.name + ` ${p.mpn}` } : p)));
                }
                if (e.key === "d") {
                    setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, mpn: "" } : p)));
                }
                if (e.key === "m") {
                    setMpnFromPrices(products, setProducts);
                }
                if (e.key === "p") {
                    setModelFromPrices(products, setProducts, selectedShops);
                }
                if (e.key === "s") {
                    setProducts((curr) =>
                        curr.map((p) => {
                            const newSku = p.skus && p.skus.length > 0 ? p.skus[0] : undefined;
                            return p.isChecked && newSku ? { ...p, name: `${p.name} ${newSku}` } : p;
                        })
                    );
                }
            }
        }
        if (e.ctrlKey) {
            if (ctrls.includes(e.key)) {
                e.preventDefault();
                if (e.key === "1") {
                    setProducts((curr) => curr.filter((p) => !p.isChecked));
                }
                if (e.key === "2") {
                    parenthesesOnly(setProducts);
                }
                if (e.key === "3") {
                    correctName(setProducts, currentCountryId);
                }
                if (e.key === "Shift") {
                    if (products.find((p) => p.isChecked)) {
                        setProducts((curr) => curr.map((s) => (s.isChecked ? { ...s, isMatching: true } : s)));
                        setIsModalMatching(true);
                    }
                }
            }
        }
    };
};

export default useKeyEvents;
