import { Col, Collapse, Divider, Layout, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { TreeCategoryType } from "../../prices/types/AllPricesTypes";
import axios from "axios";
import { getAllCategoryL3 } from "../../../actions/category/categoryActions";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import MappingTable from "../components/TopAttributeMapping/MappingTable";
import { PrimaryAttributesType } from "./TopProductAttributes";
const { Header, Content } = Layout;
const { Panel } = Collapse;

export type MapType = {
    attribute_allowed_value_id: number | null;
    id: number;
    attribute_id: number;
    category_l3_id: number;
    value: string;
    include: string;
    exclude: string;
    search: string;
    or: false;
    attribute_name: string;
    category_name: string;
    attribute_value: null;
    attribute_type: "range" | "select" | "multiselect" | "yesno";
};

export type GroupedItem = {
    attributeName: string;
    data: MapType[];
};

const TopAttributeMapping = () => {
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [primaryAttributes, setPrimaryAttributes] = useState<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>([]);
    const [mapList, setMapList] = useState<GroupedItem[] | undefined>();
    const [loading, setLoading] = useState(false);
    const state = useSelector((state: RootStore) => state);

    const currentCountryName = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";
    const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;
    const dispatch = useDispatch();
    const onChangeCategory = (e: number) => {
        setSelectedCategory(e);
    };

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);

    useEffect(() => {
        const getPrimaryAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-primary-attributes-by-l3?l3_id=${selectedCategory}&country_id=${currentCountryId}`);
                setPrimaryAttributes(data.data);
            } catch (err) {
                console.log(err);
            }
        };

        selectedCategory && getPrimaryAttributes();
    }, [selectedCategory, currentCountryId]);

    useEffect(() => {
        const getTopAttributes = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/list?category_l3_id=${selectedCategory}&page=1`);
                const datas = data.data as MapType[];

                const groupedItemsArray = datas.reduce<GroupedItem[]>((acc, item) => {
                    const existingCategory = acc.find((group) => group.attributeName === item.attribute_name);

                    if (existingCategory) {
                        existingCategory.data.push(item);
                    } else {
                        acc.push({
                            attributeName: item.attribute_name,
                            data: [item],
                        });
                    }

                    return acc;
                }, []);

                setMapList(groupedItemsArray.map((d, i) => ({ ...d, key: i.toString() })).sort((a: GroupedItem, b: GroupedItem) => a.attributeName.localeCompare(b.attributeName)));
                setLoading(false);
            } catch (err) {}
        };
        selectedCategory && getTopAttributes();
    }, [selectedCategory]);

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Top Attribute Mapping overview" />
            <Col span={23}>
                <div style={{ border: "1px solid lightgray", padding: "2rem", minHeight: "88vh" }} id="feed-files-box">
                    <Divider>Top Attribute Mapping</Divider>
                    <div style={{ padding: "1rem 2rem", backgroundColor: "#ebeced", border: "1px solid lightgray", borderRadius: "3px", marginBottom: "1.3rem" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <div style={{ width: "22px" }}>{loading && <Spin style={{ marginTop: "6px", marginRight: "6px" }} size="small" />}</div>
                            <Select
                                showSearch
                                placeholder="Select a category"
                                optionFilterProp="label"
                                options={state.categoriesL3.data?.map((l3: TreeCategoryType) => ({ label: `${l3.id}: ${l3[`name_${currentCountryName}`]}`, value: l3.id }))}
                                style={{ width: "400px" }}
                                showArrow={false}
                                onChange={onChangeCategory}
                                allowClear
                            />
                        </div>
                    </div>
                    <Layout style={{ margin: "0.6rem 1rem" }}>
                        <Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px", position: "relative" }}>
                            <span> Primary Attributes </span>{" "}
                        </Header>
                        <Content style={{ backgroundColor: "white" }}></Content>
                        {
                            /*selectedCategory && */ mapList && mapList?.length > 0 && (
                                <Collapse accordion bordered collapsible="icon">
                                    {mapList?.map((item) => (
                                        <Panel
                                            header={
                                                <div>
                                                    <span>{item.attributeName}</span>
                                                    <span style={{ marginLeft: "0.5rem", color: "darkred" }}>({item.data.length})</span>
                                                </div>
                                            }
                                            key={item.attributeName}
                                        >
                                            <MappingTable tableData={item.data} primaryAttributes={primaryAttributes} attr={item} setMapList={setMapList} currentCountryId={currentCountryId} />
                                        </Panel>
                                    ))}
                                </Collapse>
                            )
                        }
                    </Layout>
                </div>
            </Col>
        </Row>
    );
};

export default TopAttributeMapping;
