import { ALL_TRANSFORM, FEED_PARSER, TRANSFORM_GET, TRANSFORM_PRICE_TO_DB } from "../actions/transformAction/transformActionType";

export const transformReducer = ( state:any=[], aciton:any ) => {
    switch (aciton.type) {
        case TRANSFORM_GET:
            return aciton.payload
        case TRANSFORM_PRICE_TO_DB:
            return [...state, aciton.payload]
        case FEED_PARSER:
            return state
        default:
            break;
    }
    return state
}

//all transform logs



export const allTransformLogsReducer = ( state:any=[], action:any ) => {
    switch ( action.type ) {
        case ALL_TRANSFORM:
            return {...state, allTransformLogs: action.payload}
    }
    return state
}