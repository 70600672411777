import React, { useContext, useEffect, useRef, useState } from "react";
import type { TableProps } from "antd";
import { Button, Form, Input, Select, Space, Spin, Table, Tag, Tooltip } from "antd";
import { GroupedItem, MapType } from "../../pages/TopAttributeMapping";
import { PrimaryAttributesType, ProductType } from "../../pages/TopProductAttributes";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { DeleteOutlined, FieldNumberOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import ProductsInModal from "./ProductsInModal";

interface MappingTableProps {
    tableData: MapType[];
    primaryAttributes: PrimaryAttributesType<{ allowed_value_id: number; value: string }>[];
    attr: GroupedItem;
    setMapList: React.Dispatch<React.SetStateAction<GroupedItem[] | undefined>>;
    currentCountryId: number;
}

const EditableContext = React.createContext<any | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof MapType;
    record: MapType;
    primaryAttributes: PrimaryAttributesType<{ allowed_value_id: number; value: string }>[];
    loading: "value" | "include" | "exclude" | "search" | "or" | undefined;
    setLoading: React.Dispatch<React.SetStateAction<"value" | "include" | "exclude" | "search" | "or" | undefined>>;
    handleSave: (record: MapType) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ title, editable, children, dataIndex, record, handleSave, primaryAttributes, loading, setLoading, ...restProps }) => {
    const [editing, setEditing] = useState(false);

    const inputRef = useRef<any>(null);
    const primaryAttribute = primaryAttributes && (primaryAttributes.find((attr) => attr.id === record.attribute_id)?.attribute_values as any);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);

        if (dataIndex === "attribute_value" && record.attribute_type === "range") {
            form.setFieldsValue({ attribute_value: record.value });
        }

        if (dataIndex === "attribute_value" && (record.attribute_type === "select" || record.attribute_type === "yesno")) {
            form.setFieldsValue({ attribute_value: record.attribute_allowed_value_id });
        }
        if (dataIndex === "include" || dataIndex === "exclude") {
            form.setFieldsValue({ [dataIndex]: record[dataIndex] ? JSON.parse(record[dataIndex]) : [] });
        }

        if (dataIndex === "search") {
            form.setFieldsValue({ [dataIndex]: record.search });
        }
        if (dataIndex === "or") {
            form.setFieldsValue({ [dataIndex]: record.or });
        }
    };

    const save = async () => {
        if (record.attribute_type === "range" && dataIndex === "attribute_value") {
            const values = await form.validateFields();
            const newValue = values.attribute_value;
            if (isNaN(Number(values.attribute_value))) {
                openNotification("Entered input is not number!");
                toggleEdit();
                return;
            }

            if (Number(values.attribute_value.trim()) === Number(record.value)) {
                toggleEdit();
                return;
            }
            setLoading("value");
            try {
                const obj = {
                    id: record.id,
                    attribute_id: record.attribute_id,
                    value: newValue,
                };
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/edit`, obj);
                showMessage(data.message);
                setLoading(undefined);
                toggleEdit();
                handleSave({ ...record, value: newValue });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        } else if ((record.attribute_type === "select" || record.attribute_type === "yesno") && dataIndex === "attribute_value") {
            setLoading("value");
            try {
                const values = await form.validateFields();
                const selectedId = values.attribute_value;

                const obj = {
                    id: record.id,
                    attribute_id: record.attribute_id,
                    attribute_allowed_value_id: selectedId,
                };
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/edit`, obj);
                showMessage(data.message);

                const attribute = primaryAttribute.find((data: { allowed_value_id: number; value: string }) => data.allowed_value_id === selectedId);
                setLoading(undefined);
                toggleEdit();
                handleSave({ ...record, attribute_value: attribute.value, attribute_allowed_value_id: attribute.allowed_value_id });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        } else if (dataIndex === "or") {
            setLoading("or");
            try {
                const values = await form.validateFields();
                const obj = {
                    id: record.id,
                    attribute_id: record.attribute_id,
                    or: values.or,
                };
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/edit`, obj);
                showMessage(data.message);
                setLoading(undefined);
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        } else if (dataIndex === "include" || dataIndex === "exclude") {
            setLoading(dataIndex);
            try {
                const values = await form.validateFields();
                const obj = {
                    id: record.id,
                    attribute_id: record.attribute_id,
                    ...values,
                };

                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/edit`, obj);
                showMessage(data.message);
                setLoading(undefined);
                toggleEdit();
                handleSave({ ...record, [dataIndex]: JSON.stringify(values[dataIndex]) });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        } else if (dataIndex === "search") {
            const values = await form.validateFields();

            if (values.search?.trim() === record.search?.trim()) {
                toggleEdit();
                return;
            }
            setLoading("search");
            try {
                const obj = {
                    id: record.id,
                    attribute_id: record.attribute_id,
                    search: values.search,
                };
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/edit`, obj);
                showMessage(data.message);
                setLoading(undefined);
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            (record.attribute_type === "select" || record.attribute_type === "yesno") && dataIndex === "attribute_value" ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex} rules={[{ required: true, message: `${title} is required.` }]}>
                    {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
                    <Select ref={inputRef} onChange={save} autoFocus defaultOpen onBlur={() => toggleEdit()} loading={loading === "value"}>
                        {primaryAttribute.map((data: { allowed_value_id: number; value: string }) => (
                            <Select.Option key={data.allowed_value_id} value={data.allowed_value_id}>
                                {data.value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : dataIndex === "or" ? (
                <Form.Item name="or" style={{ margin: 0 }}>
                    <Select style={{ width: "40px" }} ref={inputRef} onChange={save} autoFocus defaultOpen onBlur={() => toggleEdit()} loading={loading === "or"}>
                        <Select.Option key={"true"} value={true}>
                            Yes
                        </Select.Option>
                        <Select.Option key={"false"} value={false}>
                            No
                        </Select.Option>
                    </Select>
                </Form.Item>
            ) : dataIndex === "include" || dataIndex === "exclude" ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <Select
                        ref={inputRef}
                        allowClear
                        mode="tags"
                        tagRender={(props) => {
                            const { label, closable, onClose } = props;
                            const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                                event.preventDefault();
                                event.stopPropagation();
                            };
                            return (
                                <Tag color={dataIndex === "include" ? "green" : "red"} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginInlineEnd: 4 }}>
                                    {label}
                                </Tag>
                            );
                        }}
                        onBlur={save}
                        placeholder="Exclude words"
                        options={[]}
                        dropdownStyle={{ display: "none" }}
                        loading={dataIndex === loading}
                    />
                </Form.Item>
            ) : record.attribute_type === "range" && dataIndex === "attribute_value" ? (
                <Form.Item style={{ margin: 0 }} name="attribute_value" rules={[{ required: true, message: `${title} is required.` }]}>
                    <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        addonAfter={loading === "value" ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <FieldNumberOutlined />}
                    />
                </Form.Item>
            ) : (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} suffix={loading === "search" ? <Loading3QuartersOutlined style={{ fontSize: 14 }} spin /> : <div></div>} />
                </Form.Item>
            )
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingInlineEnd: 24, minHeight: "32px", borderRadius: "8px" }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type ColumnTypes = Exclude<TableProps<MapType>["columns"], undefined>;

const MappingTable = ({ tableData, primaryAttributes, attr, setMapList, currentCountryId }: MappingTableProps) => {
    const [dataSource, setDataSource] = useState<MapType[]>(tableData);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [loadingDelete, setLoadingDelete] = useState<number | undefined>();
    const [loadingProducts, setLoadingProducts] = useState<number | undefined>();
    const [isShowProducts, setIsShowProducts] = useState(false);
    const [loading, setLoading] = useState<"value" | "search" | "include" | "exclude" | "or" | undefined>();
    const [searchParam, setSearchParam] = useState<
        { category_name: string; attribute_name: string; attribute_type: string; search: string; include: string[]; exclude: string[]; or: Boolean } | undefined
    >();

    const deleteMap = async (id: number) => {
        setLoadingDelete(id);
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attributes/primary-mapping/delete?id=${id}`);
            showMessage(data.message);
            setMapList((curr) => curr?.map((mapItem) => (mapItem.attributeName === attr.attributeName ? { ...mapItem, data: mapItem.data.filter((d) => d.id !== id) } : mapItem)));
            setDataSource((curr) => curr.filter((d) => d.id !== id));
            setLoadingDelete(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    const showProducts = async (record: MapType) => {
        setLoadingProducts(record.id);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attributes/get-l3-products-primary-attributes?l3_id=${record.category_l3_id}&country_id=${currentCountryId}&page=${1}${
                    record?.search && record.search.length > 0 ? `&search=${record.search}` : ""
                }${
                    record.include
                        ? JSON.parse(record.include)
                              ?.map((text: string) => `&search_include[]=${text}`)
                              ?.join("")
                        : ""
                }${
                    record.exclude
                        ? JSON.parse(record.exclude)
                              ?.map((text: string) => `&search_exclude[]=${text}`)
                              ?.join("")
                        : ""
                }${record?.or ? `&or=${1}` : ""}`
            );

            setSearchParam({
                category_name: record.category_name,
                attribute_name: record.attribute_name,
                attribute_type: record.attribute_type,
                search: record.search,
                include: JSON.parse(record.include),
                exclude: JSON.parse(record.exclude),
                or: record.or,
            });

            setProducts(data.data);
            setIsShowProducts(true);

            setLoadingProducts(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        { title: "Value", dataIndex: "attribute_value", render: (text, record) => (record.attribute_type === "range" ? record.value : record.attribute_value), width: "20%", editable: true },
        { title: "Search query", dataIndex: "search", width: "20%", editable: true },
        {
            title: "Include keywords",
            dataIndex: "include",
            width: "20%",
            editable: true,
            render: (_, record) =>
                JSON.parse(record.include)?.map((item: string) => (
                    <Tag color="green" key={item}>
                        {item}
                    </Tag>
                )),
        },
        {
            title: "Exclude keywords",
            dataIndex: "exclude",
            width: "20%",
            editable: true,
            render: (_, record) =>
                JSON.parse(record.exclude)?.map((item: string) => (
                    <Tag color="red" key={item}>
                        {item}
                    </Tag>
                )),
        },
        { title: "Or", dataIndex: "or", width: "150px", editable: true, render: (text) => (text ? "Yes" : "No") },
        {
            dataIndex: "id",
            width: "20px",
            align: "center" as "center",
            render: (id, record) => (
                <Space>
                    {/* <Button size="small">Delete</Button> */}
                    <Tooltip title={`Show product list - '${record.attribute_value ? record.attribute_value : record.value}'`}>
                        <Button onClick={() => loading === undefined && showProducts(record)} size="small" loading={loadingProducts === record.id}>
                            Show Products
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
        {
            dataIndex: "id",
            width: "20px",
            align: "center" as "center",
            render: (id, record) => (
                <Space>
                    <Tooltip title={`Delete attribute value - '${record.attribute_value ? record.attribute_value : record.value}'`}>
                        <Button onClick={() => deleteMap(id)} danger shape="circle" size="small" icon={<DeleteOutlined />} loading={loadingDelete === id} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleSave = (row: MapType) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        setDataSource(newData);
        setMapList((curr) => curr?.map((map) => (map.attributeName === attr.attributeName ? { ...map, data: newData } : map)));
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: MappingTableProps) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                primaryAttributes: primaryAttributes,
                loading: loading,
                setLoading: setLoading,
                handleSave,
            }),
        };
    });

    const handleCancel = () => {
        setIsShowProducts(false);
    };

    // console.log(searchParam);

    return (
        <>
            <ProductsInModal searchParam={searchParam} products={products} isShowProducts={isShowProducts} handleCancel={handleCancel} />
            <Table<MapType>
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
                rowKey={(record) => record.id}
                pagination={false}
            />
        </>
    );
};

export default MappingTable;
