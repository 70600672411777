import axios from "axios";
import { ProductType } from "../types/types";
import showMessage from "../../../shared/MessagesInfo/message";
const baseUrl = process.env.REACT_APP_URL_API;

export const setMpnFromPrices = async (
    products: ProductType[],
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>
) => {
    const ids = products.filter((p) => p.isChecked).map((p) => p.product_id);

    if (ids.length > 0) {
        try {
            const { data } = await axios.get(`${baseUrl}/prices/list-prices-mpns?product_ids=${JSON.stringify(ids)}`);
            const pricesMpns: { product_id: number; mpn: string }[] = data.data;

            setProducts((curr) =>
                curr.map((p) => {
                    const findMPn = pricesMpns.find((pm) => pm.product_id === p.product_id);
                    return findMPn ? { ...p, mpn: findMPn.mpn } : p;
                })
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    }
};

export const setModelFromPrices = async (
    products: ProductType[],
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>,
    selectedShops: string[]
) => {
    const ids = products.filter((p) => p.isChecked).map((p) => p.product_id);

    const shop_id = selectedShops.length === 1 ? selectedShops[0].split("::")[0] : undefined;

    console.log(shop_id);

    if (ids.length > 0) {
        try {
            const { data } = await axios.get(
                `${baseUrl}/prices/list-prices-fields?product_ids=${JSON.stringify(ids)}&field=product_model${
                    shop_id ? `&shop_id=${Number(shop_id)}` : ""
                }`
            );
            console.log(data);
            const models: { product_id: number; product_model: string }[] = data.data;

            setProducts((curr) =>
                curr.map((p) => {
                    const findModel = models.find((m) => m.product_id === p.product_id);
                    return findModel ? { ...p, name: `${p.name} ${findModel.product_model ?? ""}` } : p;
                })
            );
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    }
};
