
import { DatePickerNewProducts } from "../pages/AutoMatchedPP"

const setParams = ( 
    selectedCategory: number | null, 
    filter: string | null, 
    date: DatePickerNewProducts,
    activeProducts: boolean | null, 
    page?:number, 
    
    ) => {
        //console.log(selectedCategory)
    const paramArr = [
        selectedCategory && `category_l3_id=${ selectedCategory }`, 
        filter && `type=${ filter }`,
        date && `days_back=${ date }`,
        activeProducts && `active_products=${ activeProducts }`,
        page && `page=${ page }` 
    ]
    return  paramArr.filter(p=> p).map(( p, i ) => p  ? `${ i === 0 ? '?' :'&' }${ p }`: ''  ).join("")
}

export default setParams