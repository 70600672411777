import { Badge, Button, Card, Checkbox, Col, Divider, Image, Modal, Popconfirm, Popover, Row, Table } from "antd";
import React, { useState } from "react";
import numberWithCommas from "../../../../shared/utils/numberUtils";
import showMessage from "../../../../shared/MessagesInfo/message";
import { ColumnType } from "antd/es/table";
import axios from "axios";
import { AllPricesListType, PricesDifferenceType, ShopType } from "../../pages/PricesDifference";
import { CheckOutlined, ZoomInOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type PricesDifferenceModalType = {
    isModalVisible: boolean;
    allPircesList: AllPricesListType[];
    setAllPricesList: React.Dispatch<React.SetStateAction<AllPricesListType[]>>;
    product: { id: number | null; urlProduct: string };
    shop: ShopType | null;
    currentCountryId: number;
    setPricesDifference: React.Dispatch<React.SetStateAction<PricesDifferenceType[]>>;
    setProduct: React.Dispatch<React.SetStateAction<{ id: number | null; urlProduct: string }>>;
    loadingGetPrices: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const procentualnoOdstupanje = (price: number, objArr: AllPricesListType[]) => {
    const mediana = Math.ceil(objArr.length / 2) - 1;
    const odstupanje = (Math.abs(price - objArr[mediana].price) / objArr[mediana].price) * 100;
    return odstupanje;
};

const PricesDifferenceModal: React.FC<PricesDifferenceModalType> = ({
    isModalVisible,
    allPircesList,
    product,
    shop,
    currentCountryId,
    setAllPricesList,
    setPricesDifference,
    setProduct,
    loadingGetPrices,
    setIsModalVisible,
}) => {
    const [checkedLoading, setCheckedLoading] = useState(false);
    const [detachAllLoading, setDetachAllLoading] = useState(false);

    const checkItem = (id: number) => {
        setAllPricesList((curr) => curr.map((p) => (p.id === id ? { ...p, isChecked: !p.isChecked } : p)));
    };

    const handleCheckedPrices = (e: CheckboxChangeEvent) => {
        setAllPricesList((curr) => curr.map((d) => ({ ...d, isChecked: e.target.checked })));
    };

    const fixPrices = async () => {
        const ids = allPircesList.filter((product) => product.isChecked).map((p) => p.id);
        const obj = {
            price_ids: ids,
        };
        setDetachAllLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/detach-prices`, obj);
            showMessage(data.message);
            setDetachAllLoading(false);
            setAllPricesList((curr) => curr.filter((p) => !ids.includes(p.id)));
        } catch (err) {
            console.log(err);
        }
    };

    const columnsInfo: (ColumnType<AllPricesListType> & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Shop",
            dataIndex: "shop_name",
            key: "shop_name",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            align: "center" as "center",
            render: (_, record) => (
                <span
                    style={{
                        fontSize: "20px",
                        color:
                            record.percentage >= 30 && record.percentage <= 50
                                ? "orange"
                                : record.percentage > 50
                                ? "red"
                                : "",
                    }}
                >
                    {numberWithCommas(String(Math.trunc(record.price)).replace(".", ","))}
                </span>
            ),
        },
        {
            title: "Image",
            dataIndex: "",
            key: "seller_image",
            className: "pd-price-center",
            align: "center" as "center",
            render: (_, record) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px" }}>
                    {record.seller_image ? (
                        <Image src={record.seller_image} alt="seller-img" style={{ height: "60px", width: "60px" }} />
                    ) : (
                        "-"
                    )}
                    <Popover
                        className="popover-images"
                        placement="topRight"
                        title="Compare images"
                        content={contentImages}
                        trigger="click"
                    >
                        <ZoomInOutlined
                            style={{
                                fontSize: "20px",
                                marginLeft: "2rem",
                                marginTop: "1rem",
                            }}
                            onClick={() => {
                                setImageID(record.id);
                            }}
                        />
                    </Popover>
                </div>
            ),
        },
        {
            title: "Model",
            dataIndex: "product_model",
            key: "product_model",
            render: (_, record) => (
                <span
                    onClick={() => window.open(record.product_url, "_blank")}
                    style={{ textDecoration: "underline", cursor: "pointer", color: "darkblue" }}
                >
                    {record.product_name ? record.product_name : record.product_model}
                </span>
            ),
        },
        {
            dataIndex: "id",
            key: "id",
            className: "pd-cell-center",
            render: (_, record) => (
                <Popconfirm
                    placement="top"
                    title="Are you sure?"
                    onConfirm={() => detachPrice(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button value="default" style={{ marginLeft: "3px" }} size="small" type="primary" danger>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
        {
            dataIndex: "id",
            align: "center" as "center",
            title: (
                <Row justify="space-between">
                    <Checkbox
                        onChange={handleCheckedPrices}
                        checked={
                            allPircesList.find((product) => !product.isChecked) || allPircesList.length === 0
                                ? false
                                : true
                        }
                    />
                    <Button
                        size="small"
                        disabled={allPircesList.find((product) => product.isChecked) ? false : true}
                        onClick={fixPrices}
                        loading={detachAllLoading}
                    >
                        Detach All
                    </Button>
                </Row>
            ),
            width: "140px",
            render: (text, record, index) => (
                <div
                    onClick={() => checkItem(text)}
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    {
                        <CheckOutlined
                            style={{
                                opacity: record.isChecked ? "1" : "0",
                            }}
                        />
                    }
                </div>
            ),
        },
    ];

    const handleOk = () => {
        setProduct({ id: null, urlProduct: "" });
    };

    const handleCancel = () => {
        setProduct({ id: null, urlProduct: "" });
        setIsModalVisible(false);
    };

    const detachPrice = async (id: number) => {
        setAllPricesList((curr) => curr.map((p) => ({ ...p, detachLoading: p.id === id ? true : false })));
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/detach-price?price_id=${id}`);
            showMessage(data.message);
            setAllPricesList((curr) => curr.filter((price) => price.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const markForChecked = async (id: number | null) => {
        setCheckedLoading(true);
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_URL_API}/prices/check-prices-difference?product_id=${id}`
            );
            showMessage(data.message);
            setIsModalVisible(false);
            setPricesDifference((curr) => curr.filter((data) => data.product_id !== id));
            setAllPricesList([]);
            setProduct({ id: null, urlProduct: "" });
            setCheckedLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const sourceData =
        allPircesList.length > 2
            ? allPircesList.map((element) => {
                  const odstupanje = procentualnoOdstupanje(element.price, allPircesList);
                  return {
                      ...element,
                      percentage: Number(odstupanje.toFixed(2)),
                  };
              })
            : allPircesList;

    const [imageID, setImageID] = useState<number | null>(null);
    const findPrice = sourceData.find((i: { id: number }) => i.id === imageID);

    const contentImages = (
        <div>
            {findPrice && (
                <>
                    {" "}
                    <img
                        alt="firstImage"
                        src={`https://img.ep-cdn.com/images/500/500/${findPrice.product_image}.webp`}
                        width="400"
                    />
                    <img alt="secondImage" src={findPrice?.seller_image} width="400" />
                </>
            )}
        </div>
    );

    return (
        <Modal open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} width={1200}>
            <Divider>{`Prices Difference ${shop?.name ? `for ${shop?.name}` : ""}`}</Divider>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {allPircesList?.length > 0 && (
                    <Card
                        size="small"
                        style={{ marginBottom: "1rem", backgroundColor: "whitesmoke", border: "1px solid lightgray" }}
                    >
                        <Image
                            width={60}
                            src={`https://img.ep-cdn.com/images/160/160/${allPircesList[0].product_image}.webp`}
                            alt="product"
                        />
                        <span style={{ marginLeft: "1rem" }}>
                            Id:{" "}
                            <a
                                target="_blank"
                                className="front-link"
                                style={{ color: "#01b0f5" }}
                                onClick={() =>
                                    window.open(
                                        Number(currentCountryId) === 6
                                            ? `${`https://shoptok.si${product.urlProduct}`}`
                                            : product.urlProduct,
                                        "_blank"
                                    )
                                }
                                rel="noreferrer"
                                href={
                                    Number(currentCountryId) === 6
                                        ? `${`https://shoptok.si${product.urlProduct}`}`
                                        : product.urlProduct
                                }
                            >
                                {product.id}
                            </a>
                        </span>
                    </Card>
                )}
                {allPircesList?.length > 2 && (
                    <Card
                        size="small"
                        style={{
                            marginBottom: "1rem",
                            backgroundColor: "whitesmoke",
                            border: "1px solid lightgray",
                            textAlign: "center",
                        }}
                    >
                        <Row justify="center" align="middle">
                            <Col span={24}>
                                <Badge status="warning" text="+ 50%" style={{ marginTop: "0.2rem" }} />
                            </Col>
                            <Col span={24}>
                                <Badge status="error" text="+ 70%" style={{ marginTop: "0.6rem" }} />
                            </Col>
                        </Row>
                    </Card>
                )}
            </div>
            <Table
                bordered
                columns={columnsInfo}
                dataSource={sourceData}
                rowKey={(record) => record.id}
                size="small"
                //showHeader={false}
                className="ant-table prices-difference-modal-table"
                pagination={{ pageSize: 100, showSizeChanger: false, hideOnSinglePage: true }}
                rowClassName={(record) => `${record.shop_name === shop?.name ? "prices-difference-marked-shop" : ""}`}
                loading={loadingGetPrices}
            />
            <Row justify="center">
                <Button
                    type="primary"
                    shape="round"
                    onClick={() => markForChecked(product.id)}
                    style={{ marginTop: "1rem" }}
                    loading={checkedLoading}
                >
                    Checked
                </Button>
            </Row>
        </Modal>
    );
};

export default PricesDifferenceModal;
