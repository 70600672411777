import { Badge, Segmented } from "antd";
import { RootStore } from "../../../../../../Store";
import { SegmentedValue } from "antd/es/segmented";

const showStatus = (flag: number | undefined | string | null) => {
    if (flag === 2) {
        return "warning";
    } else if (flag === 1) {
        return "success";
    } else {
        return "error";
    }
};

interface CountriesTabsProps {
    stateProduct: RootStore;
    description: Record<string, string | number | null> | undefined;
    changeCountryValue: (e: SegmentedValue) => void;
}

const CountriesTabs = (props: CountriesTabsProps) => {
    const { stateProduct, description, changeCountryValue } = props;
    return (
        <Segmented
            options={stateProduct.countries.map((country) => ({
                label: (
                    <span>
                        {country.locale_code.toUpperCase()} {<Badge status={showStatus(description && description[`flag_${country.locale_code}` as keyof typeof description])} />}
                    </span>
                ), //
                value: country.locale_code,
            }))}
            // value={descValue}
            onChange={(e) => changeCountryValue(e)}
            size="large"
            style={{ marginRight: "1rem" }}
        />
    );
};

export default CountriesTabs;
