import { Form, Select, TreeSelect } from 'antd'
import React from 'react'
import { capitalize } from '../../helper/firstLetter'

type SelectType= {
    name:string
    showSearch?:boolean
    allowClear?:boolean
    optionFilterProp?:string
    //filterOption:(input:string, option:{label:string }) => boolean
    sourcedata: {id:number, name:string}[] 
    select?:boolean
    //for TreeSelect
    multiple?:boolean
    onchange?:(e:number| undefined) => void
    form?:any 
    checkLinkLoading?:boolean
}

const FormSelect = ( props:SelectType ) => 
    { const checkTitle = Form.useWatch( 'title', props.form )
    return <Form.Item 
        name={props.name}
        label={capitalize(props.name.replace("_id",""))}
        rules={[{ required: props.name === "url_id" ? false: true, message: `Please input ${ capitalize(props.name.replace("_id","")) }` }]}
    >
        {props.select ? 
            <Select
                showSearch={ props.showSearch}
                allowClear={ props.allowClear }
                placeholder={`Select ${ props.name.replace("_id","") }`}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => props.onchange ? props.onchange(e) :{}}
                options={ props.sourcedata.map(shop => ({
                    value: shop.id,
                    label: shop.name
                }))}
                disabled={ props.name === 'url_id' ? checkTitle ? false:true :false }
                //status={ props.checkLinkLoading ? "warning":undefined  }    
                //loading={ props.checkLinkLoading }
                
                //loading

                style={ props.name === 'url_id' && props.checkLinkLoading ?  { border: '1px solid rgb(64, 150, 255)', borderRadius:'6px' }:{}}
            /> : 
            <TreeSelect
                multiple
                showSearch
                treeNodeFilterProp="title"
                showCheckedStrategy='SHOW_ALL'
                treeData={props.sourcedata}
                placeholder={`Select ${ capitalize(props.name.replace("_id","")) }`}
            />}  
        
    </Form.Item>}

export default FormSelect