import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";

const formatFilters = (filters: string[]) => {
    return filters
        .filter((d) => d.length > 0)
        .map((d) => `&&${d}`)
        .join("");
};

export const applyToAll = async (
    selectedShops: string[],
    selectedCategories:
        | {
              id: string;
              level: string;
              fullCategory: string;
          }
        | undefined,
    selectedBrands: string[],
    user: boolean,
    selectedNew: string | undefined,
    xml: boolean,
    brand: string | undefined,
    category: string | undefined,
    search: string,
    currentCountryName: string,
    currentCountryId: number
) => {
    const seller_filter = selectedShops.length > 0 ? `sellers:=[${selectedShops.map((b) => `'${b}'`).join(",")}]` : "";
    const categories_filter = selectedCategories
        ? ` ${`category${
              selectedCategories.level !== "3" ? `_${selectedCategories.level}` : ""
          }_${currentCountryName}:=${selectedCategories.id}`} `
        : "";

    const brand_filter = selectedBrands.length > 0 ? `brand:=[${selectedBrands.map((b) => `'${b}'`).join(",")}]` : "";

    const user_filter = user ? `user_active:=1` : "";
    const newProducts = selectedNew ? `condition:=${selectedNew}` : "";

    const xml_active = xml ? `active_prices_${currentCountryName}:=true` : "";

    const filters = formatFilters([
        seller_filter,
        categories_filter,
        brand_filter,
        user_filter,
        newProducts,
        xml_active,
    ]);

    const objToSend = {
        ...(brand && { brand_id: Number(brand.split("::")[0]) }),
        ...(category && { category_l3_id: category }),
        filter_by: `countries:=[${currentCountryId}]${filters}`,
        ...(search && search.trim().length > 0 && { q: search }),
    };
    const baseUrl = process.env.REACT_APP_URL_API;
    try {
        const { data } = await axios.put(`${baseUrl}/product/item/check-all-products`, objToSend);
        showMessage(data.message);
    } catch (err) {
        console.log(err);
    }
};
