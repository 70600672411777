import { CheckCircleOutlined, CheckSquareOutlined, CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Affix, Button, Col, Divider, Empty, Input, Layout, Popconfirm, Popover, Row, Select, Skeleton, Spin, Table, Tooltip, Typography } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'
import React,{ useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../../Store'
import DatePickerFromToReports from '../../reports/components/DatePickerFromToReports'
import FadeIn from 'react-fade-in';
import '../style/ShopBrand.css'
import showMessage from '../../../shared/MessagesInfo/message'
import { getAllBrands } from '../../../actions/brand/BrandAction'
const { Search } = Input
const { Header } = Layout
const { Paragraph } = Typography
type DatePickerShopBrands = {
    startDate:Date,
    endDate:Date|null
}

type ShopBrand = {
    allow_brands: boolean
    brands_count: number
    id: number
    name: string
    isShowSelect:boolean
}

type BrandListData = {
  id: number 
  name: string
  isShowSelect:boolean
  link:string
  isChecked:boolean
  isChangeName:boolean
}

type BrandList = {
  shopName: string 
  shop_id:number
  data: BrandListData[]
}

const ShopBrends = () => {
  const [ shopBrands, setShopBrands ] = useState<ShopBrand[]>([])
  const [ showBrandsList, setShowBrandsList ] = useState<BrandList | null >(null)
  const [ loadingBrandList, setLoadingBrandList ] = useState(false)
  const [ date, setDate ] = useState<DatePickerShopBrands>({ startDate:new Date(), endDate:new Date()})
  const dispatch = useDispatch()
  const state = useSelector(( store:RootStore) => store)
  const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1

  useEffect(() => {
    dispatch(getAllBrands())
  }, [ dispatch ])

  const getShopBrands = useCallback( async () => {
    try {
      const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/shop/get-shop-brands-count?country_id=${ currentCountryId }${ `&from=${moment( date.startDate ).format("YYYY-MM-DD")}&to=${moment( date.endDate ).format("YYYY-MM-DD")}`}`)
      setShopBrands(data.data)
    } catch ( err ) {
      console.log(err)
    }
  }, [ currentCountryId, date])



  useEffect(() => {
    if (date.startDate && date.endDate) {
      getShopBrands()
    }
  }, [ getShopBrands, date ])

  useEffect(() => {
    setShowBrandsList(null)
  }, [ date ])

  const showBrandByShop = async (id:number, name:string) => {
    setShowBrandsList(null)
    setLoadingBrandList(true)
    try {
      const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/shop/get-shop-brands?shop_id=${ id }${ date.endDate ? `&from=${moment( date.startDate ).format("YYYY-MM-DD")}&to=${moment( date.endDate ).format("YYYY-MM-DD")}`:'' }`)
      setShowBrandsList({ shopName:name,shop_id:id, data: data.data.map( (item:BrandList)=>({...item, isChecked:false, isChangeName:false}))})
      setLoadingBrandList(false)
    } catch ( err ) {
      console.log(err)
      setLoadingBrandList(false)
    }
  }

  const allowBrands = async (id:number, allow_brands:boolean) => {
    const objToSend = {id:id, allow_brands:!allow_brands}
    try {
      const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/shop/edit`, objToSend)
      showMessage( data.message )
      setShopBrands( curr => curr.map( shop => shop.id === id ? ({...shop, allow_brands:!shop.allow_brands}):shop ) )
      
    } catch ( err ) {
      console.log(err)
    }
  }

  const columns: ColumnsType<ShopBrand> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width:'40%',
      render:(text, record ) => 
        <div style={{ display:'flex', justifyContent:"space-between", color: !record.allow_brands ? "#f83e4b":"", marginLeft:"10px" }}>
          <span>{ text }</span>
          { record.allow_brands ? 
          <CheckCircleOutlined style={{ color:'green',position:'relative', bottom:'0.2rem', marginTop:"8px" }} /> : 
          <ExclamationCircleOutlined style={{ color:'darkred',position:'relative', bottom:'0.2rem', marginTop:"8px" }}/>}
        </div>
    },
    {
      key: 'id',
      title: 'Allow/Forbid',
      dataIndex: 'id',
      render: (_,  record ) => 
        <Button size='small' danger={record.allow_brands ? true:false } style={{ width:'100px' }} onClick={() => allowBrands(record.id, record.allow_brands)}>{`${ record.allow_brands ? 'Forbid':'Allow'} Brands`}</Button>,
      align:'center'
    },
    {
      key: 'brands_count',
      title: 'Count',
      dataIndex: 'brands_count',
      //className:'attribute-mapping-shop-att-td',
      align:'center',
      sorter: (a, b) => b.brands_count - a.brands_count
    },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      align:'center',
      render:(text,record) => <Button size='small' onClick={() => showBrandByShop(record.id, record.name)}>Show Brands</Button>
    },
  ]

  const setToNN = async (id:number, name:string) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/product/brand/map-brand`,{ redirect_brand_id:3621 , brands:[{id:id, name:name, map: true}]})
      setShowBrandsList( curr => curr ? ({...curr, data: curr.data.filter( data => data.id !== id )})  :null )

      getShopBrands()
      showMessage(data.message)
    } catch ( err ) {
      console.log(err)
    }
  }
 
  const [ selectedBrand, setSelectedBrand ] = useState<{id:number,name:string}|null>(null)

  const  openPopover = (id:number) => {
    setShowBrandsList( curr => curr ? ({...curr, data: curr.data.map( data => ({ ...data, isShowSelect: data.id === id ? true : false })) }) :null )
    setSelectedBrand(null)
  }

  const handleBrandChange = async ( id:number ) => {
    const findBrand = state.brands.data.find( (brand:any) =>  brand.id === id)

    setSelectedBrand({id: findBrand.id, name: findBrand.name})
    
  
  }
  const resetMapBrand = () => {

    setShowBrandsList( curr => curr ? ({...curr, data: curr.data.map( data =>{ return  { ...data, isShowSelect: false }}) }) :null )
    setSelectedBrand(null)
  }

  const saveBrandMap = async (record:{id:number, name:string}) => {  //      map brand      
    if (selectedBrand) {
      try {
        const { data } = await axios.put(`${process.env.REACT_APP_URL_API }/product/brand/map-brand`,{ redirect_brand_id:selectedBrand.id , brands:[{ id:record.id, name:record.name, map: true}]})
        //setBrands( curr => curr.map( data =>{ return  { ...data, isShowSelect: false }}))
        setShowBrandsList( curr => curr ? ({...curr, data: curr.data.filter( data => data.id !== record.id )})  :null )
        setSelectedBrand(null)
        showMessage(data.message)
        getShopBrands()
      } catch ( err ) {
        console.log(err)
      }
    }
  }

  const delayCheckBrand = async (id:number, shop_id:number) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL_API }/product/brand/update`,{id:id, checked:true})
      setShowBrandsList( curr => curr ? ({...curr, data:curr?.data.filter(item => item.id !== id) }):null)
      //shopBrands(  )
      setShopBrands( curr => curr.map( shop => shop.id === shop_id ? ({...shop, brands_count: shop.brands_count - 1}):shop ) ) //??
      showMessage(data.message)
    } catch ( err ) {
      console.log(err)
    }
  }

  const markBrand = async (item:BrandListData, shop_id:number) => {
    setShowBrandsList( curr => curr ? ({...curr, data:curr?.data.map(data => data.id === item.id ? ({...data, isChecked:true }):data )}):null)
    setTimeout(() => {
      delayCheckBrand(item.id, shop_id)
    }, 300)

    //console.log(showBrandsList, shop_id)
   
  }

  const [ brandName, setBrandName ] = useState('')

  const changeBrandName = async ( id:number, e:string ) => {
    //
    // if(e?.relatedTarget?.id === "reset-name-btn"){
    //   alert('worng')
    // }
    console.log(id)
    console.log(e)

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, { id:id , name: e })
      console.log(data)
      showMessage(data.message)
      setShowBrandsList(curr => curr ?  
        ({ 
          ...curr, 
          data: curr.data.map(s => s.id === id ? ({
            ...s,  
            name:brandName,
            isChangeName:false
          }):
          s)
        }): 
        null  
      )
      setBrandName('')
    } catch ( err ) {
      console.log(err)
    }
  }

  const resetChangeName = (item:BrandListData) => {
    setShowBrandsList(curr => curr ?  
      ({ 
        ...curr, 
        data: curr.data.map(s => s.id === item.id ? ({
          ...s,  
          isChangeName: false,
          name:s.name 
        }):s)
      }): 
      null  
    )
    setBrandName('') 
  }

  return (
    <Row justify='center' style={{ backgroundColor:'white',  margin:"1.5rem" }}>
      <Divider>Shop Brands</Divider>
      <Col span={20} style={{ backgroundColor:'white', paddingBottom:"1.2rem" }} >
        <div className='product-img-info-bar' style={{ textAlign:'center', zIndex:200 }}>
          <DatePickerFromToReports date={date} setDate={setDate} position="right-start" isDate={true} />
        </div>
        {shopBrands.length > 0 ? 
        <Row justify='center'>
          <Col span={/*windowSize.width <2000 ? 16: 12*/12} >
            <Layout style={{ marginTop:'6px', marginRight:"5px" }}>
              <Header className='product-img-header' style={{ color:'whitesmoke',backgroundColor: '#5a5a5a', height:"1.9rem", lineHeight:"30px" }}><span> List of Brands </span>  </Header>
              
              <Table
                dataSource={shopBrands}
                columns={columns}
                className="shop-brands-table"
                rowKey={ ( record ) => record &&  record.id }
                bordered
                scroll={ { y:'64.8vh'}}
                pagination={{ pageSize:100, showSizeChanger: false }}
              /> 
            </Layout>
          </Col>
          <Col span={12} style={{ backgroundColor:'#f9f9f9', padding:'10px'}}>
          <div style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px', width:'90%', margin:'0 auto', marginTop:'10px',  padding:"3px", display:'flex', justifyContent:'center'   }}>
              <Paragraph 
                strong 
                style={{ marginTop:"6px"}}
              >
                {!loadingBrandList && showBrandsList?.shopName } {!loadingBrandList && showBrandsList && date.endDate && 
                <span style={{ color:'darkred', fontSize:"12px" }}> 
                  { `(${moment( date.startDate ).format("DD MMMM YYYY")} - ${ moment( date.endDate ).format("DD MMMM YYYY") })` } 
                </span>} {loadingBrandList &&  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                { !showBrandsList && 'No selected shop' }
              </Paragraph> 
            </div>
            <Affix offsetTop={30}>
              <div className='shop-brand-box'>
                {
                  ( showBrandsList && showBrandsList?.data.length > 0 ) ? 
                    <div style={{backgroundColor:'#f9f9f9' }} >
                      <Row gutter={16} style={{ marginTop:"1rem", marginLeft:"0.4rem", marginBottom:'1rem' }}>
                      {showBrandsList?.data.map( item => 
                        <Col className="gutter-row" span={12} key={item.id}>
                          <FadeIn delay={300} transitionDuration={800} key={item.id}>
                          {!loadingBrandList ? 
                            <div className={`shop-brand-item ${ item.isChecked ? 'shop-brand-item-test':''}`}>
                              <div> 
                               {!item.isChangeName &&  
                                <React.Fragment>
                                  {!item.isChecked ? 
                                    <CheckSquareOutlined onClick={() => markBrand(item, showBrandsList.shop_id)}  style={{ position: "relative", bottom:"-1px", left:"3px", fontSize:"19px", cursor:"pointer" }}  />:
                                    <CheckCircleOutlined style={{ position: "relative", bottom:"-1px", left:"3px", fontSize:"19px", cursor:"pointer", color:'green' }}  />
                                  }
                                </React.Fragment>}
                                {item.isChangeName ? 
                                  // <Form form={form} onFinish={() => changeBrandName(item.id) }>
                                  //   <Input 
                                  //     size='small' 
                                  //     className='shop-brand-name' 
                                  //     value={brandName} 
                                  //     onChange={(e) => setBrandName(e.target.value)} 
                                  //     //onBlur={(e) => changeBrandName(item.id,e)} 
                                  //   />
                                  // </Form>
                                  <Search 
                                    placeholder="Change brand" 
                                    size='small'  
                                    style={{ width: '95%', margin:'0 5px'}}  
                                    value={brandName}
                                    onChange={(e) => setBrandName(e.target.value)} 
                                    onSearch={(e)=> changeBrandName(item.id,e)} 
                                    enterButton="Edit" 
                                  />
                                  :
                                  <a className='shop-brand-name'  href={item.link} onClick={()=> window.open(item.link, "", "width=900,height=1080")}  target="_blank" rel="noreferrer">{ item.name }</a> 
                                }
                                </div>
                                {/* <EditOutlined style={{ marginLeft:'100%' }} /> */}
                              <div style={{  display:"flex", flexDirection:'row' }}>
                                {!item.isChangeName ? 
                                <Tooltip title="Edit Brand">
                                  <EditOutlined 
                                    style={{ marginRight:'10px',marginTop:'4px', cursor:'pointer' }} 
                                    onClick={() => 
                                      {setShowBrandsList(curr => curr ?  
                                        ({ 
                                          ...curr, 
                                          data: curr.data.map(s => ({
                                            ...s,  
                                            isChangeName: s.id === item.id ? true:false 
                                          }))
                                        }): 
                                        null  
                                      );setBrandName(item.name) }
                                    } 
                                  />
                                </Tooltip>:
                                <CloseCircleOutlined 
                                  style={{ marginRight:'10px',marginTop:'4px', cursor:'pointer' }}
                                  onClick={() => resetChangeName(item)} 
                                />
                                }
                                <Popconfirm 
                                  placement="top" 
                                  title='Are you sure?' 
                                  onConfirm={() => setToNN(item.id, item.name)} 
                                  okText="Yes" 
                                  cancelText="No"
                                >
                                  <Button size='small' style={{ marginRight:'10px' }} danger type='dashed'>NN</Button>
                                </Popconfirm>
                                <Popover
                                  content={
                                    item.isShowSelect ?
                                    <div style={{ display:'flex' }}>
                                      <Select
                                        showSearch
                                        allowClear={undefined}
                                        style={{ width: '200px' }}
                                        placeholder="Select Brand"
                                        onChange={ (e) => handleBrandChange(e)}
                                        filterOption={(input, option:any) =>
                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        size="small"
                                      >
                                        { state.brands.data && state.brands.data.sort((a:any,b:any) => b.products_count - a.products_count).map((brand:any)=>
                                          <Select.Option key={brand.id} value={brand.id}>{ `(${brand.id}) ${brand.name} - ${brand.products_count}` }</Select.Option>
                                          )
                                        }
                                      </Select>
                                      <Button 
                                        size='small' 
                                        type='primary' 
                                        disabled={ item.isShowSelect && selectedBrand ? false : true }
                                        style={{ marginLeft:'5px'}} 
                                        onClick={()=> saveBrandMap({id:item.id, name: item.name})}
                                      >
                                        Save
                                      </Button>
                                      <CloseCircleOutlined 
                                        style={{ marginTop:'5px', marginLeft:'6px' }} 
                                        onClick={resetMapBrand} 
                                      />
                                    </div>:''}
                                  title={''}
                                  trigger="click"
                                  open={item.isShowSelect}
                                >
                                  <Button onClick={() =>openPopover(item.id)} type='primary' size="small" >Map</Button>
                                </Popover>
                              </div>
                             
                            </div>: 
                            <Skeleton.Input active block style={{ display:'flex', alignItems:'center', position:'relative', width:'94%', backgroundColor:'white',margin:'0 0.6rem 0.2rem 0.3rem', padding:'0.5rem 0.3rem', border:'1px solid #eef2e2' }}  />}
                          </FadeIn>
                        </Col>
                      )}
                      </Row>
                    </div>
                  :
                    <Empty style={{ margin:'50% auto' }} />
                }
              </div>
            </Affix>
          </Col>
        </Row>: <div style={{ height:'76vh', display:'flex', justifyContent:"center", alignItems:"center" }}> <Empty  description="No Data, Select product Id"/> </div>}
      </Col>
    </Row>
  )
}

export default ShopBrends