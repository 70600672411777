import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Checkbox, Modal, Row, Image, Popover, Badge, Input, Form, Spin } from "antd";
import axios from "axios";
import "../../../style/FixPricesModal.css";
import { AimOutlined, ZoomInOutlined } from "@ant-design/icons";
import { RootStore } from "../../../../../Store";
import openNotification from "../../../../../shared/MessagesInfo/WarningBox";
import showMessage from "../../../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../../../shared/utils/numberUtils";
import { PicturesType, PriceType, Tabs } from "../../../types/type";
import DefaultImage from "../DefaultImage";

type FixPricesModalType = {
    fixPriceVisible: boolean;
    setFixPriceVisible: React.Dispatch<React.SetStateAction<boolean>>;
    productInfo: { name: string; value: string | PicturesType[] }[];
    selectedTab: number;
    getPrices: () => Promise<void>;
    prices: PriceType[];
    stateProduct: RootStore;
};

export type MainProductType = {
    id: number;
    default_image_url: string;
    prices: { [key: string]: string }[];
    brand_name: string;
    image_slug: string;
};

type ProductArrType = {
    id: number;
    default_image_url: string;
    prices: { [key: string]: string }[];
    brand_name: string;
    isChecked: boolean;
    image_slug: string;
};

const FixPricesModal: React.FC<FixPricesModalType> = ({ fixPriceVisible, setFixPriceVisible, productInfo, selectedTab, getPrices, prices, stateProduct }) => {
    const [productArr, setProductArr] = useState<ProductArrType[]>([]);
    const [imageId, setImageId] = useState<number | null>(null);
    const [loadingSearchProduct, setLoadingSearchProduct] = useState(false);
    const [loadingFix, setLoadingFix] = useState(false);
    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";
    const modalRef: any = useRef(null);
    const [form] = Form.useForm();

    const handleCancel = () => {
        setFixPriceVisible(false);
        setProductArr([]);
    };

    const onfixPriceChange = (id: number) => {
        setProductArr((curr) =>
            curr.map((product) => ({
                ...product,
                isChecked: product.id === id ? !product.isChecked : product.isChecked,
            }))
        );
    };

    const contentImages = (
        <div>
            {productInfo?.find((p) => p.name === "default_image_url")?.value && imageId && (
                <Row>
                    <div style={{ width: "400px", height: "400px" }}>
                        <DefaultImage productInfo={productInfo} preview={false} />
                    </div>
                    <div style={{ width: "400px", height: "400px", border: "1px solid lightgray", marginLeft: "1rem" }}>
                        <Image
                            preview={false}
                            alt="secondImage"
                            src={`https://img.ep-cdn.com/i/500/500/${productArr.find((p) => p.id === imageId)?.default_image_url}${productArr.find((p) => p.id === imageId)?.image_slug}.webp`}
                        />
                    </div>
                </Row>
            )}
        </div>
    );

    const formSubmit = async (e: { product_id: string }) => {
        if (e?.product_id?.trim().length > 0) {
            setLoadingSearchProduct(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/product/item/show?id=${e.product_id}`);
                const newData = {
                    ...data.data,
                    isChecked: false,
                };
                setProductArr((prev) => (prev.length === 0 ? [newData] : [...prev, newData]));
                form.resetFields();
                setLoadingSearchProduct(false);
            } catch (err) {
                console.log(err);
                setLoadingSearchProduct(false);
            }
        } else {
            openNotification("Product Id is required");
        }
    };

    const fixPrices = async () => {
        const chechedProducts = productArr.filter((product) => product.isChecked);
        if (chechedProducts.length > 0) {
            const onlyId = chechedProducts.map((product) => product.id);
            const obj = {
                product_id: productInfo.find((p) => p.name === "id")?.value,
                products: onlyId,
            };
            setLoadingFix(true);
            try {
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/update-product-price`, obj);
                showMessage(data.message);
                setProductArr([]);
                setLoadingFix(false);
                selectedTab === Tabs.PRICES && getPrices();
                setFixPriceVisible(false);
            } catch (err) {
                console.log(err);
            }
        } else {
            openNotification("There is no checked products!");
        }
    };

    //const prices: any = productInfo?.find((p) => p.name === "prices")?.value;

    const productPrices = typeof prices !== "string" ? prices.sort((a: any, b: any) => a && b && a.price - b.price) : [];

    useEffect(() => {
        if (fixPriceVisible && modalRef.current) {
            modalRef.current.focus(); // Preusmerava fokus na modal
        }
    }, [fixPriceVisible]);

    return (
        <Modal
            aria-hidden={false} // Ensure aria-hidden is correctly set
            title={
                <>
                    <p>Price fixing</p>
                    <Row justify="center">
                        <Form form={form} onFinish={formSubmit} layout="inline" className="fix-price-modal-form">
                            <Form.Item label="Product Id" name="product_id">
                                <Input placeholder="Product Id" />
                            </Form.Item>
                            <Form.Item>
                                <Button danger htmlType="submit" style={{ width: "90px" }} loading={loadingSearchProduct}>
                                    Search
                                </Button>
                            </Form.Item>
                        </Form>
                    </Row>
                </>
            }
            open={fixPriceVisible}
            onCancel={handleCancel}
            width={"100%"}
            footer={false}
        >
            <div ref={modalRef} tabIndex={-1}>
                {productPrices ? (
                    <React.Fragment>
                        <div className="fix-price-modal-product-list">
                            {productPrices && (
                                <Card className="all-prices-card" style={{ backgroundColor: "beige" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "140px", height: "140px" }}>
                                            <DefaultImage productInfo={productInfo} />
                                        </div>
                                        {productPrices && (
                                            <Badge count={productPrices.length} style={{ backgroundColor: "#1d9696", width: "38px", height: "25px", fontSize: "16px", paddingTop: "1px" }} />
                                        )}
                                    </div>
                                    <div style={{ height: "300px", width: "200px" }}>
                                        <AimOutlined style={{ color: "red" }} />
                                        <br />
                                        <p>
                                            <i>
                                                Id: <b>{`${productInfo.find((p) => p.name === "id")?.value}`}</b>
                                            </i>
                                        </p>
                                        <p>
                                            <i>Model: {`${productInfo.find((p) => p.name === "model")?.value}`}</i>
                                        </p>
                                        <p>
                                            <i>Brand: {`${productInfo.find((p) => p.name === "brand")?.value}`}</i>
                                        </p>
                                        <p>
                                            <i>Category: {`${`${productInfo.find((p) => p.name === "category")?.value}`}`} </i>
                                        </p>
                                        {productPrices && productPrices[0]?.price ? (
                                            <p>
                                                <i style={{ backgroundColor: "beige" }}>
                                                    Price Range:{" "}
                                                    {productPrices &&
                                                        `${numberWithCommas(String(productPrices[0]?.price).replace(".", ",")).slice(0, -3)} - ${numberWithCommas(
                                                            String(productPrices[productPrices.length - 1]?.price).replace(".", ",")
                                                        ).slice(0, -3)}`}
                                                </i>
                                                {` ${stateProduct.countries.find((c) => c.name === currentCountryName)?.currency.toLowerCase()}`}
                                            </p>
                                        ) : (
                                            "Price Range -"
                                        )}
                                    </div>
                                </Card>
                            )}
                            <div style={{ display: "flex", marginLeft: "1rem" }}>
                                {productArr.length > 0 &&
                                    productArr.map((productItem) => (
                                        <Card className="all-prices-card" key={productItem.id}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Image width={140} src={`https://img.ep-cdn.com/i/500/500/${productItem.default_image_url}${productItem.image_slug}.webp`} />
                                                {productItem.prices && (
                                                    <Badge
                                                        count={productItem.prices.length}
                                                        style={{ backgroundColor: "#1d9696", width: "30px", height: "25px", fontSize: "16px", paddingTop: "1px" }}
                                                    />
                                                )}
                                                <Popover className="popover-images" placement="bottomRight" title="Compare images" content={contentImages} trigger="click">
                                                    <ZoomInOutlined
                                                        onClick={() => {
                                                            setImageId(productItem.id);
                                                        }}
                                                    />
                                                </Popover>
                                            </div>
                                            <div style={{ height: "300px", width: "200px" }}>
                                                <Checkbox className="fix-price-checkbox" onChange={() => onfixPriceChange(productItem.id)} />
                                                <p>
                                                    Id: <b>{`${productItem.id}`}</b>
                                                </p>
                                                <p>Name: {`${productItem[`name_${currentCountryName}` as keyof MainProductType]}`}</p>
                                                <p>Brand: {`${productItem.brand_name}`}</p>
                                                <p>Category: {`${productItem[`category_name_${currentCountryName}` as keyof MainProductType]}`}</p>
                                                {productItem.prices.length > 0 ? (
                                                    <p>
                                                        <span style={{ backgroundColor: "beige" }}>
                                                            Price Range:{" "}
                                                            {`${productItem.prices ? numberWithCommas(String(productItem.prices[0].price).replace(".", ",")) : ""} - ${
                                                                productItem.prices ? numberWithCommas(String(productItem.prices[productItem.prices.length - 1].price).replace(".", ",")) : ""
                                                            }`}
                                                        </span>
                                                    </p>
                                                ) : (
                                                    "Price Range -"
                                                )}
                                            </div>
                                        </Card>
                                    ))}
                            </div>
                        </div>
                        <Row justify="center">
                            <Button style={{ marginLeft: "auto", marginTop: "10px" }} type="primary" onClick={fixPrices} disabled={productArr.length === 0 ? true : false} loading={loadingFix}>
                                Fix prices
                            </Button>
                        </Row>
                    </React.Fragment>
                ) : (
                    <Row justify="center">
                        <Spin />
                    </Row>
                )}
            </div>
        </Modal>
    );
};

export default FixPricesModal;
