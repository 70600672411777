
import React, { forwardRef } from 'react'
import DatePicker  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/SingleDatePicker.css'

interface DatePickerReportsProps {
    selectedDate:Date
    setSelectedDate:React.Dispatch<React.SetStateAction<Date>>
    className:string
}

const SingleDatePicker:React.FC<DatePickerReportsProps> = ({ selectedDate, setSelectedDate, className }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }:any, ref:any) => (
    <button className={className} onClick={onClick} ref={ref}>
      {value}
    </button>
  ))
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date:Date) => setSelectedDate( date)}
      customInput={<ExampleCustomInput />}
      calendarClassName="date-picker-calendar-body"
      //calendarClassName="sdp-users-task-list"
      popperPlacement="left-start"
      dateFormat=" d MMM yyyy"
    />
  )
}

export default SingleDatePicker
