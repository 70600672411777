import { Button, Col, Divider, Layout, Row, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import DatePickerFromToReports from "../components/DatePickerFromToReports";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import showMessage from "../../../shared/MessagesInfo/message";
const { Header } = Layout;

type DatePickerSNReport = {
    startDate: Date;
    endDate: Date | null;
};

type MpnCheckType = {
    id: number;
    created_at: string;
    model: string;
    mpn: string;
    username: string;
};

const sortTable = (a: string, b: string, type: string) => {
    if (type === "date") {
        if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
        if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
        return 0;
    }
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

const MpnCheck = () => {
    const [date, setDate] = useState<DatePickerSNReport>({ startDate: new Date(), endDate: new Date() });
    const [mpns, setMpns] = useState<MpnCheckType[]>([]);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getMpns = async () => {
            try {
                const { data } = await axios.get(
                    `${
                        process.env.REACT_APP_URL_API
                    }/product/mpn/report-change${`?from=${formTo.startDate}&to=${formTo.endDate}`}`
                );
                setMpns(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        date.startDate && date.endDate && getMpns();
    }, [date]);

    const setMpnToNN = async (id: number) => {
        const objToSend = {
            id: id,
            mpn: null,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/item/update`, objToSend);
            showMessage(data.message);
            mpns.filter((m) => m.id !== id);
            setMpns((curr) => curr.filter((m) => m.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const columns: ColumnsType<MpnCheckType> = [
        {
            title: "Mpn",
            dataIndex: "mpn",
            width: "220px",
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
        },
        {
            title: "user",
            dataIndex: "username",
            width: "200px",
        },
        {
            title: "Link",
            dataIndex: "product_link",
            width: "120px",
            align: "center" as "center",
            render: (text) => (
                <a
                    href={text}
                    onClick={() => window.open(text, "", "width=900, height=1080, top=0, left=960")}
                    className="shop-link"
                    style={{
                        marginLeft: "0.5rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Link
                </a>
            ),
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            width: "200px",
            sorter: (a: MpnCheckType, b: MpnCheckType) => sortTable(a.created_at, b.created_at, "date"),
        },
        {
            title: "",
            dataIndex: "id",
            align: "center" as "center",
            width: "140px",
            render: (record) => (
                <Button size="small" onClick={() => setMpnToNN(record)}>
                    Remove
                </Button>
            ),
        },
    ];

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Mpn Check" />
            <Row justify="center">
                <Col span={16} style={{ backgroundColor: "white", padding: "1rem", marginTop: "1rem" }}>
                    <Divider>Mpn Check</Divider>
                    <div className="product-img-info-bar" style={{ display: "flex", justifyContent: "center" }}>
                        <DatePickerFromToReports date={date} setDate={setDate} isDate />
                    </div>
                </Col>
                <Col span={16} style={{ backgroundColor: "white", padding: "1rem", height: "77vh" }}>
                    <Layout style={{ margin: "0.6rem 1rem", backgroundColor: "white", height: "100%" }}>
                        <Header
                            className="product-img-header"
                            style={{
                                color: "whitesmoke",
                                backgroundColor: "#5a5a5a",
                                height: "1.9rem",
                                lineHeight: "30px",
                                position: "relative",
                            }}
                        />
                        <Table
                            dataSource={mpns}
                            columns={columns}
                            rowKey={(record) => record && record.id}
                            bordered
                            pagination={{ pageSize: 100, hideOnSinglePage: true, showSizeChanger: false }}
                            scroll={{ y: "62vh" }}
                        />
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MpnCheck;
