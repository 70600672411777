import axios from "axios";
import { TreeCategoryType } from "../types/types";
import numberWithCommas from "../../../shared/utils/numberUtils";

const baseUrl = process.env.REACT_APP_URL_API;

const removeSC = (text: string) => {
    return text.replace(/[()]/g, "");
};

const formatFilters = (filters: string[]) => {
    return filters
        .filter((d) => d.length > 0)
        .map((d) => `&&${d}`)
        .join("");
};

//Get Shops

let initShops: { id: number; shop_name: string; count: number; user_filter: string }[] = [];

export const getShops = async (
    setShops: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                shop_name: string;
                count: number;
            }[]
        >
    >,
    currentCountryId: number,
    currentCountryName: string,
    selectedShops: string[],
    selectedShopCategories: string[],
    search: string,
    selectedCategories: { id: string; level: string; fullCategory: string } | undefined,
    selectedBrands: string[],
    selectedNew: string | undefined,
    xml: boolean,
    user: boolean,
    strictCategory: boolean,
    loadCategory: boolean
) => {
    if (!loadCategory) {
        const shop_categories = selectedShopCategories.length > 0 ? `seller_categories:=[${selectedShopCategories.map((s) => `\`${s}\``).join(",")}]` : "";
        const categories_filter = selectedCategories ? ` ${`category${selectedCategories.level !== "3" ? `_${selectedCategories.level}` : ""}_${currentCountryName}:=${selectedCategories.id}`} ` : "";
        const user_filter = user ? `user_active:=1` : "";
        const strict_category_filter = strictCategory && selectedCategories?.level === "3" ? `category_id:=${selectedCategories.id.split("::")[0]}` : "";

        const brand_filter = selectedBrands.length > 0 ? `brand:=[${selectedBrands.map((b) => `\`${b}\``).join(",")}]` : "";

        const newProducts = selectedNew ? `condition:=${selectedNew}` : "";
        const xml_active = xml ? `active_prices_${currentCountryName}:=true` : "";
        const filters = formatFilters([shop_categories, categories_filter, brand_filter, user_filter, newProducts, xml_active, strict_category_filter]);

        const obj = {
            query: search && search.length > 0 ? search : "*",
            facet: "sellers",
            filter_by: `countries:=${currentCountryId}${filters}`,
        };

        try {
            const { data } = await axios.post(`${baseUrl}/prices/get-facets`, obj);
            let shopsWithZero: {
                id: number;
                shop_name: string;
                count: number;
                user_filter: string;
            }[] = [];

            let shopIds: string[] = [];

            user &&
                data.data.user_shops.forEach((d: string) => {
                    const splitWord = d.split("::");
                    splitWord[2].toString() === currentCountryId.toString() && shopIds.push(`${splitWord[0]}::${splitWord[1]}`);
                });

            setShops((curr) => {
                const allShops = data.data[0].counts
                    .map((d: { value: string; count: number }) => {
                        const splitWord = d.value.split("::");
                        if (splitWord[2] === currentCountryId.toString()) {
                            return {
                                id: d.value,
                                shop_name: splitWord[1],
                                count: d.count,
                                user_filter: `${splitWord[0]}::${splitWord[1]}`,
                            };
                        }
                        return null;
                    })
                    .filter((s: { id: string; user_filter: string }) => (user ? s && shopIds.includes(s.user_filter) : true));

                initShops.forEach((s) => {
                    selectedShops?.forEach((selShop) => {
                        if (s?.id.toString() === selShop) {
                            const checkShop = allShops.find((shop: { id: string }) => shop?.id.toString() === s?.id.toString());
                            const findEmptyShop = curr.find((sh) => sh?.id.toString() === s?.id.toString());
                            if (!checkShop && findEmptyShop) {
                                shopsWithZero.push({ ...s, count: 0 });
                            }
                        }
                    });
                });

                return [...allShops, ...(shopsWithZero.length > 0 ? shopsWithZero : [])];
            });

            initShops = data.data[0].counts
                .map((d: { value: string; count: number }) => {
                    const splitWord = d.value.split("::");
                    if (splitWord[2] === currentCountryId.toString()) {
                        return {
                            id: d.value,
                            shop_name: splitWord[1],
                            count: d.count,
                            user_filter: `${splitWord[0]}::${splitWord[1]}`,
                        };
                    }
                    return null;
                })
                .filter((s: { id: string; user_filter: string }) => (user ? s && shopIds.includes(s.user_filter) : true));
        } catch (err) {
            console.log(err);
        }
    }
};

// Get shop categories

let initShopCategories: { id: string; shop_category_name: string; count: number }[] = [];

export const getShopCategories = async (
    setShopCategories: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                shop_category_name: string;
                count: number;
            }[]
        >
    >,
    currentCountryId: number,
    currentCountryName: string,
    search: string,
    selectedShops: string[],
    selectedShopCategories: string[],
    selectedBrands: string[],
    selectedCategories: { id: string; level: string; fullCategory: string } | undefined,
    selectedNew: string | undefined,
    xml: boolean,
    user: boolean,
    strictCategory: boolean
) => {
    const seller_filter = selectedShops.length > 0 ? `sellers:=[${selectedShops.map((s) => `\`${s}\``).join(",")}]` : "";

    const categories_filter = selectedCategories ? ` ${`category${selectedCategories.level !== "3" ? `_${selectedCategories.level}` : ""}_${currentCountryName}:=${selectedCategories.id}`} ` : "";

    const brand_filter = selectedBrands.length > 0 ? `brand:=[${selectedBrands.map((b) => `\`${b}\``).join(",")}]` : "";

    const user_filter = user ? `user_active:=1` : "";
    const strict_category_filter = strictCategory && selectedCategories?.level === "3" ? `category_id:=${selectedCategories.id.split("::")[0]}` : "";
    const newProducts = selectedNew ? `condition:=${selectedNew}` : "";

    const xml_active = xml ? `active_prices_${currentCountryName}:=true` : "";

    const filters = formatFilters([seller_filter, brand_filter, categories_filter, user_filter, newProducts, xml_active, strict_category_filter]);

    if (selectedShops.length > 0) {
        const obj = {
            query: search && search.length > 0 ? search : "*",
            facet: "seller_categories",
            filter_by: `countries:=${currentCountryId}${filters}`,
        };
        try {
            const { data } = await axios.post(`${baseUrl}/prices/get-facets`, obj);

            let shopCategoryWithZero: {
                id: string;
                shop_category_name: string;
                count: number;
            }[] = [];

            const shops = selectedShops.map((s) => s.split("::")[0]);

            setShopCategories((curr) => {
                const allShopCategories = data.data[0].counts
                    .map((d: { value: string; count: number }) => {
                        const splitWord = d.value.split("::");
                        if (shops.includes(splitWord[0])) {
                            return {
                                id: d.value,
                                shop_category_name: splitWord[1],
                                count: d.count,
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter((d: { shop_category_name: string }) => d && d.shop_category_name);

                initShopCategories.forEach((sc) => {
                    selectedShopCategories?.forEach((selShopCat) => {
                        if (sc.id === selShopCat) {
                            const checkShopCategory = allShopCategories.find((c: { id: string }) => c.id.toString() === sc.id.toString());
                            const findEmptyBrand = curr.find((br) => br.id.toString() === sc.id.toString());
                            if (!checkShopCategory && findEmptyBrand) {
                                shopCategoryWithZero.push({ ...sc, count: 0 });
                            }
                        }
                    });
                });

                return [...allShopCategories, ...(shopCategoryWithZero.length > 0 ? shopCategoryWithZero : [])];
            });

            initShopCategories = data.data[0].counts
                .map((d: { value: string; count: number }) => {
                    const splitWord = d.value.split("::");
                    if (shops.includes(splitWord[0])) {
                        return {
                            id: d.value,
                            shop_category_name: splitWord[1],
                            count: d.count,
                        };
                    } else {
                        return null;
                    }
                })
                .filter((d: { shop_category_name: string }) => d && d.shop_category_name);
        } catch (err) {
            console.log(err);
        }
    } else {
        setShopCategories([]);
        initShopCategories = [];
    }
};

// Get Categories

export const getCategories = async (
    setCategories: React.Dispatch<React.SetStateAction<TreeCategoryType[]>>,
    currentCountryId: number,
    currentCountryName: string,
    selectedShopCategories: string[],
    search: string,
    selectedShops: string[],
    selectedBrands: string[],
    selectedNew: string | undefined,
    xml: boolean,
    user: boolean,
    strictCategory: boolean,
    loadCategory: boolean,
    setloadChangeCountry: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCategories: { id: string; level: string; fullCategory: string } | undefined
) => {
    if (!loadCategory) {
        const seller_filter = selectedShops.length > 0 ? `sellers:=[${selectedShops.map((s) => `\`${s}\``).join(",")}]` : "";

        const shop_categories_filter = selectedShopCategories && selectedShopCategories.length > 0 ? `seller_categories:=[${selectedShopCategories.map((c) => `\`${c}\``).join(",")}]` : "";

        const brand_filter = selectedBrands.length > 0 ? `brand:=[${selectedBrands.map((b) => `\`${b}\``).join(",")}]` : "";

        const user_filter = user ? `user_active:=1` : "";
        const strict_category_filter = strictCategory && selectedCategories?.level === "3" ? `category_id:=${selectedCategories.id.split("::")[0]}` : "";
        const newProducts = selectedNew ? `condition:=${selectedNew}` : "";

        const xml_active = xml ? `active_prices_${currentCountryName}:=true` : "";

        const filters = formatFilters([seller_filter, shop_categories_filter, brand_filter, user_filter, newProducts, xml_active, strict_category_filter]);

        const obj = {
            query: search && search.length > 0 ? search : "*",
            facet: `category_1_${currentCountryName},category_2_${currentCountryName}, category_${currentCountryName}, category_4_${currentCountryName}`,
            filter_by: `countries:=${currentCountryId}${filters}`,
        };

        try {
            const { data } = await axios.post(`${baseUrl}/prices/get-facets`, obj);
            const level1 = data.data[0].counts
                .map((l1: { value: string; count: number }) => {
                    const splitWord = l1.value.split("::");
                    return {
                        value: removeSC(`${l1.value}--1`),
                        id: splitWord[0],
                        title: `(L1) ${splitWord[0]}: ${splitWord[1]} (${numberWithCommas(String(l1.count).replace(".", ","))})`,
                        level: 1,
                        count: l1.count,
                    };
                })
                .sort((a: { value: string; count: number }, b: { value: string; count: number }) => a.value.split("::")[1].localeCompare(b.value.split("::")[1]));

            const level2 = data.data[1].counts
                .map((l2: { value: string; count: number }) => {
                    const splitWord = l2.value.split("::");
                    return {
                        value: removeSC(`${l2.value}--2`),
                        id: splitWord[0],
                        title: `(L2) ${splitWord[0]}: ${splitWord[1]} (${numberWithCommas(String(l2.count).replace(".", ","))})`,
                        count: l2.count,
                        level: 2,
                        parent_id: splitWord[2],
                    };
                })
                .sort((a: { value: string; count: number }, b: { value: string; count: number }) => a.value.split("::")[1].localeCompare(b.value.split("::")[1]));
            const level3 = data.data[2].counts
                .map((l3: { value: string; count: number }) => {
                    const splitWord = l3.value.split("::");
                    return {
                        value: removeSC(`${l3.value}--3`),
                        id: splitWord[0],
                        title: `(L3) ${splitWord[0]}: ${splitWord[1]} (${numberWithCommas(String(l3.count).replace(".", ","))})`,
                        count: l3.count,
                        level: 3,
                        parent_id: splitWord[2],
                    };
                })
                .sort((a: { value: string; count: number }, b: { value: string; count: number }) => a.value.split("::")[1].localeCompare(b.value.split("::")[1]));
            const level4 = data.data[3].counts
                .filter((l4: { value: string; count: number }) => l4.value)
                .map((l4: { value: string; count: number }) => {
                    const splitWord = l4.value.split("::");
                    return {
                        value: removeSC(`${l4.value}--4`),
                        id: splitWord[0],
                        title: `(L4) ${splitWord[0]}: ${splitWord[1]} (${numberWithCommas(String(l4.count).replace(".", ","))})`,
                        count: l4.count,
                        level: 4,
                        parent_id: splitWord[2],
                    };
                })
                .sort((a: { value: string; count: number }, b: { value: string; count: number }) => a.value.split("::")[1].localeCompare(b.value.split("::")[1]));

            const l1l2 = level1.map((l1: TreeCategoryType) => {
                const findChilrenForL1 = level2.filter((l2: TreeCategoryType) => l2.parent_id === l1.id);
                return {
                    ...l1,
                    children: findChilrenForL1,
                };
            });

            const l1l2l3 = l1l2.map((l1: TreeCategoryType) => {
                return {
                    ...l1,
                    children: l1.children?.map((l2) => {
                        const findChilrenForL2 = level3.filter((l3: TreeCategoryType) => l3.parent_id === l2.id);
                        return {
                            ...l2,
                            children: findChilrenForL2,
                        };
                    }),
                };
            });

            const l1l2l3l4 = l1l2l3.map((l1: TreeCategoryType) => {
                return {
                    ...l1,
                    children: l1.children?.map((l2: TreeCategoryType) => {
                        return {
                            ...l2,
                            children: l2.children?.map((l3: TreeCategoryType) => {
                                const findChilrenForL3 = level4.filter((l4: TreeCategoryType) => l4.parent_id === l3.id);
                                return {
                                    ...l3,
                                    children: findChilrenForL3,
                                };
                            }),
                        };
                    }),
                };
            });

            setCategories(l1l2l3l4);
            setloadChangeCountry(false); // because of changing countries
        } catch (err) {
            console.log(err);
        }
    }
};

//Get Brands

let intBrands: { id: string; brand_name: string; count: number }[] = [];

export const getBrands = async (
    setBrands: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                brand_name: string;
                count: number;
            }[]
        >
    >,
    currentCountryId: number,
    currentCountryName: string,
    loadCategory: boolean,
    selectedShopCategories?: string[],
    selectedBrands?: string[],
    selectedNew?: string | undefined,
    search?: string,
    selectedShops?: string[],
    selectedCategories?: { id: string; level: string; fullCategory: string } | undefined,
    xml?: boolean,
    user?: boolean,
    strictCategory?: boolean
) => {
    if (!loadCategory) {
        const seller_filter = selectedShops && selectedShops.length > 0 ? `sellers:=[${selectedShops.map((s) => `\`${s}\``).join(",")}]` : "";

        const shop_categories_filter = selectedShopCategories && selectedShopCategories.length > 0 ? `seller_categories:=[${selectedShopCategories.map((c) => `\`${c}\``).join(",")}]` : "";

        const categories_filter = selectedCategories ? ` ${`category${selectedCategories.level !== "3" ? `_${selectedCategories.level}` : ""}_${currentCountryName}:=${selectedCategories.id}`} ` : "";

        const user_filter = user ? `user_active:=1` : "";
        const strict_category_filter = strictCategory && selectedCategories?.level === "3" ? `category_id:=${selectedCategories.id.split("::")[0]}` : "";

        const newProducts = selectedNew ? `condition:=${selectedNew}` : "";
        const xml_active = xml ? `active_prices_${currentCountryName}:=true` : "";
        const filters = formatFilters([seller_filter, shop_categories_filter, categories_filter, user_filter, newProducts, xml_active, strict_category_filter]);

        const obj = {
            query: search && search.length > 0 ? search : "*",
            facet: "brand",
            filter_by: `countries:=${currentCountryId}${filters}`,
        };

        try {
            const { data } = await axios.post(`${baseUrl}/prices/get-facets`, obj);
            let brandsWithZero: {
                id: string;
                brand_name: string;
                count: number;
            }[] = [];

            setBrands((curr) => {
                const findNN = data.data[0].counts.find((b: { value: string }) => b.value === "3621::NN");
                const allBrands = [
                    ...(findNN
                        ? [
                              {
                                  id: findNN.value,
                                  brand_name: findNN.value.split("::")[1],
                                  count: findNN.count,
                              },
                          ]
                        : []),
                    ...data.data[0].counts
                        .map((d: { value: string; count: number }) => {
                            const splitWord = d.value.split("::");
                            return {
                                id: d.value,
                                brand_name: splitWord[1],
                                count: d.count,
                            };
                        })
                        .filter((b: { id: string }) => b.id !== "3621::NN")
                        .sort((a: { count: number }, b: { count: number }) => b.count - a.count),
                ];

                intBrands.forEach((b) => {
                    selectedBrands?.forEach((selBrand) => {
                        if (b.id === selBrand) {
                            const checkBrand = allBrands.find((c: { id: string }) => c.id.toString() === b.id.toString());
                            const findEmptyBrand = curr.find((br) => br.id.toString() === b.id.toString());
                            if (!checkBrand && findEmptyBrand) {
                                brandsWithZero.push({ ...b, count: 0 });
                            }
                        }
                    });
                });

                return [...allBrands, ...(brandsWithZero.length > 0 ? brandsWithZero : [])];
            });

            const findNN = data.data[0].counts.find((b: { value: string }) => b.value === "3621::NN");
            intBrands = [
                ...(findNN
                    ? [
                          {
                              id: findNN.value,
                              brand_name: findNN.value.split("::")[1],
                              count: findNN.count,
                          },
                      ]
                    : []),
                ...data.data[0].counts
                    .map((d: { value: string; count: number }) => {
                        const splitWord = d.value.split("::");
                        return {
                            id: d.value,
                            brand_name: splitWord[1],
                            count: d.count,
                        };
                    })
                    .filter((b: { id: string }) => b.id !== "3621::NN")
                    .sort((a: { count: number }, b: { count: number }) => b.count - a.count),
            ];
        } catch (err) {
            console.log(err);
        }
    }
};
