import React, { ReactNode } from "react";
import { Layout } from "antd";

interface HeaderProps {
    children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
    return (
        <Layout.Header
            style={{
                color: "whitesmoke",
                backgroundColor: "#5a5a5a",
                height: "1.9rem",
                lineHeight: "30px",
                position: "relative",
            }}
        >
            {children}
        </Layout.Header>
    );
};

export default Header;
