import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Form, Input, Select, Spin } from "antd";
import type { FormInstance } from "antd/es/form";
//import { EditProductMpnType, clearMpn } from '../../pages/EditProductMpn';
import { CloseOutlined, EditTwoTone, Loading3QuartersOutlined } from "@ant-design/icons";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import errorMessage from "../../../../shared/MessagesInfo/ErrorMessage";
import { EditProductMpnType, clearMpn } from "../../pages/EditProductMpn";

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof EditProductMpnType;
    record: EditProductMpnType;
    setProducMpn: React.Dispatch<React.SetStateAction<EditProductMpnType[]>>;
    lastDeletedMpn: null | number;
    handleSave: (record: EditProductMpnType, type: string) => void;
    brandList: { id: number; name: string }[];
    currentCountryName: string;
    removeMpn: (id: number) => void;
    updateProduct: (id: number, text: string | number | undefined | null, type: string) => Promise<void>;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    setProducMpn,
    lastDeletedMpn,
    handleSave,
    brandList,
    currentCountryName,
    updateProduct,
    removeMpn,
    ...restProps
}) => {
    const form = useContext(EditableContext)!;
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const [loadingBrand, setLoadingBrand] = useState(false);
    const [loadingMpn, setLoadingMpn] = useState(false);

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    useEffect(() => {
        dataIndex === "mpn" && form.setFieldValue("mpn", record.mpn);
    }, [record?.mpn, dataIndex, form]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    useEffect(() => {}, [lastDeletedMpn]);

    const save = async () => {
        try {
            if (dataIndex === "mpn") {
                inputRef.current?.blur();
                const values = await form.validateFields();
                //console.log(values.mpn)

                if (values.mpn && values.mpn.trim().length < 4) {
                    openNotification("Mpn text has to be longer than 3 characters");
                    setProducMpn((curr) => curr.map((m) => (m.id === record.id ? { ...m, mpn: null } : m)));
                    toggleEdit();

                    return;
                }

                if (record.mpn?.trim() === values.mpn?.trim()) {
                    // is new mpn is the same as previous don't call endpoint
                    return;
                }
                setLoadingMpn(true);
                updateProduct(record.id, values.mpn ? clearMpn(values.mpn) : null, "mpn")
                    .then(() => {
                        handleSave({ ...record, ...values }, dataIndex);

                        form.setFieldValue(dataIndex, values.mpn ? clearMpn(values.mpn) : "");
                        setLoadingMpn(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoadingMpn(false);
                    });
            }
            if (dataIndex === "brand") {
                setLoadingBrand(true);
                const values = await form.validateFields();
                const findBrand = brandList.find((b) => b.id === values.brand);

                updateProduct(record.id, findBrand?.id, "brand_id")
                    .then((resp) => {
                        if (resp !== undefined) {
                            const tempObj = {
                                brand: findBrand?.name as string,
                                //mpn:values.mpn
                                //name: values.name
                            };

                            handleSave({ ...record, ...tempObj }, dataIndex);
                            toggleEdit();
                            setLoadingBrand(false);
                        } else {
                            errorMessage("Error!");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            if (dataIndex === `name_${currentCountryName}`) {
                const values = await form.validateFields();
                if (!values[`name_${currentCountryName}`] || values[`name_${currentCountryName}`].trim().length < 5) {
                    openNotification("Name has to be longer than 4 characters");
                    return;
                }
                if (
                    record[`name_${currentCountryName}` as keyof typeof record]?.toString().trim() ===
                    values[`name_${currentCountryName}`]?.trim()
                ) {
                    // is new name is the same as previous don't call endpoint
                    toggleEdit();
                    return;
                }
                updateProduct(record.id, values[`name_${currentCountryName}`], `name_${currentCountryName}`)
                    .then((resp) => {
                        if (resp !== undefined) {
                            handleSave({ ...record, ...values }, dataIndex);
                            toggleEdit();
                        } else {
                            errorMessage("Error!");
                        }
                    })
                    .catch((err) => console.log(err));
            }
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    const saveBrand = () => {
        toggleEdit();
    };

    let childNode = children;

    if (editable && dataIndex === "mpn") {
        childNode = (
            <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item style={{ margin: 0, width: "300px" }} name={dataIndex}>
                    <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        //onChange={handleInputChange}
                        style={{ width: "300px" }}
                        status={record.isMpnShort ? "error" : ""}
                        suffix={
                            loadingMpn || record.isProductMpnLoading ? (
                                <Spin
                                    size="small"
                                    indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />}
                                />
                            ) : (
                                <span />
                            )
                        }
                    />
                </Form.Item>
                <Button
                    size="small"
                    style={{ width: "24px", color: "#ffaaa5", marginLeft: "10px" }}
                    // loading={ record.isProductMpnLoading }
                    icon={<CloseOutlined /> /*!record.isProductMpnLoading ? <CloseOutlined />:""*/}
                    onClick={() => removeMpn(record.id)}
                    loading={record.removeMpnLoading}
                />
            </div>
        );
    }

    if (editable && dataIndex === "brand") {
        childNode = (
            <div>
                {editing ? (
                    <Form.Item style={{ margin: 0 }} name={dataIndex}>
                        <Select
                            ref={inputRef}
                            onChange={save}
                            onBlur={saveBrand}
                            autoFocus
                            defaultOpen
                            showSearch
                            filterOption={(input: string, option?: { label: string; value: number }) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={brandList?.map((b) => ({ label: b.name, value: b.id }))}
                            loading={loadingBrand}
                        />
                    </Form.Item>
                ) : (
                    <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                        {children}
                    </div>
                )}
            </div>
        );
    }

    if (editable && dataIndex === `name_${currentCountryName}`) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} size="large" />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 14, display: "flex", justifyContent: "space-between", cursor: "default" }}
            >
                {children}

                <span onClick={toggleEdit} style={{ cursor: "pointer" }}>
                    <EditTwoTone />
                </span>
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
