import axios from "axios";
import { Dispatch } from "react";
import { LogsByShop, LOGS_BY_SHOP, TransformGet, TransformPriceToDb, TRANSFORM_GET, TRANSFORM_PRICE_TO_DB } from "./transformActionType";

//----------get transform-----------

export const getTransform = ( id:any, setLoading:any, setIsModalTransformVisible:any ) => async ( dispatch:Dispatch<TransformGet> ) => {
    setLoading(true)
    try {
       
        const {data} = await axios.get(`${process.env.REACT_APP_URL_API}/maps/transform?shop_id=${ id }`)
        dispatch({ type: TRANSFORM_GET, payload:data })
        setLoading(false)
         typeof(setIsModalTransformVisible) !== 'string' && setIsModalTransformVisible(true)
    } catch ( err ) {
        console.log(err)
        setLoading(false)
    }
}

//--------price to DB

export const priceToDb = ( objToSend:any, setIsModalVisible:any, setLoadingBtn:any ) => async (dispatch:Dispatch<TransformPriceToDb>) => {
    try {
        const { data } = await axios.post(`${ process.env.REACT_APP_URL_API}/feed-parser/insert-main-fields`, objToSend )
        console.log(data)
        if (data.status) {
            setIsModalVisible(true)
        }
        setLoadingBtn(false)
        dispatch({type:TRANSFORM_PRICE_TO_DB, payload:data})
    } catch ( err ) {
        console.log(err)
    }
}

//---------feed parser download 

export const feedParser =  ( path:any )  => {
    const ext = path.substring( path.length-3, path.length)
    try {
        axios({
            url: `${ process.env.REACT_APP_URL_API}/feed-parser/feed-download?feed_file=${ path }`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `file.${ext}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    } catch ( err ) {
        console.log(err)
    }
}

//---------logs by shop

export const getLogsByShop = ( id:any, setShowError:any, setLoading:any ) => async ( dispatch:Dispatch<LogsByShop> ) => {
    setLoading(true)
    try {
        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/feed-parser/get-shop-price-items?shop_id=${id}`)
        //setLogs(data)
        dispatch({ type:LOGS_BY_SHOP, payload:data })
        setShowError(false)
        setLoading(false)
    } catch ( err ) {
        console.log(err)
        setShowError(true)
        setLoading(false)
    }
}

