import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Divider, Checkbox, Space, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getAllMainMap } from '../../../actions/maps/mapsAction'
import { RootStore } from '../../../Store'
import { getAllShops } from '../../../actions/shopAction/shopAction'
import { CaretDownOutlined } from '@ant-design/icons'
import '../style/AppendReplaceXMLdata.css'
import axios from 'axios'
import showMessage from '../../../shared/MessagesInfo/message'
import openNotification from '../../../shared/MessagesInfo/WarningBox'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
const { Option } = Select

const AppendReplaceXMLdata = () => {
    const [ checkedCategorized, setCheckedCategorized ] = useState({
        categorized:false,
        uncategorized:false
    })
    const [ unique, setUnique ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ checkedItems, setCheckedItems ] = useState<any>([])
    const [ showReplaceAll, setShowReplaceAll ] = useState(false)
    const [ replaceAll, setReplaceAll ] = useState(false)
    const [ form ] = Form.useForm()
    const state = useSelector(( state:RootStore ) => state)
    const dispatch = useDispatch()
    const style = { background: '#fff', padding: '16px 16px', borderRadius:'5px' };

    const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1
    useEffect(()=>{
        dispatch(getAllShops(0, currentCountryId))
    }, [ dispatch, currentCountryId ])

    useEffect(()=>{
        dispatch(getAllMainMap())
    }, [ dispatch ])
   
   
    const makeCheckeArray = (name:any, checked:boolean) => {
        if(name ==='seller_image_url') {
            setShowReplaceAll(checked)
            form.setFieldValue('action',2)
            if(!checked){
                setReplaceAll(false)
            }
        }
        if ( checked && !checkedItems.includes(name) ) {
            setCheckedItems((prev:any) => [...prev,name])
        }
        else if ( !checked ) {
            setCheckedItems((prev:any) => {
                const newArr = prev.filter((i:any) => i !== name)
                return newArr
            })
        }
    }
    const submitForm = async (e:any) => {
        if ( checkedItems.length === 0 ) {
            openNotification('Choose feeld')
        }
        else {
            setLoading(true)
            const obj = {
                ...e,
                categorized:checkedCategorized.uncategorized === true ? 1:0,
                fields:checkedItems,
                replace_all:replaceAll ? 1:0,
                ...(unique && {[`unique`]: 1}),
                ...(showReplaceAll && {[`action`]: 2})
            }
            console.log(obj)
            try {
                const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/feed-parser/update-product-prices`, obj)
                form.resetFields()
                setUnique(false)
                setCheckedItems([])
                showMessage(data.message)
                setLoading(false)

            } catch ( err ) {
                console.log(err)
                setLoading(false)
            }
        }
        
    }

    if (!state.mainMap.data) {
        return  <div className="loading"> Loading&#8230;</div>
    }
    if (loading) {
        return <><span style={{position:'absolute', left:'44%', top:'40vh'}}>Loading ...(It may take some time)</span><div className="loading"> Loading...&#8230;</div></>
    }
    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Append/Replace XML data" />
            <Row justify={"center"}>
                <Divider>Append/Replace XML data</Divider>
                <Col span={20} className="append-replace-box">
                    <Form
                        onFinish={submitForm}
                        form={form}
                    >
                        <Form.Item 
                            label="" 
                            name="shop_id"
                            rules={[{ required: true, message: 'Missing Shop' }]}
                            style={{ width:'250px'}}
                        >
                            <Select
                                suffixIcon={<CaretDownOutlined />}
                                showSearch
                                //mode="multiple"
                                allowClear
                                style={{ width: '190px'}}
                                placeholder="Select shop"
                                value={undefined}
                            
                                filterOption={(input, option:any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {state.shops.data && state.shops.data.sort((a:any,b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((brand:any)=>(
                                    <Option key={brand.id} value={brand.id}>{ brand.name }</Option>
                                ))
                                }
                            </Select>
                        </Form.Item>
                        <Divider></Divider>
                        <Row gutter={[16, 24]} className='append-replace-check-box'>
                            { state.mainMap.data.map(( mainKey:any ) =>(
                                <Col 
                                    key={ mainKey.id } 
                                    className="gutter-row" 
                                    span={ 6 }
                                >
                                    <div style={style} >
                                        <Checkbox  onChange={(e)=>makeCheckeArray(mainKey.key_name,e.target.checked)} className="main-key-checkbox" /> <Space> {" "}</Space>
                                        { mainKey.key_name }
                                        { mainKey.key_name === 'seller_image_url' && showReplaceAll && <Checkbox checked={replaceAll} style={{ marginLeft:'5px' }} onChange={(e)=>setReplaceAll(e.target.checked)} >replace_all </Checkbox>}
                                    </div>
                                </Col>
                            ))
                            }
                        </Row>
                        <Divider></Divider>
                        <Form.Item 
                            label="" 
                            name="action"
                            rules={[{ required: showReplaceAll ? false:true, message: 'Missing Action' }]}
                            style={{ width:'250px'}}
                        >
                            <Select
                                className='prices-sort-select' 
                                style={{width:'160px', textAlign:"center", margin:'0 auto', marginBottom:'1rem'}}
                                placeholder="Choose action"
                                disabled={ showReplaceAll }
                            >
                                <Option value={1}>Append</Option>
                                <Option value={2}>Replace</Option>
                            </Select>
                        </Form.Item><br/> 
                        <Form.Item 
                            label="" 
                            name="type"
                            style={{ width:'250px'}}
                            rules={[{ required: true, message: 'Missing Solution' }]}
                        >
                            <Select
                                className='prices-sort-select' 
                                //defaultValue="Sort"  
                                style={{width:'160px', textAlign:"center", margin:'0 auto', marginBottom:'1rem' }}
                                placeholder="Choose Solution"
                            >
                                <Option value={1}>Price</Option>
                                <Option value={2}>Product</Option>
                                <Option value={3}>Price and Product</Option>
                            </Select>
                        </Form.Item><br/>
                        <Form.Item 
                            label="Uncategorized"  
                            name="categorized"  //change because of api
                            style={{width:'100%'}}
                            valuePropName="checked"
                        >
                            <Checkbox 
                                checked={checkedCategorized.uncategorized}
                                onChange={(e) =>setCheckedCategorized((prev:any)=>{return {...prev, uncategorized:e.target.checked }})}
                            >
                            </Checkbox>
                        </Form.Item>
                        {/* <Form.Item 
                            label="Unique (product with one price)"  
                            name="unique 1"  //change because of api
                            style={{width:'100%'}}
                            valuePropName="checked"
                        > */}
                        <label htmlFor='unique1'>Unique (product with one price): </label>{' '}
                            <Checkbox 
                                id='unique1'
                                checked={unique}
                                onChange={(e) =>setUnique((prev:any)=>!prev)}
                            >
                            </Checkbox><br/>
                        
                        {/* </Form.Item> */}
                        <Divider style={{ margin:'1.5rem 0'}}/>
                        <button type='submit' className='u-button'>Save</button>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default AppendReplaceXMLdata
