import { Col, Row, Select, TreeSelect } from "antd";
import React from "react";
import { EansType } from "../../pages/EanOverview";

interface FiltersProps {
    category: { selectedCategory: string | undefined; setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>> };
    cheched: { checked: "1" | "2" | undefined; setChecked: React.Dispatch<React.SetStateAction<"1" | "2" | undefined>> };
    order: { order: "asc" | "desc" | undefined; setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>> };
    orderBy: { orderBy: "1" | "1" | "3" | undefined; setOrderBy: React.Dispatch<React.SetStateAction<"1" | "1" | "3" | undefined>> };
    setProductList: React.Dispatch<React.SetStateAction<Record<string, string | number>[]>>;
    setEans: React.Dispatch<React.SetStateAction<EansType>>;
    categories: { id: number; name: string; count: number; l2_name: string; children: { id: number; name: string; count: number }[] }[];
    loadingCategories: boolean;
}

const Filters = (props: FiltersProps) => {
    const { category, cheched, order, orderBy, setProductList, setEans, categories, loadingCategories } = props;

    const changeCategory = (e: string) => {
        if (!e) {
            setProductList([]);
            setEans({ data: [], product_id: undefined, eanInfo: {} });
            cheched.setChecked(undefined);
            order.setOrder(undefined);
            orderBy.setOrderBy(undefined);
        }
        category.setSelectedCategory(e);
    };

    return (
        <Row justify="space-between" align="middle" className="ean-cleaning-box">
            <div className="ean-cleaning-filter-box">
                <TreeSelect
                    showSearch
                    style={{ width: "447px", marginTop: "10px" }}
                    placeholder="Select Category"
                    allowClear
                    onChange={changeCategory}
                    treeDefaultExpandAll={false}
                    value={category.selectedCategory}
                    loading={loadingCategories}
                    filterTreeNode={(search: string, item: any) => {
                        // console.log(item.title.props.children[0]);
                        return item?.title?.props?.children[0].toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    treeData={categories.map((c3) => ({
                        title: (
                            <span style={{ display: "flex" }}>
                                {`${c3?.id}: ${c3?.name} `} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray", marginLeft: "0.3rem" }}> {`(${c3.l2_name}) `}</span>
                                <div style={{ marginLeft: "0.5rem" }}>{`${c3?.count}`}</div>
                            </span>
                        ),
                        value: c3?.id,
                        children: c3.children
                            ? c3.children.map((c4) => ({
                                  title: `${c4.id}: ${c4.name} ${c4.count}`,
                                  value: c4.id,
                              }))
                            : [],
                    }))}
                    // treeData={
                    //     categories
                    //         ? categories.map((c3) => ({
                    //               value: c3?.id,
                    //               title: (
                    //                   <span>
                    //                       {`(L3) ${c3.id}: ${c3?.name}`}
                    //                       <span style={{ fontSize: "12px", opacity: "0.8", color: "gray" }}>{` (${c3.l2_name})`}</span>
                    //                   </span>
                    //               ),
                    //               children: c3.children
                    //                   ? c3.children.map((c4) => ({
                    //                         value: c4.id,
                    //                         title: <span>{`(L4) ${c4.id}: ${c4.name}`}</span>,
                    //                     }))
                    //                   : [],
                    //           }))
                    //         : []
                    // }
                />
                <Row>
                    <Col span={8}>
                        <Select
                            style={{ width: "145px", marginTop: "10px", marginRight: "7px" }}
                            allowClear
                            placeholder="Status"
                            onChange={(e) => cheched.setChecked(e)}
                            value={cheched.checked}
                            options={[
                                { value: "1", label: "Checked" },
                                { value: "2", label: "Not Cheched" },
                            ]}
                        />
                    </Col>
                    <Col span={8}>
                        <Select
                            style={{ width: "145px", marginTop: "10px", marginRight: "7px" }}
                            allowClear
                            placeholder="Order"
                            onChange={(e) => {
                                order.setOrder(e);
                                orderBy.setOrderBy(undefined);
                            }}
                            value={order.order}
                            options={[
                                { value: "asc", label: "Ascending" },
                                { value: "desc ", label: "Descending" },
                            ]}
                        />
                    </Col>
                    <Col span={8}>
                        <Select
                            style={{ width: "145px", marginTop: "10px" }}
                            allowClear
                            placeholder="Order By"
                            onChange={(e) => {
                                orderBy.setOrderBy(e);
                                order.setOrder(undefined);
                            }}
                            value={orderBy.orderBy}
                            options={[
                                { value: "1", label: "1 + " },
                                { value: "2", label: "2 + " },
                                { value: "3", label: "3 + " },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
            {/* <Card className="ean-cleaning-card-statistic">
        <Statistic title="Total" value={count !== null ? numberWithCommas(count) : ""} valueStyle={{ color: "green" }} loading={countLoading} />
    </Card> */}
        </Row>
    );
};

export default Filters;
