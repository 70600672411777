import { Button, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'

const CorrectWordModal:React.FC<any> = ({ correctWordModal,correctLatinWord,setCorrectLatinWord, saveCorrectWord, closeCorrectWordModal }) => {
    return (
        <Modal 
                    title="Set correct word" 
                    open={correctWordModal} /*onOk={handleOk} onCancel={handleCancel}*/
                    width={360}
                    footer={false}
                    onCancel={closeCorrectWordModal}
                >
                    <label>Misspelled Word</label> 
                    <Input 
                        placeholder="Misspelled Word" 
                        value={ correctLatinWord.uncorrect }  
                        onChange={(e) => setCorrectLatinWord({...correctLatinWord,uncorrect:e.target.value})} 
                    />
                    <br />
                    <br/>
                    <label>Correct Word</label> 
                    <Input 
                        placeholder="Correct Word" 
                        value={ correctLatinWord.correct } 
                        onChange={(e) => setCorrectLatinWord({...correctLatinWord,correct:e.target.value})} 
                    />
                    <br />
                    <Button 
                        type='primary' 
                        style={{ marginTop:'1rem'}} 
                        disabled={correctLatinWord.uncorrect.trim() === '' || correctLatinWord.correct.trim() === '' ? true:false}
                        onClick={saveCorrectWord}
                    >
                        Save
                    </Button>
                </Modal>
    )
}

export default CorrectWordModal
