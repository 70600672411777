import { Image } from "antd";
import { PicturesType } from "../../types/type";

interface DefaultImageType {
    productInfo: { name: string; value: string | PicturesType[] }[];
    preview?: boolean;
}

const DefaultImage = ({ productInfo, preview }: DefaultImageType) => {
    return (
        <Image
            src={`https://img.ep-cdn.com/i/500/500/${`${productInfo.find((d) => d.name === "default_image_url")?.value}${productInfo.find((d) => d.name === "image_slug")?.value}`}.webp`}
            alt="product"
            style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "0.5rem" }}
            preview={preview}
        />
    );
};

export default DefaultImage;
