import React from 'react';
import { Editor } from "@tinymce/tinymce-react";

interface CreateInfoTextProps { 
    editorText:string
    setEditorText:React.Dispatch<React.SetStateAction<string>>
    descriptionInit:string
    path:string
}

const CreateInfoText:React.FC<CreateInfoTextProps> = ({ editorText, setEditorText, descriptionInit, path }) => {

    return (
        <Editor
            initialValue={descriptionInit}
            init={{
            plugins: 'emoticons hr image link lists charmap table code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright |emoticons | image | link | lists | charmap | table forecolor backcolor numlist bullist',
            height : "480",
            entity_encoding : "raw",
            force_br_newlines : false,  // ????????????change
            force_p_newlines : false,    //?????? change
            forced_root_block : '',
            menubar: 'edit insert view format table tools lists'
        }}
            
            onChange={(e:any)=>console.log('')}
            apiKey='ttoom8yj7vhzborinbvzx8hsyovkzfee1cdfpjwpmeke3z3n'
            onEditorChange={(newValue, editor) =>  setEditorText(newValue)}
            
        />
        
    )
};

export default CreateInfoText;