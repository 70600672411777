import React, { forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/DatePickerReports.css'
import { DatePickerNewProducts } from './NewShopProducts';

interface DateProps {
    date:DatePickerNewProducts
    setDate:React.Dispatch<React.SetStateAction<DatePickerNewProducts>>
    setRadioDate?: React.Dispatch<React.SetStateAction<string>>
    position?:string 
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
    setIsDate?:React.Dispatch<React.SetStateAction<boolean>>
    isDate?:boolean
}

const DatePickerFromToReports:React.FC<DateProps> = ({ date, setDate, setRadioDate, position, setCurrentPage, setIsDate, isDate }) => {

  const onDateChange = (dates:[Date , Date]) => {
    setRadioDate && setRadioDate("dateRange")
    const [start, end] = dates;
    setDate({
      startDate:start,
      endDate:end
    })
    start && end && setCurrentPage &&  setCurrentPage(1)
    setIsDate && setIsDate(true)
    
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }:any, ref:any) => (
    <button 
      className={`date-picker-new-shop-products ${ !isDate ? `data-picker-no-selected-date`:'' }`} 
      onClick={onClick} 
      ref={ref}
    >
      {value}
    </button>
  ))

  return (
    <ReactDatePicker
      selected={date.startDate}
      onChange={onDateChange}
      startDate={date.startDate}
      endDate={date.endDate}
      selectsRange
      customInput={<ExampleCustomInput />}
      calendarClassName="date-picker-calendar-body"
      showDisabledMonthNavigation
      popperPlacement={ position ? "bottom" : "left-start" }
      dateFormat=" d MMM yyyy"
      
    />
  );
}

export default DatePickerFromToReports