import { Modal, Form, Input, Button, Divider } from "antd";
import axios from "axios";
import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { ValuesType } from "../../types/AttributeType";

type ValueType = {
    name: string;
    id: number;
    data: ValuesType[];
    type: string;
} | null;

interface NewValueModalProps {
    isModalNewValue: boolean;
    setIsModalNewValue: React.Dispatch<React.SetStateAction<boolean>>;
    values: ValueType;
    setValues: React.Dispatch<React.SetStateAction<ValueType | null>>;
    countries: { name: string; value: number }[];
}

const CreteNewValue: React.FC<NewValueModalProps> = ({ isModalNewValue, setIsModalNewValue, values, setValues, countries }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const handleOkNewValue = () => {
        setIsModalNewValue(false);
    };

    const handleCancelNewValue = () => {
        setIsModalNewValue(false);
    };
    const saveNewValue = async (e: any) => {
        const objToSend = {
            ...e,
            attribute_id: values?.id,
        };
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-value`, objToSend);
            if (data.data.already_exists) {
                openNotification("Attribute already exists");
                setLoading(false);
            } else {
                const obj: Record<string, string> = {};
                countries.forEach((c) => {
                    obj[`value_${c.name}`] = e[`value_${c.name}`];
                });
                setValues((curr: any) =>
                    curr
                        ? {
                              ...curr,
                              data: [
                                  ...curr.data,
                                  {
                                      id: data.data.values,
                                      attribute_id: values?.id ? values?.id : 0,
                                      value_id: data.data.values,
                                      products_assigned: 0,
                                      ...obj,
                                      value_en: e.value_rs,
                                      isMergeValues: false,
                                  },
                              ],
                          }
                        : null
                );
                showMessage(data.message);
                setIsModalNewValue(false);
                form.resetFields();
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Modal title="Create new Value" footer={false} open={isModalNewValue} onOk={handleOkNewValue} onCancel={handleCancelNewValue}>
            <Divider />
            <Form form={form} onFinish={saveNewValue}>
                {countries.map((c) => (
                    <Form.Item name={`value_${c.name}`} label={`Name ${c.name.toUpperCase()}`} rules={[{ required: true }]} key={c.name}>
                        <Input style={{ width: "99%", marginLeft: "3px" }} placeholder={`Name ${c.name.toUpperCase()}`} />
                    </Form.Item>
                ))}
                <Form.Item name="value_en" label="Name EN" rules={[{ required: true }]}>
                    <Input placeholder="Name EN" />
                </Form.Item>
                <Divider />
                <Form.Item style={{ marginTop: "1rem" }}>
                    <Button htmlType="submit" type="primary" block loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreteNewValue;
