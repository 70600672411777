import {  Input, Form, Table } from 'antd'
import React, { useEffect, useState, useContext, useRef } from 'react'
import type { InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';
const { TextArea } = Input
const EditableContext = React.createContext<FormInstance<any> | null>(null);

// if( record.mpn?.trim() === values.mpn?.trim() ) {   // is new mpn is the same as previous don't call endpoint
//   return
// }

interface Item {
  type:string
  value: string
  name_short: string
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  isInput?:boolean,
  record: Item;
  handleSave: (record: Item) => void;
}

type EditableTableProps = Parameters<typeof Table>[0]
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  isInput,    //added boolean for checking is input Text Area (in MetaDescirption is Text Area)
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      if (dataIndex === 'name_short' && record.name_short?.trim() === values.name_short?.trim()) {  // only for short name
        toggleEdit();
        return
      }

      if ( record.value?.trim() ===  values.value?.trim() ) {  // is input value is the same as previous don't call endpoint
        toggleEdit();
        return
      }

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >

        {isInput ?
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />:
          <TextArea ref={inputRef} rows={3} onPressEnter={save} onBlur={save} />
        }
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '30px', width: '100%' }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const mappedColumns = (defaultColumns:(ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] , handleSave:(row: any ) => Promise<void>, isInput:boolean, isTitle?:boolean ) => {
  return defaultColumns.map((col:any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EditableTableProps) =>({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title.props?.children? col.title.props?.children : col.title,
        isInput: isInput,
        handleSave,
      })
    }
  })
}


export const editableCellcomponents  = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }