import { Button, Form } from "antd";

/*interface buttonProps {
    type:string
    htmlType:string
    title:string
    wrapperCol:any
}*/

const ButtonE:React.FC<any> = ({ type, htmlType, title, wrapperCol, href, handleClick, block, style }) => {
    return (
        <Form.Item wrapperCol={ wrapperCol }>
            <Button 
                style={style}
                block={block}
                type={type}
                htmlType={htmlType}       
                href={href}          
                onClick={handleClick}  
            >
                { title }
            </Button>
        </Form.Item>
    )
}

export default ButtonE
