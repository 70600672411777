import { DeleteFilled } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Image,
    Input,
    Popover,
    Row,
    Space,
    Typography,
} from "antd";
import React, { useState } from "react";
import {
    addImageToProduct,
    deleteImage,
    setNewPrimaryImage,
} from "../../api/apiImage";
import { ImageType, ProductType } from "../../types/types";
import showMessage from "../../../../shared/MessagesInfo/message";
import axios from "axios";

interface PopupImageContentProps {
    record: ProductType;
    images: ImageType[];
    setImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
}

let imagePriceId: string | undefined;

const PopupImageContent: React.FC<PopupImageContentProps> = (props) => {
    const [newImage, setNewImage] = useState<string | undefined>();
    const [priceImages, setPriceImages] = useState([]);
    const [priceImagesLoading, setPriceImagesLoading] = useState(false);
    const [loadNewImage, setLoadNewImage] = useState(false);
    const [loadDeleteImage, setLoadDeleteImage] = useState<
        string | undefined
    >();

    const addNewImage = async (img?: string) => {
        setLoadNewImage(true);
        addImageToProduct(props.record.product_id, img ? img : newImage)
            .then((resp) => {
                props.setImages((curr) => [
                    ...curr,
                    { image_name: resp.data.name, primaryimage: false },
                ]);
                setNewImage(undefined);
                setLoadNewImage(false);
                imagePriceId = undefined;
                showMessage(resp.message);
            })
            .catch((err) => {
                console.log(err);
                setLoadNewImage(false);
            });
    };

    const deleteImg = async (product_id: number, name: string) => {
        setLoadDeleteImage(name);
        deleteImage(product_id, name)
            .then(() => {
                props.setImages((curr) =>
                    curr.filter((i) => i.image_name !== name)
                );
                setLoadDeleteImage(undefined);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const newPrimaryImage = async (product_id: number, image_name: string) => {
        setNewPrimaryImage(product_id, image_name)
            .then((resp) => {
                const image = `https://img.ep-cdn.com/images/500/500/${image_name}.webp`;
                props.setImages((curr) =>
                    curr.map((img) => ({
                        ...img,
                        primaryimage:
                            img.image_name === image_name ? true : false,
                    }))
                );
                props.setProducts((curr) =>
                    curr.map((p) =>
                        p.id === props.record.id ? { ...p, image: image } : p
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showImageOfPrices = async () => {
        setPriceImages([]);
        setPriceImagesLoading(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/list-prices-images?product_id=${props.record.product_id}`
            );
            setPriceImages(data.data);
            setPriceImagesLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Row style={{ backgroundColor: "white", width: "800px" }}>
            <Typography.Title level={4}>Add Image</Typography.Title>
            <Col span={24}>
                <Space.Compact style={{ width: "100%" }}>
                    <Input
                        onChange={(e) => setNewImage(e.target.value)}
                        value={newImage}
                        style={{ width: "50%" }}
                        placeholder="Add New Images"
                    />
                    <Button
                        type="primary"
                        onClick={() => addNewImage()}
                        loading={newImage && loadNewImage ? true : false}
                        disabled={!newImage}
                    >
                        Save
                    </Button>
                </Space.Compact>
            </Col>
            <Divider style={{ margin: "0.7rem 0" }} />
            <Space
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                }}
            >
                {props.images.map((img) => (
                    <div
                        key={img.image_name}
                        style={{ position: "relative", marginLeft: "1rem" }}
                    >
                        <Image
                            width={100}
                            style={{
                                border: img.primaryimage
                                    ? "2px solid #005792"
                                    : loadDeleteImage === img.image_name
                                    ? "2px solid #ff6f3c"
                                    : "2px solid whitesmoke",
                                borderRadius: "8px",
                                cursor: "pointer",
                                padding: "0.2rem",
                            }}
                            preview={false}
                            src={`https://img.ep-cdn.com/images/500/500/${img.image_name}.webp`}
                            alt="product"
                            onClick={() =>
                                newPrimaryImage(
                                    props.record.product_id,
                                    img.image_name
                                )
                            }
                        />
                        {!img.primaryimage && (
                            <DeleteFilled
                                onClick={() =>
                                    deleteImg(
                                        props.record.product_id,
                                        img.image_name
                                    )
                                }
                                style={{
                                    color: "darkgray",
                                    fontSize: "18px",
                                    zIndex: 200,
                                    position: "absolute",
                                    right: "0.3rem",
                                    top: "0.4rem",
                                }}
                                className="product-remove-img"
                            />
                        )}
                    </div>
                ))}
            </Space>
            <Divider />

            {/* <Col span={24}>
                <Button onClick={showImageOfPrices} loading={priceImagesLoading}>
                    Show Price Images{" "}
                </Button>
            </Col> */}
            <br />
            <Row
                wrap
                /*style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}*/ style={{
                    minHeight: "6rem",
                }}
                align="middle"
                gutter={[8, 16]}
            >
                {priceImages?.map((i) => (
                    <Col key={i}>
                        <Popover
                            placement="left"
                            content={
                                <div style={{ width: "100%" }}>
                                    <Image
                                        src={i}
                                        //width={60}
                                        preview={false}
                                        style={{
                                            border: `1px solid ${
                                                imagePriceId === i &&
                                                loadNewImage
                                                    ? "blue"
                                                    : "lightgray"
                                            }`,
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            }
                        >
                            <Image
                                src={i}
                                width={60}
                                style={{
                                    border: `1px solid ${
                                        imagePriceId === i && loadNewImage
                                            ? "blue"
                                            : "lightgray"
                                    }`,
                                    cursor: "pointer",
                                }}
                                preview={false}
                                onClick={() => {
                                    imagePriceId = i;
                                    addNewImage(i);
                                }}
                            />
                        </Popover>
                    </Col>
                ))}
            </Row>
        </Row>
    );
};

export default PopupImageContent;
