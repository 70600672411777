import { Button, Col, Form, Input, Row, Space } from 'antd'

interface SkuContentType  {
    setSkus: React.Dispatch<React.SetStateAction<string | null>>
}

const SkuContent:React.FC<SkuContentType> = ({ setSkus }) => {
    const [ form ] = Form.useForm()
    const searchSku = ( e:{skus:string} ) => {
        const removeSpace = e.skus.replace(/^\s*[\r\n]/gm, '')
        setSkus(removeSpace)
    }

    const clearSku = () => {
        setSkus(null)
        form.resetFields()
    }

    const checkSkus = Form.useWatch( 'skus', form )
   
    return (
        <Row>
            <Col>
                <Form  
                    style={{ width:'300px'}} 
                    onFinish={ searchSku }
                    form={ form }
                    
                >
                    <Form.Item
                        name="skus"
                        //rules={[{ required: true, message: 'Please enter sku!' }]}
                    >
                        <Input.TextArea rows={18} placeholder='Enter sku' />
                    </Form.Item>
                    <Form.Item>
                       
                        <Space size={160}>
                            <Button 
                                htmlType='submit' 
                                type='primary' 
                                disabled={ checkSkus?.trim() ? false : true }
                            >
                                Search
                            </Button>
                            <Button 
                                htmlType='submit' 
                                type='primary' 
                                danger 
                                onClick={ clearSku }
                            >
                                Clear
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default SkuContent