import { ArrowDownOutlined, ArrowUpOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Popconfirm, Row, Select, Switch, TreeSelect } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getBrands, getCategories, getShopCategories, getShops } from "../api/apiFacets";
import { ProductType, TreeCategoryType } from "../types/types";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../shared/utils/numberUtils";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { BrandType, FilterBoxProps, ShopCategoryType, ShopType } from "../types/filters";
import { getDisabledCategories } from "../api/apiData";

const FiltersBox: React.FC<FilterBoxProps> = (props) => {
    const [shops, setShops] = useState<ShopType[]>([]);
    const [shopCategories, setShopCategories] = useState<ShopCategoryType[]>([]);
    const [brands, setBrands] = useState<BrandType[]>([]);
    const [disabledCategories, setDisabledCategories] = useState<{ id: number; name: string; type: number }[]>([]);
    const [categories, setCategories] = useState<TreeCategoryType[]>([]);
    const [callFacets, setCallFacets] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getShops(
            setShops,
            props.currentCountryId,
            props.currentCountryName,
            props.selectedShops,
            props.selectedShopCategories,
            props.search,
            props.selectedCategories,
            props.selectedBrands,
            props.selectedNew,
            props.xml,
            props.user,
            props.strictCategory,
            props.loadCategory
        );
    }, [
        props.currentCountryId,
        props.currentCountryName,
        props.selectedShops,
        props.selectedShopCategories,
        props.search,
        props.selectedCategories,
        props.selectedBrands,
        props.selectedNew,
        props.xml,
        props.user,
        props.strictCategory,
        props.loadCategory,
        callFacets,
    ]);

    useEffect(() => {
        getShopCategories(
            setShopCategories,
            props.currentCountryId,
            props.currentCountryName,
            props.search,
            props.selectedShops,
            props.selectedShopCategories,
            props.selectedBrands,
            props.selectedCategories,
            props.selectedNew,
            props.xml,
            props.user,
            props.strictCategory
        );
    }, [
        props.currentCountryId,
        props.currentCountryName,
        props.search,
        props.selectedShops,
        props.selectedShopCategories,
        props.selectedBrands,
        props.selectedCategories,
        props.selectedNew,
        props.xml,
        props.user,
        props.strictCategory,
        callFacets,
    ]);

    useEffect(() => {
        getDisabledCategories(props.currentCountryName)
            .then((resp) => {
                setDisabledCategories(resp);
            })
            .catch((err) => console.log(err));
    }, [props.currentCountryName]);

    useEffect(() => {
        getCategories(
            setCategories,
            props.currentCountryId,
            props.currentCountryName,
            props.selectedShopCategories,
            props.search,
            props.selectedShops,
            props.selectedBrands,
            props.selectedNew,
            props.xml,
            props.user,
            props.strictCategory,
            props.loadCategory,
            props.setloadChangeCountry,
            props.selectedCategories
        );
    }, [
        props.currentCountryId,
        props.currentCountryName,
        props.selectedShopCategories,
        props.selectedCategories,
        props.search,
        props.selectedShops,
        props.selectedBrands,
        props.selectedNew,
        props.xml,
        props.user,
        props.strictCategory,
        props.loadCategory,
        props.setloadChangeCountry,
        // props.selectedCategories,
        callFacets,
    ]);

    useEffect(() => {
        getBrands(
            setBrands,
            props.currentCountryId,
            props.currentCountryName,
            props.loadCategory,
            props.selectedShopCategories,
            props.selectedBrands,
            props.selectedNew,
            props.search,
            props.selectedShops,
            props.selectedCategories,
            props.xml,
            props.user,
            props.strictCategory
        );
    }, [
        props.selectedShops,
        props.selectedShopCategories,
        props.currentCountryId,
        props.currentCountryName,
        props.loadCategory,
        props.selectedBrands,
        props.search,
        props.selectedCategories,
        props.selectedNew,
        props.xml,
        props.user,
        props.strictCategory,
        callFacets,
    ]);

    let delayTimer: ReturnType<typeof setTimeout>;
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            props.setSearch(e.target.value);
            props.setPage(1);
        }, 1000);
    };

    const onShopChange = (e: string[]) => {
        props.setSelectedShops(e);
        props.setPage(1);
        if (e.length === 0) {
            props.setSelectedShopCategories([]);
        }
    };

    const onShopCategoryChange = (e: string[]) => {
        props.setSelectedShopCategories(e);
        props.setPage(1);
    };

    const onCategoryChange = (e: string) => {
        if (e) {
            const splitString = e.split("--");
            const id = splitString[0];
            const level = splitString[1];
            props.setSelectedCategories({
                id: id,
                level: level,
                fullCategory: e,
            });
            props.setPage(1);
        } else {
            props.setSelectedCategories(undefined);
            props.setPage(1);
        }
    };

    const onBrandChange = (e: string[]) => {
        props.setSelectedBrands(e);
        props.setPage(1);
    };

    const onChangeUser = (e: CheckboxChangeEvent) => {
        props.setUser(e.target.checked);
        props.setPage(1);
    };

    const onChangeStrictCategory = (e: CheckboxChangeEvent) => {
        props.setStrictCategory(e.target.checked);
        props.setPage(1);
    };

    const onSortChange = (e: string) => {
        props.setSelectedSort(e);
        props.setPage(1);
    };

    const onNewChange = (e: string) => {
        props.setSelectedNew(e);
        props.setPage(1);
    };

    const onXmlChange = (e: boolean) => {
        props.setXml(e);
        props.setPage(1);
    };

    const save = async () => {
        const baseUrl = process.env.REACT_APP_URL_API;
        const products = props.products
            .filter((p) => p.isChanged)
            .map((p) =>
                p.new_prodcut_id
                    ? {
                          old_product_id: p.product_id,
                          product_id: p.new_prodcut_id,
                          merge_product: 1,
                      }
                    : {
                          product_id: p.product_id,
                          brand_id: p.brand_id,
                          category_l3_id: p[`category_4_${props.currentCountryName}` as keyof ProductType]
                              ? Number(p[`category_4_${props.currentCountryName}` as keyof ProductType].toString().split("::")[0])
                              : p.category_id,
                          [`name_${props.currentCountryName}`]: p.name,
                          mpn: p.mpn,
                      }
            );
        if (products.length > 0) {
            setLoading(true);
            try {
                const { data } = await axios.put(`${baseUrl}/product/item/update-all-products`, products);
                props.setProducts((curr) => curr.filter((p) => !p.isChanged));
                showMessage(data.message);
                setLoading(false);
                setCallFacets((curr) => curr + 1);
                return data;
            } catch (err) {
                console.log(err);
            }
        } else {
            openNotification("At least one product must be changed");
        }
    };

    return (
        <Row
            style={{
                border: "1px solid lightgray",
                backgroundColor: "whitesmoke",
            }}
        >
            <Col span={24} style={{ position: "relative" }}>
                <TreeSelect
                    showSearch
                    style={{ width: "300px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Select Category"
                    allowClear
                    onChange={onCategoryChange}
                    //treeData={categories}
                    treeDefaultExpandAll={false}
                    // multiple
                    // tokenSeparators={[","]}
                    //defaultValue={"30::Nesortirano--1"}

                    value={categories.length > 0 && !props.loadChangeCountry ? props.selectedCategories?.fullCategory : undefined}
                    filterTreeNode={(search: string, item: any) => item.value.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                    treeData={categories
                        .filter((c) => c.value)
                        .map((level1) => ({
                            title: (
                                <span className={disabledCategories.find((c) => level1.id === c.id.toString() && level1.level === c.type) ? "select-category-disabled" : undefined}>
                                    {level1.title?.toString()}
                                </span>
                            ),
                            value: level1.value,
                            children: level1.children?.map((level2) => ({
                                title: (
                                    <span className={disabledCategories.find((c) => level2.id === c.id.toString() && level2.level === c.type) ? "select-category-disabled" : undefined}>
                                        {level2.title?.toString()}
                                    </span>
                                ),
                                value: level2.value,
                                children: level2.children?.map((level3) => ({
                                    title: (
                                        <span className={disabledCategories.find((c) => level3.id === c.id.toString() && level3.level === c.type) ? "select-category-disabled" : undefined}>
                                            {level3.title?.toString()}
                                        </span>
                                    ),
                                    value: level3.value,
                                    children: level3.children?.map((level4) => ({
                                        title: (
                                            <span className={disabledCategories.find((c) => level4.id === c.id.toString() && level4.level === c.type) ? "select-category-disabled" : undefined}>
                                                {level4.title?.toString()}
                                            </span>
                                        ),
                                        value: level4.value,
                                    })),
                                })),
                            })),
                        }))}
                />
                <Select
                    showSearch
                    allowClear
                    placeholder="Filter Shop"
                    mode="multiple"
                    tokenSeparators={[","]}
                    value={props.selectedShops}
                    onChange={onShopChange}
                    style={{ width: "300px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    optionLabelProp="label"
                    options={
                        shops.length > 0
                            ? [
                                  {
                                      label: <span>Active Shops</span>,
                                      options: shops
                                          .filter((s) => {
                                              const active = s?.id.toString().split("::")[3];
                                              return s && active === "1";
                                          })
                                          .map((s) => ({
                                              value: s.id.toString(),
                                              label: (
                                                  <span
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >{`${s.shop_name} (${numberWithCommas(String(s.count).replace(".", ","))})`}</span>
                                              ),
                                          })),
                                  },
                                  {
                                      label: <span>Inactive Shops</span>,
                                      options: shops
                                          .filter((s) => {
                                              const active = s?.id.toString().split("::")[3];
                                              return s && active === "0";
                                          })
                                          .map((s) => ({
                                              value: s.id.toString(),
                                              label: <span>{`${s.shop_name} (${numberWithCommas(String(s.count).replace(".", ","))})`}</span>,
                                          })),
                                  },
                              ]
                            : []
                    }
                />
                <Select
                    showSearch
                    allowClear
                    placeholder="Filter Shop Category"
                    mode="multiple"
                    tokenSeparators={[","]}
                    value={props.selectedShopCategories}
                    onChange={onShopCategoryChange}
                    style={{ width: "500px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    optionLabelProp="label"
                    filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                    options={shopCategories.map((s) => ({
                        value: s.id.toString(),
                        label: `${s.shop_category_name} (${numberWithCommas(String(s.count).replace(".", ","))})`,
                    }))}
                />
                <Select
                    showSearch
                    allowClear
                    placeholder="Filter Brand"
                    mode="multiple"
                    tokenSeparators={[","]}
                    value={props.selectedBrands}
                    onChange={onBrandChange}
                    style={{ width: "300px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    optionLabelProp="label"
                    filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                    options={brands.map((b) => ({
                        value: b.id.toString(),
                        label: `${b.brand_name} (${numberWithCommas(String(b.count).replace(".", ","))})`,
                    }))}
                />
                <Switch checkedChildren="xml" unCheckedChildren="All" checked={props.xml} size="small" onChange={onXmlChange} style={{ margin: "0.5rem 0 0.5rem 0.5rem" }} />
                <Checkbox children="User" onChange={onChangeUser} checked={props.user} style={{ margin: "0.5rem 0 0.5rem 0.5rem" }} />
                <Checkbox children="Strict Category Search" onChange={onChangeStrictCategory} checked={props.strictCategory} style={{ margin: "0.5rem 0 0.5rem 0.5rem" }} />
            </Col>
            <Col span={24} style={{ position: "relative" }}>
                <Input allowClear style={{ width: "300px", margin: "0.5rem 0 0.5rem 0.5rem" }} placeholder="Product name, ID, sku" onChange={handleSearch} />

                <Select
                    placeholder="Sort"
                    style={{ width: "120px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    optionLabelProp="label"
                    allowClear
                    onChange={onSortChange}
                    //defaultValue="active_prices:desc"
                    value={props.selectedSort}
                    options={[
                        {
                            value: "active_prices:desc",
                            label: <span># of prices</span>,
                        },
                        {
                            value: "clicks:desc",
                            label: <span># of clicks</span>,
                        },
                        {
                            value: `name_${props.currentCountryName}:asc`,
                            label: (
                                <div>
                                    Name <ArrowUpOutlined style={{ marginLeft: "1.4rem" }} />
                                </div>
                            ),
                        },
                        {
                            value: `name_${props.currentCountryName}:desc`,
                            label: (
                                <span>
                                    Name <ArrowDownOutlined style={{ marginLeft: "1.4rem" }} />
                                </span>
                            ),
                        },
                    ]}
                />

                <Select
                    placeholder="All"
                    style={{ width: "80px", margin: "0.5rem 0 0.5rem 0.5rem" }}
                    optionLabelProp="label"
                    allowClear
                    onChange={onNewChange}
                    value={props.selectedNew}
                    options={[
                        {
                            value: "1",
                            label: "New",
                        },
                        {
                            value: "2",
                            label: "Outlet",
                        },
                    ]}
                />

                <Button onClick={() => props.setIsModalApplyToAll(true)} style={{ margin: "0.5rem 0 0.5rem 0.5rem" }}>
                    Apply to All
                </Button>
                <Popconfirm placement="top" title={"Are you sure?"} onConfirm={save} okText="Yes" cancelText="No" open={loading ? true : undefined}>
                    <Button
                        className="all-products-save-button"
                        icon={<SaveOutlined />}
                        // loading={loading}
                        style={{
                            margin: "0.5rem 0 0.5rem 1rem",
                            marginLeft: "20%",
                        }}
                    >
                        Save
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    );
};

export default FiltersBox;
