import { Popover } from "antd";
import { ValueType } from "../../types/AttributesAnalytics.type";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

export const checkStatus = (record: ValueType) => {
    if (record.is_mapped_key) {
        if (record.attribute_type === "range" && (record.is_numeric === 0 || record.is_numeric === null || record.is_numeric === false)) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Popover
                        placement="rightTop"
                        /*title="Allowed Values"*/ content={<div>The value needs to be numeric to be assigned as a range value. Please clean the value on the attribute mapping page.</div>}
                    >
                        <CloseCircleFilled style={{ color: "#da5151", fontSize: "18px" }} />
                    </Popover>
                    <span style={{ marginLeft: "0.5rem" }}>The value isn't numeric!</span>
                </div>
            );
        }

        if (
            (record.attribute_type === "select" || record.attribute_type === "multiselect" || record.attribute_type === "yesno") &&
            (record.is_allowed_value === false || record.is_allowed_value === null)
        ) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Popover placement="rightTop" /*title="Allowed Values"*/ content={<div>You must allow it on the mapping page or clean the value so it matches one of ours.</div>}>
                        <CloseCircleFilled style={{ color: "#da5151", fontSize: "18px" }} />
                    </Popover>
                    <span style={{ marginLeft: "0.5rem" }}>The value isn't allowed!</span>
                </div>
            );
        }

        if (record.attribute_type === "range" && record.in_range === 0) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Popover
                        placement="rightTop"
                        /*title="Allowed Values"*/ content={<div> The value is not in range. The range must be widen or the value must be altered on the mapping page so it can pass validation.</div>}
                    >
                        <CloseCircleFilled style={{ color: "#da5151", fontSize: "18px" }} />
                    </Popover>
                    <span style={{ marginLeft: "0.5rem" }}>The value is out of range! </span>
                </div>
            );
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <Popover placement="rightTop" /*title="Allowed Values"*/ content={<div> Value is ok. Attribute is or will be created.</div>}>
                    <CheckCircleFilled style={{ color: "#42b883", fontSize: "18px" }} />
                </Popover>
                <span style={{ marginLeft: "0.5rem" }}>OK</span>
            </div>
        );
    } else {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <Popover placement="rightTop" /*title="Allowed Values"*/ content={<div>The shop key isn't mapped. Please map the key on the attribute mapping page.</div>}>
                    <CloseCircleFilled style={{ color: "#da5151", fontSize: "18px" }} />
                </Popover>
                <span style={{ marginLeft: "0.5rem" }}>The key isn't mapped!</span>
            </div>
        );
    }
};
