import { Progress,Badge } from 'antd';
import React from 'react';

const ProgressLine:React.FC<any> = ({ multySelected, selectTemp }) => {
  return (
    <div style={{ position:'relative', width:'100%' }}>
    <Progress
      strokeColor={{
        from: '#108ee9',
        to: '#87d068',
      }}
      percent={Math.round(selectTemp/multySelected.length * 100)}
    />

    <div style={{ position:'absolute',top:'-2px',right:'0px' }}>

        <Badge 
          count={selectTemp !== 0 && multySelected.length !== 0 ? `${selectTemp}/${multySelected.length}`:''} 
          style={{  height:'30px',fontSize:'25px',paddingTop:'3px', backgroundColor: selectTemp < multySelected.length ? 'skyblue' : '#52c41a' }}
        />
      </div>
    </div>
    )
}
export default ProgressLine