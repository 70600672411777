import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreType } from '../../pages/ShopList'
import { Form, Input, Select, Spin, Tooltip } from 'antd'
import type { FormInstance } from 'antd/es/form'
import axios from 'axios'
import showMessage from '../../../../shared/MessagesInfo/message'
import { Loading3QuartersOutlined, PlusCircleOutlined } from '@ant-design/icons'
import openNotification from '../../../../shared/MessagesInfo/WarningBox'
//import openNotification from '../../../../shared/MessagesInfo/WarningBox'

const dateExample = 
    <> 
      <span>dateExample:</span>
      <p>pon-sub: 9-21h, ned: 9-21h</p>
    </>

interface EditableCellProps {
    title: React.ReactNode
    editable: boolean
    children: React.ReactNode
    dataIndex: keyof StoreType
    record: StoreType
    handleSave: (record: StoreType) => void
    cities:{id: number, name: string}[]
    setStores: React.Dispatch<React.SetStateAction<StoreType[]>>
}

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
    index: number
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
}

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    cities,
    setStores,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const inputRef = useRef<any>(null)
    const form = useContext(EditableContext)!
  
    useEffect(() => {
      if (editing) {
        inputRef.current!.focus()
      }
    }, [editing])
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }
  
    const save = async () => {
        setLoading(true)
        try {
            const values = await form.validateFields()
            let obj:{[key: string]: string | number} = {}
            for ( let key in values) {
              if (key === 'city') {
                  obj['city_id'] = values[key]
              }
              else {
                  obj[key] = values[key]
              }
            }
            
            if( record[dataIndex] === values[dataIndex] ) {   // is new dataIndex is the same as previous don't call endpoint
                toggleEdit();
                handleSave({ ...record, ...values })
                setLoading(false)
                return
            }

            const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/store/update`, {id:record.id, ...obj})
            showMessage(data.message)
            toggleEdit();
            //console.log( cities.find( c => c.id === values.city)?.name )

            handleSave({ ...record, ...values, ...(dataIndex === 'city' && {city:cities.find( c => c.id === values.city)?.name }) })
            setLoading(false)
        } catch (errInfo) {
            console.log('Save failed:', errInfo)
            toggleEdit();
            openNotification('Error update')
            setLoading(false)
        }
    }
    
    let childNode = children
    
    if (editable && dataIndex === 'city') {
        childNode =
        <div>
          {editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                //initialValue={93}
            >
                 <Select 
                    ref={inputRef} 
                    onChange={save} 
                    onBlur={save} 
                    autoFocus 
                    defaultOpen 
                    showSearch 
                    filterOption={ (input: string, option?: { label: string; value: number }) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) }
                    options={cities.map( c => ({ label:c.name, value: c.id }))}
                />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        )}
        </div>
    }

    //////////////// add example date in table 

    const addExampleDate = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: 'pon-sub:9-21h, ned:9-21h' })
    }

    ///////////////
  
    if (editable && (dataIndex === 'address' || dataIndex === 'phone' || dataIndex === 'email' || dataIndex === 'website' || dataIndex === 'work_hour' || dataIndex === 'coordinates')) {
        childNode =
        <div>
          {editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            >
                <Input 
                    ref={inputRef} 
                    onPressEnter={save} 
                    onBlur={save} 
                    suffix={ 
                        loading ? 
                        <Spin 
                            size='small' 
                            indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin /> } 
                        />:
                        <span />
                    } 
                />
            </Form.Item>
         ) : (
          <div style={{ display:'flex' }}>
            <div className="editable-cell-value-wrap store-editable-cell" style={{ paddingRight: 24, width:'100%' }} onClick={toggleEdit}>
              { record && record[dataIndex] ? children :<div style={{ width:'100%', height:'20px' }}></div>}
            </div>
           {dataIndex === 'work_hour' && 
            <Tooltip placement="topRight" title={dateExample}>
              <PlusCircleOutlined onClick={ addExampleDate } className='add-work-hour-to-store' />
            </Tooltip>}
          </div>
        )}
        </div>
    }
      return <td {...restProps}>{ childNode }</td>
    }


export default EditableCell