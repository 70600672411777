import { Button, Card, Image, Modal } from 'antd'
import React from 'react'
import { AttrProductsType } from './ShopValuesTable'
import '../../style/ShowProductsModal.css'

interface ShowProductsModalProps {
  showProductsModal: boolean
  setShowProductsModal: React.Dispatch<React.SetStateAction<boolean>> 
  attrProducts: AttrProductsType[]
  setAttrProducts: React.Dispatch<React.SetStateAction<AttrProductsType[]>>
}

const ShowProductsModal:React.FC<ShowProductsModalProps> = ({ showProductsModal, setShowProductsModal, attrProducts, setAttrProducts }) => {
  return (
    <Modal
      title="Products"
        centered
        open={showProductsModal}
        onOk={() =>{ setShowProductsModal(false); setAttrProducts([]) }}
        onCancel={() =>{ setShowProductsModal(false); setAttrProducts([]) }}
        width="60%"
        cancelButtonProps={undefined}
        footer={[
          <Button key="back" type='primary' style={{ margin:'0.5rem' }} onClick={() => setShowProductsModal(false)} >
            Ok
          </Button>
        ]}
        className='attribute-mapping-table-scroll'
    >
      <div className='attr-mapping-show-products-modal-box'>
      { attrProducts.map( item => 
        <Card
          className='attr-mapping-show-products-modal-card'
        >
          <div style={{ display:'flex', flexDirection:'row'  }}>
            <div style={{ marginTop:'1rem' }} >
              <Image
                style={{ width:'200px' }}
                alt="example"
                src={`https://img.ep-cdn.com/images/500/500/${ item.default_image_url }.webp`}
                preview={ false }
              />
            </div>
            <div style={{ padding:'0 1rem' }}>
              <p style={{ fontWeight:'bold', fontSize:'18px' }}>{ item.name_rs }</p>
             <div
                dangerouslySetInnerHTML={{__html: item.preview_text_rs}}
              />
            </div>
          </div>
        </Card>)}
      </div>
    </Modal>
  )
}

export default ShowProductsModal