import axios from "axios";
import { Dispatch } from "react";
import showMessage from "../../shared/MessagesInfo/message";
import { BrandDelete, BrandList, BRAND_DELETE, BRAND_LIST } from "./BrandActionType";

//-------- Brand list --------
export const getAllBrands = () => async ( dispatch:Dispatch<BrandList> ) => {
    try {
        const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/product/brand/list`)
        const newData = data.data.map((singleData:any)=>{ return {...singleData, checkedForMap:false}})
        dispatch({ type:BRAND_LIST, payload:{...data, data:newData} })
    } catch ( err ) {
        console.log(err)
    }
}

//----------Delete brand-------

export const deleteBrand = ( id :number ) => async ( dispatch:Dispatch<BrandDelete> ) => {
    try {
        const { data } = await axios.delete(`${ process.env.REACT_APP_URL_API }/product/brand/delete?id=${ id }`)
        showMessage(data.message)
        dispatch({ type:BRAND_DELETE, payload:data})
    } catch ( err ) {
        console.log(err)
    }
}

//---------Create brand-------

// export const createBrand = ( brand:any, history:any ) => async ( dispatch:Dispatch<BrandCreate> ) => {
//     try {
//         const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/brand/create`,{name:brand})        
//         dispatch({ type:BRAND_CREATE, payload:data})
//         showMessage(data.message)
//         history.push('/brand-list')
//     } catch ( err ) {
//         console.log(err)
//     }
// }