import { ATTRIBUTE_CREATE, ATTRIBUTE_DELETE, ATTRIBUTE_ITEM, ATTRIBUTE_LIST, ATTRIBUTE_UPDATE } from "../actions/attribute/attributeActionType";

export const attributeReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case ATTRIBUTE_LIST:
            return action.payload
        case ATTRIBUTE_DELETE:
            const newState = state.data.data.filter((shop: any) => shop.id !== Number(action.payload))
            return { ...state, data: { ...state.data, data: newState } }
        case ATTRIBUTE_UPDATE:
            const upadateState = state.data.data.map((attribute: any) => attribute.id === Number(action.payload.id) ? action.payload : attribute)
            return { ...state, data: { ...state.data, data: upadateState } }
        default:
            break;
    }
    return state
}

export const attributeItemReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case ATTRIBUTE_ITEM:
            return action.payload
        case ATTRIBUTE_CREATE:
            return state
        default:
            break;
    }
    return state
}