import { CATEGORY_L3_LIST, CATEGORY_L3_L4 } from "../actions/category/categoryActionsType";

export const categoryL3Reducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case CATEGORY_L3_LIST:
            return action.payload
    }
    return state
}

export const categoryL3L4Reducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case CATEGORY_L3_L4:
            return action.payload
    }
    return state
}