import React from 'react'
import { AttributeParameterValueType } from '../../types/AttributeType'

interface AttributeDefItemProps {
    rule: AttributeParameterValueType
}

const AttributeDefItem:React.FC<AttributeDefItemProps> = ({ rule }) => 
    <span key={ rule.parameter_id }>
        { rule.parameter_name }{" "} (<b>{ rule.parameter_value }</b>)
    </span>
    
export default AttributeDefItem