//-----all atributes------
export const ATTRIBUTE_LIST = "ATTRIBUTE_LIST"
export interface AttributeList {
    type: typeof ATTRIBUTE_LIST
    payload: any
}

//-----create attribute------
export const ATTRIBUTE_CREATE = "ATTRIBUTE_CREATE"
export interface AttributeCreate {
    type: typeof ATTRIBUTE_CREATE
    payload: any
}

//------delete atribute----

export const ATTRIBUTE_DELETE = "ATTRIBUTE_DELETE"
export interface AttributeDelete {
    type: typeof ATTRIBUTE_DELETE
    payload: any
}

//------update atribute----

export const ATTRIBUTE_UPDATE = "ATTRIBUTE_UPDATE"
export interface AttributeUpdate {
    type: typeof ATTRIBUTE_UPDATE
    payload: any
}

//-----attribute item----

export const ATTRIBUTE_ITEM = "ATTRIBUTE_ITEM"
export interface AttributeItem {
    type: typeof ATTRIBUTE_ITEM
    payload: any
}