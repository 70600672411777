export const BRAND_LIST = "BRAND_LIST"
export interface BrandList {
    type: typeof BRAND_LIST
    payload:any
}

export const BRAND_DELETE = "BRAND_DELETE"
export interface BrandDelete {
    type: typeof BRAND_DELETE
    payload:any
}

export const BRAND_CREATE = "BRAND_CREATE"
export interface BrandCreate {
    type: typeof BRAND_CREATE
    payload:any
}