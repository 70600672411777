import { CloseOutlined, DeleteOutlined, EditOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { Button, Divider, Form, FormInstance, Input, Popconfirm, Popover, Row, Select, Space, Table, Tooltip } from "antd";
import { ValuesType } from "../../types/AttributeType";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import React, { useState } from "react";
import CreteNewValue from "./CreteNewValue";
import { RootStore } from "../../../../Store";

interface AttributeValuesProps {
    values: {
        name: string;
        id: number;
        data: ValuesType[];
        type: string;
    } | null;
    setValues: React.Dispatch<
        React.SetStateAction<{
            name: string;
            id: number;
            data: ValuesType[];
            type: string;
        } | null>
    >;
    setTranslationModal: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            type: "attribute" | "value" | "";
        }>
    >;

    formOne: FormInstance<any>;
    state: RootStore;
    currentCountryName: string;
}

const AttributeValues = (props: AttributeValuesProps) => {
    const [selectedNewValue, setSelectedNewValue] = useState<number | null>(null);
    const [loadingAttributeList, setLoadingAttributeList] = useState(false);
    const [isModalNewValue, setIsModalNewValue] = useState(false);
    const [popoverClean, setPopoverClean] = useState(false);
    const [loadingClean, setLoadingClean] = useState(false);
    const { values, setValues, setTranslationModal, formOne, state, currentCountryName } = props;

    const [form] = Form.useForm();

    const deleteValue = async (id: number) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-attribute-value?attribute_allowed_value_id=${id}`);
            setValues((curr) => (curr ? { ...curr, data: curr.data.filter((d) => d.value_id !== id) } : null));
            showMessage(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    const onChangeAction = (type: "edit" | "merge" | "delete", record: ValuesType) => {
        //console.log(type);
        switch (type) {
            case "edit":
                setTranslationModal({ open: true, type: "value" });
                formOne.setFieldsValue({ ...record, id: record.value_id });
                break;
            case "merge":
                break;
            case "delete":
                deleteValue(record.value_id);
                break;
        }
    };

    //console.log(values?.data);

    const openPopover = (id: number) => {
        const newData =
            values?.data &&
            values?.data.map((d) => {
                return {
                    ...d,
                    isMergeValues: d.value_id === id ? true : false,
                };
            });
        newData && setValues((curr) => (curr?.name ? { ...curr, data: newData } : null));
    };

    const hide = () => {
        const newData =
            values?.data &&
            values?.data.map((d) => {
                return {
                    ...d,
                    isMergeValues: false,
                };
            });
        newData && setValues((curr) => (curr?.name ? { ...curr, data: newData } : null));
    };

    const showValueAfterMergeOrDelete = async (id: number, name: string, clean?: boolean) => {
        !clean && setLoadingAttributeList(true);
        !clean && setValues(null);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-values-with-count?attribute_id=${id}`);
            setValues({
                name: name,
                id: id,
                data: data.data.map((item: ValuesType) => {
                    return { ...item, isMergeValues: false };
                }),
                type: data.data[0].type,
            });
            !clean && setLoadingAttributeList(false);
        } catch (err) {
            console.log(err);
            !clean && setLoadingAttributeList(false);
        }
    };

    const mergeAttributeValues = async (old_id: number, atr_id: number, atr_name: string | null | undefined) => {
        if (old_id && values?.data.find((d) => d.value_id === selectedNewValue)?.value_id) {
            try {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/merge-attribute-values?old_value_id=${old_id}&new_value_id=${
                        values?.data.find((d) => d.value_id === selectedNewValue)?.value_id
                    }`
                );

                setSelectedNewValue(null);
                atr_name && showValueAfterMergeOrDelete(atr_id, atr_name);
                showMessage(data.message);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const showModalValue = () => {
        setIsModalNewValue(true);
    };

    const deleteAllValues = async (id: number | undefined, name: string | undefined) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-all-attribute-values?attribute_id=${id}`);
            showMessage(data.message);
            typeof id === "number" && typeof name === "string" && showValueAfterMergeOrDelete(id, name);
        } catch (err) {
            console.log(err);
        }
    };

    const hideCreatePopover = () => {
        setPopoverClean(false);
    };
    const handleVisibleChange = (newVisible: boolean) => {
        setPopoverClean(newVisible);
    };

    const submitForm = async (e: { text: string }) => {
        const obj = {
            method: "remove",
            attribute_id: values?.id,
            sourceString: e.text.trim(),
            destinationString: null,
        };
        setLoadingClean(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/bulk-clean-attribute-allowed-values`, obj);
            showMessage(data.message);
            showValueAfterMergeOrDelete(values?.id!, values?.name!, true);
            setPopoverClean(false);
            setLoadingClean(false);
            form.resetFields();
        } catch (err) {
            console.log(err);
        }
    };

    // console.log(values);

    const columns: ColumnsType<ValuesType> = [
        {
            title: (
                <>
                    <Row justify="center">
                        <div style={{ height: "30px" }}>{values?.name}</div>
                    </Row>
                    <Row justify="space-between">
                        <Button size="small" onClick={showModalValue} disabled={values?.type === "range"}>
                            New Value
                        </Button>
                        <Popover
                            title={
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>Clean attribute value</span>
                                    <CloseOutlined style={{ marginTop: "5px", cursor: "pointer" }} onClick={hideCreatePopover} />
                                </div>
                            }
                            trigger="click"
                            placement="bottomRight"
                            open={popoverClean}
                            onOpenChange={handleVisibleChange}
                            content={
                                <React.Fragment>
                                    <Divider style={{ margin: "0 0 2rem 0" }} />
                                    <Form form={form} onFinish={submitForm} style={{ marginBottom: "2rem", width: "600px" }} labelCol={{ span: 6 }} wrapperCol={{ span: 17 }}>
                                        <Form.Item name="text" label="Remove from text" rules={[{ required: true, message: "Please input text!" }]}>
                                            <Input placeholder="Enter text" />
                                        </Form.Item>

                                        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
                                            <Button htmlType="submit" type="primary" loading={loadingClean}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </React.Fragment>
                            }
                        >
                            <Button size="small">Clean</Button>
                        </Popover>

                        <Popconfirm
                            placement="top"
                            disabled={values?.type === "range"}
                            title="Are sure?"
                            style={{ marginTop: "2px" }}
                            onConfirm={() => deleteAllValues(values?.id, values?.name)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size="small" danger disabled={values?.type === "range"}>
                                Delete All Values
                            </Button>
                        </Popconfirm>
                    </Row>
                </>
            ),
            children: [
                {
                    title: "Value",
                    dataIndex: "value_rs",
                },
                {
                    title: "",
                    dataIndex: "value_id",
                    width: 200,
                    align: "center" as "center",
                    render: (text, record) => (
                        <Space size={1}>
                            <Tooltip title="Edit">
                                <Button value="edit" size="small" onClick={(e) => onChangeAction("edit", record)} disabled={record.type === "range" ? true : false}>
                                    <EditOutlined style={{ cursor: "pointer" }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Merge">
                                <Popover
                                    content={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={(e) => setSelectedNewValue(Number(e))}
                                                style={{ width: 200 }}
                                                // onSearch={onSearch}
                                                filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                options={
                                                    values
                                                        ? values.data
                                                              .filter((d) => d.value_id !== record.value_id)
                                                              .map((v) => ({
                                                                  value: v.value_id?.toString(),
                                                                  label: v[`value_${currentCountryName}` as keyof typeof v]?.toString(),
                                                              }))
                                                        : []
                                                }
                                            />

                                            <Button onClick={() => mergeAttributeValues(record.value_id, record.attribute_id, values?.name)}>Merge</Button>
                                        </div>
                                    }
                                    title={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            Merge value
                                            <CloseOutlined
                                                style={{
                                                    cursor: "pointer",
                                                    marginTop: "3px",
                                                }}
                                                onClick={hide}
                                            />
                                        </div>
                                    }
                                    trigger="click"
                                    open={record.isMergeValues}
                                >
                                    <Button onClick={() => openPopover(record.value_id)} size="small" disabled={record.type === "range" ? true : false}>
                                        <MergeCellsOutlined />
                                    </Button>
                                </Popover>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button value="delete" size="small" onClick={(e) => onChangeAction("delete", record)} disabled={record.type === "range" ? true : false}>
                                    <DeleteOutlined style={{ cursor: "pointer" }} />
                                </Button>
                            </Tooltip>
                        </Space>
                    ),
                },
                {
                    title: "Count",
                    dataIndex: "product_count",
                    align: "center" as "center",
                    width: 80,
                },
            ],
        },
    ];

    return (
        <div style={{ border: "1px solid lightgray", borderRadius: "8px", margin: "0.3rem 0 0 0", height: "82vh" }}>
            <CreteNewValue isModalNewValue={isModalNewValue} setIsModalNewValue={setIsModalNewValue} values={values} setValues={setValues} countries={state.countries} />{" "}
            {values && (
                <Table
                    dataSource={values?.data.filter((d) => d.value_id || (!d.value_id && d.type === "range"))}
                    columns={columns}
                    rowKey={(record) => record.value_id}
                    loading={loadingAttributeList}
                    pagination={false}
                    scroll={{ y: `70vh` }}
                />
            )}
        </div>
    );
};

export default AttributeValues;
