import { Popover, Table } from "antd";
import React from "react";
import { HistoryType } from "../../types/type";
import { useSearchState } from "../../../../shared/hooks/useSearchState";
import moment from "moment";
import { CaretUpOutlined } from "@ant-design/icons";

interface HistoryProps {
    history: HistoryType[];
    loading: boolean;
}

const History = (props: HistoryProps) => {
    const { history, loading } = props;
    const { getColumnSearchProps } = useSearchState(history);

    const sortTable = (a: string, b: string, type: string) => {
        if (type === "date") {
            if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
            if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
            return 0;
        }
        if (a < b) return -1;
        if (b < a) return 1;
        return 0;
    };
    return (
        <Table
            columns={[
                {
                    title: "Shop",
                    dataIndex: "shop_name",
                    ...getColumnSearchProps("shop_name"),
                    sorter: (a, b) => sortTable(String(a.shop_name), String(b.shop_name), "string"),
                },
                {
                    title: "Action",
                    dataIndex: "action",
                    sorter: (a, b) => sortTable(String(a.action), String(b.action), "string"),
                    render: (text, record) => (
                        <div>
                            <Popover content={record.change ? <span>{record.change}</span> : ""}>
                                <span style={{ width: "350px", pointerEvents: !record.change ? "none" : "auto" }}>
                                    {record.action}{" "}
                                    {record.change && (
                                        <CaretUpOutlined
                                            style={{
                                                fontSize: "9px",
                                                color: "green",
                                                position: "relative",
                                                bottom: "3px",
                                            }}
                                        />
                                    )}
                                </span>
                            </Popover>
                        </div>
                    ),
                },
                {
                    title: "User",
                    dataIndex: "user",
                    ...getColumnSearchProps("user"),
                    sorter: (a, b) => sortTable(String(a.user), String(b.user), "string"),
                },
                {
                    title: "Created at",
                    dataIndex: "created_at",

                    sorter: (a, b) => sortTable(a.created_at, b.created_at, "date"),
                    render: (text: string) => (/*parseDate(record)*/ text ? `${moment(text).format("DD MMM YYYY")}, ${moment(text).format("HH:mm")}` : "-"),
                },
            ]}
            dataSource={history}
            pagination={false}
            size="small"
            rowKey={(record) => record.id}
            loading={loading}
        />
    );
};

export default History;
