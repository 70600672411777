import { Button } from 'antd'

type FromButtonType = {
    danger?:boolean 
    htmlType?:"button" | "submit" | "reset" | undefined
    type?:"link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined
    style:any 
    onClick?:() => void
    title: string 
    loading?:boolean
    form?:any
    icon?:any
    shape?: "default" | "circle"|"round" | undefined
    disabled?:boolean
    size?: any
}

const FormButton = (props:FromButtonType) => {
  return (
    <Button 
      {...props}
    >
      {props.title}
    </Button>
  )
}

export default FormButton

