export type ProductInfo = {};

export type FieldType = {
    id?: string;
};

export type PicturesType = {
    name: string;
    height: number;
    primaryimage: boolean;
    source_url: string;
    width: number;
};

export type HistoryType = {
    id: number;
    shop_name: string;
    action: string;
    user: string;
    created_at: string;
};

export type Price = {
    id: number;
    seller_image_url: string;
    shop_name: string;
    status: string;
    created_at: string;
    updated_at: string;
    sku: string;
    mpn: string;
    processed: number;
    ean: string;
    availability: number;
    active_for_website: number;
    condition: number;
    product_url: string;
    price: number;
    isFix: boolean;
};

export interface AttributeListType {
    product_attribute_id: number;
    key: any;
    name: string;
    product_id: number;
    attribute_id: number;
    attribute_type: "range" | "select" | "multiselect";
    attribute_allowed_value_id: number;
    value: string | null;
    loadingDelete: boolean;
    //isLoadingType:boolean
    all_values: { allowed_value_id: number; value: string }[];
    min_value: string;
    max_value: string;
    /////
    allowed_values: (string | undefined)[];
    allowed_ids: number[];
    all_obj_data: any;
}

export type PriceType = {
    id: number;
    shop_name: string;
    price: string;
    product_url: string;
    isFix: boolean;
    active_for_website: number;
    updated_at: string;
    ///////
    created_at: string;
    mpn: string | null;
    product_model: string;
    product_name: string;
    seller_image: string;
    shop_id: number;
    sku: string;
    ean: string;
    availability: number;
    processed: number;
};

export enum Tabs {
    PRICES = 1,
    DESCRIPTION_REVIEW,
    ATTRIBUTES,
    HISTORY,
}

export type EanType = {
    checked: boolean;
    code: string;
    id: number;
};
