import { Divider, Row } from "antd";
import { RootStore } from "../../../../Store";
import Description from "./DescriptionAndReview/Description/Description";
import { PicturesType } from "../../types/type";
import Review from "./DescriptionAndReview/Review/Review";

interface DescriptionAndReviewProps {
    productId: number | undefined;
    stateProduct: RootStore;
    description: Record<string, string | number | null>;
    setDescription: React.Dispatch<React.SetStateAction<Record<string, string | number | null> | undefined>>;
    currentCountryId: number;
    //review
    review: Record<string, string> | undefined;
    setReview: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
    productInfo: { name: string; value: string | PicturesType[] }[];
    loading: boolean;
}

const DescriptionAndReview = (props: DescriptionAndReviewProps) => {
    return (
        <Row style={{ overflowY: "auto", /*height: "85vh",*/ paddingBottom: "2rem", opacity: props.loading ? 0.7 : 1 }} className="product-content-box-scroll">
            <Description {...props} />
            <Divider></Divider>
            <Review {...props} />
        </Row>
    );
};

export default DescriptionAndReview;
