import { PrimaryAttributesType } from "../../pages/TopProductAttributes";

export const tableWidth = (primaryAttributes: PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]) => {
    switch (primaryAttributes.length) {
        case 0:
            return 6;
        case 1:
            return 10;
        case 2:
            return 12;
        case 3:
            return 14;
        case 4:
            return 20;
        case 5:
            return 22;
        case 6:
            return 24;
        default:
            return 24;
    }
};
