import { Button, Divider, Form, Input, Radio, Row } from "antd";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { EditableNamePopoverProp } from "../types/props";

const EditableNamePopoverContent: React.FC<EditableNamePopoverProp> = (props) => {
    const [form] = Form.useForm();

    const addText = () => {
        //props.setPopoverVisible(false);
        const oldText = form.getFieldValue("old-text");
        const newText = form.getFieldValue("new-text");
        const textPosition = form.getFieldValue("textPosition") as "start" | "end" | "replace" | "replace_all";
        switch (textPosition) {
            case "start":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: newText + ` ${p.name}` } : p)));
                break;
            case "end":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: `${p.name} ` + newText } : p)));
                break;
            case "replace":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: `${p.name}`.replace(oldText, newText) } : p)));
                break;
            case "replace_all":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: newText } : p)));
                break;
            default:
                openNotification("Select text position!");
                break;
        }

        form.resetFields();
    };

    const textPosition = Form.useWatch("textPosition", form);

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "1.5rem" }}>
            <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
            <Form onFinish={addText} initialValues={{ textPosition: "start" }} form={form}>
                {textPosition === "replace" && (
                    <Form.Item label="Old Text" name="old-text" rules={[{ required: true, message: "Missing old text" }]}>
                        <Input placeholder="Old Text" />
                    </Form.Item>
                )}
                <Form.Item label="New Text" name="new-text" rules={[{ required: true, message: "Missing new text" }]}>
                    <Input placeholder="New Text" />
                </Form.Item>

                <Form.Item key="modelPosition" label="Position" name="textPosition">
                    <Radio.Group name="radiogroup">
                        <Radio value={"start"}>Start</Radio>
                        <Radio value={"end"}>End</Radio>
                        <Radio value={"replace"}>Replace</Radio>
                        <Radio value={"replace_all"}>Replace All</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
                <Row justify="center">
                    <Button type="primary" htmlType="submit">
                        Add text
                    </Button>
                </Row>
            </Form>
        </div>
    );
};

export default EditableNamePopoverContent;
