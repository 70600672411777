import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
const baseUrl = process.env.REACT_APP_URL_API;

export const addImageToProduct = async (product_id: number, newImage: string | undefined) => {
    const obj = {
        product_id: product_id,
        image: newImage,
    };

    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/image/add-image`, obj);
    return data;
};

// Get image from seller and set it as default product image

export const replaceProductImageFromSeller = async (product_id: number, image: string) => {
    const obj = {
        product_id: product_id,
        link: image,
    };
    const { data } = await axios.put(`${baseUrl}/product/image/set-default-image-by-link`, obj);
    return data;
};

/* Get product images */

export const getProductImages = async (product_id: number) => {
    const { data } = await axios.get(`${baseUrl}/product/image/list-product-images?product_id=${product_id}`);
    return data;
};

// Set new primary image (on double click)

export const setNewPrimaryImage = async (product_id: number, image_name: string) => {
    const { data } = await axios.put(`${baseUrl}/product/image/set-default-image?product_id=${product_id}&name=${image_name.substring(3)}`);
    showMessage(data.message);
    return data;
};

// Delete image

export const deleteImage = async (product_id: number, name: string) => {
    const { data } = await axios.delete(`${baseUrl}/product/image/delete-image?product_id=${product_id}&name=${name.substring(3)}`);
    showMessage(data.message);
};
