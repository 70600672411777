import { Button, Form, Modal, Row, Spin, Switch, TreeSelect } from 'antd';
import React, { useState, useEffect, useCallback } from 'react'
import { RootStore } from '../../../../Store';
import axios from 'axios';
import showMessage from '../../../../shared/MessagesInfo/message';
import DescriptionEditor from './DescriptionEditor';
import { CategoryArrType } from '../../pages/AllBrands';
const { TreeNode } = TreeSelect

interface PrevTextProps {
    descriptionBrand: { id:number, name:string } | null
    setDescriptionBrand: React.Dispatch<React.SetStateAction<{id: number; name: string;} | null>>
    setDescriptionVisible: React.Dispatch<React.SetStateAction<boolean>>
    descriptionVisible: boolean
    brandState: RootStore
    currentCountryName: string
    currentCountryId: number
    categories: CategoryArrType[]
    getCategories: () => Promise<void>
    setShowAllDescription: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedCategory: React.Dispatch<React.SetStateAction<{ id: number, name: string } | null>>
    selectedCategory: { id: number, name: string } | null
}

export type InitValueType = {
    [key: string]:string
}

type BrandDescStateType = {
    country: string
    value: string 
    isChanged: boolean
    /////////
    title: string
    h1: string
}

const AllBrandsDescription:React.FC<PrevTextProps> = ({ descriptionBrand, setDescriptionBrand, setDescriptionVisible, descriptionVisible, brandState, currentCountryName, currentCountryId,categories, getCategories, setShowAllDescription, setSelectedCategory, selectedCategory }) => {
    const [ loading, setLoading ] = useState(false)
    const [ initValue, setInitValue ] = useState<InitValueType| null>(null)
    const initDescState = brandState.countries.map( c => ({ country: c.name, title:'',h1:'', value:'', isChanged:false }))
    const [ brandDescriptionState, setBrandDescriptionState ] = useState<BrandDescStateType[]> (initDescState)
    const [ saveDescriptionLoading, setSaveDescriptionLoading ] = useState(false)
    const [ clearTextEditor, setClearTextEditor ] = useState(1)
    const [ form ] = Form.useForm()
    
    const checkDescTexts = useCallback( async ( id:number ) => {
        //setBrandDescriptionState(initDescState)
        setInitValue(null)
        setLoading(true)
        setClearTextEditor( curr => curr +1)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API }/product/brand/show-description?brand_id=${ id }${ selectedCategory ? `&category_l3_id=${ selectedCategory.id }`:'' }`)
            const html = data.data ?  data.data : null 
            const newData = brandState.countries.map( c => {
                const title = data?.data[`title_${ c.name }`] ? data.data[`title_${ c.name }`] : null
                const h1 = data?.data[`h1_${ c.name }`] ? data.data[`h1_${ c.name }`] :null
                const desc = data?.data[`description_${ c.name }`] ? data.data[`description_${ c.name }`] :null
                return { 
                    country: c.name, 
                    title: title, 
                    h1: h1, 
                    value: desc, 
                    isChanged:false 
                }
            })
            setBrandDescriptionState(newData)
            setInitValue(html)
            setLoading(false)
            setDescriptionVisible(true)
        } catch ( err ) {
            console.log(err)
            setLoading(false)
        }
    }, [ setDescriptionVisible, selectedCategory, brandState.countries ])

    useEffect(() => {
        descriptionBrand?.id && checkDescTexts(descriptionBrand?.id)
    }, [ descriptionBrand, checkDescTexts ])

    const closeDesc = () => {
        setDescriptionVisible(false)
        setDescriptionBrand(null)
        setSelectedCategory(null)
    }

    const saveDescription = async ( clear?: boolean, clearCountry?: string ) => {
        const changedDescTexts = brandDescriptionState.filter( b => b.isChanged)
        const obj:Record<string, string | boolean>= {}
        changedDescTexts.forEach( b => {
            for (let key in b) {
                if (key === 'country') {
                    obj[ `description_${ b[key as keyof BrandDescStateType ] }` ] = b.value
                    obj[ `title_${ b[key as keyof BrandDescStateType ] }` ] = b.title
                    obj[ `h1_${ b[key as keyof BrandDescStateType ] }` ] = b.h1
                }   
            }
        })
                
        const objectToSend = !clear ? {
            id:descriptionBrand?.id , 
            ...obj,
            ...(selectedCategory && {category_l3_id: selectedCategory?.id}),
           

        }:{ 
            id:descriptionBrand?.id, 
            [ `description_${ clearCountry }` ] : null,
            ...(selectedCategory && {category_l3_id: selectedCategory?.id})
        }
        !clear && setSaveDescriptionLoading(true)
        console.log(objectToSend)
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/product/brand/update`, objectToSend)
            showMessage(!clear ? data.message : 'Description deleted')
            !clear && setSaveDescriptionLoading(false)
            //setBrandDescriptionState(initDescState)
            clear && setBrandDescriptionState( curr =>  curr.map( b => b.country === clearCountry ? ({...b, value: '', isChanged: true}):b))
            getCategories()
            // if switch button is missing, than 
        } catch ( err ) {
            console.log(err)
            !clear && setSaveDescriptionLoading(false)
        }
    }

    const handleSwitchChange = ( e:boolean ) => {
        setShowAllDescription(e)
    }

    const onCategoryChange = (e:number) => {

        const findCateogry = categories.find( c => c.id === e )?.name as string

        const onlyL4= categories.filter( c => c.children.length > 0).map( c => c.children)
        let emptyArr:{ name: string, id: number, brand_description_flag: boolean } [] = []
        const flattenedArray = emptyArr.concat(...onlyL4);
        const findL4Category = flattenedArray.find( c => c.id === e)?.name || ''

        if(e) {
            setSelectedCategory({
                id:e,
                name: findCateogry ? findCateogry : findL4Category
            })
        }
        else {
            setSelectedCategory(null)
        }
    }

    return (
        <Modal
            title={`${ descriptionBrand?.name }${ selectedCategory ? ` & ${ selectedCategory.name }` : '' }`}
            centered
            open={ descriptionVisible }
            onCancel={() =>{ closeDesc(); form.resetFields() }}
            width="100%"
            footer={false}
            style={{  height:'100vh', paddingBottom:'3rem', marginTop:'4rem'}}
        >
           
                <div style={{ border:'1px solid lightgray', borderRadius:'8px', padding:'1rem', backgroundColor:'whitesmoke'}}>
                    <Row justify="center" align="middle">
                        <div style={{ width:'50px' }}>
                            {loading && <Spin style={{ marginLeft:'1rem' }} />}
                        </div>
                        <div style={{ width: '500px' }}>
                            <TreeSelect
                                showSearch
                                treeDataSimpleMode
                                placeholder="Select Category"
                                allowClear
                                style={{
                                    width:'100%'
                                }}
                                value={ selectedCategory?.id }
                                onChange={onCategoryChange}
                                filterTreeNode={(search:string, item:any) => 
                                    item.title.props?.children[0]?.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                }
                            >
                                { categories
                                    .sort((a, b) => 
                                        a.brand_description_flag === b.brand_description_flag ? 
                                            0: a.brand_description_flag ?
                                                -1 : 1)
                                    .map((category:CategoryArrType) => (
                                category.children.length === 0  ?
                                <TreeNode 
                                    key={category.id} 
                                    value={ category.id } 
                                    title={<div style={{ fontWeight: category.brand_description_flag ? 'bold': '' }}>{`${category.id}: ${category.name}`} <span style={{ fontSize:"12px", opacity:"0.8", color:"gray" }}> ({category.name}) </span></div>} 
                                    st
                                />:
                                <TreeNode 
                                    key={category.id} 
                                    value={ category.id } 
                                    title={<div style={{ fontWeight: category.brand_description_flag ? 'bold': '' }}>{`${category.id}: ${category.name}`} <span style={{ fontSize:"12px", opacity:"0.8", color:"gray" }}> ({category.name}) </span></div>} 
                                >
                                    { typeof category.children !== 'string' && category.children.map( itemChild => (
                                    <TreeNode 
                                        key={itemChild.id} 
                                        value={itemChild.id} 
                                        title={<div style={{ fontWeight: itemChild.brand_description_flag ? 'bold': '' }}>{`${itemChild.id}: ${itemChild.name} `} </div>}
                                    />))}
                                </TreeNode>))}
                            </TreeSelect>  
                        </div>
                            <Switch 
                                checkedChildren="All" 
                                unCheckedChildren="Missing" 
                                defaultChecked 
                                style={{ marginLeft:'1rem', alignSelf:'start', marginTop:'0.3rem' }}
                                onChange={ handleSwitchChange }
                            />
                        
                    </Row>
                    <div style={{ display:'flex', flexWrap:"wrap" }}>
                    { brandState.countries.map( c => 
                        <DescriptionEditor
                            initValue={ initValue  }
                            country={ c.name }
                            loading={ loading }
                            setBrandDescriptionState={ setBrandDescriptionState }
                            key={ c.name }
                            clearTextEditor={ clearTextEditor }
                            saveDescription={ saveDescription }
                            form={ form }
                        />
                    )}
                    </div>
                    <Button 
                        type='primary' 
                        style={{ marginTop:'1rem' }} 
                        onClick={() => saveDescription()}
                        loading={ saveDescriptionLoading }
                    >
                        Save Description
                    </Button>
                </div>
            
        </Modal>
    )
}

export default AllBrandsDescription