import { KeyEventsParam } from "../types/keyEvents";
import { AllowKeysF, ProductType } from "../types/types";

const newName = (product: ProductType, key: AllowKeysF, currentCountryName?: string) => {
    const wordToArray = product[currentCountryName ? (`name_${currentCountryName}` as keyof typeof product) : "name"]
        .toString()
        .split(" ");
    switch (key) {
        case "F1":
            return wordToArray.slice(0, 1).join(" ");
        case "F2":
            return wordToArray.slice(wordToArray.length - 1, wordToArray.length).join(" ");
        case "F3":
            return wordToArray.slice(1, wordToArray.length).join(" ");
        case "F4":
            return wordToArray.slice(0, wordToArray.length - 1).join(" ");
        case "F5":
            const brand = product.brand_name ? product.brand_name.toLowerCase() : "";
            return wordToArray.filter((w) => !w.toLowerCase().trim().includes(brand)).join(" ");
        case "F6":
            return product.name;
        case "F8":
            return product.name ? product.name[0].charAt(0).toLowerCase() + product.name.substring(1) : "";
        case "F9":
            const makeSmallLetter = wordToArray.map((word: string) => {
                if (
                    (/^[a-zA-ZŠšČčĆćŽžĐđ]+$/.test(word) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(word)) &&
                    !/\d/g.test(word)
                ) {
                    return word.toLocaleLowerCase();
                } else if (parseInt(word)) {
                    return word;
                } else {
                    return word;
                }
            });
            return makeSmallLetter.join(" ");
        default:
            return "";
    }
};
// Universal function for F1 - F12

export const keyF = (setProducts: KeyEventsParam, key: AllowKeysF, currentCountryName?: string, property?: string) => {
    setProducts((curr) =>
        curr.map((product) => {
            if (product.isChecked /*&& product.name*/) {
                return {
                    ...product,
                    [property ? "mpn" : "name"]: newName(product, key, currentCountryName),
                };
            } else {
                return product;
            }
        })
    );
};
