import { Form, Input } from 'antd'
import React from 'react'
import { capitalizeFirstLetter } from '../../../attributes/helpers/checkIsIncludes'

type InputElementType = {
  handleKeyword: any
  type: string 
}

const InputElement:React.FC<InputElementType> = ({ handleKeyword, type }) => {
  return (
    <Form.Item label={ capitalizeFirstLetter( type )} name={ type } style={{ margin:'8px', width:'400px' }} >

      <Input 
          placeholder={`Enter ${ capitalizeFirstLetter( type ) }`}
          style={{ marginLeft:'14px'}} 
          onChange={(e) => handleKeyword(e.target.value, type )}
      />
    </Form.Item>

       
  )
}

export default InputElement