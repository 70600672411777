import { CheckOutlined, CloseOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Image, Popconfirm, Popover, Row, Table, message } from "antd";
import { EansType, OffersType } from "../../pages/EanOverview";
import axios from "axios";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface EansTableProps {
    en: {
        code: string;
        id: number;
        loadingNewProductByEAN: boolean;
        loadingNewProductByOffer: boolean;
        offers: OffersType[];
    };
    eans: EansType;
    setEans: React.Dispatch<React.SetStateAction<EansType>>;
    removeEanCreateProduct: (ean: string) => Promise<void>;
    loadingRemoveEan: string | undefined;
    getData: () => Promise<void>;
}

const isAllChecked = (eans: EansType, en: { code: string; id: number; loadingNewProductByEAN: boolean; loadingNewProductByOffer: boolean; offers: OffersType[] }) => {
    if (eans.data.find((item) => item.code === en.code)?.offers.length === 1 && eans.data.find((item) => item.code === en.code)?.offers.filter((of) => of.correctPrice).length === 1) return false;
    return eans.data.find((item) => item.code === en.code)?.offers.length === eans.data.find((item) => item.code === en.code)?.offers.filter((of) => of.checkedPrice || of.correctPrice).length;
};

const EansTable = (props: EansTableProps) => {
    const { en, eans, setEans, removeEanCreateProduct, loadingRemoveEan, getData } = props;

    const chooseCorrectPrice = (price_id: number) => {
        setEans((curr) => ({
            ...curr,
            data: curr.data.map((e) =>
                e.id === en.id ? { ...e, offers: e.offers.map((o) => (o.price_id === price_id ? { ...o, correctPrice: !o.correctPrice, checkedPrice: false } : { ...o, correctPrice: false })) } : e
            ),
        }));
    };

    const createNewProductByEAN = async (ean: string) => {
        setEans((curr) => ({ ...curr, data: curr.data.map((d) => (d.code === ean ? { ...d, loadingNewProductByEAN: true } : d)) }));

        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.post(`${baseUrl}/product/ean-overview/create-new-product-by-ean`, { ean: ean, source_price: en.offers.find((o) => o.correctPrice)?.price_id }); // l3=229
            message.success({
                onClose() {},
                style: { marginTop: "8rem" },
                content: (
                    <span style={{ display: "flex", alignItems: "end" }}>
                        <div>Created new product:</div>
                        <span
                            className="ean-overview-product-id"
                            onClick={() => {
                                window.open(`/product/${data.data}/prices`);
                                message.destroy();
                            }}
                        >
                            {data.data}
                        </span>
                        <div style={{ marginLeft: "1rem", marginBottom: "0.2rem" }}>
                            <CloseOutlined style={{ cursor: "pointer" }} onClick={() => message.destroy()} />
                        </div>
                    </span>
                ),
                className: "custom-class",
                duration: 0,
            });
            // showMessage(data.message);
            setEans((curr) => ({
                ...curr,
                data: curr.data.filter((d) => d.code !== ean),
            }));
            getData();
        } catch (err) {
            console.log(err);
        }
    };

    const checkPrice = (price_id: number) => {
        setEans((curr) => ({
            ...curr,
            data: curr.data.map((e) => (e.id === en.id ? { ...e, offers: e.offers.map((o) => (o.price_id === price_id ? { ...o, checkedPrice: o.correctPrice ? false : !o.checkedPrice } : o)) } : e)),
        }));
    };

    const createNewProductByOffers = async (ean: string) => {
        setEans((curr) => ({ ...curr, data: curr.data.map((d) => (d.code === ean ? { ...d, loadingNewProductByOffer: true } : d)) }));

        const selectedPrices: Record<string, string | number | boolean>[] = [];

        eans.data.forEach((e) =>
            e.offers.forEach((o) => {
                if (o.checkedPrice && !o.correctPrice) {
                    selectedPrices.push(o);
                }
            })
        );

        const source_price = en.offers.find((o) => o.correctPrice)?.price_id;
        const prices = selectedPrices.map((p) => p.price_id);

        const obj = {
            source_price: source_price,
            prices: prices,
        };

        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.post(`${baseUrl}/product/ean-overview/create-new-product-with-offers`, obj);

            message.success({
                onClose() {},
                style: { marginTop: "8rem" },
                content: (
                    <span style={{ display: "flex", alignItems: "end" }}>
                        <div>Created new product:</div>
                        <span
                            className="ean-overview-product-id"
                            onClick={() => {
                                window.open(`/product/${data.data.new_product_id}/prices`);
                                message.destroy();
                            }}
                        >
                            {data.data.new_product_id}
                        </span>
                        <div style={{ marginLeft: "1rem", marginBottom: "0.2rem" }}>
                            <CloseOutlined style={{ cursor: "pointer" }} onClick={() => message.destroy()} />
                        </div>
                    </span>
                ),
                className: "custom-class",
                duration: 0,
            });

            setEans((curr) => ({
                ...curr,
                data: curr.data
                    .map((d) => (d.code === ean ? { ...d, loadingNewProductByOffer: false } : d)) // stop for loading in button
                    .map((e) => ({
                        ...e,
                        offers: e.offers.filter(
                            (o) => !prices.includes(o.price_id) && o.price_id !== source_price
                            // return true;
                        ),
                    })),
            }));
            getData();
        } catch (err) {
            console.log(err);
        }
    };

    const checkAll = (event: CheckboxChangeEvent, ean: any) => {
        setEans((curr) => ({
            ...curr,
            data: curr.data.map((e) => (e.code === ean ? { ...e, offers: e.offers.map((o) => ({ ...o, checkedPrice: o.correctPrice ? false : event.target.checked })) } : e)),
        }));
    };

    const noEans = en.code === "0000000000000";

    return (
        <Col className="gutter-row" span={8}>
            <Card style={{ backgroundColor: noEans ? "#FEF3E2" : "#F6F5F5", marginTop: "1.5rem" }}>
                <Row justify="space-between">
                    <div>
                        <span style={{ backgroundColor: noEans ? "#EB3678" : "#0F67B1", color: "white", padding: "0.3rem 0.5rem", borderRadius: "5px" }}>
                            {noEans ? "Prices without ean" : en.code}
                        </span>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {!noEans && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem", alignItems: "flex-end" }}>
                                <Popconfirm placement="top" title={"Are you sure?"} onConfirm={!loadingRemoveEan ? () => removeEanCreateProduct(en.code) : undefined} okText="Yes" cancelText="No">
                                    <Button size="small" danger /*onClick={() => (!loadingRemoveEan ? removeEanCreateProduct(en.code) : {})} */ loading={en.code === loadingRemoveEan}>
                                        Remove EAN & Create new products
                                    </Button>
                                </Popconfirm>
                                <Button
                                    size="small"
                                    style={{ width: "242px" }}
                                    onClick={() => createNewProductByEAN(en.code)}
                                    disabled={!en.offers.find((o) => o.correctPrice)}
                                    loading={en.loadingNewProductByEAN}
                                >
                                    Create new product by EAN
                                </Button>
                            </div>
                        )}
                        <Button
                            size="small"
                            style={{ width: "242px", marginTop: "0.2rem" }}
                            onClick={() => createNewProductByOffers(en.code)}
                            disabled={!en.offers.find((o) => o.correctPrice)}
                            loading={en.loadingNewProductByOffer}
                        >
                            Create new product by Offers
                        </Button>
                    </div>
                </Row>
                <Table
                    bordered
                    className="ean-review-eans-table"
                    style={{ marginTop: "1rem" }}
                    columns={[
                        {
                            title: "",
                            align: "center" as "center",
                            render: (record) => (
                                <Popover
                                    placement="rightTop"
                                    content={
                                        <div style={{ width: "180px", height: "180px" }}>
                                            <Image src={record.seller_image_url} preview={false} />
                                        </div>
                                    }
                                    arrowPointAtCenter
                                >
                                    <Image src={record.seller_image_url} width={35} preview={false} />
                                </Popover>
                            ),
                        },
                        {
                            title: "Product Name",
                            dataIndex: "product_name",
                            render: (text, record) => (
                                <span style={{ cursor: "pointer" }} onClick={() => chooseCorrectPrice(record.price_id)}>
                                    {record.product_name}
                                </span>
                            ),
                        },
                        {
                            title: "Brand",
                            dataIndex: "brand",
                        },
                        {
                            title: "Mpn",
                            dataIndex: "mpn",
                        },
                        {
                            title: "Shop",
                            dataIndex: "shop",
                        },
                        {
                            dataIndex: "seller_url",
                            align: "center" as "center",
                            render: (text) => (
                                <div onClick={() => window.open(text, "_blank", "noreferrer")} style={{ cursor: "pointer" }}>
                                    <LinkOutlined style={{ fontSize: "20px" }} />
                                </div>
                            ),
                        },
                        {
                            title: <Checkbox onChange={(e) => checkAll(e, en.code)} checked={isAllChecked(eans, en)} />,
                            dataIndex: "price_id",
                            align: "center" as "center",
                            render: (text, record) => (
                                <div
                                    //onClick={() => chooseCorrectPrice(text)}
                                    onClick={() => checkPrice(text)}
                                    style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "100%",
                                        padding: "10px",
                                    }}
                                >
                                    {
                                        <CheckOutlined
                                            style={{
                                                opacity: record.checkedPrice ? "1" : "0",
                                            }}
                                        />
                                    }
                                </div>
                            ),
                        },
                    ]}
                    dataSource={en.offers}
                    pagination={false}
                    rowKey={(record) => record.price_id}
                    rowClassName={(record) => (record.correctPrice ? "ean-overview-correct-price no-row-hover" : "")}
                />
            </Card>
        </Col>
    );
};

export default EansTable;
