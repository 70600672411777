import { Select, Row, Col, Form, Spin, Checkbox, Modal, Button, Divider } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createShopRules, editShopRules, showShopRulesById } from "../../../actions/shopAction/shopRulesAction";
import InputE from "../../../shared/FormElements/Input";
import { RootStore } from "../../../Store";
import "../style/CreateShopRules.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { ShopRulesType } from "./ShopList";
import openNotification from "../../../shared/MessagesInfo/WarningBox";

const Option = Select.Option;

interface BooleansI {
    parse_price: boolean;
    price_required: boolean;
    trim: boolean;
    relax: boolean;
    skip_empty_lines: boolean;
    skip_lines_with_error: boolean;
    has_multiple_images: boolean;
    has_attributes: boolean;
}

interface ObjectCreateShopRules {
    decimal_separator: string;
    delimiter: undefined | string;
    file_type: string;
    items_plural: string;
    items_singular: string;
    parse_price: string;
    price_key_name: undefined | string;
    price_required: string;
    relax: string;
    shop_id: number | undefined;
    skip_empty_lines: string;
    skip_lines_with_error: string;
    thousand_separator: string;
    traverse_key: string;
    trim: string;
    has_multiple_images: boolean;
    single_image_xml_tag: string;
    mix_image_with: string;
    //hasAttributes: boolean;
    has_attributes: boolean;
    attribute_array?: string[];
}

interface CreateShopRulesProps {
    shopRules: ShopRulesType | null;
    isModalRules: boolean;
    handleModalRulesOk: () => void;
    handleModalRulesCancel: () => void;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const CreateShopRulesModal: React.FC<CreateShopRulesProps> = ({ shopRules, isModalRules, handleModalRulesOk, handleModalRulesCancel, setCounter }) => {
    const [form] = Form.useForm();
    const [fileType, setFileType] = useState<string>("");
    const [booleans, setBooleans] = useState<BooleansI>({
        parse_price: false,
        price_required: false,
        trim: false,
        relax: false,
        skip_empty_lines: false,
        skip_lines_with_error: false,
        has_multiple_images: false,
        has_attributes: false,
    });
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();
    const shopsState = useSelector((state: RootStore) => state);
    useEffect(() => {
        if (shopRules?.status === "Edit") {
            dispatch(showShopRulesById(shopRules?.id, form, setBooleans, setLoading, /*setIsRulesMessage,*/ setFileType));
        } else {
            form.resetFields();
        }
    }, [shopRules, form, dispatch]);
    const currentCountryId = shopsState.dataLs.country ? shopsState.dataLs.country.countryId : 1;

    useEffect(() => {
        setLoading(false);
    }, [dispatch, currentCountryId]);

    const creteShopRuls = async (objectToSend: ObjectCreateShopRules) => {
        if (shopRules?.status === "Edit") {
            const objectUpdate = {
                ...objectToSend,
                shop_id: Number(shopRules?.id),
                parse_price: booleans.parse_price,
                price_required: booleans.price_required,
                trim: booleans.trim,
                relax: booleans.relax,
                skip_empty_lines: booleans.skip_empty_lines,
                skip_lines_with_error: booleans.skip_lines_with_error,
                has_multiple_images: booleans.has_multiple_images,
                has_attributes: booleans.has_attributes,
                attribute_array: objectToSend.attribute_array && objectToSend.attribute_array?.length > 0 ? objectToSend.attribute_array : null,
            };
            dispatch(editShopRules(objectUpdate, form /*, setShopId,*/, setLoading, history, setCounter, handleModalRulesOk));
        } else {
            dispatch(
                createShopRules(
                    {
                        ...objectToSend,
                        attribute_array: objectToSend.attribute_array && objectToSend.attribute_array?.length > 0 ? objectToSend.attribute_array : null,
                    },
                    form /*, setShopId,*/,
                    setLoading,
                    history,
                    handleModalRulesOk,
                    setCounter
                )
            );
        }
    };

    const handleSubmit = (event: ObjectCreateShopRules) => {
        if (event.file_type === "csv" && event.has_attributes && (!event.attribute_array || event.attribute_array?.length === 0)) {
            openNotification("If 'Has Attribute' is checked, Attributes have to contain at least one item.");
            return;
        }
        if (event.has_multiple_images && !event.single_image_xml_tag && !event.mix_image_with) {
            openNotification('If checkbox "Has Multiple Images is checked",  "Single Image Xml Tag" input or "Mix Image With" input sould be filled');
        } else {
            const Obj: any = {
                ...event,
                shop_id: shopRules?.id,
            };
            creteShopRuls(Obj);
        }
    };

    const onSelectFileType = (event: string) => {
        if (event === "csv") {
            form.setFieldValue("has_multiple_images", false);
            form.setFieldValue("single_image_xml_tag", "");
            form.setFieldValue("mix_image_with", "");

            setBooleans({
                ...booleans,
                has_multiple_images: false,
            });
        }
        setFileType(event);
    };

    const hasMultipleImages = Form.useWatch("has_multiple_images", form);
    const singleImageXmlTag = Form.useWatch("single_image_xml_tag", form);
    const mixImageWith = Form.useWatch("mix_image_with", form);
    const hasAttributes = Form.useWatch("has_attributes", form);

    useEffect(() => {
        !hasAttributes && form.setFieldValue("attribute_array", []);
    }, [hasAttributes, form]);

    return (
        <React.Fragment>
            {isModalRules && <HelmetTitle title="Data Entry App - Create Shop Rules" />}
            <Modal
                forceRender
                title={
                    <p style={{ textAlign: "center" }}>
                        {`${shopRules?.status === "Edit" ? "Edit" : "Create"} shop rules ${shopRules?.shop_name && `for  `}`}
                        <span style={{ color: "darkred", fontSize: "16px" }}>{shopRules?.shop_name}</span>
                    </p>
                }
                open={isModalRules}
                onOk={handleModalRulesOk}
                onCancel={handleModalRulesCancel}
                width={900}
                footer={false}
            >
                {loading && shopRules?.id ? (
                    <Row style={{ minHeight: "57.5vh" }}>
                        <Col span={12} offset={12}>
                            <Spin size="large" style={{ marginTop: "9rem" }}></Spin>
                        </Col>
                    </Row>
                ) : (
                    <Form
                        form={form}
                        name="create-shop-rules-form"
                        style={{ width: "100%" }}
                        labelCol={{ span: 20 }}
                        onFinish={handleSubmit}
                        initialValues={{
                            parse_price: "true",
                            price_required: "true",
                            trim: "true",
                            relax: "true",
                            skip_empty_lines: "true",
                            skip_lines_with_error: "true",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div style={{ width: "35%" }}>
                                <InputE label="Items Singular" name="items_singular" rules={[{ required: true, message: "Missing Items Singular" }]} />
                                <InputE label="Items Plural" name="items_plural" rules={[{ required: true, message: "Missing Items Plural" }]} />
                                <InputE label="Traverse Key" name="traverse_key" rules={[{ required: true, message: "Missing traverse key" }]} />
                                <InputE label="Price Key Name" name="price_key_name" disabled={true} />
                                <Form.Item name="parse_price" label="Parse price" rules={[{ required: true }]}>
                                    <Select
                                        id="parse_price"
                                        onChange={(value) =>
                                            setBooleans({
                                                ...booleans,
                                                parse_price: value === "true" ? true : false,
                                            })
                                        }
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="price_required" label="Price Required" rules={[{ required: true }]}>
                                    <Select
                                        onChange={(value) =>
                                            setBooleans({
                                                ...booleans,
                                                price_required: value === "true" ? true : false,
                                            })
                                        }
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                                <InputE label="Decimal Separator" name="decimal_separator" />
                                <InputE label="Thousand Separator" name="thousand_separator" />
                                <InputE label="Product description element XPath" name="product_page_specs_xpath" />
                            </div>
                            <div style={{ width: "35%" }}>
                                {/*---------------------------*/}
                                <Form.Item name="file_type" label="File Type" rules={[{ required: true, message: "Missing File Type" }]}>
                                    <Select placeholder="Choose file type" onChange={onSelectFileType}>
                                        <Option value="csv">CSV</Option>
                                        <Option value="xml">XML</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="has_multiple_images" style={{ width: "100%", marginTop: "1rem" }} valuePropName="checked">
                                    <Checkbox
                                        checked={booleans.has_multiple_images}
                                        onChange={(e) => {
                                            setBooleans({
                                                ...booleans,
                                                has_multiple_images: e.target.checked,
                                            });
                                        }}
                                        disabled={fileType === "xml" ? false : true}
                                    >
                                        Has Multiple Images
                                    </Checkbox>
                                </Form.Item>
                                <InputE label="Single Image Xml Tag" name="single_image_xml_tag" disabled={!hasMultipleImages || mixImageWith?.length > 0 ? true : fileType === "xml" ? false : true} />
                                <InputE label="Mix Image With" name="mix_image_with" disabled={!hasMultipleImages || singleImageXmlTag?.length > 0 ? true : fileType === "xml" ? false : true} />
                                <Form.Item name="has_attributes" valuePropName="checked">
                                    <Checkbox
                                        style={{ marginTop: "1.2rem" }}
                                        checked={booleans.has_attributes}
                                        onChange={(e) => {
                                            setBooleans({
                                                ...booleans,
                                                has_attributes: e.target.checked,
                                            });
                                        }}
                                        // disabled={fileType === "xml" ? false : true}
                                    >
                                        Has Attributes
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item name="attribute_array" label="Attributes" rules={[{ required: false }]}>
                                    <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="Tags Mode"
                                        //onChange={handleChange}
                                        disabled={hasAttributes ? false : true}
                                        options={[]}
                                    />
                                </Form.Item>

                                <Form.Item name="trim" label="Trim" rules={[{ required: false }]}>
                                    <Select
                                        disabled={fileType === "xml" ? true : false}
                                        onChange={(value) =>
                                            setBooleans({
                                                ...booleans,
                                                trim: value === "true" ? true : false,
                                            })
                                        }
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="relax" label="Relax" rules={[{ required: false }]}>
                                    <Select
                                        disabled={fileType === "xml" ? true : false}
                                        onChange={(value) =>
                                            setBooleans({
                                                ...booleans,
                                                relax: value === "true" ? true : false,
                                            })
                                        }
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                                <InputE label="Delimiter" name="delimiter" rules={[{ required: false, message: "Missing delimiter" }]} disabled={fileType === "xml" ? true : false} />
                                <Form.Item name="skip_empty_lines" label="Skip Empty Lines" rules={[{ required: false }]}>
                                    <Select
                                        disabled={fileType === "xml" ? true : false}
                                        onChange={(value) =>
                                            setBooleans({
                                                ...booleans,
                                                skip_empty_lines: value === "true" ? true : false,
                                            })
                                        }
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="skip_lines_with_error" label="Skip Lines With Error" rules={[{ required: false }]}>
                                    <Select
                                        disabled={fileType === "xml" ? true : false}
                                        onChange={(value) => {
                                            setBooleans({
                                                ...booleans,
                                                skip_lines_with_error: value === "true" ? true : false,
                                            });
                                        }}
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <Divider />

                        <Button
                            /*className='u-button'*/ style={{
                                marginTop: "10px",
                                marginLeft: "83%",
                            }}
                            htmlType="submit"
                            type="primary"
                        >{`${shopRules?.status === "Edit" ? "Update" : "Add new"} rules`}</Button>
                    </Form>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default CreateShopRulesModal;
