import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { CorrectWordData, SortedAlphabetType } from '../pages/WordReplacement'
import axios from 'axios'
import openNotification from '../../../shared/MessagesInfo/WarningBox'
import showMessage from '../../../shared/MessagesInfo/message'

type CreateCorrectWordType = {
    setSortedAlphabet: React.Dispatch<React.SetStateAction<SortedAlphabetType[]>>
    letterItem: SortedAlphabetType
    currentCountryId: number 
    editWord: CorrectWordData | null
}

const CreateCorrectWord:React.FC<CreateCorrectWordType> = ({ letterItem, currentCountryId, setSortedAlphabet, editWord }) => {
    const [ loading, setLoading ] = useState(false)
    const [ form ] = Form.useForm()

    useEffect(() => {
        editWord && form.setFieldsValue(editWord)
    }, [ editWord, form ])

    const onSubmit = async ( e: { misspelled_word: string, correct_word: string } ) => {
        if(e.misspelled_word[0].toLowerCase() === letterItem.letter[0].toLowerCase() ) {
            setLoading(true)
            const baseUrl = process.env.REACT_APP_URL_API
            const update = '/prices/word-replacement/update-word'
            const create = '/prices/word-replacement/add-word'
            const countryQuery = `?country_id=${ currentCountryId }`
            const misspelledQuery = `&misspelled_word=${ e.misspelled_word.trim().toLowerCase() }`
            const correctQuery = `&correct_word=${ e.correct_word.trim().toLowerCase() }`
            const productIdQuery = editWord ? `&id=${editWord.id}`:''
            try {
                const url = `${ baseUrl }${ editWord ? update : create }${ countryQuery }${ misspelledQuery }${ correctQuery }${ productIdQuery }`
                const { data } = await axios[ editWord ? 'put':'post' ](url)
                setSortedAlphabet( 
                    curr => curr.map( l => l.letter === letterItem.letter ? 
                        ({
                            ...l, 
                            data: editWord ?   // edit word
                                l.data.map( item => item.id === editWord.id ? 
                                    ({
                                        ...item, 
                                        misspelled_word: e.misspelled_word, 
                                        correct_word: e.correct_word
                                    })    
                                    : item )  
                            :[ ...l.data, data.data ] // creten new word
                        }) 
                    : l 
                ))
                form.resetFields()
                setLoading(false)
                setSortedAlphabet( curr => curr.map( w => ({ ...w, addNew:false })))
                showMessage(data.message)
            } catch ( err ) {
                console.log(err)
                setLoading(false)
            }
        }
        else {
            openNotification(`Misspelled word have to start with ${ letterItem.letter.toUpperCase() }`)
        }
    }

    return (
        <Form 
            style={{ border:'1px solid whitesmoke', padding:'6px', marginBottom:'10px', borderRadius:'5px' }}
            form={ form }
            onFinish={ onSubmit }
        > 
            <Form.Item 
                name='misspelled_word' 
                className='w-r-label-color' 
                style={{ padding:0, margin:0 }} 
                rules={[{ required: true,message:'Misspelled Word is required!' }]}
            >
                <Input placeholder='Misspelled Word' />
            </Form.Item>
            <Form.Item 
                name='correct_word' 
                className='w-r-label-color' 
                style={{ padding:0, margin:0, marginTop:'0.5rem' }}
                rules={[{ required: true,message:'Correct Word is required!' }]}
            >
                <Input placeholder='Correct Word' />
            </Form.Item>
            <Form.Item style={{ padding:0, margin:0, marginTop:'0.5rem' }}>
                <Button 
                    size='small' 
                    ghost
                    htmlType='submit'
                    loading={ loading }
                >
                    Add
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CreateCorrectWord