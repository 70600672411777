export const tabs = [
    {
        id: 1,
        key: "prices",
        name: "Prices",
    },
    {
        id: 2,
        key: "description-review",
        name: "Description / Review",
    },
    {
        id: 3,
        key: "attributes",
        name: "Attributes",
    },

    {
        id: 4,
        key: "history",
        name: "History",
    },
];
