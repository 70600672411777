import { Button, Col, Divider, Empty, Layout, Row, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import axios from "axios";
import EansModal from "../components/EanOverview/EansModal";
import "../style/EanOverview.css";
import Filters from "../components/EanOverview/Filters";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";

export type OffersType = { product_name: string; brand: string; mpn: string; shop: string; price_id: number; correctPrice: boolean; checkedPrice: boolean };

export type EansType = {
    data: { code: string; id: number; loadingNewProductByEAN: boolean; loadingNewProductByOffer: boolean; offers: OffersType[] }[];
    product_id: number | undefined;
    eanInfo: Record<string, string | number>;
};

const EanOverview = () => {
    const [productList, setProductList] = useState<Record<string, string | number>[]>([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [checked, setChecked] = useState<"1" | "2" | undefined>();
    const [order, setOrder] = useState<"asc" | "desc" | undefined>();
    const [orderBy, setOrderBy] = useState<"1" | "1" | "3" | undefined>();
    const [eans, setEans] = useState<EansType>({ data: [], product_id: undefined, eanInfo: {} });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const stateProduct = useSelector((state: RootStore) => state);

    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";

    useEffect(() => {
        const getCategories = async () => {
            setLoadingCategories(true);
            try {
                const baseUrl = process.env.REACT_APP_URL_API;
                const { data } = await axios.get(`${baseUrl}/product/ean-overview/list-grouped-categories`); // l3=229
                setCategories(data.data);
                setLoadingCategories(false);
            } catch (err) {
                console.log(err);
            }
        };
        getCategories();
    }, []);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.get(
                `${baseUrl}/product/ean-overview/list-products-by-l3?l3=${selectedCategory}${checked ? `&checked=${checked}` : ""}${order ? `&order_by=${order}` : ""}${
                    orderBy ? `&order_by=${orderBy}` : ""
                }`
            ); // l3=229
            setProductList(data.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [selectedCategory, checked, order, orderBy]);

    useEffect(() => {
        selectedCategory && getData();
    }, [selectedCategory, getData]);

    // const showEans = async (record: Record<string, string | number>) => {
    //     try {
    //         const baseUrl = process.env.REACT_APP_URL_API;
    //         const { data } = await axios.get(`${baseUrl}/product/ean-overview/list-product-eans?productID=${record.id}`);
    //         setEans({
    //             data: data.data.map((d: { code: string; offers: string }) => ({
    //                 ...d,
    //                 key: d.code,
    //                 loadingNewProductByEAN: false,
    //                 loadingNewProductByOffer: false,
    //                 offers: JSON.parse(d.offers).map((d: Record<string, string>) => ({ ...d, correctPrice: false, checkedPrice: false })),
    //             })),
    //             product_id: Number(record.id),
    //             eanInfo: record,
    //         });
    //         setIsModalOpen(true);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const showEans = async (record: Record<string, string | number>) => {
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.get(`${baseUrl}/product/ean-overview/list-product-eans?productID=${record.id}`); /// ${record.id}    5360472
            //console.log(data);
            setEans({
                data: [
                    ...data.data
                        .map((d: { code: string; offers: string }) => ({
                            ...d,
                            key: d.code,
                            loadingNewProductByEAN: false,
                            loadingNewProductByOffer: false,
                            offers: [...JSON.parse(d.offers).map((d: Record<string, string>) => ({ ...d, correctPrice: false, checkedPrice: false }))],
                        }))
                        .sort((a: { offers: string }, b: { offers: string }) => b.offers.length - a.offers.length),
                    ...(data[`non-offers`]
                        ? [
                              {
                                  // insert prices without enas
                                  brand_name: "",
                                  code: "0000000000000",
                                  id: 0,
                                  image_url: "",
                                  key: "0000000000000",
                                  loadingNewProductByEAN: false,
                                  loadingNewProductByOffer: false,
                                  [`name_${currentCountryName}`]: "no name",
                                  offers: data[`non-offers`], // prices without eans
                              },
                          ]
                        : []),
                ],
                product_id: Number(record.id),
                eanInfo: record,
            });
            setIsModalOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Ean Overview " />
            <Col span={12} className="automatched-prices-box" style={{ minHeight: "90vh", marginBottom: "2rem" }}>
                <Divider>Ean Overview</Divider>
                <Filters
                    category={{ selectedCategory, setSelectedCategory }}
                    cheched={{ checked, setChecked }}
                    order={{ order, setOrder }}
                    orderBy={{ orderBy, setOrderBy }}
                    setProductList={setProductList}
                    setEans={setEans}
                    categories={categories}
                    loadingCategories={loadingCategories}
                />
                <EansModal eans={eans} setEans={setEans} setProductList={setProductList} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getData={getData} />
                <Layout style={{ margin: "0.6rem 1rem", minHeight: "70%" }}>
                    <Layout.Header className="ean-overview-header" />
                    {selectedCategory ? (
                        <Table
                            loading={loading}
                            columns={[
                                {
                                    title: "Id",
                                    dataIndex: "id",
                                    align: "center" as "center",
                                    width: "50px",
                                },
                                {
                                    title: "Product",
                                    dataIndex: "name",
                                },
                                {
                                    title: "Ean Count",
                                    align: "center" as "center",
                                    dataIndex: `ean_count`,
                                    width: "100px",
                                },
                                {
                                    title: "",
                                    dataIndex: `id`,
                                    align: "center" as "center",
                                    width: "100px",
                                    render: (text, record) => (
                                        <Button size="small" onClick={() => showEans(record)}>
                                            Show Eans
                                        </Button>
                                    ),
                                },
                            ]}
                            dataSource={productList}
                            pagination={{ pageSize: 50, showSizeChanger: false }}
                            rowKey={(record) => record.id}
                        />
                    ) : (
                        <Empty
                            style={{ marginTop: "30%" }}
                            description={
                                <div>
                                    For list products,<span style={{ color: "darkred" }}> Select category</span>
                                </div>
                            }
                        />
                    )}
                </Layout>
            </Col>
        </Row>
    );
};

export default EanOverview;
