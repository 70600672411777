import { Modal } from 'antd'
import React from 'react'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import CreateShopMaps from '../../maps/pages/CreateShopMap'
import EditShopMap from '../../maps/pages/EditShopMap'
import { ShopRulesType } from './ShopList'


interface CreateShopMapModalProp {
    shopMap:ShopRulesType | null
    isModalShopMap:boolean
    handleModalShopMapOk:() => void
    handleModalShopMapCancel: () => void
    setCounter: React.Dispatch<React.SetStateAction<number>>
}

const CreateShopMapModal:React.FC<CreateShopMapModalProp> = ({ isModalShopMap, handleModalShopMapOk, handleModalShopMapCancel, shopMap, setCounter }) => {
    
    return (
        <React.Fragment>
            { isModalShopMap && <HelmetTitle title={`Data Entry App - ${ shopMap?.status === "Edit" ? "Edit":"Create" } Shop Map`} />}
        
            <Modal 
                forceRender
                title={ 
                    <p style={{ textAlign:'center'}}>
                        {`${ shopMap?.status === 'Edit' ? 'Edit':'Create' } shop map ${ shopMap?.shop_name && `for  `}`}
                        <span style={{ color:'darkred', fontSize:'16px' }}>
                            { shopMap?.shop_name }
                        </span>
                </p>  } 
                open={isModalShopMap}  
                onOk={handleModalShopMapOk} 
                onCancel={handleModalShopMapCancel}
                width={1400}
                footer={false}
                //style={{ border: shopMap?.status === 'Edit' ? '2px solid white':'', padding: shopMap?.status === 'Edit' ? '0.3rem':'', backgroundColor:  shopMap?.status === 'Edit' ? 'lightgray':'' }}
            >
                {
                    shopMap?.status === 'Create' ? 
                    <CreateShopMaps 
                        shopMap={ shopMap } 
                       
                        handleModalShopMapOk={handleModalShopMapOk}
                        setCounter={setCounter}
                    />:
                    <EditShopMap
                        shopMap={ shopMap } 
                        isModalShopMap={isModalShopMap} 
                        handleModalShopMapOk={handleModalShopMapOk}
                        setCounter={setCounter}
                    />
                }
            </Modal>
        </React.Fragment>
    )
}

export default CreateShopMapModal