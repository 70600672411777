import { Divider, Row } from 'antd'
import React from 'react'
import StoredImage from './StoredImage';

type StoredImagesProps = {
    editImages: {id: number, picture_url: string;}[]
}

const StoredImages:React.FC<StoredImagesProps> = (props) => {
  return (
    <Row style={{  padding:'1rem', paddingTop:"0rem", marginBottom:'1rem', backgroundColor:'#f7f7f7', borderRadius:"8px", border:'1px solid whitesmoke' }}>
        <Divider>Stored Images</Divider>
        {props.editImages.map( item => <StoredImage item={ item } key={ item.id } /> )}
    </Row>
  )
}

export default StoredImages