import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { SimilarDataType } from "../../types/AttributeType";
import { useEffect, useState } from "react";

type TabComponentType = {
    columns: ColumnsType<SimilarDataType>;
    mapped: SimilarDataType[];
    notMapped: SimilarDataType[];
    tabValue: number | null;
};

const TabComponent: React.FC<TabComponentType> = ({ mapped, notMapped, columns, tabValue }) => {
    const [mappedPage, setMappedPage] = useState(1);
    const [notMappedPage, setNotMappedPage] = useState(1);

    const changeMappedPage = (e: number) => {
        setMappedPage(e);
    };

    const changeNotMappedPage = (e: number) => {
        setNotMappedPage(e);
    };

    useEffect(() => {
        setMappedPage(1);
        setNotMappedPage(1);
    }, [tabValue]);

    return (
        <Row justify="center">
            <Col span={24} style={{ padding: "1rem" }}>
                <Table
                    className={`attribute-mapping-table-scroll attribute-mapping-table table${1} ${
                        mapped.length === 0 && "noAttMappingData"
                    } `}
                    columns={columns}
                    dataSource={mapped}
                    pagination={{
                        size: "small",
                        showSizeChanger: false,
                        pageSize: 10,
                        position: ["topRight"],
                        onChange: changeMappedPage,
                        current: mappedPage,
                    }}
                    bordered
                    rowKey={(record) => record.id}
                    style={{ marginTop: "0", scrollbarColor: "red" }}
                    rowClassName={(record) =>
                        `${record.isLoading ? `attribute-mapping${1}-loading-record` : ""} ${
                            record.isSelect ? "att-mapping-selected-row" : ""
                        }`
                    }
                />
                <Table
                    className={`attribute-mapping-table-scroll attribute-mapping-table table${2} ${
                        notMapped.length === 0 && "noAttMappingData"
                    } `}
                    columns={columns}
                    dataSource={notMapped}
                    pagination={{
                        size: "small",
                        showSizeChanger: false,
                        pageSize: 10,
                        position: ["topRight"],
                        onChange: changeNotMappedPage,
                        current: notMappedPage,
                    }}
                    bordered
                    rowKey={(record) => record.id}
                    style={{ marginTop: "1rem", scrollbarColor: "red" }}
                    rowClassName={(record) =>
                        `${record.isLoading ? `attribute-mapping${2}-loading-record` : ""} ${
                            record.isSelect ? "att-mapping-selected-row" : ""
                        }`
                    }
                />
            </Col>
            {!tabValue && <p>Please select a shop for attribute mapping.</p>}
        </Row>
    );
};

export default TabComponent;
