import { Alert, Button, Checkbox, Col, Divider, Input, Popconfirm, Row, Select, Typography } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllShops } from '../../../actions/shopAction/shopAction'
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import showMessage from '../../../shared/MessagesInfo/message'
import { RootStore } from '../../../Store'
import openNotification from '../../../shared/MessagesInfo/WarningBox'

type FeedType = {
    id: number
    enabled: boolean
    feed_url: string
    shop_id: number
    isDelete: boolean
    editFeed: boolean
    editFeedUrl: boolean
}

const XmlMerged = () => {
    const [ xmlMerged, setXmlMerged ] = useState<{data:FeedType[]}>({data:[]})
    const [ shopId, setShopId ] = useState('')
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ feedUrl, setFeedUrl ] = useState({feed:'', enabled:true})
    const [ openFeedUrlInput, setOpenFeedUrlInput ] = useState<FeedType | null>(null)
    const shopState = useSelector((state:RootStore ) => state)
    const dispatch = useDispatch()
    const currentCountryId =  shopState.dataLs.country ? shopState.dataLs.country.countryId:1
  
    useEffect(()=>{
        dispatch(getAllShops(0, currentCountryId))
    },[ dispatch, currentCountryId ])

    function handleChange(value:any) {
        if (value) {
            setShopId(value)
            getXmlMerged(Number(value))
        }
    }
   
    const getXmlMerged = async (id:number) => {
        if( typeof id === 'number'){
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/xml-merger/list-feeds?shop_id=${id}`)
                const newData = data.data.map((d:FeedType) => ({...d, isDelete: false, editFeed: false, editFeedUrl: false}))
                setXmlMerged({...data, data:newData})
            } catch ( err ) {
                console.log(err)
            }
        }
        
    }
    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const addNewFeedUrl = async () => {
        const obj = {
            shop_id: shopId,
            feed_url: feedUrl.feed,
            enabled: feedUrl.enabled
        }
        try {
            const { data } = await axios.post(`${ process.env.REACT_APP_URL_API }/xml-merger/add-feed`, obj)
            showMessage(data.message)
            const newData = {
                ...xmlMerged, 
                data:[
                    ...xmlMerged.data, 
                    {
                        id:data.data.id,
                        isDelete: false, 
                        editFeed: false,
                        editFeedUrl: false,
                        shop_id:data.data.shop_id,
                        feed_url:data.data.feed_url, 
                        enabled: data.data.enabled === "true" || data.data.enabled === true ? true:false 
                    }
                ]
            }
            setXmlMerged(newData)
            setFeedUrl({feed:'', enabled:true})
            setIsModalVisible(false)
        } catch ( err ) {
            console.log(err)
        }
    }
    const deleteFeed = async (id:number) => {
        setXmlMerged(curr => ({...curr, data: curr.data.map( d => d.id === id ? ({...d, isDelete:true }) : d )}))
        try {
            const { data } = await axios.delete(`${ process.env.REACT_APP_URL_API }/xml-merger/delete-feed?id=${ id }`)
            const newData = xmlMerged.data.filter((item:any ) => item.id!==id)
            setXmlMerged({...xmlMerged, data:newData})
            showMessage(data.message)
        } catch ( err ) {
            console.log(err)
        }
    }

    const handleFeedChange = async ( isChecked:boolean, id:number ) => {
        const findFeed = xmlMerged.data.find( x => x.id === id)
        const obj = {
            shop_id:findFeed?.shop_id,
            enabled:isChecked ? '1':'0',
            feed_url:findFeed?.feed_url,
            id:id
        }

        try {
            const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/xml-merger/update-feed`, obj)
            showMessage(data.message)
        } catch ( err ) {
            console.log(err)
        }

        setXmlMerged(curr => ({...curr, data: curr.data.map( d => d.id === id ? ({...d, enabled: !d.enabled }) : d )}))
    }

    const editFeedName = async (e:string) => {
        if(e.trim().length > 0) {
            const obj = {
                shop_id:openFeedUrlInput?.shop_id,
                enabled:openFeedUrlInput?.enabled? '1':'0',
                feed_url:e,
                id:openFeedUrlInput?.id
            }
            setXmlMerged( curr => ({...curr, data:curr.data.map( x =>x.id === openFeedUrlInput?.id ? ({...x, editFeedUrl:  true}) : x)}))
            try {
                const { data } = await axios.put(`${ process.env.REACT_APP_URL_API }/xml-merger/update-feed`, obj)
                //here to map a new array
    
                setXmlMerged( curr => ({...curr, data:curr.data.map( x =>x.id === openFeedUrlInput?.id ? ({...x, feed_url:  e, editFeedUrl: false}) : x)}))
                setOpenFeedUrlInput(null)
                showMessage(data.message)
                
            } catch ( err ) {
                console.log(err)
            }
        }
        else {
            openNotification('Feed url without charaters is not allowed!')
        }
    }

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - XML Merged" />  
            <Row justify={"center"}>
                <Col span={18} style={{ backgroundColor:'white', marginTop:'1rem', padding:'1rem', minHeight:'86vh', height:'100%' }}>
                    <Divider>XML Merged</Divider>
                    <Select
                        showSearch
                        allowClear
                        style={{ width: '180px', marginBottom:'0.5rem' }}
                        placeholder="Select shop"
                        //defaultValue='1'
                        onClear={()=>{setShopId('');setXmlMerged({data:[]})}}
                        onChange={handleChange}
                        filterOption={ (input: string, option?: { label: string; value: string }) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={shopState.shops.data ? shopState.shops.data.map((s:any) => ({label: s.name, value:s.id}) ):[]}
                    /><br/>
                    <Button 
                        disabled={shopId === '' || undefined ? true : false} 
                        //className="u-button" 
                        style={{ marginTop:'1rem'}} 
                        type="primary" 
                        onClick={showModal}
                    >
                        Add Feed
                    </Button>
                    <Modal 
                        title="Add new feed" 
                        open={isModalVisible} 
                        onOk={handleOk} 
                        onCancel={handleCancel}
                        footer={false}
                        width={1000}
                    >
                        <Input name="feed-url" style={{ width:'90%' }} placeholder='Feed url' value={feedUrl.feed} onChange={(e) => setFeedUrl({...feedUrl, feed:e.target.value})} />
                        <Button style={{ marginTop:'10px', marginLeft:'5px'}} type='primary' onClick={addNewFeedUrl} disabled={feedUrl.feed.trim() === '' ? true:false}>Add feed</Button>
                    </Modal>
                    {xmlMerged.data.length > 0 &&  
                    <div>
                        <p style={{ margin:'1rem 0' }}>{ `${ process.env.REACT_APP_URL_API }/xml-merger/get-xml?shop_id=${shopId}` }</p>
                        <Alert
                            //type='warning'
                            style={{ margin:'1rem 0', backgroundColor:'#fcfbf7'}}
                            message={
                                <div>
                                    {
                                        xmlMerged.data && xmlMerged.data.map((item)=> (
                                            <Alert
                                                key={item.id}
                                                type={ item.isDelete ? 'error'  : item.enabled ? 'info':'warning'}
                                                
                                                style={{ marginBottom:'5px' }}
                                                message={
                                                    <div>
                                                        <div>
                                                            <Typography.Paragraph 
                                                                editable={{ onChange: editFeedName, }} 
                                                                onClick={() => setOpenFeedUrlInput(item)}
                                                                type={ item.editFeedUrl ? 'success' : undefined }
                                                            >
                                                                { item.feed_url }
                                                            </Typography.Paragraph>
                                                        </div>
                                                        <span ><em>Enabled:</em> </span><Checkbox name='enabled' checked={item.enabled} onChange={(e)=> handleFeedChange(e.target.checked, item.id)} style={{ marginLeft:'0.2rem'}} /><br/>
                                                        <Popconfirm placement="top" title={'Are you sure?'} onConfirm={() => deleteFeed (item.id)} okText="Yes" cancelText="No">
                                                            <Button style={{ marginLeft:'5px', marginTop:"2rem"}} danger>Delete</Button>
                                                        </Popconfirm>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </div>
                            }
                            
                        />
                    </div>}
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default XmlMerged
