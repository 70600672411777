import { USERS_LIST, USERS_LOGIN, USER_CREATE, USER_DELETE, USER_EDIT, USER_GET, USER_LOGOUT, USER_MYAC_CHANGE_EMAIL, USER_MYAC_CHANGE_PASSWORD, USER_MYAC_UPDATE, USER_SET } from "../actions/userAction/userActionsType";

const userReducer = (state:any=[], action:any) => {
    switch (action.type) {
        case USERS_LIST:
            return  action.payload 
        case USER_EDIT:
            return state.users.data.map((user:any)=> user.id === Number(action.payload.id) ? action.payload:user)
        case USER_DELETE:
            return  {...state, users:{data: state.users.data.filter((user:any)=>user.id!==action.payload)}}
    }
    return state
}
export const loginUser = (state:any={}, action:any) => {
    switch (action.type) {
        case USERS_LOGIN:
            return { user: action.payload }
        case USER_CREATE:
            return { user: action.payload }
        case USER_SET:
            return { user:action.payload}
        case USER_LOGOUT:
            return action.payload
        default:
            break;
    }
    return state
}


//------for my account-------

export const userMyAcReducer = (state:any=[], action:any) => {
    switch ( action.type) {
        case USER_GET:
            return action.payload
        case USER_MYAC_UPDATE:
            return action.payload
        case USER_MYAC_CHANGE_PASSWORD:
            return state
        case USER_MYAC_CHANGE_EMAIL:
            return {...state, data:{...state.data, email:action.payload.data.email}}
        case USER_LOGOUT:
            return action.payload   //new 
           
        default:
            break;
    }
    return state
}


export default userReducer