import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Divider, Checkbox, Skeleton, Row, Col } from "antd";
import "../style/DailyReportUser.css";
import axios from "axios";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DatePickerNewProducts } from "./NewShopProducts";
import DatePickerFromToReports from "./DatePickerFromToReports";
import moment from "moment";
import numberWithCommas from "../../../shared/utils/numberUtils";

type UserReportType = {
    auto_match: number;
    create_product: number;
    ean_match: number;
    mpn_match: number;
    merge_product: number;
    insert: number;
    category_mapping: number;
    name: string;
    /////////////
    updated_product: number;
    username: string;
    image_page_change: number;
    check_price_difference: number;
    //////
    check_preview_text_change: number;
    short_description_change: number;
    category_change: number;
    product_name_match: number;
    catalogue_create: number;

    catalogue_update: number;
    product_name_mass_edit: number;
    product_review_change: number;
    automatch_blacklist: number;
    mpn_check: number;
    ean_detach: number;
    faq_action: number;
    ///////
    delete_attribute_value: number;
    create_attribute_mapping: number;
    delete_attribute_mapping: number;
    create_product_attribute: number;
    update_attribute: number;
    update_product_attribute: number;
    merge_attribute: number;
    ean_overview_check: number;
};

const dataKeyArray = [
    {
        name: "auto_match",
        color: "#81AEF5",
    },
    {
        name: "ean_match",
        color: "#FFDAC1",
    },
    {
        name: "mpn_match", //check
        color: "#C89FE8",
    },
    {
        name: "product_name_match",
        color: "#C7CEEA",
    },
    {
        name: "create_product",
        color: "#8F8BD8",
    },
    {
        name: "merge_product",
        color: "#C5E1A5",
    },
    {
        name: "insert",
        color: "#FCE4EC",
    },
    {
        name: "category_mapping",
        color: "#80BFD5",
    },
    {
        name: "updated_product",
        color: "#C3A0F7",
    },
    {
        name: "image_page_change",
        color: "#90BCB9",
    },
    {
        name: "category_change",
        color: "#FCF8AE",
    },

    {
        name: "check_preview_text_change",
        color: "#CCF5AE",
    },
    {
        name: "short_description_change",
        color: "#AEE8F0",
    },
    {
        name: "check_price_difference",
        color: "#FF9AA2",
    },
    {
        name: "catalogue_create",
        color: "#ff9a3c",
    },
    {
        name: "catalogue_update",
        color: "#f5dd7b",
    },
    {
        name: "product_name_mass_edit",
        color: "#f95959",
    },
    {
        name: "product_review_change",
        color: "#acc6aa",
    },
    {
        name: "automatch_blacklist",
        color: "#5c5470",
    },
    {
        name: "mpn_check",
        color: "#f03861",
    },
    {
        name: "ean_detach",
        color: "#f0d78c",
    },
    {
        name: "faq_action",
        color: "#B3DDC6",
    },
    {
        name: "delete_attribute_value",
        color: "#6499E9",
    },
    {
        name: "create_attribute_mapping",
        color: "#9EDDFF",
    },
    {
        name: "delete_attribute_mapping",
        color: "#A6F6FF",
    },
    {
        name: "create_product_attribute",
        color: "#BEFFF7",
    },
    {
        name: "update_attribute",
        color: "#074173",
    },
    {
        name: "update_product_attribute",
        color: "#1679AB",
    },
    {
        name: "merge_attribute",
        color: "#808000",
    },
    {
        name: "ean_overview_check",
        color: "#E3A5C7",
    },
    {
        name: "sum_checked",
        color: "black",
    },
];

const formatName = (fullName: string) => {
    const nameArr = fullName.split(" ");
    const firsName = nameArr[0];
    const lastName = nameArr[1];
    return `${firsName} ${lastName.charAt(0)}.`;
};

function capitalizeFirstLetter(string: string) {
    const newString = string.charAt(0).toUpperCase() + string.slice(1);
    const stringToArr = newString.split("_");
    return `${stringToArr[0]} ${stringToArr[1] ? stringToArr[1].charAt(0).toUpperCase() + stringToArr[1].slice(1) : ""} ${
        //string.split('_').length <=2 ? ''  :stringToArr[2].charAt(0).toUpperCase() + stringToArr[2].slice(1)
        stringToArr[2] ? stringToArr[2].charAt(0).toUpperCase() + stringToArr[2].slice(1) : ""
    } ${stringToArr[3] ? stringToArr[3].charAt(0).toUpperCase() + stringToArr[3].slice(1) : ""}`;
}

const CustomTooltip = ({ active, payload, label, selectData, setSelectData, userReport }: any) => {
    if (payload && payload[0] && payload[0].payload) {
        return (
            <div style={{ backgroundColor: "whitesmoke", position: "relative", bottom: "2rem", left: "2rem", border: "1px solid gray", borderRadius: "8px" }}>
                {
                    <p className="label" style={{ fontSize: "20px", textAlign: "center", marginTop: "0.5rem" }}>
                        {label}{" "}
                    </p>
                }
                <Row gutter={[16, 8]} style={{ marginTop: "0rem", width: "1000px" }}>
                    {/* <Col span={12}></Col> */}
                    {dataKeyArray.map(
                        (action, i) =>
                            selectData[action.name] && (
                                <Col key={action.name} span={action.name === "sum_checked" ? 24 : 6} style={{ paddingLeft: "1rem", paddingBottom: dataKeyArray.length === i + 1 ? "0.3rem" : "" }}>
                                    {dataKeyArray.length === i + 1 && <Divider style={{ margin: "0.3rem 0" }} />}
                                    <div key={action.name} style={{ display: "flex", flexDirection: "row" /*, borderTop: action.name === "sum_checked" ? "1px solid #340000" : "" */ }}>
                                        <div
                                            className={action.name === "sum_checked" && Object.values(selectData).every(Boolean) ? "" : `square-label`}
                                            style={{ backgroundColor: payload[0].payload[action.name] === 0 ? "lightgray" : action.color }}
                                        ></div>
                                        <span
                                            className="intro"
                                            style={action.name === "sum_checked" ? { color: "#340000", fontSize: "14px" } : { color: payload[0].payload[action.name] === 0 ? "lightgray" : "" }}
                                        >
                                            {action.name !== "sum_checked" ? (
                                                `${capitalizeFirstLetter(action.name)}: ${numberWithCommas(payload[0].payload[action.name])}`
                                            ) : !Object.values(selectData).every(Boolean) ? (
                                                <span style={{ top: "1rem" }}>
                                                    {`Sum Of Checked: ${numberWithCommas(
                                                        dataKeyArray
                                                            .map((data) =>
                                                                userReport[0] && data.name !== "sum_checked" && selectData[`${data.name}`]
                                                                    ? userReport[userReport.findIndex((user: UserReportType) => `${user.name}` === label)][`${data.name}`]
                                                                    : 0
                                                            )
                                                            .reduce((a, b) => a + b)
                                                    )}`}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </div>
                                </Col>
                            )
                    )}
                </Row>

                <div style={{ padding: "0 1rem" }}>
                    {selectData.auto_match &&
                        selectData.create_product &&
                        selectData.ean_match &&
                        selectData.mpn_match &&
                        selectData.merge_product &&
                        selectData.insert &&
                        selectData.category_mapping &&
                        /////////////
                        selectData.updated_product &&
                        selectData.image_page_change &&
                        selectData.check_price_difference &&
                        selectData.check_preview_text_change &&
                        selectData.short_description_change &&
                        selectData.category_change &&
                        selectData.product_name_match &&
                        selectData.catalogue_create &&
                        selectData.catalogue_update &&
                        selectData.product_name_mass_edit &&
                        selectData.product_review_change &&
                        selectData.automatch_blacklist &&
                        selectData.mpn_check &&
                        selectData.ean_detach &&
                        selectData.faq_action &&
                        selectData.delete_attribute_value &&
                        selectData.create_attribute_mapping &&
                        selectData.delete_attribute_mapping &&
                        selectData.create_product_attribute &&
                        selectData.update_attribute &&
                        selectData.update_product_attribute &&
                        selectData.merge_attribute &&
                        selectData.ean_overview_check && (
                            ////////////
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {/* <Divider style={{ margin: "0.3rem 0" }} /> */}
                                <p className="intro">{`All: ${numberWithCommas(payload[0].payload.all)}`}</p>
                            </div>
                        )}
                </div>
            </div>
        );
    }

    return null;
};

const DailyReportUser = () => {
    const [userReport, setUserReport] = useState<UserReportType[]>([]);
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const [sumData, setSumData] = useState(0);
    const [dataLoading, setDataLoading] = useState(true);
    const [selectData, setSelectData] = useState<any>({
        auto_match: false,
        create_product: false,
        ean_match: false,
        mpn_match: false,
        merge_product: false,
        insert: false,
        category_mapping: false,
        updated_product: false,
        image_page_change: false,
        check_price_difference: false,
        check_preview_text_change: false,
        short_description_change: false,
        category_change: false,
        product_name_match: false,
        catalogue_create: false,
        catalogue_update: false,
        product_name_mass_edit: false,
        product_review_change: false,
        automatch_blacklist: false,
        mpn_check: false,
        ean_detach: false,
        faq_action: false,
        delete_attribute_value: false,
        create_attribute_mapping: false,
        delete_attribute_mapping: false,
        create_product_attribute: false,
        update_attribute: false,
        update_product_attribute: false,
        merge_attribute: false,
        ean_overview_check: false,
        sum_checked: true,
    });

    useEffect(() => {
        const sumAllNumbers = () => {
            let sum = 0;
            for (let el in selectData) {
                if (selectData[el] && el !== "sum_checked") {
                    sum += userReport.map((data: any) => data[el]).reduce((partialSum: number, a: number) => partialSum + a, 0);
                }
            }
            return sum;
        };
        setSumData(sumAllNumbers());
    }, [userReport, selectData]);

    const [loading, setLoading] = useState(true);
    const size = useWindowSize();
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        let formTo: { startDate: string; endDate: string };
        if (date.endDate) {
            formTo = {
                startDate: moment(date.startDate).format("YYYY-MM-DD"),
                endDate: moment(date.endDate).format("YYYY-MM-DD"),
            };
        }
        const getUserDailyReport = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/logger/list-activity?from=${formTo.startDate}&to=${formTo.endDate}`);
                const changeNewData = data.data.map((item: any) => {
                    let sum = 0;
                    for (let el in item) {
                        if (item.hasOwnProperty(el)) {
                            if (
                                el === "auto_match" ||
                                el === "create_product" ||
                                el === "ean_match" ||
                                el === "mpn_match" ||
                                el === "merge_product" ||
                                el === "insert" ||
                                el === "category_mapping" ||
                                el === "updated_product" ||
                                el === "image_page_change" ||
                                el === "check_price_difference" ||
                                el === "check_preview_text_change" ||
                                el === "short_description_change" ||
                                el === "category_change" ||
                                el === "product_name_match" ||
                                el === "catalogue_create" ||
                                el === "catalogue_update" ||
                                el === "product_name_mass_edit" ||
                                el === "product_review_change" ||
                                el === "automatch_blacklist" ||
                                el === "mpn_check" ||
                                el === "ean_detach" ||
                                el === "faq_action" ||
                                el === "delete_attribute_value" ||
                                el === "create_attribute_mapping" ||
                                el === "delete_attribute_mapping" ||
                                el === "create_product_attribute" ||
                                el === "update_attribute" ||
                                el === "update_product_attribute" ||
                                el === "merge_attribute" ||
                                el === "ean_overview_check"
                            ) {
                                sum += parseFloat(item[el]);
                            }
                        }
                    }
                    return {
                        ...item,
                        name: `${formatName(item.username)}`,
                        all: sum,
                    };
                });
                setUserReport(changeNewData);
                setDataLoading(false);
            } catch (err) {
                setDataLoading(false);
                console.log(err);
            }
        };
        if (date.startDate && date.endDate) {
            getUserDailyReport();
        }
    }, [date]);

    const handleSelectData = (e: CheckboxChangeEvent) => {
        const obj: any = {};

        for (const key of dataKeyArray) {
            if (e.target.id === key.name) {
                obj[key.name] = !selectData[`${e.target.id}`];
            }
            setSelectData({ ...selectData, ...obj, sum_checked: true });
        }
    };
    const showAllData = (e: CheckboxChangeEvent) => {
        const obj: any = {};
        for (const key of dataKeyArray) {
            obj[key.name] = e.target.checked;
        }
        setSelectData(obj);
    };

    if (loading) {
        <h3>Loading...</h3>;
    }
    return (
        <div id="daily-report-user-box">
            <div style={{ width: "80%", margin: "0 auto" }}>
                <Divider>User Activity</Divider>
            </div>
            <div style={{ width: "80%", margin: "0 auto" }}>{!dataLoading && <DatePickerFromToReports date={date} setDate={setDate} isDate={true} />}</div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", position: "relative" }}>
                {sumData !== 0 && (
                    <div
                        style={{
                            position: "absolute",
                            right: "2rem",
                            top: "-4rem",
                            borderRadius: "3px",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#eef1f9",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0.4rem",
                        }}
                    >
                        <span style={{ color: "#3f8600", fontSize: "18px" }}>{numberWithCommas(sumData)}</span>
                    </div>
                )}
                {!dataLoading ? (
                    <BarChart
                        width={size.width < 1935 ? 1150 : 1070}
                        height={360}
                        data={userReport}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        className="user-activity-chart-bar"
                        onClick={(e: any) => console.log(e)}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" className="x-axis-report-user" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip selectData={selectData} setSelectData={setSelectData} userReport={userReport} userListLength={userReport.length} />} />

                        {dataKeyArray.map((action) => selectData[action.name] && action.name !== "all" && <Bar key={action.name} dataKey={action.name} stackId="a" fill={action.color} />)}
                    </BarChart>
                ) : (
                    <div style={{ width: size.width < 1935 ? 1150 : 1140, height: 510 }}>
                        <Skeleton
                            active
                            paragraph={{ rows: 9 }}
                            title={{ width: "100%" }}
                            className="uncategorized-products-table"
                            style={{ maxWidth: "90%", minWidth: "200px", border: "none", marginLeft: "4rem", position: "relative", bottom: "0rem" }}
                        />
                    </div>
                )}
                {!dataLoading && (
                    <div
                        style={{
                            margin: "0 3rem",
                            //width:'90%',
                            backgroundColor: "#F2F3F4",
                            padding: "0.5rem",
                            borderRadius: "8px",
                        }}
                    >
                        {dataKeyArray.map(
                            (action) =>
                                action.name !== "all" &&
                                action.name !== "sum_checked" && (
                                    <Checkbox
                                        key={action.name}
                                        id={action.name}
                                        checked={selectData[`${action.name}`]}
                                        onChange={handleSelectData}
                                        style={{ color: "#839BDB", marginLeft: "15px", width: "210px" }}
                                    >
                                        {capitalizeFirstLetter(action.name)}
                                    </Checkbox>
                                )
                        )}
                        <Checkbox checked={Object.values(selectData).every(Boolean)} onChange={showAllData} style={{ marginLeft: "15px", width: "210px", color: "darkblue" }}>
                            All
                        </Checkbox>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DailyReportUser;
