import { Segmented } from "antd";
import { RootStore } from "../../../../../../Store";
import { SegmentedValue } from "antd/es/segmented";

interface CountriesTabsProps {
    stateProduct: RootStore;
    changeCountryValue: (e: SegmentedValue) => void;
}

const CountriesTabs = (props: CountriesTabsProps) => {
    const { stateProduct, changeCountryValue } = props;
    return (
        <Segmented
            options={stateProduct.countries.map((country) => ({
                label: <span>{country.locale_code.toUpperCase()}</span>, //
                value: country.locale_code,
            }))}
            onChange={(e) => changeCountryValue(e)}
            size="large"
            style={{ marginRight: "1rem", marginBottom: "0.5rem" }}
        />
    );
};

export default CountriesTabs;
