import { DeleteTwoTone } from "@ant-design/icons";
import { Card, Image } from "antd";
import React, { useState } from "react";
import { PicturesType } from "../../../types/type";
import axios from "axios";
import showMessage from "../../../../../shared/MessagesInfo/message";

interface ImageCardType {
    img: PicturesType;
    productId: number | undefined;
    setProductInfo: React.Dispatch<React.SetStateAction<{ name: string; value: string | PicturesType[] }[]>>;
}

const ImageCard = (props: ImageCardType) => {
    const { img, productId, setProductInfo } = props;
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);

    const setImageAsDefault = async (name: string) => {
        // set left image

        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/product/image/set-default-image?product_id=${productId}&name=${name.substring(3)}`);
            showMessage(data.message);
            setProductInfo((curr) =>
                curr.map((p) =>
                    p.name === "pictures" && typeof p.value !== "string"
                        ? { ...p, value: p.value.map((img) => (img.name === name ? { ...img, primaryimage: true } : { ...img, primaryimage: false })) }
                        : p.name === "default_image_url"
                        ? { ...p, value: name }
                        : p
                )
            );
        } catch (err) {
            console.log(err);
        }
    };

    const deleteImage = async (name: string) => {
        setLoadingDeleteImage(true);
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/product/image/delete-image?product_id=${productId}&name=${name.substring(3)}`);
            showMessage(data.message);
            setProductInfo((curr) => curr.map((p) => (p.name === "pictures" && typeof p.value !== "string" ? { ...p, value: p.value.filter((img) => img.name !== name) } : p)));
            setLoadingDeleteImage(false);
        } catch (err) {
            console.log(err);
            setLoadingDeleteImage(false);
        }
    };

    return (
        <Card
            style={{
                padding: 0,
                marginLeft: "0.3rem",
                marginTop: "0.3rem",
                border: img.primaryimage ? "2px solid #FF9B9B" : `2px solid ${loadingDeleteImage ? "#9DBDFF" : "lightgray"}`,
                cursor: "pointer",
            }}
            bodyStyle={{ padding: "5px 10px", width: "80px", height: "80px" }}
            onDoubleClick={() => setImageAsDefault(img.name)}
        >
            <Image preview={false} src={`https://img.ep-cdn.com/i/500/500/${img.name}.webp`} height={50} width={50} style={{ opacity: loadingDeleteImage ? 0.6 : 1 }} />
            <div style={{ fontSize: "11px", display: "flex", alignItems: "center", justifyContent: "center" }}>{img.width && img.height && `${img.width} x ${img.height}`}</div>
            <DeleteTwoTone
                onClick={() => /*!img.primaryimage &&*/ deleteImage(img.name)}
                className="product-image-delete"
                style={{ position: "absolute", right: "3px", top: "3px", cursor: "pointer" }}
            />
        </Card>
    );
};

export default ImageCard;
