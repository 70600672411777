import { notification } from "antd";

const key = "updatable";

const openNotification = (msg: string | React.ReactNode, dur?: number) => {
    notification.error({
        key,
        message: "Error",
        description: msg,
        placement: "bottomRight",
        duration: dur === 0 ? 0 : 3,
    });
};

export default openNotification;
